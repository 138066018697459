import React, { useMemo, useEffect, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { Typography, Input, Button } from '@kea-inc/parrot-ui';

import * as paymentSelectors from '@modules/payment/selectors';
import {
  setManualPaymentField,
  processManualPayment,
  closeManualPaymentOverlay,
} from '@modules/payment/api';

import { CenterOverlay, AnnounceBadge } from '@components';
import { usePayment } from '@hooks';

import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { makeRemainingVbxPhrase } from './utils';
import * as S from './styles';

const ManualPaymentOverlay = () => {
  const [hasStartedProcessing, setHasStartedProcessing] = useState(false);
  const {
    manualCreditCard,
    creditCard,
    isManualPaymentOverlayOpen,
    fields,
    streamFields,
    pending,
  } = usePayment();
  const { isProcessingManualPayment } = pending;

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const remainingVbx = useMemo(
    () => makeRemainingVbxPhrase(manualCreditCard),
    [manualCreditCard],
  );

  const { statuses } = useMemo(
    () => paymentSelectors.getFieldsStatuses(),
    [creditCard],
  );

  const isManualPaymentValid = useMemo(
    () => paymentSelectors.isManualPaymentValid(),
    [manualCreditCard],
  );

  const handleProcessManualPayment = () => {
    setHasStartedProcessing(true);
    processManualPayment();
  };

  useEffect(() => {
    if (!isProcessingManualPayment && hasStartedProcessing) {
      setHasStartedProcessing(false);
    }
  }, [isProcessingManualPayment]);

  const hasRemaining = !Object.values(manualCreditCard).every(Boolean);

  const cardMessage = 'Can I get your full card number now?';
  const expDateMessage = 'Can I get the expiration date on the card?';
  const cvvMessage = 'Can I get the security code on the card?';
  const billingZipMessage = 'Can I get your billing zip code?';

  const handleAnnounceBadgeClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return isManualPaymentOverlayOpen ? (
    <CenterOverlay>
      <S.Container>
        <Typography variant="subheader" weight="heavy">
          Payment
        </Typography>

        <form>
          <AnnounceBadge
            className="label"
            message={cardMessage}
            onClick={handleAnnounceBadgeClick(cardMessage)}
          >
            Card #
          </AnnounceBadge>
          <Input
            value={manualCreditCard[fields.NUMBER]}
            onChange={(e) =>
              setManualPaymentField(fields.NUMBER, e.target.value)
            }
            mask="9999999999999999999"
            alwaysShowMask={false}
            maskChar=""
            placeholder="Card #"
            disabled={hasStartedProcessing}
            rightIcon={
              statuses[streamFields.NUMBER] === 'filled'
                ? AiFillCheckCircle
                : undefined
            }
          />

          <div className="exp_date__cvv">
            <div>
              <AnnounceBadge
                className="label"
                message={expDateMessage}
                onClick={handleAnnounceBadgeClick(expDateMessage)}
              >
                Exp. Date
              </AnnounceBadge>
              <Input
                value={manualCreditCard[fields.EXP_DATE]}
                onChange={(e) =>
                  setManualPaymentField(fields.EXP_DATE, e.target.value)
                }
                mask="99/99"
                alwaysShowMask={false}
                disabled={hasStartedProcessing}
                placeholder="MM/YY"
                maskChar=""
                rightIcon={
                  statuses[streamFields.EXP_DATE] === 'filled'
                    ? AiFillCheckCircle
                    : undefined
                }
              />
            </div>

            <div>
              <AnnounceBadge
                className="label"
                message="Can I get the security code on the card?"
                onClick={handleAnnounceBadgeClick(cvvMessage)}
              >
                CVV
              </AnnounceBadge>
              <Input
                value={manualCreditCard[fields.CVV]}
                onChange={(e) =>
                  setManualPaymentField(fields.CVV, e.target.value)
                }
                mask="9999"
                alwaysShowMask={false}
                maskChar=""
                disabled={hasStartedProcessing}
                placeholder="CVV"
                rightIcon={
                  statuses[streamFields.CVV] === 'filled'
                    ? AiFillCheckCircle
                    : undefined
                }
              />
            </div>
          </div>

          <AnnounceBadge
            className="label"
            message="Can I get your billing zip code?"
            onClick={handleAnnounceBadgeClick(billingZipMessage)}
          >
            Zip Code
          </AnnounceBadge>
          <Input
            value={manualCreditCard[fields.ZIPCODE]}
            onChange={(e) =>
              setManualPaymentField(fields.ZIPCODE, e.target.value)
            }
            mask="99999"
            maskChar=""
            alwaysShowMask={false}
            disabled={hasStartedProcessing}
            placeholder="Zip Code"
            rightIcon={
              statuses[streamFields.ZIPCODE] === 'filled'
                ? AiFillCheckCircle
                : undefined
            }
          />
        </form>

        <S.Footer>
          {hasRemaining && (
            <AnnounceBadge
              message={remainingVbx}
              onClick={handleAnnounceBadgeClick(remainingVbx)}
            >
              Remaining
            </AnnounceBadge>
          )}

          <div className="actions">
            <Button
              onClick={closeManualPaymentOverlay}
              disabled={hasStartedProcessing}
              size="small"
              secondary
            >
              Cancel
            </Button>

            <Button
              disabled={hasRemaining || !isManualPaymentValid}
              loading={hasStartedProcessing}
              onClick={handleProcessManualPayment}
              size="small"
            >
              Process
            </Button>
          </div>
        </S.Footer>
      </S.Container>
    </CenterOverlay>
  ) : (
    <div />
  );
};

export default React.memo(ManualPaymentOverlay);
