import React from 'react';
import { Button } from '@kea-inc/parrot-ui';
import { useRoles, useHistory } from '@hooks';

import NotFound404 from '@images/not-found.png';
import { Container } from './styles';

const NotFound = () => {
  const { availableRoutes } = useRoles();
  const history = useHistory();

  const handleHomeButton = () => {
    history.replace(availableRoutes[0]);
  };

  return (
    <Container>
      <img src={NotFound404} alt="Not found" />
      <Button onClick={handleHomeButton}>Go Home</Button>
    </Container>
  );
};

export default NotFound;
