import { types } from './types';

export function notificationEmit(payload) {
  return {
    type: types.NOTIFICATION_EMIT,
    payload,
  };
}

export function notificationDismiss(payload) {
  return {
    type: types.NOTIFICATION_DISMISS,
    payload,
  };
}

export function reset() {
  return {
    type: types.RESET,
  };
}
