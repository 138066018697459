import styled, { css } from 'styled-components';
import { theme } from '@kea-inc/parrot-ui';

export const Footer = styled.div`
  border-top: 1px solid ${theme.colors.gray_lighter};
  max-height: 152px;
  transition: 0.1s all ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px 16px 16px;

  .quick_actions {
    align-items: center;
    display: flex;
    margin-bottom: 13px;
    margin-top: 13px;

    > div + div {
      margin-left: 16px;
    }
  }

  ${(props) =>
    props.size <= 32 &&
    css`
      .quick_actions {
        justify-content: space-between !important;
        > div + div {
          margin-left: 8px;
        }
      }
    `}
`;

export const VbxCollapse = styled.section`
  width: 100%;
  transition: 0.2s all;

  ${(props) =>
    props.isCollapsed &&
    css`
      width: 70%;
      padding-right: 17px;

      .button__Send {
        display: none;
        pointer-events: none;
      }
    `}
`;
