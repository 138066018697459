import { Button, Typography } from '@kea-inc/parrot-ui';

import { AnnounceBadge, CenterOverlay } from '@components';
import { closeTaxAndFeesOverlay } from '@modules/order/api';
import { formatList, formatPrice } from '@utils';
import * as cartSelectors from '@modules/cart/selectors';
import { useAppSelector } from '@store';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as S from './styles';

function TaxAndFeesOverlay() {
  const taxes = useAppSelector(cartSelectors.selectTaxes);
  const fees = useAppSelector(cartSelectors.selectFees);
  const taxAndFees = useAppSelector(cartSelectors.selectTaxAndFees);
  const customerHandoffCharge = useAppSelector(
    cartSelectors.selectCustomerHandoffCharge,
  );

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  function listTaxesAndFees() {
    const taxList =
      taxes?.map((tax) => `${formatPrice(tax.tax)} ${tax.label}`) || [];
    const feesList =
      fees?.map((fee) => `${formatPrice(fee.amount)} ${fee.description}`) || [];
    const deliveryFee =
      customerHandoffCharge && customerHandoffCharge > 0
        ? `${formatPrice(customerHandoffCharge)} Delivery Fee`
        : '';

    return `The tax and fees include ${formatList([
      ...taxList,
      ...feesList,
      deliveryFee,
    ])}`;
  }

  const totalMessage = `The total tax and fees are ${formatPrice(taxAndFees)}`;
  const handoffChargeMessage = `${formatPrice(
    customerHandoffCharge,
  )} Delivery Fee`;

  const handleAnnounceBadgeClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return (
    <CenterOverlay>
      <S.Container>
        <S.Header>
          <Typography variant="subheader" weight="heavy">
            Tax and Fees
          </Typography>

          <div>
            <AnnounceBadge
              variant="accent_2"
              message={listTaxesAndFees()}
              onClick={handleAnnounceBadgeClick(listTaxesAndFees())}
            >
              List
            </AnnounceBadge>
            <AnnounceBadge
              message={totalMessage}
              onClick={handleAnnounceBadgeClick(totalMessage)}
            >
              Total
            </AnnounceBadge>
          </div>
        </S.Header>

        <S.Content>
          <Typography weight="heavy">Tax</Typography>
          <ul>
            {taxes?.map((tax) => {
              const taxMessage = `${formatPrice(tax.tax)} ${tax.label}`;
              return (
                <li key={`${tax.label}-${tax.tax}`}>
                  <Typography>{tax.label}</Typography>
                  <AnnounceBadge
                    message={taxMessage}
                    onClick={handleAnnounceBadgeClick(taxMessage)}
                  >
                    {formatPrice(tax.tax)}
                  </AnnounceBadge>
                </li>
              );
            })}
          </ul>

          <Typography weight="heavy">Fees</Typography>
          <ul>
            {fees?.map((fee) => {
              const feeMessage = `${formatPrice(fee.amount)} ${
                fee.description
              }`;
              return (
                <li key={`${fee.description}-${fee.amount}`}>
                  <Typography>{fee.description}</Typography>
                  <AnnounceBadge
                    message={feeMessage}
                    onClick={handleAnnounceBadgeClick(feeMessage)}
                  >
                    {formatPrice(fee.amount)}
                  </AnnounceBadge>
                </li>
              );
            })}

            {customerHandoffCharge && customerHandoffCharge > 0 && (
              <li>
                <Typography>Delivery Fee</Typography>
                <AnnounceBadge
                  message={handoffChargeMessage}
                  onClick={handleAnnounceBadgeClick(handoffChargeMessage)}
                >
                  {formatPrice(customerHandoffCharge)}
                </AnnounceBadge>
              </li>
            )}
          </ul>
        </S.Content>

        <footer>
          <Button onClick={closeTaxAndFeesOverlay}>Close</Button>
        </footer>
      </S.Container>
    </CenterOverlay>
  );
}

export default TaxAndFeesOverlay;
