import { useRef, useState } from 'react';
import { FaPhoneSlash } from 'react-icons/fa';

import {
  handleHangupEndOfTask,
  handleEndOfTrainingTask,
} from '@utils/handleEndOfTask';
import { useDevice } from '@hooks';
import { useAppDispatch, useAppSelector } from '@store';

import { createOrderEvent } from '@modules/order/api';
import { getConferenceSid } from '@modules/taskrouter/selectors';
import { actions } from '@modules/device/device-slice';
import Confirmation from './Confirmation';
import * as S from './styles';
import { useConnector } from '../useConnector';

const HangupBadge = () => {
  const dispatch = useAppDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const badgeRef = useRef(null);
  const { isHangingUp } = useDevice().pending;

  const { selectors } = useConnector();
  const { isTrainingTask } = selectors;

  const openConfirmation = () => setIsConfirmationOpen(true);
  const closeConfirmation = () => setIsConfirmationOpen(false);

  const conferenceSid = useAppSelector(getConferenceSid);
  const handleHangup = () => {
    if (isTrainingTask) {
      handleEndOfTrainingTask(conferenceSid);
    } else {
      dispatch(actions.setHangingUp(true));
      // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
      createOrderEvent('agent_hung_up');
      handleHangupEndOfTask(conferenceSid);
    }
  };
  return (
    <>
      <S.Badge
        onClick={openConfirmation}
        variant="error"
        leftIcon={FaPhoneSlash}
        ref={badgeRef}
      >
        Hang up
      </S.Badge>

      <Confirmation
        action="hang up"
        anchor={badgeRef}
        open={isConfirmationOpen}
        onCancel={closeConfirmation}
        onConfirm={handleHangup}
        isLoading={isHangingUp}
        isDisabled={isHangingUp}
      />
    </>
  );
};

export default HangupBadge;
