import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { RootState } from '@store';

import { cartItemsAdapter } from './state';

export const {
  selectEntities: selectCartItems,
  selectAll: selectAllCartItems,
  selectById: selectCartItemById,
  selectIds: selectCartItemIds,
} = cartItemsAdapter.getSelectors();

const selectSelf = (state: RootState) => state.cart;

export const hasOngoingCartActionSelector = createDraftSafeSelector(
  selectSelf,
  (state) =>
    state.cartItems.isAddingCartItem ||
    state.cartItems.isUpdatingCartItem ||
    state.cartItems.isRemovingCartItem,
);

export const selectCurrentCartItemId = createDraftSafeSelector(
  selectSelf,
  (state) => state.cartItems.current,
);

export const selectCurrentCartItem = createDraftSafeSelector(
  selectSelf,
  (state) => state.cartItems.entities[state.cartItems.current || ''],
);

export const selectSubtotal = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    let subtotal = 0;

    if (state.featureFlags.store.useOrderService) {
      subtotal = state.cart.pricing.subtotal;
    }

    if (state.order.validatedBasket.subtotal) {
      subtotal = state.order.validatedBasket.subtotal as number;
    }

    return subtotal || selectCalculatedSubtotal(state);
  },
);

const selectCalculatedSubtotal = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    const itemEntities = state.menu.items.entities;
    const optionEntities = state.menu.options.entities;
    const cartItems = selectAllCartItems(state.cart.cartItems);

    return cartItems.reduce((acc, cartItem) => {
      const menuItem = itemEntities[cartItem.menuItemId];
      if (!menuItem) {
        return acc;
      }

      let { cost } = menuItem;

      cartItem.options?.forEach((cartOption) => {
        const option = optionEntities[cartOption.id];
        if (!option) {
          return;
        }

        cost += option.cost * cartOption.quantity;
      });

      return acc + cost * cartItem.quantity;
    }, 0);
  },
);

export const selectTotal = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.total;
    }

    const { total } = state.order.validatedBasket;
    if (total) {
      return total as number;
    }

    return 0;
  },
);

export const selectTaxAndFees = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.taxAndFees;
    }

    const { tax, totalfees, customerhandoffcharge } =
      state.order.validatedBasket;

    return (tax ?? 0) + (totalfees ?? 0) + (customerhandoffcharge ?? 0);
  },
);

export const selectDiscount = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.discount;
    }

    const { discount, coupondiscount } = state.order.validatedBasket;
    if (discount || coupondiscount) {
      return (coupondiscount ?? discount) as number;
    }

    return 0;
  },
);

export const selectCustomerHandoffCharge = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.customerHandoffCharge;
    }

    const { customerhandoffcharge } = state.order.validatedBasket;
    if (customerhandoffcharge) {
      return customerhandoffcharge as number;
    }

    return 0;
  },
);

export const selectTaxes = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.taxes;
    }

    const { taxes } = state.order.validatedBasket;
    if (taxes) {
      return taxes as Array<{
        label: string;
        tax: number;
      }>;
    }

    return [];
  },
);

export const selectFees = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    if (state.featureFlags.store.useOrderService) {
      return state.cart.pricing.fees;
    }

    const { fees } = state.order.validatedBasket;
    if (fees) {
      return fees as Array<{
        type: string;
        description?: string;
        amount: number;
      }>;
    }

    return [];
  },
);

export const selectCoupons = createDraftSafeSelector(
  selectSelf,
  (state) => state.pricing.coupons,
);

export const selectHasPreexistingCart = createDraftSafeSelector(
  selectSelf,
  (state) => state.hasPreexistingCart,
);

export const selectIsCartEmpty = createDraftSafeSelector(
  selectSelf,
  (state) => state.cartItems.ids.length === 0,
);

export const selectPreparationTimeRange = createDraftSafeSelector(
  selectSelf,
  (state) => state.preparationTimeRange,
);
