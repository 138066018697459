import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';

const actions = {
  [types.SURVEY_START_NEW_ORDER]: (state, action) =>
    produce(state, (survey) => {
      survey = { ...INITIAL_STATE };
      survey.orderId = action.payload;
      survey.show = true;
      return survey;
    }),

  [types.SURVEY_ORDER_SUBMITTED]: (state) =>
    produce(state, (survey) => {
      survey.wasSubmitted = true;
      return survey;
    }),

  [types.SURVEY_ORDER_FORWARDED]: (state) =>
    produce(state, (survey) => {
      survey.wasForwarded = true;
      return survey;
    }),

  [types.SURVEY_SET_PRIMARY_DISPOSITION]: (state, action) =>
    produce(state, (survey) => {
      survey.primaryDisposition = action.payload;
      return survey;
    }),

  [types.SURVEY_SET_SECONDARY_DISPOSITION]: (state, action) =>
    produce(state, (survey) => {
      survey.secondaryDisposition = action.payload;
      return survey;
    }),

  [types.SURVEY_SET_COMMENTS]: (state, action) =>
    produce(state, (survey) => {
      survey.comments = action.payload;
      return survey;
    }),

  [types.SURVEY_SUBMITTING]: (state) =>
    produce(state, (survey) => {
      survey.pending.isSubmittingSurvey = true;
      survey.errors.hasSubmitSurveyError = false;
      return survey;
    }),

  [types.SURVEY_SUBMITTED]: (state) =>
    produce(state, (survey) => {
      survey.pending.isSubmittingSurvey = false;
      survey.errors.hasSubmitSurveyError = false;
      survey.show = false;
      return survey;
    }),

  [types.SURVEY_NOT_SUBMITTED]: (state) =>
    produce(state, (survey) => {
      survey.pending.isSubmittingSurvey = false;
      survey.errors.hasSubmitSurveyError = true;
      survey.show = false;
      return survey;
    }),

  [types.SURVEY_TIMEOUT]: (state) =>
    produce(state, (survey) => {
      survey.show = false;
      return survey;
    }),
};

export const surveyReducer = createReducer(INITIAL_STATE, actions);
