import { useMemo } from 'react';

import { useOrder } from '@hooks';

import { createRepeatCartPhrase } from '@utils';
import { AnnounceBadge } from '@components';
import { useAppSelector } from '@store';
import { selectAllCartItems } from '@modules/cart/selectors';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';

export function RepeatCartBadge() {
  const { checklist } = useOrder();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const cartItems = useAppSelector((state) =>
    selectAllCartItems(state.cart.cartItems),
  );

  const repeatCartMessage = useMemo(
    () => createRepeatCartPhrase(cartItems),
    [cartItems],
  );

  const { cartHasItems } = checklist;

  const handleClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: repeatCartMessage },
    });
  };

  return (
    <AnnounceBadge
      message={repeatCartMessage}
      disabled={!cartHasItems}
      onClick={handleClick}
    >
      Repeat Order
    </AnnounceBadge>
  );
}
