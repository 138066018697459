import env from '@beam-australia/react-env';
import rum from '@rum';
import { useEffect } from 'react';

const rumEnvs = ['uat', 'production'];

export function StartReplayRecording() {
  const environment = env('ENVIRONMENT');

  useEffect(() => {
    if (rumEnvs.includes(environment)) {
      rum.startReplayRecording();
    } else {
      rum.stopReplayRecording();
    }

    return () => {
      rum.stopReplayRecording();
    };
  }, []);

  return null;
}
