import { ValidationErrorCode } from '@modules/order/types';

export const humanReadableErrorNames: Partial<
  Record<ValidationErrorCode, string>
> = {
  COMPLETE: 'Item Complete',
  EXCESS_SINGLE_ITEM_QUANTITY: 'Excess Quantity for Single Item',
  EXCESS_SINGLE_OPTION_QUANTITY: 'Excess Quantity for Single Option',
  EXCESS_TOTAL_ITEM_QUANTITY: 'Excess Total Item Quantity',
  EXCESS_TOTAL_OPTION_QUANTITY: 'Excess Total Option Quantity',
  EXCESS_UNIQUE_OPTIONS: 'Excess Unique Options',
  INSUFFICIENT_SINGLE_ITEM_QUANTITY: 'Insufficient Quantity for Single Item',
  INSUFFICIENT_SINGLE_OPTION_QUANTITY:
    'Insufficient Quantity for Single Option',
  INSUFFICIENT_TOTAL_OPTION_QUANTITY: 'Insufficient Total Option Quantity',
  INSUFFICIENT_UNIQUE_OPTIONS: 'Insufficient Unique Options',
  INVALID_ITEM_QUANTITY_INTERVAL: 'Invalid Item Quantity Range',
  INVALID_OPTION_QUANTITY_INTERVAL: 'Invalid Option Quantity Range',
  ITEM_OUTSIDE_AVAILABLE_TIME: 'Item Not Available at This Time',
  ITEM_OUT_OF_STOCK: 'Item Out of Stock',
  MODIFIER_OUTSIDE_AVAILABLE_TIME: 'Modifier Not Available at This Time',
  OPTION_OUT_OF_STOCK: 'Option Out of Stock',
  OPTION_OUTSIDE_AVAILABLE_TIME: 'Option Not Available at This Time',
  REQUIRED_MODIFIER_MISSING_OPTIONS: 'Missing Options for Required Modifier',
  ITEM_UNAVAILABLE_IN_HANDOFF_MODE:
    'Item Not Available in Selected Handoff Mode',
};

export const humanReadableErrors: Partial<Record<ValidationErrorCode, string>> =
  {
    COMPLETE: 'The item is complete.',
    EXCESS_SINGLE_ITEM_QUANTITY:
      'You have added too many of this item to the cart.',
    EXCESS_SINGLE_OPTION_QUANTITY:
      'You have chosen too many of the same option for this item.',
    EXCESS_TOTAL_ITEM_QUANTITY:
      'The total number of items in the cart exceeds the limit.',
    EXCESS_TOTAL_OPTION_QUANTITY:
      'The total number of options selected exceeds the limit.',
    EXCESS_UNIQUE_OPTIONS:
      'You have selected too many options for this modifier.',
    INSUFFICIENT_SINGLE_ITEM_QUANTITY:
      'You need to add more of this item to the cart.',
    INSUFFICIENT_SINGLE_OPTION_QUANTITY:
      'You need to select more of this option for the item.',
    INSUFFICIENT_TOTAL_OPTION_QUANTITY:
      'You need to select more options in total.',
    INSUFFICIENT_UNIQUE_OPTIONS: 'Modifier requires more options.',
    INVALID_ITEM_QUANTITY_INTERVAL:
      'The quantity of this item must be in the allowed range.',
    INVALID_OPTION_QUANTITY_INTERVAL:
      'The number of this option must be within the allowed range.',
    ITEM_OUTSIDE_AVAILABLE_TIME:
      'This item is not available at the selected time.',
    ITEM_OUT_OF_STOCK: 'This item is currently out of stock.',
    MODIFIER_OUTSIDE_AVAILABLE_TIME:
      'This modifier is not available at the selected time.',
    OPTION_OUT_OF_STOCK: 'This option is currently out of stock.',
    OPTION_OUTSIDE_AVAILABLE_TIME:
      'This option is not available at the selected time.',
    REQUIRED_MODIFIER_MISSING_OPTIONS:
      'You must select options for the required modifier.',
    ITEM_UNAVAILABLE_IN_HANDOFF_MODE:
      'This item is not available for the selected handoff mode.',
  };
