import { Reservation } from 'twilio-taskrouter';
import camelcaseKeys from 'camelcase-keys';

import store, { RootState } from '@store';
import { taskrouter } from '@services';
import logger from '@logger';
import rum from '@rum';
import * as clockSelectors from '@modules/clock/selectors';
import * as clockAPI from '@modules/clock/api';
import * as orderAPI from '@modules/order/api';
import * as featureFlagsActions from '@modules/featureFlags/actions';
import { fetchAddressById } from '@modules/delivery/actions';

import { TaskAttributes } from './types';
import { actions } from './slice';
import {
  acceptTask,
  connectConference,
  fetchWorkerToken,
  resetTaskrouter,
} from './actions';
import * as selectors from './selectors';

export function startWorkerListeners(state: RootState) {
  taskrouter.setWorkerEvent('ready', (worker) => {
    store.dispatch(actions.setWorkerSid(worker.sid));
  });

  taskrouter.setWorkerEvent('reservationCreated', (reservation) => {
    if (!clockSelectors.isClockingOut() && clockSelectors.isClockedIn()) {
      taskrouter.updateReservation(reservation);
      startReservationListeners();

      store.dispatch(
        actions.onReservationCreated({
          reservationSid: reservation.sid,
          taskSid: reservation.task.sid,
          taskStatus: reservation.task.status,
          taskAttributes: camelcaseKeys(reservation.task.attributes, {
            deep: true,
          }) as TaskAttributes,
        }),
      );

      orderAPI.createOrderEvent('task_received_on_uoa', {
        agent: reservation?.workerSid,
        sid: reservation?.sid,
      });
      orderAPI.createAnalyticsEvent('task_received_on_uoa', {
        agent: reservation?.workerSid,
        sid: reservation?.sid,
      });

      store.dispatch(acceptTask(reservation));
    }
  });

  taskrouter.setWorkerEvent('tokenExpired', () => {
    const workerSid = selectors.getWorkerSid(state);

    if (workerSid) {
      fetchWorkerToken({ workerSid, renew: true });
    } else {
      clockAPI.clockOut();
      logger.error(
        'Worker token expired and no worker sid was found. Clocking out.',
      );
    }
  });

  taskrouter.setWorkerEvent('error', () => {
    store.dispatch(actions.onUnhandledError());
    store.dispatch(resetTaskrouter());
  });

  taskrouter.setWorkerEvent('activityUpdated', () => {});

  taskrouter.setWorkerEvent('attributesUpdated', () => {});

  taskrouter.setWorkerEvent('disconnected', () => {});

  taskrouter.setWorkerEvent('reservationFailed', () => {});

  taskrouter.setWorkerEvent('tokenUpdated', () => {});
}

export function startReservationListeners() {
  taskrouter.setReservationEvent('accepted', onReservationAccepted);

  taskrouter.setReservationEvent('canceled', () => {
    store.dispatch(actions.onReservationCanceled());
    taskrouter.reset();
  });

  taskrouter.setReservationEvent('timeout', () => {
    store.dispatch(actions.onReservationTimeout());
    resetTaskrouter({ shouldCompleteTask: false });
  });

  taskrouter.setReservationEvent('rejected', ({ canceledReasonCode }) => {
    logger.info('Reservation rejected', { canceledReasonCode });
    taskrouter.reset();
  });

  taskrouter.setReservationEvent('rescinded', ({ canceledReasonCode }) => {
    logger.info('Reservation rescinded', { canceledReasonCode });
    taskrouter.reset();
  });

  taskrouter.setReservationEvent('wrapup', () => {
    logger.info('Reservation wrap up');
    taskrouter.reset();
  });

  taskrouter.setReservationEvent('completed', () => {
    logger.info('Reservation completed');
    taskrouter.reset();
  });
}

function triggerFetchAddressById(handoffMode: string, addressId?: string) {
  if (addressId && handoffMode === 'delivery') {
    store.dispatch(fetchAddressById(addressId));
  }
}

export function onReservationAccepted(reservation: Reservation) {
  taskrouter.updateReservation(reservation);
  orderAPI.setOrderId(reservation.task.attributes.order_id);
  const taskAttributes = camelcaseKeys(reservation.task.attributes, {
    deep: true,
  }) as TaskAttributes;

  const { brand, store: taskAttributesStore } = taskAttributes;

  store.dispatch(
    featureFlagsActions.getStoreFeatureFlags({
      key: `store:${brand}-${taskAttributesStore.franchiseStoreNumber}`,
      name: `store:${brand}-${taskAttributesStore.franchiseStoreNumber}`,
      custom: {
        source: 'Unified Ordering Application',
      },
    }),
  );

  store.dispatch(
    featureFlagsActions.getBrandFeatureFlags({
      key: `store:${brand}`,
      name: `store:${brand}`,
      custom: {
        source: 'Unified Ordering Application',
      },
    }),
  );

  triggerFetchAddressById(
    reservation.task.attributes.handoff_mode,
    reservation.task.attributes.address_id,
  );
  store.dispatch(actions.onAcceptedTask(taskAttributes));
  store.dispatch(connectConference());

  logger.action('Accept task');
  rum.addAction('task', {
    ...reservation,
    actionState: 'ACCEPTED',
  });
}
