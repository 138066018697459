import * as notifications from '@modules/notifications/api';

export function surveyNotSubmittedError(error) {
  notifications.error({
    title: 'Unable to submit survey',
    message:
      'Thank you for trying to help us but an error occurred while submitting the survey',
    error,
  });
}
