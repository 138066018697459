import styled, { keyframes } from 'styled-components';

import { ImSpinner2 } from 'react-icons/im';
import { theme, Card } from '@kea-inc/parrot-ui';

export const Container = styled(Card).attrs({
  selected: true,
  radius: 8,
})`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: ${theme.elevations[3]};
  }
`;

export const Content = styled.div`
  margin: 12px 2px 16px 2px;
  padding: 0 2px 0 2px;
  transition: 0.1s all ease-in-out;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid ${theme.colors.gray_lighter};
  height: 100%;
  max-height: 152px;
  transition: 0.1s all ease-in-out;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;

  .quick_actions {
    align-items: center;
    display: flex;
    margin-bottom: 13px;

    > div + div {
      margin-left: 16px;
    }
  }
`;

export const VbxCollapse = styled.section`
  width: 100%;
  transition: 0.2s all;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 48,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin: 0 auto;
`;
