import { FetchStatus } from '@modules/menu/types';

type GetIsItemRequirementsFulfilledReturn = {
  isRequirementsFulfilled: boolean;
  erroredModifierId: string | null;
};

export type ModifierConfig = {
  id: string;
  parentId: string;
  minOptions: number | null;
  maxOptions: number | null;
  minQuantity: number | null;
  maxQuantity: number | null;
  minQuantityPerOption: number | null;
  maxQuantityPerOption: number | null;
  isPromptRequired: boolean;
  required?: boolean;
  status: FetchStatus;
};

export type AddedOption = {
  quantity: number;
  parentId: string;
  id: string;
  isFullyAdded: boolean;
  isLoadingNestedModifiers: boolean;
};

export function getIsItemRequirementsFulfilled(params: {
  currentItemId: string | null;
  modifiers: ModifierConfig[];
  promptedModifiers: Record<string, boolean>;
  noSelectionModifiers: Record<string, boolean>;
  addedOptionsById: Record<string, AddedOption>;
}): GetIsItemRequirementsFulfilledReturn {
  const { currentItemId, modifiers, addedOptionsById, noSelectionModifiers } =
    params;

  if (!currentItemId) {
    return {
      isRequirementsFulfilled: false,
      erroredModifierId: null,
    };
  }

  const optionsByParentId = Object.values(addedOptionsById).reduce(
    (acc, option) => {
      if (!acc[option.parentId]) {
        acc[option.parentId] = [];
      }

      acc[option.parentId].push(option);

      return acc;
    },
    {} as Record<string, AddedOption[]>,
  );

  for (const modifier of modifiers) {
    if (modifier.status === 'pending') {
      return {
        isRequirementsFulfilled: false,
        erroredModifierId: null,
      };
    }

    const modifierAddedOptions = optionsByParentId[modifier.id] || [];

    const totalQuantity = modifierAddedOptions.reduce(
      (acc, option) => acc + option.quantity,
      0,
    );

    const {
      minOptions,
      maxOptions,
      minQuantity,
      maxQuantity,
      minQuantityPerOption,
      maxQuantityPerOption,
    } = modifier;

    // Do not check nested modifiers which parent is not added
    if (modifier.parentId !== currentItemId) {
      const isParentOptionAdded = !!addedOptionsById[modifier.parentId];

      if (!isParentOptionAdded) {
        // We should pass the check for this modifier if the above condition is true
        // eslint-disable-next-line no-continue
        continue;
      }
    }

    const hasNestedModifiersLoading = modifierAddedOptions.some(
      (option) => option.isLoadingNestedModifiers,
    );

    if (hasNestedModifiersLoading) {
      return {
        isRequirementsFulfilled: false,
        erroredModifierId: modifier.id,
      };
    }

    if (modifier.isPromptRequired && modifier.required) {
      const hasFullyAddedOption = modifierAddedOptions.some(
        (option) => option.isFullyAdded,
      );

      if (!hasFullyAddedOption) {
        return {
          isRequirementsFulfilled: false,
          erroredModifierId: modifier.id,
        };
      }
    }

    if (modifier.isPromptRequired && !modifier.required) {
      const isNoSelection = noSelectionModifiers[modifier.id] ?? false;
      const hasFullyAddedOption = modifierAddedOptions.some(
        (option) => option.isFullyAdded,
      );

      if (!hasFullyAddedOption && !isNoSelection) {
        return {
          isRequirementsFulfilled: false,
          erroredModifierId: modifier.id,
        };
      }
    }

    if (modifier.required && !modifierAddedOptions.length) {
      return {
        isRequirementsFulfilled: false,
        erroredModifierId: modifier.id,
      };
    }

    if (minOptions && minOptions > modifierAddedOptions.length) {
      return { isRequirementsFulfilled: false, erroredModifierId: modifier.id };
    }

    if (maxOptions && maxOptions < modifierAddedOptions.length) {
      return { isRequirementsFulfilled: false, erroredModifierId: modifier.id };
    }

    if (minQuantity && minQuantity > totalQuantity) {
      return { isRequirementsFulfilled: false, erroredModifierId: modifier.id };
    }

    if (maxQuantity && maxQuantity < totalQuantity) {
      return { isRequirementsFulfilled: false, erroredModifierId: modifier.id };
    }

    if (minQuantityPerOption) {
      for (const option of modifierAddedOptions) {
        if (minQuantityPerOption > option.quantity) {
          return {
            isRequirementsFulfilled: false,
            erroredModifierId: modifier.id,
          };
        }
      }
    }

    if (maxQuantityPerOption) {
      for (const option of modifierAddedOptions) {
        if (maxQuantityPerOption < option.quantity) {
          return {
            isRequirementsFulfilled: false,
            erroredModifierId: modifier.id,
          };
        }
      }
    }
  }

  return {
    isRequirementsFulfilled: true,
    erroredModifierId: null,
  };
}
