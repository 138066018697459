import env from '@beam-australia/react-env';
import logger from '@logger';
import { Call, Device, PreflightTest } from '@twilio/voice-sdk';
import { IExtendedDeviceOptions } from '@twilio/voice-sdk/es5/twilio/device';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';

import { FakeTwilioDevice, fakeReport } from './twilio-utils';

class TwilioDevice {
  device: Device | null = null;

  deviceEvents: string[] = [];

  callEvents: string[] = [];

  call: Call | null = null;

  token: string | null = null;

  preflightStarted: boolean = false;

  skipPreflight?: string = env('SKIP_PREFLIGHT');

  setup(edge: string[] | string) {
    if (this.device || !this.token) {
      return;
    }

    const deviceOptions: IExtendedDeviceOptions = {
      appName: 'uoa',
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      forceAggressiveIceNomination: true,
      logLevel: 'debug',
      closeProtection: true,
      edge,
    };

    if (this.skipPreflight) {
      // @ts-ignore - Fake implementation of Twilio
      this.device = new FakeTwilioDevice();
      this.device?.register();
      // @ts-ignore - Exposing the device when testing so we can control it
      window.twilioDevice = this.device;
    } else {
      this.device = new Device(this.token, deviceOptions);
    }
  }

  setDeviceEvent(name: string, cb: (data: any) => void) {
    const alreadyExists = this.deviceEvents.includes(name);
    if (alreadyExists || !this.device) {
      logger.info(
        `Device | setEvent: ${name} | already exists or device is null`,
      );
      return;
    }

    this.deviceEvents.push(name);
    logger.info(`Device | setEvent: ${name}`);

    this.device.on(name, (data) => {
      logger.info(`Device event received: ${name}`);
      cb(data);
    });
  }

  setCallEvent(name: string, cb: (data: any) => void) {
    const alreadyExists = this.callEvents.includes(name);
    if (alreadyExists || !this.call) {
      logger.info(`Call | setEvent: ${name} | already exists or call is null`);
      return;
    }

    this.callEvents.push(name);
    logger.info(`Call | setEvent: ${name}`);

    this.call.on(name, (data) => {
      logger.info(`Call event received: ${name}`);
      cb(data);
    });
  }

  reset() {
    if (this.device) {
      this.device.destroy();
      this.device = null;
    }

    this.deviceEvents = [];
    this.preflightStarted = false;
  }

  setToken(token: string) {
    this.token = token;

    if (this.device) {
      this.device.updateToken(token);
    }
  }

  setCall(call: Call | null) {
    this.call = call;
  }

  runPreflightTest(
    {
      onSuccess,
      onError,
    }: {
      onSuccess: (report: PreflightTest.Report) => void;
      onError: (error: TwilioError | DOMException) => void;
    },
    edge?: string,
  ) {
    this.preflightStarted = true;

    if (this.skipPreflight) {
      onSuccess(fakeReport as PreflightTest.Report);
    } else {
      if (!this.token) {
        onError(new DOMException('No token found'));
        return;
      }

      const preflightTest = Device.runPreflight(this.token, {
        fakeMicInput: false,
        edge,
      });

      preflightTest.on(
        PreflightTest.Status.Completed,
        (report: PreflightTest.Report) => {
          onSuccess(report);
        },
      );

      preflightTest.on(
        PreflightTest.Status.Failed,
        (error: TwilioError | DOMException) => {
          onError(error);
        },
      );
    }
  }

  async register() {
    if (!this.device) {
      return;
    }

    if (this.device.state === 'unregistered') {
      await this.device.register();
    }
  }

  async unregister() {
    if (!this.device) {
      return;
    }

    if (this.device.state === 'registered') {
      await this.device.unregister();
    }
  }
}

export default new TwilioDevice();
