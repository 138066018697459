/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Card,
  CardAtoms,
  Chip,
  Typography,
  AlertBar,
} from '@kea-inc/parrot-ui';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {
  AiFillClockCircle,
  AiOutlineDownload,
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlinePause,
} from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';

import * as clockAPI from '@modules/clock/api';

import { useDevice } from '@hooks';

import {
  edges,
  totalsTranslation,
  stats,
  round,
  capitalizeFirstLetter,
  prettyWarning,
  downloadPreflightAsJson,
} from '../utils';
import * as S from './styles';

export function PreflightReport({ open, setOpen }) {
  const { preflight, isPendingPreflight } = useDevice();

  const handleClockIn = () => {
    setOpen(false);
    clockAPI.clockIn();
  };

  if (!preflight) return null;

  return (
    <>
      {open && <S.Background onClick={() => setOpen(false)} />}
      <S.Container open={open}>
        <S.Header>
          <Typography variant="headline" weight="heavy">
            Preflight Report
          </Typography>

          <Button
            onClick={handleClockIn}
            LeftIcon={AiFillClockCircle}
            disabled={isPendingPreflight}
          >
            Clock in now
          </Button>
        </S.Header>

        <S.Content>
          <S.GeneralInfo>
            <Chip selected leftIcon={FaMapMarkerAlt}>
              {edges[preflight.edge]}
            </Chip>
            <Chip>{preflight.callSid}</Chip>
          </S.GeneralInfo>

          <InformationCards totalsReport={preflight.totals} />

          <S.CallQuality>
            <AlertBar
              title={capitalizeFirstLetter(preflight.callQuality)}
              variant={
                preflight.callQuality === 'excellent' ? 'success' : 'error'
              }
            />
          </S.CallQuality>

          <StatsCards statsReport={preflight.stats} />

          {preflight.warnings.length > 0 && (
            <Warnings warnings={preflight.warnings} />
          )}
        </S.Content>

        <S.Footer>
          <Button
            onClick={() => downloadPreflightAsJson(preflight)}
            RightIcon={AiOutlineDownload}
          >
            Download
          </Button>
          <Button onClick={() => setOpen(false)} danger RightIcon={IoClose}>
            Close
          </Button>
        </S.Footer>
      </S.Container>
    </>
  );
}

function InformationCards({ totalsReport }) {
  return (
    <S.TotalsInfo>
      {Object.keys(totalsTranslation).map((total) => (
        <Card height={100} padded vertical key={total}>
          <CardAtoms.Title
            variant="caption"
            color="black_light"
            dangerouslySetInnerHTML={{ __html: totalsTranslation[total] }}
          />
          <CardAtoms.Description variant="subheader" weight="heavy">
            {round(totalsReport[total])}
          </CardAtoms.Description>
        </Card>
      ))}
    </S.TotalsInfo>
  );
}

function StatsCards({ statsReport }) {
  return (
    <S.StatsCards>
      <Typography variant="title" weight="heavy">
        Stats
      </Typography>

      {Object.keys(statsReport).map((stat) => (
        <Card vertical key={stat}>
          <CardAtoms.Content>
            <CardAtoms.Title variant="subheader" weight="heavy">
              {stats[stat].title}
            </CardAtoms.Title>
            <CardAtoms.Description color="black_light">
              {stats[stat].description}
            </CardAtoms.Description>
          </CardAtoms.Content>
          <CardAtoms.Footer position="left">
            <Chip leftIcon={AiOutlinePause}>
              Average: {round(statsReport[stat].average)}
            </Chip>
            <Chip leftIcon={AiOutlineArrowUp}>
              Max: {round(statsReport[stat].max)}
            </Chip>
            <Chip leftIcon={AiOutlineArrowDown}>
              Min: {round(statsReport[stat].min)}
            </Chip>
          </CardAtoms.Footer>
        </Card>
      ))}
    </S.StatsCards>
  );
}

function Warnings({ warnings }) {
  return (
    <S.Warnings>
      <Typography variant="title" weight="heavy">
        Warnings
      </Typography>

      {warnings.map((warning) => (
        <AlertBar
          key={warning}
          title={prettyWarning(warning.name)}
          description={warning.name}
          variant="error"
        />
      ))}
    </S.Warnings>
  );
}
