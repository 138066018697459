import { dispatch } from '@store';
import { v4 as uuid } from 'uuid';

import * as actions from './actions';
import { notificationTypes } from './state';

export function emit(data) {
  const id = uuid();
  dispatch(actions.notificationEmit({ id, ...data }));
}

export function dismiss(id) {
  dispatch(actions.notificationDismiss(id));
}

export function error(data) {
  emit({
    type: notificationTypes.ERROR,
    ...data,
  });
}

export function info(data) {
  emit({
    type: notificationTypes.INFO,
    ...data,
  });
}

export function reset() {
  dispatch(actions.reset());
}
