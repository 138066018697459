import { createEntityAdapter } from '@reduxjs/toolkit';
import { Coupon, CouponState } from './types';

export const couponAdapter = createEntityAdapter<Coupon>({
  selectId: (coupon) => coupon.code,
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

export const initialCouponState: CouponState = couponAdapter.getInitialState({
  isFetching: false,
  hasFetchError: false,
  current: null,
  searchTerm: null,
  savedCoupon: null,
  isSaving: false,
  isRemoving: false,
  isOverlayOpen: false,
});
