import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import store, { dispatch } from '@store';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import logger from '@logger';
import { Actions, DeliveryInfo } from './types';

import * as utils from './utils';

export async function fetchSoundboardPrompts({
  platformName,
  storeId,
  brandId,
}: {
  platformName: string;
  storeId: string;
  brandId: string;
}) {
  dispatch(Actions.VOICEPANE_FETCHING);

  const { useSoundboardV2 } = getStoreFeatureFlags(store.getState());

  if (useSoundboardV2) {
    try {
      const soundboardEntries = await utils.fetchSoundboardEntries();
      dispatch(Actions.VOICEPANE_SOUNDBOARD_ENTRIES_FETCHED, {
        soundboardEntries,
      });
      setInitialVoicePane();
    } catch (error) {
      const typedError = error as Error;
      logger.error(
        `Unable to fetch soundboard entries: ${
          typedError?.message || JSON.stringify(error)
        }`,
      );

      dispatch(Actions.VOICEPANE_NOT_FETCHED);
    }
  } else {
    try {
      const soundboardPrompts = await utils.fetchSoundboardPrompts({
        platformName,
        storeId,
        brandId,
      });
      dispatch(Actions.VOICEPANE_PROMPTS_FETCHED, { soundboardPrompts });
      setInitialVoicePane();
    } catch {
      dispatch(Actions.VOICEPANE_NOT_FETCHED);
    }
  }
}

export function setVoicePaneContext(contexts: string[]) {
  dispatch(Actions.VOICEPANE_SET_CONTEXT_PROMPTS, { contexts });
}

export function setVoicePaneCategory(category: string) {
  dispatch(Actions.VOICEPANE_SET_CATEGORY_PROMPTS, { category });
}

export function setInitialVoicePane() {
  dispatch(Actions.VOICEPANE_SET_INITIAL);
}

export function filterPrompts(searchTerm: string) {
  dispatch(Actions.VOICEPANE_FILTER_PROMPTS, { searchTerm });
}

export function setVoicePaneDeliveryTime(deliveryInfo: DeliveryInfo) {
  dispatch(Actions.VOICEPANE_SET_DELIVERY_TIME, {
    deliveryInfo,
    timezone: deprecatedGetStoreTimezone(),
  });
}

export function reset() {
  dispatch(Actions.VOICEPANE_RESET);
}
