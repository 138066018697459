import styled, { css, keyframes } from 'styled-components';
import { Card, theme } from '@kea-inc/parrot-ui';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FiChevronUp } from 'react-icons/fi';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled(Card).attrs({
  selected: true,
})`
  min-height: 48px;
  max-height: ${(props) => (props.isOpen ? 350 : 48)}px;
  margin-top: 12px;
  border-radius: 8px;
  padding: 0 18px;
  flex-direction: column;
  overflow-y: ${(props) => (props.isOpen ? 'scroll' : 'hidden')};

  ::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    box-shadow: ${theme.elevations[3]};
  }
`;

export const Header = styled.header`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;
    align-items: center;
  }

  .right {
    transition: 0.2s all;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .status__all {
    margin-left: 8px;
    margin-top: 1.5px;
    font-size: 18px;
  }
`;

export const Status = styled(AiFillCheckCircle)`
  color: ${theme.colors.success};
  font-size: 22px;
  transition: 0.2s all;

  ${(props) =>
    !props.complete &&
    css`
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.warning};
      font-size: 20px;
      margin-left: 1.5px;
      border-radius: 50%;
    `}
`;

export const OpenClose = styled(FiChevronUp)`
  color: ${theme.colors.gray_dark};
  margin-left: 8px;
  transition: 0.2s all;
  ${(props) =>
    props.isopen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const Content = styled.section``;

export const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .status {
    margin-right: 14px;
    transform: translateY(1.5px);
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 12,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
