export const INITIAL_STATE = {
  prompts: {
    byContext: {},
    byCategory: {},
  },
  currentContext: [],
  filteredPrompts: null,
  categoryBased: null,
  pending: {
    isFetchingPrompts: false,
  },
  errors: {
    hasFetchPromptsError: false,
  },
};
