import { useEffect, useMemo } from 'react';

import { useAppSelector } from '@store';
import * as orderAPI from '@modules/order/api';
import * as paymentSelectors from '@modules/payment/selectors';
import { isPayingByCard } from '@modules/order/selectors';
import { TaskAttributes } from '@modules/taskrouter/types';
import * as couponsSelectors from '@modules/coupons/selectors';

import { useOrder, usePayment } from '@hooks';

import PaybotOverlay from './PaybotOverlay';
import TipsOverlay from './TipsOverlay';
import ManualPaymentOverlay from './ManualPaymentOverlay';
import SubmitOverlay from './SubmitOverlay';
import { TtFAOverlay } from './TtFAOverlay';
import HumanRequestOverlay from './HumanRequestOverlay';
import CouponsOverlay from './CouponsOverlay';
import TaxAndFeesOverlay from './TaxAndFeesOverlay';

export function Overlays() {
  const { creditCard } = usePayment();
  const taskAttributes = useAppSelector(
    (state) => state.taskrouter.taskAttributes,
  );
  const talkToHumanIntent = getTalkToHumanIntent(taskAttributes);
  const isCouponOverlayOpen = useAppSelector(couponsSelectors.isOverlayOpen);
  const { isTaxAndFeesOverlayOpen } = useOrder();

  const { success } = useMemo(
    () => paymentSelectors.getFieldsStatuses(),
    [creditCard],
  );

  useEffect(() => {
    if (success && isPayingByCard()) {
      orderAPI.submit();
    }
  }, [success]);

  return (
    <>
      <PaybotOverlay />
      <TipsOverlay />
      <ManualPaymentOverlay />
      <SubmitOverlay />
      {isCouponOverlayOpen ? <CouponsOverlay /> : null}
      {talkToHumanIntent ? <HumanRequestOverlay /> : <TtFAOverlay />}
      {isTaxAndFeesOverlayOpen ? <TaxAndFeesOverlay /> : null}
    </>
  );
}

function getTalkToHumanIntent(taskAttributes: TaskAttributes | null) {
  if (!taskAttributes) {
    return false;
  }

  // NEW IMPLEMENTATION
  if (taskAttributes.isLiveAgent !== undefined) {
    return taskAttributes.isLiveAgent;
  }

  // FALLBACK
  if (!taskAttributes.context) {
    return false;
  }

  return taskAttributes.context === 'LIVE_AGENT';
}
