import { useAppSelector } from '@store';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import * as deviceActions from '@modules/device/device-actions';
import * as transcriptsAPI from '@modules/transcripts/api';
import * as orderAPI from '@modules/order/api';

export function useConnector() {
  const selectors = {
    taskrouterBrand: useAppSelector(taskrouterSelectors.getBrand),
    hasOngoingTask: useAppSelector(taskrouterSelectors.getHasOngoingTask),

    handOffMode: useAppSelector(({ order }) => order.details.handOffMode),

    isMuted: useAppSelector(deviceSelectors.isDeviceMuted),
  };

  const actions = {
    stopCurrentVbx: deviceActions.stopCurrentVbx,
    closeStream: transcriptsAPI.closeStream,
    buildChecklist: orderAPI.buildChecklist,
  };

  return { selectors, actions };
}
