import styled, { css } from 'styled-components';

export const Container = styled.form.attrs({
  className: 'vbx__Controller',
})`
  display: flex;
  align-items: center;

  button {
    margin-left: 16px;
    margin-top: -24px;
    min-width: 65.38px;

    ${(props) =>
      props.buttonDown &&
      css`
        transform: translateY(18px);
      `}
  }
`;
