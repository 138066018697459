import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';

const actions = {
  [types.AGENT_FETCHING]: (state) =>
    produce(state, (agents) => {
      agents.pending.isFetching = true;
      agents.errors.hasFetchError = false;
      return agents;
    }),

  [types.AGENT_FETCHED]: (state, action) =>
    produce(state, (agents) => {
      agents.pending.isFetching = false;
      agents.errors.hasFetchError = false;

      const { agent, brands } = action.payload;
      agents.byId[agent.id] = { ...agent, brands };
      agents.current = agent.id;

      return agents;
    }),

  [types.AGENT_NOT_FETCHED]: (state) =>
    produce(state, (agents) => {
      agents.pending.isFetching = false;
      agents.errors.hasFetchError = true;
      return agents;
    }),

  [types.AGENT_RESET]: (state) => produce(state, () => INITIAL_STATE),
};

export const agentReducer = createReducer(INITIAL_STATE, actions);
