import { useAppSelector } from '@store';

import * as orderAPI from '@modules/order/api';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';

type CreateAnalyticsEventMetadata = {
  id: string;
  message: string;
  categoryId?: string;
  itemId?: string;
  modifierId?: string;
};

type CreateAnalyticsEventParams =
  | {
      eventName: typeof EVENT_NAME_BUTTONS.GENERAL;
      metadata: Pick<CreateAnalyticsEventMetadata, 'message'>;
    }
  | {
      eventName: typeof EVENT_NAME_BUTTONS.SPECIFIC;
      metadata: CreateAnalyticsEventMetadata;
    };

export const useButtonAnalyticsTracker = () => {
  const storeId = useAppSelector(taskrouterSelectors.getStoreId);
  const brand = useAppSelector(taskrouterSelectors.getBrand);

  const handleAnalyticsTrackButton = (params: CreateAnalyticsEventParams) => {
    const { eventName, metadata } = params;

    const specificMetadata =
      eventName === EVENT_NAME_BUTTONS.SPECIFIC
        ? { brandId: brand?.id, storeId }
        : { id: EVENT_NAME_BUTTONS.GENERAL };

    orderAPI.createAnalyticsEvent(eventName, {
      ...metadata,
      ...specificMetadata,
    });
  };

  return { handleAnalyticsTrackButton };
};
