import { Card, theme } from '@kea-inc/parrot-ui';
import { ImSpinner2 } from 'react-icons/im';
import styled, { keyframes } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 560px;
  width: 450px;
`;

export const Header = styled.header`
  &,
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  margin-bottom: 1.5rem;
`;

export const Coupons = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  padding: 1rem 0.5rem;
  overflow-y: scroll;

  > li {
    width: 98%;
    cursor: pointer;
  }
`;

export const Coupon = styled(Card)<{ onClick?: () => void }>`
  display: flex;
  gap: 1rem;
  padding: 0.75rem;

  border: ${({ selected }) =>
    selected ? `1px solid ${theme.colors.primary}` : 'none'};
`;

export const CouponCode = styled.div`
  align-self: center;

  > p {
    color: ${theme.colors.gray_darker};
  }
`;

export const Divider = styled.div`
  width: 1px;
  border: 1px dashed ${theme.colors.gray_light};
  height: calc(100% + 1.1rem);
  margin: -0.5rem -0.3rem;
`;

export const CouponContent = styled.div`
  flex: 1;

  > p {
    font-size: 1rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.25rem;

    > span {
      color: ${theme.colors.gray_darker};
    }
  }
`;

export const ButtonsWrapper = styled.footer`
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.colors.gray_light};
  padding-top: 1rem;
  width: 100%;
  margin-top: auto;

  .button__Remove {
    margin-right: auto;
    p {
      color: ${theme.colors.error};
    }
    &:disabled {
      p {
        color: ${theme.colors.gray_light};
      }
    }
  }

  .button__Cancel {
    margin-right: 0.5rem;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  'data-testid': 'loading-icon',
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;

export const SearchContainer = styled.div`
  padding: 0.5rem;
`;
