import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useClearCache } from 'react-clear-cache';

import { CLOCK_STATUS } from '@modules/clock/state';
import * as notifications from '@modules/notifications/api';

import { Loading } from '@components';
import { useClock } from '@hooks';

export function CacheProvider({ children }) {
  const { status } = useClock();
  const { isLatestVersion, loading, emptyCacheStorage } = useClearCache({
    duration: 1000 * 60 * 60, // 1 hour
  });

  useEffect(() => {
    if (!isLatestVersion && status === CLOCK_STATUS.CLOCK_OUT) {
      notifications.info({
        title: 'New version available',
        message: 'Please click the button below to update UOA',
        dismissText: 'Update',
        onDismiss: emptyCacheStorage,
      });
    }
  }, [isLatestVersion, loading]);

  return loading ? <Loading /> : children;
}

CacheProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
