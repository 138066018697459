import { traverseMenuItem } from '@kea-inc/order';
import {
  CartItemStatus,
  ItemStatus,
  MenuItem,
  Modifier,
  Option,
  PlatformStore,
} from '@kea-inc/types';
import {
  getPlatformStore,
  deprecatedGetStoreTimezone,
} from '@modules/taskrouter/selectors';
import { getOrderHandoffMode } from '@modules/order/selectors';
import store from '@store';
import isInAvailabilityRange from './is-in-availability-range';
import { MenuClient } from './menuClient';
import getModifierStatus from './modifierStatus';

export interface ClassifiedOption {
  id: string;
  modifierId: string;
  quantity: number;
}

export interface ClassifiedOptionsByModifierId {
  [modifierId: string]: ClassifiedOption[];
}

export interface CartOptionsByModifierId {
  [modifierId: string]: CartOption[];
}

export interface CartValidatorItem {
  cartItemId: string;
  menuItem: MenuItem;
  quantity: number;
  cartOptionsByModifierId: CartOptionsByModifierId;
  classifiedOptionsByModifierId: ClassifiedOptionsByModifierId;
  recipient?: string;
  specialInstructions?: string;
  status?: CartItemStatus;
}

interface CartOption {
  option: Option;
  quantity: number;
  supportsQuantities: boolean;
}

export default class ItemValidator {
  private menuClient: MenuClient;

  private platformStore: PlatformStore;

  constructor(
    private cart: {
      items: CartValidatorItem[];
    },
  ) {
    this.menuClient = new MenuClient();
    this.platformStore = getPlatformStore();
  }

  async calculateCurrentCartStatus(): Promise<
    Array<{
      erroredModifier?: Modifier;
      erroredOption?: Option;
      erroredQuantity?: number;
      status: ItemStatus;
    }>
  > {
    const handoffMode: string | null = getOrderHandoffMode();

    const itemStatuses = await Promise.all(
      this.cart.items.map((item) =>
        this.calculateItemStatus({
          menuItem: item.menuItem,
          quantity: item.quantity,
          cartOptionsByModifierId: item.cartOptionsByModifierId,
          cartItemId: item.cartItemId,
          handoffMode,
        }),
      ),
    );

    return itemStatuses;
  }

  async calculateItemStatus(params: {
    menuItem: MenuItem;
    quantity: number;
    cartOptionsByModifierId: Record<string, Array<CartOption>>;
    cartItemId: string;
    handoffMode?: string | null;
  }): Promise<{
    erroredModifier?: Modifier;
    erroredOption?: Option;
    erroredQuantity?: number;
    status: ItemStatus;
    cartItemId: string;
  }> {
    const { menuItem, quantity, cartOptionsByModifierId, handoffMode } = params;
    const optionEntities = store.getState().menu.options.entities;
    const itemEntities = store.getState().menu.items.entities;
    const modifierEntities = store.getState().menu.modifiers.entities;
    const storeTimezone = deprecatedGetStoreTimezone();

    if (
      handoffMode &&
      menuItem.unavailableHandoffModes?.includes(handoffMode)
    ) {
      return {
        status: ItemStatus.ITEM_UNAVAILABLE_IN_HANDOFF_MODE,
        cartItemId: params.cartItemId,
      };
    }

    if (!isInAvailabilityRange(menuItem, storeTimezone)) {
      return {
        status: ItemStatus.ITEM_OUTSIDE_AVAILABLE_TIME,
        cartItemId: params.cartItemId,
      };
    }

    // When hasStockAvailable is undefined, it means there is stock available
    if (menuItem.metadata?.hasStockAvailable === false) {
      return {
        status: ItemStatus.ITEM_OUT_OF_STOCK,
        cartItemId: params.cartItemId,
      };
    }

    if (menuItem.maxQuantity && quantity > menuItem.maxQuantity) {
      return {
        status: ItemStatus.EXCESS_SINGLE_ITEM_QUANTITY,
        erroredQuantity: quantity,
        cartItemId: params.cartItemId,
      };
    }

    if (quantity < menuItem.minQuantity!) {
      return {
        status: ItemStatus.INSUFFICIENT_SINGLE_ITEM_QUANTITY,
        erroredQuantity: quantity,
        cartItemId: params.cartItemId,
      };
    }

    const totalQuantity = this.cart.items.reduce(
      (count, item) =>
        item.menuItem.id === menuItem.id ? count + item.quantity : count,
      0,
    );

    if (
      menuItem.maxBasketQuantity &&
      totalQuantity > menuItem.maxBasketQuantity
    ) {
      return {
        status: ItemStatus.EXCESS_TOTAL_ITEM_QUANTITY,
        erroredQuantity: quantity,
        cartItemId: params.cartItemId,
      };
    }
    if (quantity % (menuItem.quantityInterval || 1) !== 0) {
      return {
        status: ItemStatus.INVALID_ITEM_QUANTITY_INTERVAL,
        erroredQuantity: quantity,
        cartItemId: params.cartItemId,
      };
    }

    const abortController = new AbortController();
    let modifierError;

    await traverseMenuItem(
      menuItem,
      {
        visitModifier: async (modifier: Modifier) => {
          const { status, erroredOption, erroredQuantity } = getModifierStatus(
            modifier,
            cartOptionsByModifierId[modifier.id],
          );

          if (status !== ItemStatus.COMPLETE) {
            // TODO Pass in abort signal to traverseMenuItem and abort here
            modifierError ??= {
              status,
              erroredModifier: modifier,
              erroredOption,
              erroredQuantity,
              cartItemId: params.cartItemId,
            };

            abortController.abort();
          }
        },
        visitMenuItem: async () => {},
        getModifiersForOption: async (modifier: Modifier, option: Option) => {
          let modifiers: Modifier[] = [];

          // Only traverse selected options that have children
          if (
            option.modifierIds.length > 0 &&
            cartOptionsByModifierId[modifier.id].find(
              (cartOption: CartOption) => cartOption.option.id === option.id,
            )
          ) {
            const optionEntity = optionEntities[option.id];

            const modifierOptions = Object.values(
              optionEntities,
              // @ts-expect-error type missmatching
            ).filter<Option>(
              (modifierOption) =>
                modifierOption && modifierOption.parentId === modifier.id,
            );

            if (!optionEntity) {
              // @ts-expect-error
              modifiers = (
                await this.menuClient.getModifiersByParentId(
                  this.platformStore,
                  option.id,
                )
              ).map((modifierFromParent) => ({
                ...modifierFromParent,
                options: modifierOptions,
              }));
            } else {
              // @ts-expect-error
              modifiers = optionEntity.modifierIds.map(
                (modifierId: string) => ({
                  ...modifierEntities[modifierId]!,
                  isRequired: modifierEntities[modifierId]!.required!,
                  options: Object.values(optionEntities).filter(
                    (opt) => opt?.parentId === modifierId,
                  ),
                }),
              );
            }
          }

          // Only traverse modifiers that have selected options
          return modifiers.filter(
            (mod: Modifier) => cartOptionsByModifierId[mod.id]?.length > 0,
          );
        },
        getModifiersForMenuItem: async (menuItemToTraverse: MenuItem) => {
          let modifiers: Modifier[] = [];

          if (menuItemToTraverse.modifierIds?.length > 0) {
            const item = itemEntities[menuItemToTraverse.id];

            if (!item) {
              // @ts-expect-error - The new types are not matching with old types
              modifiers = (
                await this.menuClient.getModifiersByParentId(
                  this.platformStore,
                  menuItemToTraverse.id,
                )
              ).map((modifier) => ({
                ...modifier,
                options: Object.values(optionEntities).filter(
                  (option) => option?.parentId === modifier.id,
                ),
              }));
            } else {
              // @ts-expect-error - The new types are not matching with old types
              modifiers = item.modifierIds.map((modifierId: string) => ({
                ...modifierEntities[modifierId]!,
                isRequired: modifierEntities[modifierId]!.required!,
                options: Object.values(optionEntities).filter(
                  (option) => option?.parentId === modifierId,
                ),
              }));
            }
          }

          return modifiers;
        },
      },
      abortController.signal,
    );

    return (
      modifierError ?? {
        status: ItemStatus.COMPLETE,
        cartItemId: params.cartItemId,
      }
    );
  }
}
