import { HandoffMode } from '@kea-inc/types';
import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import { DateTime } from 'luxon';

// TODO: refactor all this file and test it: https://kea-engineering.atlassian.net/browse/DEV-4792

export type StoreHours = {
  actionTime: string;
  action: 'TURN_ON' | 'TURN_OFF';
  dayOfWeek: string;
};

export const getClosingTimeInMinutes = (params: { closingTime: DateTime }) => {
  const { closingTime } = params;
  const now = DateTime.now();
  return Math.round(closingTime.diff(now, 'minutes').minutes);
};

export const getShowStoreClosingWarning = (minutes: number) => minutes <= 60;

export const getStoreClosingText = (minutes: number) => {
  if (minutes > 60) {
    const hours = Math.ceil(minutes / 60) - 1;
    const remainder = minutes % 60;
    if (remainder < 30) {
      return `Closing in ${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }
    return `Closing in less than ${hours} hours`;
  }
  if (minutes > 0) {
    return `Closing in ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  }
  return 'Store is now closed';
};

export function reduceTime(time: string) {
  const hoursAndMinutes = time.slice(0, 5).trim();
  const [hours, minutes] = hoursAndMinutes.split(':');

  // REGEXP TO GET VALUES INSIDE PARENTHESES
  const duration = time.match(/\(([^)]+)\)/)?.[1];

  return {
    hours: reduceHours(time, Number(hours)),
    minutes: Number(minutes),
    duration:
      duration?.[0] === '0' ? `${duration.slice(-3, -1)} minutes` : duration,
  };
}

function reduceHours(time: string, hours: number) {
  if (time.indexOf('AM') !== -1 && hours === 12) {
    return 0;
  }
  if (time.indexOf('PM') !== -1 && hours < 12) {
    return hours + 12;
  }
  return hours;
}

// TODO: fix typing
export function getDateWithTime(
  date: any,
  hours: any,
  minutes: any,
  timezone: any,
) {
  const dateTime = DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }).setZone(timezone, { keepLocalTime: true });
  const dateWithTime = dateTime.plus({ hours, minutes });
  return dateWithTime.toMillis();
}

export function getDateNext10min() {
  const timezone = deprecatedGetStoreTimezone();

  const now = DateTime.now().setZone(timezone);
  const ms = 1000 * 60 * 10;
  const time = DateTime.fromMillis(Math.ceil(now.toMillis() / ms) * ms).setZone(
    timezone,
  );

  if (time.diffNow('minutes').minutes < 10) {
    return time.plus({ minutes: 10 }).toMillis();
  }

  return time.toMillis();
}

export function getNextHourOrHalfHour() {
  const timezone = deprecatedGetStoreTimezone();

  const now = DateTime.now().setZone(timezone);
  const ms = 1000 * 60 * 30;
  const time = DateTime.fromMillis(Math.ceil(now.toMillis() / ms) * ms).setZone(
    timezone,
  );

  if (time.diffNow('minutes').minutes < 10) {
    return makeTimeString(time.plus({ minutes: 10 }), true);
  }

  return makeTimeString(time, true);
}

const makeTimeString = (date: DateTime, hour12: boolean) => {
  const timeFormat = hour12 ? 'h:mm a' : 'HH:mm';
  const time = date.toFormat(timeFormat);

  const diff = date.diffNow(['hours', 'minutes']).toFormat("h'h' mm'm'");

  return `${time} (${diff})`;
};

export function getCurrentDate() {
  const timezone = deprecatedGetStoreTimezone();
  const now = DateTime.now().setZone(timezone);
  return now.toJSDate();
}

export function getFormattedTimeByDate(deliveryTimeInMillis: number) {
  const timezone = deprecatedGetStoreTimezone();
  const date = DateTime.fromMillis(deliveryTimeInMillis).setZone(timezone);
  return makeTimeString(date, true);
}

export const makeScheduledMessage = (
  time: string,
  date: Date,
  diff: string,
  type?: string,
) => {
  switch (type) {
    case 'time':
      return `Your order will be ready at ${time.slice(0, 9)}. ${
        diff ? `That's ${diff} from now.` : ''
      } Is that right?`;

    case 'date':
      return `Your order is scheduled for ${DateTime.fromJSDate(date).toFormat(
        'cccc, MMMM dd',
      )}. Is that correct?`;

    default:
      return `I've scheduled your order for ${DateTime.fromJSDate(
        date,
      ).toFormat('cccc, MMMM dd')} at ${time.slice(0, 9)}. Is that correct?`;
  }
};

export const handoffMethodsTranslations: Partial<Record<HandoffMode, string>> =
  {
    pickup: 'Pick up',
    curbside: 'Curbside',
    delivery: 'Delivery',
    'dine-in': 'Dine-in',
    // COMMENTED BECAUSE WE JUST WANT TO SHOW THE ABOVE METHODS FOR NOW
    // dispatch: 'Delivery',
    // drivethru: 'Drivethru',
  };

export function getReducedHandoffMethods(
  handoffMethods: HandoffMode[],
  isTurnedOn: boolean,
) {
  if (!isTurnedOn || handoffMethods === null || handoffMethods.length === 0) {
    return [
      {
        key: 'pickup',
        children: 'Pick up',
      },
    ];
  }

  const sortedHandoffMethods = [...handoffMethods];

  const pickupIndex = handoffMethods.indexOf('pickup');

  if (pickupIndex !== -1) {
    sortedHandoffMethods.splice(pickupIndex, 1);
    sortedHandoffMethods.unshift('pickup');
  }

  const reducedHandoffMethods: {
    key: string;
    children: string;
  }[] = [];

  sortedHandoffMethods.forEach((method) => {
    if (handoffMethodsTranslations[method]) {
      reducedHandoffMethods.push({
        key: method,
        children: handoffMethodsTranslations[method] || '',
      });
    }
  });

  return reducedHandoffMethods;
}

export function getHandoffMessage(handoffMethods: { children: string }[]) {
  if (handoffMethods.length === 1) {
    const method = handoffMethods[0];
    return `We currently only offer ${method.children}, is that okay?`;
  }

  const methodsList = handoffMethods.map((method) => method.children);
  return `We currently offer ${methodsList.join(
    ', ',
  )}. Which would you like?`.replace(/, ([^,]+)$/, ' or $1');
}
