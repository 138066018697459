import { useMemo } from 'react';
import { theme, Typography } from '@kea-inc/parrot-ui';
import { MdNotInterested, MdVolumeUp } from 'react-icons/md';

import { announce } from '@modules/device/device-actions';
import { formatPrice, makeItemUnavailablePhrase } from '@utils';

import { useAppDispatch, useAppSelector } from '@store';
import {
  selectAllItems,
  selectCategoryEntities,
} from '@modules/menu/selectors';
import { Category, Item } from '@modules/menu/types';
import { navigateToCategory, navigateToItem } from '@modules/menu/actions';
import { changeItemsSearchTerm } from '@modules/menu/slice';
import { getOrderHandoffMode } from '@modules/order/selectors';
import { getHandoffModes } from '@modules/taskrouter/selectors';
import * as S from '../CategoryItems/styles';

export function Searching() {
  const dispatch = useAppDispatch();
  const searchTerm = useAppSelector((state) => state.menu.items.searchTerm);

  const categoryEntities = useAppSelector((state) =>
    selectCategoryEntities(state.menu.categories),
  );

  const currentCategoryId = useAppSelector(
    (state) => state.menu.categories.current,
  );

  const allItems = useAppSelector((state) => selectAllItems(state.menu.items));

  const filteredItems = useMemo(() => {
    if (!searchTerm) {
      return allItems;
    }

    if (currentCategoryId) {
      return allItems.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          item.parentId === currentCategoryId,
      );
    }

    return allItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, allItems]);

  const categoriesWithItems = useMemo(() => {
    const categories: (Category & { filteredItems: Item[] })[] = [];

    Object.values(categoryEntities).forEach((category) => {
      const filteredItemsInCategory = filteredItems.filter(
        (item) => item.parentId === category!.id,
      );

      if (filteredItemsInCategory.length > 0) {
        categories.push({
          ...category!,
          filteredItems: filteredItemsInCategory,
        });
      }
    });

    return categories;
  }, [categoryEntities, filteredItems]);

  function onItemClick(item: Item, categoryId: string) {
    if (!item.hasStockAvailable) {
      const currentHandoffMode = getOrderHandoffMode();
      const allHandoffModes = getHandoffModes();

      const unavailablePhrase = makeItemUnavailablePhrase(
        item,
        currentHandoffMode,
        allHandoffModes,
      );

      return dispatch(announce({ text: unavailablePhrase }));
    }

    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(navigateToCategory({ categoryId }));
    dispatch(navigateToItem({ itemId: item.id }));
  }

  const handleCategoryHeaderClick = (categoryId: string) => {
    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(navigateToCategory({ categoryId }));
  };

  return (
    <S.Container>
      {categoriesWithItems.map((category) => (
        <div key={category.id}>
          <div className="category__title">
            <Typography
              variant="subheader"
              color="primary_darker"
              weight="heavy"
              onClick={() => handleCategoryHeaderClick(category.id)}
            >
              {category.name} ({category.filteredItems.length})
            </Typography>
          </div>
          <S.ItemsList>
            {/* TODO: fix item typing */}
            {category.filteredItems.map((item: any) => (
              <S.Item
                unavailable={!item.hasStockAvailable}
                key={item.id}
                onClick={() => onItemClick(item, category.id)}
              >
                <div className="item__Wrapper">
                  {!item.hasStockAvailable && (
                    <MdNotInterested color={theme.colors.error} size={14} />
                  )}
                  <div className="left">
                    <Typography
                      weight="heavy"
                      color="primary_darker"
                      className="item__Name"
                    >
                      {item.name}
                    </Typography>
                    <Typography weight="heavy" className="price">
                      {formatPrice(item.cost)}
                    </Typography>
                  </div>
                </div>

                <div className="right">
                  {!item.hasStockAvailable && (
                    <MdVolumeUp color={theme.colors.primary} size={18} />
                  )}
                </div>
              </S.Item>
            ))}
          </S.ItemsList>
        </div>
      ))}
    </S.Container>
  );
}
