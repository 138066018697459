export const INITIAL_STATE = {
  orderId: null,
  show: false,
  wasSubmitted: false,
  wasForwarded: false,
  primaryDisposition: null,
  secondaryDisposition: null,
  comments: '',
  pending: {
    isSubmittingSurvey: false,
    isFetchingSurveyModel: false, // WILL BE USED LATER
  },
  errors: {
    hasSubmitSurveyError: false,
    hasFetchSurveyModelError: false, // WILL BE USED LATER
  },
};
