import React from 'react';
import {
  AiOutlinePlus,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from 'react-icons/ai';
import PropTypes from 'prop-types';

import * as S from './styles';

const AddRemoveStatus = ({ status }) => (
  <>
    {status === 'none' && (
      <S.None>
        <AiOutlinePlus size={20} />
      </S.None>
    )}
    {status === 'added' && (
      <S.Added>
        <AiFillCheckCircle size={20} />
      </S.Added>
    )}
    {status === 'remove' && (
      <S.Remove>
        <AiFillCloseCircle size={20} />
      </S.Remove>
    )}
  </>
);

AddRemoveStatus.defaultProps = {
  status: 'none',
};

AddRemoveStatus.propTypes = {
  // none, added, remove
  status: PropTypes.string,
};

export default AddRemoveStatus;
