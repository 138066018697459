/**
 * THIS FILE DUPLICATES THE LOGIC IN THE ORDER MODULE UTILS V2 FILE.
 * ONCE WE MOVE AWAY FROM DATA-SERVICE, WE CAN DELETE THE ORDER MODULE UTILS V2 FILE AND USE THIS FILE INSTEAD.
 * @returns
 */
import { v4 as uuid } from 'uuid';

import { dataService, orderService } from '@services';
import * as orderSelectors from '@modules/order/selectors';
import { Validation, ValidationError } from '@modules/order/types';
import { AddedOptions } from '@modules/menu/types';

import { getStore } from '@modules/taskrouter/selectors';
import { validate } from '@modules/order/api';

import {
  APICartItemOption,
  APICartItem,
  AddCartItemParams,
  AddCartItemPayloadItem,
  AddCartItemResponse,
  AddCartItemReturn,
  CartItem,
  DSAddToCartPayload,
  DSAddToCartResponse,
  DSOrderItemModifier,
  DSPreexistingCartResponse,
  DSUpdateOrderItemPayload,
  DSUpdateOrderItemResponse,
  UpdateCartItemParams,
  UpdateCartItemPayload,
  UpdateCartItemResponse,
} from './types';

function makeAddCartItemPayload(params: AddCartItemParams[]) {
  const payloadItems: AddCartItemPayloadItem[] = [];

  params.forEach((item) => {
    const newItem: AddCartItemPayloadItem = {
      menuItemId: item.menuItemId,
      quantity: item.quantity,
    };

    if (Object.keys(item.addedOptions).length > 0) {
      newItem.options = getCartItemOptions(item.addedOptions);
    }

    if (
      item.removedDefaultOptions &&
      Object.keys(item.removedDefaultOptions).length > 0
    ) {
      newItem.options = (newItem.options ?? []).concat(
        getCartItemRemovedOptions(item.removedDefaultOptions),
      );
    }

    if (item.specialInstructions) {
      newItem.specialInstructions = item.specialInstructions;
    }

    if (item.recipient) {
      newItem.recipient = item.recipient;
    }

    payloadItems.push(newItem);
  });

  return { items: payloadItems };
}

export async function addCartItem(
  params: AddCartItemParams[],
  useOrderService?: boolean,
) {
  if (!useOrderService) {
    const response = await DSAddCartItem(params);
    await validate(undefined, { isAddingToCart: true });
    return response;
  }

  const id = orderSelectors.getOrderId();

  const payload = makeAddCartItemPayload(params);

  const url = `/orders/${id}/items`;
  const response = await orderService.post<AddCartItemResponse>(url, payload);

  return response.data;
}

export async function updateCartItem(
  params: UpdateCartItemParams,
  useOrderService?: boolean,
) {
  if (!useOrderService) {
    const response = await DSUpdateCartItem(params);
    await validate();
    return response;
  }

  const orderId = orderSelectors.getOrderId();

  const payload: Partial<UpdateCartItemPayload> = {};

  if (params.quantity) {
    payload.quantity = params.quantity;
  }

  if (params.addedOptions) {
    payload.options = getCartItemOptions(params.addedOptions);
  }

  if (
    params.removedDefaultOptions &&
    Object.keys(params.removedDefaultOptions).length > 0
  ) {
    payload.options = (payload.options ?? []).concat(
      getCartItemRemovedOptions(params.removedDefaultOptions),
    );
  }

  if (params.specialInstructions) {
    payload.specialInstructions = params.specialInstructions;
  }

  if (params.recipient) {
    payload.recipient = params.recipient;
  }

  const url = `/orders/${orderId}/items/${params.cartItemId}`;
  const response = await orderService.patch<UpdateCartItemResponse>(
    url,
    payload,
  );

  return response.data;
}

export async function deleteCartItem(
  cartItemId: string,
  isCartEmpty: boolean,
  useOrderService?: boolean,
) {
  if (!useOrderService) {
    const response = await DSDeleteCartItem(cartItemId);
    if (!isCartEmpty) {
      await validate();
    }
    return response;
  }

  const id = orderSelectors.getOrderId();

  const url = `/orders/${id}/items/${cartItemId}`;
  const response = await orderService.delete<{ validation: Validation }>(url);

  return response.data;
}

export async function fetchItemsFromCart(
  isCartEmpty: boolean,
  useOrderService?: boolean,
) {
  if (!useOrderService) {
    const response = await DSFetchPreexistingCart();
    if (!isCartEmpty) {
      await validate();
    }
    return response;
  }

  const orderId = orderSelectors.getOrderId();
  const url = `/orders/${orderId}/items`;
  const response = await orderService.get<{ items: APICartItem[] }>(url);
  return response.data.items;
}

function getCartItemOptions(addedOptions: AddedOptions) {
  const cartItemOptions: APICartItemOption[] = [];

  Object.values(addedOptions).forEach((option) => {
    cartItemOptions.push({
      id: option.id,
      quantity: option.quantity,
    });
  });

  return cartItemOptions;
}

function getCartItemRemovedOptions(
  modifiers: Record<string, Array<{ id: string }>>,
) {
  const cartItemRemovedOptions: APICartItemOption[] = [];

  Object.values(modifiers).forEach((options) => {
    options.forEach((option) => {
      cartItemRemovedOptions.push({
        id: option.id,
        quantity: 0,
      });
    });
  });

  return cartItemRemovedOptions;
}

export function tranformCartItems(
  items: APICartItem[],
  selectionData?: AddCartItemReturn['selectionData'],
): CartItem[] {
  const { addedOptions, promptedModifiers, noSelectionModifiers } =
    selectionData ?? {};
  const result: CartItem[] = [];

  items.forEach((item) => {
    result.push({
      ...item,
      createdAt: new Date().toISOString(),
      options: item.options?.map((option) => ({
        ...option,
        isFullyAdded:
          !addedOptions || Object.keys(addedOptions).length === 0
            ? true
            : addedOptions[option.id]?.isFullyAdded ?? false,
      })),
      promptedModifiers: promptedModifiers ?? [],
      noSelectionModifiers: noSelectionModifiers ?? [],
      hasOngoingAction: false,
    });
  });

  return result;
}

export function getUpdateChanges(item: APICartItem, cartItem?: CartItem) {
  if (!cartItem) {
    return {};
  }

  const changes: Partial<CartItem> = {};

  if (item.quantity !== cartItem.quantity) {
    changes.quantity = item.quantity;
  }

  if (item.specialInstructions !== cartItem.specialInstructions) {
    changes.specialInstructions = item.specialInstructions;
  }

  if (item.recipient !== cartItem.recipient) {
    changes.recipient = item.recipient;
  }

  changes.options = [];

  item.options?.forEach((option) => {
    const cartItemOption = cartItem.options?.find((o) => o.id === option.id);
    changes.options?.push({
      ...option,
      isFullyAdded: cartItemOption?.isFullyAdded ?? true,
    });
  });

  return changes;
}

export function getErrorsByCartItemId(errors: ValidationError[]) {
  return errors.reduce<Record<string, ValidationError[]>>((acc, error) => {
    if (error.cartItemId) {
      if (!acc[error.cartItemId]) {
        acc[error.cartItemId] = [];
      }

      acc[error.cartItemId].push(error);
    }

    return acc;
  }, {});
}

export function transformToHistoryItems(items: CartItem[]) {
  return items.map((item) => ({
    id: item.id,
    menuItemId: item.menuItemId,
    options: item.options ? item.options.map((o) => o.id) : undefined,
  }));
}

// DEPRECATED DATA-SERVICE FUNCTIONS
function makeDSAddToCartPayload(
  params: AddCartItemParams[],
): DSAddToCartPayload {
  const payloadItems: DSAddToCartPayload['items'] = [];

  params.forEach((item) => {
    const newItem: DSAddToCartPayload['items'][0] = {
      id: uuid(),
      store_menu_item_id: item.menuItemId,
      item_id: item.menuItemId,
      quantity: item.quantity,
      modifiers: makeDSOrderItemModifier(item.addedOptions),
    };

    if (item.specialInstructions) {
      newItem.special_instructions = item.specialInstructions;
    }

    if (item.recipient) {
      newItem.recipient = item.recipient;
    }

    payloadItems.push(newItem);
  });

  return { items: payloadItems };
}

function makeDSOrderItemModifier(addedOptions: AddedOptions) {
  const modifiers: DSOrderItemModifier[] = [];

  Object.values(addedOptions).forEach((option) => {
    modifiers.push({
      external_id: option.id,
      chain_id: option.chainId,
      quantity: option.quantity,
    });
  });

  return JSON.stringify(modifiers);
}

function transformToAddCartItemResponse(
  response: DSAddToCartResponse,
  removedDefaultOptions: AddCartItemParams['removedDefaultOptions'],
): AddCartItemResponse {
  const addCartItemResponse: AddCartItemResponse = {
    items: response.created_order_items.map((item) => ({
      externalItemId: item.store_menu_item_id,
      id: item.id,
      itemFullDescription: '',
      menuItemId: item.item_id,
      quantity: item.quantity,
      specialInstructions: item.special_instructions,
      recipient: item.recipient,
      options: item.modifiers
        ? JSON.parse(item.modifiers)
            .map((modifier: DSOrderItemModifier) => ({
              id: modifier.external_id,
              quantity: modifier.quantity,
              chainId: modifier.chain_id,
            }))
            .concat(
              (removedDefaultOptions[item.store_menu_item_id] ?? []).map(
                (o) => ({
                  id: o.id,
                  quantity: 0,
                  chainId: '',
                }),
              ),
            )
        : [],
      orderId: item.order_id,
    })),
    validation: {
      isValid: true,
      errors: [],
    },
  };

  return addCartItemResponse;
}

function tranformToUpdateCartItemResponse(
  response: DSUpdateOrderItemResponse,
  removedDefaultOptions: UpdateCartItemParams['removedDefaultOptions'],
): UpdateCartItemResponse {
  let options = [];

  if (response.modifiers) {
    const responseModifiers = JSON.parse(response.modifiers).map(
      (modifier: DSOrderItemModifier) => ({
        id: modifier.external_id,
        quantity: modifier.quantity,
        chainId: modifier.chain_id,
      }),
    );

    const removedOptions = (removedDefaultOptions[response.id] ?? []).map(
      (o) => ({
        id: o.id,
        quantity: 0,
        chainId: '',
      }),
    );

    options = responseModifiers.concat(removedOptions);
  }
  return {
    item: {
      id: response.id,
      itemFullDescription: '',
      menuItemId: response.store_menu_item_id,
      quantity: response.quantity,
      options,
      recipient: response.recipient,
      orderId: response.order_id,
      specialInstructions: response.special_instructions,
    },
    validation: {
      isValid: true,
      errors: [],
    },
  };
}

function transformToAPICartItem(
  items: DSPreexistingCartResponse['order_items'],
): APICartItem[] {
  return items.map((item) => ({
    id: item.id,
    menuItemId: item.store_menu_item_id,
    quantity: item.quantity,
    options: item.modifiers.map((modifier) => ({
      id: modifier.external_id,
      quantity: modifier.quantity,
      chainId: modifier.chain_id,
    })),
    specialInstructions: item.special_instructions,
    recipient: item.recipient,
    orderId: item.order_id,
    itemFullDescription: '',
  }));
}

/** @deprecated - This method will no longer exist after the complete migration to order-service */
async function DSAddCartItem(params: AddCartItemParams[]) {
  const orderId = orderSelectors.getOrderId();

  const payload = makeDSAddToCartPayload(params);

  const url = `/orders/${orderId}/order_items`;
  const response = await dataService.post<DSAddToCartResponse>(url, payload);

  const removedDefaultOptions = params.reduce<
    Record<string, Array<{ id: string }>>
  >((acc, item) => {
    acc[item.menuItemId] = Object.values(item.removedDefaultOptions).flat();

    return acc;
  }, {});
  return transformToAddCartItemResponse(response.data, removedDefaultOptions);
}

/** @deprecated - This method will no longer exist after the complete migration to order-service */
async function DSUpdateCartItem(params: UpdateCartItemParams) {
  const url = `/orders/order_items/${params.cartItemId}`;

  const payload: DSUpdateOrderItemPayload = {};

  if (params.quantity) {
    payload.quantity = params.quantity;
  }

  if (params?.addedOptions) {
    payload.modifiers = makeDSOrderItemModifier(params.addedOptions);
  }

  if (params.specialInstructions) {
    payload.special_instructions = params.specialInstructions;
  }

  if (params.recipient) {
    payload.recipient = params.recipient;
  }

  const response = await dataService.patch<DSUpdateOrderItemResponse>(
    url,
    payload,
  );
  const removedDefaultOptions = [params].reduce<
    Record<string, Array<{ id: string }>>
  >((acc, item) => {
    acc[params.cartItemId] = Object.values(item.removedDefaultOptions).flat();

    return acc;
  }, {});
  return tranformToUpdateCartItemResponse(response.data, removedDefaultOptions);
}

/** @deprecated - This method will no longer exist after the complete migration to order-service */
async function DSDeleteCartItem(cartItemId: string) {
  const url = `/orders/order_items/${cartItemId}`;
  await dataService.delete(url);

  return {
    validation: {
      isValid: true,
      errors: [],
    },
  };
}

/** @deprecated - This method will no longer exist after the complete migration to order-service */
async function DSFetchPreexistingCart() {
  const orderId = orderSelectors.getOrderId();
  const { brandId, id: storeId } = getStore() ?? {};

  const url = `/orders/${orderId}/brands/${brandId}/store/${storeId}/cart`;
  const response = await dataService.get<DSPreexistingCartResponse>(url);

  return transformToAPICartItem(response.data.order_items);
}
