import { Button, theme } from '@kea-inc/parrot-ui';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import styled, { keyframes } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h6`
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

export const AddressWrapper = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  > section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 0.5rem;
  }

  > article,
  > section article {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  > article:nth-child(2),
  > article:nth-child(4) {
    width: 50%;
  }

  /* Chrome, Safari, Edge, Opera */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const InstructionsBadges = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;

export const SearchIcon = styled(Button).attrs({
  tertiary: true,
  size: 'small',
})`
  padding: 0;

  > p {
    line-height: 1;
    margin: 0 0.25rem;
  }

  svg {
    font-size: 1rem;
    margin: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

export const MapsContainer = styled.div`
  width: 100%;
  height: 250px;

  > div > div {
    border-radius: 8px;
  }
`;

type MarkerProps = {
  lat: number;
  lng: number;
  isStore?: boolean;
};

export const Marker = styled(FaMapMarkerAlt)<MarkerProps>`
  width: 28px;
  height: 28px;

  color: ${(props) =>
    props.isStore ? theme.colors.primary : theme.colors.error};
  transform: translate(-50%, -100%);
`;
