import React from 'react';
import { Typography } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';

import { ChecklistItem } from './ChecklistItem';

export const CartNotEmpty = () => (
  <ChecklistItem field={ORDER_CHECKLIST.CART_HAS_ITEMS}>
    <Typography weight="heavy">Order is not empty</Typography>
  </ChecklistItem>
);
