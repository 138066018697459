import React from 'react';
import PropTypes from 'prop-types';

import { Card, Background } from './styles';

const Overlay = ({ open, children, ...props }) => (
  <span>
    <Background open={open} />
    <Card open={open} {...props}>
      {children}
    </Card>
  </span>
);

Overlay.defaultProps = {
  open: false,
  children: undefined,
};

Overlay.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  ),
};

export default React.memo(Overlay);
