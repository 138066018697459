import React, { useRef, useState, useMemo } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { Avatar, Typography, Dropdown } from '@kea-inc/parrot-ui';

import { useHistory, useInterval } from '@hooks';
import { getCurrentVersion } from '@utils';

import Loading from '@components/Loading';
import KeaLogo from '@images/kea-logo.png';

import * as clockAPI from '@modules/clock/api';
import { getInitials } from '@modules/auth/utils';
import { WorkerStatus } from '@components/Header/WorkerStatus';
import ClockButton from './ClockButton';
import { CallDuration } from './CallDuration';
import {
  CallStoreBadge,
  CallerBadge,
  HangupBadge,
  MuteUnmuteBadge,
  CallBackBadge,
} from './Badges';
import * as S from './styles';
import * as utils from './utils';
import { useConnector } from './useConnector';
import StatusOverlay from './Overlays/StatusOverlay';
import LeaveTrainingModeButton from './LeaveTrainingModeButton';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [copiedTroubleshoot, setCopiedTroubleshoot] = useState(false);
  const avatarRef = useRef(null);

  const { selectors } = useConnector();
  const {
    hasOngoingCall,
    hasOngoingTask,
    user,
    brandName,
    isClockedIn,
    isTrainingTask,
    featureFlagsUser,
  } = selectors;
  const history = useHistory();

  const { useAgentStatuses } = featureFlagsUser;

  const shouldUseNewStatuses =
    !hasOngoingTask && !brandName && useAgentStatuses;

  const version = useMemo(getCurrentVersion, []);
  useInterval(() => {
    if (isClockedIn) {
      clockAPI.pulseCheckPing();
    }
  }, 10000);

  const handleCopyTroubleshoot = () => {
    utils.copyTroubleshootInformationToClipboard();
    setCopiedTroubleshoot(true);
    setTimeout(() => {
      setCopiedTroubleshoot(false);
    }, 5000);
  };

  if (!user) {
    return <Loading />;
  }

  return (
    <S.Container>
      <img src={KeaLogo} alt="Kea Logo" />

      <div className="version">
        <Typography variant="caption" color="black_light">
          UOA {version}
        </Typography>
        {brandName && (
          <S.BrandName>
            <Typography variant="title" weight="heavy">
              {brandName}
            </Typography>
          </S.BrandName>
        )}
        {shouldUseNewStatuses && (
          <>
            <WorkerStatus />
            <StatusOverlay />
          </>
        )}
      </div>

      <S.Actions>
        {hasOngoingTask && hasOngoingCall && (
          <>
            <MuteUnmuteBadge />
            <CallBackBadge />
            <CallStoreBadge />
            <HangupBadge />
            <CallerBadge />
            <CallDuration />
          </>
        )}

        {isTrainingTask ? <LeaveTrainingModeButton /> : <ClockButton />}
      </S.Actions>

      <Avatar
        ref={avatarRef}
        image={user.picture}
        initials={getInitials(user.name)}
        // @ts-expect-error onClick is a valid prop
        onClick={() => setIsDropdownOpen(true)}
      />

      <Dropdown.Menu
        anchor={avatarRef}
        position="bottomLeft"
        width={200}
        open={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
      >
        <Dropdown.Atoms.Icon
          leftIcon={FiLogOut}
          leftIconColor="error"
          onClick={() => history.replace('/logout')}
          className="logout__Button"
        >
          Logout
        </Dropdown.Atoms.Icon>
        <S.Button
          style={{ width: '85%', marginTop: 10 }}
          size="small"
          secondary
          onClick={handleCopyTroubleshoot}
        >
          {copiedTroubleshoot ? 'Copied!' : 'Copy for Troubleshoot'}
        </S.Button>
      </Dropdown.Menu>
    </S.Container>
  );
};

export default React.memo(Header);
