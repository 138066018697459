import { FetchStatus } from '../store/modules/menu/types';

type GetIsModifierRequirementsFulfilledReturn = {
  isRequirementsFulfilled: boolean;
};

export type ModifierConfig = {
  minOptions: number | null;
  maxOptions: number | null;
  minQuantity: number | null;
  maxQuantity: number | null;
  minQuantityPerOption: number | null;
  maxQuantityPerOption: number | null;
  required?: boolean;
  status: FetchStatus;
};

export function getIsModifierRequirementsFulfilled(
  modifier: ModifierConfig,
  addedModifierOptions: { quantity: number }[],
): GetIsModifierRequirementsFulfilledReturn {
  const {
    minOptions,
    maxOptions,
    minQuantity,
    maxQuantity,
    minQuantityPerOption,
    maxQuantityPerOption,
    status,
  } = modifier;

  if (status === 'pending') {
    return { isRequirementsFulfilled: false };
  }

  const totalQuantity = addedModifierOptions.reduce(
    (acc, option) => acc + option.quantity,
    0,
  );

  if (modifier.required && !addedModifierOptions.length) {
    return { isRequirementsFulfilled: false };
  }

  if (minOptions && minOptions > addedModifierOptions.length) {
    return { isRequirementsFulfilled: false };
  }

  if (maxOptions && maxOptions < addedModifierOptions.length) {
    return { isRequirementsFulfilled: false };
  }

  if (minQuantity && minQuantity > totalQuantity) {
    return { isRequirementsFulfilled: false };
  }

  if (maxQuantity && maxQuantity < totalQuantity) {
    return { isRequirementsFulfilled: false };
  }

  if (minQuantityPerOption) {
    for (const option of addedModifierOptions) {
      if (minQuantityPerOption > option.quantity) {
        return {
          isRequirementsFulfilled: false,
        };
      }
    }
  }

  if (maxQuantityPerOption) {
    for (const option of addedModifierOptions) {
      if (maxQuantityPerOption < option.quantity) {
        return {
          isRequirementsFulfilled: false,
        };
      }
    }
  }

  return {
    isRequirementsFulfilled: true,
  };
}
