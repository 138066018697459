import env from '@beam-australia/react-env';

import { dataService } from '@services';
import { dispatch } from '@store';
import rum from '@rum';

import * as authSelectors from '@modules/auth/selectors';
import * as notifications from '@modules/notifications/api';

import * as actions from './actions';

const workspaceSid = env('TWILIO_WORKSPACE_SID');

export function reset() {
  dispatch(actions.agentReset());
}

export async function fetchByAuth0Id() {
  dispatch(actions.agentFetching());

  const user = authSelectors.currentUser();

  try {
    const payload = { auth0_user_id: user.sub, email: user.email };
    const result = await dataService.post('/agents/login-agent', payload);

    if (result.data.length === 0) throw new Error('Agent does not exist');

    const agentsBrands = await dataService.get(
      `/agents/${result.data.id}/workspace/${workspaceSid}`,
    );

    dispatch(
      actions.agentFetched({
        agent: result.data,
        brands: agentsBrands.data.brands,
      }),
    );
    rum.setUser();
  } catch (error) {
    dispatch(actions.agentNotFetched());
    notifications.error({
      title: 'Login failed',
      message: 'Sorry, your access was denied.',
      error,
      shouldLogout: true,
    });
  }
}
