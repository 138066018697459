import React, { useMemo } from 'react';
import env from '@beam-australia/react-env';
import { Typography } from '@kea-inc/parrot-ui';
import PropTypes from 'prop-types';

import * as paymentAPI from '@modules/payment/api';
import * as paymentSelectors from '@modules/payment/selectors';

import { usePayment } from '@hooks';
import { CenterOverlay } from '@components';

import * as S from './styles';

const environment = env('ENVIRONMENT');
const agentCanCancel = ['development'].includes(environment);

const PaybotOverlay = () => {
  const { isPaybotOverlayOpen, creditCard, pending, errors, streamFields } =
    usePayment();

  const handleCloseReset = () => {
    paymentAPI.closePaybotOverlay();
  };

  const { statuses, success } = useMemo(
    () => paymentSelectors.getFieldsStatuses(),
    [creditCard],
  );

  const { isConnectingPaybot, isConnectingStream } = pending;
  const { hasConnectToPaybotError, hasConnectToStreamError } = errors;
  const shouldAppearRobotAnimation =
    !hasConnectToPaybotError &&
    !hasConnectToStreamError &&
    !isConnectingPaybot &&
    !isConnectingStream;

  return isPaybotOverlayOpen ? (
    <CenterOverlay>
      <S.Container>
        <S.Title weight="heavy" variant="subheader">
          Customer payment in process...
        </S.Title>

        <S.Content>
          <Typography weight="heavy" color="primary_dark">
            Credit Card #
          </Typography>
          <Status status={statuses[streamFields.NUMBER]} />

          <Typography weight="heavy" color="primary_dark">
            Expiration Date
          </Typography>
          <Status status={statuses[streamFields.EXP_DATE]} />

          <Typography weight="heavy" color="primary_dark">
            Zip Code
          </Typography>
          <Status status={statuses[streamFields.ZIPCODE]} />

          <Typography weight="heavy" color="primary_dark">
            CVV
          </Typography>
          <Status status={statuses[streamFields.CVV]} />
        </S.Content>

        {(isConnectingPaybot || isConnectingStream) && (
          <>
            <Typography variant="subheader" weight="heavy" color="primary">
              CONNECTING PAYBOT
            </Typography>
            {agentCanCancel && (
              <S.Button
                size="small"
                onClick={handleCloseReset}
                disabled={!shouldAppearRobotAnimation}
              >
                Cancel
              </S.Button>
            )}
          </>
        )}

        {(hasConnectToPaybotError || hasConnectToStreamError) && (
          <>
            <Typography variant="subheader" weight="heavy" color="error">
              ERROR CONNECTING PAYBOT
            </Typography>
            <S.Button size="small" onClick={handleCloseReset}>
              Close
            </S.Button>
          </>
        )}

        {(shouldAppearRobotAnimation || success) && (
          <>
            <S.Button
              size="small"
              onClick={handleCloseReset}
              disabled={!shouldAppearRobotAnimation}
            >
              Cancel
            </S.Button>
          </>
        )}
      </S.Container>
    </CenterOverlay>
  ) : (
    <div />
  );
};

function Status({ status }) {
  return (
    <S.Status>
      {status === 'filled' && <S.CheckCircle />}
      {status === 'notFilled' && <></>}
    </S.Status>
  );
}

Status.propTypes = {
  // filled, filling, notFilled
  status: PropTypes.string.isRequired,
};

export default React.memo(PaybotOverlay);
