import { theme, Button as parrotButton } from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

export const ItemSummary = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  height: 100%;
  padding-left: 14px;
  border-left: 1px solid ${theme.colors.gray_light};

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  transition: width 0.1s 0.1s, opacity 0.2s 0.2s;
`;

type ButtonProps = {
  isDisabled: boolean;
};

export const Button = styled(parrotButton)<ButtonProps>`
  ${(props) =>
    props.isDisabled &&
    css`
      background-color: #eff1f3;
      cursor: not-allowed;

      p {
        color: #91969c;
      }
      :hover {
        background-color: #eff1f3;
      }
    `}
`;
