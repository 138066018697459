import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from '@store';
import logger from '@logger';
import { orderService } from '@services';
import { navigateToCategory, navigateToItem } from '@modules/menu/actions';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import * as menuSlice from '@modules/menu/slice';
import * as utils from '@modules/cart/utils';
import * as notifications from '@modules/notifications/api';
import * as orderAPI from '@modules/order/api';
import * as utilsV2 from '@modules/order/utilsV2';
import { ORDER_CHECKLIST } from '@modules/order/state';
import * as orderSelectors from '@modules/order/selectors';
import { Validation } from '@modules/order/types';

import {
  APICartItem,
  AddCartItemParams,
  AddCartItemReturn,
  PricingResponse,
  RemoveCartItemReturn,
  UpdateCartItemParams,
  UpdateCartItemResponse,
} from './types';
import { actions as cartSlice } from './slice';
import * as selectors from './selectors';

interface ThunkConfig {
  state: RootState;
}

export const addCartItems = createAsyncThunk<
  AddCartItemReturn | void,
  AddCartItemParams[],
  ThunkConfig
>('cart/addCartItem', async (cartItems, { dispatch, getState }) => {
  if (!cartItems || cartItems.length === 0) {
    return;
  }

  try {
    const { useOrderService } = getStoreFeatureFlags(getState());
    const data = await utils.addCartItem(cartItems, useOrderService);
    const addedOptions = getState().menu.options.added;
    const promptedModifiers = getState().menu.modifiers.prompted;
    const noSelectionModifiers = getState().menu.modifiers.noSelection;

    dispatch(navigateToItem({ itemId: null }));
    dispatch(navigateToCategory({ categoryId: null }));
    dispatch(menuSlice.changeItemsSearchTerm({ searchTerm: '' }));

    orderAPI.setVisibleTab('order');
    orderAPI.setChecklist(ORDER_CHECKLIST.CART_HAS_ITEMS, true);

    if (cartItems.length === 1) {
      orderAPI.createOrderEvent('added_item_to_cart', {
        order_item_id: data.items[0].id,
        item_id: data.items[0].menuItemId,
        name: data.items[0].itemFullDescription,
        quantity: data.items[0].quantity,
      });
    }

    return {
      data,
      selectionData: {
        addedOptions,
        promptedModifiers: Object.keys(promptedModifiers),
        noSelectionModifiers: Object.keys(noSelectionModifiers),
      },
    };
  } catch (error) {
    logger.error('Error adding item to cart', { addCartItemError: error });

    notifications.error({
      title: 'Error adding item',
      message: 'An error occurred while adding the item to the cart.',
    });
  }
});

export const removeCartItem = createAsyncThunk<
  RemoveCartItemReturn | void,
  string,
  ThunkConfig
>('cart/removeCartItem', async (cartItemId, { getState, dispatch }) => {
  try {
    const isCartEmpty = selectors.selectIsCartEmpty(getState());
    const { useOrderService } = getStoreFeatureFlags(getState());
    const data = await utils.deleteCartItem(
      cartItemId,
      isCartEmpty,
      useOrderService,
    );
    const cartItem = getState().cart.cartItems.entities[cartItemId];

    orderAPI.createOrderEvent('remove_item_from_cart', {
      productId: cartItem?.menuItemId,
      name: cartItem?.itemFullDescription,
    });

    orderAPI.setChecklist(ORDER_CHECKLIST.CART_HAS_ITEMS, !isCartEmpty);

    dispatch(navigateToItem({ itemId: null }));
    dispatch(navigateToCategory({ categoryId: null }));
    dispatch(menuSlice.changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(cartSlice.setCurrentCartItem(null));

    return { cartItemId, data };
  } catch (error: any) {
    logger.error('Error removing item from cart', {
      removeCartItemError: error,
    });

    notifications.error({
      title: 'Error removing item',
      message: 'An error occurred while removing the item from the cart.',
    });
  }
});

export const updateCartItem = createAsyncThunk<
  UpdateCartItemResponse | void,
  UpdateCartItemParams,
  ThunkConfig
>('cart/updateCartItem', async (params, { dispatch, getState }) => {
  const { cartItemId, quantity } = params;

  try {
    const { useOrderService } = getStoreFeatureFlags(getState());
    const data = await utils.updateCartItem(params, useOrderService);
    const cartItem = getState().cart.cartItems.entities[cartItemId];

    dispatch(navigateToItem({ itemId: null }));
    dispatch(navigateToCategory({ categoryId: null }));
    dispatch(menuSlice.changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(cartSlice.setCurrentCartItem(null));

    orderAPI.createOrderEvent('update_item_on_cart', {
      productId: cartItem?.menuItemId,
      name: cartItem?.itemFullDescription,
      quantity,
    });
    orderAPI.setVisibleTab('order');

    return data;
  } catch (error: any) {
    logger.error('Error updating item in cart', { updateCartItemError: error });

    notifications.error({
      title: 'Error updating item',
      message: 'An error occurred while updating the item in the cart.',
    });
  }
});

export const selectCartItemForUpdate = createAsyncThunk<
  void,
  string,
  ThunkConfig
>('cart/selectCartItem', async (cartItemId, { dispatch, getState }) => {
  dispatch(cartSlice.setCurrentCartItem(cartItemId));

  const cartItem = selectors.selectCartItemById(
    getState().cart.cartItems,
    cartItemId,
  );

  if (cartItem) {
    dispatch(
      menuSlice.editCartItem({
        itemId: cartItem.menuItemId,
        options: cartItem.options?.filter((o) => o.quantity > 0) || [],
        recipientName: cartItem.recipient || '',
        specialInstructions: cartItem.specialInstructions || '',
        promptedModifiers: cartItem.promptedModifiers,
        noSelectionModifiers: cartItem.noSelectionModifiers,
        quantity: cartItem.quantity,
      }),
    );
  }
});

export const fetchPrices = createAsyncThunk<
  PricingResponse,
  void,
  {
    rejectValue: PricingResponse;
  }
>('cart/fetchPrices', async (_, { rejectWithValue }) => {
  try {
    orderAPI.setChecklist(ORDER_CHECKLIST.HAS_VALIDATED, true);
    const orderId = orderSelectors.getOrderId();
    const url = `/orders/${orderId}/pricing`;
    const { data } = await orderService.get<PricingResponse>(url);

    return data;
  } catch (error) {
    orderAPI.setChecklist(ORDER_CHECKLIST.HAS_VALIDATED, false);
    logger.error('Error fetching prices', { fetchPricesError: error });

    const typedError = error as AxiosError<PricingResponse>;

    return rejectWithValue(typedError.response!.data);
  }
});

export const fetchPreexistingCart = createAsyncThunk<
  APICartItem[] | undefined,
  void,
  { state: RootState }
>('cart/fetchPreexistingCart', async (_, { dispatch, getState }) => {
  try {
    const isCartEmpty = selectors.selectIsCartEmpty(getState());
    const { useOrderService } = getStoreFeatureFlags(getState());
    const items = await utils.fetchItemsFromCart(isCartEmpty, useOrderService);

    if (!items.length) {
      return;
    }

    const compressedMenu = await utilsV2.getMenuItemsSelections(items);

    if (!utilsV2.isCompressedMenuValid(compressedMenu)) {
      return;
    }

    dispatch(menuSlice.populateCompressedMenu({ compressedMenu }));

    orderAPI.setChecklist(ORDER_CHECKLIST.CART_HAS_ITEMS, true);

    return items;
  } catch (error) {
    logger.error('Error fetching preexisting cart', {
      fetchPreexistingCartError: error,
    });

    notifications.error({
      title: 'Error fetching preexisting cart',
      message: 'An error occurred while fetching the preexisting cart.',
    });
  }
});

export const setCouponValidationStatus = createAsyncThunk(
  'cart/setCouponValidationStatus',
  (validation: Validation) => {
    orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ORDER, false);
    return validation;
  },
);
