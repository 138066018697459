import { RootState, store } from '@store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const account = createDraftSafeSelector(
  (state: RootState) => state.account,
  (accountState) => accountState,
);

export function getAccountDetails() {
  const { accountDetails } = account(store.getState());

  const hasLastName = !!accountDetails.lastName;

  if (hasLastName) {
    return accountDetails;
  }

  return {
    ...accountDetails,
    lastName: 'Unknown',
  };
}

export function getAccountId() {
  const { id } = account(store.getState());
  return id;
}

export function getAccountFullName() {
  const accountDetails = getAccountDetails();
  const { firstName, lastName } = accountDetails;

  if (!firstName) {
    return 'Customer';
  }

  return `${firstName} ${lastName}`.trim();
}
