import { useAppSelector } from '@store';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as orderAPI from '@modules/order/api';
import * as voicePaneAPI from '@modules/voicePane/api';

export function useConnector() {
  const selectors = {
    currentCategoryId: useAppSelector(({ menu }) => menu.categories.current),

    categoryEntities: useAppSelector(({ menu }) => menu.categories.entities),

    currentItemId: useAppSelector(({ menu }) => menu.items.current),

    itemEntities: useAppSelector(({ menu }) => menu.items.entities),

    brandKey: useAppSelector(taskrouterSelectors.getBrandKey),

    isChecklistOpen: useAppSelector(({ order }) => order.isChecklistOpen),
  };

  const actions = {
    setChecklist: orderAPI.setChecklist,
    filterPrompts: voicePaneAPI.filterPrompts,
  };

  return { selectors, actions };
}
