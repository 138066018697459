import { theme } from '@kea-inc/parrot-ui';
import styled, { keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.article<{
  hasError: boolean;
}>`
  margin-top: 12px;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: ${(props) =>
      props.hasError ? `1px solid ${theme.colors.error}` : 'none'};

    width: 98%;
    margin: auto;
  }

  &:hover {
    .error__Tooltip {
      opacity: ${(props) => (props.hasError ? 1 : 0)};
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .product__removeAction {
    &:hover {
      opacity: 0.8;
    }

    p {
      color: ${theme.colors.error};
      font-weight: bold;
    }

    svg {
      color: ${theme.colors.error};
    }
  }
`;

export const OptionsList = styled.ul`
  padding: 0 8px;

  li {
    display: flex;
    align-items: center;
  }

  li::before {
    content: '';
    width: 4px;
    height: 4px;
    margin-right: 8px;
    border-radius: 50%;
    background: ${theme.colors.gray_darker};
  }
`;

export const OptionsListItem = styled.li<{
  ident: number;
  hidden?: boolean;
}>`
  margin-left: ${(props) => `${props.ident <= 3 ? 0 : props.ident * 3}px`};
  display: ${(props) => (props.hidden ? 'none !important' : '')};
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  .price__Badge {
    margin-top: 6px;
  }

  section:first-child > p {
    margin-top: 12px;
  }

  section:last-child {
    width: 35%;
    text-align: center;

    div {
      display: flex;
      align-items: center;
      justify-content: space-around;

      padding: 8px 0;

      color: ${theme.colors.gray_dark};

      border: 1px solid ${theme.colors.gray_light};
      border-radius: 8px;

      button {
        color: inherit;
        background-color: transparent;
        border: none;
      }

      svg {
        font-size: 16px;
      }
    }

    > span {
      display: block;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 16,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin: 0 auto;
`;

export const SpecialInstructionsWrapper = styled.section`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    margin-right: 4px;
    color: ${theme.colors.error};
    font-size: 18px;
  }

  span {
    font-size: 14px;
  }
`;

export const ErrorTooltip = styled.div.attrs({
  className: 'error__Tooltip',
})`
  position: absolute;
  bottom: 0;
  transform: translateY(80%);
  left: 8;
  padding: 8px;
  background-color: ${theme.colors.error};
  color: ${theme.colors.white};
  font-size: 14px;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 100ms ease-in-out;
`;
