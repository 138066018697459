import { theme, Card } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.section`
  height: 100vh;
  width: 100vw;
  background: ${theme.colors.gray_light};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  button {
    margin-top: 18px;
    margin-left: auto;
  }
`;

export const ErrorBox = styled(Card).attrs({
  padded: true,
})`
  margin-top: 18px;
  padding: 20px;
  border-top: 3px solid ${theme.colors.error};

  display: flex;
  flex-direction: column;

  pre {
    margin-top: 18px;
    background: ${theme.colors.gray_lighter};
    padding: 30px;
    font-family: 'Courier Prime', monospace;
    border-radius: 8px;
  }
`;
