import React from 'react';
import { Typography } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { useOrder } from '@hooks';

import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { useAppSelector } from '@store';
import { ChecklistItem } from './ChecklistItem';
import * as S from '../styles';

export const OrderIsValid = () => {
  const { isValidatingBasket } = useOrder().pending;
  const { useOrderService } = useAppSelector(getStoreFeatureFlags);
  const checkListBasedOnFeatureFlag = useOrderService
    ? [ORDER_CHECKLIST.CART_ITEMS_ARE_VALID]
    : [ORDER_CHECKLIST.HAS_VALIDATED, ORDER_CHECKLIST.CART_ITEMS_ARE_VALID];
  return (
    <ChecklistItem field={checkListBasedOnFeatureFlag}>
      <Typography weight="heavy">Order is valid</Typography>
      {isValidatingBasket && <S.Loading />}
    </ChecklistItem>
  );
};
