import logger from '@logger';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const bypassSuccess = ['/status'];

function onSuccess(response: AxiosResponse) {
  const { config, data: responseData, status } = response;

  if (!config) {
    logger.error('Request succeeded without config', {
      responseData,
      status,
    });
  }

  const { method, baseURL, url, data: requestData } = config;

  const shouldBypassLog = bypassSuccess.find((bypass) => url?.includes(bypass));

  if (shouldBypassLog !== undefined) {
    return response;
  }

  const message = `${method?.toUpperCase()} ${baseURL}${url} finished successfully with status ${status}`;
  const attributes = { requestData, responseData };
  logger.info(message, attributes);
  return response;
}

function onError(error: AxiosError) {
  if (!error.response) {
    logger.error('Request failed without response', { axiosError: error });
    return Promise.reject(error);
  }

  const { config, data: responseData, status } = error.response;

  if (!config) {
    logger.error('Request failed without config', {
      responseData,
      status,
      axiosError: error,
    });

    return Promise.reject(error);
  }

  const { method, baseURL, url, data: requestData } = config;
  const message = `${method?.toUpperCase()} ${baseURL}${url} failed with status ${status}`;
  const attributes = { requestData, responseData };
  logger.error(message, attributes);
  return Promise.reject(error);
}

type InterceptorFunction<T = any> = () => T;

function safeInterceptor<T>(interceptor: InterceptorFunction<T>): T | void {
  try {
    return interceptor();
  } catch (error) {
    logger.error(
      `${interceptor.name} interceptor threw an error. Request was not logged.`,
      { interceptorError: error },
    );
  }
}

export function addLoggerInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response) => safeInterceptor(() => onSuccess(response)),
    (error) => safeInterceptor(() => onError(error)),
  );
}
