import { createEntityAdapter } from '@reduxjs/toolkit';

import { Category, Item, MenuState, Modifier, Option } from './types';

export const categoryAdapter = createEntityAdapter<Category>({
  selectId: (category) => category.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const itemAdapter = createEntityAdapter<Item>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const modifierAdapter = createEntityAdapter<Modifier>({
  selectId: (modifier) => modifier.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const optionAdapter = createEntityAdapter<Option>({
  selectId: (option) => option.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const linkedSpecialAdapter = createEntityAdapter<Item>({
  selectId: (item) => item.id,
  sortComparer: (a, b) => a.sortOrder - b.sortOrder,
});

export const initialMenuState: MenuState = {
  customUpsells: [],
  menuUpsells: [],
  upsold: { ids: [], type: null },
  isFetchingUpsells: false,

  recipientName: '',
  specialInstructions: '',
  categories: categoryAdapter.getInitialState({
    isFetching: false,
    hasFetchingError: false,
    current: null,
  }),
  items: itemAdapter.getInitialState({
    isFetching: false,
    hasFetchingError: false,
    current: null,
    isEditing: false,
    searchTerm: '',
  }),
  modifiers: modifierAdapter.getInitialState({
    isFetching: false,
    hasFetchingError: false,
    highlighted: null,
    prompted: {},
    noSelection: {},
  }),
  options: optionAdapter.getInitialState({
    isFetching: false,
    hasFetchingError: false,
    added: {},
    fullyAddedBefore: {},
  }),
  linkedSpecials: itemAdapter.getInitialState({
    isFetching: false,
    hasFetchingError: false,
    current: null,
    isEditing: false,
    searchTerm: '',
  }),
};
