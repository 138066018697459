import { FiThumbsUp, FiThumbsDown, FiPhoneCall } from 'react-icons/fi';
import { WorkerStatus as StatusType } from '@modules/taskrouter/types';
import { AllColors } from '@kea-inc/parrot-ui/dist/types';
import { IconType } from 'react-icons';
import { BsClock } from 'react-icons/bs';
import { GiKnifeFork } from 'react-icons/gi';
import { PiToilet } from 'react-icons/pi';
import { LuDumbbell } from 'react-icons/lu';
import { LiaChalkboardTeacherSolid } from 'react-icons/lia';
import { AiOutlineAlert } from 'react-icons/ai';

export function getAgentStatusesList() {
  const agentStatuses: Partial<
    Record<
      StatusType,
      {
        color: AllColors;
        icon?: IconType;
        hasButton?: boolean;
        surveyButton?: boolean;
      }
    >
  > = {
    Offline: {
      color: 'error',
      icon: FiThumbsDown,
    },
    Idle: {
      color: 'success',
      icon: FiThumbsUp,
    },
    Pending: {
      color: 'warning',
      icon: FiThumbsDown,
    },
    Busy: {
      color: 'warning',
      icon: FiPhoneCall,
    },
    Error: {
      color: 'error',
      icon: FiThumbsDown,
    },
    Break: {
      color: 'primary',
      icon: BsClock,
      hasButton: true,
      surveyButton: true,
    },
    Lunch: {
      color: 'primary_dark',
      icon: GiKnifeFork,
      hasButton: true,
      surveyButton: true,
    },
    Bio_Break: {
      color: 'accent_2',
      icon: PiToilet,
      hasButton: true,
      surveyButton: true,
    },
    Training: {
      color: 'accent_1_dark',
      icon: LuDumbbell,
      hasButton: true,
    },
    Coaching: {
      color: 'primary_darker',
      icon: LiaChalkboardTeacherSolid,
      hasButton: true,
    },
    Absent: {
      color: 'error',
      icon: AiOutlineAlert,
    },
    Network_Issue: {
      color: 'black',
    },
  };
  return agentStatuses;
}
