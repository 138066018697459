import React, { useMemo, useState } from 'react';
import { findMatchingUpsell } from '@kea-inc/order';
import { Upsell, CartItem as CommonCartItem } from '@kea-inc/types';

import store, { useAppDispatch, useAppSelector } from '@store';
import { saveCustomUpsold } from '@modules/menu/slice';
import { selectAllCartItems, selectIsCartEmpty } from '@modules/cart/selectors';
import { CartItem } from '@modules/cart/types';

const NewUpsellContext = React.createContext({});

export function NewUpsellProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const customUpsells = useAppSelector((state) => state.menu.customUpsells);
  const cartItems = useAppSelector((state) =>
    selectAllCartItems(state.cart.cartItems),
  );
  const isCartEmpty = useAppSelector(selectIsCartEmpty);

  const [upsoldByAgent, setUpsoldByAgent] = useState(false);
  const matchingUpsellRef = React.useRef<Upsell | null>(null);

  const upsellMessage = useMemo(() => {
    if (!customUpsells?.length || !cartItems.length) {
      return 'Would you like anything else?';
    }

    const convertedCartItems = cartItems
      .map(convertCartItemToUpsellItem)
      .filter(Boolean);

    // TODO: fix on cart upgrade iteration
    const upsell = findMatchingUpsell(convertedCartItems, customUpsells);
    matchingUpsellRef.current = upsell;

    return upsell?.phrase || 'Would you like anything else?';
  }, [cartItems, customUpsells]);

  function handleUpsell() {
    const upsell = matchingUpsellRef.current;
    setUpsoldByAgent(true);

    if (!upsell) {
      return;
    }

    if (upsell.promoId) {
      dispatch(saveCustomUpsold({ ids: [upsell.promoId], type: 'promo' }));
      return;
    }

    // IN THE FUTURE WE CAN DIFFERENTIATE BETWEEN ITEM AND CATEGORY
    dispatch(saveCustomUpsold({ ids: upsell.chainIds, type: 'item' }));
  }

  return (
    <NewUpsellContext.Provider
      value={{
        upsellMessage,
        handleUpsell,
        matchingUpsell: matchingUpsellRef.current,
        shouldUpsell:
          customUpsells?.length > 0 && !upsoldByAgent && !isCartEmpty,
      }}
    >
      {children}
    </NewUpsellContext.Provider>
  );
}

export function useUpsellV2() {
  const context = React.useContext(NewUpsellContext);
  if (context === undefined) {
    throw new Error('useUpsellV2 must be used within a NewUpsellProvider');
  }
  return context;
}

// DO NOT EXPORT
interface UpsellItem {
  cartItemId: string;
  quantity: number;
  menuItem: {
    chainId: string;
    modifiers: Array<{
      chainId: string;
    }>;
  };
  cartOptionsByModifierId: Record<
    string,
    {
      option: {
        chainId: string;
      };
      quantity: number;
    }[]
  >;
}

function getItemById(itemId: string) {
  return store.getState().menu.items.entities[itemId];
}

function getModifierById(modifierId: string) {
  return store.getState().menu.modifiers.entities[modifierId];
}

function getOptionById(optionId: string) {
  return store.getState().menu.options.entities[optionId];
}

function convertCartItemToUpsellItem(cartItem: CartItem) {
  const item = getItemById(cartItem.menuItemId);

  if (!item) {
    return {} as CommonCartItem;
  }

  const menuItem = {
    chainId: item.chainId,
    modifiers: item.modifierIds.map((modifierId) => ({
      chainId: getModifierById(modifierId)!.chainId,
    })),
  };

  const cartOptionsByModifierId = convertOptionsToCartOptionsByModifierId(
    cartItem.options,
  );

  return {
    cartItemId: cartItem.id,
    quantity: cartItem.quantity,
    menuItem,
    cartOptionsByModifierId,
  } as CommonCartItem;
}

function convertOptionsToCartOptionsByModifierId(options: CartItem['options']) {
  const cartOptionsByModifierId: UpsellItem['cartOptionsByModifierId'] = {};

  if (!options) {
    return cartOptionsByModifierId;
  }

  options.forEach((option) => {
    const menuOption = getOptionById(option.id);

    if (!menuOption) {
      return;
    }

    if (!cartOptionsByModifierId[menuOption.parentId]) {
      cartOptionsByModifierId[menuOption.parentId] = [];
    }

    cartOptionsByModifierId[menuOption.parentId].push({
      option: {
        chainId: menuOption.chainId,
      },
      quantity: option.quantity,
    });
  });

  return cartOptionsByModifierId;
}
