import pluralize from 'pluralize';
import { ConfirmationMessageHandoffModeEnum } from '@kea-inc/types';
import KeaOrder from '@kea-inc/order';

import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import * as accountSelectors from '@modules/account/selectors';
import * as featureFlagsSelectors from '@modules/featureFlags/selectors';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as agentSelectors from '@modules/agent/selectors';
import logger from '@logger';

import snakecaseKeys from 'snakecase-keys';
import {
  getServiceAPIVersion,
  getServiceInstance,
} from '@utils/getServiceForOrdersIntegration';
import { DateTime } from 'luxon';
import rootStore from '@store';
import { dataService } from '@services';
import { ORDER_CHECKLIST } from './state';
import * as selectors from './selectors';

export async function getOrder() {
  const orderId = selectors.getOrderId();
  const url = `/orders/${orderId}`;
  const response = await getServiceInstance().get(url);

  return response.data;
}

export async function validateBasket(version = 'v2') {
  const validateAttempts = selectors.getValidateAttempts();

  const id = selectors.getOrderId();

  const url = `/orders/${id}/validate`;
  const response = await dataService.post(
    url,
    {},
    {
      headers: {
        attempts: validateAttempts,
      },
      version,
    },
  );

  return response;
}

export async function submitOrder() {
  const id = selectors.getOrderId();

  const url = `/orders/${id}/submit`;
  const response = await getServiceInstance().post(url);

  return response.data;
}

export async function updateOrder(data, { isOloInfo = false }) {
  const id = selectors.getOrderId();

  if (!id) throw new Error('Order is no longer active');

  if (isOloInfo) {
    await createOloOrderInfo();
  }

  const url = isOloInfo ? `/orders/${id}/olo_order_info` : `/orders/${id}`;
  const response = await getServiceInstance().patch(url, data);

  return response.data;
}

export async function createOloOrderInfo() {
  try {
    const id = selectors.getOrderId();
    const response = await getServiceInstance().post(
      `/orders/${id}/olo_order_info`,
    );
    return response.data?.olo_store_handoff_methods;
  } catch (error) {
    logger.debug(`Unable to create olo order info due to ${error.message}`);
  }
}

// HANDLE ESTIMATION TIME
export function getVBxClosingMessage() {
  const { firstName } = accountSelectors.getAccountDetails();
  const { handOffMode } = selectors.getDetails();

  if (handOffMode) {
    return `Thank you ${firstName}.
    Your order has been placed and will be ${
      ConfirmationMessageHandoffModeEnum[handOffMode]
    } ${formatEstimation()}. See you soon!`;
  }

  return `Thank you ${firstName}.
  Your order has been placed and will be ready for pickup ${formatEstimation()}. See you soon!`;
}

function formatEstimation() {
  const { uoaKeaTequilaDisableEstimatedTime } =
    featureFlagsSelectors.getBrandFeatureFlags(rootStore.getState());

  if (uoaKeaTequilaDisableEstimatedTime) {
    return 'shortly';
  }

  const { isASAP, deliveryTime, oloEstimation } = selectors.getDetails();

  if (isASAP && oloEstimation < 60) {
    return `in ${oloEstimation} minutes`;
  }

  if (isASAP && oloEstimation >= 60) {
    const etaHours = Math.floor(oloEstimation / 60);
    const etaMinutes = oloEstimation % 60;

    return `in ${etaHours} ${pluralize(
      'hour',
      etaHours,
    )} and ${etaMinutes} minutes`;
  }

  if (isASAP && oloEstimation == null) {
    logger.error(
      'OLO estimation returned invalid response: ',
      selectors.getDetails(),
    );
    return `in about 15 minutes`;
  }

  return getFutureOrderETA(new Date(deliveryTime));
}

export function parseReadyTime(time) {
  // TIME COMES FROM OLO VALIDATION AS A STRING LIKE '20210628 14:00'. HENCE THE PARSING BELOW
  const year = Number(time.slice(0, 4));
  const month = Number(time.slice(4, 6)) - 1;
  const day = Number(time.slice(6, 8));
  const hours = Number(time.slice(-5, -3));
  const minutes = Number(time.slice(-2));

  const parsedReadyTime = new Date(year, month, day, hours, minutes);

  return parsedReadyTime;
}

// READYTIME ALREADY COMES IN THE STORE'S TIMEZONE
export function getIntervalInMinutes(readytime) {
  const timezone = deprecatedGetStoreTimezone();
  const zonedNow = DateTime.fromObject(
    DateTime.now().setZone(timezone).toObject(),
  );
  const { minutes } = DateTime.fromJSDate(readytime).diff(zonedNow, [
    'minutes',
  ]);
  return Math.floor(minutes);
}

function getFutureOrderETA(deliveryTime) {
  const timezone = 'America/New_York';
  const deliveryDateTime = DateTime.fromJSDate(deliveryTime);
  const now = DateTime.now();

  if (deliveryDateTime.hasSame(now, 'day')) {
    const { minutes } = deliveryDateTime.diff(now, ['minutes']);

    const intervalInMinutes = Math.ceil(minutes);

    const etaHours = Math.floor(intervalInMinutes / 60);
    const etaMinutes = intervalInMinutes % 60;

    if (intervalInMinutes > 60) {
      return `in ${etaHours} ${pluralize(
        'hour',
        etaHours,
      )} and ${etaMinutes} minutes`;
    }

    return `in ${intervalInMinutes} minutes`;
  }

  const deliveryTimeWithTimeZone = deliveryDateTime.setZone(timezone);
  return `on ${deliveryTimeWithTimeZone.toFormat(
    "cccc, MMMM dd 'at' hh:mm a",
  )}`;
}

export function getOloEstimation(readytime) {
  const parsedReadyTime = parseReadyTime(readytime);
  return getIntervalInMinutes(parsedReadyTime);
}

export async function syncOrder() {
  const databaseTranslatedDetails = selectors.getDatabaseTranslatedDetails();
  return updateOrder(databaseTranslatedDetails);
}

export function buildChecklist() {
  const { handOffMode } = selectors.getDetails();
  const checklist = selectors.getChecklist();

  const shouldTip = getShouldTip();

  const newChecklist = {};
  const deliveryHandoffs = ['delivery', 'dispatch'];

  // If the blocker is true, it will not be set as a checklist item
  const blockers = {
    [ORDER_CHECKLIST.HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS]:
      !deliveryHandoffs.includes(handOffMode),
    [ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS]:
      !deliveryHandoffs.includes(handOffMode),
    [ORDER_CHECKLIST.ADDRESS_IS_VALID]: !deliveryHandoffs.includes(handOffMode),
    [ORDER_CHECKLIST.HAS_ASKED_FOR_TIP]: !shouldTip,
    [ORDER_CHECKLIST.TABLE_NUMBER_FILLED]: handOffMode !== 'dine-in',
    [ORDER_CHECKLIST.HAS_CONFIRMED_LOYALTY_POINTS]: handOffMode !== 'dine-in',
  };

  for (const field of Object.values(ORDER_CHECKLIST)) {
    if (blockers[field] !== true) {
      newChecklist[field] = checklist?.[field] || false;
    }
  }

  return newChecklist;
}

export function evaluateTipsChecklist() {
  const brandFeatureFlags = featureFlagsSelectors.getBrandFeatureFlags(
    rootStore.getState(),
  );

  const { disableTipsForCurbside } = brandFeatureFlags;

  const checklist = selectors.getChecklist();

  if (!disableTipsForCurbside) {
    return checklist;
  }

  const shouldTip = getShouldTip(brandFeatureFlags);

  const newChecklist = {};

  for (const field of Object.values(ORDER_CHECKLIST)) {
    if (field !== ORDER_CHECKLIST.HAS_ASKED_FOR_TIP) {
      if (field in checklist) {
        newChecklist[field] = checklist[field];
      }
    } else if (shouldTip) {
      newChecklist[ORDER_CHECKLIST.HAS_ASKED_FOR_TIP] = false;
    }
  }

  return newChecklist;
}

export function getShouldTip() {
  const brand = taskrouterSelectors.deprecatedGetBrand();
  const store = taskrouterSelectors.getStore();
  const { handOffMode } = selectors.getDetails();

  const brandFeatureFlags = featureFlagsSelectors.getBrandFeatureFlags(
    rootStore.getState(),
  );

  // If the tip will be offered at paybot, we don't need to ask for it on UOA
  if (brandFeatureFlags?.enablePaybotTipV2) {
    return false;
  }

  return KeaOrder.getShouldTip(
    { shouldTip: brand?.shouldTip },
    { shouldTip: store?.shouldTip },
    brandFeatureFlags,
    handOffMode,
  );
}

export function getErrorMessage(error) {
  try {
    return JSON.parse(error.request?.response ?? error.response?.data).error;
  } catch {
    return error.message;
  }
}

export function getValidationErrorMessage(error) {
  const errorMessage = getErrorMessage(error);
  const start = errorMessage.indexOf(':') + 1;
  const end = errorMessage.indexOf(',');
  return errorMessage.substring(start, end);
}

export async function createOrderEvent(name, tags, orderId) {
  const taskrouterOrderId = taskrouterSelectors.deprecatedGetOrderId();

  const eventTags = snakecaseKeys({
    ...tags,
    agent_id: agentSelectors.currentAgentId(),
    uoa: true,
  });

  const { data } = await getServiceInstance().post(
    `/orders/${orderId || taskrouterOrderId}/record_event`,
    {
      orderId: orderId || taskrouterOrderId,
      eventName: name,
      eventTags,
    },
  );

  return data;
}

export async function fetchAvailableTimes() {
  const orderId = selectors.getOrderId();

  const response = await getServiceInstance().get(
    `/orders/${orderId}/scheduleAvailability`,
    { version: getServiceAPIVersion() },
  );

  return response.data;
}
