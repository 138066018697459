import { types } from './types';

export function transcriptionsStreamClosed() {
  return {
    type: types.STREAM_CLOSED,
  };
}

export function transcriptionsStreamConnecting() {
  return {
    type: types.STREAM_CONNECTING,
  };
}

export function transcriptionsStreamConnected() {
  return {
    type: types.STREAM_CONNECTED,
  };
}

export function transcriptionsStreamNotConnected() {
  return {
    type: types.STREAM_NOT_CONNECTED,
  };
}

export function transcriptionsStreamError() {
  return {
    type: types.TRANSCRIPTS_STREAM_ERROR,
  };
}

export function transcriptsAddMessage(payload) {
  return {
    type: types.TRANSCRIPTS_ADD_MESSAGE,
    payload,
  };
}

export function transcriptsIntentClassifying() {
  return {
    type: types.TRANSCRIPTS_INTENT_CLASSIFYING,
  };
}

export function transcriptsIntentClassified(target, payload) {
  return {
    target,
    type: types.TRANSCRIPTS_INTENT_CLASSIFIED,
    payload,
  };
}

export function transcriptsIntentNotClassified() {
  return {
    type: types.TRANSCRIPTS_INTENT_NOT_CLASSIFIED,
  };
}

export function transcriptionsReset() {
  return {
    type: types.RESET,
  };
}

export function transcriptionsFetching() {
  return {
    type: types.FETCHING_TRANSCRIPTS,
  };
}

export function transcriptionsFetched(payload) {
  return {
    type: types.FETCHED_TRANSCRIPTS,
    payload,
  };
}

export function transcriptionsNotFetched() {
  return {
    type: types.NOT_FETCHED_TRANSCRIPTS,
  };
}

export function autocartEventCreating() {
  return {
    type: types.AUTOCART_EVENT_CREATING,
  };
}

export function autocartEventCreated() {
  return {
    type: types.AUTOCART_EVENT_CREATED,
  };
}

export function autocartEventNotCreated() {
  return {
    type: types.AUTOCART_EVENT_NOT_CREATED,
  };
}
