export const fields = {
  NUMBER: 'cc_pan',
  CVV: 'cvv',
  EXP_DATE: 'expiry_date',
  ZIPCODE: 'zip_code',
};

export const streamFields = {
  NUMBER: 'ccn',
  CVV: 'cvv',
  EXP_DATE: 'exp',
  ZIPCODE: 'zip',
};

export const INITIAL_STATE = {
  manualCreditCard: {
    [fields.NUMBER]: '',
    [fields.CVV]: '',
    [fields.EXP_DATE]: '',
    [fields.ZIPCODE]: '',
  },
  creditCard: {
    [streamFields.NUMBER]: '',
    [streamFields.EXP_DATE]: '',
    [streamFields.ZIPCODE]: '',
    [streamFields.CVV]: '',
  },
  // paybot, manual
  isPaybotOverlayOpen: false,
  isManualPaymentOverlayOpen: false,
  paybotRanOnce: false,
  streamConnected: false,
  pending: {
    isConnectingStream: false,
    isConnectingPaybot: false,
    isTerminatingPaybot: false,
    isProcessingManualPayment: false,
  },
  errors: {
    hasConnectToStreamError: false,
    hasConnectToPaybotError: false,
    hasTerminatePaybotError: false,
    hasProcessManualPaymentError: false,
  },
};
