import { Interval } from './types';

type AvailableTimeProps = {
  interval: Interval;
};

export function AvailableTime({ interval }: AvailableTimeProps) {
  return (
    <>
      {interval.formattedTime} ({interval.diffToNow})
    </>
  );
}
