import { types } from './types';

export function addTips(payload) {
  return {
    type: types.ADD_TIPS,
    payload,
  };
}

export function setOrderId(payload) {
  return {
    type: types.SET_ID,
    payload,
  };
}

export function setDetails(payload) {
  return {
    type: types.SET_DETAILS,
    payload,
  };
}

export function removeChecklistItem(payload) {
  return {
    type: types.REMOVE_CHECKLIST_ITEM,
    payload,
  };
}

export function setChecklist(payload) {
  return {
    type: types.SET_CHECKLIST,
    payload,
  };
}

export function validatingBasket() {
  return {
    type: types.VALIDATING,
  };
}

export function validatedBasket(payload) {
  return {
    type: types.VALIDATED,
    payload,
  };
}

export function notValidatedBasket() {
  return {
    type: types.NOT_VALIDATED,
  };
}

export function validationDismissed() {
  return {
    type: types.VALIDATION_DISMISSED,
  };
}

export function submittingOrder() {
  return {
    type: types.SUBMITTING,
  };
}

export function submittedOrder() {
  return {
    type: types.SUBMITTED,
  };
}

export function notSubmittedOrder() {
  return {
    type: types.NOT_SUBMITTED,
  };
}

export function changeTipsOverlayVisibility(payload) {
  return {
    type: types.CHANGE_TIPS_OVERLAY_VISIBILITY,
    payload,
  };
}

export function changeSubmitOverlayVisibility(payload) {
  return {
    type: types.CHANGE_SUBMIT_OVERLAY_VISIBILITY,
    payload,
  };
}

export function changeChecklistVisibility(payload) {
  return {
    type: types.CHANGE_CHECKLIST_VISIBILITY,
    payload,
  };
}

export function changeTaxAndFeesOverlayVisibility(payload) {
  return {
    type: types.CHANGE_TAX_AND_FEES_OVERLAY_VISIBILITY,
    payload,
  };
}

export function reset() {
  return {
    type: types.RESET,
  };
}

export function updatingOrder() {
  return {
    type: types.UPDATING,
  };
}

export function updatedOrder(payload) {
  return {
    type: types.UPDATED,
    payload,
  };
}

export function notUpdatedOrder() {
  return {
    type: types.NOT_UPDATED,
  };
}

export function endOrder() {
  return {
    type: types.END_ORDER,
  };
}

export function orderSyncing() {
  return {
    type: types.ORDER_SYNCING,
  };
}

export function orderSynced() {
  return {
    type: types.ORDER_SYNCED,
  };
}

export function orderNotSynced() {
  return {
    type: types.ORDER_NOT_SYNCED,
  };
}

export function buildingChecklist() {
  return {
    type: types.BUILDING_CHECKLIST,
  };
}

export function buildChecklist(payload) {
  return {
    type: types.BUILD_CHECKLIST,
    payload,
  };
}

export function eventCreating() {
  return {
    type: types.EVENT_CREATING,
  };
}

export function eventCreated() {
  return {
    type: types.EVENT_CREATED,
  };
}

export function eventNotCreated() {
  return {
    type: types.EVENT_NOT_CREATED,
  };
}

export function setItemsValidationStatus(payload) {
  return {
    type: types.SET_ITEMS_VALIDATION_STATUS,
    payload,
  };
}
