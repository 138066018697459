import { MouseEventHandler } from 'react';
import { Card, theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export interface OptionProps {
  borderColor?: string;
  onClick?: (event: MouseEventHandler<HTMLElement>) => void;
}

export const Option = styled(Card)<OptionProps>`
  display: flex;
  align-items: center;

  min-height: 2rem;
  width: 30%;
  padding: 0.5rem;

  border-left: 0.25rem solid
    ${(props) => props.borderColor ?? theme.colors.primary_dark};

  position: relative;
  cursor: pointer;
`;
