import React from 'react';
import PropTypes from 'prop-types';

import { HistoryProvider } from './HistoryProvider';
import { RedirectProvider } from './RedirectProvider';
import { OrderPatchProvider } from './OrderPatchProvider';
import { UnloadProvider } from './UnloadProvider';
import { NotificationProvider } from './NotificationProvider';
import { CacheProvider } from './CacheProvider';
import { ValidationProvider } from './ValidationProvider';

export const AppProvider = ({ children }) => (
  <CacheProvider>
    <UnloadProvider>
      <HistoryProvider>
        <RedirectProvider>
          <OrderPatchProvider>
            <NotificationProvider>
              <ValidationProvider>{children}</ValidationProvider>
            </NotificationProvider>
          </OrderPatchProvider>
        </RedirectProvider>
      </HistoryProvider>
    </UnloadProvider>
  </CacheProvider>
);

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
