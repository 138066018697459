import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import menuService from '@services/menu-service';
import { apolloClient } from '../../../graphql/apollo-client';
import * as schema from '../../../graphql/generated/schema';
import { SoundboardEntry } from './types';

export async function fetchSoundboardPrompts({
  platformName,
  storeId,
  brandId,
}: {
  platformName: string;
  storeId: string;
  brandId: string;
}) {
  const response = await apolloClient.query<
    schema.GetSoundboardPromptsQuery,
    schema.GetSoundboardPromptsQueryVariables
  >({
    query: schema.GetSoundboardPromptsDocument,
    variables: {
      platformName,
      storeId,
      brandId,
    },
  });

  return response.data.store.edges[0].node.metadata?.soundboardByContext || {};
}

export async function fetchSoundboardEntries() {
  const store = taskrouterSelectors.getStore();

  if (!store) {
    return [];
  }

  const response = await menuService.get<SoundboardEntry[]>(
    `/soundboard-entries/${store.id}`,
  );

  return response.data;
}
