import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import env from '@beam-australia/react-env';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const domain = env('AUTH0_DOMAIN');
  const clientId = env('AUTH0_CLIENT_ID');
  const audience = env('AUTH0_AUDIENCE');

  const onRedirectCallback = (appState) => {
    history.replace(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};

export default Auth0ProviderWithHistory;
