import { Coupon } from '@modules/coupons/types';
import { formatList, pluralize } from '@utils';

export function makeCouponsListMessage(coupons: Coupon[]) {
  const couponNames = coupons.map((coupon) => coupon.name);

  return `We currently have ${couponNames.length} special ${pluralize(
    'deal',
    couponNames.length,
  )}: ${formatList(couponNames)}. Which deal would you like to hear about?`;
}
