import { DateTime } from 'luxon';
import { CompressedMenu, CompressedMenuParser } from '@kea-inc/menu';

import * as agentSelectors from '@modules/agent/selectors';
import * as taskRouterSelectors from '@modules/taskrouter/selectors';
import { APICartItemOption } from '@modules/cart/types';
import { analyticsService, menuService, orderService } from '@services';
import * as selectors from './selectors';

import {
  AddAgentToOrderPayload,
  OrderPayload,
  SubmitOrderSurveyPayload,
  UpdateOrderPayload,
  Validation,
  ValidationErrorCode,
} from './types';
import { deprecatedGetStoreTimezone } from '../taskrouter/selectors';

export async function updateOrder(props: UpdateOrderPayload) {
  const id = selectors.getOrderId();
  const url = `/orders/${id}`;
  const response = await orderService.patch<{
    order: OrderPayload;
    validation: Validation;
  }>(url, props);
  return response.data;
}

export async function addAgentToOrder(orderId: string) {
  const agent = agentSelectors.currentAgent();
  const timezone = deprecatedGetStoreTimezone();
  const payload: AddAgentToOrderPayload = {
    agentId: agent.id,
    bpoId: agent.bpo_id,
    createdAt: DateTime.fromJSDate(new Date(), { zone: timezone })
      .toUTC()
      .toISO(),
  };
  const url = `/orders/${orderId}/agents`;
  const response = await orderService.post<AddAgentToOrderPayload>(url, {
    orderAgents: [payload],
  });
  return response.data;
}

export async function submitSurvey(
  surveyData: SubmitOrderSurveyPayload,
  orderId: string,
) {
  const url = `/orders/${orderId}/surveys`;
  const response = await orderService.post<SubmitOrderSurveyPayload>(
    url,
    surveyData,
  );
  return response.data;
}

const messagesByErrorCode: Record<
  string,
  {
    title: string;
    message: string;
    onDismissPrompt?: string;
  }
> = {
  EXCESS_SINGLE_ITEM_QUANTITY: {
    title: 'Excessive Single Item Quantity',
    message: 'You have added too many of a single item to your order.',
  },
  EXCESS_SINGLE_OPTION_QUANTITY: {
    title: 'Excessive Single Option Quantity',
    message: 'You have selected too many of a single option for an item.',
  },
  EXCESS_TOTAL_ITEM_QUANTITY: {
    title: 'Excessive Total Item Quantity',
    message:
      'The total quantity of items in your order exceeds the allowed limit.',
  },
  EXCESS_TOTAL_OPTION_QUANTITY: {
    title: 'Excessive Total Option Quantity',
    message:
      'The total quantity of options selected for items in your order exceeds the allowed limit.',
  },
  EXCESS_UNIQUE_OPTIONS: {
    title: 'Excessive Unique Options',
    message: 'You have chosen too many unique options for your order.',
  },
  INSUFFICIENT_SINGLE_ITEM_QUANTITY: {
    title: 'Insufficient Single Item Quantity',
    message:
      'You have not added enough of a single item to meet the minimum quantity requirement.',
  },
  INSUFFICIENT_SINGLE_OPTION_QUANTITY: {
    title: 'Insufficient Single Option Quantity',
    message:
      'You have not selected enough of a single option for an item to meet the minimum quantity requirement.',
  },
  INSUFFICIENT_TOTAL_OPTION_QUANTITY: {
    title: 'Insufficient Total Option Quantity',
    message:
      'The total quantity of options selected for items in your order does not meet the minimum requirement.',
  },
  INSUFFICIENT_UNIQUE_OPTIONS: {
    title: 'Insufficient Unique Options',
    message:
      'You have not chosen enough unique options to meet the minimum requirement for your order.',
  },
  INVALID_ITEM_QUANTITY_INTERVAL: {
    title: 'Invalid Item Quantity Interval',
    message:
      'The quantity of items you have selected does not match the allowed interval.',
  },
  INVALID_OPTION_QUANTITY_INTERVAL: {
    title: 'Invalid Option Quantity Interval',
    message:
      'The quantity of options you have selected for an item does not match the allowed interval.',
  },
  ITEM_OUTSIDE_AVAILABLE_TIME: {
    title: 'Item Outside Available Time',
    message: 'The selected item is not available for order at this time.',
  },
  ITEM_OUT_OF_STOCK: {
    title: 'Item Out of Stock',
    message: 'The selected item is currently out of stock.',
  },
  MODIFIER_OUTSIDE_AVAILABLE_TIME: {
    title: 'Modifier Outside Available Time',
    message: 'The selected modifier is not available for order at this time.',
  },
  OPTION_OUT_OF_STOCK: {
    title: 'Option Out of Stock',
    message: 'The selected option for an item is currently out of stock.',
  },
  OPTION_OUTSIDE_AVAILABLE_TIME: {
    title: 'Option Outside Available Time',
    message:
      'The selected option for an item is not available for order at this time.',
  },
  REQUIRED_MODIFIER_MISSING_OPTIONS: {
    title: 'Required Modifier Missing Options',
    message: 'You have not selected all the required modifiers for your order.',
  },
  ITEM_UNAVAILABLE_IN_HANDOFF_MODE: {
    title: 'Item Unavailable in Handoff Mode',
    message: 'The selected item is not available for order in handoff mode.',
  },
  INVALID_ITEM: {
    title: 'Invalid Item',
    message: 'The selected item is invalid or does not exist.',
  },
  INVALID_OPTIONS: {
    title: 'Invalid Options',
    message: 'The selected options are invalid or do not exist.',
  },
  STORE_CLOSED: {
    title: 'Store Closed',
    message: 'The store is currently closed and not accepting orders.',
  },
  INVALID_COUPONS: {
    title: 'Invalid Coupon',
    message: 'The coupon you have applied is invalid or expired.',
    onDismissPrompt:
      'The coupon you have applied is invalid or expired. Would you like to change anything?',
  },
  TRANSACTION_FAILED: {
    title: 'Transaction Failed',
    message:
      'There was an issue processing your transaction. Please try again later.',
  },
  COUPON_UNFULFILLED: {
    title: 'Coupon Unfulfilled',
    message:
      'The coupon you have applied is not valid for the items in your order.',
    onDismissPrompt:
      'The coupon you have applied is not valid for the items in your order. Would you like to change anything?',
  },
  COUPON_EXCLUSIVITY_VIOLATION: {
    title: 'Coupon Exlusivity Violation',
    message:
      'One or more of the coupons present cannot be used with other coupons.',
  },
  COUPON_UNAVAILABLE_FOR_HANDOFF_MODE: {
    title: 'Coupon Unavailable For Handoff Mode',
    message:
      'The coupon you have applied is not available for the current handoff mode.',
    onDismissPrompt:
      'The coupon you have applied is not available for the current handoff mode. Would you like to change anything?',
  },
  SCHEDULED_TIME_NOT_AVAILABLE: {
    title: 'Scheduled Time Not Available',
    message: 'The scheduled time you have selected is not available for order.',
  },
  CAPACITY_THROTTLED: {
    title: 'Order capacity reached',
    message: 'The store is experiencing high order volumes.',
  },
  BELOW_MINIMUM_COUPON_AMOUNT: {
    title: 'Below Minimum Amount',
    message:
      'The coupon you have applied requires a minimum order amount to be valid.',
    onDismissPrompt:
      'The coupon you have applied requires a minimum order amount to be valid. Would you like to change anything?',
  },
  COUPON_ALREADY_APPLIED: {
    title: 'Coupon Already Applied',
    message: 'The coupon you have applied is already present in your order.',
  },
};

export function mapOrderErrorToMessage(errorCode: ValidationErrorCode) {
  const message = messagesByErrorCode[errorCode];

  if (!message) {
    return {
      title: 'Unknown Error',
      message: `An unknown error has occurred: ${errorCode}`,
    };
  }

  return {
    title: message.title,
    message: message.message,
    onDismissPrompt: message.onDismissPrompt,
  };
}

export async function getMenuItemsSelections(
  menuItems: Array<{
    menuItemId: string;
    options?: APICartItemOption[];
  }>,
) {
  const store = taskRouterSelectors.getStore();

  if (!store) {
    throw new Error('Store not found');
  }

  const payload = {
    selections: (menuItems || []).map((item) => ({
      itemId: item.menuItemId,
      optionIds: (item?.options || []).map((option) => option.id),
    })),
  };

  const menuServiceUrl = `/menus/${store.id}/version/${encodeURIComponent(
    store?.menuVersion || '',
  )}/selections`;

  const responseMenuService = await menuService.post<CompressedMenu>(
    menuServiceUrl,
    payload,
  );

  const compressedMenuItems = new CompressedMenuParser(
    responseMenuService.data,
  );

  return compressedMenuItems;
}

export async function createAnalyticsEvent(
  eventName: string,
  eventInfo: string,
) {
  const payload = {
    callId: selectors.getOrderId(),
    eventName,
    eventInfo,
    createdAt: new Date().toISOString(),
  };

  const url = `/record-uoa-event`;

  const response = await analyticsService.post(url, payload);

  return response.status;
}

export function isCompressedMenuValid(compressedMenu: CompressedMenuParser) {
  const invalidItems = compressedMenu
    .getItems()
    ?.filter(
      (item) => !item.id || item.id === '' || typeof item.id !== 'string',
    );

  const invalidModifiers = compressedMenu
    .getModifiers()
    ?.filter(
      (modifier) =>
        !modifier.id || modifier.id === '' || typeof modifier.id !== 'string',
    );

  const invalidOptions = compressedMenu
    .getOptions()
    ?.filter(
      (option) =>
        !option.id || option.id === '' || typeof option.id !== 'string',
    );

  if (
    invalidItems?.length > 0 ||
    invalidModifiers?.length > 0 ||
    invalidOptions?.length > 0
  ) {
    return false;
  }

  return true;
}
