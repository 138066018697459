import styled from 'styled-components';
import { theme } from '@kea-inc/parrot-ui';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 460px;
  width: 350px;
`;

export const Header = styled.header`
  text-align: left;
  width: 100%;

  h4 {
    margin-bottom: 10px;
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  overflow: hidden;

  button {
    margin-top: 10px;
    margin-left: 6px;
  }

  p[role='paragraph'] {
    margin-top: 10px;
  }

  .input_money {
    margin-top: 10px;
    width: 120px;
  }

  .selected {
    border-color: ${theme.colors.primary};
    p {
      color: ${theme.colors.primary};
    }
  }
`;

export const ButtonsWrapper = styled.footer`
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.colors.gray_light};
  padding-top: 16px;
  width: 100%;
  margin-top: auto;

  .button__Remove {
    margin-right: auto;
    p {
      color: ${theme.colors.error};
    }
    &:disabled {
      p {
        color: ${theme.colors.gray_light};
      }
    }
  }

  .button__Cancel {
    margin-right: 8px;
  }
`;
