import { DeviceState } from '@modules/device/device-types';

const LOCAL_STORAGE_KEY = '@kea-inc/uoa/edge';
const storedEdge = () => localStorage.getItem(LOCAL_STORAGE_KEY);

export const initialDeviceState: DeviceState = {
  registered: false,
  preflight: null,
  token: null,
  isMuted: true,
  hasTriedHangup: false,
  hasForwarded: false,
  callbackCallSid: null,
  callAcceptedAt: null,
  hasOngoingCall: false,
  lastAnnouncement: null,
  announcementId: null,
  edge: storedEdge() ?? 'roaming',
  lastEdgeRun: null,
  pending: {
    isDisconnecting: false,
    isAcceptingCall: false,
    isFetchingToken: false,
    isPreflightRunning: false,
    isStartingDevice: false,
    isAnnouncingToConference: false,
    isForwardingStore: false,
    isCallingBack: false,
    isMuting: false,
    isHangingUp: false,
    isCheckingMicrophone: false,
  },
  errors: {
    hasFetchTokenError: false,
    hasPreflightError: false,
    hasDeviceStartError: false,
    hasAnnounceToConferenceError: false,
    hasForwardStoreError: false,
    hasCallBackError: false,
    hasMuteUnmuteError: false,
    hasHungUpError: false,
    hasMicrophoneError: false,
  },
};
