import styled, { keyframes, css } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

import { theme, Card } from '@kea-inc/parrot-ui';

export const Container = styled.main`
  max-width: ${(props) => `${props.large ? 65 : 31}rem`};
  width: 100%;
  margin: 0 auto;
  padding-top: 4.5rem;

  .preflightResults__Open {
    padding-left: 0;
  }
`;

export const PreflightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const BrandsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  height: fit-content;

  margin: 1.125rem 0;
  padding: 2.75rem 2.18rem;

  border-radius: 0.5rem;

  .no_brands {
    text-align: center;

    padding: 2rem 0;
  }

  &:hover {
    box-shadow: ${theme.elevations[1]};
  }

  .brands__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.125rem;
    padding-right: 1.25rem;
    width: 100%;
  }

  .brands__list {
    overflow-y: scroll;
    max-height: 11rem;

    ::-webkit-scrollbar {
      width: 0;
    }
  }

  .brand {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.625rem;

    .chip__queued {
      background: ${theme.colors.success_lightest_2};
      svg {
        color: ${theme.colors.success};
      }
    }

    > div {
      margin-left: auto;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 18,
})`
  animation: ${spin} 0.8s infinite linear;
  margin-left: 0.5rem;
  transform: translateY(3px);
  color: ${theme.colors.gray_darker};
`;

export const EdgeChooserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    min-width: 150px;
  }

  .edgeChooser__Select {
    width: 65%;

    menu {
      overflow: auto;
      height: 200px;
    }
  }
`;

export const TrainingContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  max-width: 31rem;
  width: 100%;
  margin: 1.125rem 2rem;
  padding: 2.75rem 2.18rem;
`;

export const TrainingSelects = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
  .training__Select {
    menu {
      overflow: auto;
      height: 200px;
    }
  }
`;

export const TrainingButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    background: ${theme.colors.accent_1_dark};
    min-width: 150px;
  }
`;

export const FooterContainer = styled.div`
  ${(props) =>
    props.large &&
    css`
      width: 50%;
      padding-right: 2rem;
    `}
`;
