import { useOrder } from '@hooks';
import AnnounceBadge from '@components/AnnounceBadge';

import { useAppSelector } from '@store';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { RandomMessageAnnounce } from './RandomMessageAnnounce';

// TODO: dumb component
export const NextItemBadge = ({ ...props }) => {
  const currentItemId = useAppSelector((state) => state.menu.items.current);
  const { checklist } = useOrder();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const message = 'What can I get for you today.';

  const handleBadgeClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  return checklist.cartHasItems || currentItemId ? (
    <RandomMessageAnnounce sampleMessages={messages} {...props}>
      Next Item
    </RandomMessageAnnounce>
  ) : (
    <AnnounceBadge {...props} message={message} onClick={handleBadgeClick}>
      Next Item
    </AnnounceBadge>
  );
};

const messages = [
  'What else can I get you.',
  'What else would you like.',
  'What else can I get for you today.',
  'What else can I get for you.',
  'What other items would you like.',
  'What other items can I get for you.',
  'What more can I get you.',
];
