import { theme, Typography } from '@kea-inc/parrot-ui';
import { Loading } from '@styles/loading';
import { MdCheckCircle } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const OptionInfo = styled.section`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: 100%;
`;

interface OptionNameProps {
  isSelected?: boolean;
  useFullWidth: boolean;
  collapseName?: boolean;
}

export const OptionName = styled(Typography).attrs({
  variant: 'caption',
})<OptionNameProps>`
  text-decoration: none;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'initial')};

  ${({ useFullWidth }) =>
    useFullWidth
      ? css`
          max-width: initial;
          width: initial;
        `
      : css`
          max-width: 90%;
          width: 85%;
        `}

  ${({ collapseName }) =>
    collapseName &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

export const OptionPrice = styled(Typography)`
  margin-left: auto;
`;

export const Check = styled(MdCheckCircle).attrs({
  color: theme.colors.success,
  size: 16,
})`
  margin-right: 0.3rem;
`;

export const LoadingSpinner = styled(Loading).attrs({
  size: 16,
})`
  color: ${theme.colors.success};
  margin-right: 0.3rem;
  margin-left: 0;
`;
