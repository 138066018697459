import { theme } from '@kea-inc/parrot-ui';
import styled, { css, keyframes } from 'styled-components';
import { FiChevronUp } from 'react-icons/fi';
import { ImSpinner2 } from 'react-icons/im';

export const ModifiersContainer = styled.section`
  width: 100%;
`;

const THREE_TILES_BREAKPOINT = 50;
const TWO_TILES_BREAKPOINT = 45;
const ONE_TILE_BREAKPOINT = 32;

interface ModifierProps {
  size: number;
  isHighlighted: boolean;
}

export const Modifier = styled.section<ModifierProps>`
  width: 100%;
  padding-bottom: 12px;

  ${(props) =>
    props.isHighlighted &&
    css`
      transition: outline 0.3s ease;
      outline: 3px solid #b91a2e;
      outline-offset: -7px;
      animation-delay: 4.6s;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: _border-fade;
      @keyframes _border-fade {
        from {
          outline-color: #b91a2e;
        }
        to {
          outline-color: rgba(0, 0, 0, 0);
        }
      }
    `}

  ${(props) =>
    props.size < THREE_TILES_BREAKPOINT &&
    css`
      .option__Card {
        width: 31%;
      }
    `}
  ${(props) =>
    props.size < TWO_TILES_BREAKPOINT &&
    css`
      .option__Card {
        width: 48%;
      }
    `}
    ${(props) =>
    props.size < ONE_TILE_BREAKPOINT &&
    css`
      .option__Card {
        width: 100%;
      }
    `};
`;

interface ModifierHeaderProps {
  hasNested: boolean;
}

export const ModifierHeader = styled.header<ModifierHeaderProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px 4px 16px;

  ${(props) =>
    props.hasNested &&
    css`
      background: ${theme.colors.gray_lighter};
    `}
`;

export const ModifierOptions = styled.section`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  padding: 0 16px;
`;

type NoSelectionContainerProps = {
  hidden: boolean;
};

export const NoSelectionContainer = styled.section<NoSelectionContainerProps>`
  margin-top: 1rem;
  display: flex;
  padding: 0 16px;
  width: 100%;

  button {
    width: 100%;
  }

  opacity: ${(props) => (props.hidden ? 0 : 1)};
  pointer-events: ${(props) => (props.hidden ? 'none' : 'all')};
`;

interface OpenCloseProps {
  isOpen: number;
}

export const OpenClose = styled(FiChevronUp)<OpenCloseProps>`
  color: ${theme.colors.primary_darker};
  margin-left: auto;
  transition: 0.2s all;
  cursor: pointer;
  ${(props) =>
    props.isOpen === 1 &&
    css`
      transform: rotate(180deg);
    `}
`;

interface BadgeContainerProps {
  isNested?: boolean;
}

export const BadgesContainer = styled.div<BadgeContainerProps>`
  display: flex;
  align-items: center;
  margin: 10px 0 10px 16px;
  ${(props) =>
    props.isNested &&
    css`
      margin: 10px 0 10px;
    `}

  .badge__Which {
    margin: 0 8px 0 0;
  }

  .badge__customWhich {
    margin: 0 8px 0 0;
    background: #fcd3b5;
    border: 1px solid #ea761f;
    span {
      color: #ea761f;
    }
  }

  .badge__Current {
    margin-left: 8px;
  }

  .selection__message {
    margin-left: 8px;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
