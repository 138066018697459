import styled from 'styled-components';
import { theme, Card } from '@kea-inc/parrot-ui';

type SoundboardContainerProps = {
  isChecklistOpen: boolean;
};

export const SoundboardContainer = styled(Card).attrs({
  selected: true,
  padded: true,
})<SoundboardContainerProps>`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ isChecklistOpen }) =>
    `calc(100% - ${isChecklistOpen ? 350 : 48}px - 12px)`};

  &:hover {
    box-shadow: ${theme.elevations[3]};
  }
`;

export const Header = styled.header`
  width: 100%;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    width: 10px;
  }

  .voicePane__badge {
    margin-top: 8px;
  }

  p[role='paragraph'] {
    margin: 8px 0;
    color: ${theme.colors.primary_darker};
    text-transform: capitalize;
  }
`;
