export const INITIAL_STATE = {
  messages: [],
  streamConnected: false,
  itemsByMessageId: {},
  lastAutocartTranscriptionId: null,
  hasFetchedTranscripts: false,
  pending: {
    isConnectingStream: false,
    isFetchingTranscripts: false,
    isClassifyingIntent: false,
    isCreatingAutocartEvent: false,
  },
  errors: {
    hasConnectToStreamError: false,
    hasFetchTranscriptsError: false,
    hasClassifyingIntentError: false,
    hasCreatingAutocartEventError: false,
  },
};
