import { useMemo } from 'react';
import { Button, Typography } from '@kea-inc/parrot-ui';
import { AnnounceIcon } from '@components';

import { formatPrice, removeHexCodes } from '@utils';

import { useAppDispatch, useAppSelector } from '@store';
import { selectItemById } from '@modules/menu/selectors';
import { removeAllOptions } from '@modules/menu/slice';
import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import * as S from './styles';

type ItemInformationProps = {
  itemId: string;
};

// TODO: dumb component
export function ItemInformation({ itemId }: ItemInformationProps) {
  const dispatch = useAppDispatch();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const item = useAppSelector((state) =>
    selectItemById(state.menu.items, itemId),
  );

  const added = useAppSelector((state) => state.menu.options.added);

  const hasAddedOptions = useMemo(() => Object.keys(added).length > 0, [added]);

  const cleanItemDescription = useMemo(
    () => removeHexCodes(item?.description ?? ''),
    [item],
  );

  if (!item) {
    return null;
  }

  const itemDescriptionMessage = `${item.spokenName}: ${cleanItemDescription}`;

  const handleItemNameAnnounceClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.NAME_ITEM,
        message: item.spokenName,
        itemId: item.id,
      },
    });
  };
  const handleItemDescriptionAnnounceClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.DESCRIPTION_ITEM,
        message: itemDescriptionMessage,
        itemId: item.id,
      },
    });
  };

  return (
    <S.Container>
      <S.Header>
        <Typography
          className="title"
          variant="subheader"
          color="primary_darker"
          weight="heavy"
        >
          {`${item.name} - ${formatPrice(item.cost)}`}
        </Typography>

        <AnnounceIcon
          variant="primary"
          message={item.spokenName}
          size={25}
          onClick={handleItemNameAnnounceClick}
        />

        {item.status === 'pending' && <S.Loading />}

        <div className="container__quickActions">
          {/* NOT USED ANYMORE - DEFAULTS ARE PRE SELECTED */}
          {/* {1 > 0 && (
            <div className="container__Default">
              <Button tertiary size="small" onClick={() => {}}>
                Default
              </Button>
            </div>
          )} */}

          {item.modifierIds.length > 0 && (
            <div className="container__Clear">
              <Button
                disabled={!hasAddedOptions}
                tertiary
                size="small"
                onClick={() => dispatch(removeAllOptions())}
              >
                Clear
              </Button>
            </div>
          )}
        </div>
      </S.Header>

      {item.description && (
        <Typography
          variant="caption"
          color="gray_darker"
          className="description"
        >
          {cleanItemDescription}
          <AnnounceIcon
            message={itemDescriptionMessage}
            variant="primary"
            size={25}
            onClick={handleItemDescriptionAnnounceClick}
          />
        </Typography>
      )}
    </S.Container>
  );
}
