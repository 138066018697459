import React, { useRef, useState, useEffect } from 'react';
import { MdPhoneForwarded } from 'react-icons/md';

import { useAppDispatch, useAppSelector } from '@store';
import { useDevice } from '@hooks';
import * as deviceActions from '@modules/device/device-actions';
import * as surveyAPI from '@modules/survey/api';
import { handlePaybotEndOfTask } from '@utils/handleEndOfTask';

import { createOrderEvent } from '@modules/order/utils';
import { getConferenceSid } from '@modules/taskrouter/selectors';
import Confirmation from './Confirmation';
import * as S from './styles';
import { useConnector } from '../useConnector';

const CallStoreBadge = () => {
  const dispatch = useAppDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [hasStartedForwarding, setHasStartedForwarding] = useState(false);
  const { isForwardingStore } = useDevice().pending;
  const badgeRef = useRef(null);

  const openConfirmation = () => setIsConfirmationOpen(true);
  const closeConfirmation = () => setIsConfirmationOpen(false);

  const conferenceSid = useAppSelector(getConferenceSid);

  const { selectors } = useConnector();
  const { isTrainingTask } = selectors;

  const handleCallStore = () => {
    setHasStartedForwarding(true);
    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    createOrderEvent('agent_forwarded_call_to_store');
    surveyAPI.setOrderStatusAsForwarded();
    dispatch(deviceActions.forwardToStore());
    handlePaybotEndOfTask(conferenceSid || undefined);
  };

  useEffect(() => {
    if (hasStartedForwarding && !isForwardingStore) {
      setHasStartedForwarding(false);
      closeConfirmation();
    }
  }, [isForwardingStore]);

  return (
    <>
      <S.Badge
        variant="error"
        leftIcon={MdPhoneForwarded}
        onClick={openConfirmation}
        ref={badgeRef}
        disabled={isTrainingTask}
      >
        Call Store
      </S.Badge>

      <Confirmation
        action="call store"
        anchor={badgeRef}
        open={isConfirmationOpen}
        onCancel={closeConfirmation}
        onConfirm={handleCallStore}
        isLoading={isForwardingStore}
      />
    </>
  );
};

export default CallStoreBadge;
