import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import env from '@beam-australia/react-env';

const DEFAULT_TTFA_MAX = 8;

const safeGetTtfaMax = () => {
  const ttfaMax = env('TTFA_MAX');
  const parsed = parseInt(ttfaMax, 10);
  if (Number.isInteger(parsed)) {
    return parsed;
  }
  return DEFAULT_TTFA_MAX;
};

export function TimeOut({ onFinish }) {
  const [duration, setDuration] = useState(safeGetTtfaMax());

  useEffect(() => {
    if (duration === 0) {
      onFinish();
    }
    const timer = setTimeout(() => {
      setDuration(duration - 1);
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <span style={{ marginTop: 10 }}>
      {`Reassigning if no action taken in ${duration}s...`}
    </span>
  );
}

TimeOut.propTypes = {
  onFinish: PropTypes.func.isRequired,
};
