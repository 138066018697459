import React from 'react';
import { Typography, Button } from '@kea-inc/parrot-ui';

import { Positions } from '@kea-inc/parrot-ui/dist/types';
import * as S from './styles';

export type ConfirmationProps = {
  anchor: React.MutableRefObject<any>;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  action: string;
  position?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export function Confirmation({
  anchor,
  onConfirm,
  onCancel,
  open = false,
  action,
  isLoading = false,
  isDisabled = false,
  position = 'bottomRight',
  ...props
}: ConfirmationProps) {
  const title = capitalizeFirstLetter(action);
  const description = `Are you sure you want to ${action}?`;
  const confirmText = `Yes, ${action}`;

  return (
    <S.Menu
      open={open}
      anchor={anchor}
      position={position as Positions}
      width={280}
      onClose={!isLoading ? onCancel : () => {}}
      {...props}
    >
      <S.Content>
        <Typography color="error" weight="heavy" variant="subheader">
          {title}
        </Typography>
        <Typography className="description">{description}</Typography>
        <footer>
          <Button
            onClick={onCancel}
            size="small"
            secondary
            disabled={isLoading}
          >
            No
          </Button>
          <Button
            onClick={onConfirm}
            size="small"
            danger
            loading={isLoading}
            disabled={isDisabled}
          >
            {confirmText}
          </Button>
        </footer>
      </S.Content>
    </S.Menu>
  );
}

function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export default Confirmation;
