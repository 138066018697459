import { useState, useEffect, useMemo } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { IoIosWarning } from 'react-icons/io';
import { RiInformationFill } from 'react-icons/ri';
import { BiChevronDown } from 'react-icons/bi';
import {
  Select,
  Option,
  Typography,
  AlertBar,
  Input,
  Button,
  ButtonGroup,
} from '@kea-inc/parrot-ui';

import { useAppSelector } from '@store';
import * as orderAPI from '@modules/order/api';
import { useOrder, useStoreHours, useDelivery } from '@hooks';
import { AnnounceBadge } from '@components';
import {
  TableNumberBadge,
  LoyaltyPointsBadge,
} from '@components/DynamicBadges';
import { DefaultColors } from '@kea-inc/parrot-ui/dist/types';

import { getStoreClosingTime } from '@utils/get-store-closing-time';
import {
  deprecatedGetStoreHours,
  getTaskAttributes,
  getTaskrouterStore,
  getHandoffMethods,
} from '@modules/taskrouter/selectors';
import camelcaseKeys from 'camelcase-keys';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import {
  getClosingTimeInMinutes,
  getHandoffMessage,
  getReducedHandoffMethods,
  getShowStoreClosingWarning,
  getStoreClosingText,
} from './utils';

import * as S from './styles';
import { StoreHours } from './StoreHours';

type StoreDetailsProps = {
  hidden: boolean;
};

export function StoreDetails({ hidden }: StoreDetailsProps) {
  const [areStoreNotesVisible, setAreStoreNotesVisible] = useState(true);

  const handoffMethods = useAppSelector(getHandoffMethods);
  const taskAttributes = useAppSelector(getTaskAttributes);
  const taskrouterStore = useAppSelector(getTaskrouterStore);
  const { orderDetails, shouldTip } = useOrder();
  const { oloHandoffMethods } = useAppSelector(getStoreFeatureFlags);
  const {
    handleFetchAddressById,
    handleAddressValidation,
    areDeliveryDetailsFilled,
    isAddressValidated,
  } = useDelivery();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const phoneNumber = parsePhoneNumber(taskrouterStore?.primaryPhone || '');
  const hasNotes = useMemo(() => !!taskrouterStore?.notes, []);

  useEffect(() => {
    setAreStoreNotesVisible(hasNotes);
  }, [hasNotes]);

  const reducedHandoffMethods = useMemo(
    () => getReducedHandoffMethods(handoffMethods, oloHandoffMethods),
    [handoffMethods],
  );

  const handoffMessage = useMemo(
    () => getHandoffMessage(reducedHandoffMethods),
    [reducedHandoffMethods],
  );

  useEffect(() => {
    orderAPI.setDetails(
      'handOffMode',
      taskAttributes?.handoffMode || reducedHandoffMethods[0].key,
    );
  }, [reducedHandoffMethods, taskAttributes?.handoffMode]);

  useEffect(() => {
    if (!shouldTip) {
      orderAPI.removeTips();
    }
  }, [shouldTip]);

  useEffect(() => {
    if (isAddressValidated) {
      orderAPI.validate();
    }
  }, [isAddressValidated]);

  const handleHandOffMode = (value: string) => {
    orderAPI.setDetails('handOffMode', value);
    orderAPI.evaluateTipsChecklist();

    orderAPI.setDetails('tableNumber', null);
    orderAPI.setDetails('isCollectingLoyaltyPoints', false);
    orderAPI.buildChecklist();

    if (value === 'delivery') {
      handleFetchAddressById();
      if (areDeliveryDetailsFilled) {
        handleAddressValidation();
      }
    }
  };

  const handleTableNumber = (value: string) => {
    orderAPI.setDetails('tableNumber', value);
  };

  const handleIsCollectingLoyaltyPoints = (value: boolean) => {
    orderAPI.setDetails('isCollectingLoyaltyPoints', value);
  };

  const nameAreaMessage = `This is ${taskAttributes?.brandResult.spokenName} ${taskrouterStore?.area}`;

  const handleAnnounceClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return (
    <header>
      <section>
        <S.HandoffModeContainer>
          <div className="handoffMode">
            <AnnounceBadge
              className="label__Badge"
              message={handoffMessage}
              onClick={handleAnnounceClick(handoffMessage)}
            >
              Handoff Mode
            </AnnounceBadge>

            <Select
              value={orderDetails.handOffMode}
              onChange={(value) => handleHandOffMode(value as string)}
              required
              data-test-id="handoff-mode-input"
            >
              {reducedHandoffMethods.map((option) => (
                <Option value={option.key} key={option.key}>
                  {option.children}
                </Option>
              ))}
            </Select>
          </div>

          {orderDetails.handOffMode === 'dine-in' && (
            <div className="tableNumber">
              <TableNumberBadge className="label__Badge" />

              <Input
                onChange={(e) => handleTableNumber(e.target.value)}
                value={orderDetails.tableNumber}
                placeholder="Table number"
                type="number"
              />
            </div>
          )}
        </S.HandoffModeContainer>

        {hasNotes && (
          <RiInformationFill
            onClick={() => setAreStoreNotesVisible((prevState) => !prevState)}
          />
        )}
      </section>

      {orderDetails.handOffMode === 'dine-in' && (
        <section>
          <div>
            <S.Label>
              <LoyaltyPointsBadge />
            </S.Label>
            <ButtonGroup>
              <Button
                onClick={() => handleIsCollectingLoyaltyPoints(true)}
                active={orderDetails.isCollectingLoyaltyPoints}
              >
                Yes
              </Button>
              <Button
                onClick={() => handleIsCollectingLoyaltyPoints(false)}
                active={!orderDetails.isCollectingLoyaltyPoints}
              >
                No
              </Button>
            </ButtonGroup>
          </div>
        </section>
      )}

      {areStoreNotesVisible && hasNotes && (
        <AlertBar title="Store notes" description={taskrouterStore?.notes} />
      )}

      <AnnounceBadge
        message={nameAreaMessage}
        onClick={handleAnnounceClick(nameAreaMessage)}
      >
        {`${taskAttributes?.brandResult.spokenName} ${taskrouterStore?.area}`}
      </AnnounceBadge>
      <Typography variant="caption" weight="light">
        {taskrouterStore?.address}
        <br />
        {`${taskrouterStore?.city}, ${taskrouterStore?.state} ${taskrouterStore?.zipcode}`}
        <br />
        {!!phoneNumber && phoneNumber.formatNational()}
        {!phoneNumber &&
          taskrouterStore?.primaryPhone &&
          taskrouterStore?.primaryPhone}
      </Typography>

      {!hidden && <ClosingTime />}
    </header>
  );
}

function ClosingTime() {
  const [areStoreHoursVisible, setAreStoreHoursVisible] = useState(false);
  const { timeAtStore, timezone } = useStoreHours();
  const storeHours = deprecatedGetStoreHours();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const storeClosingTime = getStoreClosingTime({
    storeHours: camelcaseKeys(storeHours),
    storeTimezone: timezone,
  });

  const closingTimeInMinutes = getClosingTimeInMinutes({
    closingTime: storeClosingTime,
  });
  const closingText = getStoreClosingText(closingTimeInMinutes);
  const showWarning = getShowStoreClosingWarning(closingTimeInMinutes);

  let badgeVariant: DefaultColors = 'primary';

  if (closingTimeInMinutes < 60) {
    badgeVariant = 'accent_2';
  }
  if (closingTimeInMinutes <= 0) {
    badgeVariant = 'error';
  }

  const timeColor = 'primary_darker';

  const handleAnnounceClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message: closingText,
      },
    });
  };

  return (
    <>
      <S.ClosingTime rotateArrow={areStoreHoursVisible}>
        {showWarning && <IoIosWarning size={16} />}
        <Typography
          className="closingTime_time"
          variant="subheader"
          weight="heavy"
          color={timeColor}
        >
          {timeAtStore.toFormat('h:mm a ZZZZ')}
        </Typography>
        <AnnounceBadge
          message={closingText}
          className="closingTime__badge"
          variant={badgeVariant}
          onClick={handleAnnounceClick}
        >
          {closingText}
        </AnnounceBadge>
        <BiChevronDown
          className="closingTime_arrow"
          onClick={() => setAreStoreHoursVisible((prevState) => !prevState)}
        />
      </S.ClosingTime>
      {areStoreHoursVisible && <StoreHours />}
    </>
  );
}
