import * as notifications from '@modules/notifications/api';
import { handleReassignTask } from '@utils/handleReassignTask';

export function fetchWorkerTokenError() {
  notifications.error({
    title: 'Unable to update worker token',
    message:
      'Something unexpected happened while updating your Twilio worker token',
  });
}

export function acceptTaskError(error: unknown) {
  notifications.error({
    title: 'Unable to accept task',
    message: 'Something unexpected happened while accepting the task',
    error,
    onDismiss: handleReassignTask,
  });
}

export function completeTaskError(error: unknown) {
  notifications.error({
    title: 'Unable to complete task',
    message: 'Something unexpected happened while completing the task',
    error,
  });
}

export function connectConferenceError(error: unknown) {
  notifications.error({
    title: 'Unable to connect the call',
    message:
      'Something unexpected happened while connecting you to the conference',
    error,
    onDismiss: handleReassignTask,
  });
}

export function updateWorkerStatusError(error: unknown) {
  notifications.error({
    title: 'Unable to update your status',
    message:
      'Please, reload UOA tab and clock in again to be able to receive new calls',
    error,
  });
}

export function reservationTimeout() {
  notifications.info({
    title: 'Call timeout',
    message:
      'A received call has timed out due to network issues and reassigned',
  });
}

export function workerStatusError() {
  notifications.error({
    title: 'Unable to get your status',
    message:
      'Please, reload UOA tab and clock in again in order to receive new calls',
  });
}
