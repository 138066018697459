import { types } from './types';

export function authLogin(payload) {
  return {
    type: types.LOGGED_IN,
    payload,
  };
}

export function authReset() {
  return {
    type: types.RESET,
  };
}

export function authSetFeatureFlags(payload) {
  return {
    type: types.SET_FEATURE_FLAGS,
    payload,
  };
}
