import { Item, Option } from '@modules/menu/types';
import { Dictionary } from '@reduxjs/toolkit';

// TODO: remove this from here after previous order is typed at the redux level
type PreviousOrder = {
  orderItems: {
    itemId: string;
    recipient: string;
    specialInstructions: string;
    quantity: number;
    options: { id: string; quantity: number }[];
  }[];
};

export function getPreviousCartItems(
  previousOrder: PreviousOrder,
  itemEntities: Dictionary<Item>,
  optionEntities: Dictionary<Option>,
) {
  if (!previousOrder) return [];

  const items: (Item & {
    quantity: number;
    recipient: string;
    specialInstructions: string;
    options: (Option & { quantity: number })[];
  })[] = [];

  previousOrder.orderItems.forEach((orderItem) => {
    const menuItem = itemEntities[orderItem.itemId];

    if (!menuItem) {
      return;
    }

    const options: (Option & { quantity: number })[] = [];

    orderItem.options.forEach(({ id, quantity }) => {
      const option = optionEntities[id];

      if (option) {
        options.push({ ...option, quantity });
      }
    });

    items.push({
      ...menuItem,
      quantity: orderItem.quantity,
      options,
      recipient: orderItem.recipient,
      specialInstructions: orderItem.specialInstructions,
    });
  });

  return items;
}
