import styled from 'styled-components';
import { theme, Button as parrotButton } from '@kea-inc/parrot-ui';

export const Container = styled.section`
  height: 170px;
  width: 420px;
`;

export const Header = styled.header`
  text-align: left;
  width: 100%;
  display: flex;
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const ButtonsWrapper = styled.footer`
  display: flex;
  justify-content: center;
  gap: 1rem;
  border-top: 1px solid ${theme.colors.gray_light};
  padding-top: 16px;
`;

export const Button = styled(parrotButton)`
  min-width: 150px;
  z-index: 10000;
`;

export const OutlinedButton = styled(parrotButton)`
  background: white;
  color: ${theme.colors.error};
  min-width: 150px;
  border: 2px solid ${theme.colors.error};
  padding: 8px 16px;
  &:hover {
    background-color: rgba(244, 67, 54, 0.08);
  }
  p {
    color: ${theme.colors.error};
    font-weight: bold;
  }
`;
