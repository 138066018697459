import { getSpokenName } from '@kea-inc/order';
import { PartOfSpeech } from '@kea-inc/types';
import store from '@store';
import { CartItem, APICartItemOption } from '@modules/cart/types';

interface OptionSelection {
  option: {
    article?: string;
    isDefault: boolean;
    isHidden: boolean;
    modifierIds: string[];
    spokenName: string;
    partOfSpeech?: PartOfSpeech;
    preposition?: string;
    removedDefaultOptionDeterminer: string;
    shouldRepeatOptionRemoval: boolean;
    shouldRepeatDefaultOptions: boolean;
    shouldPluralize: boolean;
    sortOrder: number;
  };
  quantity: number;
  isSelected: boolean;
}

export function convertCartItemToDescriptionItem(cartItem: CartItem) {
  const menuItem = store.getState().menu.items.entities[cartItem.menuItemId];

  return {
    recipient: cartItem.recipient,
    shouldPluralize: true,
    specialInstructions: cartItem.specialInstructions,
    spokenName: menuItem?.spokenName ?? getSpokenName(menuItem),
    modifierIds: menuItem?.modifierIds ?? [],
    quantity: cartItem.quantity ?? 1,
    selectionDataByModifierId: getSelectionDataByModifierId(cartItem.options),
  };
}

function getSelectionDataByModifierId(options?: APICartItemOption[]) {
  if (!options) {
    return {};
  }

  return options.reduce<Record<string, OptionSelection[]>>((acc, option) => {
    const optionEntities = store.getState().menu.options.entities;
    const menuOption = optionEntities[option.id];
    const menuModifier =
      store.getState().menu.modifiers.entities[menuOption?.parentId ?? ''];

    if (!menuOption) {
      return acc;
    }

    if (!acc[menuOption.parentId]) {
      acc[menuOption.parentId] = [];
    }

    acc[menuOption.parentId].push({
      option: {
        article: menuOption.article,
        isDefault: menuOption.isDefault,
        isHidden: menuOption.isHidden,
        modifierIds: menuOption.modifierIds,
        spokenName: menuOption.spokenName,
        partOfSpeech: menuOption.partOfSpeech,
        preposition: menuOption.preposition,
        removedDefaultOptionDeterminer: 'NO',
        shouldRepeatOptionRemoval: !!menuOption.shouldRepeatOptionRemoval,
        shouldRepeatDefaultOptions: !!menuOption.shouldRepeatDefaultOptions,
        shouldPluralize: !!menuOption.shouldPluralize,
        sortOrder: menuOption.sortOrder,
      },
      quantity: menuModifier?.supportOptionQuantity ? option.quantity : 0,
      isSelected: option.quantity > 0,
    });

    return acc;
  }, {});
}
