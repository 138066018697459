import { formatPrice } from './formatPrice';

export function makeCartItemPricePhrase(cartItem: {
  quantity: number;
  subtotal: number;
}) {
  if (cartItem.quantity === 1) {
    return `${formatPrice(cartItem.subtotal)}`;
  }

  return `${formatPrice(
    cartItem.subtotal / cartItem.quantity,
  )} each for a total of ${formatPrice(cartItem.subtotal)}`;
}
