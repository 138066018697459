import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useClock, useOrder } from '@hooks';
import { CLOCK_STATUS } from '@modules/clock/state';

export const UnloadProvider = ({ children }) => {
  const { status } = useClock();
  const { id: order_id } = useOrder();
  useEffect(() => {
    window.onbeforeunload = () => {
      if (status === CLOCK_STATUS.CLOCK_IN) {
        return true;
      }
    };
  }, [status, order_id]);

  return children;
};

UnloadProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
