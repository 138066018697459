import env from '@beam-australia/react-env';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Call, Device } from '@twilio/voice-sdk';
import { TwilioError } from '@twilio/voice-sdk/es5/twilio/errors';

import {
  coreService,
  device as twilioDevice,
  operatorService,
} from '@services';
import store, { RootState } from '@store';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as paymentSelectors from '@modules/payment/selectors';
import * as agentSelectors from '@modules/agent/selectors';
import { getOrderHandoffMode } from '@modules/order/selectors';
import * as notifications from '@modules/notifications/api';
import logger from '@logger';
import { createOrderEvent } from '@modules/order/api';
import { getServiceInstance } from '@utils';
import { handleReassignTask } from '@utils/handleReassignTask';

import { actions } from './device-slice';
import * as selectors from './selectors';

const applicationSid = env('TWILIO_APPLICATION_SID');

export const checkMicrophone = createAsyncThunk(
  'device/checkMicrophone',
  async () => {
    try {
      logger.info('Checking microphone');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      notifications.error({
        title: 'Cannot access microphone',
        message:
          'User denied access to microphone, or the web browser did not allow microphone access at this address.',
        error,
      });
      logger.error('Cannot access microphone', {
        menuError: error,
      });
    }
  },
);

export const fetchToken = createAsyncThunk<string, void, { state: RootState }>(
  'device/fetchToken',
  async (_, { rejectWithValue }) => {
    try {
      const agent = agentSelectors.currentAgent();

      const response = await coreService.post<{ token: string }>(
        '/twilio/devices/token',
        {
          application_sid: applicationSid,
          agent_nickname: agent.username,
        },
      );

      twilioDevice.setToken(response.data.token);
      return response.data.token;
    } catch (error) {
      const typedError = error as Error;
      logger.error('Error fetching token', {
        tokenError: typedError,
      });
      return rejectWithValue(typedError.message);
    }
  },
);

export const fetchTrainerToken = createAsyncThunk<
  string,
  void,
  { state: RootState }
>('device/fetchToken', async (_, { rejectWithValue }) => {
  try {
    const agent = agentSelectors.currentAgent();

    const response = await coreService.post<{ token: string }>(
      '/twilio/devices/token',
      {
        application_sid: applicationSid,
        agent_nickname: `self-${agent.username}`,
      },
    );

    twilioDevice.setToken(response.data.token);
    return response.data.token;
  } catch (error) {
    const typedError = error as Error;
    logger.error('Error fetching token', {
      tokenError: typedError,
    });
    return rejectWithValue(typedError.message);
  }
});

export const startDevice = createAsyncThunk<void, string, { state: RootState }>(
  'device/startDevice',
  async (edge = 'roaming', { dispatch, getState }) => {
    await dispatch(checkMicrophone());

    const state = getState().device;

    if (state.registered) {
      return;
    }

    try {
      twilioDevice.setup(edge);

      if (!twilioDevice.preflightStarted) {
        dispatch(actions.setPreflightRunning());
        twilioDevice.runPreflightTest(
          {
            onSuccess: (report) => {
              dispatch(actions.onPreflightSuccess({ report }));
            },
            onError: () => dispatch(actions.onPreflightError()),
          },
          edge,
        );
      }

      startEventListeners();
    } catch (error) {
      logger.error('Unable to start device', {
        deviceError: error,
      });
    }
  },
);

export const hangup = createAsyncThunk<
  void,
  { endConference: boolean; conferenceSid: string },
  { state: RootState }
>('device/hangup', async (params, { getState, dispatch }) => {
  const { endConference, conferenceSid } = params;
  const { hasForwarded } = getState().device;

  logger.action('Hangup');

  const retrievedConferenceSid =
    conferenceSid ?? taskrouterSelectors.deprecatedGetConferenceSid();

  try {
    const shouldEndConference = endConference ?? !hasForwarded;
    const orderId = taskrouterSelectors.deprecatedGetOrderId();

    const payload = {
      label: 'agent',
      end_conference: shouldEndConference,
      order_id: orderId,
    };

    if (!retrievedConferenceSid) {
      logger.warn(
        'Conference sid is not available. Participant not removed.',
        payload,
      );

      throw new Error(
        'Conference sid is not available. Participant not removed.',
      );
    }

    const url = `/twilio/conferences/${retrievedConferenceSid}/removeParticipant`;

    await coreService.post(url, payload);
  } catch (error) {
    const typedError = error as Error;
    dispatch(
      ensureHangUp({
        conferenceSid: retrievedConferenceSid,
        error: typedError,
      }),
    );
  }
});

const ensureHangUp = createAsyncThunk<
  void,
  {
    conferenceSid: string;
    error: Error;
  }
>('device/ensureHangUp', async (params) => {
  const { conferenceSid, error } = params;

  try {
    const url = `/conferences/${conferenceSid}/participants`;

    const response = await operatorService.get(url);

    if (response?.data?.participants.includes('agent')) {
      throw error;
    }
  } catch (err) {
    notifications.error({
      title: 'Unable to hangup call',
      message: 'Unfortunately, the agent was not removed from the conference.',
      err,
    });

    logger.error('Unable to hangup call', {
      hangupError: err,
    });
  }
});

export const mute = createAsyncThunk('device/mute', async () => {
  logger.action('Mute');
  // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
  createOrderEvent('agent_muted');

  twilioDevice.call?.mute(true);

  try {
    const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
    const orderId = taskrouterSelectors.deprecatedGetOrderId();

    const payload = { order_id: orderId };
    const url = `/twilio/conferences/${conferenceSid}/mute`;

    await coreService.post(url, payload);
  } catch (error) {
    notifications.error({
      title: 'Unable to mute on server side',
      message: "No worries, the client can't hear you anyway",
    });
    logger.error('Unable to mute call', { muteError: error });
  }
});

export const unmute = createAsyncThunk('device/unmute', async () => {
  logger.action('Unmute');
  // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
  createOrderEvent('agent_unmuted');

  twilioDevice.call?.mute(false);

  try {
    const orderId = taskrouterSelectors.deprecatedGetOrderId();
    const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();

    const payload = { order_id: orderId };
    const url = `/twilio/conferences/${conferenceSid}/unmute`;

    await coreService.post(url, payload);
  } catch (error) {
    notifications.error({
      title: 'Unable to unmute on server side',
      message: 'The client is still not listening to you',
    });
    logger.error('Unable to unmute call', { unmuteError: error });
  }
});

export const announce = createAsyncThunk<
  void,
  {
    id?: string;
    text: string;
    custom?: boolean;
  }
>('device/announce', async (params, { rejectWithValue }) => {
  logger.action('Announce');
  const { text, custom } = params;

  try {
    const agentId = agentSelectors.currentAgentId();
    const orderId = taskrouterSelectors.deprecatedGetOrderId();
    const conferenceName = taskrouterSelectors.getConferenceName();
    const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
    const brandVoice = taskrouterSelectors.getBrandVoice();

    const payload = {
      agent_id: agentId,
      text,
      conference_name: conferenceName,
      conference_sid: conferenceSid,
      brand_voice: brandVoice,
    };

    await coreService.post(`/textbacks/${orderId}`, payload, {
      version: 'v0',
    });

    createAnnounceEvent({ text, custom: custom ?? false });
  } catch (error) {
    const typedError = error as Error;
    logger.error('Unable to announce', {
      announceError: typedError,
    });
    rejectWithValue(typedError.message);
  }
});

export const stopCurrentVbx = createAsyncThunk(
  'device/stopCurrentVbx',
  async (_, { dispatch }) => {
    await dispatch(announce({ text: '*' }));
  },
);

export const forwardToStore = createAsyncThunk(
  'device/forwardToStore',
  async (_, { dispatch }) => {
    logger.action('Forward Store');

    announceForwardIfBlazePizza((text) => dispatch(announce({ text })));

    try {
      const orderId = taskrouterSelectors.deprecatedGetOrderId();
      const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
      const caller = taskrouterSelectors.getCaller();
      const taskrouterStore = taskrouterSelectors.getStore();
      const storeId = taskrouterStore?.id;

      const payload = {
        caller,
        store_number: taskrouterStore?.secondaryPhone,
        orderId,
        store_id: storeId,
      };

      const url = `/twilio/conferences/${conferenceSid}/forward`;

      await coreService.post(url, payload);
    } catch (error) {
      notifications.error({
        title: 'Unable to forward call',
        message: 'Unfortunately, the client was not forwarded to the store.',
      });
      logger.error('Unable to forward call', { forwardError: error });
    }
  },
);

export const callBack = createAsyncThunk(
  'device/callBack',
  async (_, { dispatch }) => {
    logger.action('Callback');

    try {
      const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
      const caller = taskrouterSelectors.getCaller();
      const taskrouterStore = taskrouterSelectors.getStore();
      const orderId = taskrouterSelectors.deprecatedGetOrderId();

      const payload = {
        to: caller,
        from: taskrouterStore?.twilioNumber,
        shouldRecord: true,
        order_id: orderId,
      };

      const url = `/twilio/conferences/${conferenceSid}/participants`;
      const response = await coreService.post<{ callSid: string }>(
        url,
        payload,
      );

      await dispatch(unmute());

      return response.data;
    } catch (error) {
      notifications.error({
        title: 'Unable to call back',
        message: 'Unfortunately, the client was not called back.',
      });
      logger.error('Unable to call back', { callBackError: error });
    }
  },
);

const createAnnounceEvent = createAsyncThunk<
  void,
  {
    text: string;
    custom: boolean;
  }
>('device/createAnnounceEvent', async (params) => {
  try {
    const { text, custom } = params;

    const orderId = taskrouterSelectors.deprecatedGetOrderId();

    await getServiceInstance().post(`/orders/${orderId}/record_event`, {
      orderId,
      eventName: 'vbx',
      eventTags: {
        text,
        custom,
      },
    });
  } catch (error) {
    logger.error('Unable to create announce event', {
      announceError: error,
    });
  }
});

// TODO: TECH DEBT - Remove this function once we introduce a better solution
export function announceForwardIfBlazePizza(
  dispatchedAnnounce: (text: string) => void,
) {
  const brand = taskrouterSelectors.deprecatedGetBrand();

  if (brand?.key === 'blazepizza') {
    logger.info('Announcing forward for Blaze Pizza');
    const handoffMode = getOrderHandoffMode();

    if (handoffMode === 'curbside') {
      dispatchedAnnounce(
        'One moment for assistance with your curbside order. If your call goes unanswered because the team is busy, please go inside for pick-up',
      );
    } else {
      dispatchedAnnounce(`I'm sorry we currently cannot process your request.
        If your call to the store goes unanswered please visit us in person or at blaze pizza dot com by selecting the contact us link at the bottom of the page.`);
    }
  }
}

function startEventListeners() {
  twilioDevice.setDeviceEvent(Device.EventName.Registered, () => {
    store.dispatch(actions.onDeviceRegistered());
  });

  twilioDevice.setDeviceEvent(Device.EventName.Incoming, (call: Call) => {
    store.dispatch(actions.resetDevice());

    store.dispatch(actions.onDeviceIncoming());
    twilioDevice.setCall(call);
    call.accept();
    store.dispatch(actions.onDeviceConnected());

    twilioDevice.setCallEvent('disconnect', async () => {
      twilioDevice.setCall(null);

      const wasDisconnectedByInternetFailure =
        !selectors.getHasTriedHangup() &&
        !taskrouterSelectors.wasReassignedByTtfa() &&
        !paymentSelectors.didPaybotRun();

      if (wasDisconnectedByInternetFailure) {
        logger.action('Agent DISCONNECTED -> Attempting to reassign');
        await handleReassignTask(false);
      }

      store.dispatch(actions.onCallDisconnect());
    });

    twilioDevice.setCallEvent('cancel', () => {
      logger.warn('The call was canceled.');
    });
  });

  twilioDevice.setDeviceEvent(
    Device.EventName.Error,
    (twilioError: TwilioError) => {
      store.dispatch(actions.onDeviceError());
      logger.error('Twilio device error', {
        twilioError,
      });
    },
  );

  twilioDevice.setDeviceEvent(Device.EventName.Unregistered, () => {
    logger.error(
      'Twilio device is Unregistered which may cause Clock Out. Trying to register again.',
    );

    twilioDevice.register().catch(() => {
      logger.error('Device registration retry failed.');
      store.dispatch(actions.onDeviceUnregistered());
    });
  });

  twilioDevice.setDeviceEvent(Device.EventName.TokenWillExpire, () => {
    store.dispatch(fetchToken());
  });
}
