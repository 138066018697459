import { GetOptionsFromModifierV2Query } from 'src/graphql/generated/schema';
import { Option } from '../types';

type APIOption = GetOptionsFromModifierV2Query['optionsV2']['edges'][0]['node'];

export function transformAPIOption(apiOption: APIOption): Option {
  return {
    id: apiOption.id,
    ancestorIds: apiOption.ancestorIds,
    article: apiOption.article,
    availabilityEnd: apiOption.availabilityEnd ?? null,
    availabilitySchedule: apiOption.availabilitySchedule ?? null,
    availabilityStart: apiOption.availabilityStart ?? null,
    chainId: apiOption.chainId,
    cost: apiOption.cost,
    hasStockAvailable: apiOption.hasStockAvailable,
    isDefault: apiOption.isDefault,
    isExclusive: apiOption.isExclusive,
    isHidden: apiOption.isHidden,
    isImplicitItem: apiOption.isImplicitItem,
    modifierIds: apiOption.modifierIds,
    name: apiOption.name,
    parentId: apiOption.parentId,
    partOfSpeech: apiOption.partOfSpeech ?? 0,
    preposition: apiOption.preposition ?? null,
    sortOrder: apiOption.sortOrder,
    spokenName: apiOption.spokenName ?? apiOption.name,
    shouldRepeatDefaultOptions: apiOption.shouldRepeatDefaultOptions,
    shouldRepeatOptionRemoval: apiOption.shouldRepeatOptionRemoval,
    shouldPluralize: apiOption.shouldPluralize,
    status: 'idle',
    version: apiOption.version,
  };
}
