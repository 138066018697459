import styled from 'styled-components';
import { Card as CardP } from '@kea-inc/parrot-ui';

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.open ? '0.5' : '0.0')};
  transition: visibility 0.2s, opacity 0.3s linear;
`;

export const Card = styled(CardP).attrs({
  selected: true,
  padded: true,
})`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  height: ${(props) => (props.open ? '500px' : '0')};
  transition: height, 0.2s linear;
`;
