import { createDraftSafeSelector } from '@reduxjs/toolkit';

import {
  categoryAdapter,
  itemAdapter,
  linkedSpecialAdapter,
  modifierAdapter,
  optionAdapter,
} from './state';
import { MenuState, Option } from './types';

export const {
  selectAll: selectAllCategories,
  selectEntities: selectCategoryEntities,
  selectIds: selectCategoryIds,
  selectTotal: selectCategoryTotal,
  selectById: selectCategoryById,
} = categoryAdapter.getSelectors();

export const {
  selectAll: selectAllItems,
  selectEntities: selectItemEntities,
  selectIds: selectItemIds,
  selectTotal: selectItemTotal,
  selectById: selectItemById,
} = itemAdapter.getSelectors();

export const {
  selectAll: selectAllModifiers,
  selectEntities: selectModifierEntities,
  selectIds: selectModifierIds,
  selectTotal: selectModifierTotal,
  selectById: selectModifierById,
} = modifierAdapter.getSelectors();

export const {
  selectAll: selectAllOptions,
  selectEntities: selectOptionEntities,
  selectIds: selectOptionIds,
  selectTotal: selectOptionTotal,
  selectById: selectOptionById,
} = optionAdapter.getSelectors();

export const {
  selectAll: selectAllLinkedSpecials,
  selectEntities: selectLinkedSpecialsEntities,
  selectIds: selectLinkedSpecialsIds,
  selectTotal: selectLinkedSpecialsTotal,
  selectById: selectLinkedSpecialsById,
} = linkedSpecialAdapter.getSelectors();

export const selectItemsByCategory = createDraftSafeSelector(
  selectAllItems,
  (items) => (categoryId: string) =>
    items.filter((item) => item.parentId === categoryId),
);

export const selectModifiersByParent = createDraftSafeSelector(
  selectAllModifiers,
  (modifiers) => (parentId: string) =>
    modifiers.filter((modifier) => modifier.parentId === parentId),
);

export const selectOptionsByParent = createDraftSafeSelector(
  selectAllOptions,
  (options) => (parentId: string) =>
    options.filter((option) => option.parentId === parentId),
);

export const selectModifierItemParent = createDraftSafeSelector(
  selectItemEntities,
  (items) => (parentId: string) => items[parentId] ?? null,
);

export const selectModifierOptionParent = createDraftSafeSelector(
  selectOptionEntities,
  (options) => (parentId: string) => options[parentId] ?? null,
);

export const selectModifierHasOptionsWithNestedModifiers = (
  modifierId: string,
) =>
  createDraftSafeSelector(selectSelf, (state) => {
    const allOptions = Object.values(state.options.entities);
    const modifierOptions = allOptions.filter(
      (option) => option?.parentId === modifierId,
    );

    return modifierOptions.some((option) => {
      if (option?.modifierIds.length === 0) {
        return false;
      }

      if (option?.modifierIds.length === 1) {
        const childrenModifierId = option.modifierIds[0];
        const childrenModifier = state.modifiers.entities[childrenModifierId];

        if (childrenModifier?.displayMode === 'NESTED') {
          return true;
        }

        return false;
      }

      return true;
    });
  });

const selectSelf = (state: { menu: MenuState }) => state.menu;

export const selectNestedModifierIds = createDraftSafeSelector(
  selectSelf,
  (state) => (rootModifierId: string) => {
    const ids: string[] = [];
    const filteredOptions: Option[] = [];

    Object.keys(state.options.added).forEach((addedOptionId) => {
      const option = state.options.entities[addedOptionId];

      if (option?.ancestorIds.includes(rootModifierId)) {
        filteredOptions.push(option);
      }
    });

    filteredOptions.forEach((option) => {
      let isNested = option.modifierIds.length > 0;

      if (option.modifierIds.length === 1) {
        const childrenModifierId = option.modifierIds[0];
        const childrenModifier = state.modifiers.entities[childrenModifierId];

        if (childrenModifier?.displayMode !== 'NESTED') {
          isNested = false;
        }
      }

      if (isNested) {
        const parentModifierIndex = ids.indexOf(option.parentId);

        if (parentModifierIndex === -1) {
          ids.unshift(...option.modifierIds);
          ids.unshift(option.parentId);
        } else {
          ids.splice(parentModifierIndex + 1, 0, ...option.modifierIds);
        }
      }
    });

    return ids;
  },
);

export const selectSubtotal = createDraftSafeSelector(
  selectSelf,
  (state) => () => {
    if (!state.items.current) {
      return 0;
    }

    let subtotal: number = 0;

    const currentItem = state.items.entities[state.items.current];

    if (currentItem) {
      subtotal += currentItem.cost;
    }

    Object.keys(state.options.added).forEach((optionId) => {
      const option = state.options.entities[optionId];

      if (option) {
        subtotal += option.cost;
      }
    });

    return subtotal;
  },
);

export const selectAddedOptions = createDraftSafeSelector(
  selectSelf,
  (state) => {
    const addedOptions: (Option & { quantity: number })[] = [];

    Object.keys(state.options.added).forEach((optionId) => {
      const option = state.options.entities[optionId];

      if (option) {
        addedOptions.push({
          ...option,
          quantity: state.options.added[optionId].quantity,
        });
      }
    });

    return addedOptions;
  },
);

export const selectWasModifierPrompted = (modifierId: string) =>
  createDraftSafeSelector(selectSelf, (state) => {
    const wasPrompted = state.modifiers.prompted[modifierId] ?? false;
    return wasPrompted;
  });

export const selectIsModifierAsNoSelection = (modifierId: string) =>
  createDraftSafeSelector(selectSelf, (state) => {
    const isNoSelection = state.modifiers.noSelection[modifierId] ?? false;
    return isNoSelection;
  });

export const selectIsModifierWithFullyAddedOptions = (modifierId: string) =>
  createDraftSafeSelector(selectSelf, (state) => {
    const hasFullyAddedOptions = Object.values(state.options.added).some(
      (addedOption) =>
        addedOption.parentId === modifierId && addedOption.isFullyAdded,
    );

    return hasFullyAddedOptions;
  });

export const selectIsPromptRequirementFulfilled = (modifierId: string) =>
  createDraftSafeSelector(selectSelf, (state) => {
    const modifier = state.modifiers.entities[modifierId];

    if (!modifier?.isPromptRequired) {
      return false;
    }

    const hasFullyAddedOptions = selectIsModifierWithFullyAddedOptions(
      modifierId,
    )({ menu: state });

    if (modifier.required) {
      return hasFullyAddedOptions;
    }

    const isNoSelection = selectIsModifierAsNoSelection(modifierId)({
      menu: state,
    });

    return hasFullyAddedOptions || isNoSelection;
  });

export const selectRemovedDefaultOptions = createDraftSafeSelector(
  selectSelf,
  (state) => {
    const removedOptionsByModifierId: Record<string, Option[]> = {};
    const allOptions = selectAllOptions(state.options);

    const modifiersAdded: Record<string, {}> = {};

    Object.keys(state.options.added).forEach((addedOptionId) => {
      const option = state.options.entities[addedOptionId];

      if (option) {
        modifiersAdded[option.parentId] = option.parentId;

        if (option.modifierIds?.length) {
          option.modifierIds.forEach((id) => {
            modifiersAdded[id] = id;
          });
        }
      }
    });

    allOptions.forEach((option) => {
      const optionWithAncestorAdded =
        modifiersAdded[option.parentId] || option.ancestorIds.length < 3;

      if (
        optionWithAncestorAdded &&
        option.ancestorIds.includes(state.items.current!) &&
        option.isDefault &&
        !state.options.added[option.id]
      ) {
        removedOptionsByModifierId[option.parentId] =
          removedOptionsByModifierId[option.parentId] ?? [];
        removedOptionsByModifierId[option.parentId].push(option);
      }
    });

    Object.keys(removedOptionsByModifierId).forEach((modifierId) => {
      const modifier = state.modifiers.entities[modifierId];

      if (
        modifier?.required &&
        !modifier?.maxOptions &&
        !modifier?.maxQuantity
      ) {
        delete removedOptionsByModifierId[modifierId];
      }
    });

    return removedOptionsByModifierId;
  },
);
