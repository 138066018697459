export default {
  '/home': {
    roles: ['Administrator', 'Agent'],
  },
  '/dashboard': {
    roles: ['Agent'],
  },
  '/waiting': {
    roles: ['Agent'],
  },
};
