import React, { useState, useEffect } from 'react';

import AnnounceBadge, { AnnounceBadgeProps } from '@components/AnnounceBadge';
import { sample } from '@utils/sample';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';

type ExtendedProps = Omit<AnnounceBadgeProps, 'message'>;

interface RandomMessageAnnounceProps extends ExtendedProps {
  sampleMessages: string[];
  children: React.ReactNode;
}

export function RandomMessageAnnounce({
  sampleMessages,
  onClick,
  children,
  ...props
}: RandomMessageAnnounceProps) {
  const [remainingMessages, setRemainingMessages] = useState(sampleMessages);
  const [message, setMessage] = useState('');

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const handleChooseMessage = () => {
    setRemainingMessages((prev) => {
      if (prev.length === 0) {
        setMessage(sampleMessages[0]);
        return sampleMessages;
      }
      const chosenMessage = sample(remainingMessages);
      setMessage(chosenMessage ?? '');
      return prev.filter((sampleMessage) => sampleMessage !== chosenMessage);
    });
    onClick?.();

    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: message ?? '' },
    });
  };

  useEffect(handleChooseMessage, []);

  return (
    <AnnounceBadge {...props} message={message} onClick={handleChooseMessage}>
      {children}
    </AnnounceBadge>
  );
}
