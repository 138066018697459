import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input, Typography } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { AnnounceBadge, ScrollableTabs } from '@components';
import { useVoicePane } from '@hooks';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import {
  EVENT_NAME_BUTTONS,
  SOUNDBOARD_BUTTON_TYPES,
} from '@utils/buttonsAnalyticsIds';
import * as S from './styles';
import { useConnector } from './useConnector';

const highlightedPromptsByBrand: Record<string, string[]> = {
  hopdoddy: ['This burger comes with a little bit of pink in the patty.'],
  all: ['Which sauce would you like?'],
};

export const Soundboard = () => {
  const {
    categoryBased,
    prompts,
    currentContext,
    filteredPrompts,
    itemSensitivePrompts,
  } = useVoicePane();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const { selectors, actions } = useConnector();
  const {
    brandKey,
    isChecklistOpen,
    categoryEntities,
    currentCategoryId,
    itemEntities,
    currentItemId,
  } = selectors;
  const { setChecklist, filterPrompts } = actions;

  const [contexts, setContexts] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSoundboardSideEffects = (context: string) => {
    if (context === 'upsell') {
      setChecklist(ORDER_CHECKLIST.HAS_UPSOLD_ONCE, true);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    filterPrompts(value);
  };

  const getVariant = (prompt: string) => {
    if (brandKey !== undefined) {
      const isBrandHighlighted =
        highlightedPromptsByBrand[brandKey]?.includes(prompt);

      if (
        isBrandHighlighted ||
        highlightedPromptsByBrand.all.includes(prompt)
      ) {
        return 'error';
      }
    }

    return 'primary';
  };

  useEffect(() => {
    if (filteredPrompts !== null) {
      setContexts(Object.keys(filteredPrompts));
    }
  }, [filteredPrompts]);

  const handlePromptClick = (prompt: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: { id: SOUNDBOARD_BUTTON_TYPES.PROMPT, message: prompt },
    });
  };

  return (
    <S.SoundboardContainer
      isChecklistOpen={isChecklistOpen}
      // @ts-expect-error - missing on parrot definition
      className="soundboard__Container"
    >
      <S.Header>
        <ScrollableTabs />
        <Input
          leftIcon={AiOutlineSearch}
          placeholder="Find Phrases"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={() => setTimeout(() => handleSearch(''), 1000)}
        />
      </S.Header>

      <S.Content>
        {filteredPrompts === null ? (
          <>
            {itemSensitivePrompts.length > 0 && currentItemId && (
              <>
                <Typography weight="heavy">
                  {itemEntities[currentItemId]?.name}
                </Typography>

                {itemSensitivePrompts?.map((prompt) => (
                  <AnnounceBadge
                    key={prompt}
                    message={prompt}
                    className="voicePane__badge"
                    variant={getVariant(prompt)}
                    onClick={handlePromptClick(prompt)}
                  >
                    {prompt}
                  </AnnounceBadge>
                ))}
              </>
            )}

            {categoryBased.length > 0 && currentCategoryId !== null && (
              <>
                <Typography weight="heavy">
                  {categoryEntities[currentCategoryId]?.name}
                </Typography>

                {categoryBased.map((prompt) => (
                  <AnnounceBadge
                    key={prompt}
                    message={prompt}
                    className="voicePane__badge"
                    variant={getVariant(prompt)}
                    onClick={handlePromptClick(prompt)}
                  >
                    {prompt}
                  </AnnounceBadge>
                ))}
              </>
            )}

            {prompts !== undefined &&
              currentContext.length > 0 &&
              currentContext.map((context) => (
                <React.Fragment key={context}>
                  <Typography weight="heavy">{context}</Typography>
                  {prompts.byContext[context]?.map((prompt) => (
                    <AnnounceBadge
                      key={prompt}
                      message={prompt}
                      className="voicePane__badge"
                      variant={getVariant(prompt)}
                      onClick={() => {
                        handleSoundboardSideEffects(context);
                        handleAnalyticsTrackButton({
                          eventName: EVENT_NAME_BUTTONS.SPECIFIC,
                          metadata: {
                            id: SOUNDBOARD_BUTTON_TYPES.PROMPT,
                            message: prompt,
                          },
                        });
                      }}
                    >
                      {prompt}
                    </AnnounceBadge>
                  ))}
                </React.Fragment>
              ))}
          </>
        ) : (
          (contexts ?? []).map((context) => (
            <React.Fragment key={context}>
              <Typography weight="heavy">{context}</Typography>

              {filteredPrompts[context]?.map((prompt) => (
                <AnnounceBadge
                  key={prompt}
                  message={prompt}
                  className="voicePane__badge"
                  onClick={() => {
                    handleSoundboardSideEffects(context);
                    handleAnalyticsTrackButton({
                      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
                      metadata: {
                        id: SOUNDBOARD_BUTTON_TYPES.PROMPT,
                        message: prompt,
                      },
                    });
                  }}
                  variant={getVariant(prompt)}
                >
                  {prompt}
                </AnnounceBadge>
              ))}
            </React.Fragment>
          ))
        )}
      </S.Content>
    </S.SoundboardContainer>
  );
};
