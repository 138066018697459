import { Typography } from '@kea-inc/parrot-ui';

import { AnnounceBadge, RequiredMark } from '@components';
import { createMinimumSelectionMessage } from '@utils';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import {
  selectIsPromptRequirementFulfilled,
  selectModifierById,
  selectModifierItemParent,
  selectModifierOptionParent,
  selectOptionsByParent,
  selectWasModifierPrompted,
} from '@modules/menu/selectors';

import { promptModifier } from '@modules/menu/slice';
import { getIsModifierRequirementsFulfilled } from '@utils/get-is-modifier-requirements-fulfilled';
import { CompletedMark } from '@components/CompletedMark';
import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import * as S from './styles';
import {
  SelectedOption,
  makeCurrentOptionsPhrase,
  makeModifierOptionsPhrase,
} from './utils';

type ModifierBadgesProps = {
  modifierId: string;
  isNested?: boolean;
};

export function ModifierBadges({ modifierId, isNested }: ModifierBadgesProps) {
  const dispatch = useAppDispatch();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const modifier = useAppSelector((state) =>
    selectModifierById(state.menu.modifiers, modifierId),
  );

  const wasModifierPrompted = useAppSelector(
    selectWasModifierPrompted(modifierId),
  );

  const promptRequirementFullfilled = useAppSelector((state) =>
    selectIsPromptRequirementFulfilled(modifierId)(state),
  );

  const modifierOptions = useAppSelector((state) =>
    selectOptionsByParent(state.menu.options)(modifierId),
  );

  const added = useAppSelector((state) => state.menu.options.added);

  if (!modifier) {
    return null;
  }

  const modifierParent =
    useAppSelector((state) =>
      selectModifierItemParent(state.menu.items)(modifier.parentId),
    ) ??
    useAppSelector((state) =>
      selectModifierOptionParent(state.menu.options)(modifier.parentId),
    );

  const whichPhrase = useMemo(() => {
    const parentPhrase = !wasModifierPrompted
      ? `For the ${modifierParent?.spokenName}, `
      : '';

    const modifierPhrase = modifier.customWhich
      ? modifier.customWhich
      : `Which ${modifier.spokenName} would you like?`;

    return `${parentPhrase}${modifierPhrase}`;
  }, [modifier]);

  const modifierOptionsList = useMemo(
    () => makeModifierOptionsPhrase(modifier, modifierOptions),
    [modifier],
  );

  const currentOptionsPhrase = useMemo(() => {
    const currentOptions: SelectedOption[] = [];

    modifierOptions.forEach((option) => {
      if (added[option.id]) {
        currentOptions.push({ ...option, quantity: added[option.id].quantity });
      }
    });

    return makeCurrentOptionsPhrase(modifier, currentOptions);
  }, [modifier, added]);

  const addedModifierOptions = useMemo(
    () =>
      Object.values(added).filter((option) => option.parentId === modifierId),
    [added, modifierId],
  );

  const { isRequirementsFulfilled } = getIsModifierRequirementsFulfilled(
    {
      maxQuantity: modifier.maxQuantity,
      minQuantity: modifier.minQuantity,
      maxOptions: modifier.maxOptions,
      minOptions: modifier.minOptions,
      minQuantityPerOption: modifier.minQuantityPerOption,
      maxQuantityPerOption: modifier.maxQuantityPerOption,
      required: modifier.required,
      status: modifier.status,
    },
    addedModifierOptions,
  );

  const showRequiredMark = useMemo(() => {
    if (modifier.isPromptRequired && !promptRequirementFullfilled) {
      return true;
    }

    return !isRequirementsFulfilled;
  }, [modifier, promptRequirementFullfilled, isRequirementsFulfilled]);

  const showCompletedMark = useMemo(() => {
    if (modifier.isPromptRequired && !promptRequirementFullfilled) {
      return false;
    }

    return isRequirementsFulfilled;
  }, [modifier, promptRequirementFullfilled, isRequirementsFulfilled]);

  const onPrompt = () => {
    dispatch(promptModifier({ modifierId }));
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.WHICH_MODIFIER,
        message: whichPhrase,
        modifierId,
      },
    });
  };

  const handleListClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.LIST_MODIFIER,
        message: modifierOptionsList,
        modifierId: modifier.id,
      },
    });
  };
  const handleCurrentClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.CURRENT_MODIFIER,
        message: currentOptionsPhrase,
        modifierId: modifier.id,
      },
    });
  };

  return (
    <S.BadgesContainer isNested={isNested}>
      {modifier.name !== '<' && modifier.name !== '>' && (
        <>
          <AnnounceBadge
            variant="accent_2"
            className={
              modifier.customWhich ? 'badge__customWhich' : 'badge__Which'
            }
            message={whichPhrase}
            onClick={onPrompt}
            data-testid="which-badge"
          >
            Which?
          </AnnounceBadge>
          <AnnounceBadge
            variant="accent_2"
            message={modifierOptionsList}
            className="badge__List"
            onClick={handleListClick}
          >
            List
          </AnnounceBadge>
          <AnnounceBadge
            variant="accent_2"
            message={currentOptionsPhrase}
            className="badge__Current"
            onClick={handleCurrentClick}
          >
            Current
          </AnnounceBadge>
        </>
      )}

      {(modifier.minOptions || modifier.maxOptions || modifier.minQuantity) && (
        <Typography
          weight="light"
          color="primary_darker"
          className="selection__message"
        >
          {createMinimumSelectionMessage({
            maxOptions: modifier.maxOptions,
            maxQuantity: modifier.maxQuantity,
            minOptions: modifier.minOptions,
            minQuantity: modifier.minQuantity,
          })}
        </Typography>
      )}

      {showRequiredMark && (
        <RequiredMark
          minOptions={modifier.minOptions}
          maxOptions={modifier.maxOptions}
          spokenName={modifier.spokenName}
          required={modifier.required}
          modifierId={modifierId}
        />
      )}

      {showCompletedMark && <CompletedMark />}
    </S.BadgesContainer>
  );
}
