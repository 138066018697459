import { useAppDispatch, useAppSelector } from '@store';
import { selectModifiersByParent } from '@modules/menu/selectors';
import { useEffect } from 'react';
import { getOptionsFromModifier } from '@modules/menu/actions';
import * as S from './styles';

import { Modifier } from './Modifier';

type ModifierListProps = {
  itemId: string;
};

export function ItemRootModifiersList({ itemId }: ModifierListProps) {
  const dispatch = useAppDispatch();

  const rootModifiers = useAppSelector((state) =>
    selectModifiersByParent(state.menu.modifiers)(itemId),
  );

  useEffect(() => {
    rootModifiers.forEach((modifier) => {
      if (modifier.status !== 'fulfilled' && modifier.status !== 'pending') {
        dispatch(
          getOptionsFromModifier({
            modifierId: modifier.id,
          }),
        );
      }
    });
  }, [rootModifiers]);

  return (
    <S.ModifiersContainer>
      {rootModifiers.map((modifier) => (
        <Modifier key={modifier.id} modifierId={modifier.id} />
      ))}
    </S.ModifiersContainer>
  );
}
