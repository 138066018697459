export function createEstimatedTimeMessage(readyTimeInMinutes?: string) {
  if (!readyTimeInMinutes) {
    return '';
  }

  const readyTime = parseInt(readyTimeInMinutes, 10);

  let message = 'This order should be ready about ';

  message += readyTime < 10 ? '10 minutes' : `${readyTime} minutes`;

  message += ' after it’s placed.';

  return message;
}
