import { pluralize } from '@utils';

import { fields } from '@modules/payment/state';

export function makeRemainingVbxPhrase(creditCard) {
  const phrase = [];
  phrase.push(missingQuantity(creditCard));
  phrase.push(cardNumberVbx(creditCard[fields.NUMBER]));
  phrase.push(expDateVbx(creditCard[fields.EXP_DATE]));
  phrase.push(cvvVbx(creditCard[fields.CVV]));
  phrase.push(zipcodeVbx(creditCard[fields.ZIPCODE]));

  const reducedPhrase = phrase.filter(Boolean);

  const hasMoreThanOneItemRemaining = reducedPhrase.length > 2;
  const lastIdx = reducedPhrase.length - 1;
  if (hasMoreThanOneItemRemaining) {
    reducedPhrase.splice(lastIdx, 0, 'and ');
  }

  reducedPhrase[lastIdx] = reducedPhrase[lastIdx].replace(',', '.');

  return reducedPhrase.join(' ').trim();
}

function missingQuantity(creditCard) {
  const missing = Object.values(creditCard).reduce((total, field) => {
    if (field === '') return total + 1;
    return total;
  }, 0);
  return `Can I get ${missing} ${missing < 4 ? 'more' : ''} ${pluralize(
    'thing',
    missing,
  )} from you.`;
}

function cardNumberVbx(cardNumber) {
  if (cardNumber !== '') return '';
  return 'The full number on the card, ';
}

function expDateVbx(expDate) {
  if (expDate !== '') return '';
  return 'the expiration date on the card, ';
}

function cvvVbx(cvv) {
  if (cvv !== '') return '';
  return 'the security code on the card, ';
}

function zipcodeVbx(zipcode) {
  if (zipcode !== '') return '';
  return 'your billing zip code.';
}
