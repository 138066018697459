import { Button, Typography } from '@kea-inc/parrot-ui';

import { AnnounceIcon, Option } from '@components';

import { useAppDispatch, useAppSelector } from '@store';
import {
  selectIsModifierAsNoSelection,
  selectModifierById,
  selectNestedModifierIds,
  selectOptionById,
  selectOptionsByParent,
} from '@modules/menu/selectors';
import { useEffect, useMemo } from 'react';
import { addOption, getOptionsFromModifier } from '@modules/menu/actions';
import { getModifierSelectionMode } from '@utils/get-modifier-selection-mode';
import { getDefaultOptionsToMaxQuantityConfig } from '@modules/taskrouter/selectors';
import { AiFillCheckCircle } from 'react-icons/ai';
import { switchModifierAsNoSelection } from '@modules/menu/slice';
import { createOrderEvent } from '@modules/order/api';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import { isEntityAvailable } from '@utils';
import * as S from './styles';
import { Loading } from '../styles';
import { ModifierBadges } from '../ModifierBadges';

type NestedModifiersProps = {
  modifierId: string;
};

export function NestedModifiers({ modifierId }: NestedModifiersProps) {
  const modifierIds = useAppSelector((state) =>
    selectNestedModifierIds(state)(modifierId),
  );

  return (
    <S.NestedModifiersContainer>
      {modifierIds.map((id) => (
        <NestedModifier key={id} modifierId={id} />
      ))}
    </S.NestedModifiersContainer>
  );
}

// TODO: move to be a dumb component at components folder
function NestedModifier({ modifierId }: NestedModifiersProps) {
  const dispatch = useAppDispatch();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const { useHalfAddedOption } = useAppSelector(getStoreFeatureFlags);

  const modifier = useAppSelector((state) =>
    selectModifierById(state.menu.modifiers, modifierId),
  );

  const isModifierAsNoSelection = useAppSelector((state) =>
    selectIsModifierAsNoSelection(modifierId)(state),
  );

  const isEditing = useAppSelector((state) => state.menu.items.isEditing);

  const highlightedModifier = useAppSelector(
    (state) => state.menu.modifiers.highlighted,
  );

  const parent = useAppSelector((state) =>
    selectOptionById(state.menu.options, modifier?.parentId ?? ''),
  );

  const modifierOptions = useAppSelector((state) =>
    selectOptionsByParent(state.menu.options)(modifierId),
  );

  const allAddedOptions = useAppSelector((state) => state.menu.options.added);

  const showNoSelectionButton = useMemo(
    () =>
      modifier?.isPromptRequired &&
      !modifier.required &&
      !Object.values(allAddedOptions).find(
        (option) => option.parentId === modifierId,
      ),
    [modifier, allAddedOptions],
  );

  useEffect(() => {
    if (!modifier) {
      return;
    }

    if (modifier.status === 'idle' || modifier.status === 'rejected') {
      dispatch(
        getOptionsFromModifier({
          modifierId,
        }),
      );
    }
  }, [modifier?.status]);

  useEffect(() => {
    if (modifier?.status === 'fulfilled' && !isEditing) {
      modifierOptions.forEach((option) => {
        if (
          option.isDefault &&
          !allAddedOptions[option.id] &&
          isEntityAvailable(option)
        ) {
          dispatch(addOption({ optionId: option.id, useHalfAddedOption }));
        }
      });
    }
  }, [modifier, isEditing]);

  if (!modifier || !parent) {
    return null;
  }

  const selectionMode = getModifierSelectionMode({
    modifier: modifier!,
    defaultOptionsToMaxQuantity: getDefaultOptionsToMaxQuantityConfig(),
    addedOptions: Object.values(allAddedOptions),
  });

  function onNoSelectionClick() {
    dispatch(switchModifierAsNoSelection({ modifierId }));
    createOrderEvent('no_selection', {
      modifierId,
      modifierName: modifier?.name,
    });
  }

  const handleNameAnnounceClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.NAME_MODIFIER,
        message: modifier.spokenName,
        modifierId: modifier.id,
      },
    });
  };

  return (
    <S.NestedModifier
      key={modifierId}
      isHighlighted={highlightedModifier === modifier.id}
      id={modifierId}
    >
      <S.Header>
        <div>
          <Typography weight="heavy" color="primary_darker">
            {parent?.name} &gt; {modifier.name ?? modifier.description}
          </Typography>
          <AnnounceIcon
            variant="primary"
            message={modifier.spokenName}
            size={20}
            onClick={handleNameAnnounceClick}
          />

          {modifier.status === 'pending' && <Loading />}
        </div>

        <ModifierBadges modifierId={modifierId} isNested />
      </S.Header>

      {(modifier.status === 'fulfilled' || modifier.status === 'pending') &&
        modifierOptions.length > 0 && (
          <div className="nestedOptions">
            {modifierOptions.map((option) => (
              <Option
                key={option.id}
                optionId={option.id}
                selectionMode={selectionMode}
              />
            ))}
          </div>
        )}

      {!modifier.required && modifier.isPromptRequired && (
        <S.NoSelectionContainer hidden={!showNoSelectionButton}>
          <Button
            onClick={onNoSelectionClick}
            size="small"
            LeftIcon={AiFillCheckCircle}
            secondary={!isModifierAsNoSelection}
            primary={isModifierAsNoSelection}
          >
            No selection
          </Button>
        </S.NoSelectionContainer>
      )}
    </S.NestedModifier>
  );
}
