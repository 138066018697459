import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as taskrouterActions from '@modules/taskrouter/actions';
import * as deviceActions from '@modules/device/device-actions';

import { useAppSelector } from '@store';

export function useConnector() {
  const selectors = {
    agentTookAction: useAppSelector(taskrouterSelectors.getAgentTookAction),
    isReassigningTask: useAppSelector(taskrouterSelectors.getIsReassigningTask),
    conferenceSid: useAppSelector(taskrouterSelectors.getConferenceSid),
    hasOngoingTask: useAppSelector(taskrouterSelectors.getHasOngoingTask),
  };

  const actions = {
    takeAction: taskrouterActions.takeAction,
    unmute: deviceActions.unmute,
  };

  return {
    selectors,
    actions,
  };
}
