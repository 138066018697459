import { useMemo } from 'react';

import { useOrder } from '@hooks';

import { createTableNumberMessage } from '@utils/createTableNumberMessage';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import AnnounceBadge, { AnnounceBadgeProps } from '../AnnounceBadge';

type TableNumberBadgeProps = Omit<
  AnnounceBadgeProps,
  'message' | 'onClick' | 'children' | 'disabled'
>;

export function TableNumberBadge(props: TableNumberBadgeProps) {
  const { orderDetails } = useOrder();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const tableNumberMessage = useMemo(
    () => createTableNumberMessage(orderDetails.tableNumber),
    [orderDetails.tableNumber],
  );

  const handleClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: tableNumberMessage },
    });
  };

  return (
    <AnnounceBadge
      onClick={handleClick}
      disabled={false}
      message={tableNumberMessage}
      {...props}
    >
      Table Number
    </AnnounceBadge>
  );
}
