import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import { Button, Tabs, Tab, IconButton, AlertBar } from '@kea-inc/parrot-ui';

import { convertProductToValidatorItem } from '@utils/itemValidator/convertProductToValidatorItem';

import * as orderAPI from '@modules/order/api';
import * as paymentAPI from '@modules/payment/api';
import * as surveyAPI from '@modules/survey/api';
import * as accountSlice from '@modules/account/slice';
import {
  deprecatedGetConferenceSid,
  deprecatedGetStoreTimezone,
  getTaskAttributes,
  isTrainingTask,
} from '@modules/taskrouter/selectors';

import { useAppSelector, useAppDispatch } from '@store';
import { useOrder, usePayment } from '@hooks';
import * as DynamicBadges from '@components/DynamicBadges';
import { getPaymentOnArrivalAvailability } from '@utils';
import {
  handlePaybotEndOfTask,
  handleEndOfTrainingTask,
} from '@utils/handleEndOfTask';

import ItemValidator from '@utils/itemValidator/itemValidator';
import logger from '@logger';
import { DateTime } from 'luxon';
import {
  selectAllCartItems,
  selectHasPreexistingCart,
  selectTotal,
} from '@modules/cart/selectors';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { DetailsTab, CartTab, TranscriptTab } from './Tabs';

import * as S from './styles';

export const RightPanel = React.memo(({ size }) => {
  const dispatch = useAppDispatch();
  const { useOrderService } = useAppSelector(getStoreFeatureFlags);
  const {
    pending: orderPending,
    canSubmit,
    orderDetails: { handOffMode, availableTimes },
    visibleTab,
    isThrottling,
  } = useOrder();
  const hasPreexistingCart = useAppSelector(selectHasPreexistingCart);
  const { pending: paymentPending } = usePayment();
  const taskAttributes = useAppSelector(getTaskAttributes);
  const isTraining = useAppSelector(isTrainingTask);

  const [visibleCashLimitWarning, setVisibleCashLimitWarning] = useState(false);
  const [visiblePreexistingCartInfo, setVisiblePreexistingCartInfo] =
    useState(false);
  const cartItems = useAppSelector((state) =>
    selectAllCartItems(state.cart.cartItems),
  );
  const total = useAppSelector(selectTotal);
  const isFetchingPreexistingCart = useAppSelector(
    (state) => state.cart.isFetchingPreexistingCart,
  );
  const canPayOnArrival = useMemo(
    () => getPaymentOnArrivalAvailability(total),
    [total],
  );

  const { isValidatingBasket } = orderPending;
  const { isConnectingPaybot } = paymentPending;

  const handleHoverPayButtons = () => {
    if (!canSubmit) orderAPI.openChecklist();
  };

  const handlePaybotHandoff = async () => {
    const conferenceSid = deprecatedGetConferenceSid();
    surveyAPI.setOrderStatusAsSubmitted();

    if (isTraining) {
      handleEndOfTrainingTask(conferenceSid);
    } else {
      await paymentAPI.handoffPaybot();
      handlePaybotEndOfTask(conferenceSid);
    }
  };

  const throttlingErrorTime = useMemo(() => {
    const firstAvailableTime = availableTimes[0];

    if (!firstAvailableTime) {
      return 'in a few minutes';
    }

    return DateTime.fromISO(firstAvailableTime.time)
      .setZone(deprecatedGetStoreTimezone())
      .toFormat('h:mm a');
  }, [availableTimes]);

  const tabs = [
    { value: 'details', content: 'Details' },
    { value: 'transcript', content: 'Transcript' },
    { value: 'order', content: `Order (${cartItems.length})` },
  ];

  useEffect(() => {
    setVisibleCashLimitWarning(!canPayOnArrival);
  }, [canPayOnArrival]);

  useEffect(() => {
    if (taskAttributes?.handoffMode === 'dine-in') {
      orderAPI.setVisibleTab('details');
    }

    if (hasPreexistingCart) {
      orderAPI.setVisibleTab('order');
      setVisiblePreexistingCartInfo(true);
    }
  }, [hasPreexistingCart, taskAttributes?.handoffMode]);

  useEffect(() => {
    dispatch(
      accountSlice.setDetails({ key: 'phone', value: taskAttributes?.caller }),
    );
  }, [taskAttributes?.caller]);

  useEffect(() => {
    if (useOrderService) {
      // ORDER-SERVICE DOES THE VALIDATION IN THE BACKEND
      return;
    }

    if (!isFetchingPreexistingCart) {
      const abortController = new AbortController();

      const items = cartItems.map(convertProductToValidatorItem);

      const itemValidator = new ItemValidator({
        items,
      });

      itemValidator
        .calculateCurrentCartStatus()
        .then((result) => {
          logger.info('Cart items validation ran', {
            validatorResult: result,
          });
          orderAPI.setItemsValidationStatus(result);
        })
        .catch((error) => {
          logger.error('ItemValidator error', {
            validatorError: error,
          });
        });

      return () => {
        abortController.abort();
      };
    }
  }, [cartItems, handOffMode, isFetchingPreexistingCart]);

  return (
    <S.Container>
      <S.Header>
        <Tabs
          orientation="horizontal"
          variant="pill"
          tabs={tabs}
          className="header__tabs"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              active={visibleTab === tab.value}
              onClick={() => orderAPI.setVisibleTab(tab.value)}
            >
              {tab.content}
            </Tab>
          ))}
        </Tabs>

        {visibleTab === 'order' && (
          <>
            <div className="header__badges">
              <DynamicBadges.UpsellBadge />
              <DynamicBadges.RepeatCartBadge />

              {!canPayOnArrival && (
                <IconButton
                  className="cashLimit__button"
                  variant="error"
                  icon={MdWarning}
                  onClick={() => setVisibleCashLimitWarning((prev) => !prev)}
                  size={32}
                />
              )}

              <DynamicBadges.EstimatedTimeBadge />
            </div>

            {visibleCashLimitWarning && (
              <AlertBar
                className="cashLimit__warning"
                title="Payment required"
                description="Total is over store limit, customer will be required to pay now."
                variant="error"
              />
            )}

            {hasPreexistingCart && visiblePreexistingCartInfo && (
              <AlertBar
                className="preexistingCart__info"
                title="Order in progress"
                description="This order is already in progress and may have items in the cart"
                variant="information"
                onClose={() => setVisiblePreexistingCartInfo(false)}
              />
            )}
          </>
        )}

        {isThrottling && availableTimes.length > 0 && (
          <AlertBar
            className="cashLimit__warning"
            title="Store experiencing high order volumes"
            description={`Next available order time is ${throttlingErrorTime}. Please inform the customer.`}
            variant="error"
          />
        )}
      </S.Header>

      <S.Content>
        <DetailsTab hidden={visibleTab !== 'details'} size={size} />
        <TranscriptTab hidden={visibleTab !== 'transcript'} />
        {visibleTab === 'order' && <CartTab />}
      </S.Content>

      <S.Footer>
        <S.Checkout>
          <div
            className="checkout__buttonsWrapper"
            onMouseEnter={handleHoverPayButtons}
          >
            <Button
              size="small"
              onClick={handlePaybotHandoff}
              loading={isConnectingPaybot}
              disabled={!canSubmit || isValidatingBasket}
            >
              Payment
            </Button>
          </div>
        </S.Checkout>
      </S.Footer>
    </S.Container>
  );
});

RightPanel.propTypes = {
  size: PropTypes.number.isRequired,
};
