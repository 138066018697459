import React, { useEffect, useRef } from 'react';
import { Chat, Message } from '@kea-inc/parrot-ui';
import PropTypes from 'prop-types';

import { useTranscripts } from '@hooks';

import * as S from './styles';

export const TranscriptTab = React.memo(({ hidden }) => {
  const { messages } = useTranscripts();
  const lastRenderedMessageRef = useRef();
  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView();

    if (hidden) {
      lastRenderedMessageRef.current = messages.at(-1)?.id;
    }
  }, [hidden, messages]);

  return (
    <S.Container hidden={hidden}>
      <Chat animated className="transcripts__Chat">
        {React.Children.toArray(
          Object.values(messages).map((message, idx) => (
            <Message
              variant={
                message.actor === 'customer' || message.actor === 'caller'
                  ? 'received'
                  : 'sent'
              }
              name={message.fullName}
              textOnly
              key={message.id}
              animated={
                idx === messages.length - 1 &&
                lastRenderedMessageRef.current !== message.id
              }
            >
              {message.dialog}
            </Message>
          )),
        )}
        <span ref={bottomRef} />
      </Chat>
    </S.Container>
  );
});

TranscriptTab.propTypes = {
  hidden: PropTypes.bool.isRequired,
};
