export const INITIAL_STATE = {
  byId: {},
  current: null,
  errors: {
    hasFetchError: false,
  },
  pending: {
    isFetching: false,
  },
};
