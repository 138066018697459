import { orderService, dataService } from '@services';

import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import store from '@store';
/**
 * This is a temporary method.
 * WARNING: If featureFlag enableOrderServiceAccountIntegration is undefined, means that its not necessary anymore and it was removed.
 *
 *
 * If `enableOrderServiceAccountIntegration` is true we'll integrate accounts using order-service.
 * Otherwise, we'll integrate using data-service
 * @returns
 */
export function getServiceInstance() {
  const { useOrderService } = getStoreFeatureFlags(store.getState());
  if (useOrderService) {
    return orderService;
  }
  return dataService;
}
/**
 * This is a temporary method.
 * WARNING: If featureFlag enableOrderServiceAccountIntegration is undefined, means that its not necessary anymore and it was removed.
 *
 *
 * If `enableOrderServiceAccountIntegration` is true we'll integrate accounts using order-service.
 * Otherwise, we'll integrate using data-service
 * @returns "v1" - If feature flag is enabled or removed
 *
 *          "v3" - If feature flag is disabled
 */
export function getServiceAPIVersion(version = 'v3') {
  const { useOrderService } = getStoreFeatureFlags(store.getState());
  if (useOrderService) {
    return 'v1';
  }
  return version;
}
