import React from 'react';
import PropTypes from 'prop-types';

import { LoadingWrapper } from './styles';

const loadingImg =
  'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const Loading = ({ size }) => (
  <LoadingWrapper size={size}>
    <img src={loadingImg} alt="Loading..." />
  </LoadingWrapper>
);

Loading.defaultProps = {
  size: 120,
};

Loading.propTypes = {
  size: PropTypes.number,
};

export default Loading;
