import { announce } from '@modules/device/device-actions';
import { useAppDispatch } from '@store';
import { makeEntityUnavailablePhrase } from '@utils';
import React from 'react';
import { FetchStatus } from '@modules/menu/types';
import * as S from './styles';
import { OptionInfo } from '../OptionInfo';

type UnableOptionProps = {
  name: string;
  spokenName: string;
  status: FetchStatus;
  onReload: () => void;
};

export function UnavailableOption({
  name,
  spokenName,
  status,
  onReload,
}: UnableOptionProps) {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(
      announce({
        text: makeEntityUnavailablePhrase({
          name,
          spokenName,
        }),
      }),
    );
  };

  const handleReload = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    onReload();
  };

  return (
    <S.UnavailableOption onClick={handleClick}>
      <S.NotAvailable />
      <OptionInfo name={name} />
      <S.Sound />

      {status === 'rejected' && <S.Reload onClick={handleReload} />}
    </S.UnavailableOption>
  );
}
