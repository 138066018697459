import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as accountSelectors from '@modules/account/selectors';

export const useTranscripts = () => {
  const messages = useSelector(({ transcripts }) => transcripts.messages);
  const lastAutocartTranscriptionId = useSelector(
    ({ transcripts }) => transcripts.lastAutocartTranscriptionId,
  );
  const itemsByMessageId = useSelector(
    ({ transcripts }) => transcripts.itemsByMessageId,
  );

  const messagesWithReducedName = useMemo(
    () =>
      messages.map((message) => ({
        ...message,
        fullName: getFullNameByActor(message.actor),
      })),
    [messages],
  );

  return {
    messages: messagesWithReducedName,
    itemsByMessageId,
    lastAutocartTranscriptionId,
  };
};

function getFullNameByActor(actor) {
  if (actor === 'customer') {
    return accountSelectors.getAccountFullName();
  }

  return 'AI';
}
