import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { couponAdapter } from './state';

const selectSelf = (state: RootState) => state.coupons;

export const {
  selectAll: selectAllCoupons,
  selectById: selectCouponByCode,
  selectIds: selectCouponCodes,
} = couponAdapter.getSelectors();

export const isOverlayOpen = createDraftSafeSelector(
  selectSelf,
  (state) => state.isOverlayOpen,
);

export const isFetchingCoupons = createDraftSafeSelector(
  selectSelf,
  (state) => state.isFetching,
);

export const currentCoupon = createDraftSafeSelector(
  selectSelf,
  (state) => state.current,
);

export const getFilteredCoupons = createDraftSafeSelector(
  selectSelf,
  (state) => {
    if (!state.searchTerm) {
      return selectAllCoupons(state);
    }

    return selectAllCoupons(state).filter(
      (coupon) =>
        coupon.name.toLowerCase().includes(state.searchTerm!.toLowerCase()) ||
        coupon.code.toLowerCase().includes(state.searchTerm!.toLowerCase()),
    );
  },
);

export const searchTerm = createDraftSafeSelector(
  selectSelf,
  (state) => state.searchTerm,
);

export const savedCoupon = createDraftSafeSelector(
  selectSelf,
  (state) => state.savedCoupon,
);

export const isSaving = createDraftSafeSelector(
  selectSelf,
  (state) => state.isSaving,
);

export const isRemoving = createDraftSafeSelector(
  selectSelf,
  (state) => state.isRemoving,
);
