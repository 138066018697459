import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@store';
import {
  selectIsModifierAsNoSelection,
  selectModifierById,
  selectModifierHasOptionsWithNestedModifiers,
  selectOptionsByParent,
} from '@modules/menu/selectors';
import { Option } from '@components';
import { getDefaultOptionsToMaxQuantityConfig } from '@modules/taskrouter/selectors';
import { getModifierSelectionMode } from '@utils/get-modifier-selection-mode';
import { isEntityAvailable } from '@utils';

import { addOption } from '@modules/menu/actions';
import { Button } from '@kea-inc/parrot-ui';
import { AiFillCheckCircle } from 'react-icons/ai';
import { switchModifierAsNoSelection } from '@modules/menu/slice';
import { createOrderEvent } from '@modules/order/api';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { ModifierName } from './ModifierName';

import * as S from './styles';
import { ModifierBadges } from './ModifierBadges';
import { NestedModifiers } from './NestedModifiers';

type ModifierProps = {
  modifierId: string;
};

// TODO: Move this to be a dumb component at components folder
export const Modifier = ({ modifierId }: ModifierProps) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const { useHalfAddedOption } = useAppSelector(getStoreFeatureFlags);

  const modifier = useAppSelector((state) =>
    selectModifierById(state.menu.modifiers, modifierId),
  );

  const isEditing = useAppSelector((state) => state.menu.items.isEditing);

  const highlightedModifier = useAppSelector(
    (state) => state.menu.modifiers.highlighted,
  );

  const isModifierAsNoSelection = useAppSelector((state) =>
    selectIsModifierAsNoSelection(modifierId)(state),
  );

  const modifierOptions = useAppSelector((state) =>
    selectOptionsByParent(state.menu.options)(modifierId),
  );

  const allAddedOptions = useAppSelector((state) => state.menu.options.added);

  const hasNested = useAppSelector((state) =>
    selectModifierHasOptionsWithNestedModifiers(modifierId)(state),
  );

  const selectionMode = getModifierSelectionMode({
    modifier: modifier!,
    defaultOptionsToMaxQuantity: getDefaultOptionsToMaxQuantityConfig(),
    addedOptions: Object.values(allAddedOptions),
  });

  const showNoSelectionButton = useMemo(
    () =>
      modifier?.isPromptRequired &&
      !modifier.required &&
      !Object.values(allAddedOptions).find(
        (option) => option.parentId === modifierId,
      ),
    [modifier, allAddedOptions],
  );

  function onNoSelectionClick() {
    dispatch(switchModifierAsNoSelection({ modifierId }));
    createOrderEvent('no_selection', {
      modifierId,
      modifierName: modifier?.name,
    });
  }

  useEffect(() => {
    if (modifier?.status === 'fulfilled' && !isEditing) {
      modifierOptions.forEach((option) => {
        if (
          option.isDefault &&
          !allAddedOptions[option.id] &&
          isEntityAvailable(option)
        ) {
          dispatch(addOption({ optionId: option.id, useHalfAddedOption }));
        }
      });
    }
  }, [modifier, isEditing]);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    const { required, minOptions } = modifier ?? {};

    const shouldAutoSelect =
      modifierOptions?.length === 1 &&
      (required || (minOptions && minOptions > 0)) &&
      modifier?.status === 'fulfilled';

    if (shouldAutoSelect) {
      const option = modifierOptions![0];

      if (
        option &&
        !option.isDefault &&
        !allAddedOptions[option.id] &&
        isEntityAvailable(option)
      ) {
        dispatch(addOption({ optionId: option.id, useHalfAddedOption }));
      }
    }
  }, [modifierOptions, modifier, isEditing]);

  if (!modifier) {
    return null;
  }

  return (
    <S.Modifier
      // TODO: remove size
      size={50}
      isHighlighted={modifier.id === highlightedModifier}
      id={modifierId}
    >
      <S.ModifierHeader hasNested={hasNested}>
        <ModifierName name={modifier.name} status={modifier.status} />

        {hasNested && (
          <S.OpenClose
            isOpen={isOpen ? 1 : 0}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        )}
      </S.ModifierHeader>

      {isOpen && (
        <>
          <ModifierBadges modifierId={modifierId} />

          <S.ModifierOptions>
            {modifierOptions.map((option) => (
              <Option
                key={option.id}
                optionId={option.id}
                selectionMode={selectionMode}
              />
            ))}
          </S.ModifierOptions>

          {!modifier.required && modifier.isPromptRequired && (
            <S.NoSelectionContainer hidden={!showNoSelectionButton}>
              <Button
                onClick={onNoSelectionClick}
                size="small"
                LeftIcon={AiFillCheckCircle}
                secondary={!isModifierAsNoSelection}
                primary={isModifierAsNoSelection}
              >
                No selection
              </Button>
            </S.NoSelectionContainer>
          )}

          {hasNested && <NestedModifiers modifierId={modifierId} />}
        </>
      )}
    </S.Modifier>
  );
};
