import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompressedMenuParser } from '@kea-inc/menu';
import {
  categoryAdapter,
  initialMenuState,
  itemAdapter,
  linkedSpecialAdapter,
  modifierAdapter,
  optionAdapter,
} from './state';

import {
  addOption,
  getCategories,
  getCustomUpsells,
  getItemsFromCategory,
  getMenuUpsells,
  getModifiersFromParent,
  getOptionsFromModifier,
  navigateToCategory,
  navigateToItem,
  replaceLinkedSpecials,
  searchItems,
  switchOption,
} from './actions';
import { Category, Item } from './types';
import { transformAPIItem } from './utils/transform-api-item';
import { transformAPICategory } from './utils/transform.api-category';
import { transformAPIModifier } from './utils/transform-api-modifier';
import { transformAPIOption } from './utils/transform-api-option';
import { getShouldSplit } from './utils/get-should-split';

const menuSlice = createSlice({
  name: 'menu',
  initialState: initialMenuState,
  reducers: {
    resetMenu: () => initialMenuState,
    changeOptionQuantity: (
      state,
      action: PayloadAction<{ optionId: string; quantity: number }>,
    ) => {
      const { optionId, quantity } = action.payload;
      state.options.added[optionId].quantity = quantity;
    },
    changeItemsSearchTerm: (
      state,
      action: PayloadAction<{ searchTerm: string }>,
    ) => {
      state.items.searchTerm = action.payload.searchTerm;
    },
    changeSpecialInstructions: (
      state,
      action: PayloadAction<{ specialInstructions: string }>,
    ) => {
      state.specialInstructions = action.payload.specialInstructions;
    },
    changeRecipientName: (
      state,
      action: PayloadAction<{ recipientName: string }>,
    ) => {
      state.recipientName = action.payload.recipientName;
    },
    highlightModifier: (
      state,
      action: PayloadAction<{ modifierId: string | null }>,
    ) => {
      state.modifiers.highlighted = action.payload.modifierId;
    },
    promptModifier: (state, action: PayloadAction<{ modifierId: string }>) => {
      state.modifiers.prompted[action.payload.modifierId] = true;
    },
    switchModifierAsNoSelection: (
      state,
      action: PayloadAction<{ modifierId: string }>,
    ) => {
      if (state.modifiers.noSelection[action.payload.modifierId]) {
        delete state.modifiers.noSelection[action.payload.modifierId];
      } else {
        state.modifiers.noSelection[action.payload.modifierId] = true;
        state.options.added = Object.values(state.options.added).reduce(
          (acc, option) => {
            if (option.parentId === action.payload.modifierId) {
              delete acc[option.id];
            }

            return acc;
          },
          state.options.added,
        );
      }
    },
    removeAllOptions: (state) => {
      state.options.added = {};
    },
    removeOption: (state, action: PayloadAction<{ optionId: string }>) => {
      const { optionId } = action.payload;
      delete state.options.added[optionId];

      const { id, modifierIds } = state.options.entities[optionId]!;
      const addedIds = Object.keys(state.options.added);

      for (const modifierId of modifierIds) {
        for (const addedId of addedIds) {
          const option = state.options.entities[addedId]!;

          if (
            option.ancestorIds.includes(optionId) ||
            option.ancestorIds.includes(id) ||
            option.ancestorIds.includes(modifierId) ||
            option.parentId === modifierId
          ) {
            delete state.options.added[addedId];
          }
        }
      }
    },
    saveCustomUpsold: (
      state,
      action: PayloadAction<{
        ids: string[];
        type: 'category' | 'item' | 'promo' | null;
      }>,
    ) => {
      const { ids, type } = action.payload;
      state.upsold = { ids, type };
    },
    editCartItem: (
      state,
      action: PayloadAction<{
        itemId: string;
        specialInstructions: string;
        recipientName: string;
        quantity: number;
        options: { id: string; quantity: number; isFullyAdded: boolean }[];
        promptedModifiers: string[];
        noSelectionModifiers: string[];
      }>,
    ) => {
      state.options.added = {};
      state.options.fullyAddedBefore = {};

      const item = state.items.entities[action.payload.itemId];
      state.categories.current = item!.parentId;
      state.items.current = action.payload.itemId;
      state.specialInstructions = action.payload.specialInstructions;
      state.recipientName = action.payload.recipientName;
      state.quantity = action.payload.quantity;
      state.items.isEditing = true;
      state.modifiers.prompted = action.payload.promptedModifiers.reduce(
        (acc, modifierId) => {
          acc[modifierId] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      state.modifiers.noSelection = action.payload.noSelectionModifiers.reduce(
        (acc, modifierId) => {
          acc[modifierId] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );

      action.payload.options.forEach((option) => {
        const menuOption = state.options.entities[option.id];

        if (menuOption) {
          state.options.added[option.id] = {
            id: option.id,
            quantity: option.quantity,
            parentId: menuOption?.parentId,
            isFullyAdded: option.isFullyAdded ?? !menuOption.isDefault,
            chainId: menuOption?.chainId,
            isLoadingNestedModifiers: false,
          };
        }
      });
    },
    populateCompressedMenu: (
      state,
      action: PayloadAction<{
        compressedMenu: CompressedMenuParser;
      }>,
    ) => {
      const { compressedMenu } = action.payload;

      categoryAdapter.addMany(
        state.categories,
        compressedMenu
          .getCategories()
          .map((category) =>
            transformAPICategory({ ...category, hasItemsAvailable: true }),
          ),
      );

      itemAdapter.addMany(
        state.items,
        compressedMenu.getItems().map(transformAPIItem),
      );

      modifierAdapter.addMany(
        state.modifiers,
        compressedMenu.getModifiers().map((modifier) =>
          transformAPIModifier({
            ...modifier,
            maxOptions: modifier.maxQuantityPerOption,
            minOptions: modifier.minQuantityPerOption,
            isPromptRequired: modifier.forceSpecify,
            supportOptionQuantity: modifier.supportsQuantities,
            isSelectionRequired: modifier.isRequired,
            // @ts-expect-error - compressed menu types are different
            displayMode: modifier.displayMode ?? 'NESTED',
          }),
        ),
      );

      optionAdapter.addMany(
        state.options,
        compressedMenu.getOptions().map((option) =>
          transformAPIOption({
            ...option,
            type: 'option',
          }),
        ),
      );
    },
  },

  extraReducers: (builder) => {
    // Categories
    builder.addCase(getCategories.pending, (state) => {
      state.categories.isFetching = true;
      state.categories.hasFetchingError = false;
    });

    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories.isFetching = false;
      state.categories.hasFetchingError = false;

      const { categories } = action.payload;

      categoryAdapter.setAll(
        state.categories,
        categories.map(transformAPICategory),
      );
    });

    builder.addCase(getCategories.rejected, (state) => {
      state.categories.isFetching = false;
      state.categories.hasFetchingError = true;
    });

    builder.addCase(navigateToCategory.pending, (state, action) => {
      state.categories.current = action.meta.arg.categoryId;
      state.items.isEditing = false;
    });

    builder.addCase(navigateToCategory.rejected, (state) => {
      state.categories.current = null;
    });

    // Items
    builder.addCase(getItemsFromCategory.pending, (state, action) => {
      state.items.isFetching = true;
      state.items.hasFetchingError = false;

      categoryAdapter.updateOne(state.categories, {
        id: action.meta.arg.categoryId,
        changes: {
          status: 'pending',
        },
      });
    });
    builder.addCase(getItemsFromCategory.fulfilled, (state, action) => {
      state.items.isFetching = false;
      state.items.hasFetchingError = false;

      const { categoryId } = action.meta.arg;

      itemAdapter.upsertMany(
        state.items,
        action.payload.items.map((item) =>
          transformAPIItem({
            ...item,
            parentId: categoryId,
          }),
        ),
      );

      categoryAdapter.updateOne(state.categories, {
        id: categoryId,
        changes: {
          status: 'fulfilled',
        },
      });
    });

    builder.addCase(getItemsFromCategory.rejected, (state) => {
      state.items.isFetching = false;
      state.items.hasFetchingError = true;
    });

    builder.addCase(searchItems.pending, (state) => {
      state.items.isFetching = true;
      state.items.hasFetchingError = false;
    });

    builder.addCase(searchItems.fulfilled, (state, action) => {
      state.items.isFetching = false;
      state.items.hasFetchingError = false;

      itemAdapter.addMany(
        state.items,
        action.payload.items.map(transformAPIItem),
      );
    });

    builder.addCase(searchItems.rejected, (state) => {
      state.items.isFetching = false;
      state.items.hasFetchingError = true;
    });

    builder.addCase(navigateToItem.pending, (state, action) => {
      state.items.current = action.meta.arg.itemId;
      state.options.added = {};
      state.options.fullyAddedBefore = {};
      state.modifiers.highlighted = null;
      state.modifiers.prompted = {};
      state.modifiers.noSelection = {};
      state.items.isEditing = false;
    });

    builder.addCase(navigateToItem.rejected, (state) => {
      state.items.current = null;
    });

    // Modifiers
    builder.addCase(getModifiersFromParent.pending, (state, action) => {
      state.modifiers.isFetching = true;
      state.modifiers.hasFetchingError = false;

      itemAdapter.updateOne(state.items, {
        id: action.meta.arg.parentId,
        changes: {
          status: 'pending',
        },
      });

      if (state.options.entities[action.meta.arg.parentId]) {
        optionAdapter.updateOne(state.options, {
          id: action.meta.arg.parentId,
          changes: {
            status: 'pending',
          },
        });
      }
    });

    builder.addCase(getModifiersFromParent.fulfilled, (state, action) => {
      state.modifiers.isFetching = false;
      state.modifiers.hasFetchingError = false;

      const { parentId } = action.meta.arg;
      const { modifiers } = action.payload;

      modifierAdapter.upsertMany(
        state.modifiers,
        modifiers.map((modifier) =>
          transformAPIModifier({
            ...modifier,
            parentId,
          }),
        ),
      );

      if (state.items.entities[parentId]) {
        itemAdapter.updateOne(state.items, {
          id: parentId,
          changes: {
            status: 'fulfilled',
          },
        });
      }

      if (state.options.entities[parentId]) {
        optionAdapter.updateOne(state.options, {
          id: parentId,
          changes: {
            status: 'fulfilled',
          },
        });
      }
    });

    builder.addCase(getModifiersFromParent.rejected, (state, action) => {
      state.modifiers.isFetching = false;
      state.modifiers.hasFetchingError = true;

      if (state.items.entities[action.meta.arg.parentId]) {
        itemAdapter.updateOne(state.items, {
          id: action.meta.arg.parentId,
          changes: {
            status: 'rejected',
          },
        });
      }

      if (state.options.entities[action.meta.arg.parentId]) {
        const option = state.options.entities[action.meta.arg.parentId]!;

        optionAdapter.updateOne(state.options, {
          id: action.meta.arg.parentId,
          changes: {
            status: 'rejected',
          },
        });

        modifierAdapter.updateOne(state.modifiers, {
          id: option.parentId,
          changes: {
            status: 'rejected',
          },
        });
      }
    });

    // Options
    builder.addCase(getOptionsFromModifier.pending, (state, action) => {
      state.options.isFetching = true;
      state.options.hasFetchingError = false;

      modifierAdapter.updateOne(state.modifiers, {
        id: action.meta.arg.modifierId,
        changes: {
          status: 'pending',
        },
      });
    });

    builder.addCase(getOptionsFromModifier.fulfilled, (state, action) => {
      state.options.isFetching = false;
      state.options.hasFetchingError = false;

      const { modifierId } = action.meta.arg;

      const { options } = action.payload;
      optionAdapter.addMany(
        state.options,
        options.map((option) =>
          transformAPIOption({
            ...option,
            parentId: modifierId,
          }),
        ),
      );

      modifierAdapter.updateOne(state.modifiers, {
        id: modifierId,
        changes: {
          status: 'fulfilled',
        },
      });
    });

    builder.addCase(getOptionsFromModifier.rejected, (state) => {
      state.options.isFetching = false;
      state.options.hasFetchingError = true;
    });

    builder.addCase(addOption.pending, (state, action) => {
      const { optionId, quantity, useHalfAddedOption } = action.meta.arg;

      const option = state.options.entities[optionId];

      if (option?.parentId) {
        const isAdded = !!state.options.added[optionId];
        const wasFullyAddedBefore = !!state.options.fullyAddedBefore[optionId];
        const { isDefault, parentId } = option;

        let isFullyAdded = true;

        if (useHalfAddedOption) {
          isFullyAdded = !isDefault || wasFullyAddedBefore || isAdded;
        }

        state.options.added[optionId] = {
          id: optionId,
          quantity: quantity ?? 1,
          parentId,
          isFullyAdded,
          chainId: option.chainId,
          isLoadingNestedModifiers: true,
        };

        if (isFullyAdded) {
          state.options.fullyAddedBefore[optionId] = true;
        }

        const { quantityPerOption, modifierAddedOptions } = getShouldSplit({
          modifierId: option.parentId,
          addedOptions: state.options.added,
          maxQuantity:
            state.modifiers.entities[option.parentId]?.maxQuantity ?? null,
        });

        if (quantityPerOption !== null) {
          for (const addedOption of modifierAddedOptions) {
            state.options.added[addedOption.id].quantity = quantityPerOption;
          }
        }

        state.modifiers.highlighted = null;
      }
    });

    builder.addCase(addOption.fulfilled, (state, action) => {
      const { optionId } = action.meta.arg;

      if (state.options.added[optionId]) {
        state.options.added[optionId].isLoadingNestedModifiers = false;
      }
    });

    builder.addCase(switchOption.pending, (state, action) => {
      const { optionId, quantity, useHalfAddedOption } = action.meta.arg;
      const { parentId, modifierIds, isDefault, chainId } =
        state.options.entities[optionId]!;

      const lastModifierAddedOption = Object.values(state.options.added).find(
        (option) => option.parentId === parentId,
      );

      if (lastModifierAddedOption) {
        delete state.options.added[lastModifierAddedOption.id];

        const addedIds = Object.keys(state.options.added);

        for (const modifierId of modifierIds) {
          for (const addedId of addedIds) {
            const option = state.options.entities[addedId]!;

            if (
              option.ancestorIds.includes(lastModifierAddedOption.id) ||
              option.ancestorIds.includes(parentId) ||
              option.ancestorIds.includes(modifierId) ||
              option.parentId === modifierId
            ) {
              delete state.options.added[addedId];
            }
          }
        }
      }

      let isFullyAdded = true;

      if (useHalfAddedOption) {
        isFullyAdded = !isDefault || state.options.fullyAddedBefore[optionId];
      }

      state.options.added[optionId] = {
        id: optionId,
        quantity: quantity ?? 1,
        parentId,
        isFullyAdded,
        chainId,
        isLoadingNestedModifiers: true,
      };

      if (isFullyAdded) {
        state.options.fullyAddedBefore[optionId] = true;
      }

      const { quantityPerOption } = getShouldSplit({
        modifierId: parentId,
        addedOptions: state.options.added,
        maxQuantity: state.modifiers.entities[parentId]?.maxQuantity ?? null,
      });

      if (quantityPerOption !== null) {
        for (const addedOption of Object.values(state.options.added)) {
          if (addedOption.parentId === parentId) {
            state.options.added[addedOption.id].quantity = quantityPerOption;
          }
        }
      }
    });

    builder.addCase(switchOption.fulfilled, (state, action) => {
      const { optionId } = action.meta.arg;

      if (state.options.added[optionId]) {
        state.options.added[optionId].isLoadingNestedModifiers = false;
      }
    });

    // Upsells
    builder.addCase(getMenuUpsells.pending, (state) => {
      state.isFetchingUpsells = true;
    });

    builder.addCase(getMenuUpsells.fulfilled, (state, action) => {
      state.isFetchingUpsells = false;

      const { items: menuItems, categories: menuCategories } = action.payload;

      const categories: Category[] = menuCategories.map((category) => ({
        id: category.id,
        chainId: category.chainId,
        customWhich: category.customWhich ?? null,
        description: category.description ?? null,
        name: category.name,
        sortOrder: category.sortOrder,
        status: 'idle',
        version: category.version,
        isUpsellTarget: category.isUpsellTarget ?? false,
        upsellPriority: category.upsellPriority ?? 0,
        upsellPhrase: category.upsellPhrase ?? null,
        hasItemsAvailable: category.hasItemsAvailable ?? true,
        spokenName: category.spokenName ?? category.name,
        soundboardEntries: category.soundboardEntries ?? [],
      }));

      const items: Item[] = menuItems.map((item) => ({
        id: item.id,
        name: item.name,
        chainId: item.chainId,
        description: item.description,
        cost: item.cost,
        hasStockAvailable: item.hasStockAvailable,
        maxQuantity: item.maxQuantity ?? 0,
        maxTotalQuantity: item.maxTotalQuantity ?? 0,
        minQuantity: item.minQuantity ?? 0,
        minTotalQuantity: item.minTotalQuantity ?? 0,
        modifierIds: item.modifierIds ?? [],
        parentId: item.parentId,
        sortOrder: item.sortOrder,
        spokenName: item.spokenName ?? null,
        isUpsellTarget: item.isUpsellTarget ?? false,
        unavailableHandoffModes: item.unavailableHandoffModes ?? [],
        version: item.version,
        availabilityStart: item.availabilityStart ?? null,
        availabilityEnd: item.availabilityEnd ?? null,
        availabilitySchedule: item.availabilitySchedule ?? null,
        status: 'idle',
        upsellPhrase: item.upsellPhrase ?? null,
        upsellPriority: item.upsellPriority ?? 0,
        quantityInterval: item.quantityInterval ?? 1,
        linkedSpecialItemId: item.linkedSpecialItemId ?? null,
        partOfSpeech: item.partOfSpeech ?? null,
        preposition: item.preposition ?? null,
        soundboardEntries: item.soundboardEntries ?? [],
      }));

      state.menuUpsells = [...categories, ...items];
    });

    builder.addCase(getMenuUpsells.rejected, (state) => {
      state.isFetchingUpsells = false;
    });

    builder.addCase(getCustomUpsells.pending, (state) => {
      state.isFetchingUpsells = true;
    });

    builder.addCase(getCustomUpsells.fulfilled, (state, action) => {
      state.isFetchingUpsells = false;
      const { customUpsells } = action.payload;
      state.customUpsells = customUpsells;
    });

    builder.addCase(getCustomUpsells.rejected, (state) => {
      state.isFetchingUpsells = false;
    });

    builder.addCase(replaceLinkedSpecials.fulfilled, (state, action) => {
      const { linkedSpecials } = action.payload;

      linkedSpecials.forEach((linkedSpecial) => {
        linkedSpecialAdapter.addOne(
          state.linkedSpecials,
          transformAPIItem(linkedSpecial),
        );
      });
    });
  },
});

export const {
  resetMenu,
  removeOption,
  changeOptionQuantity,
  removeAllOptions,
  highlightModifier,
  changeItemsSearchTerm,
  changeRecipientName,
  changeSpecialInstructions,
  saveCustomUpsold,
  editCartItem,
  populateCompressedMenu,
  promptModifier,
  switchModifierAsNoSelection,
} = menuSlice.actions;

export default menuSlice.reducer;
