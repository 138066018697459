import { useEffect } from 'react';

import { useDelivery } from '@hooks';
import { setChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';

import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import AnnounceBadge, { AnnounceBadgeProps } from '../AnnounceBadge';

type ConfirmAddressBadgeProps = Omit<
  AnnounceBadgeProps,
  'message' | 'onClick' | 'children' | 'disabled'
>;

export function ConfirmAddressBadge(props: ConfirmAddressBadgeProps) {
  const { areDeliveryDetailsFilled, formattedAddress } = useDelivery();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const message = formattedAddress
    ? `${formattedAddress}. Is that correct? `
    : '';

  const handleClick = () => {
    setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS, true);
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  useEffect(() => {
    if (!areDeliveryDetailsFilled) {
      setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS, false);
    }
  }, [areDeliveryDetailsFilled]);

  return (
    <AnnounceBadge
      onClick={handleClick}
      disabled={!areDeliveryDetailsFilled}
      message={message}
      {...props}
    >
      Confirm Address
    </AnnounceBadge>
  );
}
