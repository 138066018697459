import { CartItemStatus, Modifier, Option, PartOfSpeech } from '@kea-inc/types';
import { CartItem } from '@modules/cart/types';
import store from '@store';
import { CartOptionsByModifierId, CartValidatorItem } from './itemValidator';

export function convertProductToValidatorItem(
  cartItem: CartItem,
): CartValidatorItem {
  const menuItem = store.getState().menu.items.entities[cartItem.menuItemId];

  if (!menuItem) {
    return {} as CartValidatorItem;
  }

  return {
    cartItemId: cartItem.id,
    menuItem: {
      categoryId: menuItem.parentId,
      maxBasketQuantity: menuItem.maxTotalQuantity || undefined,
      maxQuantity: menuItem.maxQuantity || undefined,
      minBasketQuantity: menuItem.minTotalQuantity || undefined,
      minQuantity: menuItem.minQuantity || undefined,
      modifiers: getItemModifiers(menuItem.modifierIds),
      chainId: menuItem.chainId,
      modifierIds: menuItem.modifierIds,
      unavailableHandoffModes: menuItem.unavailableHandoffModes,
      partOfSpeech: menuItem.partOfSpeech ?? PartOfSpeech.noun,
      // @ts-expect-error
      availabilitySchedule: menuItem.availabilitySchedule,
      id: menuItem.id,
      name: menuItem.name,
      preposition: menuItem.preposition ?? 'of',
      availabilityEnd: menuItem.availabilityEnd,
      availabilityStart: menuItem.availabilityStart,
      description: menuItem.description,
      hasStockAvailable: menuItem.hasStockAvailable,
      quantityInterval: menuItem.quantityInterval ?? 1,
      sortOrder: menuItem.sortOrder,
    },
    quantity: cartItem.quantity ?? 1,
    cartOptionsByModifierId: getCartOptionsByModifierId(cartItem),
    classifiedOptionsByModifierId: {},
    recipient: cartItem.recipient,
    specialInstructions: cartItem.specialInstructions,
    status: CartItemStatus.NOT_SUBMITTED,
  };
}

function getCartOptionsByModifierId(cartItem: CartItem) {
  const cartOptionsByModifierId: CartOptionsByModifierId = {};

  cartItem.options?.forEach((option) => {
    const menuOption = store.getState().menu.options.entities[option.id];

    if (menuOption) {
      const menuModifier =
        store.getState().menu.modifiers.entities[menuOption.parentId];

      if (!cartOptionsByModifierId[menuOption.parentId]) {
        cartOptionsByModifierId[menuOption.parentId] = [];
      }

      cartOptionsByModifierId[menuOption.parentId].push({
        option: {
          ...menuOption,
          adjustItemPrice: false,
          // @ts-expect-error
          availabilitySchedule: menuOption.availabilitySchedule,
        },
        quantity: option.quantity ?? 1,
        supportsQuantities: !!menuModifier?.supportOptionQuantity,
      });
    }
  });

  return cartOptionsByModifierId;
}

function getItemModifiers(modifierIds: string[]) {
  const modifiers: Modifier[] = [];

  modifierIds.forEach((id) => {
    const menuModifier = store.getState().menu.modifiers.entities[id];

    if (menuModifier) {
      modifiers.push({
        ...menuModifier,
        isRequired: menuModifier.required,
        options: [] as Option[],
        chainId: menuModifier.chainId || '',
        // @ts-expect-error
        availabilitySchedule: menuModifier.availabilitySchedule,
      });
    }
  });

  return modifiers;
}
