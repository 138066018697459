import axios from 'axios';
import env from '@beam-australia/react-env';

import { addLoggerInterceptor, addTokenInterceptor } from './interceptors';

export const baseURL = env('BOT_SERVICE');

export const api = axios.create({
  baseURL: `${baseURL}`,
});

addLoggerInterceptor(api);
addTokenInterceptor(api);

export default api;
