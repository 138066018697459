import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@kea-inc/parrot-ui';

import { AnnounceBadge, Counter } from '@components';
import { useAppDispatch, useAppSelector } from '@store';
import { useUpsell } from '@hooks';

import { getBrandFeatureFlags } from '@modules/featureFlags/selectors';
import { AddCartItemReturn } from '@modules/cart/types';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as S from './styles';
import { getIsItemRequirementsFulfilled } from './get-is-item-requirements-fulfilled';
import { useConnector } from './useConnector';
import { createUpsoldEvent } from './create-upsold-event';

let timeout: NodeJS.Timeout;

export function ItemSummary() {
  const dispatch = useAppDispatch();
  const { upsoldEntities } = useUpsell();
  const { selectors, actions } = useConnector();
  const {
    specialInstructions,
    recipientName,
    addedOptions,
    isEditing,
    currentItemId,
    currentItem,
    subtotal,
    modifiers,
    promptedModifiers,
    noSelectionModifiers,
    hasOngoingCartAction,
    currentCartItemId,
    currentCartItem,
    upsold,
    removedDefaultOptions,
  } = selectors;
  const { highlightModifier, addCartItem, updateCartItem } = actions;
  const [quantity, setQuantity] = useState(1);

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const { useUpsellV2 } = useAppSelector(getBrandFeatureFlags);

  const { isRequirementsFulfilled, erroredModifierId } = useMemo(
    () =>
      getIsItemRequirementsFulfilled({
        addedOptionsById: addedOptions,
        currentItemId,
        modifiers,
        promptedModifiers,
        noSelectionModifiers,
      }),
    [
      currentItemId,
      quantity,
      addedOptions,
      modifiers,
      promptedModifiers,
      noSelectionModifiers,
    ],
  );

  async function onAddButtonClick() {
    clearTimeout(timeout);

    if (!isRequirementsFulfilled) {
      if (erroredModifierId) {
        dispatch(highlightModifier({ modifierId: erroredModifierId }));
        scrollToModifier(erroredModifierId);

        timeout = setTimeout(() => {
          dispatch(highlightModifier({ modifierId: null }));
        }, 5000);
      }

      return;
    }

    const response = await dispatch(
      addCartItem([
        {
          menuItemId: currentItemId || '',
          quantity,
          addedOptions,
          specialInstructions,
          recipient: recipientName,
          removedDefaultOptions,
        },
      ]),
    );

    createUpsoldEvent({
      upsoldV2: useUpsellV2 ? upsold : null,
      item: currentItem!,
      orderItemId: (response.payload as AddCartItemReturn).data.items[0].id,
      quantity,
      upsoldEntities,
    });

    setQuantity(currentItem?.minQuantity ?? 1);
  }

  async function onUpdateButtonClick() {
    if (!isEditing || !isRequirementsFulfilled) {
      return;
    }

    dispatch(
      updateCartItem({
        cartItemId: currentCartItemId || '',
        quantity,
        addedOptions,
        specialInstructions,
        recipient: recipientName,
        removedDefaultOptions,
      }),
    );
  }

  function scrollToModifier(modifierId: string) {
    const modifierElement = document.getElementById(modifierId);

    if (modifierElement) {
      modifierElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  useEffect(() => {
    setQuantity(currentCartItem?.quantity ?? 1);
  }, [currentItemId]);

  const handleSubtotalClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: subtotal },
    });
  };

  return (
    <S.ItemSummary>
      <div className="subtotal">
        <AnnounceBadge
          message={subtotal}
          size="normal"
          disabled={!currentItemId}
          onClick={handleSubtotalClick}
        >
          Subtotal
        </AnnounceBadge>
        {currentItemId ? (
          <Typography weight="heavy">{subtotal}</Typography>
        ) : (
          <Typography weight="heavy">$__.__</Typography>
        )}
      </div>

      <div>
        <Counter
          noBorder
          min={currentItem?.minQuantity ?? null}
          max={currentItem?.maxQuantity ?? null}
          value={quantity}
          onDecrease={() =>
            setQuantity((prev) => prev - (currentItem?.quantityInterval ?? 1))
          }
          onIncrease={() =>
            setQuantity((prev) => prev + (currentItem?.quantityInterval ?? 1))
          }
          setQuantity={setQuantity}
          disabled={!currentItemId}
        />

        {isEditing ? (
          <S.Button
            size="small"
            onClick={onUpdateButtonClick}
            loading={hasOngoingCartAction}
            isDisabled={!isRequirementsFulfilled}
          >
            Update
          </S.Button>
        ) : (
          <S.Button
            size="small"
            onClick={onAddButtonClick}
            loading={hasOngoingCartAction}
            isDisabled={!isRequirementsFulfilled}
          >
            Add to Order
          </S.Button>
        )}
      </div>
    </S.ItemSummary>
  );
}
