import { useEffect, useMemo, useRef } from 'react';
import { Badge } from '@kea-inc/parrot-ui';
import { v4 as uuid } from 'uuid';

import { useDevice } from '@hooks';
import { makeTotalPhrase } from '@utils';
import { announce } from '@modules/device/device-actions';
import { useAppDispatch, useAppSelector } from '@store';
import { fetchPrices } from '@modules/cart/actions';
import { selectTotal } from '@modules/cart/selectors';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';

export function TotalBadge(props: { className?: string }) {
  const dispatch = useAppDispatch();
  const { useOrderService } = useAppSelector(getStoreFeatureFlags);
  const validation = useAppSelector(({ cart }) => cart.validation);
  const total = useAppSelector(selectTotal);
  const lastTotal = useRef<number>(total);
  const id = useMemo(() => uuid(), []);
  const { announcementId } = useDevice();
  const isAnnouncing = announcementId === id;

  useEffect(() => {
    lastTotal.current = total;
  }, [total]);

  const handleClick = async () => {
    await dispatch(fetchPrices());
    dispatch(
      announce({
        text: makeTotalPhrase(),
        id,
      }),
    );
  };

  return (
    <Badge
      onClick={isAnnouncing ? undefined : handleClick}
      variant={isAnnouncing ? 'gray' : 'primary'}
      disabled={useOrderService ? !validation.isValid : false}
      {...props}
    >
      Total
    </Badge>
  );
}
