import env from '@beam-australia/react-env';
import { createAsyncThunk } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';
import * as LDClient from 'launchdarkly-js-client-sdk';

import {
  FeatureFlagsBrand,
  FeatureFlagsStore,
  FeatureFlagsUser,
} from 'src/@types/featureFlags';

const envKey = env('LAUNCH_DARKLY_CLIENT_SIDE_ID');

type GetUserFeatureFlagsParams = {
  key?: string;
  secondary?: string;
  name?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  custom: {
    roles: unknown;
    source: string;
  };
};

export const getUserFeatureFlags = createAsyncThunk(
  'featureFlags/getUserFeatureFlags',
  async (params: GetUserFeatureFlagsParams, { rejectWithValue }) => {
    try {
      const client = LDClient.initialize(
        envKey,
        { kind: 'user', ...params },
        {
          bootstrap: 'localStorage',
        },
      );

      await client.waitForInitialization(1);

      const flags = client.allFlags();

      client.close();

      return camelcaseKeys(flags) as FeatureFlagsUser;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

type GetStoreFeatureFlagsParams = {
  key: string;
  name: string;
  custom: {
    source: string;
  };
};

export const getStoreFeatureFlags = createAsyncThunk(
  'featureFlags/getStoreFeatureFlags',
  async (params: GetStoreFeatureFlagsParams, { rejectWithValue }) => {
    try {
      const client = LDClient.initialize(envKey, { kind: 'user', ...params });

      await client.waitForInitialization(1);

      const flags = client.allFlags();

      client.close();

      return camelcaseKeys(flags) as FeatureFlagsStore;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

type GetBrandFeatureFlagsParams = {
  key: string;
  name: string;
  custom: {
    source: string;
  };
};

export const getBrandFeatureFlags = createAsyncThunk(
  'featureFlags/getBrandFeatureFlags',
  async (params: GetBrandFeatureFlagsParams, { rejectWithValue }) => {
    try {
      const client = LDClient.initialize(envKey, { kind: 'user', ...params });

      await client.waitForInitialization(1);

      const flags = client.allFlags();

      client.close();

      return camelcaseKeys(flags) as FeatureFlagsBrand;
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);
