import React from 'react';

import * as S from './styles';
import { HoverController } from '../HoverController';
import { OptionInfo } from '../OptionInfo';

type NotAddedOptionProps = {
  name: string;
  cost: number;
  onClick: (event: React.MouseEventHandler<HTMLElement>) => void;
  hoverText: string;
};

export function NotAddedOption({
  name,
  cost,
  onClick,
  hoverText,
}: NotAddedOptionProps) {
  return (
    <S.NotAddedOption onClick={onClick}>
      <HoverController text={hoverText} />
      <OptionInfo name={name} cost={cost} />
    </S.NotAddedOption>
  );
}
