import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.white};
  padding: 16px 16px 0 16px;
  border-radius: 8px;

  > div {
    display: flex;

    > button {
      padding: 0;
    }
  }

  .search_items {
    max-width: 225px;
    min-width: 225px;
    width: 100%;
  }
`;
