import React from 'react';
import * as S from './styles';

export type InlineRadioOptionButtonProps = {
  optionName: string;
  selected?: boolean;
} & React.HTMLAttributes<HTMLButtonElement>;

export function InlineRadioOptionButton({
  optionName,
  selected,
  ...props
}: InlineRadioOptionButtonProps) {
  return selected ? (
    <S.SelectedInlineRadioOptionButton {...props}>
      {optionName}
    </S.SelectedInlineRadioOptionButton>
  ) : (
    <S.InlineRadioOptionButton {...props}>
      {optionName}
    </S.InlineRadioOptionButton>
  );
}
