import { useState, useEffect, useRef, useMemo } from 'react';
import { DateTime } from 'luxon';
import { DatePicker, TimePicker } from '@kea-inc/parrot-ui';

import * as orderAPI from '@modules/order/api';
import * as voicePaneAPI from '@modules/voicePane/api';

import { useOrder, useStoreHours } from '@hooks';

import { deprecatedGetStoreHours } from '@modules/taskrouter/selectors';
import { ThrottledTimePicker } from '@components/ThrottledTimePicker';
import * as utils from './utils';
import * as S from './styles';

type DateTimePickerProps = {
  size: number;
  min?: Date;
  max?: Date;
};

export function DateTimePicker({ size, min, max }: DateTimePickerProps) {
  const { timezone } = useStoreHours();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(true);
  const [diff, setDiff] = useState<string>('');
  const storeHours = deprecatedGetStoreHours();

  const [date, setDate] = useState(utils.getCurrentDate());
  const datePickerRef = useRef<HTMLInputElement>(null);
  const { orderDetails, isThrottling } = useOrder();

  const handleTime = (value: string) => {
    orderAPI.sync();

    const { hours, minutes, duration } = utils.reduceTime(value);
    setDiff(duration ?? '');
    const dateWithTime = utils.getDateWithTime(date, hours, minutes, timezone);

    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    orderAPI.createOrderEvent('changed_delivery_time', {
      dateWithTime,
    });

    orderAPI.setDetails('deliveryTime', dateWithTime);

    voicePaneAPI.setVoicePaneDeliveryTime({
      deliveryTime: dateWithTime,
      isASAP: orderDetails.isASAP,
      // @ts-expect-error - TODO: fix this
      timezone,
    });
  };

  const time = useMemo(() => {
    if (!orderDetails?.deliveryTime) return '';

    return DateTime.fromMillis(orderDetails.deliveryTime)
      .setZone(timezone)
      .toFormat('hh:mm a');
  }, [orderDetails?.deliveryTime]);

  useEffect(() => {
    const today = DateTime.now().setZone(timezone);
    const dateTime = DateTime.fromJSDate(date);

    if (dateTime.toISODate() === today.toISODate() && !isThrottling) {
      handleTime(utils.getNextHourOrHalfHour());
    }
  }, [date]);

  useEffect(() => {
    if (isDatePickerOpen) {
      const content = document.querySelector('.rightPanel__content');
      content?.scroll({
        top: datePickerRef?.current?.getBoundingClientRect().top,
      });
      window.dispatchEvent(new Event('scroll'));
    }
  }, [isDatePickerOpen]);

  useEffect(() => {
    if (orderDetails.deliveryTime) {
      const previousDeliveryTime = utils.getFormattedTimeByDate(
        orderDetails.deliveryTime,
      );

      handleTime(previousDeliveryTime);
    }
  }, []);

  return (
    <section className={`order__time ${isDatePickerOpen ? 'open' : ''}`}>
      <article>
        <span className="orderTime__badgesWrapper">
          <S.Badge message={utils.makeScheduledMessage(time, date, diff)}>
            Date & Time
          </S.Badge>
          <S.Badge
            message={utils.makeScheduledMessage(time, date, diff, 'date')}
          >
            Date
          </S.Badge>
        </span>
        <DatePicker
          showTodayShortcut
          dropdownWidth={size ? size * 10 : undefined}
          value={
            orderDetails.deliveryTime
              ? new Date(orderDetails.deliveryTime)
              : undefined
          }
          onVisibilityChange={setIsDatePickerOpen}
          position="bottomRight"
          onChangeDate={setDate}
          onlyForwardDays
          ref={datePickerRef}
          onBlur={orderAPI.sync}
          timezone={timezone}
          required
          min={min}
          max={max}
        />
      </article>

      <article>
        <S.Badge message={utils.makeScheduledMessage(time, date, diff, 'time')}>
          Time (
          {DateTime.fromJSDate(utils.getCurrentDate())
            .setZone(timezone)
            .toFormat('ZZZZ')}
          )
        </S.Badge>
        {isThrottling ? (
          <ThrottledTimePicker
            // TODO: fix typing
            availableTimes={orderDetails.availableTimes.map((T: any) => T.time)}
            onChange={handleTime}
            valueMs={orderDetails.deliveryTime}
            onVisibilityChange={setIsDatePickerOpen}
            storeHours={storeHours}
            storeTimezone={timezone}
          />
        ) : (
          <TimePicker
            value={time}
            onChange={(value) => handleTime(value)}
            timezone={timezone}
            roundBy={10}
            interval={10}
            externalDate={date.getTime()}
            hour12
            required
          />
        )}
      </article>
    </section>
  );
}
