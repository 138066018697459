import { DateTime } from 'luxon';
import { IntervalWithAvailability } from '../types';

export function getNearestAvailableTime(params: {
  iso: string;
  intervals: IntervalWithAvailability[];
}): IntervalWithAvailability | null {
  const { iso, intervals } = params;

  const unavailableTimeDate = DateTime.fromISO(iso);

  let nearestInterval: IntervalWithAvailability | null = null;
  let nearestIntervalDiff = Number.MAX_SAFE_INTEGER;

  intervals.forEach((interval) => {
    if (interval.isAvailable) {
      const intervalDate = DateTime.fromISO(interval.iso);
      const diff = Math.abs(
        intervalDate.diff(unavailableTimeDate).as('minutes'),
      );

      if (diff < nearestIntervalDiff) {
        nearestIntervalDiff = diff;
        nearestInterval = interval;
      }
    }
  });

  return nearestInterval;
}
