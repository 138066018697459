import store from '@store';

interface MakeDeliveryPhrasesResponse {
  addressSearch: string;
  streetNumber: string;
  streetName: string;
  zipcode: string;
  city: string;
  state: string;
  addressLine2: string;
  deliveryInstructions: string;
}

export function makeDeliveryPhrases(
  delivery: ReturnType<typeof store.getState>['delivery'],
): MakeDeliveryPhrasesResponse {
  const {
    state,
    city,
    streetName,
    streetNumber,
    zipcode,
    formattedAddress,
    addressLine2,
    deliveryInstructions,
  } = delivery;

  const phrases: MakeDeliveryPhrasesResponse = {
    addressSearch: 'Can you please tell me your address?',
    streetNumber: 'Can you please tell me your street number?',
    streetName: 'Can you please tell me your street name?',
    zipcode: 'Can you please tell me your zipcode?',
    city: 'Can you please tell me your city?',
    state: 'Can you please tell me your state?',
    addressLine2: 'Is there any additional information for your address?',
    deliveryInstructions: 'Do you have any delivery instructions?',
  };

  if (formattedAddress) {
    phrases.addressSearch = `${formattedAddress}. Is that correct?`;
  }

  if (state) {
    phrases.state = `Your state is ${state}. Is that correct?`;
  }

  if (city) {
    phrases.city = `Your city is ${city}. Is that correct?`;
  }

  if (streetName) {
    phrases.streetName = `Your street name is ${streetName}. Is that correct?`;
  }

  if (streetNumber) {
    phrases.streetNumber = `Your street number is ${streetNumber}. Is that correct?`;
  }

  if (zipcode) {
    const zipcodeArray = zipcode.split('');
    const zipcodeWithDashes = zipcodeArray.join('---');
    phrases.zipcode = `Your zipcode is ${zipcodeWithDashes}. Is that correct?`;
  }

  if (addressLine2) {
    phrases.addressLine2 = `${addressLine2}. Is that correct?`;
  }

  if (deliveryInstructions) {
    phrases.deliveryInstructions = `Your delivery instructions are ${deliveryInstructions}. Is that correct?`;
  }

  return phrases;
}
