import { CompressedMenuParser } from '@kea-inc/menu';
import { orderService, dataService } from '@services';
import { MenuItem, Modifier, Option } from '@kea-inc/types';

import * as taskRouterSelectors from '@modules/taskrouter/selectors';
import { getMenuItemsSelections } from '@modules/order/utilsV2';
import { getServiceAPIVersion } from '@utils/getServiceForAccountIntegration';
import * as selectors from '../selectors';
import {
  GetPreviousOrderResponse,
  OrderItemModifier,
  OrderItem,
  StoreItem,
  StoreOption,
  StoreModifier,
  OrderItemDeprecated,
} from '../types';

// TODO: Change getPreviousOrder to Order module
export async function getPreviousOrder() {
  const accountId = selectors.getAccountId();
  const store = taskRouterSelectors.getStore();

  const url = `/accounts/${accountId}/store/${store?.id}/previousOrder`;
  const response = await orderService.get<GetPreviousOrderResponse>(url);

  const compressedMenuItems = await getMenuItemsSelections(
    response.data?.previousOrderCartItems,
  );

  const previousOrder = mapPreviousOrderToDomain(
    response.data,
    compressedMenuItems,
  );

  return previousOrder;
}

function mapPreviousOrderToDomain(
  apiPreviousOrder: GetPreviousOrderResponse,
  compressedMenuItems: CompressedMenuParser,
) {
  if (!apiPreviousOrder?.previousOrder?.id) {
    return null;
  }

  const previousOrderCartItems = apiPreviousOrder?.previousOrderCartItems;

  const orderItems = (previousOrderCartItems || []).reduce((acc, item) => {
    let modifiers: OrderItemModifier[] = [];
    if (item.options?.length) {
      modifiers = item.options.reduce((accOpt, option) => {
        const optionObj = compressedMenuItems.getOptionById(option.id);

        const name = optionObj ? optionObj.name : '';

        if (optionObj) {
          accOpt.push({
            externalId: option.id,
            chainId: option.id,
            quantity: option.quantity,
            name: name as string,
          });
        }
        return accOpt;
      }, [] as OrderItemModifier[]);
    }
    acc.push({
      ...item,
      itemId: item.menuItemId,
      chainId: item.menuItemId,
      storeMenuItemId: item.menuItemId,
      modifiers,
    });
    return acc;
  }, [] as OrderItem[]);

  const storeItems: StoreItem[] = [];

  compressedMenuItems.getItems().forEach((item) => {
    if (item) {
      const itemObj: StoreItem = {
        ...item,
      } as unknown as StoreItem;

      itemObj.modifiers = getItemsModifiers(
        itemObj as unknown as MenuItem,
        compressedMenuItems,
      );
      storeItems.push({
        ...itemObj,
        itemId: item.id as string,
        categoryId: item.parentId as string,
      });
    }
  });

  return {
    orderItems,
    storeItems,
  };
}

function getItemsModifiers(
  item: MenuItem,
  compressedMenuItems: CompressedMenuParser,
) {
  if (!item) {
    return [];
  }

  const modifiers: Modifier[] = [];

  item.modifierIds?.forEach((modifierId: string) => {
    const modifier: StoreModifier = compressedMenuItems.getModifierById(
      modifierId,
    ) as StoreModifier;

    if (modifier) {
      const options: Option[] = [];
      ((modifier.optionIds as String[]) || []).forEach((optionId) => {
        const option: StoreOption = compressedMenuItems.getOptionById(
          optionId as string,
        ) as StoreOption;
        if (option) {
          if (option.modifierIds && (option.modifierIds as String[]).length) {
            option.modifiers = getItemsModifiers(
              option as unknown as MenuItem,
              compressedMenuItems,
            );
          }
          options.push(option as unknown as Option);
        }
      });

      modifier.options = options?.sort((a, b) => a.sortOrder! - b.sortOrder!);

      modifiers.push(modifier as unknown as Modifier);
    }
  });

  return modifiers?.sort((a, b) => a.sortOrder! - b.sortOrder!);
}

export async function fetchPreviousOrderDeprecated() {
  const accountId = selectors.getAccountId();
  const store = taskRouterSelectors.getStore();

  const response = await dataService.get(
    `/accounts/${accountId}/brands/${store?.brandId}/store/${store?.id}/previousOrderWithAllOptions`,
    {
      version: getServiceAPIVersion(),
    },
  );

  const noPreviousOrder = Object.keys(response.data).length === 0;
  if (noPreviousOrder) {
    return null;
  }

  const previousOrder = {
    ...response.data,
    order_items: response.data.orders_items.map(
      (order_item: OrderItemDeprecated) => ({
        ...order_item,
        modifiers: JSON.parse(order_item.modifiers),
      }),
    ),
  };

  delete previousOrder.orders_items;
  return previousOrder;
}
