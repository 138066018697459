import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const InlineRadioOptionButton = styled.button`
  background: ${theme.colors.white};
  color: ${theme.colors.black};
  font-size: 12px;
  padding: 0.3rem 0.2rem;
  width: 100%;
  transition: 0.1s all ease-in-out;

  border: 1px solid ${theme.colors.gray_light};

  &:hover {
    background: ${theme.colors.gray_lighter};
  }
`;

export const SelectedInlineRadioOptionButton = styled(InlineRadioOptionButton)`
  background: ${theme.colors.primary_dark};
  color: ${theme.colors.white};

  &:hover {
    background: ${theme.colors.primary_dark};
  }
`;
