import { useState, useEffect } from 'react';
import env from '@beam-australia/react-env';

import { setWorkerStatus } from '@modules/taskrouter/actions';
import * as surveyAPI from '@modules/survey/api';

import { useInterval } from '@hooks';
import { useAppDispatch } from '@store';

export function Timeout() {
  const [leftTime, setLeftTime] = useState(safeGetSurveyTimeout());
  const dispatch = useAppDispatch();

  useInterval(() => {
    setLeftTime((prev) => prev - 1);
  }, 1000);

  useEffect(() => {
    if (leftTime === 0) {
      dispatch(setWorkerStatus('Idle'));
      surveyAPI.timeout();
    }
  }, [leftTime]);

  return <span>{leftTime}</span>;
}

const DEFAULT_TIMEOUT = 30;

const safeGetSurveyTimeout = () => {
  const timeoutInSeconds = env('SURVEY_TIMEOUT_SECONDS');
  const parsed = parseInt(timeoutInSeconds, 10);
  if (Number.isInteger(parsed)) {
    return parsed;
  }
  return DEFAULT_TIMEOUT;
};
