export const getInitials = (name) => {
  const splittedName = name.split(' ');

  if (splittedName.length === 1) {
    return name[0];
  }

  const firstName = splittedName[0];
  const lastName = splittedName[splittedName.length - 1];

  return `${firstName[0]}${lastName[0]}`;
};
