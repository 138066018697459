import { DateTime, Info } from 'luxon';

export type StoreHours = {
  actionTime: string;
  action: 'TURN_ON' | 'TURN_OFF';
  dayOfWeek: string;
};

export function getStoreClosingTime(params: {
  storeTimezone?: string;
  storeHours: StoreHours[];
}) {
  const { storeHours, storeTimezone } = params;

  const timeAtStore = DateTime.now().setZone(storeTimezone);

  const weekday = Info.weekdays('long', { locale: 'en-EN' })[
    timeAtStore.weekday - 1
  ].toUpperCase();

  const closing = storeHours.find(
    (hours) => weekday === hours.dayOfWeek && hours.action === 'TURN_OFF',
  );

  // If a closing time can't be calculated, return end of day
  if (!closing) {
    return DateTime.fromObject({
      year: timeAtStore.year,
      month: timeAtStore.month,
      day: timeAtStore.day,
      hour: 23,
      minute: 59,
      second: 59,
    }).setZone(storeTimezone);
  }

  const [closingHours, closingMinutes] = closing.actionTime.split(':');

  const closingTime = timeAtStore.set({
    year: timeAtStore.year,
    month: timeAtStore.month,
    day: timeAtStore.day,
    hour: parseInt(closingHours, 10),
    minute: parseInt(closingMinutes, 10),
    second: 0,
  });

  return closingTime;
}
