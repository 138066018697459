import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Typography, Button } from '@kea-inc/parrot-ui';

import * as orderAPI from '@modules/order/api';

import { useAccount } from '@hooks';
import {
  makePreviousOrderSummaryPhrase,
  isEntityAvailable,
  formatPrice,
} from '@utils';

import AnnounceBadge from '@components/AnnounceBadge';

import { useAppDispatch, useAppSelector } from '@store';

import { getPreviousCartItems } from '@utils/get-previous-cart-items';
import {
  closePreviousOrderMenu,
  openPreviousOrderMenu,
} from '@modules/account/slice';
import { fetchPreviousOrder } from '@modules/account/actions';
import {
  hasOngoingCartActionSelector,
  selectHasPreexistingCart,
} from '@modules/cart/selectors';
import { selectAllOptions } from '@modules/menu/selectors';

import { addCartItems } from '@modules/cart/actions';
import { AddCartItemParams } from '@modules/cart/types';
import { AddedOptions, Option } from '@modules/menu/types';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as S from './styles';

type PreviousOrderMenuProps = {
  anchor: React.MutableRefObject<any>;
};

// TODO: dumb component
export function PreviousOrderMenu({ anchor }: PreviousOrderMenuProps) {
  const dispatch = useAppDispatch();
  const [hasStartedAdding, setHasStartedAdding] = useState(false);
  const {
    isPreviousOrderMenuOpen,
    isPending,
    previousOrder,
    errors,
    id: accountId,
  } = useAccount();

  const hasOngoingCartAction = useAppSelector(hasOngoingCartActionSelector);

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const itemEntities = useAppSelector((state) => state.menu.items.entities);
  const optionEntities = useAppSelector((state) => state.menu.options.entities);

  const previousOrderItems = useMemo(
    () => getPreviousCartItems(previousOrder, itemEntities, optionEntities),
    [previousOrder, itemEntities, optionEntities],
  );

  const previousOrderVBX = useMemo(
    () => makePreviousOrderSummaryPhrase(previousOrderItems),
    [previousOrderItems],
  );

  const hasPreexistingCart = useAppSelector(selectHasPreexistingCart);
  const allOptions = useAppSelector((state) =>
    selectAllOptions(state.menu.options),
  );

  function getRemovedDefaultOptions(itemId: string, selectedOptions: Option[]) {
    const removedOptionsByModifierId: Record<string, Option[]> = {};

    allOptions.forEach((option) => {
      if (
        option.ancestorIds.includes(itemId) &&
        option.isDefault &&
        !selectedOptions.some(
          (selectedOption) => selectedOption.id === option.id,
        )
      ) {
        if (!removedOptionsByModifierId[option.parentId]) {
          removedOptionsByModifierId[option.parentId] = [];
        }
        removedOptionsByModifierId[option.parentId].push(option);
      }
    });

    return removedOptionsByModifierId;
  }

  const handleAddAllToOrder = async () => {
    setHasStartedAdding(true);
    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    orderAPI.createOrderEvent('clicked_to_add_previous_order_items_to_cart', {
      previousOrder: previousOrder.id,
    });

    const params: AddCartItemParams[] = [];

    previousOrderItems.forEach((item) => {
      const anyOptionUnavailable = item.options.some(
        (option) => isEntityAvailable(option) === false,
      );

      if (isEntityAvailable(item) === false || anyOptionUnavailable) {
        setHasStartedAdding(false);
        return;
      }

      const addedOptions = item.options.reduce<AddedOptions>((acc, option) => {
        acc[option.id] = {
          id: option.id,
          quantity: option.quantity,
          parentId: option.parentId,
          chainId: option.chainId,
          isFullyAdded: true,
          isLoadingNestedModifiers: false,
        };

        return acc;
      }, {});

      params.push({
        menuItemId: item.id,
        quantity: item.quantity,
        addedOptions,
        specialInstructions: item.specialInstructions,
        recipient: item.recipient,
        removedDefaultOptions: getRemovedDefaultOptions(item.id, item.options),
      });
    });

    await dispatch(addCartItems(params));
  };

  useEffect(() => {
    if (hasStartedAdding && !hasOngoingCartAction) {
      setHasStartedAdding(false);
      dispatch(closePreviousOrderMenu());
    }
  }, [hasOngoingCartAction]);

  useEffect(() => {
    if (hasPreexistingCart) {
      dispatch(closePreviousOrderMenu());
    } else {
      dispatch(openPreviousOrderMenu());
    }
  }, [hasPreexistingCart]);

  useEffect(() => {
    if (accountId) {
      dispatch(fetchPreviousOrder());
    }
  }, [accountId]);

  const hasError = errors.hasFetchPreviousOrderError;
  const isLoading = isPending && !previousOrder && !hasError;
  const noPreviousOrder = !isPending && !previousOrder && !hasError;
  const showPreviousOrder = !isLoading && !noPreviousOrder && !hasError;

  const handlePreviousOrderClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: previousOrderVBX },
    });
  };

  return (
    <>
      <Dropdown.Menu
        position="bottomLeft"
        width={250}
        open={isPreviousOrderMenuOpen}
        onClose={() => dispatch(closePreviousOrderMenu())}
        anchor={anchor}
      >
        {hasError && (
          <S.Wrapper>
            <Typography color="error">
              Unable to fetch previous order
            </Typography>
          </S.Wrapper>
        )}
        {isLoading && (
          <S.Wrapper>
            <S.Loading />
          </S.Wrapper>
        )}
        {noPreviousOrder && (
          <S.Wrapper>
            <Typography color="gray_dark">No previous order found</Typography>
          </S.Wrapper>
        )}
        {showPreviousOrder && (
          <>
            <S.Header>
              <AnnounceBadge
                message={previousOrderVBX}
                onClick={handlePreviousOrderClick}
              >
                Previous Order
              </AnnounceBadge>

              {/* TODO: new API does not come with END DATE */}
              {/* <Typography variant="caption" color="black_light">
                {date}
              </Typography> */}
            </S.Header>

            <Dropdown.Atoms.Divider />

            <S.OrderItems>
              {previousOrderItems.map((item) => (
                <section className="item" key={item.id + item.quantity}>
                  <Typography variant="caption" weight="heavy" color="primary">
                    {item.name}
                  </Typography>

                  <Typography weight="heavy" className="price__quantity">
                    {formatPrice(item.cost)} x{item.quantity}
                  </Typography>

                  <ul className="modifiers__list">
                    {React.Children.toArray(
                      item.options
                        ?.filter((option) => !option.isHidden)
                        .map((option) => (
                          <li key={option.id}>
                            <Typography
                              variant="caption"
                              weight="light"
                              color="gray_darker"
                            >
                              {`${option.quantity} - `}
                              {option.quantity === 0 && 'NO '}
                              {option.name}
                            </Typography>
                          </li>
                        )),
                    )}
                  </ul>
                </section>
              ))}
            </S.OrderItems>

            <Dropdown.Atoms.Divider />

            <S.Footer isOpen={isPreviousOrderMenuOpen}>
              <Button
                loading={hasOngoingCartAction}
                size="small"
                onClick={handleAddAllToOrder}
              >
                Add all to order
              </Button>
            </S.Footer>
          </>
        )}
      </Dropdown.Menu>
    </>
  );
}
