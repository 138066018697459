import { dispatch } from '@store';

import * as actions from './actions';

export function reset() {
  dispatch(actions.authReset());
}

export function login(user, token, roles) {
  dispatch(
    actions.authLogin({
      user,
      token,
      roles,
    }),
  );
}

export function setFeatureFlags(featureFlags) {
  dispatch(actions.authSetFeatureFlags(featureFlags));
}
