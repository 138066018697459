import logger from '@logger';
import parsePhoneNumber from 'libphonenumber-js';

export function formatPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) {
    return '';
  }

  if (phoneNumber.startsWith('client')) {
    return phoneNumber;
  }

  try {
    const parsed = parsePhoneNumber(phoneNumber);

    if (parsed) {
      return parsed.formatInternational();
    }

    throw new Error('Phone number could not be parsed');
  } catch (error) {
    logger.error('Error parsing phone number', {
      phoneNumber,
      error: (error as Error).message,
    });

    return phoneNumber;
  }
}
