import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  height: 460px;
  width: 350px;

  form {
    width: 100%;

    .label {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    svg {
      color: ${theme.colors.success};
    }

    .exp_date__cvv {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      > div {
        width: 48%;
      }
    }
  }
`;

export const Footer = styled.footer`
  border-top: 1px solid ${theme.colors.gray_lighter};
  padding-top: 16px;
  width: 100%;
  margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .actions {
    display: flex;
    margin-left: auto;

    button:first-child {
      margin-right: 8px;
    }
  }
`;
