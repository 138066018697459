import store, { dispatch } from '@store';

import logger from '@logger';
import { vgsStream } from '@services';
import * as accountActions from '@modules/account/actions';
import { getBrandFeatureFlags } from '@modules/featureFlags/selectors';

import * as actions from './actions';
import * as utils from './utils';
import * as selectors from './selectors';
import * as errors from './errors';
import { streamFields } from './state';

export async function connectStream() {
  if (selectors.isStreamConnected()) return;

  dispatch(actions.paymentStreamConnecting());

  try {
    await utils.connectTokenStream();
    dispatch(actions.paymentStreamConnected());

    utils.addErrorListener(vgsStream.source, errorsListener);
    utils.addTokensListener(vgsStream.source, tokensListener);
  } catch (error) {
    errors.streamConnectionError(error);
    dispatch(actions.paymentStreamNotConnected());
  }
}

export async function startPaybot() {
  openPaybotOverlay();
  dispatch(actions.paymentPaybotConnecting());

  try {
    await utils.connectPaybot();
    dispatch(actions.paymentPaybotConnected());
  } catch (error) {
    errors.paybotConnectionError(error);
    dispatch(actions.paymentPaybotNotConnected());
  }
}

export async function handoffPaybot() {
  openPaybotOverlay();
  dispatch(actions.paymentPaybotConnecting());

  const { useInkPaybot } = getBrandFeatureFlags(store.getState());

  try {
    await dispatch(accountActions.updateAccount());

    if (useInkPaybot) {
      await utils.startInkPaybot();
    } else {
      await utils.handoffPaybot();
    }

    dispatch(actions.paymentPaybotConnected());
  } catch (error) {
    errors.paybotConnectionError(error);
    dispatch(actions.paymentPaybotNotConnected());
  }
}

export async function processManualPayment() {
  dispatch(actions.creditCardReset());
  dispatch(actions.manualPaymentProcessing());

  try {
    const payload = await utils.processPayment();
    dispatch(actions.manualPaymentProcessed(payload));
  } catch (error) {
    errors.manualPaymentError(error);
    dispatch(actions.manualPaymentNotProcessed());
  }
}

export function setManualPaymentField(field, value) {
  dispatch(actions.setManualPaymentField({ field, value }));
}

export function openPaybotOverlay() {
  closeManualPaymentOverlay();
  dispatch(actions.creditCardReset());
  dispatch(actions.changePaybotVisibility(true));
}

export function closePaybotOverlay() {
  dispatch(actions.changePaybotVisibility(false));
}

export function openManualPaymentOverlay() {
  closePaybotOverlay();
  dispatch(actions.creditCardReset());
  dispatch(actions.changeManualPaymentVisibility(true));
}

export function closeManualPaymentOverlay() {
  dispatch(actions.changeManualPaymentVisibility(false));
}

export function closeStream() {
  vgsStream.close();
  utils.removeErrorListener(vgsStream.source, errorsListener);
  utils.removeTokensListener(vgsStream.source, tokensListener);
  dispatch(actions.paymentStreamClosed());
}

export function reset() {
  dispatch(actions.reset());
}

// SIDE EFFECTS
function tokensListener(event) {
  if (!event.data) return;
  logger.info('Received tokens event', event.data);
  const data = event.data.split(' ');
  const type = data[0].replace(/[^\w\s]/gi, '');
  const value = data[1];
  const validType = [...Object.values(streamFields), 'terminate'].includes(
    type,
  );
  if (!validType) return;
  const isTerminatingType = type === 'terminate';
  if (isTerminatingType) return closePaybotOverlay();
  dispatch(actions.setStreamCreditCardInfo({ type, value }));
}

function errorsListener() {
  dispatch(actions.streamCreditCardError());
}
