import produce from 'immer';
import { DateTime } from 'luxon';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE, ORDER_CHECKLIST, VALIDATE_CHECKLIST } from './state';
import { types } from './types';

const actions = {
  [types.ADD_TIPS]: (state, { payload: tips }) =>
    produce(state, (order) => {
      order.tips = tips;
      return order;
    }),

  [types.SET_ID]: (state, action) =>
    produce(state, (order) => {
      order.id = action.payload;
      return order;
    }),

  [types.SET_DETAILS]: (state, action) =>
    produce(state, (order) => {
      const { key, value } = action.payload;

      if (key === 'tableNumber') {
        order.checklist[ORDER_CHECKLIST.TABLE_NUMBER_FILLED] = !!value;
      }

      order.details[key] = value;
      return order;
    }),

  [types.REMOVE_CHECKLIST_ITEM]: (state, action) =>
    produce(state, (order) => {
      const target = action.payload;
      delete order.checklist[target];
      return order;
    }),

  [types.SET_CHECKLIST]: (state, action) =>
    produce(state, (order) => {
      const { target, value } = action.payload;
      order.checklist[target] = value;
      const { canSubmit, canValidate } = getChecklistStatus(order.checklist);
      order.canSubmit = canSubmit;
      order.canValidate = canValidate;
      return order;
    }),

  [types.VALIDATING]: (state) =>
    produce(state, (order) => {
      order.pending.isValidatingBasket = true;
      order.errors.hasBasketValidationError = false;
      const { canSubmit, canValidate } = getChecklistStatus(order.checklist);
      order.canSubmit = canSubmit;
      order.canValidate = canValidate;
      order.validateAttempts += 1;
      return order;
    }),

  [types.VALIDATED]: (state, action) =>
    produce(state, (order) => {
      order.pending.isValidatingBasket = false;
      order.errors.hasBasketValidationError = false;
      const validatedBasket =
        action.payload.validated_basket ?? action.payload.validatedBasket;
      order.validatedBasket = {
        ...validatedBasket?.basket,
        ...validatedBasket,
      };

      order.checklist[ORDER_CHECKLIST.HAS_VALIDATED] = true;

      const { canSubmit, canValidate } = getChecklistStatus(order.checklist);
      order.canSubmit = canSubmit;
      order.canValidate = canValidate;

      return order;
    }),

  [types.NOT_VALIDATED]: (state) =>
    produce(state, (order) => {
      order.pending.isValidatingBasket = false;
      order.errors.hasBasketValidationError = true;
      order.checklist[ORDER_CHECKLIST.HAS_VALIDATED] = false;

      const { canSubmit, canValidate } = getChecklistStatus(order.checklist);
      order.canSubmit = canSubmit;
      order.canValidate = canValidate;
      return order;
    }),

  [types.VALIDATION_DISMISSED]: (state) =>
    produce(state, (order) => {
      order.pending.isValidatingBasket = false;
      return order;
    }),

  [types.SUBMITTING]: (state) =>
    produce(state, (order) => {
      order.pending.isSubmittingOrder = true;
      order.errors.hasSubmitOrderError = false;
      order.isSubmitOverlayOpen = true;
      return order;
    }),

  [types.SUBMITTED]: (state) =>
    produce(state, (order) => {
      order.pending.isSubmittingOrder = false;
      order.errors.hasSubmitOrderError = false;
      order.submittedAt = new Date();
      return order;
    }),

  [types.NOT_SUBMITTED]: (state) =>
    produce(state, (order) => {
      order.pending.isSubmittingOrder = false;
      order.errors.hasSubmitOrderError = true;
      return order;
    }),

  [types.CHANGE_TIPS_OVERLAY_VISIBILITY]: (state, action) =>
    produce(state, (order) => {
      order.isTipsOverlayOpen = action.payload;
      return order;
    }),

  [types.CHANGE_SUBMIT_OVERLAY_VISIBILITY]: (state, action) =>
    produce(state, (order) => {
      order.isSubmitOverlayOpen = action.payload;
      return order;
    }),

  [types.CHANGE_CHECKLIST_VISIBILITY]: (state, action) =>
    produce(state, (order) => {
      order.isChecklistOpen = action.payload;
      return order;
    }),

  [types.CHANGE_TAX_AND_FEES_OVERLAY_VISIBILITY]: (state, action) =>
    produce(state, (order) => {
      order.isTaxAndFeesOverlayOpen = action.payload;
      return order;
    }),

  [types.UPDATING]: (state) =>
    produce(state, (order) => {
      order.pending.isUpdatingOrder = true;
      order.errors.hasUpdateOrderError = false;
      return order;
    }),

  [types.UPDATED]: (state, action) =>
    produce(state, (order) => {
      order.pending.isUpdatingOrder = false;
      order.errors.hasUpdateOrderError = false;

      if (action.payload) {
        const { agent_id, order: orderFromOrderService } = action.payload;
        if (agent_id) {
          order.hasPatchedOrderWithAgentId = true;
        }
        if (orderFromOrderService?.orderAgent?.length > 0) {
          order.hasPatchedOrderWithAgentId = true;
        }
      }
      return order;
    }),

  [types.NOT_UPDATED]: (state) =>
    produce(state, (order) => {
      order.pending.isUpdatingOrder = false;
      order.errors.hasUpdateOrderError = true;
      return order;
    }),

  [types.END_ORDER]: (state) =>
    produce(state, (order) => {
      order.details.endTime = new Date();
      return order;
    }),

  [types.ORDER_SYNCING]: (state) =>
    produce(state, (order) => {
      order.pending.isSyncingOrder = true;
      order.errors.hasOrderSyncError = false;
      return order;
    }),

  [types.ORDER_SYNCED]: (state) =>
    produce(state, (order) => {
      order.pending.isSyncingOrder = false;
      order.errors.hasOrderSyncError = false;
      return order;
    }),

  [types.ORDER_NOT_SYNCED]: (state) =>
    produce(state, (order) => {
      order.pending.isSyncingOrder = false;
      order.errors.hasOrderSyncError = true;
      return order;
    }),

  [types.BUILDING_CHECKLIST]: (state) =>
    produce(state, (order) => {
      order.pending.isBuildingChecklist = true;
      return order;
    }),

  [types.BUILD_CHECKLIST]: (state, action) =>
    produce(state, (order) => {
      order.checklist = action.payload;
      const { canSubmit, canValidate } = getChecklistStatus(order.checklist);
      order.canSubmit = canSubmit;
      order.canValidate = canValidate;
      order.pending.isBuildingChecklist = false;
      return order;
    }),

  [types.RESET]: (state) => produce(state, () => INITIAL_STATE),

  [types.SET_VISIBLE_TAB]: (state, action) =>
    produce(state, (order) => {
      order.visibleTab = action.payload;
      return order;
    }),

  [types.FETCHING_AVAILABLE_TIMES]: (state) =>
    produce(state, (order) => {
      order.pending.isFetchingAvailableTimes = true;
      order.errors.hasFetchAvailableTimesError = false;
      return order;
    }),

  [types.FETCHED_AVAILABLE_TIMES]: (state, action) =>
    produce(state, (order) => {
      order.pending.isFetchingAvailableTimes = false;
      order.errors.hasFetchAvailableTimesError = false;

      if (action.payload.availableTimes.length) {
        order.details.availableTimes = action.payload.availableTimes;
      }

      return order;
    }),

  [types.NOT_FETCHED_AVAILABLE_TIMES]: (state) =>
    produce(state, (order) => {
      order.pending.isFetchingAvailableTimes = false;
      order.errors.hasFetchAvailableTimesError = true;
      return order;
    }),

  [types.AUTO_SCHEDULE]: (state, action) =>
    produce(state, (order) => {
      const { timezone } = action.payload;
      order.details.isASAP = false;

      for (const availableTime of order.details.availableTimes) {
        if (availableTime.canAutoSchedule) {
          order.details.wasAutoScheduled = true;
          order.details.deliveryTime = isoToMillis(
            availableTime.time,
            timezone,
          );
          return order;
        }
      }

      order.details.wasAutoScheduled = false;
      if (order.details.availableTimes.length > 0) {
        order.details.deliveryTime = isoToMillis(
          order.details.availableTimes[0].time,
          timezone,
        );
      }

      return order;
    }),

  [types.SET_ORDER_THROTTLING]: (state, action) =>
    produce(state, (order) => {
      order.isThrottling = action.payload;
      return order;
    }),
};

function getChecklistStatus(checklist) {
  const isOrderChecklistComplete = Object.values(checklist).every(
    (listItem) => listItem === true,
  );

  const isValidateChecklistComplete = VALIDATE_CHECKLIST.every(
    (field) => checklist[field],
  );

  return {
    canValidate: isValidateChecklistComplete,
    canSubmit: isOrderChecklistComplete,
  };
}

function isoToMillis(iso, timezone) {
  const date = DateTime.fromISO(iso).setZone(timezone);
  return date.toMillis();
}

export const orderReducer = createReducer(INITIAL_STATE, actions);
