import styled from 'styled-components';

import { Dropdown, theme, Badge as parrotBadge } from '@kea-inc/parrot-ui';

export const Menu = styled(Dropdown.Menu)`
  border-top: 3px solid ${theme.colors.error};
`;

export const Content = styled.div`
  padding: 0 1rem;

  .description {
    margin-top: 0.3rem;
  }

  footer {
    margin-top: 0.95rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button:first-child {
      margin-right: 0.5rem;
    }
  }
`;

export const Badge = styled(parrotBadge)`
  margin-right: 1rem;
`;

export const DisabledBadge = styled(Badge)`
  pointer-events: none;
  cursor: not-allowed;
`;
