import { useEffect } from 'react';
import Split from 'react-split';

import { useAppDispatch, useAppSelector } from '@store';
import {
  useKeyPress,
  UpsellProvider,
  NewUpsellProvider,
  useThrottle,
} from '@hooks';

import { Loading } from '@components';

import { getBrandFeatureFlags } from '@modules/featureFlags/selectors';
import { LeftPanel } from './LeftPanel';
import { CenterPanel } from './CenterPanel';
import { RightPanel } from './RightPanel';
import { Overlays } from './Overlays';
import { Container, Section, Panel } from './styles';
import { useConnector } from './useConnector';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { selectors, actions } = useConnector();
  const brandFlags = useAppSelector(getBrandFeatureFlags);
  const { taskrouterBrand, handOffMode, hasOngoingTask, isMuted } = selectors;
  const { closeStream, buildChecklist, stopCurrentVbx } = actions;
  const escKey = useKeyPress('Escape');

  useEffect(
    () => () => {
      closeStream();
    },
    [],
  );

  const throttledStopCurrentVbx = useThrottle(
    () => dispatch(stopCurrentVbx()),
    1500,
  );
  useEffect(() => {
    if (escKey.isPressed) {
      throttledStopCurrentVbx();
    }
  }, [escKey]);

  useEffect(() => {
    buildChecklist();
  }, [taskrouterBrand, handOffMode, brandFlags]);

  if (!hasOngoingTask) {
    return <Loading />;
  }

  return (
    <NewUpsellProvider>
      <UpsellProvider>
        <Container isMuted={isMuted}>
          <Overlays />
          <Split
            className="split"
            sizes={[52, 24, 24]}
            minSize={[600, 320, 350]}
            expandToMin={false}
          >
            <Panel>
              <Section className="left">
                <LeftPanel />
              </Section>
            </Panel>
            <Panel>
              <Section>
                <CenterPanel />
              </Section>
            </Panel>
            <Panel>
              <Section className="right">
                <RightPanel />
              </Section>
            </Panel>
          </Split>
        </Container>
      </UpsellProvider>
    </NewUpsellProvider>
  );
};

export default Dashboard;
