import { useSelector } from 'react-redux';
import KeaOrder from '@kea-inc/order';
import * as featureFlagsSelectors from '@modules/featureFlags/selectors';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';

export const useOrder = () => {
  const errors = useSelector(({ order }) => order.errors);
  const pending = useSelector(({ order }) => order.pending);
  const tips = useSelector(({ order }) => order.tips);
  const orderDetails = useSelector(({ order }) => order.details);
  const checklist = useSelector(({ order }) => order.checklist);
  const canValidate = useSelector(({ order }) => order.canValidate);
  const canSubmit = useSelector(({ order }) => order.canSubmit);
  const id = useSelector(({ order }) => order.id);
  const isTipsOverlayOpen = useSelector(({ order }) => order.isTipsOverlayOpen);
  const isSubmitOverlayOpen = useSelector(
    ({ order }) => order.isSubmitOverlayOpen,
  );
  const isChecklistOpen = useSelector(({ order }) => order.isChecklistOpen);
  const isTaxAndFeesOverlayOpen = useSelector(
    ({ order }) => order.isTaxAndFeesOverlayOpen,
  );
  const validatedBasket = useSelector(({ order }) => order.validatedBasket);
  const brand = useSelector(({ taskrouter }) => taskrouter.brand);
  const store = taskrouterSelectors.getStore();

  const { disableTipsForCurbside, disablePickupTipping } = useSelector(
    featureFlagsSelectors.getBrandFeatureFlags,
  );

  const isPending = Object.keys(pending).some((key) => pending[key]);
  const hasError = Object.keys(errors).some((key) => errors[key]);

  const handOffMode = orderDetails?.handOffMode;

  const shouldTip = KeaOrder.getShouldTip(
    { shouldTip: brand?.shouldTip },
    { shouldTip: store?.shouldTip },
    { disableTipsForCurbside, disablePickupTipping },
    handOffMode,
  );

  const hasPatchedOrderWithAgentId = useSelector(
    ({ order }) => order.hasPatchedOrderWithAgentId,
  );

  const visibleTab = useSelector(({ order }) => order.visibleTab);
  const isThrottling = useSelector(({ order }) => order.isThrottling);

  return {
    tips,
    orderDetails,
    errors,
    pending,
    isPending,
    hasError,
    isTipsOverlayOpen,
    isSubmitOverlayOpen,
    isTaxAndFeesOverlayOpen,
    id,
    canValidate,
    canSubmit,
    checklist,
    isChecklistOpen,
    shouldTip,
    tipPercentage: brand?.tip_percentage,
    validatedBasket,
    handOffMode,
    hasPatchedOrderWithAgentId,
    visibleTab,
    isThrottling,
  };
};
