import React, { useEffect, useRef } from 'react';
import { Input } from '@kea-inc/parrot-ui';
import { AiOutlineSearch } from 'react-icons/ai';

import { useDebounce, useKeyPress } from '@hooks';

import { useAppDispatch, useAppSelector } from '@store';
import { changeItemsSearchTerm } from '@modules/menu/slice';
import { searchItems } from '@modules/menu/actions';
import { Breadcrumbs } from './Breadcrumbs';

import * as S from './styles';

export function LeftPanelHeader() {
  const dispatch = useAppDispatch();
  const debouncedDispatch = useDebounce(dispatch, 200);

  const itemSearchRef = useRef(null);
  const { isPressed } = useKeyPress(')', { withShift: true });

  const searchTerm = useAppSelector((state) => state.menu.items.searchTerm);

  useEffect(() => {
    if (searchTerm.length > 3) {
      debouncedDispatch(searchItems({ query: searchTerm }));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (isPressed) {
      itemSearchRef.current.focus();
    }
  }, [isPressed]);

  return (
    <S.Header>
      <Breadcrumbs />

      <div>
        <Input
          leftIcon={AiOutlineSearch}
          placeholder="Find items"
          className="search_items"
          value={searchTerm}
          ref={itemSearchRef}
          onChange={(e) =>
            dispatch(changeItemsSearchTerm({ searchTerm: e.target.value }))
          }
        />
      </div>
    </S.Header>
  );
}
