import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialCouponState, couponAdapter } from './state';
import {
  fetchCoupons,
  removeCouponFromOrder,
  updateOrderWithCoupon,
} from './actions';
import { Coupon } from './types';

const couponSlice = createSlice({
  name: 'coupons',
  initialState: initialCouponState,
  reducers: {
    resetCoupons: () => initialCouponState,
    openCouponOverlay: (state) => {
      state.isOverlayOpen = true;
    },
    closeCouponOverlay: (state) => {
      state.isOverlayOpen = false;
    },
    onCouponClick: (state, action: PayloadAction<Coupon>) => {
      if (state.current?.code === action.payload.code) {
        state.current = null;
      } else {
        state.current = action.payload;
      }
    },
    clearCouponSelection: (state) => {
      state.current = null;
    },
    setCustomCoupon: (state, action: PayloadAction<string>) => {
      const customCoupon = {
        id: action.payload,
        code: action.payload,
        name: action.payload,
        description: 'Coupon requested by the caller.',
        prompt: "I'll try to apply this coupon to your order.",
        createdAt: new Date().toISOString(),
      };
      state.current = customCoupon;
      couponAdapter.addOne(state, customCoupon);
    },
    onSearchTermChange: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    onCancelClick: (state) => {
      if (state.savedCoupon) {
        state.current = state.savedCoupon;
      } else {
        state.current = null;
      }
      state.isOverlayOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCoupons.pending, (state) => {
      state.isFetching = true;
      state.hasFetchError = false;
    });
    builder.addCase(fetchCoupons.fulfilled, (state, action) => {
      state.isFetching = false;
      state.hasFetchError = false;

      if (action.payload) {
        couponAdapter.setAll(state, action.payload);
      }
    });
    builder.addCase(fetchCoupons.rejected, (state) => {
      state.isFetching = false;
      state.hasFetchError = true;
    });

    builder.addCase(updateOrderWithCoupon.pending, (state) => {
      state.isSaving = true;
    });
    builder.addCase(updateOrderWithCoupon.fulfilled, (state) => {
      state.isSaving = false;
      state.savedCoupon = state.current;
      state.isOverlayOpen = false;
    });
    builder.addCase(updateOrderWithCoupon.rejected, (state) => {
      state.isSaving = false;
    });

    builder.addCase(removeCouponFromOrder.pending, (state) => {
      state.isRemoving = true;
    });
    builder.addCase(removeCouponFromOrder.fulfilled, (state) => {
      state.isRemoving = false;
      state.savedCoupon = null;
      state.isOverlayOpen = false;
      state.current = null;
    });
    builder.addCase(removeCouponFromOrder.rejected, (state) => {
      state.isRemoving = false;
    });
  },
});

const { actions } = couponSlice;

export const couponActions = {
  ...actions,
  fetchCoupons,
  updateOrderWithCoupon,
  removeCouponFromOrder,
};

export default couponSlice.reducer;
