// STORE V2 WITH REDUX-TOOLKIT
import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from '@modules/auth/reducer';
import { agentReducer } from '@modules/agent/reducer';
import { reducer as featureFlagsReducer } from '@modules/featureFlags';
import { orderReducer } from '@modules/order/reducer';
import { clockReducer } from '@modules/clock/reducer';
import { paymentReducer } from '@modules/payment/reducer';
import { voicePaneReducer } from '@modules/voicePane/reducer';
import { notificationsReducer } from '@modules/notifications/reducer';
import { transcriptsReducer } from '@modules/transcripts/reducer';
import { surveyReducer } from '@modules/survey/reducer';

import deliveryReducer from '@modules/delivery/slice';
import menuReducer from '@modules/menu/slice';
import deviceReducer from '@modules/device/device-slice';
import accountReducer from '@modules/account/slice';
import taskrouterReducer from '@modules/taskrouter/slice';
import cartReducer from '@modules/cart/slice';
import couponsReducer from '@modules/coupons/slice';

const store = configureStore({
  reducer: {
    account: accountReducer,
    agents: agentReducer,
    auth: authReducer,
    cart: cartReducer,
    clock: clockReducer,
    coupons: couponsReducer,
    delivery: deliveryReducer,
    device: deviceReducer,
    featureFlags: featureFlagsReducer,
    menu: menuReducer,
    notifications: notificationsReducer,
    order: orderReducer,
    payment: paymentReducer,
    survey: surveyReducer,
    taskrouter: taskrouterReducer,
    transcripts: transcriptsReducer,
    voicePane: voicePaneReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

// THIS SUPPORTS PROGRESSIVE MIGRATION TO REDUX-TOOLKIT.
// IT WILL BE REMOVED ONCE ALL MODULES ARE MIGRATED.
export function dispatch(
  type: string,
  payload?: any,
  target: string | null = null,
) {
  if (typeof type === 'string') {
    return store.dispatch({ type, payload, target });
  }

  store.dispatch(type);
}
export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from './hooks';

export default store;
