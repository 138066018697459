import env from '@beam-australia/react-env';

export function getCurrentVersion() {
  const version = env('SERVICE_VERSION');

  if (!version) {
    return v('1.0');
  }

  const hasLabel = version.includes('-');

  if (hasLabel) {
    return versionWithoutLabel(version);
  }

  return v(version);
}

function versionWithoutLabel(version) {
  return v(version.split('-')[0]);
}

function v(version) {
  return `v${version}`;
}
