import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useRoles } from '@hooks';
import { Loading } from '@components';

const ProtectedRoute = ({ component, path, ...args }) => {
  const { roles, availableRoutes } = useRoles();

  const userHasNoRoles =
    roles && availableRoutes && availableRoutes.length === 0;
  const userNotAllowed =
    availableRoutes.length > 0 && !availableRoutes.includes(path);

  if (userHasNoRoles || userNotAllowed) {
    return (
      <Route render={() => <Redirect to="/404" />} path={path} {...args} />
    );
  }

  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loading />,
      })}
      path={path}
      {...args}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
