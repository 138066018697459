import qs from 'qs';

import {
  operatorService,
  dataService,
  vgsService,
  vgsStream,
  botService,
} from '@services';

import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import * as agentSelectors from '@modules/agent/selectors';
import * as orderSelectors from '@modules/order/selectors';
import { getVBxClosingMessage } from '@modules/order/utils';

import {
  getPaymentOnArrivalAvailability,
  getCashLimitValues,
} from '@utils/getPaymentOnArrivalAvailability';

import * as selectors from './selectors';
import { fields } from './state';

// STREAM
export async function connectTokenStream() {
  const id = taskrouterSelectors.deprecatedGetOrderId();
  const source = await dataService.getEvent(`/vgs-tokens/${id}/stream`);
  vgsStream.setSource(source);
}

export function addErrorListener(source, cb) {
  source.addEventListener('error', cb, false);
}

export function addTokensListener(source, cb) {
  source.addEventListener('tokens', cb, false);
}

export function removeErrorListener(source, cb) {
  if (source) {
    source.removeEventListener('error', cb);
  }
}

export function removeTokensListener(source, cb) {
  if (source) {
    source.removeEventListener('tokens', cb);
  }
}

// PAYBOT
export async function connectPaybot() {
  const id = taskrouterSelectors.deprecatedGetOrderId();
  const caller = taskrouterSelectors.getCaller();
  const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
  const agent = agentSelectors.currentAgent();

  const payload = {
    caller,
    agent: agent.username,
    orderId: id,
  };

  const url = `/Conferences/${conferenceSid}/connectPaybot`;
  const response = await operatorService.post(url, payload);

  return response.data;
}

export async function handoffPaybot() {
  const conferenceName = taskrouterSelectors.getConferenceName();
  const conferenceSid = taskrouterSelectors.deprecatedGetConferenceSid();
  const caller = taskrouterSelectors.getCaller();
  const store = taskrouterSelectors.getStore();
  const orderId = taskrouterSelectors.deprecatedGetOrderId();
  const agent = agentSelectors.currentAgent();
  const brandVoice = taskrouterSelectors.getBrandVoice();
  const confirmationMessage = getVBxClosingMessage();
  const total = orderSelectors.getTotal();
  const { handOffMode } = orderSelectors.getDetails();
  const { storeCashLimit } = getCashLimitValues();

  const payload = {
    conference_name: conferenceName,
    caller,
    store: store?.secondaryPhone,
    order_id: orderId,
    agent: agent.username,
    brand_voice: brandVoice,
    confirmation_message: confirmationMessage,
    cash_limit: storeCashLimit,
    handoff_mode: handOffMode,
    can_pay_on_arrival: getPaymentOnArrivalAvailability(total),
  };

  const url = `/Conferences/${conferenceSid}/connectPaybot`;
  const response = await operatorService.post(url, payload);

  return response.data;
}

export async function startInkPaybot() {
  const callbackCallSid = deviceSelectors.getCallbackCallSid();
  const caller = taskrouterSelectors.getCaller();
  const store = taskrouterSelectors.getStore();
  const orderId = taskrouterSelectors.deprecatedGetOrderId();

  const response = await botService.post('/start_ink_paybot', {
    from: caller,
    to: store?.twilioNumber,
    orderId,
    callbackCallSid,
  });

  return response.data;
}

// MANUAL PAYMENT
export async function processPayment() {
  const cardNumber = await processCardField(fields.NUMBER);
  const expDate = await processCardField(fields.EXP_DATE);
  const zipcode = await processCardField(fields.ZIPCODE);
  const cvv = await processCardField(fields.CVV);
  return { cardNumber, cvv, expDate, zipcode };
}

async function processCardField(field) {
  const value = selectors.getManualCreditCardValueByField(field);
  const caller = taskrouterSelectors.getCaller();

  const payload = qs.stringify({
    Caller: caller,
    Digits: value.replace(/ /g, '').replace('/', ''),
  });

  const url = `/paybot/${field}/collect`;

  const response = await vgsService.post(url, payload);
  return response.data;
}
