import { createAsyncThunk } from '@reduxjs/toolkit';
import { apolloClient } from 'src/graphql/apollo-client';
import * as schema from 'src/graphql/generated/schema';
import { getStore as taskrouterGetStore } from '@modules/taskrouter/selectors';
import { dataService } from '@services';
import { Upsell } from '@kea-inc/types';
import camelcaseKeys from 'camelcase-keys';

import { relayToArray } from '@utils/relay-to-array';
import {
  selectCategoryById,
  selectItemById,
  selectModifierById,
  selectOptionById,
} from './selectors';
import { MenuState } from './types';

export const getCategories = createAsyncThunk(
  'menu/getCategories',
  async (_, { rejectWithValue }) => {
    try {
      const store = getStore();

      // TODO: move to a new file (graphql/queries.ts) - all instances
      const response = await apolloClient.query<
        schema.GetCategoriesV2Query,
        schema.GetCategoriesV2QueryVariables
      >({
        query: schema.GetCategoriesV2Document,
        variables: { storeId: store.id },
      });

      return {
        categories: relayToArray(response.data.categoriesV2),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const getItemsFromCategory = createAsyncThunk(
  'menu/getItemsFromCategory',
  async (params: { categoryId: string }, { rejectWithValue, dispatch }) => {
    try {
      const { categoryId } = params;

      const store = getStore();

      const response = await apolloClient.query<
        schema.GetItemsFromCategoryV2Query,
        schema.GetItemsFromCategoryV2QueryVariables
      >({
        query: schema.GetItemsFromCategoryV2Document,
        variables: { storeId: store.id, categoryId },
      });

      const items = relayToArray(response.data.itemsV2);
      const itemIdByLinkedSpecialId: Record<string, string> = {};

      items.forEach((item) => {
        if (item.linkedSpecialItemId) {
          itemIdByLinkedSpecialId[item.linkedSpecialItemId] = item.id;
        }
      });

      if (Object.keys(itemIdByLinkedSpecialId).length > 0) {
        dispatch(replaceLinkedSpecials({ itemIdByLinkedSpecialId }));
      }

      return {
        items,
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const navigateToCategory = createAsyncThunk(
  'menu/navigateToCategory',
  async (
    params: { categoryId: string | null },
    { dispatch, rejectWithValue, getState, fulfillWithValue },
  ) => {
    const { categoryId } = params;

    if (categoryId === null) {
      return fulfillWithValue(null);
    }

    try {
      const { menu } = getState() as { menu: MenuState };
      const category = selectCategoryById(menu.categories, categoryId);

      if (category?.status === 'fulfilled' || category?.status === 'pending') {
        return fulfillWithValue(categoryId);
      }

      await dispatch(
        getItemsFromCategory({
          categoryId,
        }),
      )
        .unwrap()
        .catch((error) => {
          rejectWithValue(error);
        });

      return fulfillWithValue(null);
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const searchItems = createAsyncThunk(
  'menu/searchItems',
  async (params: { query: string }, { rejectWithValue }) => {
    try {
      const { query } = params;

      const store = getStore();

      const response = await apolloClient.query<
        schema.SearchItemsQuery,
        schema.SearchItemsQueryVariables
      >({
        query: schema.SearchItemsDocument,
        variables: { storeId: store.id, name: query },
      });

      return {
        items: relayToArray(response.data.itemsV2),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const navigateToItem = createAsyncThunk(
  'menu/navigateToItem',
  async (
    params: { itemId: string | null },
    { fulfillWithValue, getState, rejectWithValue, dispatch },
  ) => {
    const { itemId } = params;

    if (itemId === null) {
      return fulfillWithValue(null);
    }

    try {
      const { menu } = getState() as { menu: MenuState };
      const item = selectItemById(menu.items, itemId);

      if (item?.status === 'fulfilled' || item?.status === 'pending') {
        return fulfillWithValue(itemId);
      }

      await dispatch(
        getModifiersFromParent({
          parentId: itemId,
        }),
      )
        .unwrap()
        .catch((error) => {
          rejectWithValue(error);
        });

      return fulfillWithValue(itemId);
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const getModifiersFromParent = createAsyncThunk(
  'menu/getModifiersFromParent',
  async (params: { parentId: string }, { rejectWithValue }) => {
    try {
      const { parentId } = params;

      const store = getStore();

      const response = await apolloClient.query<
        schema.GetModifiersFromParentV2Query,
        schema.GetModifiersFromParentV2QueryVariables
      >({
        query: schema.GetModifiersFromParentV2Document,
        variables: { storeId: store.id, parentId },
      });

      return {
        modifiers: relayToArray(response.data.modifiers),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const getOptionsFromModifier = createAsyncThunk(
  'menu/getOptionsFromModifier',
  async (params: { modifierId: string }, { rejectWithValue }) => {
    try {
      const { modifierId } = params;
      const store = getStore();

      const response = await apolloClient.query<
        schema.GetOptionsFromModifierV2Query,
        schema.GetOptionsFromModifierV2QueryVariables
      >({
        query: schema.GetOptionsFromModifierV2Document,
        variables: { storeId: store.id, parentId: modifierId },
      });

      return {
        options: relayToArray(response.data.optionsV2),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const addOption = createAsyncThunk(
  'menu/addOption',
  async (
    params: {
      optionId: string;
      quantity?: number | null;
      useHalfAddedOption?: boolean;
    },
    { rejectWithValue, getState, fulfillWithValue, dispatch },
  ) => {
    try {
      const { optionId } = params;

      const { menu } = getState() as { menu: MenuState };
      const option = selectOptionById(menu.options, optionId);

      const hasNestedModifiers =
        option?.modifierIds && option?.modifierIds?.length > 0;

      if (hasNestedModifiers) {
        if (option.status !== 'fulfilled' && option.status !== 'pending') {
          await dispatch(
            getModifiersFromParent({
              parentId: optionId,
            }),
          ).unwrap();
        }

        const promises: Promise<unknown>[] = [];

        option.modifierIds.forEach((modifierId) => {
          const modifier = selectModifierById(menu.modifiers, modifierId);

          if (
            modifier?.status !== 'fulfilled' &&
            modifier?.status !== 'pending'
          ) {
            promises.push(
              dispatch(
                getOptionsFromModifier({
                  modifierId,
                }),
              ),
            );
          }
        });

        await Promise.all(promises);
      }

      return fulfillWithValue(optionId);
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const switchOption = createAsyncThunk(
  'menu/switchOption',
  async (
    params: {
      optionId: string;
      quantity?: number | null;
      useHalfAddedOption?: boolean;
    },
    { rejectWithValue, getState, fulfillWithValue, dispatch },
  ) => {
    try {
      const { optionId } = params;

      const { menu } = getState() as { menu: MenuState };

      const option = selectOptionById(menu.options, optionId);

      const isPending = option?.status === 'pending';
      const isFulfilled = option?.status === 'fulfilled';
      const noNestedModifiers = option?.modifierIds?.length === 0;

      if (isPending || isFulfilled || noNestedModifiers) {
        return fulfillWithValue(optionId);
      }

      if (option?.modifierIds) {
        await dispatch(
          getModifiersFromParent({
            parentId: optionId,
          }),
        );

        const promises: Promise<unknown>[] = [];

        option.modifierIds.forEach((modifierId) => {
          const modifier = selectModifierById(menu.modifiers, modifierId);

          if (
            modifier?.status !== 'fulfilled' &&
            modifier?.status !== 'pending'
          )
            promises.push(
              dispatch(
                getOptionsFromModifier({
                  modifierId,
                }),
              ),
            );
        });

        await Promise.all(promises);
      }

      return fulfillWithValue(optionId);
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const getCustomUpsells = createAsyncThunk(
  'menu/getCustomUpsells',
  async (_, { rejectWithValue }) => {
    try {
      const store = getStore();

      const response = await dataService.get<Upsell[]>(
        `/brands/${store.brandId}/upsells`,
      );

      return {
        customUpsells: camelcaseKeys(response.data),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const getMenuUpsells = createAsyncThunk(
  'menu/getMenuUpsells',
  async (_, { rejectWithValue }) => {
    try {
      const store = getStore();

      const response = await apolloClient.query<
        schema.GetUpsellsQuery,
        schema.GetUpsellsQueryVariables
      >({
        query: schema.GetUpsellsDocument,
        variables: { storeId: store.id },
      });

      return {
        categories: relayToArray(response.data.categoriesV2),
        items: relayToArray(response.data.itemsV2),
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

export const replaceLinkedSpecials = createAsyncThunk(
  'menu/replaceLinkedSpecials',
  async (
    params: { itemIdByLinkedSpecialId: Record<string, string> },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { itemIdByLinkedSpecialId } = params;
      const linkedSpecialIds = Object.keys(itemIdByLinkedSpecialId);

      const store = getStore();

      const response = await Promise.all(
        linkedSpecialIds.map((id) =>
          apolloClient.query<
            schema.GetItemByIdQuery,
            schema.GetItemByIdQueryVariables
          >({
            query: schema.GetItemByIdDocument,
            variables: { storeId: store.id, itemId: id },
          }),
        ),
      );

      const linkedSpecials = response
        .map((item) => relayToArray(item.data.itemsV2))
        .flat();

      await Promise.all(
        linkedSpecials.map((linkedSpecial) =>
          dispatch(
            getItemsFromCategory({
              categoryId: linkedSpecial.parentId,
            }),
          ),
        ),
      );

      return {
        linkedSpecials,
      };
    } catch (error) {
      const typedError = error as Error;
      return rejectWithValue(typedError.message);
    }
  },
);

function getStore() {
  const store = taskrouterGetStore();

  if (!store) {
    throw new Error('Store is not defined');
  }

  return store;
}
