import { useEffect, useMemo, useState } from 'react';

import { setVoicePaneCategory } from '@modules/voicePane/api';
import { useAppSelector } from '@store';
import {
  selectCategoryEntities,
  selectItemEntities,
} from '@modules/menu/selectors';

export const useVoicePane = () => {
  const { prompts, currentContext, filteredPrompts } = useAppSelector(
    ({ voicePane }) => voicePane,
  );

  const categoryEntities = useAppSelector((state) =>
    selectCategoryEntities(state.menu.categories),
  );

  const itemsEntities = useAppSelector((state) =>
    selectItemEntities(state.menu.items),
  );

  const currentCategoryId = useAppSelector(
    (state) => state.menu.categories.current,
  );

  const currentItemId = useAppSelector((state) => state.menu.items.current);

  const [itemSensitivePrompts, setItemSensitivePrompts] = useState<string[]>(
    [],
  );

  const categoryBased = useMemo(() => {
    if (!currentCategoryId) {
      return [];
    }

    const category = categoryEntities[currentCategoryId];

    if (!category) {
      return [];
    }

    const defaultCategoryBased = prompts.byCategory[category.name] || [];
    const metadataCategoryBased = category.soundboardEntries;

    return [...defaultCategoryBased, ...metadataCategoryBased];
  }, [currentCategoryId]);

  const soundboardEntriesPerItem = useMemo(() => {
    const items = Object.values(itemsEntities);
    const entries: Record<string, string[]> = {};

    items.forEach((item) => {
      if (item) {
        entries[item.id] = item?.soundboardEntries;
      }
    });

    return entries;
  }, [itemsEntities]);

  useEffect(() => {
    if (currentCategoryId) {
      const category = categoryEntities[currentCategoryId];

      if (category) {
        setVoicePaneCategory(category.name);
      }
    }
  }, [currentCategoryId]);

  useEffect(() => {
    if (currentItemId) {
      const entries = soundboardEntriesPerItem[currentItemId];
      setItemSensitivePrompts(entries);
    }
  }, [currentItemId]);

  return {
    prompts,
    currentContext,
    categoryBased,
    filteredPrompts,
    itemSensitivePrompts,
  };
};
