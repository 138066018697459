import { useEffect } from 'react';
import { Badge } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { useDelivery } from '@hooks';
import { setChecklist } from '@modules/order/api';

import { ChecklistItem } from './ChecklistItem';
import * as S from '../styles';

export function IsAddressValid() {
  const {
    isValidatingAddress,
    isAddressValidated,
    hasFetchingError,
    canDeliver,
    isAddressFetched,
    handleAddressValidation,
  } = useDelivery();

  useEffect(() => {
    setChecklist(ORDER_CHECKLIST.ADDRESS_IS_VALID, canDeliver);
  }, [canDeliver]);

  return (
    <ChecklistItem field={ORDER_CHECKLIST.ADDRESS_IS_VALID}>
      <Badge
        variant={isAddressValidated && canDeliver ? 'success' : 'warning'}
        disabled={
          isValidatingAddress || (!isAddressFetched && !hasFetchingError)
        }
        onClick={handleAddressValidation}
      >
        Address is valid
      </Badge>
      {isValidatingAddress && (
        <S.Loading data-testid="validateAddress__loading" />
      )}
    </ChecklistItem>
  );
}
