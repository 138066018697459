import React, { useEffect, useState } from 'react';
import { Button, Input, Typography } from '@kea-inc/parrot-ui';
import { MdOutlineSearch } from 'react-icons/md';

import { useAppDispatch } from '@store';
import { AnnounceBadge, AnnounceIcon, CenterOverlay } from '@components';
import { Highlight } from '@components/HighlightTypography';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';

import * as S from './styles';
import { makeCouponsListMessage } from './utils';
import { useConnector } from './useConnector';

const CouponsOverlay = () => {
  const dispatch = useAppDispatch();
  const { selectors, actions } = useConnector();
  const {
    brandId,
    storeId,
    coupons,
    currentCoupon,
    isFetchingCoupons,
    searchTerm,
    filteredCoupons,
    isSaving,
    isRemoving,
  } = selectors;
  const {
    clearCouponSelection,
    fetchCoupons,
    removeCoupon,
    selectCoupon,
    updateOrderWithCoupon,
    setCustomCoupon,
    setSearchTerm,
    onCancelClick,
  } = actions;

  const [customCode, setCustomCode] = useState(''); // Coupon code that the customer might have and is not in our DB.

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const handleCustomCoupon = () => {
    if (!customCode) {
      return;
    }
    dispatch(setCustomCoupon(customCode));
    setCustomCode('');
  };

  const couponsListMessage = makeCouponsListMessage(coupons);

  const handleAnnounceClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  useEffect(() => {
    if (coupons.length === 0) {
      dispatch(
        fetchCoupons({
          brandId,
          storeId,
        }),
      );
    }
  }, []);

  return (
    <CenterOverlay>
      <S.Container>
        <S.Header>
          <div>
            <Typography weight="heavy" variant="subheader">
              Promo / Coupon / Deal
            </Typography>
            {isFetchingCoupons && <S.Loading />}
          </div>
          <AnnounceBadge
            onClick={handleAnnounceClick(couponsListMessage)}
            disabled={coupons.length === 0}
            message={couponsListMessage}
          >
            List/Which
          </AnnounceBadge>
        </S.Header>

        <S.SearchContainer>
          <Input
            placeholder="Find coupons"
            label="Search"
            leftIcon={MdOutlineSearch}
            value={searchTerm || ''}
            onChange={(e) => dispatch(setSearchTerm(e.target.value))}
            data-testid="search-input"
            required
          />
        </S.SearchContainer>

        <S.Coupons>
          <li>
            <S.Coupon
              onClick={() => dispatch(clearCouponSelection())}
              selected={currentCoupon === null}
            >
              <S.CouponCode>
                <Typography variant="body">(None)</Typography>
              </S.CouponCode>

              <S.Divider />

              <S.CouponContent>
                <Typography variant="body">No Coupon Selected</Typography>
              </S.CouponContent>
            </S.Coupon>
          </li>

          {filteredCoupons.map((coupon) => {
            const couponMessage = `${coupon.name}: ${coupon.description}`;
            return (
              <li key={coupon.id}>
                <S.Coupon
                  onClick={() => dispatch(selectCoupon(coupon))}
                  selected={currentCoupon?.id === coupon.id}
                >
                  <S.CouponCode data-testid="coupon-code">
                    <Highlight searchTerm={searchTerm || ''} variant="body">
                      {coupon.code}
                    </Highlight>
                  </S.CouponCode>

                  <S.Divider />

                  <S.CouponContent>
                    <Highlight searchTerm={searchTerm || ''} variant="body">
                      {coupon.name}
                    </Highlight>

                    <div>
                      <Highlight
                        searchTerm={searchTerm || ''}
                        variant="caption"
                      >
                        {coupon.description}
                      </Highlight>
                      <AnnounceIcon
                        variant="primary"
                        message={couponMessage}
                        size={16}
                        onClick={handleAnnounceClick(couponMessage)}
                      />
                    </div>
                  </S.CouponContent>
                </S.Coupon>
              </li>
            );
          })}

          <li>
            <S.Coupon>
              <S.CouponCode>
                <Typography variant="body">CUSTOM</Typography>
              </S.CouponCode>

              <S.Divider />

              <S.CouponContent>
                <div>
                  <Input
                    value={customCode || ''}
                    onChange={(e) =>
                      setCustomCode(e.target.value.toUpperCase())
                    }
                    onKeyDown={(e) =>
                      e.key === 'Enter' ? handleCustomCoupon() : null
                    }
                    onBlur={handleCustomCoupon}
                    data-testid="custom-coupon-input"
                  />
                  <AddCustomCouponButton
                    onClick={handleCustomCoupon}
                    disabled={customCode === null}
                  />
                </div>
              </S.CouponContent>
            </S.Coupon>
          </li>
        </S.Coupons>

        <S.ButtonsWrapper>
          <Button
            size="small"
            tertiary
            className="button__Remove"
            onClick={() => dispatch(removeCoupon())}
            loading={isRemoving}
            disabled={currentCoupon === null || isRemoving || isSaving}
          >
            Remove
          </Button>
          <Button
            size="small"
            secondary
            onClick={() => dispatch(onCancelClick())}
            className="button__Cancel"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() =>
              dispatch(updateOrderWithCoupon(currentCoupon || null))
            }
            data-testid="save-button"
            loading={isSaving}
            disabled={isSaving || isRemoving}
          >
            Save
          </Button>
        </S.ButtonsWrapper>
      </S.Container>
    </CenterOverlay>
  );
};

function AddCustomCouponButton(props: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <Button
      size="small"
      tertiary
      onClick={props.onClick}
      disabled={props.disabled}
    >
      Add
    </Button>
  );
}

export default React.memo(CouponsOverlay);
