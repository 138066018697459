import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { ApolloProvider } from '@apollo/client';

import Auth0ProviderWithHistory from '@auth/auth0-provider-with-history';
import GlobalStyle from '@styles/global';

import { getCurrentVersion } from '@utils';
import env from '@beam-australia/react-env';
import React from 'react';
import App from './App';
import { store } from './store';
import { apolloClient } from './graphql/apollo-client';
import initFaro from './config/faro';

const faroUrl = env('FARO_URL');
const faroTracing = env('FARO_TRACING');
const version = getCurrentVersion();
const environment = env('ENVIRONMENT');

initFaro(faroUrl, !!faroTracing, version, environment);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={<div>Something went wrong, your application crashed</div>}
    >
      <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <Router>
            <Auth0ProviderWithHistory>
              <GlobalStyle />
              <App />
            </Auth0ProviderWithHistory>
          </Router>
        </ReduxProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
