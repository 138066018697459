import React, { useRef, useState } from 'react';

import { handleEndOfTrainingTask } from '@utils/handleEndOfTask';
import { getConferenceSid } from '@modules/taskrouter/selectors';
import { useAppSelector } from '@store';
import * as S from './styles';
import { useConnector } from './useConnector';
import Confirmation from './Badges/Confirmation';

const LeaveTrainingModeButton = () => {
  const { selectors } = useConnector();
  const conferenceSid: string | null = useAppSelector(getConferenceSid);
  const { isClockingIn, isClockingOut } = selectors;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const buttonRef = useRef(null);

  const openConfirmation = () => setIsConfirmationOpen(true);
  const closeConfirmation = () => setIsConfirmationOpen(false);

  const loading: boolean = isClockingIn || isClockingOut;

  const handleLeaveMenu = () => {
    handleEndOfTrainingTask(conferenceSid);
  };

  return (
    <>
      <S.LeaveTrainingModeButton
        size="small"
        onClick={openConfirmation}
        loading={loading}
        ref={buttonRef}
      >
        Leave Training Mode
      </S.LeaveTrainingModeButton>
      <Confirmation
        action="leave training mode"
        anchor={buttonRef}
        open={isConfirmationOpen}
        onCancel={closeConfirmation}
        onConfirm={() => handleLeaveMenu()}
        isLoading={loading}
        isDisabled={loading}
        position="bottomLeft"
      />
    </>
  );
};

export default React.memo(LeaveTrainingModeButton);
