import { useState, useEffect } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { Typography, Button, Input } from '@kea-inc/parrot-ui';

import { AnnounceBadge, CenterOverlay } from '@components';
import {
  addTips,
  closeTipsOverlay,
  createOrderEvent,
} from '@modules/order/api';
import { useOrder } from '@hooks';
import { formatPrice } from '@utils/formatPrice';

import { useAppSelector } from '@store';
import { selectSubtotal } from '@modules/cart/selectors';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { Container, Header, Content, ButtonsWrapper } from './styles';

function TipsOverlay() {
  const { isTipsOverlayOpen, tips } = useOrder();
  const subtotal = useAppSelector(selectSubtotal);

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(0);
  const tip = percent ? subtotal * (percent / 100) : amount;

  useEffect(() => {
    setAmount(tips);
  }, [tips]);

  const handleAdd = () => {
    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    createOrderEvent('added_tip', {
      tip: formatPrice(tip),
    });
    addTips(tip);
    closeTipsOverlay();
  };

  const handlePercent = (perc: number) => {
    setAmount(0);
    setPercent(perc);
  };

  const handleAddAmount = (value: string) => {
    setPercent(0);
    let val = parseFloat(value.replace(/-/g, ''));
    val = val < 0 ? val * -1 : val;
    setAmount(Number.isNaN(val) ? 0 : val);
  };

  const handleRemove = () => {
    setAmount(0);
    addTips(0);
    closeTipsOverlay();
  };

  const howMuchMessage = 'How much tip would you like to give?';

  const handleAnnounceBadgeClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return isTipsOverlayOpen ? (
    <CenterOverlay>
      <Container>
        <Header>
          <Typography weight="heavy" variant="subheader">
            Add tip:
          </Typography>
          <AnnounceBadge
            message={howMuchMessage}
            onClick={handleAnnounceBadgeClick(howMuchMessage)}
          >
            How much?
          </AnnounceBadge>
        </Header>
        <Content>
          <Button
            size="small"
            secondary
            className={percent === 10 ? 'selected' : ''}
            onClick={() => handlePercent(10)}
          >{`10% - $${(subtotal * 0.1).toFixed(2)}`}</Button>
          <Button
            size="small"
            secondary
            data-test-id="fifteen-percent-button"
            className={percent === 15 ? 'selected' : ''}
            onClick={() => handlePercent(15)}
          >{`15% - $${(subtotal * 0.15).toFixed(2)}`}</Button>
          <Button
            size="small"
            secondary
            className={percent === 20 ? 'selected' : ''}
            onClick={() => handlePercent(20)}
          >{`20% - $${(subtotal * 0.2).toFixed(2)}`}</Button>
          <Button
            size="small"
            secondary
            className={percent === 25 ? 'selected' : ''}
            onClick={() => handlePercent(25)}
          >{`25% - $${(subtotal * 0.25).toFixed(2)}`}</Button>
          <Typography weight="heavy" color="primary_darker">
            or
          </Typography>
          <Typography weight="heavy" color="gray_dark">
            Custom Amount
          </Typography>
          <Input
            value={amount}
            leftIcon={MdAttachMoney}
            className="input_money"
            type="number"
            onChange={(e) => handleAddAmount(e.target.value)}
          />
        </Content>

        <ButtonsWrapper>
          <Button
            size="small"
            tertiary
            className="button__Remove"
            disabled={tips === 0}
            onClick={handleRemove}
          >
            Remove
          </Button>
          <Button
            size="small"
            secondary
            onClick={closeTipsOverlay}
            className="button__Cancel"
          >
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleAdd}
            disabled={tip < 0 || typeof tip === 'string'}
          >
            {`Add - ${formatPrice(tip)}`}
          </Button>
        </ButtonsWrapper>
      </Container>
    </CenterOverlay>
  ) : (
    <div />
  );
}

export default TipsOverlay;
