export const types = {
  STREAM_CONNECTING: '@payment/STREAM_CONNECTING',
  STREAM_CONNECTED: '@payment/STREAM_CONNECTED',
  STREAM_NOT_CONNECTED: '@payment/STREAM_NOT_CONNECTED',
  STREAM_CLOSED: '@payment/STREAM_CLOSED',

  PAYBOT_CONNECTING: '@payment/PAYBOT_CONNECTING',
  PAYBOT_CONNECTED: '@payment/PAYBOT_CONNECTED',
  PAYBOT_NOT_CONNECTED: '@payment/PAYBOT_NOT_CONNECTED',

  PAYBOT_TERMINATING: '@payment/PAYBOT_TERMINATING',
  PAYBOT_TERMINATED: '@payment/PAYBOT_TERMINATED',
  PAYBOT_NOT_TERMINATED: '@payment/PAYBOT_NOT_TERMINATED',

  SET_STREAM_CREDIT_CARD_FIELD: '@payment/SET_STREAM_CREDIT_CARD_FIELD',
  STREAM_CREDIT_CARD_ERROR: '@payment/STREAM_CREDIT_CARD_ERROR',

  PAYBOT_CHANGE_OVERLAY_VISIBILITY: '@payment/PAYBOT_CHANGE_OVERLAY_VISIBILITY',
  MANUAL_PAYMENT_CHANGE_OVERLAY_VISIBILITY:
    '@payment/MANUAL_PAYMENT_CHANGE_OVERLAY_VISIBILITY',

  SET_MANUAL_PAYMENT_FIELD: '@payment/SET_MANUAL_PAYMENT_FIELD',

  MANUAL_PAYMENT_PROCESSING: '@payment/MANUAL_PAYMENT_PROCESSING',
  MANUAL_PAYMENT_PROCESSED: '@payment/MANUAL_PAYMENT_PROCESSED',
  MANUAL_PAYMENT_NOT_PROCESSED: '@payment/MANUAL_PAYMENT_NOT_PROCESSED',

  BRAND_CASH_LIMIT_FETCHED: '@payment/BRAND_CASH_LIMIT_FETCHED',
  BRAND_CASH_LIMIT_NOT_FETCHED: '@payment/BRAND_CASH_LIMIT_NOT_FETCHED',

  RESET: '@payment/RESET',
  CREDIT_CARD_RESET: '@payment/CREDIT_CARD_RESET',
};
