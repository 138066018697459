import { AccountState } from './types';

export const initialAccountState: AccountState = {
  id: null,
  isPreviousOrderMenuOpen: false,
  previousOrder: null,
  previousOrderStatus: 'idle',
  accountDetails: {
    firstName: '',
    lastName: '',
    phone: '',
    deliveryAddressId: '',
  },
  pending: {
    isUpsertingAccount: false,
    isFetchingPreviousOrder: false,
    isFetchingAccount: false,
    isUpdatingAccount: false,
  },
  errors: {
    hasUpsertAccountError: false,
    hasFetchPreviousOrderError: false,
    hasFetchAccountError: false,
    hasUpdateAccountError: false,
  },
};
