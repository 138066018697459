import React, { useMemo } from 'react';
import GoogleMapReact from 'google-map-react';
import env from '@beam-australia/react-env';
import { Button, Input, Tooltip } from '@kea-inc/parrot-ui';

import { setAddressInfo } from '@modules/delivery/slice';
import { useDelivery } from '@hooks';
import { makeDeliveryPhrases } from '@utils/makeDeliveryPhrases';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { BsSearch } from 'react-icons/bs';
import {
  ConfirmAddressBadge,
  DeliveryInstructionsBadge,
} from '@components/DynamicBadges';
import { AnnounceBadge } from '@components';
import { getTaskrouterStore } from '@modules/taskrouter/selectors';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as S from './styles';

export const DeliveryDetails = React.memo(() => {
  const taskrouterStore = useAppSelector(getTaskrouterStore);
  const {
    delivery,
    resetDelivery,
    isAddressFetched,
    hasFetchingError,
    canSearchAddress,
    lat,
    lng,
    handleAddressSearch,
  } = useDelivery();
  const dispatch = useAppDispatch();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const handleReset = () => {
    resetDelivery();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && canSearchAddress) {
      handleAddressSearch();
    }
  };

  const deliveryPhrases = useMemo(
    () => makeDeliveryPhrases(delivery),
    [delivery],
  );

  const accessCodeMessage = 'Is an access code required?';

  const handleAnnounceClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return (
    <S.Container>
      <S.Title>Delivery</S.Title>
      <S.AddressWrapper>
        <article>
          {delivery.formattedAddress ? (
            <ConfirmAddressBadge />
          ) : (
            <AnnounceBadge
              message={deliveryPhrases.addressSearch}
              onClick={handleAnnounceClick(deliveryPhrases.addressSearch)}
            >
              Address Search
            </AnnounceBadge>
          )}
          <Input
            value={delivery.addressSearch}
            onChange={(e) =>
              dispatch(setAddressInfo({ addressSearch: e.target.value }))
            }
            onBlur={handleAddressSearch}
            onKeyDown={(e) => handleKeyPress(e)}
            placeholder={`Ex: ${taskrouterStore?.address}`}
            disabled={delivery.isFetchingAddress}
            required
            rightIcon={() => (
              <AddressInputIcon
                isFetching={delivery.isFetchingAddress}
                isAddressFetched={isAddressFetched}
                canSearch={canSearchAddress}
                resetDelivery={handleReset}
                handleAddressSearch={handleAddressSearch}
              />
            )}
          />
        </article>

        <article>
          <AnnounceBadge
            message={deliveryPhrases.zipcode}
            onClick={handleAnnounceClick(deliveryPhrases.zipcode)}
          >
            Zip Code
          </AnnounceBadge>
          <Input
            type="number"
            onChange={(e) =>
              dispatch(setAddressInfo({ zipcode: e.target.value }))
            }
            value={delivery.zipcode}
            placeholder={`Ex: ${taskrouterStore?.zipcode}`}
            disabled={!isAddressFetched && !hasFetchingError}
            required
          />
        </article>

        <section>
          <article>
            <AnnounceBadge
              message={deliveryPhrases.streetName}
              onClick={handleAnnounceClick(deliveryPhrases.streetName)}
            >
              Street Name
            </AnnounceBadge>
            <Input
              onChange={(e) =>
                dispatch(setAddressInfo({ streetName: e.target.value }))
              }
              value={delivery.streetName}
              placeholder={`Ex: ${taskrouterStore?.address
                ?.replace(/\d+/g, '')
                .trim()}`}
              disabled={!isAddressFetched && !hasFetchingError}
              required
            />
          </article>

          <article>
            <AnnounceBadge
              message={deliveryPhrases.streetNumber}
              onClick={handleAnnounceClick(deliveryPhrases.streetNumber)}
            >
              Number
            </AnnounceBadge>
            <Input
              onChange={(e) =>
                dispatch(setAddressInfo({ streetNumber: e.target.value }))
              }
              value={delivery.streetNumber}
              placeholder={`Ex: ${taskrouterStore?.address?.match(/\d+/g)}`}
              disabled={!isAddressFetched && !hasFetchingError}
              required
            />
          </article>

          <article>
            <AnnounceBadge
              message={deliveryPhrases.city}
              onClick={handleAnnounceClick(deliveryPhrases.city)}
            >
              City
            </AnnounceBadge>
            <Input
              onChange={(e) =>
                dispatch(setAddressInfo({ city: e.target.value }))
              }
              value={delivery.city}
              placeholder={`Ex: ${taskrouterStore?.city}`}
              disabled={!isAddressFetched && !hasFetchingError}
              required
            />
          </article>

          <article>
            <AnnounceBadge
              message={deliveryPhrases.state}
              onClick={handleAnnounceClick(deliveryPhrases.state)}
            >
              State
            </AnnounceBadge>
            <Input
              onChange={(e) =>
                dispatch(
                  setAddressInfo({ state: e.target.value.toLocaleUpperCase() }),
                )
              }
              value={delivery.state.toLocaleUpperCase()}
              placeholder={`Ex: ${taskrouterStore?.state}`}
              disabled={!isAddressFetched && !hasFetchingError}
              required
            />
          </article>
        </section>

        <article>
          <AnnounceBadge
            message={deliveryPhrases.addressLine2}
            onClick={handleAnnounceClick(deliveryPhrases.addressLine2)}
          >
            Address line 2
          </AnnounceBadge>
          <Input
            onChange={(e) =>
              dispatch(setAddressInfo({ addressLine2: e.target.value }))
            }
            value={delivery.addressLine2}
            placeholder="Ex: Apt, Suite, etc"
            disabled={!isAddressFetched && !hasFetchingError}
            required
          />
        </article>

        <article>
          <S.InstructionsBadges>
            <DeliveryInstructionsBadge />
            <AnnounceBadge
              variant="primary"
              message={accessCodeMessage}
              onClick={handleAnnounceClick(accessCodeMessage)}
            >
              Access code
            </AnnounceBadge>
          </S.InstructionsBadges>
          <Input
            onChange={(e) =>
              dispatch(setAddressInfo({ deliveryInstructions: e.target.value }))
            }
            value={delivery.deliveryInstructions}
            placeholder="Ex: Access code, special request, etc"
            disabled={!isAddressFetched && !hasFetchingError}
            required
          />
        </article>

        {isAddressFetched && delivery.lat && delivery.lng ? (
          <S.MapsContainer data-testid="search__map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: env('DELIVERY_SERVICE_GOOGLE_MAPS_API_KEY'),
              }}
              yesIWantToUseGoogleMapApiInternals
              center={{ lat, lng }}
              defaultZoom={17}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                clickableIcons: true,
                fullscreenControl: true,
                styles: [
                  {
                    featureType: 'poi.business',
                    elementType: 'labels.icon',
                    stylers: [
                      {
                        visibility: 'off',
                      },
                    ],
                  },
                ],
              }}
            >
              <S.Marker lat={lat} lng={lng} />

              {taskrouterStore?.latitude && taskrouterStore?.longitude && (
                <S.Marker
                  lat={Number(taskrouterStore.latitude)}
                  lng={Number(taskrouterStore.longitude)}
                  isStore
                />
              )}
            </GoogleMapReact>
          </S.MapsContainer>
        ) : null}
      </S.AddressWrapper>
    </S.Container>
  );
});

const AddressInputIcon = (props: {
  isFetching: boolean;
  isAddressFetched: boolean;
  canSearch: boolean;
  resetDelivery: () => void;
  handleAddressSearch: () => void;
}) => {
  if (props.isFetching) {
    return <S.Loading data-testid="search__loading" />;
  }

  if (props.isAddressFetched) {
    return (
      <Tooltip title="Erase address information" position="bottom">
        <Button
          onClick={props.resetDelivery}
          tertiary
          size="small"
          style={{ padding: '0 0.5rem' }}
        >
          X
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Search Address" position="bottom">
      <S.SearchIcon
        onClick={props.handleAddressSearch}
        disabled={!props.canSearch}
      >
        <BsSearch />
      </S.SearchIcon>
    </Tooltip>
  );
};
