import pluralize from 'pluralize';
import { formatList } from './formatList';

export function makeItemUnavailablePhrase(
  item: { spokenName: string; unavailableHandoffModes: string[] },
  currentHandoffMode: string,
  allHandoffModes: string[],
) {
  const { spokenName } = item;

  const isNameInPlural = pluralize.isPlural(spokenName);
  const noun = pluralize('is', isNameInPlural ? 2 : 1);

  const { unavailableHandoffModes } = item;

  if (unavailableHandoffModes?.includes(currentHandoffMode)) {
    const availableHandoffModes = allHandoffModes.filter(
      (mode: string) => !unavailableHandoffModes.includes(mode),
    );

    // TODO: This is a temporary fix for the case where the only available handoff method
    // is one that the store does not support
    if (availableHandoffModes.length === 0) {
      return `${spokenName} ${noun} currently unavailable.`;
    }

    return `${spokenName} ${noun} only available for ${formatList(
      availableHandoffModes,
    )}.`;
  }

  return `${spokenName} ${noun} currently unavailable.`;
}
