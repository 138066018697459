import styled from 'styled-components';
import { IoTimerOutline } from 'react-icons/io5';
import { theme } from '@kea-inc/parrot-ui';

import * as GlobalStyles from '@styles/loading';

type ContainerProps = {
  isReassigningTask: boolean;
};

export const Container = styled.section<ContainerProps>`
  height: 260px;
  width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.isReassigningTask ? 'flex-start' : 'space-between'};
`;

export const Header = styled.header`
  padding-left: 10px;
`;

export const Content = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 51px;

  .title {
    margin-top: 13px;
  }
`;

export const Waiting = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const WaitingIcon = styled(IoTimerOutline).attrs({
  size: 48,
})`
  color: ${theme.colors.success};
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${theme.colors.gray_light};
  padding-top: 16px;

  button {
    margin: 0;
  }
`;

export const { Loading, LoadingContainer } = GlobalStyles;
