import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
`;

export const SpecialInstructionsWrapper = styled.section`
  padding: 20px 16px;
`;

export const RecipientWrapper = styled.section`
  padding: 0 16px 20px;
`;
