import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@kea-inc/parrot-ui';

const Greetings = ({ greetings, headline }) => (
  <div>
    <Typography variant="headline" color="gray_darker">
      {greetings}
    </Typography>
    <Typography variant="display" weight="heavy">
      {headline}
    </Typography>
  </div>
);

Greetings.propTypes = {
  greetings: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default React.memo(Greetings);
