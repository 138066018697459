import { useEffect, useMemo, useState } from 'react';
import {
  Option,
  Select,
  Typography,
  TextArea,
  Button,
  theme,
  Input,
} from '@kea-inc/parrot-ui';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { FaArrowRightLong } from 'react-icons/fa6';

import * as surveyAPI from '@modules/survey/api';
import { setWorkerStatus } from '@modules/taskrouter/actions';
import {
  WorkerStatus as StatusType,
  WorkerStatus,
} from '@modules/taskrouter/types';

import { useAppDispatch, useAppSelector } from '@store';
import { CenterOverlay } from '@components';
import { useSurvey } from '@hooks';

import { MdContentCopy } from 'react-icons/md';
import { copyToClipboard } from '@utils/copyToClipboard';
import { getAgentStatusesList } from '@utils';
import {
  openStatusOverlay,
  resetWorkerStatus,
} from '@modules/taskrouter/slice';
import { prettifyWorkerStatus } from '@components/Header/utils';
import { getUserFeatureFlags } from '@modules/featureFlags/selectors';
import { successOptions, hangupOptions, forwardedOptions } from './utils';
import * as S from './styles';
import { Timeout } from './Timeout';

export function SurveyOverlay() {
  const {
    primaryDisposition,
    secondaryDisposition,
    comments,
    wasSubmitted,
    wasForwarded,
    isPending,
    orderId,
  } = useSurvey();
  const [errors, setErrors] = useState({
    primaryDisposition: false,
    secondaryDisposition: false,
  });
  const [hasSecondary, setHasSecondary] = useState({});
  const [isOther, setIsOther] = useState(false);
  const [orderIdCopied, setOrderIdCopied] = useState(false);
  const dispatch = useAppDispatch();
  const agentStatuses = getAgentStatusesList();
  const userFeatureFlags = useAppSelector(getUserFeatureFlags);
  const { useAgentStatuses } = userFeatureFlags;

  const handleSubmitSurvey = (status: WorkerStatus = 'Idle') => {
    setErrors({
      primaryDisposition: primaryDisposition === null,
      secondaryDisposition: hasSecondary
        ? secondaryDisposition === null
        : false,
    });

    const hasErrors = Object.values(errors).some(Boolean);
    if (hasErrors) return;

    surveyAPI.submitSurvey().then(() => {
      dispatch(resetWorkerStatus());
      dispatch(setWorkerStatus(status));
      if (status !== 'Idle') {
        dispatch(openStatusOverlay());
      }
    });
  };

  const handleCopyOrderId = () => {
    copyToClipboard(orderId);
    setOrderIdCopied(true);
    setTimeout(() => setOrderIdCopied(false), 2000);
  };

  const { options, defaultOption } = useMemo(() => {
    if (wasSubmitted)
      return { options: successOptions, defaultOption: 'No issues' };
    if (wasForwarded)
      return { options: forwardedOptions, defaultOption: 'Other' };
    return { options: hangupOptions, defaultOption: 'Other' };
  }, [wasSubmitted, wasForwarded]);

  const headerText = useMemo(() => {
    if (wasSubmitted) return 'Good job placing the order!';
    if (wasForwarded) return 'Call forwarded to the store';
    return 'Oh no, what happened?';
  }, [wasSubmitted, wasForwarded]);

  const labelText = useMemo(() => {
    if (wasSubmitted) return 'Did you have any issues?';
    if (wasForwarded) return 'Why did you forward the call?';
    return 'Why the order was not placed?';
  }, [wasSubmitted, wasForwarded]);

  useEffect(() => {
    surveyAPI.setPrimaryDisposition(Object.keys(options)[0]);
  }, [options]);

  useEffect(() => {
    const optionsIsNotEmpty =
      options[primaryDisposition as keyof typeof options]?.length > 0;
    setHasSecondary(optionsIsNotEmpty);
    setIsOther(primaryDisposition === 'Other');

    if (optionsIsNotEmpty) {
      surveyAPI.setSecondaryDisposition(
        options[primaryDisposition as keyof typeof options]?.[0],
      );
    } else {
      surveyAPI.setSecondaryDisposition(null);
    }
  }, [primaryDisposition]);

  return (
    <CenterOverlay>
      <S.Header>
        {wasSubmitted ? (
          <AiFillCheckCircle color={theme.colors.success} />
        ) : (
          <AiFillWarning color={theme.colors.warning} />
        )}
        <Typography variant="headline" weight="heavy">
          {headerText}
        </Typography>
      </S.Header>

      <S.Step>
        <Select
          onChange={surveyAPI.setPrimaryDisposition}
          value={defaultOption}
          status={errors.primaryDisposition ? 'error' : 'none'}
          label={labelText}
          required
        >
          {Object.keys(options).map((issue) => (
            <Option key={issue} value={issue}>
              {issue}
            </Option>
          ))}
        </Select>
      </S.Step>
      {hasSecondary && (
        <S.Step>
          <Select
            value={secondaryDisposition}
            label={`Which kind of ${primaryDisposition}?`}
            onChange={surveyAPI.setSecondaryDisposition}
            status={errors.secondaryDisposition ? 'error' : 'none'}
            required
          >
            {options[primaryDisposition as keyof typeof options]?.map(
              (subIssue) => (
                <Option key={subIssue} value={subIssue}>
                  {subIssue}
                </Option>
              ),
            )}
          </Select>
        </S.Step>
      )}

      {isOther && (
        <S.Step>
          <TextArea
            value={comments}
            onChange={(e) => surveyAPI.setComments(e.target.value)}
            label="Any comments?"
            rows={3}
            maxRows={3}
          />
        </S.Step>
      )}

      <S.Step className="order-id" onClick={handleCopyOrderId}>
        <Input
          label="Order ID (click to copy)"
          value={orderIdCopied ? 'Copied to clipboard!' : orderId}
          rightIcon={MdContentCopy}
          onClick={handleCopyOrderId}
          readOnly
          required
        />
      </S.Step>
      {useAgentStatuses && (
        <S.Footer>
          <S.StatusButtons>
            <Typography weight="heavy" variant="caption" color="black_light">
              Change Status to:
            </Typography>
            {Object.keys(agentStatuses)
              .filter((key) => agentStatuses[key as StatusType]?.surveyButton)
              .map((key) => (
                <S.StatusButton
                  key={key}
                  size="small"
                  color={agentStatuses[key as StatusType]?.color}
                  onClick={() => handleSubmitSurvey(key as StatusType)}
                  LeftIcon={agentStatuses[key as StatusType]?.icon}
                >
                  {prettifyWorkerStatus(key)}
                </S.StatusButton>
              ))}
          </S.StatusButtons>
          <S.FooterButtons>
            <Typography variant="caption" color="black_light">
              Back to call queue: <Timeout />s
            </Typography>
            <Button
              loading={isPending}
              onClick={() => handleSubmitSurvey()}
              LeftIcon={FaArrowRightLong}
            >
              Call Queue
            </Button>
          </S.FooterButtons>
        </S.Footer>
      )}
      {!useAgentStatuses && (
        <S.Footer>
          <Typography color="black_light">
            <Timeout />s
          </Typography>
          <Button loading={isPending} onClick={() => handleSubmitSurvey()}>
            Confirm
          </Button>
        </S.Footer>
      )}
    </CenterOverlay>
  );
}
