import * as notifications from '@modules/notifications/api';

export function streamConnectionError(error) {
  notifications.error({
    title: 'Cannot connect to payment stream',
    message: 'Unfortunately, the online payment stream could not be connected.',
    error,
  });
}

export function paybotConnectionError(error) {
  notifications.error({
    title: 'Cannot connect to paybot',
    message: 'Unfortunately, the paybot could not be connected.',
    error,
  });
}

export function paybotTerminateError(error) {
  notifications.error({
    title: 'Cannot terminate paybot',
    message: 'Unfortunately, the paybot could not be terminated.',
    error,
  });
}

export function manualPaymentError(error) {
  notifications.error({
    title: 'Cannot proccess card',
    message: 'Unfortunately, the card could not be processed.',
    error,
  });
}
