export const successOptions = {
  'No issues': [],
  'Tech Issue': ['VBX', 'Audio', 'Paybot'],
  'Site Limitation': ['Lite/Extra Toppings', 'Item not available'],
  Other: [],
};

export const forwardedOptions = {
  'Allergy/dietary restriction': [],
  'Item not on menu': [],
  'CX here for Curbside': [],
  'Employee/interviewee': [],
  'Asked to speak to Manager': [],
  'Question I could not answer': [],
  'Existing Order': ['Complaint', 'Modification', 'Cancellation'],
  'Tech Issue': ['VBX', 'Audio', 'Paybot'],
  'Site Limitation': ['Lite/Extra Toppings', 'Item not available'],
  Other: [],
};

export const hangupOptions = {
  'Caller Not Here': [],
  'Tech Issue': ['VBX', 'Audio', 'Paybot'],
  'Site Limitation': ['Lite/Extra Toppings', 'Item not available'],
  Other: [],
};
