import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  background-color: white;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;

  > img {
    height: auto;
    width: ${(props) => props.size}px;
  }
`;
