type GetModifierSelectionModeParams = {
  defaultOptionsToMaxQuantity?: boolean | null;
  addedOptions: { id: string; quantity: number; parentId: string }[];
  modifier: {
    id: string;
    required: boolean;
    maxOptions: number | null;
    maxQuantity: number | null;
  };
};

export function getModifierSelectionMode({
  modifier,
  addedOptions,
  defaultOptionsToMaxQuantity,
}: GetModifierSelectionModeParams) {
  const modifierAddedOptions = addedOptions.filter(
    (option) => option.parentId === modifier.id,
  );

  const uniqueOptionsQty = modifierAddedOptions.length ?? 0;
  const totalOptionsQty = modifierAddedOptions.reduce(
    (acc, option) => acc + option?.quantity,
    0,
  );

  const { maxOptions, maxQuantity } = modifier;

  // The required modifier with no maxOptions and no maxQuantity, should default to switch
  if (modifier.required && !maxOptions && !maxQuantity) {
    if (uniqueOptionsQty === 1) {
      // Required modifier with one option selected
      return 'switch';
    }
  }

  if (modifier.required && maxOptions && uniqueOptionsQty >= maxOptions) {
    // Required modifier with max options selected
    return 'switch';
  }

  if (uniqueOptionsQty === 0 || (!maxOptions && !maxQuantity)) {
    // No options selected or neither max quantity nor max options
    return 'add';
  }

  if (!maxQuantity && maxOptions && uniqueOptionsQty < maxOptions) {
    // There is no max quantity and max options is not reached
    return 'add';
  }

  if (!maxOptions && maxQuantity && totalOptionsQty < maxQuantity) {
    // There is no max options and max quantity is not reached
    return 'add';
  }

  if (
    maxOptions &&
    uniqueOptionsQty < maxOptions &&
    maxQuantity &&
    totalOptionsQty < maxQuantity
  ) {
    // Max options and max quantity is not reached
    return 'add';
  }

  if (
    defaultOptionsToMaxQuantity &&
    maxQuantity &&
    totalOptionsQty >= maxQuantity &&
    maxOptions &&
    uniqueOptionsQty < maxOptions
  ) {
    // Max quantity is reached but max options is not reached
    return 'add';
  }

  if (
    maxOptions &&
    uniqueOptionsQty >= maxOptions &&
    maxQuantity &&
    totalOptionsQty < maxQuantity
  ) {
    // Max options is reached but max quantity is not reached
    return 'add';
  }

  if (
    !defaultOptionsToMaxQuantity &&
    maxQuantity &&
    totalOptionsQty >= maxQuantity &&
    maxOptions &&
    uniqueOptionsQty < maxOptions
  ) {
    // Max quantity is reached but max options is not reached and defaultOptionsToMaxQuantity is false
    return 'switch';
  }

  if (
    maxOptions &&
    uniqueOptionsQty >= maxOptions &&
    maxQuantity &&
    totalOptionsQty >= maxQuantity
  ) {
    // Max options and max quantity is reached
    return 'switch';
  }
}
