import { useRef, useCallback, useEffect, useState } from 'react';
import { Typography } from '@kea-inc/parrot-ui';
import { useAppDispatch, useAppSelector } from '@store';
import { CenterOverlay } from '@components';
import { handleReassignTask } from '@utils/handleReassignTask';
import { takeAction } from '@modules/taskrouter/actions';
import { startForNewOrder } from '@modules/survey/api';

import { getOrderHandoffMode } from '@modules/order/selectors';
import { announce } from '@modules/device/device-actions';
import {
  getAgentTookAction,
  getHasOngoingTask,
  getIsReassigningTask,
  getConferenceSid,
} from '@modules/taskrouter/selectors';
import { selectHasPreexistingCart } from '@modules/cart/selectors';

import { TimeOut } from './TimeOut';
import * as S from './styles';
import { getInitialVbx } from './get-initial-vbx';

export function TtFAOverlay() {
  const agentTookAction = useAppSelector(getAgentTookAction);
  const isReassigningTask = useAppSelector(getIsReassigningTask);
  const hasOngoingTask = useAppSelector(getHasOngoingTask);
  const conferenceSid = useAppSelector(getConferenceSid);
  const hasPreexistingCart = useAppSelector(selectHasPreexistingCart);

  const [timeToFirstAction, setTimeToFirstAction] = useState(Date.now());
  const [announceFirstVBX, setAnnounceFirstVBX] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const handleOnFinish = useCallback(async () => {
    handleReassignTask(true);
  }, []);

  useEffect(() => {
    if (!agentTookAction && hasOngoingTask) {
      setTimeToFirstAction(Date.now());

      if (containerRef.current) {
        containerRef.current.focus();
      }
    }
  }, [agentTookAction, hasOngoingTask]);

  useEffect(() => {
    if (announceFirstVBX && conferenceSid) {
      const handoffMode = getOrderHandoffMode();

      const initialVbx = getInitialVbx({
        handoffMode,
        hasPreexistingCart,
      });

      dispatch(announce({ text: initialVbx }));

      setAnnounceFirstVBX(false);
    }
  }, [announceFirstVBX, conferenceSid]);

  const handleTakeAction = () => {
    if (agentTookAction || isReassigningTask) {
      return;
    }

    setAnnounceFirstVBX(true);
    const handoffMode = getOrderHandoffMode();

    const initialVbx = getInitialVbx({
      handoffMode,
      hasPreexistingCart,
    });

    dispatch(announce({ text: initialVbx }));

    dispatch(takeAction(Date.now() - timeToFirstAction));
    startForNewOrder();
  };

  return !agentTookAction && hasOngoingTask ? (
    <CenterOverlay onClick={handleTakeAction}>
      <S.Container
        isReassigningTask={isReassigningTask}
        onClick={handleTakeAction}
        onKeyUp={handleTakeAction}
        ref={containerRef}
      >
        <S.Content>
          {!isReassigningTask ? (
            <S.Waiting>
              <S.WaitingIcon />
              <Typography
                className="title"
                variant="title"
                weight="heavy"
                color="primary"
              >
                Click anywhere to begin
              </Typography>
              <TimeOut onFinish={handleOnFinish} />
            </S.Waiting>
          ) : (
            <S.LoadingContainer>
              <S.Loading />
            </S.LoadingContainer>
          )}
        </S.Content>
      </S.Container>
    </CenterOverlay>
  ) : (
    <></>
  );
}
