import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '@store';
import { FeatureFlagsState } from './slice';

export const getUserFeatureFlags = (state: RootState) =>
  state.featureFlags.user;

export const getStoreFeatureFlags = (state: RootState) =>
  state.featureFlags.store;

export const getBrandFeatureFlags = (state: RootState) =>
  state.featureFlags.brand;

export const isFetching = createDraftSafeSelector(
  (state: RootState) => state.featureFlags.fetching,
  (fetching) => Object.values(fetching).some((ft) => ft),
);

export const isActionFetching = (ucName: keyof FeatureFlagsState['fetching']) =>
  createDraftSafeSelector(
    (state: RootState) => state.featureFlags.fetching,
    (fetching) => fetching[ucName],
  );
