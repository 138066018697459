import { useContext } from 'react';
import { useHistory as useRRDHistory } from 'react-router-dom';

import { HistoryContext } from '@providers/HistoryProvider';

const useHistory = () => {
  const history = useRRDHistory();
  const { actions } = useContext(HistoryContext);
  return {
    ...history,
    ...actions,
  };
};

export { useHistory };
