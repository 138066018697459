/* eslint-disable max-classes-per-file */
/**
 * This code is only used when the env var `SKIP_PREFLIGHT` is set to true.
 * This is only used in integration tests.
 */

import { EventEmitter } from 'events';

export class FakeTwilioDevice extends EventEmitter {
  state = 'unregistered';

  updateToken() {}

  destroy() {}

  register() {
    this.emit('registered');
    this.state = 'registered';
  }

  unregister() {}

  triggerCallConnected() {
    const call = {
      accept: () => {},
      on() {},
    };
    this.emit('incoming', call);
  }
}

export const fakeReport = {
  callSid: 'CA1e6709edc927f8476a99d8d29b7cb4bb',
  edge: 'sao-paulo',
  iceCandidateStats: [],
  networkTiming: {
    signaling: {
      start: 1669048706874,
      end: 1669048707368,
      duration: 494,
    },
    dtls: {
      start: 1669048707463,
      end: 1669048707530,
      duration: 67,
    },
    ice: {
      start: 1669048707367,
      end: 1669048707463,
      duration: 96,
    },
    peerConnection: {
      start: 1669048707374,
      end: 1669048707530,
      duration: 156,
    },
  },
  samples: [
    {
      audioInputLevel: 6493,
      audioOutputLevel: 0,
      bytesReceived: 0,
      bytesSent: 6720,
      codecName: 'PCMU',
      jitter: 0,
      mos: null,
      packetsLost: 0,
      packetsLostFraction: 0,
      packetsReceived: 0,
      packetsSent: 42,
      rtt: 43,
      timestamp: 1669048708371.228,
      totals: {
        bytesReceived: 0,
        bytesSent: 6720,
        packetsLost: 0,
        packetsLostFraction: 100,
        packetsReceived: 0,
        packetsSent: 42,
      },
    },
  ],
  selectedEdge: 'roaming',
  stats: {
    jitter: {
      average: 8,
      max: 12,
      min: 4,
    },
    mos: {
      average: 4.365,
      max: 4.413940029014851,
      min: 4.225813298889962,
    },
    rtt: {
      average: 55.5,
      max: 117,
      min: 35,
    },
  },
  testTiming: {
    start: 1669048706517,
    end: 1669048713087,
    duration: 6570,
  },
  totals: {
    bytesReceived: 31680,
    bytesSent: 38720,
    packetsLost: 1,
    packetsLostFraction: 0.5025125628140703,
    packetsReceived: 198,
    packetsSent: 242,
  },
  warnings: [],
  selectedIceCandidatePairStats: {
    localCandidate: {
      id: 'ICF3k6KOQ',
      timestamp: 1669048708371.228,
      type: 'local-candidate',
      transportId: 'T01',
      isRemote: false,
      networkType: 'vpn',
      ip: '8.36.217.104',
      address: '8.36.217.104',
      port: 59396,
      protocol: 'udp',
      candidateType: 'prflx',
      priority: 1853628159,
    },
    remoteCandidate: {
      id: 'IAHj9ao+v',
      timestamp: 1669048708371.228,
      type: 'remote-candidate',
      transportId: 'T01',
      isRemote: true,
      ip: '18.228.249.173',
      address: '18.228.249.173',
      port: 10790,
      protocol: 'udp',
      candidateType: 'host',
      priority: 2130706431,
    },
  },
  isTurnRequired: false,
  callQuality: 'excellent',
};

declare global {
  interface Window {
    callbackMap: Map<string, (data: any) => void>;
  }
}

export class FakeTwilioWorker {
  constructor() {
    window.callbackMap = new Map();
  }

  updateToken() {
    // Do nothing
  }

  on(event: string, callback: (data: any) => void) {
    window.callbackMap.set(event, callback);

    if (event === 'ready') {
      setTimeout(() => {
        const worker = {
          statistics: {},
          available: false,
          workspaceSid: 'fake-workspace-sid',
          workspace: {
            activities: {},
            workflows: {},
            taskqueues: {
              statistics: {},
              realtimeStats: {},
              cumulativeStats: {},
            },
            workers: {
              statistics: {},
              realtimeStats: {},
              cumulativeStats: {},
            },
            tasks: {},
            statistics: {},
            realtimeStats: {},
            cumulativeStats: {},
          },
          dateUpdated: '2022-09-27T18:14:55.000Z',
          activitySid: 'WA7fe2d36686c10c655db2a9ea9140b8d5',
          sid: 'fake-sid',
          friendlyName: 'john.the.agent@kea.cloud',
          accountSid: 'AC0be432075a082a63e6e4bf4f33fabe5a',
          dateStatusChanged: '2022-11-10T21:31:47.000Z',
          dateCreated: '2022-07-06T20:07:52.000Z',
          attributes: {
            username: 'john.the.agent',
            queues: ['test_store_no_1'],
            role_weight: 100,
          },
          activityName: 'Offline',
        };
        callback(worker);
      });
    }
  }
}
