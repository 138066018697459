import { useSelector } from 'react-redux';

export const useClock = () => {
  const errors = useSelector(({ clock }) => clock.errors);
  const pending = useSelector(({ clock }) => clock.pending);
  const status = useSelector(({ clock }) => clock.status);
  const id = useSelector(({ clock }) => clock.id);
  const datetime = useSelector(({ clock }) => clock.datetime);
  return { errors, pending, status, id, datetime };
};
