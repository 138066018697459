import { Option } from '@modules/menu/types';

export function shouldHideOptionInTheCart(option: Option, quantity: number) {
  // THIS SHOULD BE IN THE BACKEND
  if (option.isHidden) {
    return true;
  }

  if (option.isDefault && quantity > 0 && !option.shouldRepeatDefaultOptions) {
    return true;
  }

  if (quantity === 0 && !option.shouldRepeatOptionRemoval && option.isDefault) {
    // As we don't want to repeat the option, we should hide it
    return true;
  }

  return false;
}
