import styled, { keyframes } from 'styled-components';
import { Card, theme, Typography } from '@kea-inc/parrot-ui';

import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.div`
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-bottom: 1px solid ${theme.colors.gray_light};
    padding: 0 16px 14px 16px;

    .badge__List {
      margin-left: auto;
    }
  }
`;

export const Categories = styled.section`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-between;
  padding: 0 16px 16px 16px;
`;

type CategoryProps = {
  onMouseEnter: () => void;
};

export const Category = styled(Card).attrs<CategoryProps>({
  padded: true,
})<{ size: number; onClick: (id: string) => void }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  max-height: 56px;
  transition: 0.1s all ease-in-out;
  width: 49%;
`;

export const CategoryName = styled(Typography).attrs({
  variant: 'subheader',
  weight: 'heavy',
  color: 'primary_darker',
})<{ hasItemsAvailable: boolean }>`
  text-decoration: ${(props) =>
    props.hasItemsAvailable ? 'none' : 'line-through'};
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// TODO: move this to be a shared component
export const Loading = styled(ImSpinner2).attrs({
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
