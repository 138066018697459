import { createAsyncThunk } from '@reduxjs/toolkit';
import camelcaseKeys from 'camelcase-keys';

import { dataService, orderService } from '@services';
import logger from '@logger';
import { RootState } from '@store';
import * as notificationsApi from '@modules/notifications/api';
import { setCouponValidationStatus } from '@modules/cart/actions';
import { announce } from '@modules/device/device-actions';

import {
  Coupon,
  RemoveCouponFromOrderResponse,
  UpdateOrderWithCouponResponse,
} from './types';

export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async (params: { brandId?: string; storeId?: string }) => {
    const { brandId, storeId } = params;

    if (!brandId && !storeId) {
      return [];
    }

    try {
      const brandCouponUrl = `/brands/${brandId}/promos`;
      const storeCouponUrl = `/stores/${storeId}/promos`;

      return Promise.all([
        dataService.get<Coupon[]>(brandCouponUrl),
        dataService.get<Coupon[]>(storeCouponUrl, { version: 'v3' }),
      ]).then(([brandResponse, storeResponse]) => [
        ...camelcaseKeys(brandResponse.data),
        ...camelcaseKeys(storeResponse.data),
      ]);
    } catch (error) {
      logger.error(
        `Failed to fetch coupons. brand: ${brandId}. store: ${storeId}`,
        error,
      );
    }
  },
);

export const updateOrderWithCoupon = createAsyncThunk(
  'coupons/updateOrderWithCoupon',
  async (coupon: Coupon | null, { dispatch, getState }) => {
    const orderId = (getState() as RootState).order.id;
    const { savedCoupon } = (getState() as RootState).coupons;

    try {
      if (!coupon) {
        dispatch(removeCouponFromOrder());
        return;
      }

      if (savedCoupon?.code === coupon.code) {
        return;
      }

      const url = `/orders/${orderId}/coupons`;
      const response = await orderService.post<UpdateOrderWithCouponResponse>(
        url,
        {
          orderCoupons: [{ code: coupon.code }],
        },
      );

      dispatch(setCouponValidationStatus(response.data.validation));
      dispatch(
        announce({
          text: `Okay, I've selected the ${coupon.name} deal. ${coupon.prompt}`,
        }),
      );

      return camelcaseKeys(response.data);
    } catch (error) {
      logger.error(
        `Failed to update order (${orderId}) with coupon (${coupon?.code})`,
        error,
      );
      notificationsApi.error({
        title: 'Unable to add coupon',
        message: 'Coupon not added to order.',
      });
    }
  },
);

export const removeCouponFromOrder = createAsyncThunk(
  'coupons/removeCouponFromOrder',
  async (_, { dispatch, getState }) => {
    const orderId = (getState() as RootState).order.id;

    try {
      const url = `/orders/${orderId}/coupons`;
      const response = await orderService.delete<RemoveCouponFromOrderResponse>(
        url,
      );

      dispatch(setCouponValidationStatus(response.data.validation));
      dispatch(announce({ text: 'Okay, no deal selected.' }));

      return camelcaseKeys(response.data);
    } catch (error) {
      logger.error(`Failed to remove coupon from order (${orderId})`, error);
    }
  },
);
