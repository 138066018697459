import React from 'react';

import { HoverController } from '../HoverController';
import { OptionInfo } from '../OptionInfo';
import * as S from './styles';

const Track = (props: any, { index }: any) => (
  <S.StyledTrack {...props} index={index} />
);

const Thumb = (props: any, { valueNow }: any) => (
  <S.StyledThumb {...props}>{valueNow}</S.StyledThumb>
);

type QuantityOptionProps = {
  maxQuantityPerOption: number;
  minQuantityPerOption: number;
  quantityInterval: number;

  name: string;
  cost: number;
  quantity: number;

  onQuantityChanged: (newQuantity: number) => void;
  onDeselect: () => void;
};

export function QuantityOption({
  name,
  cost,
  quantity,
  quantityInterval,
  maxQuantityPerOption,
  minQuantityPerOption,
  onQuantityChanged,
  onDeselect,
}: QuantityOptionProps) {
  const canDecreaseQuantity =
    quantity - quantityInterval >= minQuantityPerOption;

  const canIncreaseQuantity = maxQuantityPerOption
    ? quantity + quantityInterval <= maxQuantityPerOption
    : true;

  const handleIncrease = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (canIncreaseQuantity) {
      onQuantityChanged(quantity + quantityInterval);
    }
  };

  const handleDecrease = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (canDecreaseQuantity) {
      onQuantityChanged(quantity - quantityInterval);
    }
  };

  const handleOnSliderChange = (newQuantity: number) => {
    const isIncreasing = newQuantity > quantity;
    const isDecreasing = newQuantity < quantity;

    if (isIncreasing && canIncreaseQuantity) {
      onQuantityChanged(newQuantity);
    }

    if (isDecreasing && canDecreaseQuantity) {
      onQuantityChanged(newQuantity);
    }
  };

  return (
    <S.QuantityOption onClick={onDeselect}>
      <HoverController text="REMOVE" />
      <OptionInfo name={name} cost={cost} isSelected collapseName />

      <S.SliderWrapper onClick={(e) => e.stopPropagation()}>
        <S.SubtractSliderButton
          data-testid="decrease-quantity-button"
          type="button"
          onClick={handleDecrease}
          disabled={!canDecreaseQuantity}
        />
        <S.Slider
          min={minQuantityPerOption}
          max={maxQuantityPerOption}
          value={quantity}
          step={quantityInterval}
          renderTrack={Track}
          renderThumb={Thumb}
          onChange={(value) => handleOnSliderChange(value as number)}
        />
        <S.AddSliderButton
          data-testid="increase-quantity-button"
          type="button"
          onClick={handleIncrease}
          disabled={!canIncreaseQuantity}
        />
      </S.SliderWrapper>
    </S.QuantityOption>
  );
}
