import { AnnounceBadge } from '@components';
import { useStoreHours } from '@hooks';
import { Typography } from '@kea-inc/parrot-ui';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as S from './styles';

export function StoreHours() {
  const { storeHours } = useStoreHours();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const handleAnnounceHours = (day: string) => {
    const { opens, closes } = storeHours[day];

    return `We open at ${opens} and close at ${closes} on ${day}`;
  };

  const handleHoursClick = (message: string) => () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message,
      },
    });
  };

  return (
    <S.StoreHours>
      <Typography variant="small" weight="light">
        Store hours
      </Typography>
      {Object.keys(storeHours).map((day) => (
        <AnnounceBadge
          key={storeHours[day].id}
          className="storeHours__badge"
          message={handleAnnounceHours(day)}
          onClick={handleHoursClick(handleAnnounceHours(day))}
        >
          {day}: {storeHours[day].opens} - {storeHours[day].closes}
        </AnnounceBadge>
      ))}
    </S.StoreHours>
  );
}
