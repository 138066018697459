import { useMemo } from 'react';

import { formatPrice } from '@utils';

import * as S from './styles';

type OptionInfoProps = {
  name: string;
  cost?: number;
  isSelected?: boolean;
  collapseName?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
};

export function OptionInfo({
  name,
  cost,
  isSelected,
  collapseName,
  onClick,
  isLoading,
}: OptionInfoProps) {
  const formattedPrice = useMemo(() => formatPrice(cost), [cost]);

  return (
    <S.OptionInfo onClick={onClick}>
      {isLoading && <S.LoadingSpinner data-testid="loading-icon" />}
      {isSelected && !isLoading && <S.Check data-testid="check-icon" />}

      <S.OptionName
        useFullWidth={!cost}
        isSelected={isSelected}
        collapseName={collapseName}
      >
        {name}
      </S.OptionName>

      {!!cost && (
        <S.OptionPrice variant="caption">{formattedPrice}</S.OptionPrice>
      )}
    </S.OptionInfo>
  );
}
