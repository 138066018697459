import styled from 'styled-components';

import { IconButton as parroIconButton } from '@kea-inc/parrot-ui';

export const IconButton = styled(parroIconButton)`
  margin-left: 1rem;
  vertical-align: sub;
  svg {
    color: #f03738;
    font-size: 16px;
    margin-top: 0.2rem;
  }
`;
