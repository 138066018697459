import { store } from '@store';

import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { DateTime } from 'luxon';
import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import { PAYMENT_TYPES } from './state';

export function order() {
  return store.getState().order;
}

export function getDetails() {
  return order().details;
}

export function getIsOrderThrottling() {
  return order().isThrottling;
}

export function getWasAutoScheduled() {
  return getDetails().wasAutoScheduled;
}

export function getOrderId() {
  return order().id;
}

export function getChecklist() {
  const { checklist } = order();
  return checklist;
}

export function getValidatedBasket() {
  const { validatedBasket } = order();
  return validatedBasket;
}

export function getPaymentType() {
  const details = getDetails();
  return details.paymentType;
}

export function isPayingByCard() {
  return getPaymentType() === PAYMENT_TYPES.CARD;
}

export function getValidateAttempts() {
  const { validateAttempts } = order();
  return validateAttempts;
}

export function isLastValidation(attempt) {
  return getValidateAttempts() === attempt;
}

export function getDatabaseTranslatedDetails() {
  const { isASAP, deliveryTime, handOffMode, paymentType } = getDetails();
  const { useOrderService } = getStoreFeatureFlags(store.getState());

  if (useOrderService) {
    const timezone = deprecatedGetStoreTimezone();
    const orderWithTZ = DateTime.fromMillis(deliveryTime, { zone: timezone });

    return {
      futureOrderTime: isASAP ? null : orderWithTZ.toISO(),
      handoffMode: handOffMode?.replace(/-/g, ''),
      paymentType: paymentType?.toLowerCase(),
    };
  }
  return {
    order_time: isASAP ? 'ASAP' : 'Future',
    future_order_time: !isASAP
      ? new Date(deliveryTime).toISOString()
      : new Date(),
    order_type: handOffMode,
    payment_type: paymentType,
  };
}

export function getTotal() {
  const { validatedBasket, tips } = order();

  if (!validatedBasket) return 0;

  return validatedBasket.total + tips;
}

export function getOrderHandoffMode() {
  const { details } = order();

  if (!details || !details.handOffMode) {
    return null;
  }

  return details.handOffMode;
}
