import { Typography } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { TableNumberBadge } from '@components/DynamicBadges';

import { ChecklistItem } from './ChecklistItem';

export const TableNumberFilled = () => (
  <ChecklistItem field={ORDER_CHECKLIST.TABLE_NUMBER_FILLED}>
    <TableNumberBadge />
    <Typography weight="heavy">&nbsp; filled</Typography>
  </ChecklistItem>
);
