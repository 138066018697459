import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';

const actions = {
  [types.LOGGED_IN]: (state, action) =>
    produce(state, (auth) => {
      const { payload } = action;
      auth.user = payload.user;
      auth.token = payload.token;
      auth.roles = payload.roles;
      auth.logged = true;
      return auth;
    }),

  [types.SET_FEATURE_FLAGS]: (state, action) =>
    produce(state, (account) => {
      account.featureFlags = action.payload;
      return account;
    }),

  [types.RESET]: (state) => produce(state, () => INITIAL_STATE),
};

export const authReducer = createReducer(INITIAL_STATE, actions);
