import React from 'react';

import { RandomMessageAnnounce } from './RandomMessageAnnounce';

export const RepeatBadge = ({ ...props }) => (
  <RandomMessageAnnounce sampleMessages={messages} {...props}>
    Repeat
  </RandomMessageAnnounce>
);

const messages = [
  'Can you repeat that',
  'Can you say that again?',
  'Could you say that again please?',
  'Can you repeat that please?',
];
