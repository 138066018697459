import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  height: 350px;
  width: 350px;

  > footer > button {
    width: 100%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    gap: 0.5rem;
  }
`;

export const Content = styled.section`
  flex: 1;
  padding-left: 0.25rem;
  overflow: scroll;

  > p {
    border-bottom: 1px solid ${theme.colors.gray_light};
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    padding: 0 0.5rem 0 0.75rem;
    margin: 0.75rem 0 1rem;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
