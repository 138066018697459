import { getQuantityPerOptionOnSplit } from './get-quantity-per-option-on-split';

export function getShouldSplit(params: {
  modifierId: string;
  addedOptions: Record<
    string,
    { parentId: string; quantity: number; id: string }
  >;
  maxQuantity: number | null;
}) {
  const { maxQuantity, addedOptions, modifierId } = params;

  const modifierAddedOptions = Object.values(addedOptions).filter(
    (option) => option.parentId === modifierId,
  );

  const totalOptionQuantity = modifierAddedOptions.reduce(
    (total, option) => total + Number(option.quantity),
    0,
  );

  const shouldSplit = maxQuantity && totalOptionQuantity > maxQuantity;

  if (shouldSplit) {
    return {
      modifierAddedOptions,
      quantityPerOption: getQuantityPerOptionOnSplit({
        modifierAddedOptions,
        maxQuantity,
        maxQuantityPerOption: null,
      }),
    };
  }

  return {
    quantityPerOption: null,
  };
}
