import { RootState, store } from '@store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.device;

export const isDeviceRegistered = createDraftSafeSelector(
  selectSelf,
  (state) => state.registered,
);

export const isDevicePending = createDraftSafeSelector(selectSelf, (state) =>
  Object.keys(state.pending).some(
    (key) => state.pending[key as keyof typeof state.pending],
  ),
);

export const getDeviceHasError = createDraftSafeSelector(selectSelf, (state) =>
  Object.keys(state.errors).some(
    (key) => state.errors[key as keyof typeof state.errors],
  ),
);

export const isPendingPreflight = createDraftSafeSelector(
  selectSelf,
  (state) => state.lastEdgeRun !== state.edge,
);

export const getHasOngoingCall = createDraftSafeSelector(
  selectSelf,
  (state) => state.hasOngoingCall,
);

export const isDeviceMuted = createDraftSafeSelector(
  selectSelf,
  (state) => state.isMuted,
);

// TODO: deprecate these selectors
const device = createDraftSafeSelector(
  (state: RootState) => state.device,
  (deviceState) => deviceState,
);

export function isStarting() {
  const { pending } = device(store.getState());
  return pending.isStartingDevice;
}

export function isDeviceReady() {
  const { registered } = device(store.getState());
  return registered;
}

export function getStartTimeOfCall() {
  const { callAcceptedAt } = device(store.getState());
  return callAcceptedAt;
}

export function getLastAnnouncement() {
  const { lastAnnouncement } = device(store.getState());
  return lastAnnouncement;
}

export function getHasTriedHangup() {
  const { hasTriedHangup } = device(store.getState());
  return hasTriedHangup;
}

export function getCallbackCallSid() {
  return device(store.getState()).callbackCallSid;
}

export function hasForwarded() {
  return device(store.getState()).hasForwarded;
}
