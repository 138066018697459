import { GetItemByIdQuery } from 'src/graphql/generated/schema';
import { Item } from '../types';

type APIItem = GetItemByIdQuery['itemsV2']['edges'][0]['node'];

export function transformAPIItem(apiItem: APIItem): Item {
  return {
    id: apiItem.id,
    availabilityEnd: apiItem.availabilityEnd ?? null,
    availabilitySchedule: apiItem.availabilitySchedule ?? null,
    availabilityStart: apiItem.availabilityStart ?? null,
    chainId: apiItem.chainId,
    cost: apiItem.cost,
    description: apiItem.description,
    hasStockAvailable: apiItem.hasStockAvailable,
    maxQuantity: apiItem.maxQuantity ?? 0,
    maxTotalQuantity: apiItem.maxTotalQuantity ?? 0,
    minQuantity: apiItem.minQuantity ?? 0,
    minTotalQuantity: apiItem.minTotalQuantity ?? 0,
    modifierIds: apiItem.modifierIds ?? [],
    name: apiItem.name,
    parentId: apiItem.parentId,
    sortOrder: apiItem.sortOrder,
    spokenName: apiItem.spokenName ?? apiItem.name,
    status: 'idle',
    unavailableHandoffModes: apiItem.unavailableHandoffModes ?? [],
    upsellPhrase: apiItem.upsellPhrase ?? null,
    upsellPriority: apiItem.upsellPriority ?? 0,
    version: apiItem.version,
    quantityInterval: apiItem.quantityInterval ?? 1,
    linkedSpecialItemId: apiItem.linkedSpecialItemId ?? null,
    partOfSpeech: apiItem.partOfSpeech ?? null,
    preposition: apiItem.preposition ?? null,
    isUpsellTarget: apiItem.isUpsellTarget ?? false,
    soundboardEntries: apiItem.soundboardEntries ?? [],
  };
}
