import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useInterval } from '@hooks';

export function TimeOut({ onFinish, stop }) {
  const [duration, setDuration] = useState(20);

  useInterval(() => {
    if (!stop) {
      setDuration((prev) => prev - 1);
    }
  }, 1000);

  useEffect(() => {
    if (duration === 0) {
      onFinish();
    }
  }, [duration]);

  return (
    <span style={{ marginTop: 10 }}>
      {`Dismissing task in ${duration}s...`}
    </span>
  );
}

TimeOut.defaultProps = {
  stop: false,
};

TimeOut.propTypes = {
  onFinish: PropTypes.func.isRequired,
  stop: PropTypes.bool,
};
