import { theme, Chip as parrotChip } from '@kea-inc/parrot-ui';
import styled, { keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.main`
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.25rem;
  height: calc(100vh - 3.5rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Content = styled.section`
  margin-top: 1.125rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80%;

  .received__call {
    margin-right: 1.5rem;
  }

  .received__call,
  .agents__feed {
    height: 100%;
    border-radius: 0.5rem;
    &:hover {
      box-shadow: ${theme.elevations[1]};
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.5rem;
`;

export const Chip = styled(parrotChip)`
  background: ${(props) =>
    props.color && theme.colors[`${props.color}_lightest_2`]};
  border: 2px solid
    ${(props) => props.color && theme.colors[`${props.color}_light`]};
  padding: 6px 16px;
  align-self: flex-end;
  p {
    color: ${(props) => props.color && theme.colors[props.color]};
    font-size: 18px;
    font-weight: bold;
  }
  svg {
    color: ${(props) => props.color && theme.colors[props.color]};
    font-size: 24px;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 19,
})`
  animation: ${spin} 0.8s infinite linear;
`;
