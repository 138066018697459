import { DateTime } from 'luxon';
import KeaOrder from '@kea-inc/order';

import store from '@store';
import { formatPrice } from '@utils';
import { CartItem } from '@modules/cart/types';
import * as orderSelectors from '@modules/order/selectors';
import * as cartSelectors from '@modules/cart/selectors';
import { convertCartItemToDescriptionItem } from './convert-cart-item-to-description-item';

export function getOrderThrottlingMessage(
  availableTimes: { time: string }[],
  storeTimezone?: string,
) {
  const firstAvailableTime = availableTimes[0];

  let errorTime: string = 'later';

  if (firstAvailableTime) {
    errorTime = DateTime.fromISO(firstAvailableTime.time)
      .setZone(storeTimezone)
      .toFormat('hh:mm a');
  }

  return `We're currently experiencing high order volume. Your order will not be ready until ${errorTime}. Would you like to continue with your order or select another time in the future?`;
}

export function makeConfirmationMessage({
  firstName,
  cartItems,
}: {
  firstName: string;
  cartItems: CartItem[];
}) {
  if (!cartItems || cartItems.length === 0) {
    return 'Your cart is empty';
  }

  const repeatCartPhrase = createRepeatCartPhrase(cartItems);

  const total = cartSelectors.selectTotal(store.getState());
  const discount = cartSelectors.selectDiscount(store.getState());

  const discountPhrase =
    discount && discount > 0
      ? `and you've saved ${formatPrice(discount)},`
      : '';

  const handOffMode = orderSelectors.getOrderHandoffMode();
  const feesPhrase =
    handOffMode === 'delivery'
      ? 'with delivery and service fees included'
      : 'with taxes included';

  return `${repeatCartPhrase} That's ${formatPrice(
    total,
  )} for ${firstName} ${feesPhrase}, ${discountPhrase} will that complete your order today?`;
}

export function createRepeatCartPhrase(cartItems: CartItem[]) {
  if (!cartItems || cartItems.length === 0) {
    return 'Your cart is empty';
  }

  const descriptionItems = cartItems.map(convertCartItemToDescriptionItem);

  return `I have ${KeaOrder.createCartDescription(descriptionItems)}.`;
}
