import React, { useEffect, useMemo } from 'react';
import { Input, Select, Option } from '@kea-inc/parrot-ui';

import * as accountActions from '@modules/account/actions';
import * as accountSlice from '@modules/account/slice';
import * as orderAPI from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import * as accountSelectors from '@modules/account/selectors';
import { FirstNameBadge } from '@components/DynamicBadges';

import { useAccount, useOrder } from '@hooks';

import { AnnounceBadge } from '@components';

import { useAppDispatch } from '@store';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import * as utils from './utils';
import * as S from './styles';

let upsertTimeout: NodeJS.Timeout;

export const AccountDetails = React.memo(() => {
  const dispatch = useAppDispatch();
  const { accountDetails, pending } = useAccount();
  const {
    orderDetails,
    checklist,
    pending: { isBuildingChecklist },
  } = useOrder();
  const { isFetchingAccount, isUpdatingAccount } = pending;

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const readyByMessage = useMemo(
    () =>
      orderDetails.isASAP
        ? "I've scheduled your order for ASAP, is that right?"
        : 'When would you like your order to be ready?',
    [orderDetails.isASAP],
  );

  const updateDetails = () => {
    const details = accountSelectors.getAccountDetails();
    if (!details.firstName) {
      orderAPI.setChecklist(ORDER_CHECKLIST.ARE_FIELDS_FILLED, false);
      return;
    }
    orderAPI.setChecklist(
      ORDER_CHECKLIST.ARE_FIELDS_FILLED,
      !!accountDetails.firstName,
    );
    orderAPI.createOrderEvent('updated_account_details');
    orderAPI.createAnalyticsEvent('updated_account_details', details);
    dispatch(accountActions.updateAccount());
  };

  const handleBlur = () => {
    clearTimeout(upsertTimeout);
    upsertTimeout = setTimeout(updateDetails, 500);
  };

  const handleFocus = () => {
    clearTimeout(upsertTimeout);
  };

  const handleReadyChange = (value: string | number) => {
    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    orderAPI.createOrderEvent('changed_ready_by', {
      readyBy: value,
    });
    orderAPI.setDetails('isASAP', value === 'asap');

    const dateWithTime = utils.getDateNext10min();

    orderAPI.setDetails('deliveryTime', dateWithTime);
  };

  const filterLetters = (str: string) => str.replace(/[^A-Za-z]/gi, '');

  useEffect(() => {
    if (!isBuildingChecklist && checklist) {
      orderAPI.setChecklist(
        ORDER_CHECKLIST.ARE_FIELDS_FILLED,
        !!accountDetails.firstName,
      );
    }
  }, [isBuildingChecklist, accountDetails.firstName]);

  const handleIsASAPClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: {
        message: readyByMessage,
      },
    });
  };

  return (
    <>
      <section>
        <article>
          <S.Label>
            <FirstNameBadge />
          </S.Label>
          <Input
            onChange={(e) =>
              dispatch(
                accountSlice.setDetails({
                  key: 'firstName',
                  value: filterLetters(e.target.value),
                }),
              )
            }
            value={accountDetails.firstName}
            disabled={isFetchingAccount || isUpdatingAccount}
            onBlur={handleBlur}
            onFocus={handleFocus}
            required
            data-test-id="first-name-input"
          />
        </article>
        <Input
          label="Last Name"
          onChange={(e) =>
            dispatch(
              accountSlice.setDetails({
                key: 'lastName',
                value: filterLetters(e.target.value),
              }),
            )
          }
          value={accountDetails.lastName}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={isFetchingAccount || isUpdatingAccount}
          required
          data-test-id="last-name-input"
        />
      </section>

      <section>
        <Input
          label="Phone #"
          onChange={(e) =>
            dispatch(
              accountSlice.setDetails({
                key: 'phone',
                value: e.target.value,
              }),
            )
          }
          value={accountDetails.phone}
          disabled
          readOnly
          required
          data-test-id="phone-input"
        />

        {/* This needs to come here because of its UI position, even not being related to the account */}
        <article>
          <S.Label>
            <AnnounceBadge message={readyByMessage} onClick={handleIsASAPClick}>
              {orderDetails.isASAP ? 'Ready By' : 'Ready by?'}
            </AnnounceBadge>
          </S.Label>
          <Select
            value={orderDetails.isASAP ? 'asap' : 'schedule'}
            required
            onChange={handleReadyChange}
            data-test-id="ready-by-select"
          >
            <Option value="asap">ASAP</Option>
            <Option value="schedule">Schedule</Option>
          </Select>
        </article>
      </section>
    </>
  );
});
