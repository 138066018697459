import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { IconButton } from '@kea-inc/parrot-ui';

import { useDevice } from '@hooks';
import { announce } from '@modules/device/device-actions';
import { FaVolumeUp } from 'react-icons/fa';
import { useAppDispatch } from '@store';
import { IIconButtonProps } from '@kea-inc/parrot-ui/dist/components/IconButton/types';

type AnnounceIconProps = Omit<IIconButtonProps, 'icon'> & {
  message: string;
  variant: string;
};

const AnnounceIcon = ({
  message = '',
  variant = 'primary',
  onClick,
  ...props
}: AnnounceIconProps) => {
  const dispatch = useAppDispatch();
  const { announcementId } = useDevice();
  const id = useMemo(() => uuid(), []);

  const handleClick: IIconButtonProps['onClick'] = (e) => {
    e.stopPropagation();
    dispatch(announce({ text: message, id }));
    onClick?.(e);
  };

  const isAnnouncing = announcementId === id;

  return (
    <IconButton
      {...props}
      icon={FaVolumeUp}
      onClick={isAnnouncing ? undefined : handleClick}
      variant={isAnnouncing ? 'accent_1' : variant}
    />
  );
};

export default AnnounceIcon;
