import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { DateTime } from 'luxon';
import { INITIAL_STATE } from './state';
import {
  Action,
  Actions,
  DeliveryInfo,
  Prompts,
  SoundboardEntry,
  SoundboardPrompts,
  VoicePaneState,
} from './types';

const actions = {
  [Actions.VOICEPANE_FETCHING]: (state: VoicePaneState) =>
    produce(state, (voicePane) => {
      voicePane.pending.isFetchingPrompts = true;
      voicePane.errors.hasFetchPromptsError = false;
      return voicePane;
    }),

  [Actions.VOICEPANE_NOT_FETCHED]: (state: VoicePaneState) =>
    produce(state, (voicePane) => {
      voicePane.pending.isFetchingPrompts = false;
      voicePane.errors.hasFetchPromptsError = true;
      return voicePane;
    }),

  [Actions.VOICEPANE_FETCHED]: (
    state: VoicePaneState,
    action: Action<{
      fetchedPrompts: Prompts;
    }>,
  ) =>
    produce(state, (voicePane) => {
      voicePane.pending.isFetchingPrompts = false;
      voicePane.errors.hasFetchPromptsError = false;
      if (action.payload) {
        const { fetchedPrompts } = action.payload;
        voicePane.prompts = fetchedPrompts;
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_PROMPTS_FETCHED]: (
    state: VoicePaneState,
    action: Action<{
      soundboardPrompts: SoundboardPrompts;
    }>,
  ) =>
    produce(state, (voicePane) => {
      voicePane.pending.isFetchingPrompts = false;
      voicePane.errors.hasFetchPromptsError = false;
      if (action.payload) {
        const { soundboardPrompts } = action.payload;
        voicePane.prompts.byContext = soundboardPrompts;
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_SOUNDBOARD_ENTRIES_FETCHED]: (
    state: VoicePaneState,
    action: Action<{
      soundboardEntries: SoundboardEntry[];
    }>,
  ) =>
    produce(state, (voicePane) => {
      voicePane.pending.isFetchingPrompts = false;
      voicePane.errors.hasFetchPromptsError = false;
      if (action.payload) {
        const { soundboardEntries } = action.payload;
        voicePane.prompts.byContext = soundboardEntries.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group]: [...(acc[curr.group] ?? []), curr.text],
          }),
          {} as Record<string, string[]>,
        );
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_SET_INITIAL]: (state: VoicePaneState) =>
    produce(state, (voicePane) => {
      const contexts = Object.keys(voicePane.prompts.byContext ?? {});
      voicePane.currentContext = contexts;
      return voicePane;
    }),

  [Actions.VOICEPANE_SET_CONTEXT_PROMPTS]: (
    state: VoicePaneState,
    action: Action<{ contexts: string[] }>,
  ) =>
    produce(state, (voicePane) => {
      if (action.payload) {
        const { contexts } = action.payload;
        voicePane.currentContext = [...contexts];
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_SET_CATEGORY_PROMPTS]: (
    state: VoicePaneState,
    action: Action<{ category: string }>,
  ) =>
    produce(state, (voicePane) => {
      if (action.payload) {
        const { category } = action.payload;
        const { byCategory } = voicePane.prompts;
        voicePane.categoryBased = byCategory?.[category] ?? [];
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_FILTER_PROMPTS]: (
    state: VoicePaneState,
    action: Action<{ searchTerm: string }>,
  ) =>
    produce(state, (voicePane) => {
      if (action.payload) {
        const { searchTerm } = action.payload;
        let filteredPrompts = {} as Record<string, string[]>;
        if (searchTerm.length < 3) {
          voicePane.filteredPrompts = null;
          return voicePane;
        }

        const { byCategory, byContext } = voicePane.prompts;
        const contexts = Object.keys(byContext);
        const categories = Object.keys(byCategory);

        contexts.map((context) =>
          byContext[context]?.forEach((prompt) => {
            const message = prompt.toLowerCase();

            if (message.includes(searchTerm.toLowerCase())) {
              filteredPrompts = {
                ...filteredPrompts,
                [context]: [...(filteredPrompts[context] ?? []), prompt],
              };
            }
          }),
        );

        categories.map((category) =>
          byCategory?.[category]?.forEach((prompt) => {
            const message = prompt.toLowerCase();

            if (message.includes(searchTerm.toLowerCase())) {
              filteredPrompts = {
                ...filteredPrompts,
                [category]: [...(filteredPrompts[category] ?? []), prompt],
              };
            }
          }),
        );

        voicePane.filteredPrompts = filteredPrompts;
      }
      return voicePane;
    }),

  [Actions.VOICEPANE_SET_DELIVERY_TIME]: (
    state: VoicePaneState,
    action: Action<{ deliveryInfo: DeliveryInfo; timezone: string }>,
  ) =>
    produce(state, (voicePane) => {
      if (action.payload) {
        const { deliveryTime, isASAP } = action.payload.deliveryInfo;
        const { byContext } = voicePane.prompts;

        const date = DateTime.fromMillis(deliveryTime as number);
        const zoned = date.setZone(action?.payload?.timezone);

        const time = isASAP
          ? 'ASAP'
          : zoned.toFormat("EEEE, MMMM dd 'at' hh:mm a");

        const deliveryTimePrompt = `I've scheduled your order for ${time}. Is that correct?`;

        if (!byContext?.Details) {
          byContext.Details = [deliveryTimePrompt];
        } else if (byContext.Details.length === 1) {
          byContext.Details.push(deliveryTimePrompt);
        } else {
          const lastIdx = byContext?.Details?.length - 1;
          byContext.Details.splice(lastIdx, 1, deliveryTimePrompt);
        }
      }

      return voicePane;
    }),

  [Actions.VOICEPANE_RESET]: (state: VoicePaneState) =>
    produce(state, () => INITIAL_STATE),
};

export const voicePaneReducer = createReducer<VoicePaneState>(
  INITIAL_STATE,
  actions,
);
