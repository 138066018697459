import { createGlobalStyle } from 'styled-components';
import { theme } from '@kea-inc/parrot-ui/dist/theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  @media(max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }

  @media(max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  html, body, #root {
    min-height: 100vh;
    min-width: 100vw;
    overflow-x: hidden;
  }
  body {
    background: ${theme.colors.gray_lighter};
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  input {
    color: var(--color-text);
  }
`;

export default GlobalStyle;
