import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
  ObjectID: any;
};

export type BrandCategory = {
  __typename?: 'BrandCategory';
  aliases?: Maybe<Array<Scalars['String']>>;
  chainId: Scalars['String'];
  customWhich?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasItemsAvailable?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  isPromptRequired?: Maybe<Scalars['Boolean']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Float']>;
  spokenName?: Maybe<Scalars['String']>;
  storeLevelIds: Array<StoreLevelIds>;
};

export type BrandCategoryConnection = {
  __typename?: 'BrandCategoryConnection';
  edges: Array<BrandCategoryEdge>;
  pageInfo: PageInfo;
};

export type BrandCategoryEdge = {
  __typename?: 'BrandCategoryEdge';
  cursor: Scalars['String'];
  node: BrandCategory;
};

export type BrandItem = {
  __typename?: 'BrandItem';
  aliases?: Maybe<Array<Scalars['String']>>;
  article?: Maybe<Scalars['String']>;
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']>;
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  customWhich?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isUpsellTarget?: Maybe<Scalars['Boolean']>;
  linkedSpecialItemId?: Maybe<Scalars['String']>;
  maxQuantity?: Maybe<Scalars['Float']>;
  maxTotalQuantity?: Maybe<Scalars['Float']>;
  minQuantity?: Maybe<Scalars['Float']>;
  minTotalQuantity?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentChainId: Scalars['String'];
  partOfSpeech?: Maybe<Scalars['Float']>;
  preposition?: Maybe<Scalars['String']>;
  quantityInterval?: Maybe<Scalars['Float']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Float']>;
  spokenName?: Maybe<Scalars['String']>;
  storeLevelIds: Array<StoreLevelIds>;
  unavailableHandoffModes?: Maybe<Array<Scalars['String']>>;
  upsellPhrase?: Maybe<Scalars['String']>;
  upsellPriority?: Maybe<Scalars['Float']>;
};

export type BrandItemConnection = {
  __typename?: 'BrandItemConnection';
  edges: Array<BrandItemEdge>;
  pageInfo: PageInfo;
};

export type BrandItemEdge = {
  __typename?: 'BrandItemEdge';
  cursor: Scalars['String'];
  node: BrandItem;
};

export type BrandModifier = {
  __typename?: 'BrandModifier';
  aliases?: Maybe<Array<Scalars['String']>>;
  ancestorIds?: Maybe<Array<Scalars['String']>>;
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']>;
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  customWhich?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  explanationText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isPromptRequired?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']>;
  maxQuantityPerOption?: Maybe<Scalars['Float']>;
  maxTotalQuantity?: Maybe<Scalars['Float']>;
  maxUniqueOptions?: Maybe<Scalars['Float']>;
  minQuantityPerOption?: Maybe<Scalars['Float']>;
  minTotalQuantity?: Maybe<Scalars['Float']>;
  minUniqueOptions?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  parentChainId: Scalars['String'];
  quantityInterval?: Maybe<Scalars['Float']>;
  shouldForceSpecify?: Maybe<Scalars['Boolean']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Float']>;
  spokenName?: Maybe<Scalars['String']>;
  storeLevelIds: Array<StoreLevelIds>;
  supportsQuantities?: Maybe<Scalars['Boolean']>;
};

export type BrandModifierConnection = {
  __typename?: 'BrandModifierConnection';
  edges: Array<BrandModifierEdge>;
  pageInfo: PageInfo;
};

export type BrandModifierEdge = {
  __typename?: 'BrandModifierEdge';
  cursor: Scalars['String'];
  node: BrandModifier;
};

export type BrandOption = {
  __typename?: 'BrandOption';
  adjustItemPrice?: Maybe<Scalars['Boolean']>;
  aliases?: Maybe<Array<Scalars['String']>>;
  ancestorIds?: Maybe<Array<Scalars['String']>>;
  article?: Maybe<Scalars['String']>;
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']>;
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  customWhich?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isImplicitItem?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentChainId: Scalars['String'];
  partOfSpeech?: Maybe<Scalars['Float']>;
  preposition?: Maybe<Scalars['String']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  shouldRepeatDefaultOptions?: Maybe<Scalars['Boolean']>;
  shouldRepeatOptionRemoval?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Float']>;
  spokenName?: Maybe<Scalars['String']>;
  storeLevelIds: Array<StoreLevelIds>;
};

export type BrandOptionConnection = {
  __typename?: 'BrandOptionConnection';
  edges: Array<BrandOptionEdge>;
  pageInfo: PageInfo;
};

export type BrandOptionEdge = {
  __typename?: 'BrandOptionEdge';
  cursor: Scalars['String'];
  node: BrandOption;
};

export type CategoryConnectionV1Deprecated = {
  __typename?: 'CategoryConnectionV1Deprecated';
  edges: Array<CategoryEdgeV1Deprecated>;
  pageInfo: PageInfo;
};

export type CategoryConnectionV2 = {
  __typename?: 'CategoryConnectionV2';
  edges: Array<CategoryEdgeV2>;
  pageInfo: PageInfo;
};

export type CategoryEdgeV1Deprecated = {
  __typename?: 'CategoryEdgeV1Deprecated';
  cursor: Scalars['String'];
  node: CategoryV1Deprecated;
};

export type CategoryEdgeV2 = {
  __typename?: 'CategoryEdgeV2';
  cursor: Scalars['String'];
  node: CategoryV2;
};

export type CategoryV1Deprecated = {
  __typename?: 'CategoryV1Deprecated';
  _id: Scalars['ObjectID'];
  chainId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasItemsAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  menuItems: ItemConnectionV1Deprecated;
  metadata?: Maybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
  utcoffset: Scalars['String'];
  version: Scalars['String'];
};


export type CategoryV1DeprecatedMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type CategoryV2 = {
  __typename?: 'CategoryV2';
  aliases: Array<Scalars['String']>;
  chainId: Scalars['String'];
  customWhich?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasItemsAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isUpsellTarget?: Maybe<Scalars['Boolean']>;
  itemIds: Array<Scalars['String']>;
  itemsV2: ItemConnectionV2;
  name: Scalars['String'];
  sortOrder: Scalars['Float'];
  soundboardEntries?: Maybe<Array<Scalars['String']>>;
  spokenName: Scalars['String'];
  upsellPhrase?: Maybe<Scalars['String']>;
  upsellPriority?: Maybe<Scalars['Float']>;
  version: Scalars['String'];
};

export type ItemConnectionV1Deprecated = {
  __typename?: 'ItemConnectionV1Deprecated';
  edges: Array<ItemEdgeV1Deprecated>;
  pageInfo: PageInfo;
};

export type ItemConnectionV2 = {
  __typename?: 'ItemConnectionV2';
  edges: Array<ItemEdgeV2>;
  pageInfo: PageInfo;
};

export type ItemEdgeV1Deprecated = {
  __typename?: 'ItemEdgeV1Deprecated';
  cursor: Scalars['String'];
  node: ItemV1Deprecated;
};

export type ItemEdgeV2 = {
  __typename?: 'ItemEdgeV2';
  cursor: Scalars['String'];
  node: ItemV2;
};

export type ItemV1Deprecated = {
  __typename?: 'ItemV1Deprecated';
  _id: Scalars['ObjectID'];
  article: Scalars['String'];
  availability: Scalars['JSONObject'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  description: Scalars['String'];
  hasStockAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  maxBasketQuantity?: Maybe<Scalars['Float']>;
  maxQuantity?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  minBasketQuantity?: Maybe<Scalars['Float']>;
  minQuantity?: Maybe<Scalars['Float']>;
  modifierIds: Array<Scalars['String']>;
  modifiers: ModifierConnectionV1Deprecated;
  name: Scalars['String'];
  partOfSpeech: Scalars['Float'];
  preposition: Scalars['String'];
  quantityInterval: Scalars['Float'];
  sortOrder: Scalars['Float'];
  unavailableHandoffMode: Scalars['JSONObject'];
  unavailableHandoffModes: Array<Scalars['String']>;
  utcoffset: Scalars['String'];
  version: Scalars['String'];
};

export type ItemV2 = {
  __typename?: 'ItemV2';
  aliases: Array<Scalars['String']>;
  article: Scalars['String'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  cost: Scalars['Float'];
  description: Scalars['String'];
  hasStockAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isUpsellTarget: Scalars['Boolean'];
  linkedSpecialItemId?: Maybe<Scalars['String']>;
  maxQuantity?: Maybe<Scalars['Float']>;
  maxTotalQuantity?: Maybe<Scalars['Float']>;
  minQuantity?: Maybe<Scalars['Float']>;
  minTotalQuantity?: Maybe<Scalars['Float']>;
  modifierIds: Array<Scalars['String']>;
  modifiers: ModifierConnectionV1Deprecated;
  name: Scalars['String'];
  parent: CategoryV2;
  parentId: Scalars['String'];
  partOfSpeech: Scalars['Float'];
  preposition: Scalars['String'];
  quantityInterval: Scalars['Float'];
  sortOrder: Scalars['Float'];
  soundboardEntries?: Maybe<Array<Scalars['String']>>;
  spokenName: Scalars['String'];
  type: Scalars['String'];
  unavailableHandoffModes: Array<Scalars['String']>;
  upsellPhrase: Scalars['String'];
  upsellPriority: Scalars['Float'];
  version: Scalars['String'];
};

export type ModifierConnectionV1Deprecated = {
  __typename?: 'ModifierConnectionV1Deprecated';
  edges: Array<ModifierEdgeV1Deprecated>;
  pageInfo: PageInfo;
};

export type ModifierConnectionV2 = {
  __typename?: 'ModifierConnectionV2';
  edges: Array<ModifierEdgeV2>;
  pageInfo: PageInfo;
};

export type ModifierEdgeV1Deprecated = {
  __typename?: 'ModifierEdgeV1Deprecated';
  cursor: Scalars['String'];
  node: ModifierV1Deprecated;
};

export type ModifierEdgeV2 = {
  __typename?: 'ModifierEdgeV2';
  cursor: Scalars['String'];
  node: ModifierV2;
};

export type ModifierV1Deprecated = {
  __typename?: 'ModifierV1Deprecated';
  _id: Scalars['ObjectID'];
  availability: Scalars['JSONObject'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['String']>;
  customWhich: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayMode?: Maybe<OptionDisplayMode>;
  hasStockAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isPromptRequired?: Maybe<Scalars['Boolean']>;
  isSelectionRequired?: Maybe<Scalars['Boolean']>;
  maxOptions?: Maybe<Scalars['Float']>;
  maxQuantity?: Maybe<Scalars['Float']>;
  maxQuantityPerOption?: Maybe<Scalars['Float']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  minOptions?: Maybe<Scalars['Float']>;
  minQuantity?: Maybe<Scalars['Float']>;
  minQuantityPerOption?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  options: OptionsConnectionV1Deprecated;
  parentId: Scalars['String'];
  quantityInterval?: Maybe<Scalars['Float']>;
  required?: Maybe<Scalars['Boolean']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  sortOrder: Scalars['Float'];
  spokenName?: Maybe<Scalars['String']>;
  supportOptionQuantity: Scalars['Boolean'];
  utcoffset: Scalars['String'];
  version: Scalars['String'];
};


export type ModifierV1DeprecatedOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type ModifierV2 = {
  __typename?: 'ModifierV2';
  aliases: Array<Scalars['String']>;
  ancestorIds: Array<Scalars['String']>;
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  explanationText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isRequired: Scalars['Boolean'];
  maxQuantityPerOption?: Maybe<Scalars['Float']>;
  maxTotalQuantity?: Maybe<Scalars['Float']>;
  maxUniqueOptions?: Maybe<Scalars['Float']>;
  minQuantityPerOption?: Maybe<Scalars['Float']>;
  minTotalQuantity?: Maybe<Scalars['Float']>;
  minUniqueOptions?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  optionIds: Array<Scalars['String']>;
  options: OptionsV2Connection;
  quantityInterval: Scalars['Float'];
  sortOrder: Scalars['Float'];
  spokenName: Scalars['String'];
  supportsQuantities: Scalars['Boolean'];
  version: Scalars['String'];
};

export enum OptionDisplayMode {
  InlineRadio = 'INLINE_RADIO',
  Nested = 'NESTED'
}

export type OptionEdgeV1Deprecated = {
  __typename?: 'OptionEdgeV1Deprecated';
  cursor: Scalars['String'];
  node: OptionV1Deprecated;
};

export type OptionV1Deprecated = {
  __typename?: 'OptionV1Deprecated';
  _id: Scalars['ObjectID'];
  adjustItemPrice: Scalars['Boolean'];
  article: Scalars['String'];
  availability: Scalars['JSONObject'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['String']>;
  cost: Scalars['Float'];
  hasStockAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isDefault: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSONObject']>;
  modifierIds?: Maybe<Array<Scalars['String']>>;
  modifiers?: Maybe<ModifierConnectionV1Deprecated>;
  name: Scalars['String'];
  partOfSpeech: Scalars['Float'];
  preposition: Scalars['String'];
  sortOrder: Scalars['Float'];
  spokenName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  utcoffset: Scalars['String'];
  version: Scalars['String'];
};


export type OptionV1DeprecatedModifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type OptionV2 = {
  __typename?: 'OptionV2';
  adjustItemPrice: Scalars['Boolean'];
  aliases: Array<Scalars['String']>;
  ancestorIds: Array<Scalars['String']>;
  article: Scalars['String'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule: Scalars['JSONObject'];
  availabilityStart?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  cost: Scalars['Float'];
  hasStockAvailable: Scalars['Boolean'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isExclusive: Scalars['Boolean'];
  isHidden: Scalars['Boolean'];
  isImplicitItem: Scalars['Boolean'];
  modifierIds: Array<Scalars['String']>;
  modifiers?: Maybe<ModifierConnectionV2>;
  name: Scalars['String'];
  parent: ModifierV2;
  parentId: Scalars['String'];
  partOfSpeech: Scalars['Float'];
  preposition: Scalars['String'];
  removedDefaultOptionDeterminer?: Maybe<Scalars['String']>;
  shouldPluralize?: Maybe<Scalars['Boolean']>;
  shouldRepeatDefaultOptions?: Maybe<Scalars['Boolean']>;
  shouldRepeatOptionRemoval?: Maybe<Scalars['Boolean']>;
  sortOrder: Scalars['Float'];
  spokenName: Scalars['String'];
  type: Scalars['String'];
  version: Scalars['String'];
};

export type OptionV2Edge = {
  __typename?: 'OptionV2Edge';
  cursor: Scalars['String'];
  node: OptionV2;
};

export type OptionsConnectionV1Deprecated = {
  __typename?: 'OptionsConnectionV1Deprecated';
  edges: Array<OptionEdgeV1Deprecated>;
  pageInfo: PageInfo;
};

export type OptionsV2Connection = {
  __typename?: 'OptionsV2Connection';
  edges: Array<OptionV2Edge>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  brandCategories: BrandCategoryConnection;
  brandItems: BrandItemConnection;
  brandModifiers: BrandModifierConnection;
  brandOptions: BrandOptionConnection;
  categories: CategoryConnectionV1Deprecated;
  categoriesV2: CategoryConnectionV2;
  items: ItemConnectionV2;
  itemsV2: ItemConnectionV2;
  menuItems: ItemConnectionV1Deprecated;
  modifiers: ModifierConnectionV1Deprecated;
  options: OptionsConnectionV1Deprecated;
  optionsV2: OptionsV2Connection;
  store: StoreConnection;
  storeHours: StoreHourConnection;
  upsell: UpsellConnection;
};


export type QueryBrandCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  parentChainId?: InputMaybe<Scalars['String']>;
};


export type QueryBrandItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  parentChainId?: InputMaybe<Scalars['String']>;
};


export type QueryBrandModifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  parentChainId?: InputMaybe<Scalars['String']>;
};


export type QueryBrandOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  parentChainId?: InputMaybe<Scalars['String']>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryCategoriesV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryItemsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isUpsellTarget?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryModifiersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  parentId?: InputMaybe<Scalars['String']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryOptionsV2Args = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};


export type QueryStoreArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  platformName: Scalars['String'];
  storeId: Scalars['String'];
};


export type QueryStoreHoursArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId: Scalars['String'];
  day?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  platformName: Scalars['String'];
  storeId: Scalars['String'];
};


export type QueryUpsellArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  brandId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
  platformName?: InputMaybe<Scalars['String']>;
  storeId: Scalars['String'];
};

export type Store = {
  __typename?: 'Store';
  brandId: Scalars['String'];
  chainId: Scalars['String'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['JSONObject']>;
  storePlatform?: Maybe<Scalars['JSONObject']>;
  syncStoreHours: Scalars['Boolean'];
  timezone: Scalars['String'];
};

export type StoreConnection = {
  __typename?: 'StoreConnection';
  edges: Array<StoreEdge>;
  pageInfo: PageInfo;
};

export type StoreEdge = {
  __typename?: 'StoreEdge';
  cursor: Scalars['String'];
  node: Store;
};

export type StoreHour = {
  __typename?: 'StoreHour';
  day: Scalars['String'];
  endday: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  type: Scalars['String'];
};

export type StoreHourConnection = {
  __typename?: 'StoreHourConnection';
  edges: Array<StoreHourEdge>;
  pageInfo: PageInfo;
};

export type StoreHourEdge = {
  __typename?: 'StoreHourEdge';
  cursor: Scalars['String'];
  node: StoreHour;
};

export type StoreLevelIds = {
  __typename?: 'StoreLevelIds';
  id: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  menuImport: CategoryConnectionV1Deprecated;
};


export type SubscriptionMenuImportArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Float']>;
  last?: InputMaybe<Scalars['Float']>;
};

export type Upsell = {
  __typename?: 'Upsell';
  _id: Scalars['ObjectID'];
  availabilityEnd?: Maybe<Scalars['String']>;
  availabilitySchedule?: Maybe<Scalars['JSONObject']>;
  availabilityStart?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  chainId?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  hasStockAvailable?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['JSONObject']>;
  name: Scalars['String'];
  unavailableHandoffModes?: Maybe<Array<Scalars['String']>>;
};

export type UpsellConnection = {
  __typename?: 'UpsellConnection';
  edges: Array<UpsellEdge>;
  pageInfo: PageInfo;
};

export type UpsellEdge = {
  __typename?: 'UpsellEdge';
  cursor: Scalars['String'];
  node: Upsell;
};

export type GetCategoriesV2QueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetCategoriesV2Query = { __typename?: 'Query', categoriesV2: { __typename?: 'CategoryConnectionV2', edges: Array<{ __typename?: 'CategoryEdgeV2', node: { __typename?: 'CategoryV2', id: string, name: string, chainId: string, description?: string | null, spokenName: string, customWhich?: string | null, sortOrder: number, version: string, upsellPhrase?: string | null, upsellPriority?: number | null, isUpsellTarget?: boolean | null, hasItemsAvailable: boolean, soundboardEntries?: Array<string> | null } }> } };

export type GetItemByIdQueryVariables = Exact<{
  storeId: Scalars['String'];
  itemId?: InputMaybe<Scalars['String']>;
}>;


export type GetItemByIdQuery = { __typename?: 'Query', itemsV2: { __typename?: 'ItemConnectionV2', edges: Array<{ __typename?: 'ItemEdgeV2', node: { __typename?: 'ItemV2', id: string, name: string, chainId: string, description: string, cost: number, hasStockAvailable: boolean, maxQuantity?: number | null, maxTotalQuantity?: number | null, minQuantity?: number | null, minTotalQuantity?: number | null, modifierIds: Array<string>, parentId: string, sortOrder: number, spokenName: string, unavailableHandoffModes: Array<string>, version: string, availabilityStart?: string | null, availabilityEnd?: string | null, availabilitySchedule: any, upsellPhrase: string, upsellPriority: number, isUpsellTarget: boolean, linkedSpecialItemId?: string | null, partOfSpeech: number, preposition: string, quantityInterval: number, soundboardEntries?: Array<string> | null } }> } };

export type GetItemsFromCategoryV2QueryVariables = Exact<{
  storeId: Scalars['String'];
  categoryId?: InputMaybe<Scalars['String']>;
}>;


export type GetItemsFromCategoryV2Query = { __typename?: 'Query', itemsV2: { __typename?: 'ItemConnectionV2', edges: Array<{ __typename?: 'ItemEdgeV2', node: { __typename?: 'ItemV2', id: string, name: string, chainId: string, description: string, cost: number, hasStockAvailable: boolean, maxQuantity?: number | null, maxTotalQuantity?: number | null, minQuantity?: number | null, minTotalQuantity?: number | null, modifierIds: Array<string>, parentId: string, sortOrder: number, spokenName: string, unavailableHandoffModes: Array<string>, version: string, availabilityStart?: string | null, availabilityEnd?: string | null, availabilitySchedule: any, upsellPhrase: string, upsellPriority: number, isUpsellTarget: boolean, linkedSpecialItemId?: string | null, partOfSpeech: number, preposition: string, quantityInterval: number, soundboardEntries?: Array<string> | null } }> } };

export type GetModifiersFromParentV2QueryVariables = Exact<{
  storeId: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
}>;


export type GetModifiersFromParentV2Query = { __typename?: 'Query', modifiers: { __typename?: 'ModifierConnectionV1Deprecated', edges: Array<{ __typename?: 'ModifierEdgeV1Deprecated', node: { __typename?: 'ModifierV1Deprecated', availabilityEnd?: string | null, availabilitySchedule: any, availabilityStart?: string | null, chainId?: string | null, description?: string | null, displayMode?: OptionDisplayMode | null, id: string, maxOptions?: number | null, maxQuantity?: number | null, maxQuantityPerOption?: number | null, minOptions?: number | null, minQuantity?: number | null, minQuantityPerOption?: number | null, name?: string | null, parentId: string, quantityInterval?: number | null, isSelectionRequired?: boolean | null, isPromptRequired?: boolean | null, sortOrder: number, spokenName?: string | null, supportOptionQuantity: boolean, customWhich: string, version: string } }> } };

export type GetOptionsFromModifierV2QueryVariables = Exact<{
  storeId: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
}>;


export type GetOptionsFromModifierV2Query = { __typename?: 'Query', optionsV2: { __typename?: 'OptionsV2Connection', edges: Array<{ __typename?: 'OptionV2Edge', node: { __typename?: 'OptionV2', id: string, ancestorIds: Array<string>, article: string, availabilityEnd?: string | null, availabilitySchedule: any, availabilityStart?: string | null, chainId: string, cost: number, hasStockAvailable: boolean, adjustItemPrice: boolean, isDefault: boolean, isExclusive: boolean, isHidden: boolean, isImplicitItem: boolean, modifierIds: Array<string>, name: string, partOfSpeech: number, parentId: string, preposition: string, shouldRepeatDefaultOptions?: boolean | null, shouldRepeatOptionRemoval?: boolean | null, shouldPluralize?: boolean | null, sortOrder: number, spokenName: string, type: string, version: string } }> } };

export type GetUpsellsQueryVariables = Exact<{
  storeId: Scalars['String'];
}>;


export type GetUpsellsQuery = { __typename?: 'Query', itemsV2: { __typename?: 'ItemConnectionV2', edges: Array<{ __typename?: 'ItemEdgeV2', node: { __typename?: 'ItemV2', id: string, name: string, chainId: string, description: string, cost: number, hasStockAvailable: boolean, maxQuantity?: number | null, maxTotalQuantity?: number | null, minQuantity?: number | null, minTotalQuantity?: number | null, modifierIds: Array<string>, parentId: string, sortOrder: number, spokenName: string, unavailableHandoffModes: Array<string>, version: string, availabilityStart?: string | null, availabilityEnd?: string | null, availabilitySchedule: any, upsellPhrase: string, upsellPriority: number, isUpsellTarget: boolean, linkedSpecialItemId?: string | null, partOfSpeech: number, preposition: string, quantityInterval: number, soundboardEntries?: Array<string> | null } }> }, categoriesV2: { __typename?: 'CategoryConnectionV2', edges: Array<{ __typename?: 'CategoryEdgeV2', node: { __typename?: 'CategoryV2', id: string, name: string, chainId: string, description?: string | null, spokenName: string, customWhich?: string | null, sortOrder: number, version: string, upsellPhrase?: string | null, upsellPriority?: number | null, isUpsellTarget?: boolean | null, hasItemsAvailable: boolean, soundboardEntries?: Array<string> | null } }> } };

export type SearchItemsQueryVariables = Exact<{
  storeId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type SearchItemsQuery = { __typename?: 'Query', itemsV2: { __typename?: 'ItemConnectionV2', edges: Array<{ __typename?: 'ItemEdgeV2', node: { __typename?: 'ItemV2', id: string, name: string, chainId: string, description: string, cost: number, hasStockAvailable: boolean, maxQuantity?: number | null, maxTotalQuantity?: number | null, minQuantity?: number | null, minTotalQuantity?: number | null, modifierIds: Array<string>, parentId: string, sortOrder: number, spokenName: string, unavailableHandoffModes: Array<string>, version: string, availabilityStart?: string | null, availabilityEnd?: string | null, availabilitySchedule: any, upsellPhrase: string, upsellPriority: number, isUpsellTarget: boolean, linkedSpecialItemId?: string | null, partOfSpeech: number, preposition: string, quantityInterval: number, soundboardEntries?: Array<string> | null } }> } };

export type GetSoundboardPromptsQueryVariables = Exact<{
  platformName: Scalars['String'];
  brandId: Scalars['String'];
  storeId: Scalars['String'];
}>;


export type GetSoundboardPromptsQuery = { __typename?: 'Query', store: { __typename?: 'StoreConnection', edges: Array<{ __typename?: 'StoreEdge', node: { __typename?: 'Store', metadata?: any | null } }> } };


export const GetCategoriesV2Document = gql`
    query GetCategoriesV2($storeId: String!) {
  categoriesV2(storeId: $storeId) {
    edges {
      node {
        id
        name
        chainId
        description
        spokenName
        customWhich
        sortOrder
        version
        upsellPhrase
        upsellPriority
        isUpsellTarget
        hasItemsAvailable
        soundboardEntries
      }
    }
  }
}
    `;

/**
 * __useGetCategoriesV2Query__
 *
 * To run a query within a React component, call `useGetCategoriesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesV2Query({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetCategoriesV2Query(baseOptions: Apollo.QueryHookOptions<GetCategoriesV2Query, GetCategoriesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesV2Query, GetCategoriesV2QueryVariables>(GetCategoriesV2Document, options);
      }
export function useGetCategoriesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesV2Query, GetCategoriesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesV2Query, GetCategoriesV2QueryVariables>(GetCategoriesV2Document, options);
        }
export type GetCategoriesV2QueryHookResult = ReturnType<typeof useGetCategoriesV2Query>;
export type GetCategoriesV2LazyQueryHookResult = ReturnType<typeof useGetCategoriesV2LazyQuery>;
export type GetCategoriesV2QueryResult = Apollo.QueryResult<GetCategoriesV2Query, GetCategoriesV2QueryVariables>;
export const GetItemByIdDocument = gql`
    query GetItemById($storeId: String!, $itemId: String) {
  itemsV2(storeId: $storeId, id: $itemId) {
    edges {
      node {
        id
        name
        chainId
        description
        cost
        hasStockAvailable
        maxQuantity
        maxTotalQuantity
        minQuantity
        minTotalQuantity
        modifierIds
        parentId
        sortOrder
        spokenName
        unavailableHandoffModes
        version
        availabilityStart
        availabilityEnd
        availabilitySchedule
        upsellPhrase
        upsellPriority
        isUpsellTarget
        linkedSpecialItemId
        partOfSpeech
        preposition
        quantityInterval
        soundboardEntries
      }
    }
  }
}
    `;

/**
 * __useGetItemByIdQuery__
 *
 * To run a query within a React component, call `useGetItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByIdQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemByIdQuery(baseOptions: Apollo.QueryHookOptions<GetItemByIdQuery, GetItemByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemByIdQuery, GetItemByIdQueryVariables>(GetItemByIdDocument, options);
      }
export function useGetItemByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemByIdQuery, GetItemByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemByIdQuery, GetItemByIdQueryVariables>(GetItemByIdDocument, options);
        }
export type GetItemByIdQueryHookResult = ReturnType<typeof useGetItemByIdQuery>;
export type GetItemByIdLazyQueryHookResult = ReturnType<typeof useGetItemByIdLazyQuery>;
export type GetItemByIdQueryResult = Apollo.QueryResult<GetItemByIdQuery, GetItemByIdQueryVariables>;
export const GetItemsFromCategoryV2Document = gql`
    query GetItemsFromCategoryV2($storeId: String!, $categoryId: String) {
  itemsV2(storeId: $storeId, parentId: $categoryId) {
    edges {
      node {
        id
        name
        chainId
        description
        cost
        hasStockAvailable
        maxQuantity
        maxTotalQuantity
        minQuantity
        minTotalQuantity
        modifierIds
        parentId
        sortOrder
        spokenName
        unavailableHandoffModes
        version
        availabilityStart
        availabilityEnd
        availabilitySchedule
        upsellPhrase
        upsellPriority
        isUpsellTarget
        linkedSpecialItemId
        partOfSpeech
        preposition
        quantityInterval
        soundboardEntries
      }
    }
  }
}
    `;

/**
 * __useGetItemsFromCategoryV2Query__
 *
 * To run a query within a React component, call `useGetItemsFromCategoryV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsFromCategoryV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsFromCategoryV2Query({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetItemsFromCategoryV2Query(baseOptions: Apollo.QueryHookOptions<GetItemsFromCategoryV2Query, GetItemsFromCategoryV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsFromCategoryV2Query, GetItemsFromCategoryV2QueryVariables>(GetItemsFromCategoryV2Document, options);
      }
export function useGetItemsFromCategoryV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsFromCategoryV2Query, GetItemsFromCategoryV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsFromCategoryV2Query, GetItemsFromCategoryV2QueryVariables>(GetItemsFromCategoryV2Document, options);
        }
export type GetItemsFromCategoryV2QueryHookResult = ReturnType<typeof useGetItemsFromCategoryV2Query>;
export type GetItemsFromCategoryV2LazyQueryHookResult = ReturnType<typeof useGetItemsFromCategoryV2LazyQuery>;
export type GetItemsFromCategoryV2QueryResult = Apollo.QueryResult<GetItemsFromCategoryV2Query, GetItemsFromCategoryV2QueryVariables>;
export const GetModifiersFromParentV2Document = gql`
    query GetModifiersFromParentV2($storeId: String!, $parentId: String) {
  modifiers(storeId: $storeId, parentId: $parentId) {
    edges {
      node {
        availabilityEnd
        availabilitySchedule
        availabilityStart
        chainId
        description
        displayMode
        id
        maxOptions
        maxQuantity
        maxQuantityPerOption
        minOptions
        minQuantity
        minQuantityPerOption
        name
        parentId
        quantityInterval
        isSelectionRequired
        isPromptRequired
        sortOrder
        spokenName
        supportOptionQuantity
        customWhich
        version
      }
    }
  }
}
    `;

/**
 * __useGetModifiersFromParentV2Query__
 *
 * To run a query within a React component, call `useGetModifiersFromParentV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetModifiersFromParentV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModifiersFromParentV2Query({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetModifiersFromParentV2Query(baseOptions: Apollo.QueryHookOptions<GetModifiersFromParentV2Query, GetModifiersFromParentV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModifiersFromParentV2Query, GetModifiersFromParentV2QueryVariables>(GetModifiersFromParentV2Document, options);
      }
export function useGetModifiersFromParentV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModifiersFromParentV2Query, GetModifiersFromParentV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModifiersFromParentV2Query, GetModifiersFromParentV2QueryVariables>(GetModifiersFromParentV2Document, options);
        }
export type GetModifiersFromParentV2QueryHookResult = ReturnType<typeof useGetModifiersFromParentV2Query>;
export type GetModifiersFromParentV2LazyQueryHookResult = ReturnType<typeof useGetModifiersFromParentV2LazyQuery>;
export type GetModifiersFromParentV2QueryResult = Apollo.QueryResult<GetModifiersFromParentV2Query, GetModifiersFromParentV2QueryVariables>;
export const GetOptionsFromModifierV2Document = gql`
    query GetOptionsFromModifierV2($storeId: String!, $parentId: String) {
  optionsV2(storeId: $storeId, parentId: $parentId) {
    edges {
      node {
        id
        ancestorIds
        article
        availabilityEnd
        availabilitySchedule
        availabilityStart
        chainId
        cost
        hasStockAvailable
        adjustItemPrice
        isDefault
        isExclusive
        isHidden
        isImplicitItem
        modifierIds
        name
        partOfSpeech
        parentId
        preposition
        shouldRepeatDefaultOptions
        shouldRepeatOptionRemoval
        shouldPluralize
        sortOrder
        spokenName
        type
        version
      }
    }
  }
}
    `;

/**
 * __useGetOptionsFromModifierV2Query__
 *
 * To run a query within a React component, call `useGetOptionsFromModifierV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionsFromModifierV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionsFromModifierV2Query({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useGetOptionsFromModifierV2Query(baseOptions: Apollo.QueryHookOptions<GetOptionsFromModifierV2Query, GetOptionsFromModifierV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionsFromModifierV2Query, GetOptionsFromModifierV2QueryVariables>(GetOptionsFromModifierV2Document, options);
      }
export function useGetOptionsFromModifierV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionsFromModifierV2Query, GetOptionsFromModifierV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionsFromModifierV2Query, GetOptionsFromModifierV2QueryVariables>(GetOptionsFromModifierV2Document, options);
        }
export type GetOptionsFromModifierV2QueryHookResult = ReturnType<typeof useGetOptionsFromModifierV2Query>;
export type GetOptionsFromModifierV2LazyQueryHookResult = ReturnType<typeof useGetOptionsFromModifierV2LazyQuery>;
export type GetOptionsFromModifierV2QueryResult = Apollo.QueryResult<GetOptionsFromModifierV2Query, GetOptionsFromModifierV2QueryVariables>;
export const GetUpsellsDocument = gql`
    query GetUpsells($storeId: String!) {
  itemsV2(storeId: $storeId, isUpsellTarget: true) {
    edges {
      node {
        id
        name
        chainId
        description
        cost
        hasStockAvailable
        maxQuantity
        maxTotalQuantity
        minQuantity
        minTotalQuantity
        modifierIds
        parentId
        sortOrder
        spokenName
        unavailableHandoffModes
        version
        availabilityStart
        availabilityEnd
        availabilitySchedule
        upsellPhrase
        upsellPriority
        isUpsellTarget
        linkedSpecialItemId
        partOfSpeech
        preposition
        quantityInterval
        soundboardEntries
      }
    }
  }
  categoriesV2(storeId: $storeId, isUpsellTarget: true) {
    edges {
      node {
        id
        name
        chainId
        description
        spokenName
        customWhich
        sortOrder
        version
        upsellPhrase
        upsellPriority
        isUpsellTarget
        hasItemsAvailable
        soundboardEntries
      }
    }
  }
}
    `;

/**
 * __useGetUpsellsQuery__
 *
 * To run a query within a React component, call `useGetUpsellsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpsellsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpsellsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetUpsellsQuery(baseOptions: Apollo.QueryHookOptions<GetUpsellsQuery, GetUpsellsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpsellsQuery, GetUpsellsQueryVariables>(GetUpsellsDocument, options);
      }
export function useGetUpsellsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpsellsQuery, GetUpsellsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpsellsQuery, GetUpsellsQueryVariables>(GetUpsellsDocument, options);
        }
export type GetUpsellsQueryHookResult = ReturnType<typeof useGetUpsellsQuery>;
export type GetUpsellsLazyQueryHookResult = ReturnType<typeof useGetUpsellsLazyQuery>;
export type GetUpsellsQueryResult = Apollo.QueryResult<GetUpsellsQuery, GetUpsellsQueryVariables>;
export const SearchItemsDocument = gql`
    query SearchItems($storeId: String!, $name: String) {
  itemsV2(storeId: $storeId, name: $name) {
    edges {
      node {
        id
        name
        chainId
        description
        cost
        hasStockAvailable
        maxQuantity
        maxTotalQuantity
        minQuantity
        minTotalQuantity
        modifierIds
        parentId
        sortOrder
        spokenName
        unavailableHandoffModes
        version
        availabilityStart
        availabilityEnd
        availabilitySchedule
        upsellPhrase
        upsellPriority
        isUpsellTarget
        linkedSpecialItemId
        partOfSpeech
        preposition
        quantityInterval
        soundboardEntries
      }
    }
  }
}
    `;

/**
 * __useSearchItemsQuery__
 *
 * To run a query within a React component, call `useSearchItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchItemsQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchItemsQuery(baseOptions: Apollo.QueryHookOptions<SearchItemsQuery, SearchItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchItemsQuery, SearchItemsQueryVariables>(SearchItemsDocument, options);
      }
export function useSearchItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchItemsQuery, SearchItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchItemsQuery, SearchItemsQueryVariables>(SearchItemsDocument, options);
        }
export type SearchItemsQueryHookResult = ReturnType<typeof useSearchItemsQuery>;
export type SearchItemsLazyQueryHookResult = ReturnType<typeof useSearchItemsLazyQuery>;
export type SearchItemsQueryResult = Apollo.QueryResult<SearchItemsQuery, SearchItemsQueryVariables>;
export const GetSoundboardPromptsDocument = gql`
    query GetSoundboardPrompts($platformName: String!, $brandId: String!, $storeId: String!) {
  store(platformName: $platformName, brandId: $brandId, storeId: $storeId) {
    edges {
      node {
        metadata
      }
    }
  }
}
    `;

/**
 * __useGetSoundboardPromptsQuery__
 *
 * To run a query within a React component, call `useGetSoundboardPromptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoundboardPromptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoundboardPromptsQuery({
 *   variables: {
 *      platformName: // value for 'platformName'
 *      brandId: // value for 'brandId'
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useGetSoundboardPromptsQuery(baseOptions: Apollo.QueryHookOptions<GetSoundboardPromptsQuery, GetSoundboardPromptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoundboardPromptsQuery, GetSoundboardPromptsQueryVariables>(GetSoundboardPromptsDocument, options);
      }
export function useGetSoundboardPromptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoundboardPromptsQuery, GetSoundboardPromptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoundboardPromptsQuery, GetSoundboardPromptsQueryVariables>(GetSoundboardPromptsDocument, options);
        }
export type GetSoundboardPromptsQueryHookResult = ReturnType<typeof useGetSoundboardPromptsQuery>;
export type GetSoundboardPromptsLazyQueryHookResult = ReturnType<typeof useGetSoundboardPromptsLazyQuery>;
export type GetSoundboardPromptsQueryResult = Apollo.QueryResult<GetSoundboardPromptsQuery, GetSoundboardPromptsQueryVariables>;