export function formatList(arr: string[]) {
  const lastIdx = arr.length - 1;

  if (arr.length > 1) {
    arr.splice(lastIdx, 1, `and ${arr[lastIdx]}`);

    return arr.join(', ').trim();
  }

  return arr;
}

export function formatListNoComma(arr: string[]) {
  const lastIdx = arr.length - 1;

  if (arr.length > 1) {
    arr.splice(lastIdx, 1, `and ${arr[lastIdx]}`);

    return arr.join(' ').trim();
  }

  return arr;
}
