import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialCartState, cartItemsAdapter } from './state';
import * as cartActions from './actions';
import * as utils from './utils';

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    resetCart: () => initialCartState,
    setCurrentCartItem: (state, action: PayloadAction<string | null>) => {
      state.cartItems.current = action.payload;
    },
    setIsValid: (state, action: PayloadAction<boolean>) => {
      state.validation.isValid = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cartActions.addCartItems.pending, (state) => {
      state.cartItems.isAddingCartItem = true;
      state.cartItems.hasError = false;
    });
    builder.addCase(cartActions.addCartItems.fulfilled, (state, action) => {
      state.cartItems.isAddingCartItem = false;
      state.cartItems.hasError = false;

      if (!action.payload) {
        return state;
      }

      const {
        data: { items, validation },
        selectionData,
      } = action.payload;

      const cartItems = utils.tranformCartItems(items, selectionData);

      cartItemsAdapter.addMany(state.cartItems, cartItems);
      state.cartItems.history = [
        ...state.cartItems.history,
        ...utils.transformToHistoryItems(cartItems),
      ];

      if (validation) {
        state.validation = {
          ...validation,
          errorsByCartItemId: utils.getErrorsByCartItemId(validation.errors),
        };
      }
    });
    builder.addCase(cartActions.addCartItems.rejected, (state) => {
      state.cartItems.isAddingCartItem = false;
      state.cartItems.hasError = true;
    });

    builder.addCase(cartActions.removeCartItem.pending, (state, action) => {
      state.cartItems.isRemovingCartItem = true;
      state.cartItems.hasError = false;

      if (state.cartItems.entities[action.meta.arg]) {
        state.cartItems.entities[action.meta.arg]!.hasOngoingAction = true;
      }
    });
    builder.addCase(cartActions.removeCartItem.fulfilled, (state, action) => {
      state.cartItems.isRemovingCartItem = false;
      state.cartItems.hasError = false;

      if (!action.payload) {
        return state;
      }

      const { cartItemId, data } = action.payload;

      if (state.cartItems.entities[cartItemId] !== undefined) {
        state.cartItems.entities[cartItemId]!.hasOngoingAction = false;
      }

      cartItemsAdapter.removeOne(state.cartItems, cartItemId);

      if (data.validation) {
        state.validation = {
          ...data.validation,
          errorsByCartItemId: utils.getErrorsByCartItemId(
            data.validation.errors,
          ),
        };
      }
    });
    builder.addCase(cartActions.removeCartItem.rejected, (state, action) => {
      state.cartItems.isRemovingCartItem = false;
      state.cartItems.hasError = true;

      if (state.cartItems.entities[action.meta.arg]) {
        state.cartItems.entities[action.meta.arg]!.hasOngoingAction = false;
      }
    });

    builder.addCase(cartActions.updateCartItem.pending, (state, action) => {
      state.cartItems.isUpdatingCartItem = true;
      state.cartItems.hasError = false;

      if (state.cartItems.entities[action.meta.arg.cartItemId]) {
        state.cartItems.entities[action.meta.arg.cartItemId]!.hasOngoingAction =
          true;
      }
    });
    builder.addCase(cartActions.updateCartItem.fulfilled, (state, action) => {
      state.cartItems.isUpdatingCartItem = false;
      state.cartItems.hasError = false;

      if (!action.payload) {
        return state;
      }

      const { item: updatedCartItem, validation } = action.payload;
      const cartItem = state.cartItems.entities[updatedCartItem.id];

      if (cartItem) {
        cartItem.hasOngoingAction = false;
      }

      cartItemsAdapter.updateOne(state.cartItems, {
        id: updatedCartItem.id,
        changes: utils.getUpdateChanges(updatedCartItem, cartItem),
      });

      if (validation) {
        state.validation = {
          ...validation,
          errorsByCartItemId: utils.getErrorsByCartItemId(validation.errors),
        };
      }
    });
    builder.addCase(cartActions.updateCartItem.rejected, (state, action) => {
      state.cartItems.isUpdatingCartItem = false;
      state.cartItems.hasError = true;

      if (state.cartItems.entities[action.meta.arg.cartItemId]) {
        state.cartItems.entities[action.meta.arg.cartItemId]!.hasOngoingAction =
          false;
      }
    });

    builder.addCase(cartActions.fetchPrices.pending, (state) => {
      state.pricing.isFetchingPrices = true;
      state.pricing.hasPricingError = false;
    });
    builder.addCase(cartActions.fetchPrices.fulfilled, (state, action) => {
      const { pricing, validation, preparationTimeRange } = action.payload;

      if (pricing) {
        state.pricing = {
          ...pricing,
          isFetchingPrices: false,
          hasPricingError: false,
        };
      }

      if (validation) {
        state.validation = {
          ...validation,
          errorsByCartItemId: utils.getErrorsByCartItemId(validation.errors),
        };
      }

      if (preparationTimeRange) {
        state.preparationTimeRange = {
          ...preparationTimeRange,
        };
      }
    });
    builder.addCase(cartActions.fetchPrices.rejected, (state, action) => {
      state.pricing.isFetchingPrices = false;
      state.pricing.hasPricingError = true;

      if (action.payload) {
        const { validation } = action.payload;
        state.validation = {
          ...validation,
          errorsByCartItemId: utils.getErrorsByCartItemId(validation.errors),
        };
      }
    });

    builder.addCase(cartActions.fetchPreexistingCart.pending, (state) => {
      state.isFetchingPreexistingCart = true;
      state.hasPreexistingCartError = false;
    });
    builder.addCase(
      cartActions.fetchPreexistingCart.fulfilled,
      (state, action) => {
        state.isFetchingPreexistingCart = false;

        if (!action.payload) {
          state.hasPreexistingCart = false;
          return state;
        }

        state.hasPreexistingCart = true;

        cartItemsAdapter.addMany(
          state.cartItems,
          utils.tranformCartItems(action.payload),
        );
      },
    );
    builder.addCase(cartActions.fetchPreexistingCart.rejected, (state) => {
      state.isFetchingPreexistingCart = false;
      state.hasPreexistingCartError = true;
    });

    builder.addCase(
      cartActions.setCouponValidationStatus.fulfilled,
      (state, action) => {
        state.validation = {
          ...action.payload,
          errorsByCartItemId: utils.getErrorsByCartItemId(
            action.payload.errors,
          ),
        };
      },
    );
  },
});

export const { actions } = cartSlice;

export default cartSlice.reducer;
