import { Typography, theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const BreadcrumbsContainer = styled.section`
  span {
    cursor: pointer;
    transition: 0.2s all;

    &:hover {
      color: ${theme.colors.gray_dark};
    }
  }
`;

export const Separator = styled(Typography).attrs({
  variant: 'caption',
  color: 'gray_darker',
  children: '>',
})`
  margin: 0 5px;
  cursor: default !important;
`;
