import * as notifications from '@modules/notifications/api';

export function clockInError(error) {
  notifications.error({
    title: 'Unable to clock in',
    message: 'Something unexpected happened while clocking in',
    error,
  });
}

export function clockOutError(error) {
  notifications.error({
    title: 'Unable to clock in',
    message: 'Something unexpected happened while clocking out',
    error,
  });
}
