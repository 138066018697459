import React from 'react';

import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';
import { MdNotInterested, MdVolumeUp } from 'react-icons/md';
import { IoMdRefreshCircle } from 'react-icons/io';

import { Option } from '../styles';

export const UnavailableOption = styled(Option)`
  gap: 0.25rem;
`;

export const NotAvailable = styled(MdNotInterested).attrs({
  color: theme.colors.error,
  size: 16,
})``;

export const Sound = styled(MdVolumeUp).attrs({
  color: theme.colors.primary,
  size: 16,
})``;

export const Reload = styled(IoMdRefreshCircle).attrs({
  color: theme.colors.error,
  size: 18,
})<{ onClick: (evt: React.MouseEvent) => void }>`
  z-index: 1;
  margin-left: auto;
`;
