import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import AnnounceBadge from '../AnnounceBadge';

export function LoyaltyPointsBadge() {
  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const message = 'Your points will be added to your account within 24 hours';

  const handleClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  return (
    <AnnounceBadge onClick={handleClick} disabled={false} message={message}>
      Should be considered for loyalty points?
    </AnnounceBadge>
  );
}
