import { createSlice } from '@reduxjs/toolkit';
import {
  FeatureFlagsBrand,
  FeatureFlagsStore,
  FeatureFlagsUser,
} from 'src/@types/featureFlags';

import * as asyncActions from './actions';

export type FeatureFlagsState = {
  user: Partial<FeatureFlagsUser>;
  store: Partial<FeatureFlagsStore>;
  brand: Partial<FeatureFlagsBrand>;
  delayedUser: boolean[];
  delayedStore: boolean[];
  delayedBrand: boolean[];
  fetching: { user: boolean; store: boolean; brand: boolean };
};

export const initialState: FeatureFlagsState = {
  user: {},
  store: {},
  brand: {},
  delayedUser: [],
  delayedStore: [],
  delayedBrand: [],
  fetching: { brand: false, store: false, user: false },
};

const delayedUserFlags: (keyof FeatureFlagsUser)[] = ['usePaybotPrototype'];
const delayedStoreFlags: (keyof FeatureFlagsStore)[] = [];
const delayedBrandFlags: (keyof FeatureFlagsBrand)[] = [];

export const slice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    resetFeatureFlags: () => initialState,
    setDelayedUserFeatureFlags: (state) => {
      state.user = {
        ...state.user,
        ...state.delayedUser,
      };
      state.delayedUser = [];
    },
    setDelayedStoreFeatureFlags: (state) => {
      state.store = {
        ...state.store,
        ...state.delayedStore,
      };
      state.delayedStore = [];
    },
    setDelayedBrandFeatureFlags: (state) => {
      state.brand = {
        ...state.brand,
        ...state.delayedBrand,
      };
      state.delayedBrand = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getUserFeatureFlags.fulfilled,
      (state, action) => {
        const userFlags = { ...action.payload };
        delayedUserFlags.forEach((userFlag) => delete userFlags[userFlag]);
        state.user = userFlags;
        const delayedUser = delayedUserFlags.map(
          (userFlag) => userFlags[userFlag],
        );
        state.delayedUser = delayedUser;
        state.fetching.user = false;
      },
    );
    builder.addCase(
      asyncActions.getStoreFeatureFlags.fulfilled,
      (state, action) => {
        const storeFlags = { ...action.payload };
        delayedStoreFlags.forEach((userFlag) => delete storeFlags[userFlag]);
        state.store = storeFlags;
        state.delayedStore = delayedStoreFlags.map(
          (storeFlag) => storeFlags[storeFlag],
        );
        state.fetching.store = false;
      },
    );
    builder.addCase(
      asyncActions.getBrandFeatureFlags.fulfilled,
      (state, action) => {
        const brandFlags = { ...action.payload };
        delayedBrandFlags.forEach((userFlag) => delete brandFlags[userFlag]);
        state.brand = brandFlags;
        state.delayedBrand = delayedBrandFlags.map(
          (brandFlag) => brandFlags[brandFlag],
        );
        state.fetching.brand = false;
      },
    );
    builder.addCase(asyncActions.getUserFeatureFlags.pending, (state) => {
      state.fetching.user = true;
    });
    builder.addCase(asyncActions.getStoreFeatureFlags.pending, (state) => {
      state.fetching.store = true;
    });
    builder.addCase(asyncActions.getBrandFeatureFlags.pending, (state) => {
      state.fetching.brand = true;
    });
    builder.addCase(asyncActions.getUserFeatureFlags.rejected, (state) => {
      state.fetching.user = false;
    });
    builder.addCase(asyncActions.getStoreFeatureFlags.rejected, (state) => {
      state.fetching.store = false;
    });
    builder.addCase(asyncActions.getBrandFeatureFlags.rejected, (state) => {
      state.fetching.brand = false;
    });
  },
});

const { actions: sliceActions, reducer } = slice;
export { reducer };

export const actions = { ...sliceActions, ...asyncActions };
