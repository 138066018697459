import { types } from './types';

export function clockFetchingClockStatus() {
  return {
    type: types.CLOCK_FETCHING_CLOCK_STATUS,
  };
}

export function clockFetchedClockStatus(target, payload) {
  return {
    target,
    type: types.CLOCK_FETCHED_CLOCK_STATUS,
    payload,
  };
}

export function clockNotFetchedClockStatus() {
  return {
    type: types.CLOCK_NOT_FETCHED_CLOCK_STATUS,
  };
}

export function clockClockingIn() {
  return {
    type: types.CLOCK_CLOCKING_IN,
  };
}

export function clockClockedIn(payload, target) {
  return {
    target,
    type: types.CLOCK_CLOCKED_IN,
    payload,
  };
}

export function clockNotClockedIn() {
  return {
    type: types.CLOCK_NOT_CLOCKED_IN,
  };
}

export function clockClockingOut() {
  return {
    type: types.CLOCK_CLOCKING_OUT,
  };
}

export function clockClockedOut(target, payload) {
  return {
    target,
    type: types.CLOCK_CLOCKED_OUT,
    payload,
  };
}

export function clockNotClockedOut() {
  return {
    type: types.CLOCK_NOT_CLOCKED_OUT,
  };
}

export function clockReset() {
  return {
    type: types.CLOCK_RESET,
  };
}
