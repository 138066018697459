export function removeHexCodes(description = '', codes: string[] = []) {
  let sentence = description;
  const hexCodesToRemove = ['&#xA;', '&#xD;', ...codes];

  hexCodesToRemove.forEach((code) => {
    sentence = sentence.replace(new RegExp(`${code}`, 'gi'), '');
  });

  return sentence.trim();
}
