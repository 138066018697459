import axios from 'axios';
import env from '@beam-australia/react-env';

import * as authSelectors from '@modules/auth/selectors';

import { addLoggerInterceptor, addTokenInterceptor } from './interceptors';
import { Client, RequestConfig } from './types';

export const formatBaseUrl = () => {
  const host = env('DELIVERY_SERVICE');
  const hostWithVersion = `${host}${host.endsWith('/') ? '' : '/'}`;
  return hostWithVersion;
};

const baseURL = formatBaseUrl();

export const api = axios.create({
  baseURL,
});

addLoggerInterceptor(api);
addTokenInterceptor(api);

const formatToken = () => {
  const token = authSelectors.getToken();
  return `Bearer ${token}`;
};

const removeVersion = (options: RequestConfig) => {
  delete options.version;
  return options;
};

const client: Client = () => ({
  get: (url, options = { version: 'v1' }) =>
    api.get(`${options.version}${url}`, {
      ...removeVersion(options),
    }),

  patch: (url, payload = {}, options = { version: 'v1' }) =>
    api.patch(`${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  post: (url, payload = {}, options = { version: 'v1' }) =>
    api.post(`${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  put: (url, payload = {}, options = { version: 'v1' }) =>
    api.put(`${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  delete: (url, options = { version: 'v1' }) =>
    api.delete(`${options.version}${url}`, {
      ...removeVersion(options),
    }),
  getEvent: (url, options = { version: 'v1' }) =>
    new EventSource(
      `${formatBaseUrl()}${options.version}${url}?token=${formatToken()}`,
    ),
});

export default client();
