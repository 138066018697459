import { MenuAvailability, MenuEntity } from '@kea-inc/types';
import { DateTime, Interval } from 'luxon';

export default function isInAvailabilityRange(
  entity: MenuAvailability,
  timezone?: string,
) {
  const currentTime = DateTime.now().setZone(timezone);

  if (entity.availabilityStart) {
    const start = DateTime.fromISO(entity.availabilityStart);

    if (currentTime < start) {
      return false;
    }
  }

  if (entity.availabilityEnd) {
    const end = DateTime.fromISO(entity.availabilityEnd);

    if (currentTime > end) {
      return false;
    }
  }

  const day = currentTime
    .toFormat('cccc')
    .toLowerCase() as keyof MenuEntity['availabilitySchedule'];

  const schedule = entity.availabilitySchedule[day];

  if (!schedule) {
    return false;
  }

  return schedule?.some(({ start, end }) => {
    const [startHour, startMinute] = start.split(':').map(Number);
    const [endHour, endMinute] = end.split(':').map(Number);

    const startDateTime = currentTime.set({
      hour: startHour,
      minute: startMinute,
      second: 0,
      millisecond: 0,
    });

    const endDateTime = currentTime.set({
      hour: endHour,
      minute: endMinute,
      second: 0,
      millisecond: 0,
    });

    const interval = Interval.fromDateTimes(startDateTime, endDateTime);
    return interval.contains(currentTime);
  });
}
