import * as notifications from '@modules/notifications/api';

export function submitError(error) {
  let message = 'Unable to submit your order.';
  if (error.response) {
    message = error.response.data.error
      .replace('Submit Order Error:', '')
      .trim();
  }
  notifications.error({
    title: 'Cannot submit order',
    message,
    error,
  });
}

const deliveryErrorMessages = {
  UNKNOWN: {
    title: 'Unknown error',
    message: 'An unknown error has occurred. Please try again later.',
  },
  DELIVERY_FEE_CHANGED: {
    title: 'Delivery fee changed',
    message: 'The delivery fee has changed. Please review before proceeding.',
  },
  READY_TIME_CHANGED: {
    title: 'Ready time changed',
    message:
      'The estimated ready time has been modified. Please check the new time.',
  },
  DELIVERY_UNAVAILABLE: {
    title: 'Delivery unavailable',
    message: 'Sorry! Delivery is currently unavailable for your location.',
  },
  INSUFFICIENT_MINIMUM_DELIVERY_TOTAL: {
    title: 'Insufficient order total',
    message:
      'Order total does not meet the minimum for delivery. Consider adding more items.',
  },
  EXCEEDED_MAXIMUM_DELIVERY_TOTAL: {
    title: 'Exceeded order total',
    message:
      'Order total exceeds the maximum for delivery. Please reduce the quantity or value.',
  },
};

export function validateError(error) {
  const errorCode = error.response?.data?.data?.errorCode;
  const deliveryErrorMessage = deliveryErrorMessages[errorCode];

  if (!deliveryErrorMessage) {
    const message = error.response.data.error
      .replace('Validate Order Error:', '')
      .trim();

    notifications.error({
      title: 'Invalid order',
      message,
      error,
    });

    return;
  }

  notifications.error({
    title: deliveryErrorMessage.title,
    message: deliveryErrorMessage.message,
    error,
  });
}

export function capacityThrottledError() {
  notifications.info({
    title: 'Order capacity reached',
    message: 'The store is experiencing high order volumes.',
  });
}
