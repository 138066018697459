import { getSpokenName } from '@kea-inc/order';
import pluralize from 'pluralize';

export function makeEntityUnavailablePhrase(entity: {
  name: string;
  description?: string;
  spokenName?: string;
}) {
  const spokenName = entity.spokenName ?? getSpokenName(entity);

  if (!spokenName) {
    return `${entity.name} is currently unavailable.`;
  }

  const isNameInPlural = pluralize.isPlural(spokenName);
  const noun = pluralize('is', isNameInPlural ? 2 : 1);

  return `${spokenName} ${noun} currently unavailable.`;
}
