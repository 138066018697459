import styled from 'styled-components';
import { Button as parrotButton, theme } from '@kea-inc/parrot-ui';
import { AllColors } from '@kea-inc/parrot-ui/dist/types';

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 12px 64px 12px 0;

  svg {
    font-size: 28px;
    margin-right: 12px;
  }
`;

export const Step = styled.section`
  margin-bottom: 12px;

  &.order-id {
    cursor: pointer;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StatusButtons = styled.div`
  margin-top: auto;
  text-align: center;
`;

export const FooterButtons = styled.div`
  margin-top: auto;
  text-align: center;
  button {
    background: ${theme.colors.success_dark};
  }
  button:hover {
    background: ${theme.colors.success_light};
  }
`;

export const StatusButton = styled(parrotButton)`
  width: 100%;
  color: white;
  margin-top: 0.5rem;
  background: ${(props) =>
    props.color && theme.colors[props.color as AllColors]};
  :hover {
    background: ${(props) =>
      props.color && theme.colors[props.color as AllColors]};
    filter: brightness(0.95) opacity(0.45);
  }
`;
