import { useSelector } from 'react-redux';

export const useAccount = () => {
  const errors = useSelector(({ account }) => account.errors);
  const pending = useSelector(({ account }) => account.pending);
  const accountDetails = useSelector(({ account }) => account.accountDetails);
  const previousOrderStatus = useSelector(
    ({ account }) => account.previousOrderStatus,
  );
  const id = useSelector(({ account }) => account.id);
  const isPreviousOrderMenuOpen = useSelector(
    ({ account }) => account.isPreviousOrderMenuOpen,
  );
  const previousOrder = useSelector(({ account }) => account.previousOrder);

  const isPending = Object.values(pending).some(Boolean);

  return {
    errors,
    pending,
    accountDetails,
    id,
    isPending,
    isPreviousOrderMenuOpen,
    previousOrder,
    previousOrderStatus,
  };
};
