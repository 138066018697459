import { AxiosInstance } from 'axios';

export function addURLEncoderInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config) => {
      if (!config.headers) {
        config.headers = {};
      }

      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=utf-8';
      return config;
    },
    (error) => Promise.reject(error),
    { synchronous: true },
  );
}
