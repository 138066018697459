import { ORDER_CHECKLIST } from '@modules/order/state';
import * as orderAPI from '@modules/order/api';

import { getServiceInstance } from '@utils/getServiceForAccountIntegration';
import snakecaseKeys from 'snakecase-keys';

import * as selectors from '../selectors';
import { Account } from '../types';

export async function upsertAccount() {
  const details = selectors.getAccountDetails();
  const response = await getServiceInstance().put('/accounts', details);
  return response.data;
}

export async function updateAccount({
  useOrderService,
  useAccountService,
}: {
  useOrderService?: boolean;
  useAccountService?: boolean;
}) {
  // TODO - Adding this because OrderService don't have PATCH API. Need to remove this workaround

  const details = selectors.getAccountDetails();

  if (useOrderService && !useAccountService) {
    return upsertAccount();
  }

  const payload = {
    firstName: details.firstName,
    lastName: details.lastName,
  };
  const id = selectors.getAccountId();
  const response = await getServiceInstance().patch(`/accounts/${id}`, payload);
  return response.data;
}

export async function fetchAccountById(id: string) {
  const response = await getServiceInstance().get(`/accounts/${id}`);
  return handleChecklistUpdate(snakecaseKeys(response.data));
}

export async function handleChecklistUpdate(account: Account) {
  const allFilled = Object.values(account).every(Boolean);
  orderAPI.setChecklist(ORDER_CHECKLIST.ARE_FIELDS_FILLED, allFilled);
  return account;
}
