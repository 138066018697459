import { useEffect, useMemo, useRef } from 'react';
import { Badge } from '@kea-inc/parrot-ui';
import { v4 as uuid } from 'uuid';

import { useAppDispatch, useAppSelector } from '@store';
import { useAccount, useDevice, useOrder } from '@hooks';

import { ORDER_CHECKLIST } from '@modules/order/state';
import * as orderAPI from '@modules/order/api';
import { types } from '@modules/order/types';
import { announce } from '@modules/device/device-actions';
import { getStoreTimezone } from '@modules/taskrouter/selectors';
import { fetchPrices } from '@modules/cart/actions';
import * as cartSelectors from '@modules/cart/selectors';
import { getOrderThrottlingMessage, makeConfirmationMessage } from '@utils';

import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import * as S from '../styles';
import { ChecklistItem } from './ChecklistItem';

export const HasConfirmedOrder = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const { useOrderService } = useAppSelector(getStoreFeatureFlags);
  const { checklist, isThrottling, orderDetails, pending } = useOrder();
  const { accountDetails } = useAccount();
  const hasConfirmedOnce = useRef(false);
  const id = useMemo(() => uuid(), []);
  const { announcementId } = useDevice();
  const isAnnouncing = announcementId === id;
  const isLoading =
    pending.isValidatingBasket || pending.isFetchingAvailableTimes;
  const cartItems = useAppSelector((state) =>
    cartSelectors.selectAllCartItems(state.cart.cartItems),
  );
  const total = useAppSelector(cartSelectors.selectTotal);
  const validation = useAppSelector((state) => state.cart.validation);

  const handleClick = async () => {
    if (isThrottling && !total && !useOrderService) {
      await handleThrottling();
    } else {
      handleAnnouncement();
    }
  };

  const storeTimezone = useAppSelector(getStoreTimezone);
  const handleThrottling = async () => {
    // USING THE RESPONSE INSTEAD OF GETTING THE VALUE FROM REDUX TO AVOID A RACE CONDITION
    const availableTimes = await orderAPI.fetchAvailableTimes();

    if (!orderDetails.wasAutoScheduled) {
      const orderThrottlingMessage = getOrderThrottlingMessage(
        availableTimes,
        storeTimezone,
      );

      dispatch(announce({ text: orderThrottlingMessage }));
    } else {
      handleAnnouncement();
    }
  };

  const handleAnnouncement = async () => {
    if (!accountDetails.firstName) {
      dispatch(
        announce({
          text: 'Can I have your first name and last initial?',
          id,
        }),
      );
      return;
    }

    orderAPI.setChecklist(
      ORDER_CHECKLIST.ARE_FIELDS_FILLED,
      !!accountDetails.firstName,
    );

    const res = await dispatch(fetchPrices());

    if (res.payload?.validation.isValid) {
      dispatch(
        announce({
          text: makeConfirmationMessage({
            firstName: accountDetails.firstName,
            cartItems,
          }),
          id,
        }),
      );

      orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ORDER, true);
      hasConfirmedOnce.current = true;
    } else {
      const errors = res.payload?.validation.errors;
      if (errors?.some((e) => e.code === 'CAPACITY_THROTTLED')) {
        dispatch({ type: types.SET_ORDER_THROTTLING, payload: true });
        await handleThrottling();
      }
    }
  };

  useEffect(() => {
    if (!accountDetails.firstName) {
      orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ORDER, false);
      return;
    }

    if (hasConfirmedOnce.current) {
      orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ORDER, true);
    } else {
      orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ORDER, false);
    }
  }, [accountDetails.firstName]);

  return (
    <ChecklistItem field={ORDER_CHECKLIST.HAS_CONFIRMED_ORDER}>
      <Badge
        onClick={isAnnouncing ? undefined : handleClick}
        variant={isAnnouncing ? 'gray' : 'primary'}
        disabled={
          useOrderService
            ? !checklist.cartHasItems || !validation.isValid
            : !checklist.cartHasItems
        }
        {...props}
      >
        Confirm Order
      </Badge>
      {isLoading && <S.Loading />}
    </ChecklistItem>
  );
};
