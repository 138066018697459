import { Typography } from '@kea-inc/parrot-ui';

import * as S from './styles';

export function CompletedMark() {
  return (
    <S.CompletedContainer data-testid="completed-mark">
      <S.CompletedIcon />
      <Typography variant="caption" color="success">
        Completed
      </Typography>
    </S.CompletedContainer>
  );
}
