import { GetModifiersFromParentV2Query } from 'src/graphql/generated/schema';
import { Modifier } from '../types';

type APIModifier =
  GetModifiersFromParentV2Query['modifiers']['edges'][0]['node'];

export function transformAPIModifier(apiModifier: APIModifier): Modifier {
  return {
    id: apiModifier.id,
    availabilityEnd: apiModifier.availabilityEnd ?? null,
    availabilitySchedule: apiModifier.availabilitySchedule ?? null,
    availabilityStart: apiModifier.availabilityStart ?? null,
    chainId: apiModifier.chainId ?? null,
    customWhich: apiModifier.customWhich ?? null,
    description: apiModifier.description!,
    displayMode: apiModifier.displayMode ?? 'NESTED',
    maxOptions: apiModifier.maxOptions ?? null,
    maxQuantity: apiModifier.maxQuantity ?? null,
    maxQuantityPerOption: apiModifier.maxQuantityPerOption ?? null,
    minOptions: apiModifier.minOptions ?? null,
    minQuantity: apiModifier.minQuantity ?? null,
    minQuantityPerOption: apiModifier.minQuantityPerOption ?? null,
    name: apiModifier.name ?? apiModifier.description!,
    parentId: apiModifier.parentId,
    quantityInterval: apiModifier.quantityInterval ?? null,
    required: apiModifier.isSelectionRequired ?? false,
    sortOrder: apiModifier.sortOrder ?? 0,
    spokenName:
      apiModifier.spokenName ?? apiModifier.name ?? apiModifier.description!,
    status: 'idle',
    supportOptionQuantity: apiModifier.supportOptionQuantity ?? false,
    isPromptRequired: apiModifier.isPromptRequired ?? false,
    version: apiModifier.version ?? null,
  };
}
