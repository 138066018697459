import { DateTime } from 'luxon';
import { Interval } from '../types';

export function getIntervals(params: {
  firstAvailableTime: string;
  storeClosingTime: DateTime;
  storeTimezone: string;
  intervalInMinutes: number;
}): Interval[] {
  const {
    storeClosingTime,
    storeTimezone,
    intervalInMinutes,
    firstAvailableTime,
  } = params;

  const intervals: Interval[] = [];

  const storeNow = DateTime.now().setZone(storeTimezone);
  let currentInterval =
    DateTime.fromISO(firstAvailableTime).setZone(storeTimezone);

  while (currentInterval < storeClosingTime) {
    const diffToNow = currentInterval.diff(storeNow, ['hours', 'minutes']);

    diffToNow.plus({ minutes: 1 });

    intervals.push({
      formattedTime: currentInterval.toFormat('h:mm a'),
      iso: currentInterval.toISO(),
      diffToNow: diffToNow.toFormat('hh:mm'),
    });

    currentInterval = currentInterval.plus({ minutes: intervalInMinutes });
  }

  return intervals;
}
