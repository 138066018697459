export const types = {
  STREAM_CONNECTING: '@transcripts/STREAM_CONNECTING',
  STREAM_CONNECTED: '@transcripts/STREAM_CONNECTED',
  STREAM_NOT_CONNECTED: '@transcripts/STREAM_NOT_CONNECTED',
  STREAM_CLOSED: '@transcripts/STREAM_CLOSED',

  TRANSCRIPTS_STREAM_ERROR: '@transcripts/STREAM_ERROR',

  TRANSCRIPTS_ADD_MESSAGE: '@transcripts/ADD_MESSAGE',

  FETCHING_TRANSCRIPTS: '@transcripts/FETCHING_TRANSCRIPTS',
  FETCHED_TRANSCRIPTS: '@transcripts/FETCHED_TRANSCRIPTS',
  NOT_FETCHED_TRANSCRIPTS: '@transcripts/NOT_FETCHED_TRANSCRIPTS',

  TRANSCRIPTS_INTENT_CLASSIFYING: '@transcripts/INTENT_CLASSIFYING',
  TRANSCRIPTS_INTENT_CLASSIFIED: '@transcripts/INTENT_CLASSIFIED',
  TRANSCRIPTS_INTENT_NOT_CLASSIFIED: '@transcripts/INTENT_NOT_CLASSIFIED',

  AUTOCART_EVENT_CREATING: '@transcripts/AUTOCART_EVENT_CREATING',
  AUTOCART_EVENT_CREATED: '@transcripts/AUTOCART_EVENT_CREATED',
  AUTOCART_EVENT_NOT_CREATED: '@transcripts/AUTOCART_EVENT_NOT_CREATED',

  RESET: '@transcripts/RESET',
};
