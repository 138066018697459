import { useAppSelector } from '@store';
import * as cartSelectors from '@modules/cart/selectors';
import * as menuSelectors from '@modules/menu/selectors';
import * as orderAPI from '@modules/order/api';
import { couponActions } from '@modules/coupons/slice';
import { savedCoupon } from '@modules/coupons/selectors';

export function useConnector() {
  const selectors = {
    subtotal: useAppSelector(cartSelectors.selectSubtotal),
    discount: useAppSelector(cartSelectors.selectDiscount),
    taxAndFees: useAppSelector(cartSelectors.selectTaxAndFees),
    total: useAppSelector(cartSelectors.selectTotal),
    taxes: useAppSelector(cartSelectors.selectTaxes),
    isFetchingPrices: useAppSelector(
      (state) => state.cart.pricing.isFetchingPrices,
    ),
    cartItems: useAppSelector((state) =>
      cartSelectors.selectAllCartItems(state.cart.cartItems),
    ),

    savedCoupon: useAppSelector(savedCoupon),
    removedDefaultOptions: useAppSelector(
      menuSelectors.selectRemovedDefaultOptions,
    ),
  };

  const actions = {
    openCouponsOverlay: couponActions.openCouponOverlay,
    openTaxAndFeesOverlay: orderAPI.openTaxAndFeesOverlay,
    openTipsOverlay: orderAPI.openTipsOverlay,
  };

  return {
    selectors,
    actions,
  };
}
