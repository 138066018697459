export const CLOCK_STATUS = {
  CLOCK_IN: 'CLOCK_IN',
  CLOCK_OUT: 'CLOCK_OUT',
};

export const INITIAL_STATE = {
  status: CLOCK_STATUS.CLOCK_OUT,
  id: null,
  datetime: null,
  errors: {
    hasClockStatusError: false,
    hasClockInError: false,
    hasClockOutError: false,
  },
  pending: {
    isFetchingClockStatus: false,
    isClockingIn: false,
    isClockingOut: false,
  },
};
