import { dispatch } from '@store';

import * as taskrouterSelectors from '@modules/taskrouter/selectors';

import * as actions from './actions';
import * as errors from './errors';
import * as utils from './utils';
import * as utilsV2 from './utilsV2';

export function startForNewOrder() {
  const orderId = taskrouterSelectors.deprecatedGetOrderId();
  dispatch(actions.surveyStartForNewOrder(orderId));
}

export function setOrderStatusAsSubmitted() {
  dispatch(actions.surveySetOrderStatusAsSubmitted());
}

export function setOrderStatusAsForwarded() {
  dispatch(actions.surveySetOrderStatusAsForwarded());
}

export function setPrimaryDisposition(payload) {
  dispatch(actions.surveySetPrimaryDisposition(payload));
}

export function setSecondaryDisposition(payload) {
  dispatch(actions.surveySetSecondaryDisposition(payload));
}

export function setComments(payload) {
  dispatch(actions.surveySetComments(payload));
}

export function timeout() {
  dispatch(actions.surveyTimeout());
}

export async function submitSurvey() {
  dispatch(actions.surveySubmitting());

  try {
    await Promise.allSettled([utilsV2.submitSurvey(), utils.submitSurvey()]);
    dispatch(actions.surveySubmitted());
  } catch (error) {
    errors.surveyNotSubmittedError(error);
    dispatch(actions.surveyNotSubmitted());
  }
}
