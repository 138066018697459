import styled, { css } from 'styled-components';

import { theme } from '@kea-inc/parrot-ui';

type ContainerProps = {
  noBorder?: boolean;
  disabled?: boolean;
};

export const Container = styled.div<ContainerProps>`
  max-width: 88px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11.67px 9.67px;
  border-radius: 8px;
  border: ${(props) => (props.noBorder ? 0 : 1)}px solid
    ${theme.colors.gray_light};

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      button {
        cursor: not-allowed;
      }
    `}

  button {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;

    svg {
      font-size: 16px;
      color: ${theme.colors.gray_dark};
    }
  }

  > input {
    border: none;
    width: 1rem;
    font-weight: bold;
    color: ${theme.colors.black_light};
    text-align: center;

    /* stylelint-disable property-no-vendor-prefix */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
