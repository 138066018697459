import React, { useMemo, useEffect } from 'react';
import { Typography } from '@kea-inc/parrot-ui';

import { openChecklist, closeChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import { useOrder } from '@hooks';

import * as Items from './Items';
import * as S from './styles';

const ChecklistItems = {
  [ORDER_CHECKLIST.CART_HAS_ITEMS]: Items.CartNotEmpty,
  [ORDER_CHECKLIST.HAS_UPSOLD_ONCE]: Items.HasUpsoldOnce,
  [ORDER_CHECKLIST.HAS_VALIDATED]: Items.OrderIsValid,
  [ORDER_CHECKLIST.HAS_ASKED_FOR_TIP]: Items.HasAskedForTip,
  [ORDER_CHECKLIST.HAS_CONFIRMED_ORDER]: Items.HasConfirmedOrder,
  [ORDER_CHECKLIST.TABLE_NUMBER_FILLED]: Items.TableNumberFilled,
  [ORDER_CHECKLIST.HAS_CONFIRMED_LOYALTY_POINTS]:
    Items.HasConfirmedLoyaltyPoints,
  [ORDER_CHECKLIST.HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS]:
    Items.HasAskedForDeliveryInstructions,
  [ORDER_CHECKLIST.ADDRESS_IS_VALID]: Items.IsAddressValid,
  [ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS]: Items.HasConfirmedAddress,
};

const checklistDependants = {};

export const Checklist = () => {
  const { isChecklistOpen, canSubmit, checklist } = useOrder();

  const handleOpenClose = () =>
    isChecklistOpen ? closeChecklist() : openChecklist();

  const itemsLeft = useMemo(() => {
    if (canSubmit) {
      return '';
    }

    const totalItems = Object.keys(checklist).filter(
      (key) => !!ChecklistItems[key],
    ).length;

    const completedItems = Object.keys(ChecklistItems).filter((key) => {
      if (checklistDependants[key]) {
        return checklistDependants[key].every((dep) => checklist[dep]);
      }

      return checklist[key];
    }).length;

    return ` (${completedItems}/${totalItems})`;
  }, [canSubmit, checklist]);

  const renderChecklistItemByKey = (key) => {
    const ChecklistItem = ChecklistItems[key];

    if (!ChecklistItem || checklist[key] === undefined) {
      return;
    }

    return <ChecklistItem key={key} />;
  };

  useEffect(() => {
    if (isChecklistOpen) return;
    const container = document.querySelector('.checklist__Container');
    container.scrollTo({ top: 0 });
  }, [isChecklistOpen]);

  return (
    <S.Container isOpen={isChecklistOpen} className="checklist__Container">
      <S.Header>
        <section className="left">
          <Typography weight="heavy">
            Checklist
            {itemsLeft}
          </Typography>
          {canSubmit && <S.Status className="status__all" complete="true" />}
        </section>

        <section className="right" onClick={handleOpenClose}>
          <S.OpenClose isopen={isChecklistOpen ? 1 : 0} />
        </section>
      </S.Header>

      <S.Content>
        {Object.keys(checklist).map((key) => (
          <React.Fragment key={key}>
            {renderChecklistItemByKey(key)}
          </React.Fragment>
        ))}
      </S.Content>
    </S.Container>
  );
};
