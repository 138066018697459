import { useRef } from 'react';

export const useDebounce = (fn, delay) => {
  const timeoutRef = useRef(null);

  function debouncedFn(...args) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return debouncedFn;
};

export const useThrottle = (fn, delay) => {
  const timeoutRef = useRef(null);
  const lastRan = useRef(Date.now());

  function throttledFn(...args) {
    const timeSinceLastRun = Date.now() - lastRan.current;

    if (timeSinceLastRun >= delay) {
      fn(...args);
      lastRan.current = Date.now();
    } else {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        fn(...args);
        lastRan.current = Date.now();
      }, delay - timeSinceLastRun);
    }
  }

  return throttledFn;
};
