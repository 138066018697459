import React from 'react';

import { RandomMessageAnnounce } from './RandomMessageAnnounce';

export const OKBadge = ({ ...props }) => (
  <RandomMessageAnnounce sampleMessages={messages} {...props}>
    OK
  </RandomMessageAnnounce>
);

const messages = ['Sure thing.', 'Okay.', 'Got it.', 'Great.', 'No problem.'];
