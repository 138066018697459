import styled, { keyframes } from 'styled-components';
import { Card, theme } from '@kea-inc/parrot-ui';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.div`
  height: 100%;

  .category__title {
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
    border-bottom: 1px solid ${theme.colors.gray_light};
    padding-bottom: 14px;
    cursor: pointer;

    .badge__Which {
      margin-left: auto;
      margin-right: 8px;
    }

    &:first-child {
      margin-top: 0;
    }

    button {
      margin-left: 16px;
    }
  }

  .description {
    display: block;
    margin: 1rem 0 1.5rem;
    padding-left: 1rem;

    button {
      margin-left: 0.5rem;
    }
  }
`;

export const ItemsList = styled.section`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-between;
  padding: 0 16px;
`;

type ItemProps = {
  unavailable: boolean;
  // TODO: no need for this when typing is fixed
  onClick: () => void;
};

export const Item = styled(Card).attrs({
  padded: true,
})<ItemProps>`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: space-between;
  transition: 0.1s all ease-in-out;
  width: 49%;

  .price,
  .name {
    margin-top: 8px;
  }

  .item__Wrapper {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }

    .item__Name {
      text-decoration: ${(props) =>
        props.unavailable ? 'line-through' : 'none'};
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
