export class StreamController {
  constructor() {
    this.source = null;
  }

  close() {
    if (this.source) {
      this.source.close();
      this.source = null;
    }
  }

  setSource(source) {
    this.close();
    this.source = source;
  }
}
