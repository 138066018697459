import axios from 'axios';
import env from '@beam-australia/react-env';

const api = axios.create({
  baseURL: 'https://uoa-dev-task.herokuapp.com/',
});

async function createTask(
  phoneNumber = '+552740420231',
  caller = '+5527995780093',
) {
  const response = await api.post('/', { phoneNumber, caller });
  return response.data;
}

if (import.meta.NODE_ENV === 'development') {
  window.createTask = () => createTask(env('PHONE_NUMBER'), env('CALLER'));
}
