import { ValidationErrorCode } from '@modules/order/types';
import {
  humanReadableErrorNames,
  humanReadableErrors,
} from '@utils/itemValidator/humanReadable';

export function getHumanReadableError(error: ValidationErrorCode) {
  const errorMessage = humanReadableErrors[error];
  return errorMessage ?? 'Unknown Error';
}

export function getHumanReadableErrorName(error: ValidationErrorCode) {
  const errorName = humanReadableErrorNames[error];
  return errorName ?? 'Error';
}
