export const ORDER_CHECKLIST = {
  // Table number needs to come first in the order
  TABLE_NUMBER_FILLED: 'tableNumberFilled',
  HAS_CONFIRMED_ADDRESS: 'hasConfirmedAddress',
  HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS: 'hasAskedForDeliveryInstructions',
  ADDRESS_IS_VALID: 'addressIsValid',
  HAS_CONFIRMED_LOYALTY_POINTS: 'hasConfirmedLoyaltyPoints',
  CART_HAS_ITEMS: 'cartHasItems',
  HAS_VALIDATED: 'hasValidated',
  ARE_FIELDS_FILLED: 'areFieldsFilled',
  HAS_UPSOLD_ONCE: 'hasUpsoldOnce',
  HAS_ASKED_FOR_TIP: 'hasAskedForTip',
  HAS_CONFIRMED_ORDER: 'hasConfirmedOrder',
  CART_ITEMS_ARE_VALID: 'cartItemsAreValid',
};

export const VALIDATE_CHECKLIST = [
  ORDER_CHECKLIST.CART_HAS_ITEMS,
  ORDER_CHECKLIST.ARE_FIELDS_FILLED,
  ORDER_CHECKLIST.HAS_REPEATED_CART,
  ORDER_CHECKLIST.CART_ITEMS_ARE_VALID,
];

export const PAYMENT_TYPES = {
  CASH: 'Cash',
  CARD: 'Card',
};

export const INITIAL_STATE = {
  id: null,
  submittedAt: null,
  isTipsOverlayOpen: false,
  isSubmitOverlayOpen: false,
  isChecklistOpen: true,
  isTaxAndFeesOverlayOpen: false,
  validateAttempts: 0,
  isThrottling: false,
  tips: 0,
  details: {
    handOffMode: null,
    paymentType: PAYMENT_TYPES.CASH,
    endTime: null,
    isASAP: true,
    deliveryTime: null, // SCHEDULED TIME
    oloEstimation: null, // ESTIMATION FROM OLO VALIDATION IN MINUTES
    tableNumber: null,
    isCollectingLoyaltyPoints: null,
    // ORDER THROTTLING FEATURE
    availableTimes: [],
    wasAutoScheduled: false,
  },
  checklist: {},
  canValidate: false,
  canSubmit: false,
  validatedBasket: {},
  hasPatchedOrderWithAgentId: false,
  visibleTab: 'transcript',
  pending: {
    isSubmittingOrder: false,
    isValidatingBasket: false,
    isUpdatingOrder: false,
    isSyncingOrder: false,
    isBuildingChecklist: false,
    isFetchingAvailableTimes: false,
  },
  errors: {
    hasBasketValidationError: false,
    hasSubmitOrderError: false,
    hasUpdateOrderError: false,
    hasOrderSyncError: false,
    hasFetchAvailableTimesError: false,
  },
};
