import { useAppDispatch, useAppSelector } from '@store';
import {
  selectModifierById,
  selectModifiersByParent,
  selectOptionById,
} from '@modules/menu/selectors';
import {
  addOption,
  getModifiersFromParent,
  switchOption,
} from '@modules/menu/actions';
import { changeOptionQuantity, removeOption } from '@modules/menu/slice';
import { getModifierSelectionMode } from '@utils/get-modifier-selection-mode';

import { getDefaultOptionsToMaxQuantityConfig } from '@modules/taskrouter/selectors';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { QuantityOption } from './QuantityOption';
import { AddedOption } from './AddedOption';
import { UnavailableOption } from './UnavailableOption';
import { NotAddedOption } from './NotAddedOption';

type OptionProps = {
  optionId: string;
  selectionMode: ReturnType<typeof getModifierSelectionMode>;
};

export function Option({ optionId, selectionMode }: OptionProps) {
  const dispatch = useAppDispatch();

  const { useHalfAddedOption } = useAppSelector(getStoreFeatureFlags);

  const option = useAppSelector((state) =>
    selectOptionById(state.menu.options, optionId),
  );

  if (!option) {
    return null;
  }

  const modifier = useAppSelector((state) =>
    selectModifierById(state.menu.modifiers, option.parentId),
  );

  const addedOption = useAppSelector(
    (state) => state.menu.options.added[optionId],
  );

  const firstChildrenModifier = useAppSelector(
    (state) => selectModifiersByParent(state.menu.modifiers)(optionId)[0],
  );

  function onReloadOption() {
    if (option) {
      dispatch(getModifiersFromParent({ parentId: option.id }));
    }
  }

  function addOptionWithMaxQuantity() {
    const { maxQuantity, maxQuantityPerOption } = modifier ?? {};

    dispatch(
      addOption({
        optionId,
        quantity: maxQuantityPerOption || maxQuantity || 1,
      }),
    );
  }

  function switchOptionWithMaxQuantity() {
    const { maxQuantity, maxQuantityPerOption } = modifier ?? {};

    dispatch(
      switchOption({
        optionId,
        quantity: maxQuantityPerOption || maxQuantity || 1,
      }),
    );
  }

  function onClickOption() {
    const withMaxQuantity = getDefaultOptionsToMaxQuantityConfig();

    if (selectionMode === 'add') {
      if (withMaxQuantity) {
        addOptionWithMaxQuantity();
        return;
      }

      const quantity =
        modifier?.quantityInterval ?? modifier?.minQuantityPerOption ?? 1;

      dispatch(addOption({ optionId, quantity, useHalfAddedOption }));
    } else {
      if (withMaxQuantity) {
        switchOptionWithMaxQuantity();
        return;
      }

      const quantity =
        modifier?.quantityInterval ?? modifier?.minQuantityPerOption ?? 1;

      dispatch(switchOption({ optionId, quantity, useHalfAddedOption }));
    }
  }

  function onRemoveOption() {
    dispatch(removeOption({ optionId }));
  }

  function onFullyAdd() {
    dispatch(addOption({ optionId, useHalfAddedOption }));
  }

  function onQuantityChanged(newQuantity: number) {
    dispatch(changeOptionQuantity({ optionId, quantity: newQuantity }));
  }

  if (addedOption?.quantity && modifier?.supportOptionQuantity) {
    return (
      <QuantityOption
        name={option.name}
        cost={option.cost}
        maxQuantityPerOption={modifier.maxQuantityPerOption ?? 1}
        minQuantityPerOption={modifier.minQuantityPerOption ?? 1}
        quantityInterval={modifier.quantityInterval ?? 1}
        quantity={addedOption.quantity}
        onQuantityChanged={onQuantityChanged}
        onDeselect={onRemoveOption}
      />
    );
  }

  if (addedOption?.quantity >= 1) {
    return (
      <AddedOption
        name={option.name}
        cost={option.cost}
        onRemove={onRemoveOption}
        childrenModifier={firstChildrenModifier}
        isFullyAdded={addedOption?.isFullyAdded}
        isLoadingNestedModifiers={addedOption?.isLoadingNestedModifiers}
        onFullyAdd={onFullyAdd}
      />
    );
  }

  if (!option.hasStockAvailable) {
    return (
      <UnavailableOption
        name={option.name}
        spokenName={option.spokenName}
        status={option.status}
        onReload={onReloadOption}
      />
    );
  }

  return (
    <NotAddedOption
      name={option.name}
      cost={option.cost}
      onClick={onClickOption}
      hoverText={selectionMode === 'add' ? 'ADD' : 'SWITCH'}
    />
  );
}
