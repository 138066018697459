import { theme } from '@kea-inc/parrot-ui';
import styled, { keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.div`
  .description {
    display: block;
    margin-top: 16px;
    padding-left: 16px;

    button {
      margin-left: 8px;
    }
  }
`;

export const Header = styled.header`
  width: 100%;

  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${theme.colors.gray_light};
  z-index: 9998;
  background: ${theme.colors.white};
  padding: 0px 16px 10px 16px;

  .title {
    margin-right: 14px;
  }

  .container__quickActions {
    margin-left: auto;
    display: flex;
    align-items: center;

    .container__Clear {
      button {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;

        &:disabled {
          cursor: default;
        }
      }
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 14,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
