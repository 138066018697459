import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';

const actions = {
  [types.STREAM_CONNECTING]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingStream = true;
      payment.errors.hasConnectToStreamError = false;
      return payment;
    }),

  [types.STREAM_CONNECTED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingStream = false;
      payment.errors.hasConnectToStreamError = false;
      payment.streamConnected = true;
      return payment;
    }),

  [types.STREAM_NOT_CONNECTED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingStream = false;
      payment.errors.hasConnectToStreamError = true;
      return payment;
    }),

  [types.STREAM_CLOSED]: (state) =>
    produce(state, (payment) => {
      payment.streamConnected = false;
      return payment;
    }),

  [types.PAYBOT_CONNECTING]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingPaybot = true;
      payment.errors.hasConnectToPaybotError = false;
      payment.paybotRanOnce = true;
      return payment;
    }),

  [types.PAYBOT_CONNECTED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingPaybot = false;
      payment.errors.hasConnectToPaybotError = false;
      return payment;
    }),

  [types.PAYBOT_NOT_CONNECTED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isConnectingPaybot = false;
      payment.errors.hasConnectToPaybotError = true;
      return payment;
    }),

  [types.MANUAL_PAYMENT_PROCESSING]: (state) =>
    produce(state, (payment) => {
      payment.pending.isProcessingManualPayment = true;
      payment.errors.hasProcessManualPaymentError = false;
      return payment;
    }),

  [types.MANUAL_PAYMENT_PROCESSED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isProcessingManualPayment = false;
      payment.errors.hasProcessManualPaymentError = false;
      return payment;
    }),

  [types.MANUAL_PAYMENT_NOT_PROCESSED]: (state) =>
    produce(state, (payment) => {
      payment.pending.isProcessingManualPayment = false;
      payment.errors.hasProcessManualPaymentError = true;
      return payment;
    }),

  [types.PAYBOT_CHANGE_OVERLAY_VISIBILITY]: (state, action) =>
    produce(state, (payment) => {
      payment.isPaybotOverlayOpen = action.payload;
      return payment;
    }),

  [types.MANUAL_PAYMENT_CHANGE_OVERLAY_VISIBILITY]: (state, action) =>
    produce(state, (payment) => {
      payment.isManualPaymentOverlayOpen = action.payload;
      return payment;
    }),

  [types.SET_STREAM_CREDIT_CARD_FIELD]: (state, action) =>
    produce(state, (payment) => {
      const { type, value } = action.payload;
      payment.creditCard[type] = value;
      return payment;
    }),

  [types.SET_MANUAL_PAYMENT_FIELD]: (state, action) =>
    produce(state, (payment) => {
      const { field, value } = action.payload;
      payment.manualCreditCard[field] = value;
      return payment;
    }),

  [types.CREDIT_CARD_RESET]: (state) =>
    produce(state, (payment) => {
      payment.creditCard = INITIAL_STATE.creditCard;
      return payment;
    }),

  [types.RESET]: (state) => produce(state, () => INITIAL_STATE),
};

export const paymentReducer = createReducer(INITIAL_STATE, actions);
