import React from 'react';

import { UpsellBadge } from '@components/DynamicBadges';
import { ORDER_CHECKLIST } from '@modules/order/state';

import { ChecklistItem } from './ChecklistItem';

export const HasUpsoldOnce = () => (
  <ChecklistItem field={ORDER_CHECKLIST.HAS_UPSOLD_ONCE}>
    <UpsellBadge variant="success" style={{ transform: 'translateY(2px)' }} />
  </ChecklistItem>
);
