import { Soundboard } from './Soundboard';
import { Checklist } from './Checklist';
import * as S from './styles';

export const CenterPanel = () => (
  <S.Container>
    <Soundboard />
    <Checklist />
  </S.Container>
);
