import { Select, Option } from '@kea-inc/parrot-ui';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useAppDispatch } from '@store';
import { announce } from '@modules/device/device-actions';

import { getStoreClosingTime } from '@utils/get-store-closing-time';
import { StoreHours } from './types';
import * as utils from './utils';
import { AvailableTime } from './AvailableTime';
import { UnavailableTime } from './UnavailableTime';

type ThrottledTimePickerProps = {
  onVisibilityChange: (value: boolean) => void;
  onChange: (value: string) => void;
  storeTimezone: string;
  valueMs: number;
  storeHours: StoreHours[];
  availableTimes: string[];
};

export function ThrottledTimePicker({
  availableTimes,
  onChange,
  onVisibilityChange,
  valueMs,
  storeHours,
  storeTimezone,
}: ThrottledTimePickerProps) {
  const dispatch = useAppDispatch();
  const storeClosingTime = getStoreClosingTime({ storeHours, storeTimezone });

  const intervals = useMemo(
    () =>
      utils.getIntervals({
        firstAvailableTime: availableTimes[0],
        intervalInMinutes: 5,
        storeClosingTime,
        storeTimezone,
      }),
    [availableTimes, storeClosingTime],
  );

  const intervalsWithAvailability = useMemo(
    () =>
      intervals.map((interval) => ({
        ...interval,
        isAvailable: utils.isWithinAvailableSlot({
          availableSlots: availableTimes,
          isoTime: interval.iso,
          slotInterval: 15,
        }),
      })),
    [availableTimes, intervals],
  );

  const handleUnavailableTimeClick = (unavailableTimeISO: string) => {
    const nearestAvailableTime = utils.getNearestAvailableTime({
      iso: unavailableTimeISO,
      intervals: intervalsWithAvailability,
    });

    const message = utils.makeUnavailableTimeMessage({
      formattedTime: DateTime.fromISO(unavailableTimeISO)
        .setZone(storeTimezone)
        .toFormat('h:mm a'),
      nearestAvailableTime: nearestAvailableTime?.formattedTime,
    });

    dispatch(announce({ text: message }));
  };

  const zonedValue = DateTime.fromMillis(valueMs).setZone(storeTimezone);

  const valueFormatted = zonedValue.toFormat('h:mm a');

  return (
    <Select
      onChange={(v) => onChange(v as string)}
      value={valueFormatted}
      visibleProp="value"
      // @ts-expect-error - Missing type in Parrot, but it's a valid prop
      className="throttledTimePicker__select"
      onFocus={() => onVisibilityChange(true)}
      onBlur={() => onVisibilityChange(false)}
    >
      {intervalsWithAvailability.map((interval) => (
        <Option
          key={interval.formattedTime}
          value={interval.formattedTime}
          className={interval.isAvailable ? '' : 'unavailable'}
        >
          {interval.isAvailable ? (
            <AvailableTime interval={interval} />
          ) : (
            <UnavailableTime
              interval={interval}
              onClick={handleUnavailableTimeClick}
            />
          )}
        </Option>
      ))}
    </Select>
  );
}
