import React, { useRef, useState, useMemo } from 'react';
import { HiRefresh } from 'react-icons/hi';

import Confirmation from '@components/Header/Badges/Confirmation';
import { useAppDispatch, useAppSelector } from '@store';
import {
  getCategories,
  getItemsFromCategory,
  getModifiersFromParent,
} from '@modules/menu/actions';
import * as S from './styles';

// TODO: dumb component
export const ReloadMenu = () => {
  const dispatch = useAppDispatch();

  const currentItemId = useAppSelector((state) => state.menu.items.current);
  const currentCategoryId = useAppSelector(
    (state) => state.menu.categories.current,
  );

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const buttonRef = useRef(null);

  const openConfirmation = () => setIsConfirmationOpen(true);
  const closeConfirmation = () => setIsConfirmationOpen(false);

  const handleReload = () => {
    if (currentItemId) {
      dispatch(getModifiersFromParent({ parentId: currentItemId }));
    } else if (currentCategoryId) {
      dispatch(getItemsFromCategory({ categoryId: currentCategoryId }));
    } else {
      dispatch(getCategories());
    }

    closeConfirmation();
  };

  const label = useMemo(() => {
    const retLabel = currentCategoryId ? 'category' : 'categories';
    return currentItemId ? 'item' : retLabel;
  }, [currentItemId, currentCategoryId]);

  return (
    <>
      <S.IconButton
        icon={HiRefresh}
        onClick={openConfirmation}
        variant="error"
        size={25}
        ref={buttonRef}
      />

      <Confirmation
        action={`reload ${label}`}
        anchor={buttonRef}
        open={isConfirmationOpen}
        onCancel={closeConfirmation}
        onConfirm={handleReload}
      />
    </>
  );
};
