import { DateTime } from 'luxon';

export function isWithinAvailableSlot(params: {
  isoTime: string;
  availableSlots: string[];
  slotInterval: number;
}) {
  const { isoTime, availableSlots, slotInterval } = params;

  const date = DateTime.fromISO(isoTime);

  return availableSlots.some((time) => {
    const slotStart = DateTime.fromISO(time);
    const slotEnd = slotStart.plus({ minute: slotInterval });

    return date >= slotStart && date < slotEnd;
  });
}
