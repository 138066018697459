import { Typography } from '@kea-inc/parrot-ui';
import { ITypographyProps } from '@kea-inc/parrot-ui/dist/components/Typography/types';
import Highlighter from 'react-highlight-words';

export function Highlight(
  props: ITypographyProps & {
    searchTerm: string;
  },
) {
  return (
    <Typography {...props}>
      <Highlighter
        textToHighlight={props.children as string}
        searchWords={[props.searchTerm]}
        autoEscape
        caseSensitive={false}
        highlightTag={({ children }) => <strong>{children}</strong>}
      />
    </Typography>
  );
}
