import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import * as orderAPI from '@modules/order/api';
import * as taskrouterSelector from '@modules/taskrouter/selectors';
import { useOrder, useAgents, useStoreHours, useHistory } from '@hooks';
import { DateTime } from 'luxon';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import logger from '@logger';
import { useAppSelector } from '@store';

export const OrderPatchProvider = ({ children }) => {
  const { orderDetails, id: order_id, tips, isThrottling } = useOrder();
  const { current: agent_id } = useAgents();
  const conferenceSid = useAppSelector(taskrouterSelector.getConferenceSid);
  const { timezone } = useStoreHours();
  const { useOrderService } = useAppSelector(getStoreFeatureFlags);
  const storeFeatureFlagFetched =
    Object.keys(useAppSelector(getStoreFeatureFlags))?.length !== 0;
  const history = useHistory();
  const isDashboardOpened = history.location.pathname === '/dashboard';
  const {
    handOffMode,
    paymentType,
    isASAP,
    deliveryTime,
    endTime,
    tableNumber,
    isCollectingLoyaltyPoints,
  } = orderDetails;

  const updateOrderV2 = (data, params = {}) => {
    if (!order_id || !isDashboardOpened) return;
    orderAPI.updateOrderV2(data, params);
  };
  const updateOrder = (data, params = {}) => {
    if (!order_id || !isDashboardOpened) return;
    orderAPI.updateOrder(data, params);
  };

  const futureOrderTime = useMemo(() => {
    const isScheduled = !isASAP && deliveryTime;
    if (useOrderService) {
      if (isScheduled) {
        const orderWithTZ = DateTime.fromMillis(deliveryTime, {
          zone: timezone,
        });
        return orderWithTZ.toISO();
      }
      return DateTime.local({ zone: timezone }).toISO();
    }
    if (isScheduled) {
      return new Date(deliveryTime).toISOString();
    }
    return new Date();
  }, [deliveryTime, isASAP]);

  const updateOrderTime = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      updateOrderV2({ scheduledTime: isASAP ? null : futureOrderTime });
    } else {
      if (isThrottling && !deliveryTime) {
        // if delivery time is not set, it will try to schedule the order to the current time
        return;
      }

      updateOrder({
        order_time: isASAP ? 'ASAP' : 'Future',
        future_order_time: futureOrderTime,
      });
    }
  };

  const updateOrderType = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (handOffMode) {
      if (useOrderService) {
        updateOrderV2({ handoffMode: handOffMode.replace(/-/g, '') });
      } else {
        updateOrder({ handoff_mode: handOffMode });
      }
    }
  };

  const updateTableNumber = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (tableNumber) {
      if (useOrderService) {
        updateOrderV2({ tableNumber });
      } else {
        updateOrder({ table_number: tableNumber });
      }
    }
  };

  const updateIsCollectingLoyaltyPoints = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (isCollectingLoyaltyPoints) {
      if (useOrderService) {
        updateOrderV2({ isCollectingLoyaltyPoints });
      } else {
        updateOrder({
          is_collecting_loyalty_points: isCollectingLoyaltyPoints,
        });
      }
    }
  };

  const updatePaymentType = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      updateOrderV2({ paymentType: paymentType.toLowerCase() });
    } else {
      updateOrder({ payment_type: paymentType });
    }
  };

  const updateAgentId = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      logger.info(
        'Call not needed since agent was already added via OperatorService',
      );
    } else {
      updateOrder({ agent_id, status: 'Pending' });
    }
  };

  const updateOrderApplication = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      updateOrderV2({ source: 'uoa' });
    } else {
      updateOrder({ order_application: 'UOA' });
    }
  };

  const updateEndTime = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      logger.warn('Update EndTime is not supported in OrderService');
    } else {
      return !!endTime && updateOrder({ end_time: endTime });
    }
  };

  const updateConferenceSid = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (conferenceSid) {
      if (useOrderService) {
        logger.warn('Update Conference SID not supported in OrderService');
      } else {
        updateOrder({ conference_sid: conferenceSid });
      }
    }
  };

  const updateTip = () => {
    if (!storeFeatureFlagFetched) {
      return;
    }
    if (useOrderService) {
      updateOrderV2({ tip: tips });
    } else {
      updateOrder({ tip_amount: tips });
    }
  };

  useEffect(updateOrderType, [
    handOffMode,
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateTableNumber, [
    tableNumber,
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateIsCollectingLoyaltyPoints, [
    isCollectingLoyaltyPoints,
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updatePaymentType, [
    paymentType,
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateAgentId, [
    agent_id,
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateOrderApplication, [
    order_id,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateOrderTime, [
    order_id,
    isASAP,
    futureOrderTime,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateEndTime, [
    endTime,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateConferenceSid, [
    conferenceSid,
    isDashboardOpened,
    storeFeatureFlagFetched,
  ]);
  useEffect(updateTip, [tips, isDashboardOpened, storeFeatureFlagFetched]);

  return children;
};

OrderPatchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
