import { Action } from 'redux';

export function createReducer<State>(
  initialState: State,
  actionsMap: ActionsMap<State>,
) {
  return (state = initialState, action: Action) => {
    const reducer = actionsMap[action.type];

    if (reducer === undefined) {
      return state;
    }

    return reducer(state, action);
  };
}

type ActionsMap<State> = {
  [key: string]: (state: State, action: Action) => State;
};
