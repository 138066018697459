import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';

import React from 'react';
import * as S from './styles';

type CounterProps = {
  value: number;
  onIncrease: () => void;
  onDecrease: () => void;
  setQuantity: (value: number) => void;
  max: number | null;
  min: number | null;
  noBorder?: boolean;
  disabled?: boolean;
};

export function Counter({
  value,
  onIncrease,
  onDecrease,
  setQuantity,
  max,
  min,
  noBorder,
  disabled,
}: CounterProps) {
  const handleIncrease = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    const shouldIncrease = max ? value < max : true;
    if (shouldIncrease) {
      onIncrease();
    }
  };

  const handleDecrease = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    const shouldDecrease = min ? value > min : true;
    if (shouldDecrease) {
      onDecrease();
    }
  };

  const handleChange = (newValue: number) => {
    if (max) {
      if (newValue > max) {
        setQuantity(max);
      } else {
        setQuantity(newValue);
      }
      return;
    }

    setQuantity(newValue);
  };

  return (
    <S.Container disabled={disabled} noBorder={noBorder}>
      <button
        data-testid="counter-decrease"
        disabled={disabled}
        type="button"
        onClick={handleDecrease}
        aria-label="decrease quantity"
      >
        <FiMinusCircle />
      </button>

      <input
        type="number"
        value={value}
        max={max ?? undefined}
        onChange={(e) => handleChange(Number(e.target.value))}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
        data-testid="counter-input"
      />

      <button
        data-testid="counter-increase"
        disabled={disabled}
        type="button"
        onClick={handleIncrease}
        aria-label="increase quantity"
      >
        <FiPlusCircle />
      </button>
    </S.Container>
  );
}

export default Counter;
