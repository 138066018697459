import { useEffect, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useOrder } from '@hooks';
import * as voicePaneAPI from '@modules/voicePane/api';

import { useAppSelector } from '@store';
import {
  getScheduleDaysLimit,
  getStoreTimezone,
} from '@modules/taskrouter/selectors';
import { StoreDetails } from './StoreDetails';
import { AccountDetails } from './AccountDetails';
import { DateTimePicker } from './DateTimePicker';
import * as S from './styles';
import { DeliveryDetails } from './Delivery';

type DetailsTabProps = {
  size: number;
  hidden: boolean;
};

export function DetailsTab({ size, hidden }: DetailsTabProps) {
  const { orderDetails, handOffMode } = useOrder();
  const { deliveryTime, isASAP } = orderDetails;

  const timezone = useAppSelector(getStoreTimezone);
  const scheduleDaysLimit = useAppSelector(getScheduleDaysLimit);

  // 0 = just today
  // 1+ = today + next x days
  // undefined/null = no limit
  const advanceOrderDays = useMemo(
    () =>
      typeof scheduleDaysLimit === 'number'
        ? DateTime.local()
            .setZone(timezone)
            .plus({ days: scheduleDaysLimit })
            .toJSDate()
        : undefined,
    [scheduleDaysLimit],
  );

  useEffect(() => {
    if (deliveryTime) {
      voicePaneAPI.setVoicePaneDeliveryTime({ deliveryTime, isASAP });
    }
  }, [isASAP]);

  return (
    <S.Container hidden={hidden} className="details_section">
      <StoreDetails hidden={hidden} />
      <form onSubmit={(e) => e.preventDefault()}>
        <AccountDetails />
        {!isASAP && <DateTimePicker size={size} max={advanceOrderDays} />}
      </form>

      {handOffMode === 'delivery' && <DeliveryDetails />}
    </S.Container>
  );
}
