export function getQuantityPerOptionOnSplit(params: {
  modifierAddedOptions: { quantity: number }[];
  maxQuantity: number | null;
  maxQuantityPerOption: number | null;
}): number {
  const { maxQuantity, maxQuantityPerOption, modifierAddedOptions } = params;

  const totalOptionQuantity = modifierAddedOptions.reduce(
    (total, option) => total + Number(option.quantity),
    0,
  );

  let quantityPerOption = maxQuantityPerOption ?? 1;

  if (maxQuantity && totalOptionQuantity > maxQuantity) {
    quantityPerOption = maxQuantity / modifierAddedOptions.length;
  }

  if (maxQuantityPerOption && quantityPerOption > maxQuantityPerOption) {
    quantityPerOption = maxQuantityPerOption;
  }

  quantityPerOption = Math.floor(quantityPerOption);

  return quantityPerOption;
}
