import { store, RootState } from '@store';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const selectSelf = (state: RootState) => state.taskrouter;

export const getAgentTookAction = createDraftSafeSelector(
  selectSelf,
  (state) => state.agentTookAction,
);

export const getHasOngoingTask = createDraftSafeSelector(
  selectSelf,
  (state) => state.taskSid !== null && state.taskStatus === 'assigned',
);

export const getIsReassigningTask = createDraftSafeSelector(
  selectSelf,
  (state) => state.pending.isReassigningTask,
);

export const getReservationSid = createDraftSafeSelector(
  selectSelf,
  (state) => state.reservationSid,
);

export const getOrderIdFromTaskAttributes = createDraftSafeSelector(
  selectSelf,
  (state) => state.taskAttributes?.orderId,
);

export const getConferenceSid = createDraftSafeSelector(
  selectSelf,
  (state) => state.conferenceSid,
);

export const getStoreId = createDraftSafeSelector(
  selectSelf,
  (state) => state.store?.id ?? state.taskAttributes?.storeId,
);

export const getTaskrouterStore = createDraftSafeSelector(
  selectSelf,
  (state) => state.store ?? state.taskAttributes?.store,
);

export const getStoreTimezone = createDraftSafeSelector(
  selectSelf,
  (state) => state.store?.timezone,
);

export const getBrandKey = createDraftSafeSelector(
  selectSelf,
  (state) => state.brand?.key,
);

export const getBrandName = createDraftSafeSelector(
  selectSelf,
  (taskrouterState) =>
    taskrouterState.brand?.name ?? taskrouterState.brand?.spokenName,
);

export const getBrand = createDraftSafeSelector(
  selectSelf,
  (state) => state.brand,
);

export const getStoreHours = createDraftSafeSelector(
  selectSelf,
  (state) => state.storeHours,
);

export const getHandoffMethods = createDraftSafeSelector(
  selectSelf,
  (state) => state.handoffMethods,
);

export const getWorkerSid = createDraftSafeSelector(
  selectSelf,
  (state) => state.workerSid,
);

export const getScheduleDaysLimit = createDraftSafeSelector(
  selectSelf,
  (state) => state.store?.scheduleDaysLimit,
);

export const isTaskrouterPending = createDraftSafeSelector(
  selectSelf,
  (state) =>
    Object.keys(state.pending).some(
      (key) => state.pending[key as keyof typeof state.pending],
    ),
);

export const shouldShowRingAnimation = createDraftSafeSelector(
  (state: RootState) => state,
  (state) => {
    const { taskrouter, device } = state;

    const { reservationSid, workerSid, taskSid, taskStatus } = taskrouter;
    const { pending } = taskrouter;

    const canAcceptReservation =
      reservationSid !== null &&
      workerSid !== null &&
      (taskSid === null || taskStatus !== 'assigned');

    const { isAcceptingReservation, isConnectingToConference } = pending;

    const { isAcceptingCall } = device.pending;

    return (
      canAcceptReservation ||
      isAcceptingCall ||
      isAcceptingReservation ||
      isConnectingToConference
    );
  },
);

export const getWorkerStatus = createDraftSafeSelector(
  selectSelf,
  (state) => state.workerStatus,
);

export const isStatusOverlayOpen = createDraftSafeSelector(
  selectSelf,
  (state) => state.isStatusOverlayOpen,
);

export const isTrainingTask = createDraftSafeSelector(
  selectSelf,
  (state) => state.isTrainingTask,
);

export const isFetchingBrands = createDraftSafeSelector(
  selectSelf,
  (state) => state.pending.isFetchingBrands,
);

export const getBrands = createDraftSafeSelector(
  selectSelf,
  (state) => state.brands,
);

export const getStoresByBrand = createDraftSafeSelector(
  selectSelf,
  (state) => state.storesByBrand,
);

// DEPRECATED SELECTORS
const taskrouter = createDraftSafeSelector(
  selectSelf,
  (taskrouterState) => taskrouterState,
);

export function getTaskSid() {
  const { taskSid } = taskrouter(store.getState());
  return taskSid;
}

// TODO
export function getTaskAttributes() {
  const { taskAttributes } = taskrouter(store.getState());

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes;
}

export function deprecatedGetOrderId() {
  const taskAttributes = getTaskAttributes();

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes.orderId;
}

export function getBrandVoice() {
  const taskAttributes = getTaskAttributes();

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes.brandResult.brandVoice;
}

export function getConferenceName() {
  const taskAttributes = getTaskAttributes();

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes.conferenceName;
}

export function deprecatedGetConferenceSid() {
  const { conferenceSid } = taskrouter(store.getState());

  if (!conferenceSid) {
    return null;
  }

  return conferenceSid;
}

export function getStore() {
  const { store: fetchedStore } = taskrouter(store.getState());

  if (fetchedStore) {
    return fetchedStore;
  }

  return getTaskStore();
}

function getTaskStore() {
  const taskAttributes = getTaskAttributes();

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes.store;
}

export function getCaller() {
  const taskAttributes = getTaskAttributes();

  if (!taskAttributes) {
    return null;
  }

  return taskAttributes.caller;
}

export function deprecatedGetStoreTimezone() {
  return getStore()?.timezone;
}

export function deprecatedGetBrandName() {
  const brand = deprecatedGetBrand();

  if (!brand) {
    return null;
  }

  return brand.name || brand.spokenName;
}

export function deprecatedGetBrand() {
  const { brand: fetchedBrand } = taskrouter(store.getState());

  if (!fetchedBrand) {
    return null;
  }

  return fetchedBrand;
}

export function deprecatedGetStoreHours() {
  const { storeHours } = taskrouter(store.getState());
  return storeHours;
}

export function getHandoffModes() {
  const { handoffMethods } = taskrouter(store.getState());
  return handoffMethods;
}

export function getStoreCashLimit() {
  const cashLimit = getStore()?.cashLimit;
  return cashLimit ?? null;
}

export function wasReassignedByTtfa() {
  const { reassignedByTtfa } = taskrouter(store.getState());
  return reassignedByTtfa;
}

export function isStoreWithOloMock() {
  return getStore()?.oloMock ?? false;
}

export function getPlatformStore() {
  const platformStore = getStore();
  return {
    brandId: platformStore?.brandId,
    storeId: platformStore?.id,
    platformName: platformStore?.platformName,
  };
}

export function getDefaultOptionsToMaxQuantityConfig() {
  const platformStore = getStore();
  const brand = deprecatedGetBrand();

  if (!platformStore && !brand) {
    return null;
  }

  return (
    platformStore?.useDefaultOptionsToMaxQuantity ??
    brand?.useDefaultOptionsToMaxQuantity
  );
}
