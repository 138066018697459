import React from 'react';
import { Typography } from '@kea-inc/parrot-ui';

import { CenterOverlay } from '@components';
import { setWorkerStatus } from '@modules/taskrouter/actions';
import {
  closeStatusOverlay,
  resetWorkerStatus,
} from '@modules/taskrouter/slice';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import { clockOut } from '@modules/clock/api';
import { useAppDispatch, useAppSelector } from '@store';
import { WorkerStatus } from '@components/Header/WorkerStatus';
import * as S from './styles';
import Stopwatch from './Stopwatch';

function StatusOverlay() {
  const dispatch = useAppDispatch();
  const isStatusOverlayOpen = useAppSelector(
    taskrouterSelectors.isStatusOverlayOpen,
  );

  const workerStatus = useAppSelector(taskrouterSelectors.getWorkerStatus);

  const handleClockOut = () => {
    dispatch(resetWorkerStatus());
    clockOut().then(() => dispatch(closeStatusOverlay()));
  };

  const handleChangeStatus = () => {
    dispatch(resetWorkerStatus());
    dispatch(setWorkerStatus('Idle'));
    dispatch(closeStatusOverlay());
  };

  return isStatusOverlayOpen ? (
    <CenterOverlay>
      <S.Container data-testid="status-overlay">
        <S.Header>
          <Typography variant="title" weight="heavy">
            {workerStatus === 'Absent'
              ? 'You missed calls'
              : 'Unavailable for calls:'}
          </Typography>

          <WorkerStatus showStatusesMenu={false} />
        </S.Header>
        <S.Content>
          <Typography variant="display" weight="heavy">
            <Stopwatch />
          </Typography>
        </S.Content>

        <S.ButtonsWrapper>
          <S.OutlinedButton danger onClick={handleClockOut}>
            Clock out
          </S.OutlinedButton>
          <S.Button danger onClick={handleChangeStatus}>
            Take Calls
          </S.Button>
        </S.ButtonsWrapper>
      </S.Container>
    </CenterOverlay>
  ) : (
    <div />
  );
}

export default React.memo(StatusOverlay);
