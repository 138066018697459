import { useAppSelector } from '@store';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import { CLOCK_STATUS } from '@modules/clock/state';
import * as paymentAPI from '@modules/payment/api';
import { actions as featureFlagsActions } from '@modules/featureFlags/slice';
import * as notificationAPI from '@modules/notifications/api';
import * as clockAPI from '@modules/clock/api';
import * as taskrouterActions from '@modules/taskrouter/actions';

export const useConnector = () => {
  const selectors = {
    isTaskrouterPending: useAppSelector(
      taskrouterSelectors.isTaskrouterPending,
    ),
    shouldShowRingAnimation: useAppSelector(
      taskrouterSelectors.shouldShowRingAnimation,
    ),
    shouldShowConnectingAnimation: useAppSelector(
      taskrouterSelectors.isTaskrouterPending,
    ),
    workerStatus: useAppSelector(taskrouterSelectors.getWorkerStatus),

    isStatusOverlayOpen: useAppSelector(
      taskrouterSelectors.isStatusOverlayOpen,
    ),

    isDeviceRegistered: useAppSelector(deviceSelectors.isDeviceRegistered),

    isClockingIn: useAppSelector(({ clock }) => clock.pending.isClockingIn),
    isClockingOut: useAppSelector(({ clock }) => clock.pending.isClockingOut),
    isClockedIn: useAppSelector(
      ({ clock }) => clock.status === CLOCK_STATUS.CLOCK_IN,
    ),

    user: useAppSelector(({ auth }) => auth.user),
  };

  const actions = {
    setDelayedUserFeatureFlags: featureFlagsActions.setDelayedUserFeatureFlags,
    setDelayedBrandFeatureFlags:
      featureFlagsActions.setDelayedBrandFeatureFlags,
    setDelayedStoreFeatureFlags:
      featureFlagsActions.setDelayedStoreFeatureFlags,
    resetPayment: paymentAPI.reset,
    clockOut: clockAPI.clockOut,
    clockIn: clockAPI.clockIn,
    infoNotification: notificationAPI.info,
    getWorkerStatus: taskrouterActions.getWorkerStatus,
    resetTaskrouter: taskrouterActions.resetTaskrouter,
  };

  return { selectors, actions };
};
