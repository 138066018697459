import { types } from './types';

export function surveyStartForNewOrder(payload) {
  return {
    type: types.SURVEY_START_NEW_ORDER,
    payload,
  };
}

export function surveySetPrimaryDisposition(payload) {
  return {
    type: types.SURVEY_SET_PRIMARY_DISPOSITION,
    payload,
  };
}

export function surveySetSecondaryDisposition(payload) {
  return {
    type: types.SURVEY_SET_SECONDARY_DISPOSITION,
    payload,
  };
}

export function surveySetComments(payload) {
  return {
    type: types.SURVEY_SET_COMMENTS,
    payload,
  };
}

export function surveySubmitting() {
  return {
    type: types.SURVEY_SUBMITTING,
  };
}

export function surveySubmitted() {
  return {
    type: types.SURVEY_SUBMITTED,
  };
}

export function surveyNotSubmitted() {
  return {
    type: types.SURVEY_NOT_SUBMITTED,
  };
}

export function surveySetOrderStatusAsSubmitted() {
  return {
    type: types.SURVEY_ORDER_SUBMITTED,
  };
}

export function surveySetOrderStatusAsForwarded() {
  return {
    type: types.SURVEY_ORDER_FORWARDED,
  };
}

export function surveyTimeout() {
  return {
    type: types.SURVEY_TIMEOUT,
  };
}
