import styled from 'styled-components';
import { theme, Card } from '@kea-inc/parrot-ui';

export const Container = styled(Card).attrs({
  selected: true,
  padded: true,
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 8px;

  &:hover {
    box-shadow: ${theme.elevations[3]};
  }
`;

export const Checkout = styled.div`
  padding-top: 16px;
  width: 100%;
  border-top: 1px solid ${theme.colors.gray_lighter};

  &,
  .checkout__buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
  }

  > div div + div {
    margin-left: 8px;
  }

  > div button + button {
    margin-left: 8px;
  }
`;

export const Header = styled.header`
  width: 100%;

  .header__tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 15px;

    border-bottom: 1px solid ${theme.colors.gray_lighter};
  }

  .header__badges {
    display: flex;
    align-items: center;

    padding-top: 15px;
    margin-bottom: 12px;

    > div + div {
      margin-left: 8px;
    }

    > div:last-child {
      margin-left: auto;
    }

    .cashLimit__button {
      margin-left: 8px;

      svg {
        font-size: 18px;
      }
    }
  }

  .cashLimit__warning,
  .preexistingCart__info {
    width: 100%;
    margin-bottom: 8px;
  }
`;

export const Content = styled.section.attrs({
  className: 'rightPanel__content',
})`
  flex: 1;
  padding: 8px 0;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const Footer = styled.footer`
  margin-top: auto;
  width: 100%;
`;
