import KeaOrder from '@kea-inc/order';
import { CartItem } from '@modules/cart/types';
import { convertCartItemToDescriptionItem } from './convert-cart-item-to-description-item';

export function makeItemDescription(cartItem?: CartItem) {
  if (!cartItem) {
    return '';
  }

  return KeaOrder.createItemDescription(
    convertCartItemToDescriptionItem(cartItem),
  );
}
