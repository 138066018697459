import { formatList, isEntityAvailable } from '@utils';

import { Category, Item } from '@modules/menu/types';

// LISTS ALL THE PRODUCTS FOR A SPECIFIC CATEGORY
export function makeItemsUnderCategoryPhrase(
  category: Category,
  items: Item[],
  handoffMode: string,
) {
  const phrase = [];

  phrase.push(`Our options for ${category.spokenName} are: `);

  const availableItems = items.filter(
    (item) =>
      isEntityAvailable(item) &&
      !item.unavailableHandoffModes?.includes(handoffMode),
  );

  phrase.push(formatList(availableItems.map((item) => item.spokenName)));

  return phrase.join(' ').trim();
}
