import { useState } from 'react';
import { Typography } from '@kea-inc/parrot-ui';
import { DateTime } from 'luxon';
import { useDevice, useInterval } from '@hooks';

export function CallDuration() {
  const [duration, setDuration] = useState('00:00:00');
  const { connectionAcceptedAt } = useDevice();

  useInterval(() => {
    if (!connectionAcceptedAt) return '00:00:00';

    const diff = DateTime.now().diff(
      DateTime.fromJSDate(connectionAcceptedAt),
      ['seconds'],
    );
    const formatted = diff.toFormat('hh:mm:ss');
    setDuration(formatted);
  }, 1000);

  return (
    <Typography variant="caption" weight="heavy">
      {duration}
    </Typography>
  );
}
