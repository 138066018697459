import { ORDER_CHECKLIST } from '@modules/order/state';
import { ConfirmAddressBadge } from '@components/DynamicBadges';

import { ChecklistItem } from './ChecklistItem';

export function HasConfirmedAddress() {
  return (
    <ChecklistItem field={ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS}>
      <ConfirmAddressBadge />
    </ChecklistItem>
  );
}
