/* eslint-disable no-unreachable */
import store, { dispatch } from '@store';

import { announce } from '@modules/device/device-actions';
import * as deviceSelectors from '@modules/device/selectors';
import { setOrderStatusAsSubmitted } from '@modules/survey/api';
import * as notifications from '@modules/notifications/api';
import * as transcriptsAPI from '@modules/transcripts/api';
import * as transcriptsSelectors from '@modules/transcripts/selectors';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import { actions as cartSlice } from '@modules/cart/slice';

import * as errors from './errors';
import * as actions from './actions';
import * as selectors from './selectors';
import * as utils from './utils';
import * as utilsV2 from './utilsV2';
import { ORDER_CHECKLIST } from './state';
import { types } from './types';

export function addTips(tips) {
  dispatch(actions.addTips(tips ?? 0));
  if (tips) {
    return store.dispatch(
      announce({
        text: `I've added a tip of $${tips.toFixed(
          2,
        )}, thank you for your generosity.`,
      }),
    );
  }

  store.dispatch(
    announce({
      text: 'No tip, got it.',
    }),
  );
}

export function removeTips() {
  dispatch(actions.addTips(0));
}

export function buildChecklist() {
  dispatch(actions.buildingChecklist());
  const checklist = utils.buildChecklist();
  dispatch(actions.buildChecklist(checklist));
}

export function evaluateTipsChecklist() {
  dispatch(actions.buildingChecklist());
  const checklist = utils.evaluateTipsChecklist();
  dispatch(actions.buildChecklist(checklist));
}

export function setOrderId(id) {
  reset();
  dispatch(actions.setOrderId(id));
}

export function setDetails(key, value) {
  const previousDetails = selectors.getDetails();

  createOrderEvent('changed_order_details', {
    [key]: {
      previous: previousDetails[key] ?? null,
      current: value,
    },
  });
  createAnalyticsEvent('order_details_changed', {
    action: `changed_${key}`,
    previous: previousDetails[key] ?? null,
    current: value,
  });

  dispatch(actions.setDetails({ key, value }));
}

export function removeChecklistItem(target) {
  dispatch(actions.removeChecklistItem(target));
}

export function setChecklist(target, value) {
  dispatch(actions.setChecklist({ target, value }));
}

export function openTipsOverlay() {
  dispatch(actions.changeTipsOverlayVisibility(true));
}

export function closeTipsOverlay() {
  dispatch(actions.changeTipsOverlayVisibility(false));
}

export function openSubmitOverlay() {
  dispatch(actions.changeSubmitOverlayVisibility(true));
}

export function closeSubmitOverlay() {
  dispatch(actions.changeSubmitOverlayVisibility(false));
}

export function openChecklist() {
  dispatch(actions.changeChecklistVisibility(true));
}

export function closeChecklist() {
  dispatch(actions.changeChecklistVisibility(false));
}

export function openTaxAndFeesOverlay() {
  dispatch(actions.changeTaxAndFeesOverlayVisibility(true));
}

export function closeTaxAndFeesOverlay() {
  dispatch(actions.changeTaxAndFeesOverlayVisibility(false));
}

export async function addAgentToOrder(orderId) {
  dispatch(actions.updatingOrder());
  try {
    await utilsV2.addAgentToOrder(orderId);
    dispatch(actions.updatedOrder());
  } catch (error) {
    dispatch(actions.notUpdatedOrder());
    const message = utils.getErrorMessage(error);
    notifications.error({
      title: 'Error updating order',
      message,
    });
  }
}

export async function updateOrderV2(data) {
  setChecklist(ORDER_CHECKLIST.HAS_VALIDATED, true);
  dispatch(actions.updatingOrder());
  try {
    const updatedOrder = await utilsV2.updateOrder({ order: data });

    if (
      !transcriptsSelectors.transcripts().hasFetchedTranscripts &&
      !transcriptsSelectors.transcripts().pending.isFetchingTranscripts
    ) {
      await transcriptsAPI.retrieveAllTranscripts();
      await transcriptsAPI.connectStream();
    }

    dispatch(actions.updatedOrder(updatedOrder));
    dispatch(cartSlice.setIsValid(updatedOrder.validation.isValid));
  } catch (error) {
    dispatch(actions.notUpdatedOrder());
    const message = utils.getErrorMessage(error);
    notifications.error({
      title: 'Error updating order',
      message,
      onDismiss: () => setChecklist(ORDER_CHECKLIST.HAS_VALIDATED, false),
    });
  }
}

export async function updateOrder(data, params = {}) {
  dispatch(actions.updatingOrder());
  try {
    const updatedOrder = await utils.updateOrder(data, params);

    const isCartEmpty = store.getState().cart.cartItems.ids.length === 0;
    const cartHasErrors = store.getState().cart.cartItems.hasError;
    if (!isCartEmpty && !cartHasErrors) {
      await validate();
    }

    if (
      updatedOrder.agent_id &&
      !transcriptsSelectors.transcripts().hasFetchedTranscripts &&
      !transcriptsSelectors.transcripts().pending.isFetchingTranscripts
    ) {
      await transcriptsAPI.retrieveAllTranscripts();
      await transcriptsAPI.connectStream();
    }

    dispatch(actions.updatedOrder(updatedOrder));
  } catch (error) {
    dispatch(actions.notUpdatedOrder());
    const message = utils.getErrorMessage(error);
    notifications.error({
      title: 'Error updating order',
      message,
      onDismiss: () => setChecklist(ORDER_CHECKLIST.HAS_VALIDATED, false),
    });
  }
}

export async function endOrder() {
  dispatch(actions.endOrder());
}

export async function validate(version = 'v3', params = {}) {
  const { useOrderService } = getStoreFeatureFlags(store.getState());
  const isCartEmpty = store.getState().cart.cartItems.ids.length === 0;

  if (useOrderService) {
    return;
  }

  dispatch(actions.validatingBasket());

  try {
    const { data, config } = await utils.validateBasket(version);

    if (!selectors.isLastValidation(config.headers.attempts)) {
      return dispatch(actions.validationDismissed());
    }

    dispatch(actions.validatedBasket(data));

    setDetails(
      'oloEstimation',
      utils.getOloEstimation(data.validated_basket.readytime),
    );

    return data.validated_basket ?? data.validatedBasket;
  } catch (error) {
    const attempt = error?.response?.config?.headers?.attempts;

    if (!selectors.isLastValidation(attempt)) {
      return;
    }

    dispatch(actions.notValidatedBasket());

    // IF WE'RE IN THE MIDDLE OF ADDING TO CART, THE STATE WILL NOT BE UPDATED YET
    if (params.isAddingToCart || !isCartEmpty) {
      // TODO: this will be removed after we move away from the old data-service
      if (error.response.data.error === 'CAPACITY_THROTTLED') {
        dispatch(types.SET_ORDER_THROTTLING, true);
        errors.capacityThrottledError();
        return;
      }

      errors.validateError(error);
      return { errorMessage: utils.getValidationErrorMessage(error) };
    }
  }
}

export async function submit() {
  dispatch(actions.submittingOrder());

  try {
    await utils.submitOrder();
    dispatch(actions.submittedOrder());
    store.dispatch(announce({ text: utils.getVBxClosingMessage() }));
    setOrderStatusAsSubmitted();
  } catch (error) {
    closeSubmitOverlay();
    dispatch(actions.notSubmittedOrder());
    errors.submitError(error);
  }
}

export async function sync() {
  dispatch(actions.orderSyncing());

  try {
    await utils.syncOrder();
    dispatch(actions.orderSynced());
  } catch {
    dispatch(actions.orderNotSynced());
  }
}

export function reset() {
  dispatch(actions.reset());
  dispatch(cartSlice.resetCart());
}

export async function dismissOrder() {
  const { useOrderService } = getStoreFeatureFlags(store.getState());
  if (!deviceSelectors.hasForwarded()) {
    if (useOrderService) {
      await updateOrderV2({
        cancellationReason: 'Agent hangup',
        status: 'dismissed',
      });
    } else {
      await updateOrder({
        cancellation_reason: 'Agent hangup',
        status: 'Dismissed',
        end_time: new Date(),
      });
    }
  }
}

export async function createOrderEvent(name, tags = {}, orderId = null) {
  dispatch(actions.eventCreating());

  try {
    await utils.createOrderEvent(name, tags, orderId);
    dispatch(actions.eventCreated());
  } catch {
    dispatch(actions.eventNotCreated());
  }
}

export async function createAnalyticsEvent(eventName, eventInfo) {
  dispatch(actions.eventCreating());

  try {
    await utilsV2.createAnalyticsEvent(eventName, eventInfo);
    dispatch(actions.eventCreated());
  } catch {
    dispatch(actions.eventNotCreated());
  }
}

export async function submitSurvey(tags = {}, orderId = null) {
  dispatch(actions.eventCreating());

  try {
    await utilsV2.submitSurvey(tags, orderId);
    dispatch(actions.eventCreated());
  } catch {
    dispatch(actions.eventNotCreated());
  }
}

export function setItemsValidationStatus(validationResult) {
  const { useOrderService } = getStoreFeatureFlags(store.getState());
  dispatch(
    actions.setItemsValidationStatus({ validationResult, useOrderService }),
  );
}

export async function fetchAvailableTimes() {
  dispatch(types.FETCHING_AVAILABLE_TIMES);

  try {
    const response = await utils.fetchAvailableTimes();

    dispatch(types.FETCHED_AVAILABLE_TIMES, {
      availableTimes: response,
    });

    dispatch(types.AUTO_SCHEDULE, { timezone: deprecatedGetStoreTimezone() });

    return response;
  } catch (error) {
    dispatch(types.NOT_FETCHED_AVAILABLE_TIMES);
  } finally {
    const wasAutoScheduled = selectors.getWasAutoScheduled();

    if (!wasAutoScheduled) {
      setVisibleTab('details');
    }
  }
}

export function updateItemsStatus(validation) {
  setItemsValidationStatus(
    validation.errors.map((error) => ({
      cartItemId: error.cartItemId,
      status: error.code,
    })),
  );
}

export function setVisibleTab(tab) {
  dispatch(types.SET_VISIBLE_TAB, tab);
}
