import { types } from './types';

export function agentFetching(target) {
  return {
    target,
    type: types.AGENT_FETCHING,
  };
}

export function agentFetched(payload) {
  return {
    target: payload.id,
    type: types.AGENT_FETCHED,
    payload,
  };
}

export function agentNotFetched() {
  return {
    type: types.AGENT_NOT_FETCHED,
  };
}

export function agentReset() {
  return {
    type: types.AGENT_RESET,
  };
}
