import { useMemo } from 'react';

import { setChecklist } from '@modules/order/api';
import { useAccount } from '@hooks';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import AnnounceBadge, { AnnounceBadgeProps } from '../AnnounceBadge';

type FirstNameBadgeProps = Omit<
  AnnounceBadgeProps,
  'message' | 'onClick' | 'children'
>;

export function FirstNameBadge(props: FirstNameBadgeProps) {
  const { accountDetails } = useAccount();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const nameMessage = useMemo(() => {
    const { firstName } = accountDetails;
    if (firstName) return `I have you down as ${firstName}, is that correct?`;

    return 'Can I have your first name and last initial?';
  }, [accountDetails.firstName]);

  function onBadgeClick() {
    setChecklist(ORDER_CHECKLIST.ARE_FIELDS_FILLED, !!accountDetails.firstName);
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: nameMessage },
    });
  }

  return (
    <AnnounceBadge onClick={onBadgeClick} message={nameMessage} {...props}>
      First Name
    </AnnounceBadge>
  );
}
