const bearerTokenRegex = /Bearer\s+[a-zA-Z0-9._-]+/g;
const twilioWorkerSidRegex = /WK[A-Za-z0-9]{32}/g;
const twilioTokensRegex = /ey+[a-zA-Z0-9._-]+/g;
const auth0UserIdRegex = /google-oauth2\|[a-zA-Z0-9_-]+/g;
const apiKeyTokenRegex = /ApiKey\s+[a-zA-Z0-9._-]+/g;
const emailRegex = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/g;

const regexPatterns = [
  bearerTokenRegex,
  twilioWorkerSidRegex,
  twilioTokensRegex,
  auth0UserIdRegex,
  apiKeyTokenRegex,
  emailRegex,
];

export function filterSensitiveData(data: Record<string, unknown>) {
  const visitedObjects = new WeakSet();

  const stringifiedData = JSON.stringify(data, (_, value) => {
    // If a circular reference is found, replace it with a placeholder
    if (typeof value === 'object' && value !== null) {
      if (visitedObjects.has(value)) {
        return '[Circular Reference]';
      }
      visitedObjects.add(value);
    }
    return value;
  });

  const filteredData = regexPatterns.reduce(
    (acc, regex) => acc.replace(regex, '********'),
    stringifiedData,
  );

  return JSON.parse(filteredData);
}
