import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory as useRRDHistory } from 'react-router-dom';

export const HistoryContext = createContext({});

const methodsToBeReplaced = ['push', 'replace'];

export const HistoryProvider = ({ children }) => {
  const history = useRRDHistory();
  const [shouldBlock, setShouldBlock] = useState(false);
  const [openBlocker, setOpenBlocker] = useState(false);

  const actions = {};
  actions.block = (value) => setShouldBlock(value);

  methodsToBeReplaced.forEach((method) => {
    actions[method] = (path) => {
      if (shouldBlock) {
        setOpenBlocker(true);
      } else {
        history[method](path);
      }
    };
  });

  useEffect(() => {
    if (shouldBlock && !openBlocker) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [shouldBlock]);

  useEffect(
    () => () => {
      window.onbeforeunload = null;
    },
    [],
  );

  return (
    <HistoryContext.Provider value={{ actions }}>
      <>{children}</>
    </HistoryContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
