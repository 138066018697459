import styled from 'styled-components';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { Typography, theme, Button as ButtonP } from '@kea-inc/parrot-ui';

export const Container = styled.section`
  height: 460px;
  width: 350px;
  text-align: center;
`;

export const Title = styled(Typography)`
  margin-top: 10px;
`;

export const Content = styled.article`
  display: grid;
  grid-row-gap: 5px;
  grid-column-gap: 16px;
  grid-template-columns: 1.5fr 1fr;
  height: auto;
  text-align: right;
  padding: 16px;
  transition: 0.1s all ease-in-out;
  width: 100%;
  overflow: hidden;
  margin-top: 10px;

  div {
    text-align: center;
    margin-right: 50%;
  }
`;

export const CheckCircle = styled(MdCheckCircle)`
  color: ${theme.colors.success};
  width: 24px;
  height: 24px;
`;

export const ErrorCircle = styled(MdError)`
  color: ${theme.colors.error};
  width: 24px;
  height: 24px;
`;

export const Status = styled.div`
  height: 30px;
`;

export const Button = styled(ButtonP)`
  display: initial;
  margin-top: 40px;
`;
