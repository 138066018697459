import React from 'react';

import AnnounceBadge from '@components/AnnounceBadge';
import { useUpsell } from '@hooks';
import * as orderAPI from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import { getBrandFeatureFlags } from '@modules/featureFlags/selectors';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useAppSelector } from '@store';

export function UpsellBadge({ ...props }) {
  const {
    upsellMessage,
    shouldUpsell,
    setUpsoldByAgent,
    entities,
    setUpsoldEntities,
    matchingUpsell,
    handleUpsell,
  } = useUpsell();

  const { useUpsellV2 } = useAppSelector(getBrandFeatureFlags);

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  function handleClick() {
    orderAPI.setChecklist(ORDER_CHECKLIST.HAS_UPSOLD_ONCE, true);

    if (useUpsellV2) {
      handleUpsell();
      orderAPI.createOrderEvent('clicked_upsell_button', matchingUpsell);
      orderAPI.createAnalyticsEvent('attempted_upsell', matchingUpsell);
      return;
    }

    const [first, second] = entities;
    orderAPI.createOrderEvent('clicked_upsell_button', {
      first,
      second,
    });
    orderAPI.createAnalyticsEvent('attempted_upsell', {
      first,
      second,
    });

    setUpsoldEntities((prev) => prev.concat([first, second].filter(Boolean)));
    setUpsoldByAgent(true);

    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: upsellMessage },
    });
  }

  return (
    <AnnounceBadge
      message={upsellMessage}
      onClick={handleClick}
      disabled={!shouldUpsell}
      {...props}
    >
      Upsell
    </AnnounceBadge>
  );
}
