import { Option } from '@modules/menu/types';
import { OrderCartItemOption } from '@modules/order/types';
import { Dictionary } from '@reduxjs/toolkit';
import { shouldHideOptionInTheCart } from './should-hide-option-in-the-cart';

export function transformToCartOption(
  menuOptionsById: Dictionary<Option>,
  cartItemOptions?: OrderCartItemOption[],
) {
  if (!cartItemOptions || !cartItemOptions.length) {
    return [];
  }

  const cartOptions: CartOption[] = [];

  cartItemOptions.forEach((cartItemOption) => {
    const menuOption = menuOptionsById[cartItemOption.id];

    if (
      !menuOption ||
      shouldHideOptionInTheCart(menuOption, cartItemOption.quantity)
    ) {
      return;
    }

    const isAlreadyAdded = cartOptions.some((o) => o.id === menuOption.id);
    if (!isAlreadyAdded) {
      cartOptions.push({
        id: cartItemOption.id,
        quantity: cartItemOption.quantity,
        name:
          cartItemOption.quantity === 0
            ? `NO ${menuOption.name}`
            : menuOption.name,
        isHiddenInCart: false,
        showQuantity: cartItemOption.quantity > 1,
        ident: 1,
      });
    }

    if (menuOption.modifierIds.length) {
      const parentCartOption = cartOptions.find(
        (co) => co.id === menuOption.id,
      );
      const parentIdent = parentCartOption ? parentCartOption.ident : 1;

      cartItemOptions.forEach((o) => {
        const opt = menuOptionsById[o.id];

        if (
          opt &&
          opt.ancestorIds.some((id) => menuOption.modifierIds.includes(id))
        ) {
          cartOptions.push({
            id: o.id,
            quantity: o.quantity,
            name: o.quantity === 0 ? `NO ${opt.name}` : opt.name,
            isHiddenInCart: shouldHideOptionInTheCart(opt, o.quantity),
            showQuantity: o.quantity > 1,
            ident: parentIdent + 3,
          });
        }
      });
    }
  });

  return cartOptions;
}

interface CartOption {
  id: string;
  name?: string;
  quantity: number;
  showQuantity: boolean | null;
  ident: number;
  isHiddenInCart?: boolean;
}
