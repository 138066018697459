import { useMemo } from 'react';

import { useAppSelector } from '@store';
import { selectCurrentCartItemId } from '@modules/cart/selectors';

import { Searching } from './Searching';
import { ItemModifiers } from './ItemModifiers';
import { CategoryItems } from './CategoryItems';
import { Categories } from './Categories';

import * as S from './styles';
import { deliberateScreen } from './utils';

export function Menu() {
  const currentCategory = useAppSelector(
    (state) => state.menu.categories.current,
  );
  const currentItem = useAppSelector((state) => state.menu.items.current);
  const searchTerm = useAppSelector((state) => state.menu.items.searchTerm);
  const currentCartItemId = useAppSelector(selectCurrentCartItemId);

  const screen = useMemo(
    () =>
      deliberateScreen(
        currentItem,
        currentCartItemId,
        currentCategory,
        searchTerm,
      ),
    [currentItem, currentCartItemId, currentCategory, searchTerm],
  );

  return (
    <S.MenuContainer>
      {screen === 'searching' && <Searching />}
      {screen === 'modifiers' && <ItemModifiers itemId={currentItem!} />}
      {screen === 'category' && <CategoryItems categoryId={currentCategory!} />}
      {screen === 'categories' && <Categories />}
    </S.MenuContainer>
  );
}
