import React, { useEffect, useState } from 'react';
import { AiOutlineCheck, AiFillCheckCircle } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';

import { initializeTaskrouter } from '@modules/taskrouter/actions';
import { selectors as featureFlagsSelectors } from '@modules/featureFlags';

import { useAppDispatch, useAppSelector } from '@store';
import { useAgents, useDevice } from '@hooks';
import { Loading, Greetings } from '@components';

import { Typography, Chip, Button } from '@kea-inc/parrot-ui';

import { getUserFeatureFlags } from '@modules/featureFlags/selectors';

import { useStore } from './useStore';
import * as S from './styles';
import { EdgeChooser } from './EdgeChoose';
import { PreflightReport } from './PreflightReport';
import { TrainingModeCard } from './TrainingModeCard';

const Home = () => {
  const [isReportOpen, setIsReportOpen] = useState(false);
  const { user, brands } = useStore();
  const { preflight } = useDevice();
  const { current, byId } = useAgents();
  const dispatch = useAppDispatch();
  const useUoaTrainingMode =
    useAppSelector(getUserFeatureFlags)?.useUoaTrainingMode;

  const isFetchingFeatureFlags = useAppSelector(
    featureFlagsSelectors.isFetching,
  );

  useEffect(() => {
    const agent = byId[current];
    if (agent && agent.twilio_worker_sid) {
      dispatch(initializeTaskrouter(agent.twilio_worker_sid));
    }
  }, []);

  if (!user || isFetchingFeatureFlags) {
    return <Loading />;
  }

  return (
    <S.Container large={useUoaTrainingMode}>
      <Greetings
        greetings={`Hello, ${user.given_name}!`}
        headline="You'll do great today 😄"
      />
      <S.PreflightContainer>
        <S.BrandsContainer>
          {brands.length === 0 ? (
            <div className="no_brands">
              <Typography color="black_light">
                It seems you don&apos;t have any brand to show with this filter.
              </Typography>
            </div>
          ) : (
            <>
              <header className="brands__header">
                <Typography color="gray_darker">BRANDS</Typography>
                <Typography color="gray_darker">STATUS</Typography>
              </header>
              <ul className="brands__list">
                {brands.map((brand) => (
                  <li key={brand.id} className="brand">
                    <Typography weight="heavy">{brand.name}</Typography>
                    {brand.queued ? (
                      <Chip
                        className="chip__queued"
                        leftIcon={AiFillCheckCircle}
                        selected
                      >
                        Queued
                      </Chip>
                    ) : (
                      <Chip leftIcon={AiOutlineCheck} selected>
                        Trained
                      </Chip>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </S.BrandsContainer>

        {useUoaTrainingMode && <TrainingModeCard />}
      </S.PreflightContainer>

      <S.FooterContainer large={useUoaTrainingMode}>
        <EdgeChooser />

        {preflight && (
          <Button
            tertiary
            className="preflightResults__Open"
            RightIcon={BsArrowRight}
            onClick={() => setIsReportOpen(true)}
          >
            See results
          </Button>
        )}

        <PreflightReport open={isReportOpen} setOpen={setIsReportOpen} />
      </S.FooterContainer>
    </S.Container>
  );
};

export default Home;
