import { theme } from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';

const Shared = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

export const None = styled.div`
  ${Shared};
  border: 1px dashed ${theme.colors.gray};

  svg {
    color: ${theme.colors.gray_dark};
  }
`;

export const Added = styled.div`
  ${Shared};
  background: ${theme.colors.success_lightest_2};

  svg {
    color: ${theme.colors.success};
  }
`;

export const Remove = styled.div`
  ${Shared};
  background: ${theme.colors.error_lightest_2};

  svg {
    color: ${theme.colors.error};
  }
`;
