import { createEntityAdapter } from '@reduxjs/toolkit';
import { CartItem, InitialCartState } from './types';

export const cartItemsAdapter = createEntityAdapter<CartItem>({
  selectId: (cartItem) => cartItem.id,
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

export const initialCartState: InitialCartState = {
  cartItems: cartItemsAdapter.getInitialState({
    current: null,
    isAddingCartItem: false,
    isUpdatingCartItem: false,
    isRemovingCartItem: false,
    hasError: false,
    history: [],
  }),
  pricing: {
    subtotal: 0,
    total: 0,
    taxAndFees: 0,
    discount: 0,
    totalFees: 0,
    totalTaxes: 0,
    customerHandoffCharge: 0,
    taxes: [],
    fees: [],
    coupons: [],
    isFetchingPrices: false,
    hasPricingError: false,
  },
  validation: {
    isValid: false,
    errors: [],
    errorsByCartItemId: {},
  },
  preparationTimeRange: {
    preparationTimeRangeStart: undefined,
  },
  hasPreexistingCart: false,
  isFetchingPreexistingCart: false,
  hasPreexistingCartError: false,
};
