import { PlatformStore } from '@kea-inc/types';

import { relayToArray } from '@utils/relay-to-array';
import { apolloClient } from '../../graphql/apollo-client';
import * as schema from '../../graphql/generated/schema';

export class MenuClient {
  async getModifiersByParentId(platformStore: PlatformStore, parentId: string) {
    const response = await apolloClient.query<
      schema.GetModifiersFromParentV2Query,
      schema.GetModifiersFromParentV2QueryVariables
    >({
      query: schema.GetModifiersFromParentV2Document,
      variables: { storeId: platformStore.storeId, parentId },
    });

    return relayToArray(response.data.modifiers);
  }
}
