import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;

  background: ${theme.colors.black};
  opacity: 0.5;
`;

export const Container = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  background: ${theme.colors.white};
  width: 40vw;

  z-index: 99999;
  padding: 30px;
  overflow-y: scroll;
  transition: 0.5s all;
  transform: ${(props) => (props.open ? 'translateX(0%)' : 'translateX(150%)')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.main`
  margin-top: 30px;
`;

export const GeneralInfo = styled.div`
  display: flex;

  > div {
    margin-right: 12px;
  }
`;

export const TotalsInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  > div {
    margin-right: 12px;
  }

  svg {
    margin-bottom: 8px;
  }
`;

export const CallQuality = styled.div`
  margin-top: 20px;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const StatsCards = styled.div`
  margin-top: 20px;

  h3,
  > div {
    margin-bottom: 12px;
  }

  .card_footer > div {
    margin-right: 8px;
  }
`;

export const Warnings = styled.div`
  margin-top: 20px;

  h3,
  > div {
    margin-bottom: 12px;
  }

  > div {
    width: 100%;
  }
`;

export const Footer = styled.footer`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 8px;
  }
`;
