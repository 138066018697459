import { useMemo } from 'react';

import pluralize from 'pluralize';
import { theme, Typography } from '@kea-inc/parrot-ui';
import { MdNotInterested, MdVolumeUp } from 'react-icons/md';

import {
  makeItemsUnderCategoryPhrase,
  formatPrice,
  makeItemUnavailablePhrase,
} from '@utils';
import { useOrder } from '@hooks';
import { AnnounceBadge, AnnounceIcon } from '@components';

import {
  selectCategoryById,
  selectItemsByCategory,
  selectLinkedSpecialsById,
} from '@modules/menu/selectors';

import { useAppDispatch, useAppSelector } from '@store';
import { getModifiersFromParent, navigateToItem } from '@modules/menu/actions';
import { announce } from '@modules/device/device-actions';
import { getOrderHandoffMode } from '@modules/order/selectors';
import { getHandoffModes } from '@modules/taskrouter/selectors';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import * as S from './styles';
import { isItemAvailable } from '../utils';

type CategoryItemsProps = {
  categoryId: string;
};

export const CategoryItems = ({ categoryId }: CategoryItemsProps) => {
  const dispatch = useAppDispatch();

  const menu = useAppSelector((state) => state.menu);

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const category = useAppSelector((state) =>
    selectCategoryById(state.menu.categories, categoryId),
  );

  if (!category) {
    return null;
  }

  const categoryItems = useAppSelector((state) =>
    selectItemsByCategory(state.menu.items)(categoryId),
  );

  const { orderDetails } = useOrder();

  const formattedCategoryName = `${category.name} (${categoryItems.length})`;

  const itemsWithAvailability = useMemo(
    () =>
      categoryItems.map((item) => {
        if (item.linkedSpecialItemId && item.linkedSpecialItemId !== '') {
          const productItem = selectLinkedSpecialsById(
            menu.linkedSpecials,
            item.linkedSpecialItemId || '',
          );

          const isAvailable =
            productItem &&
            isItemAvailable(productItem, orderDetails.handOffMode);

          if (isAvailable) {
            return {
              ...productItem,
              isAvailable,
            };
          }
        }
        return {
          ...item,
          isAvailable: isItemAvailable(item, orderDetails.handOffMode),
        };
      }),
    [categoryItems, orderDetails.handOffMode, menu.linkedSpecials],
  );

  const message = useMemo(
    () =>
      makeItemsUnderCategoryPhrase(
        category,
        categoryItems,
        orderDetails.handOffMode,
      ),
    [category, orderDetails.handOffMode],
  );

  const whichMessage = useMemo(() => {
    if (category.customWhich) {
      return category.customWhich;
    }

    return `Which ${pluralize(category.name, 1)} would you like?`;
  }, [category]);

  const prefetchModifiers = (itemId: string, status: string) => {
    if (status === 'fulfilled' || status === 'pending') {
      return;
    }

    dispatch(
      getModifiersFromParent({
        parentId: itemId,
      }),
    );
  };

  const handleNavigateToItem = (itemId: string) => {
    dispatch(
      navigateToItem({
        itemId,
      }),
    );
  };

  const handleAnnounceUnavailable = (
    spokenName: string,
    unavailableHandoffModes: string[],
  ) => {
    const allHandoffModes = getHandoffModes();
    const currentHandoffMode = getOrderHandoffMode();

    const phrase = makeItemUnavailablePhrase(
      {
        spokenName,
        unavailableHandoffModes,
      },
      currentHandoffMode,
      allHandoffModes,
    );
    dispatch(announce({ text: phrase }));
  };

  const descriptionMessage = `${category.name}: ${category.description}`;

  const handleWhichClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.WHICH_ITEMS,
        message: whichMessage,
        categoryId: category.id,
      },
    });
  };
  const handleListClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.LIST_ITEMS,
        message,
        categoryId: category.id,
      },
    });
  };
  const handleDescriptionAnnounceClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: {
        id: MENU_BUTTON_IDS.DESCRIPTION_CATEGORY,
        message: descriptionMessage,
        categoryId: category.id,
      },
    });
  };

  return (
    <S.Container>
      <div className="category__title">
        <Typography variant="subheader" color="primary_darker" weight="heavy">
          {formattedCategoryName}
        </Typography>
        {category.status === 'pending' && <S.Loading />}

        <AnnounceBadge
          message={whichMessage}
          className="badge__Which"
          variant="accent_2"
          onClick={handleWhichClick}
        >
          Which?
        </AnnounceBadge>
        <AnnounceBadge
          variant="accent_2"
          message={message}
          data-value={message}
          onClick={handleListClick}
        >
          List
        </AnnounceBadge>
      </div>

      {!!category.description && (
        <Typography
          variant="caption"
          color="gray_darker"
          className="description"
        >
          {category.description}
          <AnnounceIcon
            message={descriptionMessage}
            size={25}
            variant="primary"
            onClick={handleDescriptionAnnounceClick}
          />
        </Typography>
      )}

      <S.ItemsList>
        {itemsWithAvailability?.map((item) => (
          <S.Item
            key={item.id}
            unavailable={!item.isAvailable}
            // @ts-expect-error: missing on parrot definition, but it works
            onMouseEnter={() => prefetchModifiers(item.id, item.status)}
            onClick={() =>
              item.isAvailable
                ? handleNavigateToItem(item.id)
                : handleAnnounceUnavailable(
                    item.spokenName,
                    item.unavailableHandoffModes,
                  )
            }
          >
            <div className="left">
              <div className="item__Wrapper">
                {!item.isAvailable && (
                  <MdNotInterested color={theme.colors.error} size={14} />
                )}
                <Typography
                  weight="heavy"
                  color="primary_darker"
                  className="item__Name"
                >
                  {item.name}
                </Typography>
              </div>
              <Typography weight="heavy" className="price">
                {formatPrice(item.cost)}
              </Typography>
            </div>

            <div className="right">
              {!item.isAvailable && (
                <MdVolumeUp color={theme.colors.primary} size={18} />
              )}
            </div>
          </S.Item>
        ))}
      </S.ItemsList>
    </S.Container>
  );
};
