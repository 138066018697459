import {
  Worker,
  Reservation,
  WorkerEvents,
  ReservationEvents,
} from 'twilio-taskrouter';
import env from '@beam-australia/react-env';

import logger from '@logger';
import { FakeTwilioWorker } from './twilio-utils';

class TaskRouterService {
  token: string | null = null;

  worker: Worker | null = null;

  reservation: Reservation | null = null;

  workerEvents: Array<keyof WorkerEvents> = [];

  reservationEvents: Array<keyof ReservationEvents> = [];

  skipPreflight?: string = env('SKIP_PREFLIGHT');

  setup() {
    try {
      if (!this.token) {
        logger.error('TaskRouter | setup: token not set');
        return;
      }

      if (this.worker) {
        logger.info('TaskRouter | setup: worker already exists');
        return;
      }

      if (this.skipPreflight) {
        // @ts-ignore - Fake implementation of Twilio
        this.worker = new FakeTwilioWorker();
      } else {
        this.worker = new Worker(this.token, {
          closeExistingSessions: true,
          logLevel: 'info',
        });
      }
    } catch (error) {
      // Worker creation tries to connect to Twilio, hence the try-catch
      logger.error('TaskRouter | setup: error', error);
    }
  }

  setToken(token: string, options?: { renew?: boolean }) {
    this.token = token;
    if (options?.renew && this.worker) {
      this.worker.updateToken(token);
    }
  }

  updateReservation(reservation: Reservation) {
    this.reservation = reservation;
  }

  setWorkerEvent<E extends keyof WorkerEvents>(
    name: E,
    cb: (data: Parameters<WorkerEvents[E]>[0]) => void,
  ) {
    if (!this.worker) {
      logger.error('TaskRouter | setWorkerEvent: worker not set');
      return;
    }

    const alreadyExists = this.workerEvents.includes(name);
    if (alreadyExists) {
      logger.info(`TaskRouter | setWorkerEvent: event ${name} already exists`);
      return;
    }

    this.workerEvents.push(name);
    logger.info(`TaskRouter | setWorkerEvent: ${name}`);

    this.worker.on(name, (data?: Parameters<WorkerEvents[E]>[0]): void => {
      logger.info(`TaskRouter worker event received: ${name}`, {
        eventDebugData: { ...data },
      });
      cb(data);
    });
  }

  setReservationEvent<E extends keyof ReservationEvents>(
    name: E,
    cb: (data: Parameters<ReservationEvents[E]>[0]) => void,
  ) {
    if (!this.reservation) {
      logger.error('TaskRouter | setReservationEvent: reservation not set');
      return;
    }

    const alreadyExists = this.reservationEvents.includes(name);
    if (alreadyExists) {
      logger.info(
        `TaskRouter | setReservationEvent: event ${name} already exists`,
      );
      return;
    }

    this.reservationEvents.push(name);
    logger.info(`TaskRouter | setReservationEvent: ${name}`);

    this.reservation.on(name, (data: Parameters<ReservationEvents[E]>[0]) => {
      logger.info(`TaskRouter reservation event received: ${name}`);
      cb(data);
    });
  }

  reset() {
    this.reservation = null;
    this.reservationEvents = [];
  }
}

export default new TaskRouterService();
