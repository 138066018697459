import { Card } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@styles/loading';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;

  &.received__calling {
    flex-direction: column;

    > button {
      margin-top: 2.75rem;
      min-width: 12.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const Connecting = styled.div`
  position: relative;
  max-width: 500px;
  max-height: 500px;
`;

export const { Loading } = GlobalStyles;
