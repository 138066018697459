import React, { useEffect, useState, useContext, useMemo } from 'react';
import { getUpsellTargetEntities, getUpsellPhrasing } from '@kea-inc/order';
import { MenuUpsellTarget } from '@kea-inc/order/dist/upsell/types';

import { useOrder, useAccount, useUpsellV2, useStoreHours } from '@hooks';
import { Item, MenuUpsell, Option } from '@modules/menu/types';

import * as orderAPI from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import { getBrandFeatureFlags } from '@modules/featureFlags/selectors';
import { useAppSelector } from '@store';
import { Dictionary } from '@reduxjs/toolkit';
import { selectAllCartItems } from '@modules/cart/selectors';
import { CartItem } from '@modules/cart/types';

type UpsellProviderProps = {
  children: React.ReactNode;
};

type UpsellContextValues = {
  upsellMessage: string;
  setUpsoldByAgent: (value: boolean) => void;
  setUpsoldEntities: (value: MenuUpsellTarget[]) => void;
  upsoldEntities: MenuUpsellTarget[];
  entities: MenuUpsellTarget[];
  shouldUpsell: boolean;
};

const UpsellContext = React.createContext<UpsellContextValues>(
  {} as UpsellContextValues,
);

export function UpsellProvider({ children }: UpsellProviderProps) {
  const menuUpsells = useAppSelector((state) => state.menu.menuUpsells);
  const menuItemsById = useAppSelector((state) => state.menu.items.entities);
  const menuOptionsById = useAppSelector(
    (state) => state.menu.options.entities,
  );
  const cartItems = useAppSelector((state) =>
    selectAllCartItems(state.cart.cartItems),
  );
  const cartItemsHistory = useAppSelector(
    (state) => state.cart.cartItems.history,
  );

  const { useUpsellV2: useUpsellV2FF } = useAppSelector(getBrandFeatureFlags);

  const [upsoldByAgent, setUpsoldByAgent] = useState(false);
  const [upsellHasBeenDisabled, setUpsellHasBeenDisabled] = useState(false);
  const [upsoldEntities, setUpsoldEntities] = useState<MenuUpsellTarget[]>([]);
  const { previousOrderStatus } = useAccount();
  const { timeAtStore } = useStoreHours();
  const { handOffMode } = useOrder();

  const upsellEntities = useMemo(
    () =>
      getUpsellTargetEntities(
        // @ts-expect-error - MenuAvailability type has a different shape in the @kea-inc/order package
        transformToMenuEntities(menuUpsells),
        getCartItemsHistory(
          cartItemsHistory,
          menuItemsById,
          menuOptionsById,
          cartItems,
        ),
        {
          storeTimeISO: timeAtStore.toISO(),
        },
      ),
    [cartItemsHistory.length, menuUpsells.length],
  );

  const leftUpsellEntities = useMemo(
    () =>
      upsellEntities
        .filter(
          (item) => !upsoldEntities.some((element) => element.id === item.id),
        )
        ?.sort((a, b) => (a.upsellPriority > b.upsellPriority ? 1 : -1)),
    [upsellEntities.length, upsoldEntities.length],
  );

  useEffect(() => {
    if (!useUpsellV2FF) {
      orderAPI.setChecklist(
        ORDER_CHECKLIST.HAS_UPSOLD_ONCE,
        upsellEntities.length === 0 ||
          upsoldByAgent ||
          menuUpsells?.length === 0,
      );
    }
  }, [
    upsellEntities.length,
    upsoldByAgent,
    menuUpsells.length,
    previousOrderStatus,
    handOffMode,
  ]);

  useEffect(() => {
    if (upsoldByAgent && leftUpsellEntities?.length === 0) {
      setUpsellHasBeenDisabled(true);
    }
  }, [upsoldByAgent, leftUpsellEntities]);

  return (
    <UpsellContext.Provider
      value={{
        upsellMessage: `Would you like ${getUpsellPhrasing(
          leftUpsellEntities,
        )}`,
        setUpsoldByAgent,
        setUpsoldEntities,
        upsoldEntities,
        entities: leftUpsellEntities,
        shouldUpsell:
          upsellEntities.length > 0 &&
          menuUpsells?.length > 0 &&
          !upsellHasBeenDisabled,
      }}
    >
      {children}
    </UpsellContext.Provider>
  );
}

function useOldUpsell() {
  return useContext(UpsellContext);
}

function transformToMenuEntities(menuUpsells: MenuUpsell[]) {
  return menuUpsells.map((entity) => ({
    id: entity.id,
    name: entity.name,
    parentId: entity.parentId,
    spokenName: entity.spokenName,
    isUpsellTarget: entity.isUpsellTarget,
    upsellPriority: entity.upsellPriority,
    upsellPhrase: entity.upsellPhrase || undefined,
    availabilityStart: entity.availabilityStart,
    availabilityEnd: entity.availabilityEnd,
    hasStockAvailable: entity.hasStockAvailable,
    availabilitySchedule: entity.availabilitySchedule,
  }));
}

function getCartItemsHistory(
  cartItemHistory: {
    id: string;
    menuItemId: string;
    options?: string[] | undefined;
  }[],
  menuItemsById: Dictionary<Item>,
  menuOptionsById: Dictionary<Option>,
  cartItems: CartItem[],
) {
  return cartItemHistory.reduce<
    Record<
      string,
      {
        parentId: string;
        optionNames?: string[];
        isCurrent: boolean;
      }
    >
  >((acc, historyCartItem) => {
    const menuItem = menuItemsById[historyCartItem.menuItemId];

    if (!menuItem) {
      return acc;
    }
    acc[historyCartItem.menuItemId] = {
      parentId: menuItem.parentId,
      optionNames: getOptionsNames(menuOptionsById, historyCartItem.options),
      isCurrent: cartItems.some((ci) => ci.id === historyCartItem.id),
    };

    return acc;
  }, {});
}

function getOptionsNames(
  menuOptionsById: Dictionary<Option>,
  options?: string[],
) {
  if (!options) {
    return;
  }

  return options
    .map((optionId) => menuOptionsById[optionId]?.name || '')
    .filter(Boolean);
}

export function useUpsell() {
  const useHook = useAppSelector(getBrandFeatureFlags)?.useUpsellV2
    ? useUpsellV2
    : useOldUpsell;

  return useHook() as UpsellContextValues;
}
