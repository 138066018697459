import { types } from './types';

export function paymentStreamClosed() {
  return {
    type: types.STREAM_CLOSED,
  };
}

export function paymentStreamConnecting() {
  return {
    type: types.STREAM_CONNECTING,
  };
}

export function paymentStreamConnected() {
  return {
    type: types.STREAM_CONNECTED,
  };
}

export function paymentStreamNotConnected() {
  return {
    type: types.STREAM_NOT_CONNECTED,
  };
}

export function paymentPaybotConnecting() {
  return {
    type: types.PAYBOT_CONNECTING,
  };
}

export function paymentPaybotConnected() {
  return {
    type: types.PAYBOT_CONNECTED,
  };
}

export function paymentPaybotNotConnected() {
  return {
    type: types.PAYBOT_NOT_CONNECTED,
  };
}

export function paymentPaybotTerminating() {
  return {
    type: types.PAYBOT_TERMINATING,
  };
}

export function paymentPaybotTerminated() {
  return {
    type: types.PAYBOT_TERMINATED,
  };
}

export function paymentPaybotNotTerminated() {
  return {
    type: types.PAYBOT_NOT_TERMINATED,
  };
}

export function changePaybotVisibility(payload) {
  return {
    type: types.PAYBOT_CHANGE_OVERLAY_VISIBILITY,
    payload,
  };
}

export function changeManualPaymentVisibility(payload) {
  return {
    type: types.MANUAL_PAYMENT_CHANGE_OVERLAY_VISIBILITY,
    payload,
  };
}

export function setStreamCreditCardInfo(payload) {
  return {
    type: types.SET_STREAM_CREDIT_CARD_FIELD,
    payload,
  };
}

export function streamCreditCardError() {
  return {
    type: types.STREAM_CREDIT_CARD_ERROR,
  };
}

export function reset() {
  return {
    type: types.RESET,
  };
}

export function creditCardReset() {
  return {
    type: types.CREDIT_CARD_RESET,
  };
}

export function setManualPaymentField(payload) {
  return {
    type: types.SET_MANUAL_PAYMENT_FIELD,
    payload,
  };
}

export function manualPaymentProcessing() {
  return {
    type: types.MANUAL_PAYMENT_PROCESSING,
  };
}

export function manualPaymentProcessed() {
  return {
    type: types.MANUAL_PAYMENT_PROCESSED,
  };
}

export function manualPaymentNotProcessed() {
  return {
    type: types.MANUAL_PAYMENT_NOT_PROCESSED,
  };
}
