import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import env from '@beam-australia/react-env';
import * as authSelectors from '@modules/auth/selectors';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import NodeWS from 'ws';
import logger from '../config/logger';

const menuServiceHost = env('MENU_SERVICE');

const httpLink = createHttpLink({
  uri: `${menuServiceHost}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = authSelectors.getToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${menuServiceHost.replace('https:', 'wss:')}/graphql`,
    connectionParams: () => ({
      authorization: authSelectors.getToken() || '',
    }),
    webSocketImpl: typeof WebSocket !== 'undefined' ? WebSocket : NodeWS,
  }),
);

const successMiddleware = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const attributes = {
      responseData: response.data,
      variables: operation.variables,
    };

    const message = `[GraphQL success]: ${operation.operationName}`;
    logger.info(message, attributes);

    return response;
  }),
);

const errorMiddleware = onError(({ graphQLErrors, operation, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const attributes = {
        responseData: response?.data,
        variables: operation.variables,
        locations,
        path,
      };
      logger.error(`[GraphQL error]: ${message}`, attributes);
    });
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  from([authLink, successMiddleware, errorMiddleware, httpLink]),
);

export const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Category: {
        keyFields: false,
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'no-cache',
      fetchPolicy: 'no-cache',
      initialFetchPolicy: 'no-cache',
    },
  },
});
