import camelcaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { deliveryService } from '@services';
import { RootState } from '@store';
import * as notifications from '@modules/notifications/api';

type SearchedAddress = {
  zip_code: string;
  street_name: string;
  street_number: string;
  city: string;
  state: string;
  country: string;
  coordinates?: {
    lat: string;
    lng: string;
  };
  formatted_address?: string;
};

export type ValidateAddressBody = {
  order_id: string;
  building?: string;
  full_street_address: string;
  city: string;
  state?: string;
  zip_code: string;
  country?: string;
  instructions?: string;
};

type Address = {
  building: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  instructions: string;
  id: string;
  created_at: string;
  updated_at: string;
};

export const searchAddress = createAsyncThunk<
  CamelCaseKeys<SearchedAddress>,
  string,
  {
    state: RootState;
  }
>('delivery/searchAddress', async (address, { getState, rejectWithValue }) => {
  try {
    const { store } = getState().taskrouter;
    const url = `/geocoding/address?address=${address}&store_id=${store.id}`;
    const response = await deliveryService.get<SearchedAddress>(url);

    return camelcaseKeys(response.data);
  } catch (error) {
    notifications.info({
      title: 'Unable to find address',
      message: 'Please fill each field manually and validate.',
    });
    return rejectWithValue(error);
  }
});

export const validateAddress = createAsyncThunk<
  {
    canDeliver: boolean;
    id?: string;
  },
  ValidateAddressBody,
  { state: RootState }
>('delivery/validateAddress', async (body, { rejectWithValue, getState }) => {
  try {
    const { useOrderService } = getState().featureFlags.store;

    const url = `/delivery-addresses`;

    const response = await deliveryService.post<{
      can_deliver: boolean;
      id?: string;
    }>(url, useOrderService ? camelcaseKeys(body) : body, {
      version: useOrderService ? 'v2' : 'v1',
    });

    return camelcaseKeys(response.data);
  } catch (err: any) {
    const message = getErrorMessage(err);

    notifications.error({
      title: 'Error validating address',
      message,
    });
    return rejectWithValue(err);
  }
});

export const fetchAddressById = createAsyncThunk<
  CamelCaseKeys<Address>,
  string,
  {
    state: RootState;
  }
>('delivery/fetchAddressById', async (id, { rejectWithValue }) => {
  try {
    const url = `/delivery-addresses/${id}`;
    const response = await deliveryService.get<Address>(url);

    return camelcaseKeys(response.data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

// UTILS
function getErrorMessage(error: any) {
  const responseString = error.response?.data?.error || '';

  if (responseString === 'ADDRESS_NOT_DELIVERABLE') {
    return 'Cannot deliver to specified location';
  }

  const regex = /message: '(.+?)'/;
  const match = responseString.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return 'Something went wrong while validating the address';
}
