import { useAppSelector } from '@store';
import { formatPrice } from '@utils';
import * as menuSelectors from '@modules/menu/selectors';
import * as cartSelectors from '@modules/cart/selectors';
import * as menuSlice from '@modules/menu/slice';
import * as cartActions from '@modules/cart/actions';

export function useConnector() {
  const selectors = {
    specialInstructions: useAppSelector(
      (state) => state.menu.specialInstructions,
    ),

    recipientName: useAppSelector((state) => state.menu.recipientName),

    addedOptions: useAppSelector((state) => state.menu.options.added),

    isEditing: useAppSelector((state) => state.menu.items.isEditing),

    currentItemId: useAppSelector((state) => state.menu.items.current),

    currentItem: useAppSelector(
      (state) => state.menu.items.entities[state.menu.items.current!],
    ),

    subtotal: useAppSelector((state) =>
      formatPrice(menuSelectors.selectSubtotal(state)()),
    ),

    modifiers: useAppSelector((state) =>
      menuSelectors.selectAllModifiers(state.menu.modifiers),
    ),

    allOptions: useAppSelector((state) =>
      menuSelectors.selectAllOptions(state.menu.options),
    ),

    promptedModifiers: useAppSelector((state) => state.menu.modifiers.prompted),

    noSelectionModifiers: useAppSelector(
      (state) => state.menu.modifiers.noSelection,
    ),

    upsold: useAppSelector((state) => state.menu.upsold),

    hasOngoingCartAction: useAppSelector(
      cartSelectors.hasOngoingCartActionSelector,
    ),

    currentCartItemId: useAppSelector(cartSelectors.selectCurrentCartItemId),

    currentCartItem: useAppSelector(cartSelectors.selectCurrentCartItem),

    removedDefaultOptions: useAppSelector(
      menuSelectors.selectRemovedDefaultOptions,
    ),
  };

  const actions = {
    highlightModifier: menuSlice.highlightModifier,
    addCartItem: cartActions.addCartItems,
    updateCartItem: cartActions.updateCartItem,
  };

  return {
    actions,
    selectors,
  };
}
