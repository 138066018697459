import * as authSelectors from '@modules/auth/selectors';
import { AxiosInstance } from 'axios';

const formatToken = () => {
  const token = authSelectors.getToken();
  return `Bearer ${token}`;
};

export function addTokenInterceptor(instance: AxiosInstance) {
  instance.interceptors.request.use(
    (config) => {
      if (!config.headers) {
        config.headers = {};
      }

      config.headers.Authorization = formatToken();
      return config;
    },
    (error) => Promise.reject(error),
    { synchronous: true },
  );
}
