import './datadog';

import { datadogLogs } from '@datadog/browser-logs';
import { faro, LogLevel } from '@grafana/faro-web-sdk';

import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as agentSelectors from '@modules/agent/selectors';

import { getCurrentVersion } from '@utils';
import { filterSensitiveData } from './filter-sensitive-data';
import { transformToFaroContext } from './transform-to-faro-context';

interface Logger {
  info: (message: string, attributes?: any) => void;
  debug: (message: string, attributes?: any) => void;
  error: (message: string, attributes?: any) => void;
  warn: (message: string, attributes?: any) => void;
  action: (message: string, attributes?: any) => void;
}

function createPayload(attributes = {}) {
  const store = taskrouterSelectors.getStore();
  const taskSid = taskrouterSelectors.getTaskSid();
  const agent = agentSelectors.currentAgent();

  const filteredAttributes = filterSensitiveData(attributes);

  return {
    ...filteredAttributes,
    order_id: taskrouterSelectors.deprecatedGetOrderId(),
    conference_sid: taskrouterSelectors.deprecatedGetConferenceSid(),
    store_id: store ? store.id : null,
    task_sid: taskSid,
    agent_username: agent ? agent.username : null,
    version: getCurrentVersion(),
  };
}

const logger = {} as Logger;

logger.info = (message: string, attributes: any) => {
  const payload = createPayload(attributes);
  datadogLogs.logger.info(message, payload);
  faro.api.pushLog([message], {
    context: transformToFaroContext(payload),
    level: LogLevel.INFO,
  });
};

logger.debug = (message: string, attributes: any) => {
  const payload = createPayload(attributes);
  datadogLogs.logger.debug(message, payload);
  faro.api.pushLog([message], {
    context: transformToFaroContext(payload),
    level: LogLevel.DEBUG,
  });
};

logger.error = (message: string, attributes: any) => {
  const payload = createPayload(attributes);
  datadogLogs.logger.error(message, payload);
  faro.api.pushLog([message], {
    context: transformToFaroContext(payload),
    level: LogLevel.ERROR,
  });
};

logger.warn = (message: string, attributes: any) => {
  const payload = createPayload(attributes);
  datadogLogs.logger.warn(message, payload);
  faro.api.pushLog([message], {
    context: transformToFaroContext(payload),
    level: LogLevel.WARN,
  });
};

logger.action = (message: string, attributes: any) => {
  logger.info(
    `[Action]: ${message}`,
    createPayload({
      log_type: 'action',
      ...attributes,
    }),
  );
};

export default logger;
