import { useSelector } from 'react-redux';

export const useAgents = () => {
  const errors = useSelector(({ agents }) => agents.errors);
  const pending = useSelector(({ agents }) => agents.pending);
  const current = useSelector(({ agents }) => agents.current);
  const byId = useSelector(({ agents }) => agents.byId);
  const currentAgent = byId[current];

  return { errors, pending, current, byId, currentAgent };
};
