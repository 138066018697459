import { useSelector } from 'react-redux';

import { useNotification } from './useNotification';

export const useSurvey = () => {
  const { current: hasNotification } = useNotification();

  const errors = useSelector(({ survey }) => survey.errors);
  const pending = useSelector(({ survey }) => survey.pending);
  const orderId = useSelector(({ survey }) => survey.orderId);
  const show = useSelector(({ survey }) => survey.show);
  const primaryDisposition = useSelector(
    ({ survey }) => survey.primaryDisposition,
  );
  const secondaryDisposition = useSelector(
    ({ survey }) => survey.secondaryDisposition,
  );
  const comments = useSelector(({ survey }) => survey.comments);
  const wasSubmitted = useSelector(({ survey }) => survey.wasSubmitted);
  const wasForwarded = useSelector(({ survey }) => survey.wasForwarded);

  const isPending = Object.keys(pending).some((key) => pending[key]);
  const hasError = Object.keys(errors).some((key) => errors[key]);

  return {
    errors,
    pending,
    isPending,
    hasError,
    orderId,
    primaryDisposition,
    secondaryDisposition,
    comments,
    wasSubmitted,
    wasForwarded,
    shouldShowSurvey: show && orderId && !hasNotification,
  };
};
