import { theme, Card } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Background = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;

  background: ${theme.colors.black};
  opacity: 0.5;
`;

export const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

export const Content = styled(Card).attrs({
  padded: true,
  vertical: true,
})`
  width: fit-content;
  height: fit-content;
  border-radius: 8px;

  &:hover {
    box-shadow: ${theme.elevations[1]};
  }
`;
