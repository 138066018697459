import * as orderAPI from '@modules/order/api';
import { resetTaskrouter } from '@modules/taskrouter/actions';
import { hangup } from '@modules/device/device-actions';
import * as accountSlice from '@modules/account/slice';
import * as voicePaneAPI from '@modules/voicePane/api';
import * as paymentAPI from '@modules/payment/api';
import * as transcriptsAPI from '@modules/transcripts/api';
import * as featureFlagsSlice from '@modules/featureFlags/slice';
import { couponActions } from '@modules/coupons/slice';
import * as clockAPI from '@modules/clock/api';
import { resetDelivery } from '@modules/delivery/slice';
import { resetMenu } from '@modules/menu/slice';
import store from '@store';
import { surveySubmitted } from '@modules/survey/actions';

export async function handleEndOfTask(conferenceSid = null) {
  await orderAPI.endOrder();
  callActions([], false, conferenceSid);
}

export async function handleEndOfTrainingTask(conferenceSid) {
  await orderAPI.dismissOrder();
  await clockAPI.clockOut();
  callActions([], false, conferenceSid);
  store.dispatch(surveySubmitted());
}

export async function handleHangupEndOfTask(conferenceSid) {
  await orderAPI.dismissOrder();
  callActions([], true, conferenceSid);
}

export async function handlePaybotEndOfTask(conferenceSid) {
  callActions([], false, conferenceSid);
}

export async function callActions(promises, endConference, conferenceSid) {
  promises.push(store.dispatch(hangup({ endConference, conferenceSid })));
  promises.push(store.dispatch(resetTaskrouter()));
  store.dispatch(accountSlice.reset());
  voicePaneAPI.reset();
  paymentAPI.closeStream();
  paymentAPI.reset();
  store.dispatch(couponActions.resetCoupons());
  store.dispatch(resetDelivery());
  store.dispatch(resetMenu());
  promises.push(transcriptsAPI.reset());
  promises.push(
    store.dispatch(featureFlagsSlice.actions.setDelayedBrandFeatureFlags()),
  );
  promises.push(
    store.dispatch(featureFlagsSlice.actions.setDelayedStoreFeatureFlags()),
  );
  await Promise.all(promises);
}
