import { Typography } from '@kea-inc/parrot-ui';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import { useMemo } from 'react';
import * as S from './styles';
import AnnounceIcon from '../AnnounceIcon';
import { makeSelectionRequirementsPhrase } from './make-selection-requirements-phrase';

type RequiredMarkProps = {
  maxOptions: number | null;
  minOptions: number | null;
  spokenName: string;
  required?: boolean | null;
  modifierId: string;
};

export function RequiredMark({
  maxOptions,
  minOptions,
  spokenName,
  required,
  modifierId,
}: RequiredMarkProps) {
  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const message = useMemo(
    () =>
      makeSelectionRequirementsPhrase(spokenName, {
        minOptions,
        maxOptions,
        required,
      }),
    [spokenName, minOptions, maxOptions, required],
  );

  const handleClick = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: { id: MENU_BUTTON_IDS.MODIFIER_REQUIRED, message, modifierId },
    });
  };

  return (
    <S.RequiredContainer data-testid="required-mark">
      <S.RequiredIcon />
      <Typography variant="caption" color="error">
        Required
      </Typography>

      <AnnounceIcon
        variant="primary"
        message={message}
        size={20}
        className="requiredMark__announce"
        onClick={handleClick}
      />
    </S.RequiredContainer>
  );
}
