import camelcaseKeys, { CamelCaseKeys } from 'camelcase-keys';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import * as orderAPI from '@modules/order/api';

import {
  getMenuItemsSelections,
  isCompressedMenuValid,
} from '@modules/order/utilsV2';
import { populateCompressedMenu } from '@modules/menu/slice';
import * as utils from './utils/account-utils';
import {
  fetchPreviousOrderDeprecated,
  getPreviousOrder,
} from './utils/previous-order-utils';

import { Account, OrderItem, OrderOption } from './types';

export const fetchAccountById = createAsyncThunk<
  CamelCaseKeys<Account>,
  string
>('account/fetchAccountById', async (id, { rejectWithValue }) => {
  try {
    const response = await utils.fetchAccountById(id);

    return camelcaseKeys(response);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const upsertAccount = createAsyncThunk<
  CamelCaseKeys<Account>,
  string,
  {
    state: RootState;
  }
>('account/upsertAccount', async (_, { rejectWithValue }) => {
  try {
    const response = await utils.upsertAccount();
    orderAPI.setChecklist(ORDER_CHECKLIST.ARE_FIELDS_FILLED, true);

    return camelcaseKeys(response.data);
  } catch (error) {
    orderAPI.setChecklist(ORDER_CHECKLIST.ARE_FIELDS_FILLED, false);
    return rejectWithValue(error);
  }
});

export const updateAccount = createAsyncThunk<
  CamelCaseKeys<Account>,
  string | undefined,
  {
    state: RootState;
  }
>('account/updateAccount', async (_, { rejectWithValue, getState }) => {
  try {
    const { useOrderService, useAccountService } = getStoreFeatureFlags(
      getState() as RootState,
    );
    const response = await utils.updateAccount({
      useOrderService,
      useAccountService,
    });

    return camelcaseKeys(response.data);
  } catch (error) {
    return rejectWithValue(error);
  }
});

// TODO: Change fetchPreviousOrder to Order module
export const fetchPreviousOrder = createAsyncThunk(
  'account/fetchPreviousOrder',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      const { useOrderService } = getStoreFeatureFlags(getState() as RootState);
      let previousOrder;

      if (useOrderService) {
        previousOrder = await getPreviousOrder();
        previousOrder = camelcaseKeys(previousOrder, { deep: true });
      } else {
        previousOrder = await fetchPreviousOrderDeprecated();
        previousOrder = camelcaseKeys(previousOrder, { deep: true });
        previousOrder.orderItems = previousOrder?.orderItems?.map(
          (item: OrderItem) => ({
            ...item,
            menuItemId: item.itemId,
            // We used to call options as modifiers in data-service
            options: item.modifiers?.map((option: OrderOption) => ({
              quantity: option.quantity,
              id: option.externalId,
            })),
          }),
        );
      }

      // TODO: call that from a better place
      if (!previousOrder?.orderItems) {
        return;
      }

      const compressedMenu = await getMenuItemsSelections(
        previousOrder.orderItems,
      );

      if (!isCompressedMenuValid(compressedMenu)) {
        return;
      }

      dispatch(
        populateCompressedMenu({
          compressedMenu,
        }),
      );

      return previousOrder;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
