import * as orderAPI from '@modules/order/api';

import * as agentSelectors from '@modules/agent/selectors';
import * as selectors from './selectors';

export async function submitSurvey() {
  const payload = selectors.getSurveyPayload();
  const orderId = selectors.getOrderId();
  const agentId = agentSelectors.currentAgentId();
  await orderAPI.submitSurvey(
    {
      ...payload.eventTags,
      agentId,
    },
    orderId,
  );
}
