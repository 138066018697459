import { store } from '@store';

function agents() {
  return store.getState().agents;
}

export function currentAgentId() {
  const { current } = agents();
  return current;
}

export function currentAgent() {
  const { current, byId } = agents();
  const agent = byId[current];

  if (!agent) return null;

  return agent;
}

export function getTwilioWorkerSid() {
  return currentAgent().twilio_worker_sid;
}
