import { formatPrice } from '@utils/formatPrice';
import * as orderSelectors from '@modules/order/selectors';
import * as cartSelectors from '@modules/cart/selectors';
import store from '@store';

export function makeTotalPhrase() {
  const total = cartSelectors.selectTotal(store.getState());
  const discount = cartSelectors.selectDiscount(store.getState());

  if (!total) {
    return `Unfortunately, I can't calculate your total yet.`;
  }

  const discountPhrase =
    discount && discount > 0 ? `and you've saved ${formatPrice(discount)}` : '';

  const handOffMode = orderSelectors.getOrderHandoffMode();

  const feesPhrase =
    handOffMode === 'delivery'
      ? 'with delivery and service fees included'
      : 'with taxes included';

  return `That will be ${formatPrice(
    total,
  )} total ${feesPhrase} ${discountPhrase}.`;
}
