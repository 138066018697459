import { pluralize } from '@utils/pluralize';
import KeaOrder from '@kea-inc/order';

export function makePreviousOrderSummaryPhrase(items) {
  if (!items || items.length === 0) return '';
  const phrase = [];
  const groupedItems = groupDuplicatedItems(items);

  phrase.push(getQuestion());
  phrase.push(getItems(groupedItems));
  return phrase.join(' ').trim();
}

function groupDuplicatedItems(items) {
  const groupedItems = [];

  items.forEach((item) => {
    const idx = groupedItems.findIndex(
      (groupedItem) => groupedItem.name === item.name,
    );

    if (idx === -1) {
      return groupedItems.push(item);
    }

    groupedItems[idx] = {
      ...groupedItems[idx],
      quantity: groupedItems[idx].quantity + item.quantity,
    };
  });

  return groupedItems;
}

function getQuestion() {
  return 'Should we start with your last order of ';
}

function getItems(items) {
  return items.reduce((phrase, item, idx) => {
    if (item.displayInBasket === 'false') {
      return phrase;
    }
    const isLastButNotFirst = idx === items.length - 1 && idx > 0;
    const isNotLast = idx < items.length - 1;
    const name = KeaOrder.getSpokenName(item);
    const quantityAndName = `${item.quantity} ${pluralize(
      name,
      item.quantity,
    )}`;
    return `${phrase} ${isLastButNotFirst ? 'and' : ''} ${quantityAndName}${
      isNotLast ? ', ' : '?'
    }`.trim();
  }, '');
}
