import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Loading, Header } from '@components';
import { AppProvider } from '@providers';
import { useRoles } from '@hooks';
import Auth0Redirect from '@auth/auth0-redirect';
import ProtectedRoute from '@auth/protected-route';

import Home from '@pages/Home';
import WaitingRoom from '@pages/WaitingRoom';
import Dashboard from '@pages/Dashboard';
import NotFound from '@pages/NotFound';
import Logout from '@pages/Logout';

import '@utils/devTask';
import '@kea-inc/parrot-ui/dist/style.css';
import {
  actions as featureFlagsActions,
  selectors as featureFlagsSelectors,
} from '@modules/featureFlags';
import { useAppDispatch, useAppSelector } from '@store';
import { withOneTabEnforcer } from './providers/tabEnforcerProvider';
import { StartReplayRecording } from './components/StartReplayRecording';

const App = () => {
  const { isLoading, user } = useAuth0();
  const { availableRoutes, roles } = useRoles();

  const dispatch = useAppDispatch();

  const isFetchingUserFF = useAppSelector(
    featureFlagsSelectors.isActionFetching('user'),
  );

  useEffect(() => {
    if (user) {
      dispatch(
        featureFlagsActions.getUserFeatureFlags({
          key: user.email,
          secondary: user.sub,
          name: user.email,
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
          avatar: user.picture,
          custom: {
            roles,
            source: 'Unified Ordering Application',
          },
        }),
      );
    }
  }, [user]);

  if (isLoading || !availableRoutes || isFetchingUserFF) {
    return <Loading />;
  }

  return (
    <>
      <AppProvider>
        <Header />
        <StartReplayRecording />
        <React.Suspense fallback="">
          <Switch>
            <Route exact path="/" component={Auth0Redirect} />
            <ProtectedRoute exact path="/home" component={Home} />
            <ProtectedRoute exact path="/waiting" component={WaitingRoom} />
            <ProtectedRoute exact path="/dashboard" component={Dashboard} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/logout" component={Logout} />
          </Switch>
        </React.Suspense>
      </AppProvider>
    </>
  );
};

export default withOneTabEnforcer({ appName: 'kea-inc/uoa' })(App);
