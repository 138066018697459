import { useSelector } from 'react-redux';

import { streamFields, fields } from '@modules/payment/state';

export const usePayment = () => {
  const creditCard = useSelector(({ payment }) => payment.creditCard);
  const manualCreditCard = useSelector(
    ({ payment }) => payment.manualCreditCard,
  );
  const isPaybotOverlayOpen = useSelector(
    ({ payment }) => payment.isPaybotOverlayOpen,
  );
  const isManualPaymentOverlayOpen = useSelector(
    ({ payment }) => payment.isManualPaymentOverlayOpen,
  );
  const paybotRanOnce = useSelector(({ payment }) => payment.paybotRanOnce);
  const pending = useSelector(({ payment }) => payment.pending);
  const errors = useSelector(({ payment }) => payment.errors);

  return {
    creditCard,
    errors,
    fields,
    isManualPaymentOverlayOpen,
    isPaybotOverlayOpen,
    manualCreditCard,
    pending,
    streamFields,
    paybotRanOnce,
  };
};
