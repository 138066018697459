export const types = {
  SURVEY_SET_PRIMARY_DISPOSITION: '@survey/SURVEY_SET_PRIMARY_DISPOSITION',
  SURVEY_SET_SECONDARY_DISPOSITION: '@survey/SURVEY_SET_SECONDARY_DISPOSITION',
  SURVEY_SET_COMMENTS: '@survey/SURVEY_SET_COMMENTS',

  SURVEY_SUBMITTING: '@survey/SUBMITTING',
  SURVEY_SUBMITTED: '@survey/SUBMITTED',
  SURVEY_NOT_SUBMITTED: '@survey/NOT_SUBMITTED',

  SURVEY_START_NEW_ORDER: '@survey/START_NEW_ORDER',

  SURVEY_ORDER_SUBMITTED: '@survey/ORDER_SUBMITTED',
  SURVEY_ORDER_FORWARDED: '@survey/ORDER_FORWARDED',

  SURVEY_TIMEOUT: '@survey/TIMEOUT',
};
