export const types = {
  CLOCK_FETCHING_CLOCK_STATUS: '@clock/FETCHING_CLOCK_STATUS',
  CLOCK_FETCHED_CLOCK_STATUS: '@clock/FETCHED_CLOCK_STATUS',
  CLOCK_NOT_FETCHED_CLOCK_STATUS: '@clock/NOT_FETCHED_CLOCK_STATUS',

  CLOCK_CLOCKING_IN: '@clock/CLOCKING_IN',
  CLOCK_CLOCKED_IN: '@clock/CLOCKED_IN',
  CLOCK_NOT_CLOCKED_IN: '@clock/NOT_CLOCKED_IN',

  CLOCK_CLOCKING_OUT: '@clock/CLOCKING_OUT',
  CLOCK_CLOCKED_OUT: '@clock/CLOCKED_OUT',
  CLOCK_NOT_CLOCKED_OUT: '@clock/NOT_CLOCKED_OUT',

  CLOCK_RESET: '@clock/RESET',
};
