import { useRef, useState, useEffect } from 'react';
import { BsArrowRepeat } from 'react-icons/bs';

import { useAppDispatch, useAppSelector } from '@store';
import { useDevice } from '@hooks';
import * as deviceActions from '@modules/device/device-actions';

import { createOrderEvent } from '@modules/order/utils';
import { formatPhoneNumber } from '@utils';
import { getTaskAttributes } from '@modules/taskrouter/selectors';
import Confirmation from './Confirmation';
import * as S from './styles';
import { useConnector } from '../useConnector';

export function CallBackBadge() {
  const dispatch = useAppDispatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [hasStartedCallingBack, setHasStartedCallingBack] = useState(false);
  const { isCallingBack } = useDevice().pending;
  const badgeRef = useRef<HTMLDivElement | null>(null);
  const taskAttributes = useAppSelector(getTaskAttributes);

  const formattedPhoneNumber = formatPhoneNumber(taskAttributes?.caller);
  const isAnonymousCall = formattedPhoneNumber
    .toLowerCase()
    .startsWith('anonymous');

  const { selectors } = useConnector();
  const { isTrainingTask } = selectors;

  const openConfirmation = () => setIsConfirmationOpen(true);
  const closeConfirmation = () => setIsConfirmationOpen(false);

  const handleCallBack = () => {
    setHasStartedCallingBack(true);
    // REMOVE CALL EVENT ENTIRELY when migrating to order-service, do not add to analytics service in future,  event covered by existing backend
    createOrderEvent('agent_called_customer_back');
    dispatch(deviceActions.callBack());
  };

  useEffect(() => {
    if (hasStartedCallingBack && !isCallingBack) {
      setHasStartedCallingBack(false);
      closeConfirmation();
    }
  }, [isCallingBack]);

  return (
    <>
      {isAnonymousCall || isTrainingTask ? (
        <S.DisabledBadge variant="gray" leftIcon={BsArrowRepeat}>
          Call Back
        </S.DisabledBadge>
      ) : (
        <S.Badge
          variant="error"
          leftIcon={BsArrowRepeat}
          onClick={openConfirmation}
          ref={badgeRef}
        >
          Call Back
        </S.Badge>
      )}

      <Confirmation
        action="call back"
        anchor={badgeRef}
        open={isConfirmationOpen}
        onCancel={closeConfirmation}
        onConfirm={handleCallBack}
        isLoading={isCallingBack}
        isDisabled={isAnonymousCall}
        position="bottomRight"
      />
    </>
  );
}
