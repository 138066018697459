export function makeUnavailableTimeMessage(params: {
  formattedTime: string;
  nearestAvailableTime?: string | null;
}) {
  const { formattedTime, nearestAvailableTime } = params;

  let message: string = `We're currently experiencing high order volume and can't accept your order at your requested time: ${formattedTime}.`;

  if (nearestAvailableTime) {
    message += ` The next available time is ${nearestAvailableTime}.`;
  }

  return `${message} Would you like to continue with the next available time or schedule for another time in the future?`;
}
