export interface Action<P = unknown> {
  payload?: P;
  type: Actions;
}

// eslint-disable-next-line no-shadow
export enum Actions {
  'VOICEPANE_FETCHING' = '@voicePane/FETCHING_PROMPTS',
  'VOICEPANE_FETCHED' = '@voicePane/FETCHED_PROMPTS',
  'VOICEPANE_PROMPTS_FETCHED' = '@voicePane/VOICEPANE_PROMPTS_FETCHED',
  'VOICEPANE_SOUNDBOARD_ENTRIES_FETCHED' = '@voicePane/VOICEPANE_SOUNDBOARD_ENTRIES_FETCHED',
  'VOICEPANE_NOT_FETCHED' = '@voicePane/PROMPTS_NOT_FETCHED',

  'VOICEPANE_SET_INITIAL' = '@voicePane/SET_INITIAL_VOICEPANE',
  'VOICEPANE_SET_CONTEXT_PROMPTS' = '@voicePane/SET_CONTEXT_PROMPTS',
  'VOICEPANE_SET_CATEGORY_PROMPTS' = '@voicePane/SET_CATEGORY_PROMPTS',
  'VOICEPANE_FILTER_PROMPTS' = '@voicePane/FILTER_PROMPTS',
  'VOICEPANE_SET_DELIVERY_TIME' = '@voicePane/SET_DELIVERY_TIME',

  'VOICEPANE_RESET' = '@voicePane/RESET',
}

export interface VoicePaneState {
  prompts: Prompts;
  currentContext: string[];
  filteredPrompts: Record<string, string[]> | null;
  categoryBased: string[] | null;
  pending: Record<string, boolean>;
  errors: Record<string, boolean>;
}

export type Prompts = {
  byContext: Record<string, string[]>;
  byCategory: Record<string, string[]>;
};

export type SoundboardPrompts = Record<string, string[]>;

export type DeliveryInfo = {
  deliveryTime: Date | number;
  isASAP: boolean;
};

export interface SoundboardEntry {
  group: string;
  text: string;
}
