import { Typography } from '@kea-inc/parrot-ui';
import KeaOrder from '@kea-inc/order';

import { AnnounceBadge } from '@components';

import { useAppDispatch, useAppSelector } from '@store';

import {
  getItemsFromCategory,
  navigateToCategory,
} from '@modules/menu/actions';

import { selectAllCategories } from '@modules/menu/selectors';
import { FetchStatus } from '@modules/menu/types';
import { changeItemsSearchTerm } from '@modules/menu/slice';

import {
  EVENT_NAME_BUTTONS,
  MENU_BUTTON_IDS,
} from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import * as S from './styles';

export const Categories = () => {
  const dispatch = useAppDispatch();

  const isFetchingCategories = useAppSelector(
    (state) => state.menu.categories.isFetching,
  );

  const categories = useAppSelector((state) =>
    selectAllCategories(state.menu.categories),
  );

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const listMessage = KeaOrder.createCategoriesListMessage(categories);

  const prefetchCategory = (categoryId: string, status: FetchStatus) => {
    if (status === 'fulfilled' || status === 'pending') {
      return;
    }

    dispatch(getItemsFromCategory({ categoryId }));
  };

  const handleSelectCategory = (id: string) => {
    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(navigateToCategory({ categoryId: id }));
  };

  const handleClickList = () => {
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.SPECIFIC,
      metadata: { id: MENU_BUTTON_IDS.LIST_CATEGORIES, message: listMessage },
    });
  };

  return (
    <S.Container>
      <div className="category__title">
        <Typography variant="subheader" color="primary_darker" weight="heavy">
          Home
        </Typography>
        {isFetchingCategories && <S.Loading />}
        <AnnounceBadge
          className="badge__List"
          variant="accent_2"
          message={listMessage}
          onClick={handleClickList}
          disabled={false}
        >
          List
        </AnnounceBadge>
      </div>

      <S.Categories>
        {categories.map((category) => (
          <S.Category
            key={category.id}
            onClick={() => handleSelectCategory(category.id)}
            // @ts-expect-error: missing on parrot definition, but it works
            onMouseEnter={() => prefetchCategory(category.id, category.status)}
          >
            <S.CategoryName hasItemsAvailable={category.hasItemsAvailable}>
              {category.name}
            </S.CategoryName>
          </S.Category>
        ))}
      </S.Categories>
    </S.Container>
  );
};
