import { ItemStatus } from '@kea-inc/types';

export const types = {
  ADD_TIPS: '@order/ADD_TIPS',

  VALIDATING: '@order/VALIDATING',
  VALIDATED: '@order/VALIDATED',
  NOT_VALIDATED: '@order/NOT_VALIDATED',
  VALIDATION_DISMISSED: '@order/VALIDATION_DISMISSED',

  SUBMITTING: '@order/SUBMITTING',
  SUBMITTED: '@order/SUBMITTED',
  NOT_SUBMITTED: '@order/NOT_SUBMITTED',

  UPDATING: '@order/UPDATING',
  UPDATED: '@order/UPDATED',
  NOT_UPDATED: '@order/NOT_UPDATED',

  SET_DETAILS: '@order/SET_DETAILS',
  REMOVE_CHECKLIST_ITEM: '@order/REMOVE_CHECKLIST_ITEM',
  SET_CHECKLIST: '@order/SET_CHECKLIST',
  SET_ID: '@order/SET_ID',

  CHANGE_TIPS_OVERLAY_VISIBILITY: '@order/CHANGE_TIPS_OVERLAY_VISIBILITY',
  CHANGE_SUBMIT_OVERLAY_VISIBILITY: '@order/CHANGE_SUBMIT_OVERLAY_VISIBILITY',
  CHANGE_CHECKLIST_VISIBILITY: '@order/CHANGE_CHECKLIST_VISIBILITY',
  CHANGE_TAX_AND_FEES_OVERLAY_VISIBILITY:
    '@order/CHANGE_TAX_AND_FEES_OVERLAY_VISIBILITY',

  ORDER_SYNCING: '@order/ORDER_SYNCING',
  ORDER_SYNCED: '@order/ORDER_SYNCED',
  ORDER_NOT_SYNCED: '@order/ORDER_NOT_SYNCED',

  RESET: '@order/RESET',

  END_ORDER: '@order/END_ORDER',

  BUILDING_CHECKLIST: '@order/BUILDING_CHECKLIST',
  BUILD_CHECKLIST: '@order/BUILD_CHECKLIST',

  EVENT_CREATING: '@order/EVENT_CREATING',
  EVENT_CREATED: '@order/EVENT_CREATED',
  EVENT_NOT_CREATED: '@order/EVENT_NOT_CREATED',

  FETCHING_AVAILABLE_TIMES: '@order/FETCHING_AVAILABLE_TIMES',
  FETCHED_AVAILABLE_TIMES: '@order/FETCHED_AVAILABLE_TIMES',
  NOT_FETCHED_AVAILABLE_TIMES: '@order/NOT_FETCHED_AVAILABLE_TIMES',
  AUTO_SCHEDULE: '@order/AUTO_SCHEDULE',

  SET_ORDER_THROTTLING: '@order/SET_ORDER_THROTTLING',

  SET_ITEMS_VALIDATION_STATUS: '@order/SET_ITEMS_VALIDATION_STATUS',

  SET_VISIBLE_TAB: '@order/SET_VISIBLE_TAB',
};

export const status = {
  ADDED: 'added',
  REMOVING: 'removing',
  UPDATING: 'updating',
};

// eslint-disable-next-line no-shadow
enum OrderErrors {
  STORE_CLOSED = 'STORE_CLOSED',
  INVALID_COUPONS = 'INVALID_COUPONS',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  CAPACITY_THROTTLED = 'CAPACITY_THROTTLED',
}

export type ValidationErrorCode =
  | OrderErrors
  | ItemStatus
  | 'INVALID_ITEM'
  | 'INVALID_OPTIONS';

export type ValidationError = {
  code: ValidationErrorCode;
  cartItemId?: string;
  menuItemId?: string;
};

export interface Validation {
  isValid: boolean;
  preparationTimeRangeStart?: string;
  errors: ValidationError[];
}

export interface PreparationTimeRange {
  preparationTimeRangeStart?: string;
}

export interface OrderFee {
  type: string;
  amount?: string;
  id?: string;
  description?: string | null;
}

interface OrderAgent {
  orderId: string;
  agentId: string;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
}

interface OrderCoupon {
  id: string;
  code: string;
}

export interface OrderPayload {
  id: string;
  accountId: string;
  storeId: string;
  total: string;
  subtotal: string;
  tax: string;
  tip: string;
  discount: string;
  handoffMode: 'delivery' | 'pickup' | 'dinein' | 'curbside';
  status: 'pending' | 'placed' | 'cancelled';
  paymentType?:
    | null
    | 'cash'
    | 'credit_card'
    | 'debit_card'
    | 'gift_card'
    | 'loyalty';
  scheduledTime?: string | null;
  cancellationReason?: string | null;
  tableNumber?: string | null;
  isCollectingLoyaltyPoints: false;
  source: 'uoa' | 'reorder' | 'orderbot';
  createdAt: string;
  updatedAt?: string | null;
  account: {
    id: string;
  };
  orderAgent: OrderAgent[];
  orderFee: OrderFee[];
  orderCoupon: OrderCoupon[];
}

export interface UpdateOrderPayload
  extends Pick<
    Partial<OrderPayload>,
    | 'handoffMode'
    | 'status'
    | 'paymentType'
    | 'scheduledTime'
    | 'cancellationReason'
    | 'tableNumber'
    | 'isCollectingLoyaltyPoints'
    | 'source'
  > {}

export interface AddAgentToOrderPayload {
  agentId: string;
  createdAt: string;
  bpoId: string;
}

export interface SubmitOrderSurveyPayload {
  primaryDisposition: string;
  secondaryDisposition?: string;
  comments?: string;
  placed: boolean;
  agentId: string;
}
