import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useToken = () => {
  const [token, setToken] = useState();

  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!user) return;
      const response = await getAccessTokenSilently();
      setToken(response);
    })();
  }, []);

  return token;
};
