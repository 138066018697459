import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  & .soundboard__Container {
    flex: 1;
  }

  & .checklist__Container {
    height: auto;
  }
`;
