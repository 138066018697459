import axios from 'axios';
import env from '@beam-australia/react-env';

import { StreamController } from '@utils/streamController';

import {
  addLoggerInterceptor,
  addTokenInterceptor,
  addURLEncoderInterceptor,
} from './interceptors';

export const baseURL = env('VGS_URL');

export const api = axios.create({
  baseURL: `${baseURL}/v0`,
});

addLoggerInterceptor(api);
addTokenInterceptor(api);
addURLEncoderInterceptor(api);

export default api;

export const vgsStream = new StreamController();
