import pluralizeWord from 'pluralize';

// TODO: this should come as a brand customization
const ignoreWords = ['Fries', 'Wings', 'fries', 'wings', 'wings', 'veggies'];

export function pluralize(word: string, quantity = 2, prefix = false) {
  const shouldIgnore = ignoreWords.some((ignoreWord) =>
    word.trim().toLowerCase().includes(ignoreWord),
  );

  if (shouldIgnore) {
    pluralizeWord.addUncountableRule(word);
    return pluralizeWord(word, quantity);
  }

  const pluralizedWord = pluralizeWord(word, quantity);

  if (quantity === 1 && prefix) {
    return `a ${pluralizedWord}`;
  }

  return pluralizedWord;
}
