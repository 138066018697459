import { useMemo } from 'react';
import AnnounceBadge, { AnnounceBadgeProps } from '@components/AnnounceBadge';
import { useOrder } from '@hooks';
import { createEstimatedTimeMessage } from '@utils';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { selectPreparationTimeRange } from '@modules/cart/selectors';
import { useAppSelector } from '@store';

type EstimatedTimeBadgeProps = AnnounceBadgeProps;

export const EstimatedTimeBadge = (props: EstimatedTimeBadgeProps) => {
  const { orderDetails } = useOrder();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const preparationTimeRange = useAppSelector(selectPreparationTimeRange);

  const readyTime = preparationTimeRange.preparationTimeRangeStart;

  const message = useMemo(
    () => createEstimatedTimeMessage(readyTime),
    [readyTime],
  );

  const handleClick = () => {
    props.onClick?.();
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  return (
    <AnnounceBadge
      {...props}
      disabled={!readyTime || !orderDetails.isASAP}
      message={message}
      onClick={handleClick}
    >
      Estimate
    </AnnounceBadge>
  );
};
