import { Badge } from '@kea-inc/parrot-ui';
import { Interval } from './types';

type UnavailableTimeProps = {
  interval: Interval;
  onClick: (iso: string) => void;
};

export function UnavailableTime({ interval, onClick }: UnavailableTimeProps) {
  return (
    <Badge variant="error" onClick={() => onClick(interval.iso)}>
      {interval.formattedTime} ({interval.diffToNow})
    </Badge>
  );
}
