import styled, { css } from 'styled-components';
import { theme } from '@kea-inc/parrot-ui';

export const NestedModifiersContainer = styled.div`
  padding: 0 16px;
  padding-top: 2px;
`;

interface NestedModifierProps {
  isHighlighted: boolean;
}

export const NestedModifier = styled.div<NestedModifierProps>`
  border: 1px solid ${theme.colors.primary_darker};
  padding: 16px 16px;
  margin: 16px 0 0 0;

  .nestedOptions {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
  }

  ${(props) =>
    props.isHighlighted &&
    css`
      transition: outline 0.3s ease;
      outline: 3px solid #b91a2e;
      outline-offset: -4px;
      animation-delay: 4.6s;
      animation-duration: 0.4s;
      animation-fill-mode: both;
      animation-name: _border-fade;
      @keyframes _border-fade {
        from {
          outline-color: #b91a2e;
        }
        to {
          outline-color: rgba(0, 0, 0, 0);
        }
      }
    `}
`;

export const Header = styled.header`
  .requiredMarkDiv {
    margin-left: -7px;
  }

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    margin-top: 4px;

    > div:first-child {
      margin-right: 8px;
    }
  }
`;

type NoSelectionContainerProps = {
  hidden: boolean;
};

export const NoSelectionContainer = styled.section<NoSelectionContainerProps>`
  margin-top: 1rem;
  display: flex;
  width: 100%;

  button {
    width: 100%;
  }

  opacity: ${(props) => (props.hidden ? 0 : 1)};
  pointer-events: ${(props) => (props.hidden ? 'none' : 'all')};
`;
