import { AnnounceBadge } from '@components';

import { ORDER_CHECKLIST } from '@modules/order/state';
import * as orderAPI from '@modules/order/api';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { ChecklistItem } from './ChecklistItem';

export const HasConfirmedLoyaltyPoints = () => {
  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const message = 'Are you collecting loyalty points today?';

  const handleClick = () => {
    orderAPI.setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_LOYALTY_POINTS, true);
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  return (
    <ChecklistItem field={ORDER_CHECKLIST.HAS_CONFIRMED_LOYALTY_POINTS}>
      <AnnounceBadge message={message} onClick={handleClick} disabled={false}>
        Loyalty Points
      </AnnounceBadge>
    </ChecklistItem>
  );
};
