import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 15px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.gray_lighter};

  > svg {
    font-size: 25px;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    align-items: center;
    margin: 0 auto;

    .wrapper__tabs {
      width: 200px;
      overflow-x: scroll;
      scroll-behavior: smooth;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    svg {
      font-size: 18px;
      cursor: pointer;
    }
  }
`;
