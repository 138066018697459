import { TaskrouterState } from './types';

export const initialTaskrouterState: TaskrouterState = {
  workerToken: null,
  workerSid: null,
  workerStatus: null,
  reservationSid: null,
  taskSid: null,
  taskStatus: null,
  taskAttributes: null,
  conferenceSid: null,
  brand: null,
  store: null,
  brands: [],
  storesByBrand: [],
  agentTookAction: false,
  timeout: false,
  storeHours: [],
  handoffMethods: [],
  reassignedByTtfa: false,
  isStatusOverlayOpen: false,
  isTrainingTask: false,
  pending: {
    isFetchingToken: false,
    isCreatingWorker: false,
    isAcceptingReservation: false,
    isReassigningTask: false,
    isConnectingToConference: false,
    isCompletingTask: false,
    isFetchingBrand: false,
    isFetchingStore: false,
    isFetchingBrands: false,
    isFetchingStoresByBrand: false,
    isCreatingTrainingTask: false,
  },
  errors: {
    hasFetchTokenError: false,
    hasCreateWorkerError: false,
    hasUnhandledError: false,
    hasAcceptReservationError: false,
    hasConferenceConnectionError: false,
    hasCompleteTaskError: false,
    hasReassigningTaskError: false,
    hasFetchBrandError: false,
    hasFetchStoreError: false,
    hasFetchBrandsError: false,
    hasFetchStoresByBrandError: false,
    hasCreateTrainingTaskError: false,
  },
};
