import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as agentSelectors from '@modules/agent/selectors';

function getCustomAttributes(attributes = {}) {
  const store = taskrouterSelectors.getStore();
  const taskSid = taskrouterSelectors.getTaskSid();
  const agent = agentSelectors.currentAgent();

  return {
    ...attributes,
    'kea.order.id': taskrouterSelectors.deprecatedGetOrderId(),
    'kea.conference.sid': taskrouterSelectors.deprecatedGetConferenceSid(),
    'kea.store.id': store ? store.id : null,
    'kea.task.sid': taskSid,
    'kea.agent.username': agent ? agent.username : null,
  };
}

function createTracingInstrumentation(environment: string) {
  const ignoreUrls = [
    /faro-collector/,
    /twilio/,
    /localhost/,
    /uoa/,
    /launchdarkly/,
    /auth0/,
    /record_event/,
    /record_autocart_event/,
    /transcripts/,
  ];
  return new TracingInstrumentation({
    instrumentations: [
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: [/localhost/, /kea\.ai/],
        ignoreUrls,
        applyCustomAttributesOnSpan: (span) => {
          span.setAttributes(
            getCustomAttributes({
              env: environment,
            }),
          );
        },
      }),
      new XMLHttpRequestInstrumentation({
        propagateTraceHeaderCorsUrls: [/localhost/, /kea\.ai/],
        ignoreUrls,
        applyCustomAttributesOnSpan: (span) => {
          span.setAttributes(
            getCustomAttributes({
              env: environment,
            }),
          );
        },
      }),
    ],
  });
}

export default function initFaro(
  url: string,
  tracing: boolean,
  version: string,
  environment: string,
): void {
  const instrumentations = [
    ...getWebInstrumentations({
      captureConsole: false,
    }),
  ];

  if (tracing) {
    const tracingInstrumentation = createTracingInstrumentation(environment);
    instrumentations.push(tracingInstrumentation);
  }

  initializeFaro({
    url,
    app: {
      name: 'UOA',
      version,
      environment,
    },
    instrumentations,
  });
}
