import React, { useState, useEffect, useMemo } from 'react';
import { Card, Typography, Button } from '@kea-inc/parrot-ui';
import { MdError } from 'react-icons/md';

import Counter from '@components/Counter';
import AnnounceBadge from '@components/AnnounceBadge';
import { useDebounce } from '@hooks';
import {
  formatPrice,
  makeItemDescription,
  makeCartItemPricePhrase,
} from '@utils';

import { useAppSelector } from '@store';
import { selectItemById } from '@modules/menu/selectors';
import { CartItem } from '@modules/cart/types';
import { hasOngoingCartActionSelector } from '@modules/cart/selectors';

import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import * as S from './styles';
import {
  getHumanReadableError,
  getHumanReadableErrorName,
} from './utils/get-human-readable-error';
import { transformToCartOption } from './utils/transform-to-cart-option';

interface CartItemCardProps {
  cartItem: CartItem;
  onRemove: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cartItemId: string,
  ) => void;
  onUpdate: (cartItemId: string, quantity: number) => void;
  onSelect: (cartItemId: string) => void;
}

function CartItemCard({
  cartItem,
  onRemove,
  onUpdate,
  onSelect,
}: CartItemCardProps) {
  const [quantity, setQuantity] = useState(cartItem.quantity);

  const menuItem = useAppSelector((state) =>
    selectItemById(state.menu.items, cartItem.menuItemId),
  );
  const hasOngoingCartAction = useAppSelector(hasOngoingCartActionSelector);
  const isRemovingCartItem = useAppSelector(
    (state) => state.cart.cartItems.isRemovingCartItem,
  );
  const isUpdatingCartItem = useAppSelector(
    (state) => state.cart.cartItems.isUpdatingCartItem,
  );
  const optionsById = useAppSelector((state) => state.menu.options.entities);

  const errorsByCartItemId = useAppSelector(
    (state) => state.cart.validation.errorsByCartItemId,
  );

  const cartItemOptions = useMemo(
    () => transformToCartOption(optionsById, cartItem.options),
    [cartItem.options],
  );

  const cartItemDescription = useMemo(
    () => makeItemDescription(cartItem),
    [cartItem, cartItemOptions],
  );
  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const cartItemSubtotal = useMemo(() => {
    let cost = menuItem?.cost ?? 0;

    cartItem.options?.forEach((cartOption) => {
      const option = optionsById[cartOption.id];

      if (option && option.cost) {
        cost += option.cost * cartOption.quantity;
      }
    });

    return cost * quantity;
  }, [cartItem.quantity]);

  const debouncedUpdateOnCart = useDebounce(onUpdate, 750);
  useEffect(() => {
    if (quantity !== cartItem.quantity) {
      debouncedUpdateOnCart(cartItem.id, quantity);
    }
  }, [quantity]);

  useEffect(() => {
    if (!cartItem.hasOngoingAction) {
      setQuantity(cartItem.quantity);
    }
  }, [cartItem.quantity, cartItem.hasOngoingAction]);

  const cartItemPricePhrase = useMemo(
    () =>
      makeCartItemPricePhrase({
        subtotal: cartItemSubtotal,
        quantity: cartItem.quantity,
      }),
    [cartItemSubtotal, cartItem.quantity],
  );

  const handleMenuItemNameClick = () => {
    if (menuItem) {
      handleAnalyticsTrackButton({
        eventName: EVENT_NAME_BUTTONS.GENERAL,
        metadata: { message: cartItemDescription },
      });
    }
  };

  const handlePriceClick = () => {
    if (menuItem) {
      handleAnalyticsTrackButton({
        eventName: EVENT_NAME_BUTTONS.GENERAL,
        metadata: { message: cartItemPricePhrase },
      });
    }
  };

  return (
    <S.Container
      hasError={!!errorsByCartItemId[cartItem.id]}
      onClick={() => onSelect(cartItem.id)}
    >
      <Card padded>
        <S.Header>
          <section>
            <AnnounceBadge
              message={cartItemDescription}
              onClick={handleMenuItemNameClick}
            >
              {menuItem?.name}
            </AnnounceBadge>

            <AnnounceBadge
              className="price__Badge"
              message={cartItemPricePhrase}
              onClick={handlePriceClick}
            >
              {formatPrice(cartItemSubtotal)}
            </AnnounceBadge>
          </section>
          <section className="actions">
            <Counter
              disabled={hasOngoingCartAction}
              min={1}
              max={menuItem?.maxQuantity ?? null}
              onIncrease={() => setQuantity((prev) => prev + 1)}
              onDecrease={() => setQuantity((prev) => prev - 1)}
              setQuantity={setQuantity}
              value={quantity}
            />

            <Button
              tertiary
              size="small"
              className="product__removeAction"
              onClick={(event) => onRemove(event, cartItem.id)}
              loading={cartItem.hasOngoingAction && isRemovingCartItem}
              disabled={hasOngoingCartAction}
            >
              Remove
            </Button>

            {cartItem.hasOngoingAction && isUpdatingCartItem && <S.Loading />}
          </section>
        </S.Header>

        <S.OptionsList data-test-id="selected-options-list">
          {cartItemOptions
            ?.filter((option) => !option.isHiddenInCart)
            .map((option) => (
              <S.OptionsListItem
                key={`${option.id}${option.ident}${option.quantity}`}
                ident={option.ident ?? 0}
                hidden={option.isHiddenInCart}
              >
                <Typography
                  variant="caption"
                  weight="light"
                  color="gray_darker"
                >
                  {option.showQuantity && `${option.quantity} - `}
                  {option.name}
                </Typography>
              </S.OptionsListItem>
            ))}
        </S.OptionsList>

        {cartItem.specialInstructions && (
          <S.SpecialInstructionsWrapper>
            <Typography variant="caption" weight="heavy" color="gray_darker">
              Special Instructions
            </Typography>
            <Typography variant="caption" weight="light" color="gray_darker">
              {cartItem.specialInstructions}
            </Typography>
          </S.SpecialInstructionsWrapper>
        )}

        {cartItem.recipient && (
          <S.SpecialInstructionsWrapper>
            <Typography variant="caption" weight="heavy" color="gray_darker">
              Made for
            </Typography>
            <Typography variant="caption" weight="light" color="gray_darker">
              {cartItem.recipient}
            </Typography>
          </S.SpecialInstructionsWrapper>
        )}

        {!!errorsByCartItemId[cartItem.id] && (
          <S.ErrorWrapper>
            <MdError />
            <Typography variant="caption" color="error">
              {getHumanReadableErrorName(
                errorsByCartItemId[cartItem.id][0].code,
              )}
            </Typography>

            <S.ErrorTooltip>
              <Typography color="white" variant="caption">
                {getHumanReadableError(errorsByCartItemId[cartItem.id][0].code)}
              </Typography>
              <br />
              <Typography color="white" variant="small">
                Code: {errorsByCartItemId[cartItem.id][0].code}
              </Typography>
            </S.ErrorTooltip>
          </S.ErrorWrapper>
        )}
      </Card>
    </S.Container>
  );
}

export default CartItemCard;
