import KeaOrder from '@kea-inc/order';

import { dataService, transcriptsStream, nluService } from '@services';

import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import { getServiceInstance } from '@utils/getServiceForOrdersIntegration';

export async function retrieveAllTranscripts() {
  const orderId = taskrouterSelectors.deprecatedGetOrderId();

  // TODO - Transcript via OrderId - Should we need to replace to orderService?
  const response = await dataService.get(`/transcripts/${orderId}/findall`);

  return response.data;
}

// STREAM
export async function connectTokenStream() {
  // TODO - OrderID HERE - Should we need to replace to orderService?
  const id = taskrouterSelectors.deprecatedGetOrderId();
  const source = await dataService.getEvent(`/transcripts/${id}/stream`);
  transcriptsStream.setSource(source);
}

export function addErrorListener(source, cb) {
  source.addEventListener('error', cb, false);
}

export function addTranscriptsListener(source, cb) {
  source.addEventListener('transcripts', cb, false);
}

export function removeErrorListener(source, cb) {
  if (source) {
    source.removeEventListener('error', cb);
  }
}

export function removeTranscriptsListener(source, cb) {
  if (source) {
    source.removeEventListener('transcripts', cb);
  }
}

export const intents = [
  'INTENT_ORDER_FOOD_NAME_ONLY',
  'INTENT_ORDER_FOOD',
  'INTENT_ORDER_BEV_NAME_ONLY',
  'INTENT_ORDER_BEV',
];

const normalized = ['ENT_FOOD_NAME', 'ENT_BEV_NAME'];

export async function createAutocartEvent(
  transcriptionId,
  eventName,
  eventTags,
  createdAt,
) {
  // TODO - ORDERID Here -Should we need to replace to orderService?
  const orderId = taskrouterSelectors.deprecatedGetOrderId();
  const { data } = await getServiceInstance().post(
    `/orders/record_autocart_event`,
    {
      order_id: orderId,
      transcription_id: transcriptionId,
      event_name: eventName,
      event_tags: eventTags,
      created_at: createdAt,
    },
  );
  return data;
}

async function validateMenuItems(items, intent) {
  const validIntent = intents.includes(intent);
  const itemsToFetch = [];
  if (validIntent && items.length) {
    items.forEach((item) => {
      if (normalized.includes(item.normalized)) {
        item.resolved.forEach((resolvedItem) =>
          itemsToFetch.push(resolvedItem),
        );
      }
    });
  }

  // TODO: fully remove autocart (not used anymore)
  // if (itemsToFetch.length) {
  //   await menuAPI.fetchItemsUnderCategoryFromAutocart(itemsToFetch);
  // }

  return validIntent;
}

export async function classifyIntent(dialog = '') {
  const store = taskrouterSelectors.getStore();
  const modelName = taskrouterSelectors.deprecatedGetBrand()?.nlu_model;

  const response = await nluService.post(
    '/classify',
    {
      utterance: dialog.toLowerCase(), // TODO: remove this workaround - check PD-2005 for more info
      store_id: store?.id,
      brand_id: store?.brandId,
      model_name: modelName,
      resolve_entities: true,
    },
    {
      version: 'v3',
    },
  );

  const { items, intent } = response.data;
  const validIntent = await validateMenuItems(items, intent);
  return { items, intent, validIntent };
}

export function getChosenOptions(item = {}) {
  if (!item.modifiers?.length) {
    return [];
  }

  return item.modifiers.reduce((acc, modifier) => {
    if (modifier.best_options) {
      modifier.best_options.forEach((option) => {
        if (option) {
          acc.push({
            ...option,
            modifierId: modifier.id,
          });
        }
      });
    }

    acc.push(...getDefaultOptionsRecursively(modifier));

    return acc;
  }, []);
}

function getDefaultOptionsRecursively(modifier) {
  const defaultOptions = KeaOrder.getDefaultOptions(modifier);

  if (defaultOptions.length) {
    defaultOptions.forEach((option) => {
      Object.assign(option, { modifierId: modifier.id });

      if (option.modifiers?.length) {
        option.modifiers.forEach((nestedModifier) => {
          defaultOptions.push(...getDefaultOptionsRecursively(nestedModifier));
        });
      }
    });
  }

  return defaultOptions;
}
