import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import * as agentsAPI from '@modules/agent/api';
import * as authAPI from '@modules/auth/api';
import * as clockAPI from '@modules/clock/api';
import { Loading } from '@components';

const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      await agentsAPI.reset();
      await authAPI.reset();
      await clockAPI.reset();
      logout();
    })();
  }, []);

  return <Loading />;
};

export default Logout;
