import React from 'react';

import { LeftPanelHeader } from './Header';
import { Menu } from './Menu';

import * as S from './styles';
import { LeftPanelFooter } from './Footer';

export const LeftPanel = React.memo(() => (
  <S.Container>
    <LeftPanelHeader />
    <Menu />
    <LeftPanelFooter />
  </S.Container>
));
