interface Edge<T> {
  node: T;
}

export interface RelayResponse<T> {
  edges: Edge<T>[];
}

export function relayToArray<T>(relayResponse: RelayResponse<T>): T[] {
  if (!relayResponse.edges) {
    return [];
  }

  return relayResponse.edges.map((edge) => edge.node);
}
