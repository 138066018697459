import { Typography } from '@kea-inc/parrot-ui';

import { FetchStatus } from '@modules/menu/types';
import * as S from './styles';

type ModifierNameProps = {
  name: string;
  status: FetchStatus;
};

export function ModifierName({ name, status }: ModifierNameProps) {
  return (
    <S.NameWrapper>
      <Typography variant="subheader" weight="heavy" color="primary_darker">
        {name}
      </Typography>

      {status === 'pending' && <S.Loading />}
    </S.NameWrapper>
  );
}
