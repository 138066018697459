import { Item } from '@modules/menu/types';
import { isEntityAvailable } from '@utils';

export function deliberateScreen(
  currentItem: string | null,
  currentProduct: string | null,
  currentCategory: string | null,
  searchTerm: string,
) {
  const showSearching = searchTerm.length >= 3;

  if (showSearching) {
    return 'searching';
  }

  const showItemModifiers = !!currentItem || !!currentProduct;
  const showCurrentCategory =
    !currentItem && !!currentCategory && !currentProduct && !showSearching;
  const showCategories =
    !currentItem && !currentCategory && !currentProduct && !showSearching;

  if (showItemModifiers) {
    return 'modifiers';
  }

  if (showCurrentCategory) {
    return 'category';
  }

  if (showCategories) {
    return 'categories';
  }

  return 'categories';
}

export const isItemAvailable = (item: Item, handOffMode: string) => {
  if (!isEntityAvailable(item)) {
    return false;
  }

  if (item.unavailableHandoffModes?.includes(handOffMode)) {
    return false;
  }

  return true;
};
