import { Input } from '@kea-inc/parrot-ui';

import { useAppDispatch, useAppSelector } from '@store';
import {
  changeRecipientName,
  changeSpecialInstructions,
} from '@modules/menu/slice';
import { ItemInformation } from './ItemInformation';
import { ItemRootModifiersList } from './ItemRootModifiersList';

import * as S from './styles';

type ItemModifiersProps = {
  itemId: string;
};

export const ItemModifiers = ({ itemId }: ItemModifiersProps) => {
  const dispatch = useAppDispatch();

  const recipientName = useAppSelector((state) => state.menu.recipientName);
  const specialInstructions = useAppSelector(
    (state) => state.menu.specialInstructions,
  );

  function onRecipientNameChanged(newName: string) {
    dispatch(changeRecipientName({ recipientName: newName }));
  }

  function onSpecialInstructionsChanged(newInstructions: string) {
    dispatch(
      changeSpecialInstructions({ specialInstructions: newInstructions }),
    );
  }

  return (
    <S.Container>
      <ItemInformation itemId={itemId} />

      <ItemRootModifiersList itemId={itemId} />

      <S.SpecialInstructionsWrapper>
        <Input
          label="Special Instructions"
          onChange={(e) => onSpecialInstructionsChanged(e.target.value)}
          value={specialInstructions}
          required
        />
      </S.SpecialInstructionsWrapper>

      <S.RecipientWrapper>
        <Input
          maxLength={32}
          label="Made for"
          onChange={(e) => onRecipientNameChanged(e.target.value)}
          value={recipientName}
          required
        />
      </S.RecipientWrapper>
    </S.Container>
  );
};
