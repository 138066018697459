import { GetCategoriesV2Query } from 'src/graphql/generated/schema';
import { Category } from '../types';

type APICategory = GetCategoriesV2Query['categoriesV2']['edges'][0]['node'];

export function transformAPICategory(apiCategory: APICategory): Category {
  return {
    id: apiCategory.id,
    chainId: apiCategory.chainId,
    customWhich: apiCategory.customWhich ?? null,
    description: apiCategory.description ?? null,
    hasItemsAvailable: apiCategory.hasItemsAvailable ?? true,
    isUpsellTarget: apiCategory.isUpsellTarget ?? false,
    name: apiCategory.name,
    sortOrder: apiCategory.sortOrder,
    status: 'idle',
    upsellPhrase: apiCategory.upsellPhrase ?? null,
    upsellPriority: apiCategory.upsellPriority ?? 0,
    version: apiCategory.version,
    spokenName: apiCategory.spokenName ?? apiCategory.name,
    soundboardEntries: apiCategory.soundboardEntries ?? [],
  };
}
