import env from '@beam-australia/react-env';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Reservation } from 'twilio-taskrouter';
import camelcaseKeys from 'camelcase-keys';

import rum from '@rum';
import logger from '@logger';
import { RootState } from '@store';
import {
  coreService,
  dataService,
  operatorService,
  taskrouter,
} from '@services';
import * as agentsSelectors from '@modules/agent/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import * as orderAPI from '@modules/order/api';
import * as clockAPI from '@modules/clock/api';
import { fetchSoundboardPrompts } from '@modules/voicePane/api';
import * as notifications from '@modules/notifications/api';
import { getCategories, getMenuUpsells } from '@modules/menu/actions';
import { resetMenu } from '@modules/menu/slice';
import { fetchPreexistingCart } from '@modules/cart/actions';
import { fetchAccountById } from '@modules/account/actions';
import * as errors from './errors';
import { TaskrouterBrand, TaskrouterStore, WorkerStatus } from './types';
import { actions } from './slice';
import * as selectors from './selectors';
import { startWorkerListeners } from './utils';

const workspaceSid = env('TWILIO_WORKSPACE_SID');

export const initializeTaskrouter = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'taskrouter/initializeTaskrouter',
  async (workerSid, { dispatch, getState }) => {
    const token = await dispatch(fetchWorkerToken({ workerSid }));

    if (token) {
      taskrouter.setup();
      startWorkerListeners(getState());
    }
  },
);

export const fetchWorkerToken = createAsyncThunk<
  string | null,
  { workerSid: string; renew?: boolean }
>('taskrouter/fetchWorkerToken', async (params) => {
  const { workerSid, renew = false } = params;

  const response = await coreService.post<{ accessToken: string }>(
    `/twilio/workspaces/${workspaceSid}/workers/${workerSid}/token`,
  );
  const token = response.data.accessToken;

  taskrouter.setToken(token, { renew });

  return token;
});

export const acceptTask = createAsyncThunk<void, Reservation>(
  'taskrouter/acceptTask',
  async (reservation, { dispatch }) => {
    try {
      await coreService.patch(
        `/twilio/workspaces/${workspaceSid}/tasks/${reservation.task.sid}/reservations/${reservation.sid}`,
        { reservationStatus: 'accepted' },
      );

      dispatch(resetMenu());
      await dispatch(fetchBrand());
      await dispatch(fetchStore(reservation.task.attributes.store.id));
      dispatch(getCategories());
      await dispatch(fetchAccountById(reservation.task.attributes.account_id));
      await dispatch(fetchPreexistingCart());
    } catch (error) {
      logger.error(
        `Failed to accept task ${JSON.stringify(
          error,
          Object.getOwnPropertyNames(error),
        )}`,
      );
      errors.acceptTaskError(error);
    }
  },
);

export const connectConference = createAsyncThunk<string | null>(
  'taskrouter/connectConference',
  async () => {
    try {
      const taskAttributes = selectors.getTaskAttributes();

      if (!taskAttributes) {
        logger.error('No task found to connect conference');
        return null;
      }

      const { conferenceName, caller, orderId } = taskAttributes;

      const agent = agentsSelectors.currentAgent();

      const url = `/Conferences/${conferenceName}/connectAgent`;
      const response = await operatorService.post<{ conference_sid: string }>(
        url,
        {
          contact_uri: `client:${agent.username}`,
          from: caller,
          order_id: orderId,
          agent,
        },
      );

      return response.data.conference_sid;
    } catch (error) {
      logger.error('Failed to connect conference', error);
      errors.connectConferenceError(error);
      return null;
    }
  },
);

export const completeTask = createAsyncThunk<void, void, { state: RootState }>(
  'taskrouter/completeTask',
  async () => {
    try {
      const taskSid = selectors.getTaskSid();

      if (!taskSid) {
        logger.error('Unable to complete task. No task found');
        return;
      }

      const orderId = selectors.deprecatedGetOrderId();
      const url = `/twilio/workspaces/${workspaceSid}/tasks/${taskSid}`;
      await coreService.patch(url, {
        assignmentStatus: 'completed',
        orderId,
      });
    } catch (error) {
      logger.error('Failed to complete task', error);
      errors.completeTaskError(error);
    }
  },
);

export const resetTaskrouter = createAsyncThunk<
  void,
  | {
      shouldCompleteTask?: boolean;
    }
  | undefined,
  { state: RootState }
>('taskrouter/reset', async (params, { dispatch }) => {
  const { shouldCompleteTask = true } = params || {};

  if (shouldCompleteTask) {
    await dispatch(completeTask());
  } else {
    await clockAPI.clockOut();
  }

  clockAPI.reset();
  taskrouter.reset();
  dispatch(actions.resetTaskrouterState());
});

export const getWorkerStatus = createAsyncThunk<
  WorkerStatus | null,
  void,
  { state: RootState }
>('taskrouter/getWorkerStatus', async () => {
  const { id } = agentsSelectors.currentAgent();

  if (!id) {
    logger.error('Unable to get agent ID.');
    return 'Error';
  }

  const response = await dataService.get<{
    status: WorkerStatus;
  }>(`/agents/${id}/status`);

  return response.data.status;
});

export const setWorkerStatus = createAsyncThunk<
  void,
  WorkerStatus,
  { state: RootState }
>('taskrouter/setWorkerStatus', async (status, { dispatch }) => {
  try {
    const { id } = agentsSelectors.currentAgent();

    if (!id) {
      logger.error('Unable to get agent ID.');
      return;
    }

    await dataService.post(`/agents/${id}/status`, { status });
  } catch (error) {
    logger.error('Failed to update agent status', error);
    dispatch(actions.closeStatusOverlay());
    await clockAPI.clockOut();
    errors.updateWorkerStatusError(error);
  }
});

export const fetchBrand = createAsyncThunk<TaskrouterBrand | null, void>(
  'taskrouter/fetchBrand',
  async () => {
    try {
      const brandId = selectors.getStore()?.brandId;
      const response = await dataService.get(`/brands/${brandId}`);

      return camelcaseKeys(response.data, { deep: true });
    } catch {
      logger.error('Failed to fetch brand');
      return null;
    }
  },
);

export const fetchStore = createAsyncThunk<TaskrouterStore, string>(
  'taskrouter/fetchStore',
  async (storeId, { dispatch }) => {
    try {
      const response = await dataService.get(`/stores/${storeId}`);
      const fetchedStore = response.data;

      if (fetchedStore?.platform_name) {
        dispatch(getMenuUpsells());
        await fetchSoundboardPrompts({
          platformName: fetchedStore.platform_name,
          storeId,
          brandId: fetchedStore.brand_id,
        });
      }

      return camelcaseKeys(fetchedStore, { deep: true });
    } catch {
      logger.error('Failed to fetch store');
    }
  },
);

export const fetchBrands = createAsyncThunk<TaskrouterBrand[]>(
  'taskrouter/fetchBrands',
  async () => {
    try {
      const response = await dataService.get(`/brands`);
      const fetchedBrands = response.data;

      return camelcaseKeys(fetchedBrands, { deep: true });
    } catch (error) {
      logger.error('Failed to fetch brands');
      notifications.error({
        title: 'Failed to fetch brands',
        error,
      });
      return null;
    }
  },
);

export const fetchStoresByBrand = createAsyncThunk<TaskrouterStore[], string>(
  'taskrouter/fetchStoresByBrand',
  async (brandId) => {
    try {
      const response = await dataService.get(`/stores?brand_id=${brandId}`);
      const fetchedStores = response.data;

      return camelcaseKeys(fetchedStores, { deep: true });
    } catch (error) {
      logger.error('Failed to fetch stores by brand');
      notifications.error({
        title: 'Failed to fetch stores by brand',
        error,
      });
      return null;
    }
  },
);

export const changeRecordingStatus = createAsyncThunk<void, 'pause' | 'resume'>(
  'taskrouter/changeRecordingStatus',
  async (action) => {
    try {
      const conferenceSid = selectors.deprecatedGetConferenceSid();

      if (!conferenceSid) {
        logger.error(
          'Unable to change recording status. No conference sid found.',
        );
        return;
      }

      const orderId = selectors.deprecatedGetOrderId();

      const method = action === 'pause' ? 'pauseRecording' : 'resumeRecording';

      await operatorService.post(`/conferences/${conferenceSid}/${method}`, {
        order_id: orderId,
      });
    } catch {
      logger.error('Failed to change recording status');
    }
  },
);

export const reassignTask = createAsyncThunk<
  void,
  { isTtfaReassign: boolean },
  { state: RootState }
>('taskrouter/reassignTask', async (_, { dispatch, getState }) => {
  try {
    const taskAttributes = selectors.getTaskAttributes();
    const taskSid = selectors.getTaskSid();

    if (!taskAttributes) {
      logger.error('Unable to reassign task. No task found');
      return;
    }

    const fetchedStore = selectors.getStore();
    const orderId = selectors.deprecatedGetOrderId();
    const reassignedByTtfa = selectors.wasReassignedByTtfa();
    const hasTriedHangup = deviceSelectors.getHasTriedHangup();
    const brandVoice = selectors.getBrandVoice();
    const agent = agentsSelectors.currentAgent();
    const { caller } = taskAttributes;
    const storeId = fetchedStore?.id;

    const conferenceSid = selectors.getConferenceSid(getState());
    const reservationSid = selectors.getReservationSid(getState());

    const url = '/twilio/tasks/reassignTask';

    await coreService.post(url, {
      workspace_sid: workspaceSid,
      workflow_sid: taskrouter.reservation?.task.workflowSid,
      conference_sid: conferenceSid,
      task_sid: taskSid,
      caller,
      store_number: fetchedStore?.secondaryPhone,
      order_id: orderId,
      recovery_reassign: !hasTriedHangup && !reassignedByTtfa,
      brand_voice: brandVoice,
      store_id: storeId,
      agent,
    });

    logger.info('Call Reassigned', {
      workspace_sid: workspaceSid,
      workflow_sid: taskrouter.reservation?.task.workflowSid,
      conference_sid: conferenceSid,
      task_sid: taskSid,
      caller,
      store_number: fetchedStore?.secondaryPhone,
      order_id: orderId,
      recovery_reassign: !hasTriedHangup && !reassignedByTtfa,
      brand_voice: brandVoice,
      agent,
    });
    orderAPI.createOrderEvent('call_reassigned');
    orderAPI.createAnalyticsEvent('call_reassigned_on_uoa');
    rum.addAction('task', {
      reservationsSid: reservationSid,
      ttfaState: 'REASSIGNED',
    });

    const wasReassignedByTtfa = selectors.wasReassignedByTtfa();

    clockAPI.reset();
    taskrouter.reset();
    dispatch(actions.resetTaskrouterState());

    if (wasReassignedByTtfa) {
      dispatch(actions.openStatusOverlay());
    } else {
      notifications.info({
        title: 'Call reassigned',
        message: 'Your call was dropped due to network issues',
        shouldLogout: false,
      });
    }
  } catch (error) {
    logger.error('Failed to reassign task', error);
  }
});

export const takeAction = createAsyncThunk<void, number, { state: RootState }>(
  'taskrouter/takeAction',
  async (ttfaMilliseconds, { dispatch, getState }) => {
    const { taskrouter: state } = getState();
    dispatch(actions.onAgentTookAction());

    logger.action('TtFA Acknowledgement');

    orderAPI.createOrderEvent('ttfa_acknowledgement', {
      ms: ttfaMilliseconds,
    });
    orderAPI.createAnalyticsEvent('time_to_first_action_accepted', {
      ms: ttfaMilliseconds,
      agent: agentsSelectors.currentAgent(),
    });
    rum.addAction('task', {
      reservationSid: state.reservationSid,
      taskSid: state.taskSid,
      ttfaState: 'ACTION_TAKEN',
      ttfa: ttfaMilliseconds,
    });
  },
);

export const createTrainingTask = createAsyncThunk<
  void,
  { brandId: string; storeId: string }
>(
  'taskrouter/createTrainingTask',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { brandId, storeId } = params;
      const agent = agentsSelectors.currentAgent();

      if (!agent) {
        throw new Error(
          'Unfortunately, it was not possible to create a training task. No agent available.',
        );
      }

      const response = await coreService.post('/twilio/tasks/trainingTask', {
        workspace_sid: env('TWILIO_WORKSPACE_SID'),
        workflow_sid: env('TWILIO_TRAINING_WORKFLOW_SID'),
        trainer_nickname: `self-${agent?.username}`,
        store_id: storeId,
        agent,
        brand_id: brandId,
        handoff_mode: 'pickup',
      });
      if (!response.data?.success) {
        throw new Error(
          'Unfortunately, it was not possible to create a training task.',
        );
      }
    } catch (error) {
      logger.error('Failed to create task', error);
      dispatch(actions.resetTaskrouterState());
      clockAPI.clockOut();
      notifications.error({
        title: 'Failed to create task',
        message:
          'Unfortunately, it was not possible to create a training task.',
        error,
      });
      return rejectWithValue(error);
    }
  },
);
