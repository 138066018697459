import React, { useState } from 'react';
import { IoCheckmarkOutline, IoPauseSharp, IoPlaySharp } from 'react-icons/io5';
import { BsArrowRepeat } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { useHotkeys } from 'react-hotkeys-hook';

import * as DynamicBadges from '@components/DynamicBadges';

import { VbxController } from './VbxController';
import { ItemSummary } from './ItemSummary';

import * as S from './styles';

export function LeftPanelFooter() {
  const [customVbxEnabled, setCustomVbxEnabled] = useState(false);

  useHotkeys('shift+v+b+x', () => setCustomVbxEnabled((prev) => !prev));

  return (
    <S.Footer>
      <S.VbxCollapse isCollapsed>
        <div className="quick_actions">
          <DynamicBadges.OKBadge
            size="normal"
            leftIcon={IoCheckmarkOutline}
            shortcut="!"
          />
          <DynamicBadges.StallBadge
            size="normal"
            leftIcon={IoPauseSharp}
            shortcut="@"
          />
          <DynamicBadges.NextItemBadge
            size="normal"
            leftIcon={IoPlaySharp}
            shortcut="#"
          />
          <DynamicBadges.UpsellBadge
            size="normal"
            leftIcon={MdAttachMoney}
            shortcut="$"
          />
          <DynamicBadges.RepeatBadge
            size="normal"
            leftIcon={BsArrowRepeat}
            shortcut="%"
          />
        </div>

        {customVbxEnabled && <VbxController />}
      </S.VbxCollapse>

      <ItemSummary isCollapsed={false} />
    </S.Footer>
  );
}
