import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { Loading } from '@components';
import { useToken, useRoles, useAgents } from '@hooks';

import * as authAPI from '@modules/auth/api';
import * as agentAPI from '@modules/agent/api';

const Auth0Redirect = () => {
  const isLogged = useSelector(({ auth }) => auth.logged);
  const { current, pending, errors } = useAgents();

  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const token = useToken();
  const history = useHistory();
  const { roles } = useRoles();

  const loginWithinAuth0 = () => {
    if (!isAuthenticated) loginWithRedirect();
  };

  const saveAuthInformation = () => {
    const isAbleToLogin = !!user && !!token && !!roles;
    if (isAbleToLogin) authAPI.login(user, token, roles);
  };

  const fetchAgentDataWhenLogged = () => {
    if (isLogged && isAuthenticated && !current) agentAPI.fetchByAuth0Id();
  };

  const onFetchAgentFinish = () => {
    const isAgentFetched =
      current !== null && !pending.isFetching && !errors.hasFetchError;
    if (isAgentFetched) history.replace('/home');
  };

  useEffect(loginWithinAuth0, [isAuthenticated]);
  useEffect(saveAuthInformation, [user, token, roles]);
  useEffect(fetchAgentDataWhenLogged, [isLogged, isAuthenticated]);
  useEffect(onFetchAgentFinish, [current, pending, errors]);

  return <Loading />;
};

export default Auth0Redirect;
