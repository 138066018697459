import { dataService } from '@services';
import env from '@beam-australia/react-env';
import * as agentSelectors from '@modules/agent/selectors';

const workspaceSid = env('TWILIO_WORKSPACE_SID');

export async function clockOutAgentById(id) {
  const payload = { agent_id: id, workspace_sid: workspaceSid };
  const response = await dataService.post('/clock-times/clock-out/', payload);
  return response.data;
}

export async function clockInAgentById(id) {
  const agent = agentSelectors.currentAgent();
  const brands = agent && agent.brands ? agent.brands : [];

  const trainedOrQueuedBrands = brands
    .filter((brand) => brand.queued)
    .map((brand) => brand.id);

  const payload = {
    agent_id: id,
    workspace_sid: workspaceSid,
    queued_brands: trainedOrQueuedBrands,
  };
  const response = await dataService.post('/clock-times/clock-in/', payload);
  return response.data;
}

export async function getAgentClockTime(agentId) {
  const response = await dataService.get(`/clock-times/${agentId}`);
  return response;
}

export const sleep = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));
