import { theme } from '@kea-inc/parrot-ui';
import styled, { css } from 'styled-components';
import AnnounceBadge from '@components/AnnounceBadge';

export const Container = styled.section`
  ${(props) =>
    props.hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  > header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 12px 0;

    border-bottom: 1px solid ${theme.colors.gray_lighter};

    > section {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      > svg {
        font-size: 30px;
        margin-left: 16px;
        padding: 5px;
        border-radius: 50px;
        color: ${theme.colors.primary};
        background-color: ${theme.colors.primary_lightest_2};
        cursor: pointer;

        transition: 0.2s;

        &:hover {
          background-color: ${theme.colors.primary_lighter};
        }
      }
    }

    > span {
      margin: 4px 0 8px;
    }

    small {
      color: ${theme.colors.primary_darker};
    }
  }

  .header__alert {
    margin-bottom: 16px;

    > div div:last-child {
      margin-left: 0;
    }
  }

  > form {
    padding: 12px 0;

    > section {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      align-items: end;
      column-gap: 16px;
      margin-bottom: 16px;

      &.order__time.open {
        margin-bottom: 340px;
      }

      .throttledTimePicker__select .dropdown_menu {
        overflow-y: scroll !important;
        max-height: 300px !important;

        .unavailable {
          background: ${theme.colors.gray_lighter};
        }
      }
    }

    .upsert {
      display: flex;
      align-items: flex-end;

      button {
        margin-bottom: 12px;
      }
    }
  }

  .orderTime__badgesWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

type ClosingTimeProps = {
  rotateArrow: boolean;
};

export const ClosingTime = styled.div<ClosingTimeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.3rem;
  width: 100%;

  .closingTime_time {
    flex: 1;
    font-size: 0.9rem;
  }

  svg {
    color: ${theme.colors.primary_darker};
  }

  .closingTime_arrow {
    cursor: pointer;
    transform: ${(props) =>
      props.rotateArrow ? 'rotate(-180deg)' : 'rotate(0deg)'};

    transition: transform 0.2s;

    &:hover {
      background-color: ${theme.colors.primary_lightest_2};
      border-radius: 50px;
    }
  }
`;

export const StoreHours = styled.div`
  .storeHours__badge {
    margin-top: 8px;
  }
`;

export const Badge = styled(AnnounceBadge)`
  margin-bottom: 8px;
`;

export const Label = styled.div`
  margin-bottom: 8px;
`;

export const HandoffModeContainer = styled.div`
  display: flex;
  align-items: center;

  .label__Badge {
    margin-bottom: 0.5rem;
  }

  .tableNumber {
    margin-left: 1rem;
  }
`;
