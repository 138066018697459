import { ItemStatus, Modifier, Option } from '@kea-inc/types';
import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';
import isInAvailabilityRange from './is-in-availability-range';

type GetModifierStatusReturn = {
  status: ItemStatus;
  erroredOption?: Option;
  erroredQuantity?: number;
};

export default function getModifierStatus(
  modifier: Modifier,
  selectedOptions: Array<{
    option: Option;
    quantity: number;
  }>,
): GetModifierStatusReturn {
  const storeTimezone = deprecatedGetStoreTimezone();

  if (!isInAvailabilityRange(modifier, storeTimezone)) {
    return { status: ItemStatus.MODIFIER_OUTSIDE_AVAILABLE_TIME };
  }

  const totalSelectedOptionQuantity = selectedOptions.reduce(
    (total, { quantity }) => total + quantity,
    0,
  );

  if (modifier.isRequired && totalSelectedOptionQuantity === 0) {
    return { status: ItemStatus.REQUIRED_MODIFIER_MISSING_OPTIONS };
  }

  const { minOptions, maxOptions, minQuantity, maxQuantity } = modifier;

  if (minOptions && selectedOptions.length < Number(minOptions)) {
    return { status: ItemStatus.INSUFFICIENT_UNIQUE_OPTIONS };
  }

  if (maxOptions && selectedOptions.length > Number(maxOptions)) {
    return { status: ItemStatus.EXCESS_UNIQUE_OPTIONS };
  }

  if (minQuantity && totalSelectedOptionQuantity < Number(minQuantity)) {
    return { status: ItemStatus.INSUFFICIENT_TOTAL_OPTION_QUANTITY };
  }

  if (maxQuantity && totalSelectedOptionQuantity > Number(maxQuantity)) {
    return { status: ItemStatus.EXCESS_TOTAL_OPTION_QUANTITY };
  }

  for (const { option, quantity } of selectedOptions) {
    if (!isInAvailabilityRange(option, storeTimezone)) {
      return {
        status: ItemStatus.OPTION_OUTSIDE_AVAILABLE_TIME,
        erroredOption: option,
        erroredQuantity: quantity,
      };
    }

    // Leave comment
    if (option.metadata?.hasStockAvailable === false) {
      return {
        status: ItemStatus.OPTION_OUT_OF_STOCK,
        erroredOption: option,
        erroredQuantity: quantity,
      };
    }

    if (
      modifier.minQuantityPerOption &&
      quantity < Number(modifier.minQuantityPerOption)
    ) {
      return {
        status: ItemStatus.INSUFFICIENT_SINGLE_OPTION_QUANTITY,
        erroredOption: option,
        erroredQuantity: quantity,
      };
    }

    if (
      modifier.maxQuantityPerOption &&
      quantity > Number(modifier.maxQuantityPerOption)
    ) {
      return {
        status: ItemStatus.EXCESS_SINGLE_OPTION_QUANTITY,
        erroredOption: option,
        erroredQuantity: quantity,
      };
    }

    if (
      modifier.quantityInterval &&
      quantity % Number(modifier.quantityInterval) !== 0
    ) {
      return {
        status: ItemStatus.INVALID_OPTION_QUANTITY_INTERVAL,
        erroredOption: option,
        erroredQuantity: quantity,
      };
    }
  }

  return { status: ItemStatus.COMPLETE };
}
