import { useAppSelector } from '@store';
import { couponActions } from '@modules/coupons/slice';
import * as couponsSelectors from '@modules/coupons/selectors';
import { getBrand, getStoreId } from '@modules/taskrouter/selectors';

export function useConnector() {
  const selectors = {
    brandId: useAppSelector(getBrand)?.id,
    storeId: useAppSelector(getStoreId),
    coupons: useAppSelector((state) =>
      couponsSelectors.selectAllCoupons(state.coupons),
    ),
    currentCoupon: useAppSelector(couponsSelectors.currentCoupon),
    isFetchingCoupons: useAppSelector(couponsSelectors.isFetchingCoupons),
    searchTerm: useAppSelector(couponsSelectors.searchTerm),
    filteredCoupons: useAppSelector(couponsSelectors.getFilteredCoupons),
    isSaving: useAppSelector(couponsSelectors.isSaving),
    isRemoving: useAppSelector(couponsSelectors.isRemoving),
  };

  const actions = {
    fetchCoupons: couponActions.fetchCoupons,
    updateOrderWithCoupon: couponActions.updateOrderWithCoupon,
    removeCoupon: couponActions.removeCouponFromOrder,
    clearCouponSelection: couponActions.clearCouponSelection,
    selectCoupon: couponActions.onCouponClick,
    setCustomCoupon: couponActions.setCustomCoupon,
    setSearchTerm: couponActions.onSearchTermChange,
    onCancelClick: couponActions.onCancelClick,
  };

  return {
    selectors,
    actions,
  };
}
