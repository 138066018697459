import { Upsold } from '@modules/menu/types';
import { createAnalyticsEvent, createOrderEvent } from '@modules/order/api';

export function createUpsoldEvent(params: {
  upsoldV2: Upsold | null;
  orderItemId: string;
  item: { id: string; name: string; parentId: string };
  quantity: number;
  upsoldEntities: { id: string }[];
}) {
  const shouldCreateEvent = getShouldCreateUpsoldEvent(params);

  if (!shouldCreateEvent) {
    return;
  }

  const { orderItemId, item, quantity } = params;

  createOrderEvent('added_upsell_item_to_cart', {
    order_item_id: orderItemId,
    item_id: item.id,
    name: item.name,
    category: item.parentId,
    entity: item,
    quantity,
  });
  createAnalyticsEvent('added_upsell_item_to_cart', {
    order_item_id: orderItemId,
    item_id: item.id,
    name: item.name,
    category: item.parentId,
    entity: item,
    quantity,
  });
}

export function getShouldCreateUpsoldEvent(params: {
  upsoldV2: Upsold | null;
  upsoldEntities: { id: string; parentId?: string }[];
  item: { id: string; name: string; parentId: string };
}) {
  if (params.upsoldV2) {
    const { type } = params.upsoldV2;
    return type && type !== 'promo';
  }

  const { upsoldEntities, item } = params;

  return upsoldEntities.some(
    (entity) => entity.id === item.id || entity.id === item.parentId,
  );
}
