import styled from 'styled-components';
import {
  theme,
  Button as parrotButton,
  Dropdown,
  Chip as parrotChip,
} from '@kea-inc/parrot-ui';

export const Container = styled.header`
  display: grid;
  grid-template-columns: 5% 31% 60% 4%;
  align-items: center;

  padding: 0 1.5rem;
  width: 100%;
  height: 3.5rem;

  border-bottom: 1px solid ${theme.colors.gray_light};
  background: ${theme.colors.white};

  > span {
    margin-top: 0.2rem;
  }

  img {
    width: 2.5rem;
  }

  .avatar {
    margin-left: auto;
    cursor: pointer;
  }

  .dropdown_menu {
    margin-top: 0.75rem;

    .logout__Button {
      p {
        color: ${theme.colors.error};
      }
    }
  }

  .version {
    display: flex;
    align-items: center;
  }
`;

export const Actions = styled.section`
  display: flex;
  align-items: center;

  margin-left: auto;
`;

export const Button = styled(parrotButton)`
  margin-left: 1rem;
  min-width: 4.8rem;
`;

export const BrandName = styled.div`
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid ${theme.colors.gray};
`;

export const Chip = styled(parrotChip)`
  background: ${(props) =>
    props.color && theme.colors[`${props.color}_lightest_2`]};
  border: 2px solid ${(props) => props.color && theme.colors[props.color]};
  padding: 3px 12px;
  align-self: flex-end;
  margin-left: 1rem;
  p {
    color: ${(props) => props.color && theme.colors[props.color]};
    font-size: 15px;
    font-weight: bold;
  }
  svg {
    color: ${(props) => props.color && theme.colors[props.color]};
    font-size: 21px;
  }
`;

export const StatusButton = styled(parrotButton)`
  width: 100%;
  color: white;
  margin-top: 0.5rem;
  background: ${(props) => props.color && theme.colors[props.color]};
`;

export const DropdownMenu = styled(Dropdown.Menu)`
  div {
    padding: 0 0.5rem;
  }
`;

export const LeaveTrainingModeButton = styled(parrotButton)`
  margin-left: 1rem;
  min-width: 4.8rem;
  background: ${theme.colors.accent_1_dark};
`;
