import { store } from '@store';

function survey() {
  return store.getState().survey;
}

export function getPrimaryDisposition() {
  const { primaryDisposition } = survey();
  return primaryDisposition;
}

export function getSecondaryDisposition() {
  const { secondaryDisposition } = survey();
  return secondaryDisposition;
}

export function getComments() {
  const { comments } = survey();
  return comments;
}

export function getOrderId() {
  const { orderId } = survey();
  return orderId;
}

export function getWasSubmitted() {
  const { wasSubmitted } = survey();
  return wasSubmitted;
}

export function getSurveyPayload() {
  return {
    orderId: getOrderId(),
    eventName: 'survey',
    eventTags: {
      primaryDisposition: getPrimaryDisposition(),
      secondaryDisposition: getSecondaryDisposition(),
      comments: getComments(),
      placed: getWasSubmitted(),
    },
  };
}
