import { Typography } from '@kea-inc/parrot-ui';

import { ORDER_CHECKLIST } from '@modules/order/state';
import { DeliveryInstructionsBadge } from '@components/DynamicBadges';

import { ChecklistItem } from './ChecklistItem';

export const HasAskedForDeliveryInstructions = () => (
  <ChecklistItem field={ORDER_CHECKLIST.HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS}>
    <DeliveryInstructionsBadge />
    <Typography weight="heavy">&nbsp; asked</Typography>
  </ChecklistItem>
);
