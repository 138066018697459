import React, { createContext, useEffect } from 'react';

import { CLOCK_STATUS } from '@modules/clock/state';
import { useClock, useHistory, useAgents, useDevice } from '@hooks';
import { useAppSelector } from '@store';
import { getHasOngoingTask } from '@modules/taskrouter/selectors';

const RedirectContext = createContext({});

export const RedirectProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const { status } = useClock();
  const { current: currentAgent } = useAgents();
  const hasOngoingTask = useAppSelector(getHasOngoingTask);
  const { hasOngoingCall } = useDevice();

  const goToTaskView = () => history.replace('/dashboard');
  const goToAuthentication = () => history.replace('/');
  const goToBrandsList = () => history.replace('/home');
  const goToWaitingRoom = () => history.replace('/waiting');

  const redirectUserBasedOnState = () => {
    if (currentAgent === null) return goToAuthentication();
    if (hasOngoingTask && hasOngoingCall) return goToTaskView();
    if (status === CLOCK_STATUS.CLOCK_OUT) return goToBrandsList();
    if (status === CLOCK_STATUS.CLOCK_IN) return goToWaitingRoom();
  };

  useEffect(redirectUserBasedOnState, [
    currentAgent,
    status,
    hasOngoingTask,
    hasOngoingCall,
  ]);

  return (
    <RedirectContext.Provider value={{}}>{children}</RedirectContext.Provider>
  );
};
