import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${(props) =>
    props.hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  .transcripts__Chat {
    background: #ffffff;
    padding: 0;
    border: none;
    min-width: 0;
  }
`;
