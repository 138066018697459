export function transformToFaroContext(
  data: Record<string, unknown>,
): Record<string, string> {
  const faroContext: Record<string, string> = {};

  try {
    Object.keys(data).forEach((key) => {
      faroContext[key] = JSON.stringify(data[key as keyof typeof data]);
    });

    return faroContext;
  } catch (error) {
    return { error: 'Unable to transform context into string' };
  }
}
