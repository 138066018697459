import styled, { css, keyframes } from 'styled-components';

import { Button, theme, Typography } from '@kea-inc/parrot-ui';
import { AnnounceBadge } from '@components';
import { ImSpinner2 } from 'react-icons/im';

export const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CartSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border-top: 1px solid ${theme.colors.gray_lighter};
`;

export const SummaryInfo = styled.section`
  display: flex;
  align-items: center;
  margin-top: 8px;

  .summaryInfo__badge {
    margin-left: auto;
  }
`;

export const SummaryLabel = styled(Typography).attrs({
  weight: 'heavy',
})`
  margin-left: auto;
`;

export const SummaryBadge = styled(AnnounceBadge)`
  margin-left: auto;
`;

export const SummaryCost = styled(Typography).attrs({
  weight: 'heavy',
})`
  max-width: 82px;
  width: 100%;
  text-align: end;
`;

export const HighlightedButton = styled(Button).attrs({
  size: 'small',
  tertiary: true,
})`
  padding: 0;
  max-width: 82px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  p {
    font-size: 14px;
    color: ${theme.colors.primary};
  }
`;

export const PaymentInfoButton = styled(Button).attrs({
  size: 'small',
  tertiary: true,
})`
  padding: 0;

  ${(props) =>
    !props.shouldAppear &&
    css`
      pointer-events: none;
      display: none;
    `}
`;

export const RemoveTip = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  button {
    margin-left: 15px;
    margin-right: 5px;
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 12,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};

  p:has(&) {
    text-align: center;
  }
`;
