import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import {
  useClock,
  useDevice,
  useInterval,
  useKeyPress,
  useNotification,
} from '@hooks';
import { formatPhoneNumber } from '@utils';

import { Typography, Button } from '@kea-inc/parrot-ui';
import CallingSound from '@sounds/receiving_task.mp3';
import logger from '@logger';
import { useClearCache } from 'react-clear-cache';
import { MdCall } from 'react-icons/md';

import { useAppSelector } from '@store';
import { useConnector } from '../useConnector';
import * as S from './styles';
import { Hourglass } from './hourglass';
import { Calling } from './calling';

const audio = document.createElement('audio');
audio.src = CallingSound;
audio.loop = true;
audio.preload = 'auto';
audio.volume = 1;
audio.currentTime = 0;

const ReceivedCall = () => {
  const { selectors } = useConnector();
  const { shouldShowRingAnimation, shouldShowConnectingAnimation } = selectors;
  const { datetime } = useClock();
  const device = useDevice();
  const taskrouter = useAppSelector((state) => state.taskrouter);
  const { notificationAPI } = useNotification();
  const { isPressed: escPressed } = useKeyPress('Escape');
  const { emptyCacheStorage } = useClearCache({
    auto: false,
    duration: 1000 * 60 * 60 * 24, // 24 hour
  });

  useEffect(() => {
    logger.info('Task/Connection changes', {
      taskrouter,
      device,
    });
  }, [taskrouter, device]);

  useEffect(() => {
    if (shouldShowRingAnimation && audio.paused) {
      audio.currentTime = 0;
      audio
        .play()
        .then(() => {
          logger.info('Ring audio played');
        })
        .catch((error) => {
          logger.error('Ring audio failed to play', {
            errorMessage: error,
          });
        });
    }

    return () => {
      audio.pause();
    };
  }, [shouldShowRingAnimation]);

  useEffect(() => {
    if (escPressed) {
      audio.pause();
    }
  }, [escPressed]);

  useInterval(() => {
    const now = DateTime.now();
    const clockInTime = DateTime.fromMillis(datetime);
    const diff = now.diff(clockInTime, 'hours');
    const { hours } = diff.toObject();

    // The token TTL is 8 hours, so the notification will dispatch at 7.5 hours
    if (hours && hours >= 7.5) {
      dispatchTokenExpiredNotification();
    }
  }, 1000 * 60); // 1 minute

  const dispatchTokenExpiredNotification = () => {
    notificationAPI.info({
      title: 'Device token expired',
      message: 'Please clock in again to be able to receive calls',
      dismissText: 'Clock out',
      onDismiss: emptyCacheStorage,
    });
  };

  if (shouldShowRingAnimation && device.ready) {
    const { taskAttributes } = taskrouter;
    const formattedPhoneNumber = formatPhoneNumber(taskAttributes?.caller);
    const location = `${taskAttributes?.store.city}, ${taskAttributes?.store.state}`;

    return (
      // @ts-expect-error - Missing type in Parrot, but it's a valid prop
      <S.Container padded className="received__call received__calling">
        <ReceivingCall />

        <Typography
          variant="display"
          weight="heavy"
          style={{ textAlign: 'center' }}
        >
          {taskAttributes?.brandResult.name} at
          <br />
          {location}
          <Typography color="black_light">{formattedPhoneNumber}</Typography>
        </Typography>

        <Button loading />

        <Typography color="black_light">
          The call was automatically accepted
        </Typography>
      </S.Container>
    );
  }

  return (
    // @ts-expect-error - Missing type in Parrot, but it's a valid prop
    <S.Container padded className="received__call">
      {shouldShowConnectingAnimation ? <ConnectingNew /> : <WaitingForCall />}
    </S.Container>
  );
};

export const ConnectingNew = () => (
  <S.Connecting>
    <S.Loading />
  </S.Connecting>
);

const WaitingForCall = () => (
  <Hourglass>
    <div className="hourglass" />
  </Hourglass>
);

const ReceivingCall = () => (
  <Calling>
    <div className="call-animation">
      <MdCall className="caller-img" size={48} />
    </div>
  </Calling>
);

export default React.memo(ReceivedCall);
