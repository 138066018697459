import { CLOCK_STATUS } from '@modules/clock/state';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import { getUserFeatureFlags } from '@modules/featureFlags/selectors';
import { useAppSelector } from '@store';

export function useConnector() {
  const selectors = {
    reservationSid: useAppSelector(taskrouterSelectors.getConferenceSid),
    hasOngoingTask: useAppSelector(taskrouterSelectors.getHasOngoingTask),
    brandName: useAppSelector(taskrouterSelectors.getBrandName),
    isTrainingTask: useAppSelector(taskrouterSelectors.isTrainingTask),

    isDeviceRegistered: useAppSelector(deviceSelectors.isDeviceRegistered),
    deviceIsPending: useAppSelector(deviceSelectors.isDevicePending),
    hasOngoingCall: useAppSelector(deviceSelectors.getHasOngoingCall),
    deviceHasError: useAppSelector(deviceSelectors.getDeviceHasError),
    isPendingPreflight: useAppSelector(deviceSelectors.isPendingPreflight),

    isClockingIn: useAppSelector(({ clock }) => clock.pending.isClockingIn),
    isClockingOut: useAppSelector(({ clock }) => clock.pending.isClockingOut),
    isClockedIn: useAppSelector(
      ({ clock }) => clock.status === CLOCK_STATUS.CLOCK_IN,
    ),

    user: useAppSelector(({ auth }) => auth.user),
    featureFlagsUser: useAppSelector(getUserFeatureFlags),
  };

  return {
    selectors,
  };
}
