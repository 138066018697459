import { store } from '@store';

import { fields, streamFields } from './state';

export function payment() {
  return store.getState().payment;
}

export function getCreditCard() {
  return payment().creditCard;
}

export function getManualCreditCardValueByField(field) {
  return payment().manualCreditCard[field];
}

export function isStreamConnected() {
  return payment().streamConnected;
}

export function getFieldsStatuses() {
  const creditCard = getCreditCard();

  const statuses = {
    [streamFields.NUMBER]: 'notFilled',
    [streamFields.EXP_DATE]: 'notFilled',
    [streamFields.ZIPCODE]: 'notFilled',
    [streamFields.CVV]: 'notFilled',
  };

  for (const field of Object.keys(statuses)) {
    const isFilled = !!creditCard[field];
    if (!isFilled) {
      statuses[field] = 'filling';
      break;
    } else {
      statuses[field] = 'filled';
    }
  }

  return {
    statuses,
    success: Object.values(statuses).every((status) => status === 'filled'),
  };
}

export function getCreditCardTokens() {
  const { creditCard } = payment();
  return creditCard;
}

export function isManualPaymentValid() {
  const { manualCreditCard } = payment();
  const number = manualCreditCard[fields.NUMBER];
  const cvv = manualCreditCard[fields.CVV];
  const expDate = manualCreditCard[fields.EXP_DATE];
  const zipCode = manualCreditCard[fields.ZIPCODE];

  if (number && number.length < 10) return false;
  if (cvv && cvv.length !== 3 && cvv.length !== 4) return false;
  if (expDate && expDate.length !== 5) return false;
  if (zipCode && zipCode.length !== 5) return false;

  return true;
}

export function didPaybotRun() {
  return payment().paybotRanOnce;
}
