import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';

import { useAppDispatch } from '@store';
import * as deviceActions from '@modules/device/device-actions';

import { useDevice } from '@hooks';
import * as S from './styles';
import { useConnector } from '../useConnector';

const MuteUnmuteBadge = () => {
  const dispatch = useAppDispatch();
  const { isMuted, pending } = useDevice();
  const { isMuting } = pending;

  const { selectors } = useConnector();
  const { isTrainingTask } = selectors;

  return isMuted ? (
    <S.Badge
      onClick={() => dispatch(deviceActions.unmute())}
      variant="error"
      leftIcon={FaMicrophone}
      disabled={isMuting || isTrainingTask}
    >
      Unmute
    </S.Badge>
  ) : (
    <S.Badge
      onClick={() => dispatch(deviceActions.mute())}
      variant="error"
      leftIcon={FaMicrophoneSlash}
      disabled={isMuting}
    >
      Mute
    </S.Badge>
  );
};

export default MuteUnmuteBadge;
