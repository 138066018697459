import * as orderAPI from '@modules/order/api';
import * as accountSlice from '@modules/account/slice';
import { reassignTask, setWorkerStatus } from '@modules/taskrouter/actions';
import * as voicePaneAPI from '@modules/voicePane/api';
import * as paymentAPI from '@modules/payment/api';
import * as clockAPI from '@modules/clock/api';
import * as transcriptsAPI from '@modules/transcripts/api';
import store from '@store';
import { getUserFeatureFlags } from '@modules/featureFlags/selectors';

export async function handleReassignTask(isTtfaReassign = false) {
  const promises = [];
  promises.push(orderAPI.endOrder());
  promises.push(store.dispatch(reassignTask({ isTtfaReassign })));
  promises.push(transcriptsAPI.reset());

  const { useAgentStatuses } = getUserFeatureFlags(store.getState());

  if (useAgentStatuses) {
    store.dispatch(setWorkerStatus('Absent'));
  } else {
    promises.push(clockAPI.clockOut());
  }

  store.dispatch(accountSlice.reset());
  voicePaneAPI.reset();
  paymentAPI.closeStream();
  paymentAPI.reset();
  await Promise.all(promises);
}
