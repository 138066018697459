import env from '@beam-australia/react-env';

import logger from '@logger';
import { dataService } from '@services';
import store, { dispatch } from '@store';

import twilioDevice from '@services/twilio-device';
import { setWorkerStatus } from '@modules/taskrouter/actions';
import { resetWorkerStatus } from '@modules/taskrouter/slice';
import * as agentSelectors from '@modules/agent/selectors';
import * as clockSelectors from '@modules/clock/selectors';
import * as actions from './actions';
import * as errors from './errors';
import * as utils from './utils';

const workspaceSid = env('TWILIO_WORKSPACE_SID');
export function reset() {
  dispatch(actions.clockReset());
}

export async function getCurrentClockStatus() {
  dispatch(actions.clockFetchingClockStatus());

  const { id } = agentSelectors.currentAgent();

  try {
    const result = await dataService.get(`/clock-times/${id}`);

    dispatch(actions.clockFetchedClockStatus(result.data, id));
  } catch (error) {
    dispatch(actions.clockNotFetchedClockStatus());
    // log the error
  }
}

export async function clockIn() {
  dispatch(actions.clockClockingIn());
  dispatch(resetWorkerStatus());
  const { id } = agentSelectors.currentAgent();

  try {
    const agentClockTime = await utils.getAgentClockTime(id);

    if (
      agentClockTime &&
      agentClockTime.data &&
      agentClockTime.data.action === 'CLOCK_IN'
    ) {
      await clockOut();
      // Waiting pulse-check to disconnect other sessions.
      await utils.sleep(3000);
    }
    const response = await utils.clockInAgentById(id);
    await dispatch(setWorkerStatus('Idle'));
    await twilioDevice.register();
    connectPulseCheck();
    dispatch(actions.clockClockedIn(response, id));
  } catch (error) {
    dispatch(actions.clockNotClockedIn());
    errors.clockInError(error);
  }
}

export async function clockOut() {
  dispatch(actions.clockClockingOut());
  dispatch(resetWorkerStatus());
  await twilioDevice.unregister();

  const { id } = agentSelectors.currentAgent();

  try {
    const response = await utils.clockOutAgentById(id);
    await store.dispatch(setWorkerStatus('Offline'));
    dispatch(actions.clockClockedOut(response.data, id));
  } catch (error) {
    dispatch(actions.clockNotClockedOut());
    errors.clockOutError(error);
  }
}

export async function pulseCheckPing() {
  const agent = agentSelectors.currentAgent();

  try {
    await dataService.get(`/ping/${agent.auth0_user_id}`, { version: 'v0' });
  } catch (error) {
    logger.warn(
      'Pulse check ping failed. Agent will be clocked out if error persists',
      { errorData: error },
    );
  }
}

export async function connectPulseCheck() {
  const agentId = agentSelectors.currentAgentId();
  const workerSid = agentSelectors.getTwilioWorkerSid();
  const source = dataService.getEvent(
    `/pulse-check/workspaces/${workspaceSid}/agents/${agentId}/${workerSid}`,
  );

  source.addEventListener(
    'pulse_check',
    (event) => {
      if (clockSelectors.isClockedOut()) {
        source.close();
      } else if (event) {
        const { data } = event;
        if (data === 'CLOCK_OUT') {
          clockOut();
          source.close();
        }
        logger.debug('Received pulse_check event', data);
      }
    },
    false,
  );
}
