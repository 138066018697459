export const INITIAL_STATE = {
  byId: {},
  current: null,
  stack: [],
};

export const notificationTypes = {
  ERROR: 'error',
  INFO: 'info',
};
