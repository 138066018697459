import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';
import { AiFillInfoCircle } from 'react-icons/ai';

export const RequiredContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  .requiredMark__announce {
    margin-left: 8px;
  }
`;

export const RequiredIcon = styled(AiFillInfoCircle)`
  color: ${theme.colors.error};
  margin-right: 4px;
`;
