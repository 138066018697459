import { Modifier } from '@modules/menu/types';
import { HoverController } from '../HoverController';
import * as S from './styles';
import { OptionInfo } from '../OptionInfo';
import { InlineRadioOptionsSelector } from './InlineRadioOptionsSelector';

type AddedOptionProps = {
  name: string;
  cost: number;
  onRemove: () => void;
  childrenModifier?: Modifier;
  isFullyAdded: boolean;
  isLoadingNestedModifiers?: boolean;
  onFullyAdd: () => void;
};

export function AddedOption({
  name,
  cost,
  isFullyAdded,
  isLoadingNestedModifiers,
  onRemove,
  onFullyAdd,
  childrenModifier,
}: AddedOptionProps) {
  const hasOptionInlineRadioSelector =
    childrenModifier?.displayMode === 'INLINE_RADIO';

  return isFullyAdded ? (
    <S.FullyAddedOption onClick={onRemove}>
      <OptionInfo
        name={name}
        cost={cost}
        isLoading={isLoadingNestedModifiers}
        isSelected
      />

      {hasOptionInlineRadioSelector && childrenModifier ? (
        <>
          <HoverController
            text="REMOVE"
            hasOptionInlineRadioSelector={hasOptionInlineRadioSelector}
          />
          <InlineRadioOptionsSelector modifier={childrenModifier} />
        </>
      ) : (
        <HoverController text={isFullyAdded ? 'REMOVE' : 'FULLY ADD'} />
      )}
    </S.FullyAddedOption>
  ) : (
    <S.HalfAddedOption onClick={onFullyAdd}>
      <OptionInfo
        name={name}
        cost={cost}
        isLoading={isLoadingNestedModifiers}
        isSelected
      />

      {hasOptionInlineRadioSelector && childrenModifier ? (
        <>
          <HoverController
            text="REMOVE"
            hasOptionInlineRadioSelector={hasOptionInlineRadioSelector}
          />
          <InlineRadioOptionsSelector modifier={childrenModifier} />
        </>
      ) : (
        <HoverController text={isFullyAdded ? 'REMOVE' : 'FULLY ADD'} />
      )}
    </S.HalfAddedOption>
  );
}
