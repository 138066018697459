import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useOrder } from '@hooks';

import * as S from '../styles';

export const ChecklistItem = ({ field, children }) => {
  const { checklist } = useOrder();
  const isComplete = useMemo(() => {
    if (Array.isArray(field)) {
      return field.every((f) => checklist[f]);
    }

    return checklist[field];
  }, [field, checklist]);

  return (
    <S.ChecklistItem>
      <S.Status
        className="status"
        complete={isComplete ? 1 : 0}
        data-testid={field}
        data-test-id="rac-status-icon"
        data-status={isComplete ? 'complete' : 'incomplete'}
      />
      {children}
    </S.ChecklistItem>
  );
};

ChecklistItem.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
