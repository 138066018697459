import React, { useEffect } from 'react';

import { ValidationError } from '@modules/order/types';
import * as notificationsApi from '@modules/notifications/api';
import { mapOrderErrorToMessage } from '@modules/order/utilsV2';
import { setChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import { useAppDispatch, useAppSelector } from '@store';
import { selectIsCartEmpty } from '@modules/cart/selectors';
import { useNotification } from '@hooks';
import { announce } from '@modules/device/device-actions';

const ValidationContext = React.createContext({} as ValidationContextValues);

export function ValidationProvider(props: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const validation = useAppSelector((state) => state.cart.validation);
  const isCartEmpty = useAppSelector(selectIsCartEmpty);
  const { isValid, errors, errorsByCartItemId } = validation;
  const { byId } = useNotification() as {
    byId: Record<string, { title: string }>;
  };

  useEffect(() => {
    if (errors.length) {
      const errorCode = errors[0].code; // ALWAYS SHOW FIRST ERROR
      const { title, message, onDismissPrompt } =
        mapOrderErrorToMessage(errorCode);
      const alreadyExists = Object.values(byId).some((n) => n.title === title);

      if (!alreadyExists) {
        notificationsApi.error({
          title,
          message,
          onDismiss: () => {
            if (onDismissPrompt) {
              dispatch(
                announce({
                  text: onDismissPrompt,
                }),
              );
            }
          },
        });
      }
    }

    setChecklist(ORDER_CHECKLIST.CART_ITEMS_ARE_VALID, isValid && !isCartEmpty);
  }, [errors, isValid]);

  return (
    <ValidationContext.Provider
      value={{
        isValid,
        errors,
        errorsByCartItemId,
      }}
    >
      {props.children}
    </ValidationContext.Provider>
  );
}

export function useValidation() {
  const context = React.useContext(ValidationContext);
  if (!context) {
    throw new Error('useValidation must be used within a ValidationProvider');
  }

  return context;
}

type ValidationContextValues = {
  isValid: boolean;
  errors: ValidationError[];
  errorsByCartItemId: Record<string, ValidationError[]>;
};
