import { DeviceState } from '@modules/device/device-types';
import { useSelector } from 'react-redux';

interface State {
  device: DeviceState;
}

export const useDevice = () => {
  const errors = useSelector(({ device }: State) => device.errors);
  const pending = useSelector(({ device }: State) => device.pending);
  const preflight = useSelector(({ device }: State) => device.preflight);
  const token = useSelector(({ device }: State) => device.token);
  const ready = useSelector(({ device }: State) => device.registered);
  const connectionAcceptedAt = useSelector(
    ({ device }: State) => device.callAcceptedAt,
  );
  const hasOngoingCall = useSelector(
    ({ device }: State) => device.hasOngoingCall,
  );
  const isMuted = useSelector(({ device }: State) => device.isMuted);
  const announcementId = useSelector(
    ({ device }: State) => device.announcementId,
  );
  const edge = useSelector(({ device }: State) => device.edge);
  const lastEdgeRun = useSelector(({ device }: State) => device.lastEdgeRun);

  const isPending = Object.keys(pending).some(
    (key) => pending[key as keyof typeof pending],
  );
  const hasError = Object.keys(errors).some(
    (key) => errors[key as keyof typeof errors],
  );

  return {
    errors,
    pending,
    preflight,
    token,
    ready,
    isPending,
    hasError,
    connectionAcceptedAt,
    hasOngoingCall,
    isMuted,
    announcementId,
    edge,
    lastEdgeRun,
    isPendingPreflight: lastEdgeRun !== edge,
  };
};
