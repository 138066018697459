import { useEffect, useState, useMemo, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import routes from '../routes';

const ROLES_KEY = 'https://kea.cloud/v2/roles';

const useRoles = () => {
  const [roles, setRoles] = useState(undefined);
  const { user, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(null);

  const userHasOneOf = (comparisonRoles) =>
    (roles ?? []).some((role) => comparisonRoles.includes(role));

  const availableRoutes = useMemo(
    () =>
      Object.keys(routes).filter((route) => userHasOneOf(routes[route].roles)),
    [roles],
  );

  useEffect(() => {
    isMountedRef.current = true;

    if (isMountedRef.current) {
      (async () => {
        if (!user) return;
        const token = await getAccessTokenSilently();
        const decoded = jwtDecode(token);
        setRoles(decoded[ROLES_KEY]);
      })();
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [user]);

  return { roles, availableRoutes, userHasOneOf };
};

export { useRoles };
