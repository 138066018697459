import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea, Button } from '@kea-inc/parrot-ui';
import store, { useAppDispatch } from '@store';
import * as deviceActions from '@modules/device/device-actions';
import { useSpeech, useDevice } from '@hooks';

import { useKeyPress } from '@hooks/useKeyPress';
import * as S from './styles';

const CONTROL_KEY = 'Control';

const events = {
  '/': () => store.dispatch(deviceActions.unmute()),
  '/\\': () => store.dispatch(deviceActions.callBack()),
  '//': () => store.dispatch(deviceActions.forwardToStore()),
};

export const VbxController = React.memo(({ buttonSize }) => {
  const [vbxValue, setVbxValue] = useState('');
  const [previousValue, setPreviousValue] = useState('');
  const controlKey = useKeyPress(CONTROL_KEY);
  const { recognition, transcript, cleanSpeech } = useSpeech();
  const { pending } = useDevice();
  const inputRef = useRef();
  const dispatch = useAppDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    setVbxValue(value);
    setPreviousValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isPredefinedEvent = Object.keys(events).includes(vbxValue);

    if (isPredefinedEvent) {
      events[vbxValue]();
    } else {
      dispatch(
        deviceActions.announce({ text: inputRef.current.value, custom: true }),
      );
    }

    cleanSpeech();
    setPreviousValue('');
    setVbxValue('');
  };

  const handleKeyPressOnTextArea = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    try {
      if (controlKey.isHolding) {
        recognition.start();
      } else {
        inputRef.current.focus();
        recognition.stop();
        setPreviousValue(vbxValue);
        cleanSpeech();
      }
    } catch {
      recognition.stop();
    }

    return () => {
      recognition.stop();
    };
  }, [controlKey.isHolding]);

  useEffect(() => {
    if (transcript !== '') {
      setVbxValue(`${previousValue} ${transcript}`.replace(/\s+/g, ' ').trim());
    }
  }, [transcript]);

  useEffect(() => {
    const hasOtherInputFocused = document.activeElement.tagName === 'INPUT';
    if (hasOtherInputFocused) return;

    if (controlKey.isPressed && !controlKey.isHolding) {
      inputRef.current.focus();
    } else if (controlKey.isPressed && controlKey.isHolding) {
      inputRef.current.blur();
    }
  }, [controlKey]);

  return (
    <S.Container
      buttonDown={buttonSize === 'small' && controlKey.isHolding}
      onSubmit={handleSubmit}
    >
      <TextArea
        maxRows={3}
        message="You are being recorded"
        placeholder="Start Speaking or typing, press enter to send..."
        readOnly={controlKey.isHolding}
        ref={inputRef}
        rows={3}
        value={vbxValue}
        onChange={handleChange}
        status={controlKey.isHolding ? 'warning' : 'none'}
        onKeyPress={handleKeyPressOnTextArea}
      />
      <Button
        type="submit"
        size={buttonSize}
        loading={pending.isAnnouncingToConference ? 1 : 0}
        className="button__Send"
      >
        Send
      </Button>
    </S.Container>
  );
});

VbxController.propTypes = {
  buttonSize: PropTypes.string.isRequired,
};
