import axios from 'axios';
import env from '@beam-australia/react-env';

import {
  addLoggerInterceptor,
  addRetryInterceptor,
  addTokenInterceptor,
} from './interceptors';
import { Client, RequestConfig } from './types';

export const formatBaseUrl = () => {
  const host = env('CORE_SERVICE');
  const hostWithVersion = `${host}${host.endsWith('/') ? '' : '/'}`;
  return hostWithVersion;
};

const baseURL = formatBaseUrl();

export const api = axios.create({
  baseURL,
});

addLoggerInterceptor(api);
addTokenInterceptor(api);
addRetryInterceptor(api);

const removeVersion = (options: RequestConfig) => {
  delete options.version;
  return options;
};

const client: Client = () => ({
  get: (url, options = { version: 'v2' }) =>
    api.get(`/${options.version}${url}`, {
      ...removeVersion(options),
    }),

  patch: (url, payload = {}, options = { version: 'v2' }) =>
    api.patch(`/${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  post: (url, payload = {}, options = { version: 'v2' }) =>
    api.post(`/${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  put: (url, payload = {}, options = { version: 'v2' }) =>
    api.put(`/${options.version}${url}`, payload, {
      ...removeVersion(options),
    }),

  delete: (url, options = { version: 'v2' }) =>
    api.delete(`/${options.version}${url}`, {
      ...removeVersion(options),
    }),
});

export default client();
