import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

export const Calling = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .call-animation {
    background: ${theme.colors.primary};
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: solid 5px rgb(252, 241, 241);
    animation: call 1.5s ease infinite;
    color: ${theme.colors.white};
    font-size: 35px;
    font-weight: bold;
    position: relative;
  }

  .caller-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes call {
    15% {
      box-shadow: 0 0 0 4px ${theme.colors.primary_lightest_2};
    }
    25% {
      box-shadow: 0 0 0 8px ${theme.colors.primary_lightest_2},
        0 0 0 16px rgba(255, 255, 255, 0.3);
    }
    30% {
      box-shadow: 0 0 0 12px ${theme.colors.primary_lightest_2},
        0 0 0 24px rgba(255, 255, 255, 0.3);
    }
  }
`;
