import './datadog';

import { datadogRum } from '@datadog/browser-rum';

import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as agentSelectors from '@modules/agent/selectors';

import { getCurrentVersion } from '@utils';

function createPayload(attributes = {}) {
  const store = taskrouterSelectors.getStore();
  const taskSid = taskrouterSelectors.getTaskSid();
  const agent = agentSelectors.currentAgent();

  return {
    ...attributes,
    order_id: taskrouterSelectors.deprecatedGetOrderId(),
    conference_sid: taskrouterSelectors.deprecatedGetConferenceSid(),
    store_id: store ? store.id : null,
    task_sid: taskSid,
    agent_username: agent ? agent.username : null,
    version: getCurrentVersion(),
  };
}

const rum = {};

rum.setUser = () => {
  const agent = agentSelectors.currentAgent();
  datadogRum.setUser({
    id: agent.id,
    name: agent.username,
    email: agent.email,
  });
};

rum.removeUser = datadogRum.removeUser;

rum.addAction = (actionName, attributes = {}) => {
  datadogRum.addAction(actionName, createPayload(attributes));
};

rum.startReplayRecording = datadogRum.startSessionReplayRecording;
rum.stopReplayRecording = datadogRum.stopSessionReplayRecording;

export default rum;
