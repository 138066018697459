import { currentAgent } from '@modules/agent/selectors';

export const edges = {
  roaming: 'Global (Default)',
  sydney: 'Australia',
  'sao-paulo': 'Brazil',
  dublin: 'Ireland',
  frankfurt: 'Frankfurt',
  tokyo: 'Japan',
  singapore: 'Singapore',
  ashburn: 'US East Coast (Virgina)',
};

export const totalsTranslation = {
  bytesSent: 'Bytes <br/> Sent',
  packetsSent: 'Packets <br/> Sent',
  bytesReceived: 'Bytes <br/> Received',
  packetsReceived: 'Packets <br/> Received',
  packetsLost: 'Packets <br/> Lost',
};

export const stats = {
  jitter: {
    title: 'Jitter',
    description:
      'Packets delay variation on audio tracks. A good average should be < 5.',
  },
  mos: {
    title: 'Mos',
    description:
      'Mean opinion score, 1.0 through roughly 4.5. A good average should be >= 3.5',
  },
  rtt: {
    title: 'RTT',
    description:
      'Round trip time, to the server back to the client. A good average should be < 400.',
  },
};

export function round(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function prettyWarning(name) {
  const words = name.split('-');
  return words.map(capitalizeFirstLetter).join(' ');
}

export function downloadPreflightAsJson(preflight) {
  const agent = currentAgent();
  const preflightWithAgent = {
    ...preflight,
    agent_username: agent.username,
  };

  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(preflightWithAgent),
  )}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `preflight-test.json`);
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
