import { useEffect, useMemo, useState } from 'react';
import { Button, Option, Select } from '@kea-inc/parrot-ui';

import { useAppDispatch } from '@store';

import { LuDumbbell } from 'react-icons/lu';
import {
  createTrainingTask,
  fetchStoresByBrand,
} from '@modules/taskrouter/actions';
import { TaskrouterBrand, TaskrouterStore } from '@modules/taskrouter/types';
import { clockIn } from '@modules/clock/api';
import * as deviceActions from '@modules/device/device-actions';
import { useClock } from '@hooks';
import twilioTrainingDevice from '@services/twilio-training-device';
import { Call, Device } from '@twilio/voice-sdk';
import twilioDevice from '@services/twilio-device';
import { useStore } from './useStore';
import * as S from './styles';

export function TrainingModeCard() {
  const dispatch = useAppDispatch();
  const [brandId, setBrandId] = useState('');
  const [storeId, setStoreId] = useState('');
  const [isSettingUpTrainingDevice, setIsSettingUpTrainingDevice] =
    useState(false);

  const { brands, storesByBrand } = useStore();
  const { isClockingIn } = useClock().pending;

  const handleChangeBrand = (brand: string) => {
    if (!brand) {
      return;
    }

    setBrandId(brand);
    dispatch(fetchStoresByBrand(brand));
  };

  const setupTrainingDevice = async () => {
    const token = await dispatch(deviceActions.fetchTrainerToken()).unwrap();
    twilioTrainingDevice.setToken(token);
    twilioTrainingDevice.setup('roaming');
    twilioTrainingDevice.register();
    twilioTrainingDevice.setDeviceEvent(
      Device.EventName.Incoming,
      (call: Call) => {
        twilioTrainingDevice.setCall(call);
        call.accept();
        call.mute(true);
        call.once('disconnect', () => {
          twilioTrainingDevice.device?.calls.forEach((connectedCall: Call) =>
            connectedCall.disconnect(),
          );

          twilioDevice.device?.calls.forEach((connectedCall: Call) =>
            connectedCall.disconnect(),
          );

          twilioTrainingDevice.unregister();
          twilioTrainingDevice.reset();
        });
      },
    );
  };

  const clearAnyPendingTrainingCall = async () => {
    twilioTrainingDevice.device?.calls.forEach((connectedCall: Call) =>
      connectedCall.disconnect(),
    );
    twilioTrainingDevice.unregister();
    twilioTrainingDevice.reset();
  };

  const handleSubmit = async () => {
    setIsSettingUpTrainingDevice(true);
    await setupTrainingDevice();
    setIsSettingUpTrainingDevice(false);
    clockIn().then(() => dispatch(createTrainingTask({ brandId, storeId })));
  };

  const trainedBrands = useMemo(
    () => brands?.filter((brand: TaskrouterBrand) => brand.trained),
    [brands],
  );

  const storesWithNumber = useMemo(
    () => storesByBrand?.filter((store) => !!store.twilioNumber),
    [storesByBrand],
  );

  useEffect(() => {
    clearAnyPendingTrainingCall();
  }, []);

  return (
    <S.TrainingContainer>
      <S.TrainingSelects>
        <Select
          label="Training Mode Brand"
          placeholder="Select a brand"
          onChange={(value) => handleChangeBrand(value as string)}
          required
          // @ts-expect-error - Missing type in Parrot, but it's a valid prop
          className="training__Select"
        >
          {trainedBrands?.map((brand: TaskrouterBrand) => (
            <Option value={brand.id} key={brand.key}>
              {brand.name}
            </Option>
          ))}
        </Select>

        <Select
          value={storeId}
          label="Store"
          placeholder="Select a store"
          onChange={(value) => setStoreId(value as string)}
          required
          // @ts-expect-error - Missing type in Parrot, but it's a valid prop
          className="training__Select"
          disabled={!brandId}
        >
          {storesWithNumber?.map((storeObj: TaskrouterStore) => (
            <Option value={storeObj.id} key={storeObj.id}>
              {storeObj.area} ({storeObj.primaryPhone})
            </Option>
          ))}
        </Select>
      </S.TrainingSelects>
      <S.TrainingButtons>
        <Button
          LeftIcon={LuDumbbell}
          onClick={() => handleSubmit()}
          loading={isClockingIn || isSettingUpTrainingDevice}
          disabled={!brandId || !storeId}
          data-testid="training-mode-button"
        >
          Training Mode
        </Button>
      </S.TrainingButtons>
    </S.TrainingContainer>
  );
}
