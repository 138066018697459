import styled from 'styled-components';
import { IoShieldCheckmark } from 'react-icons/io5';
import { theme } from '@kea-inc/parrot-ui';

import * as GlobalStyles from '@styles/loading';

export const Container = styled.section<{ isSubmittingOrder: boolean }>`
  height: 460px;
  width: 350px;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.isSubmittingOrder ? 'flex-start' : 'space-between'};
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const Content = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: -21px;

  .title {
    margin-top: 13px;
  }
`;

export const UnmuteBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin: 0;
  }
`;

export const Success = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SuccessIcon = styled(IoShieldCheckmark).attrs({
  size: 48,
})`
  color: ${theme.colors.success};
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${theme.colors.gray_light};
  padding-top: 16px;

  button {
    margin: 0;
  }
`;

export const { Loading, LoadingContainer } = GlobalStyles;
