import { useEffect, useMemo, useRef } from 'react';

import { setChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';
import { resetDelivery, setAddressInfo } from '@modules/delivery/slice';
import {
  ValidateAddressBody,
  fetchAddressById,
  searchAddress,
  validateAddress,
} from '@modules/delivery/actions';
import store, { useAppDispatch, useAppSelector } from '@store';
import * as notifications from '@modules/notifications/api';

const requiredDeliveryFields = [
  'zipcode',
  'streetName',
  'streetNumber',
  'city',
  'state',
] as const;

export function useDelivery() {
  const { delivery, order, account } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const canSearchAddress =
    delivery.addressSearch.length >= 5 &&
    delivery.addressSearch !== delivery.formattedAddress;
  const lastValidatedAddress = useRef<null | typeof delivery>(null);

  const handleAddressSearch = () => {
    if (!canSearchAddress) {
      return;
    }

    dispatch(searchAddress(delivery.addressSearch));
  };

  const handleAddressValidation = () => {
    if (!areDeliveryDetailsFilled) {
      notifications.info({
        title: 'Missing information',
        message: 'Please fill out all required fields',
      });
      return;
    }

    if (!delivery.isAddressFetched && !delivery.hasFetchingError) {
      return;
    }

    const data: ValidateAddressBody = {
      order_id: order.id,
      full_street_address: `${delivery.streetNumber} ${delivery.streetName}`,
      city: delivery.city,
      zip_code: delivery.zipcode,
    };

    if (delivery.addressLine2.length) {
      data.building = delivery.addressLine2;
    }

    if (delivery.deliveryInstructions.length) {
      data.instructions = delivery.deliveryInstructions;
    }

    if (delivery.state.length) {
      data.state = delivery.state;
    }

    if (delivery.country.length) {
      data.country = delivery.country;
    }

    dispatch(validateAddress(data));
  };

  const handleFetchAddressById = () => {
    if (account.accountDetails.deliveryAddressId) {
      dispatch(fetchAddressById(account.accountDetails.deliveryAddressId));
    }
  };

  const areDeliveryDetailsFilled = useMemo(
    () => requiredDeliveryFields.every((field) => !!delivery[field]),
    [
      delivery.zipcode,
      delivery.streetName,
      delivery.streetNumber,
      delivery.city,
      delivery.state,
    ],
  );

  useEffect(() => {
    if (lastValidatedAddress.current) {
      if (
        !compareAddresses(
          getAddressFields(lastValidatedAddress.current),
          getAddressFields(delivery),
        )
      ) {
        dispatch(
          setAddressInfo({ isAddressValidated: false, canDeliver: false }),
        );
        setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS, false);
      } else {
        dispatch(
          setAddressInfo({
            isAddressValidated:
              lastValidatedAddress.current?.isAddressValidated || false,
            canDeliver: lastValidatedAddress.current?.canDeliver || false,
          }),
        );
        setChecklist(ORDER_CHECKLIST.HAS_CONFIRMED_ADDRESS, true);
      }
    }
  }, [
    delivery.zipcode,
    delivery.streetName,
    delivery.streetNumber,
    delivery.city,
    delivery.state,
    delivery.addressLine2,
    delivery.deliveryInstructions,
  ]);

  useEffect(() => {
    if (delivery.id.length) {
      lastValidatedAddress.current = delivery;
    }
  }, [delivery.id]);

  return {
    resetDelivery: () => dispatch(resetDelivery()),
    handleAddressSearch,
    handleAddressValidation,
    handleFetchAddressById,
    delivery,
    canSearchAddress,
    areDeliveryDetailsFilled,
    ...delivery,
  };
}

function getAddressFields(
  state: ReturnType<typeof store.getState>['delivery'] | null,
) {
  if (!state) {
    return null;
  }

  return {
    zipcode: state.zipcode,
    streetName: state.streetName,
    streetNumber: state.streetNumber,
    city: state.city,
    state: state.state,
    addressLine2: state.addressLine2,
    deliveryInstructions: state.deliveryInstructions,
  };
}

function compareAddresses(
  a: ReturnType<typeof getAddressFields> | null,
  b: ReturnType<typeof getAddressFields>,
) {
  if (!a || !b) {
    return false;
  }

  return Object.keys(a).every(
    (key) =>
      a[key as keyof typeof a]?.trim() === b[key as keyof typeof b]?.trim(),
  );
}
