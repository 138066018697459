import React, { useEffect } from 'react';

import { InlineRadioOptionButton } from '@components/InlineRadioOptionButton';
import { Modifier } from '@modules/menu/types';
import { useAppDispatch, useAppSelector } from '@store';
import { selectOptionsByParent } from '@modules/menu/selectors';
import { addOption, switchOption } from '@modules/menu/actions';
import { getStoreFeatureFlags } from '@modules/featureFlags/selectors';
import * as S from './styles';

type InlineRadioOptionsSelectorProps = {
  modifier: Modifier;
};

export function InlineRadioOptionsSelector({
  modifier,
}: InlineRadioOptionsSelectorProps) {
  const dispatch = useAppDispatch();

  const { useHalfAddedOption } = useAppSelector(getStoreFeatureFlags);

  const options = useAppSelector((state) =>
    selectOptionsByParent(state.menu.options)(modifier.id),
  );

  const addedOptions = useAppSelector((state) => state.menu.options.added);

  const handleClick = (optionId: string, event: React.MouseEvent) => {
    event.stopPropagation();

    onChange(optionId);
  };

  const onChange = (optionId: string) => {
    const isAdded = Object.values(addedOptions).find(
      (addedOption) => addedOption.parentId === modifier.id,
    );

    if (isAdded) {
      dispatch(switchOption({ optionId, useHalfAddedOption }));
      return;
    }

    dispatch(addOption({ optionId, useHalfAddedOption }));
  };

  useEffect(() => {
    const isAdded = Object.values(addedOptions).find(
      (addedOption) => addedOption.parentId === modifier.id,
    );

    if (isAdded) {
      return;
    }

    const defaultOption = options.find((option) => option?.isDefault);

    if (defaultOption) {
      onChange(defaultOption.id);
    } else {
      const firstOption = options?.[0];

      if (firstOption) {
        onChange(firstOption.id);
      }
    }
  }, [options]);

  const buttons = options.map((option) => {
    const selected = addedOptions[option.id] !== undefined;

    return (
      <InlineRadioOptionButton
        optionName={option.name}
        selected={selected}
        onClick={(event: React.MouseEvent) => handleClick(option.id, event)}
        key={option.id}
      />
    );
  });

  const buttonRows = [];
  const rowSize = 3;

  for (let i = 0; i < buttons.length; i += rowSize) {
    buttonRows.push(buttons.slice(i, i + rowSize));
  }

  return (
    <S.InlineRadioOptionsSelector>
      <S.ButtonGrid>
        {buttonRows.map((row) => (
          <S.ButtonRow key={row.join('')}>{row}</S.ButtonRow>
        ))}
      </S.ButtonGrid>
    </S.InlineRadioOptionsSelector>
  );
}
