import { useEffect } from 'react';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import * as notifications from '@modules/notifications/api';
import { WorkerStatus as StatusType } from '@modules/taskrouter/types';
import { useInterval } from '@hooks';
import { useAppDispatch } from '@store';
import env from '@beam-australia/react-env';
import { AllColors } from '@kea-inc/parrot-ui/dist/types';
import { IconType } from 'react-icons';
import { Chip, Loading } from './styles';
import { useConnector } from './useConnector';

export function WorkerStatus() {
  const { selectors, actions } = useConnector();
  const { isClockingIn, isClockingOut, workerStatus } = selectors;
  const { getWorkerStatus, resetTaskrouter } = actions;
  const dispatch = useAppDispatch();

  const statusColor: Partial<
    Record<
      StatusType,
      {
        color: AllColors;
        icon: IconType;
      }
    >
  > = {
    Offline: {
      color: 'error',
      icon: FiThumbsDown,
    },
    Idle: {
      color: 'success',
      icon: FiThumbsUp,
    },
    Pending: {
      color: 'warning',
      icon: FiThumbsDown,
    },
    Busy: {
      color: 'error',
      icon: FiThumbsDown,
    },
    Error: {
      color: 'error',
      icon: FiThumbsDown,
    },
  };

  const workerStatusInterval = parseInt(
    env('WORKER_STATUS_INTERVAL') || '5000',
    10,
  );

  useInterval(() => {
    if (!isClockingIn && !isClockingOut) {
      dispatch(getWorkerStatus());
    }
  }, workerStatusInterval);

  useEffect(() => {
    if (workerStatus && workerStatus === 'Offline') {
      notifications.error({
        title: 'You are offline',
        message: 'Please clock in again.',
        onDismiss: () => {
          dispatch(resetTaskrouter({ shouldCompleteTask: false }));
        },
      });
    }
  }, [workerStatus]);

  return workerStatus ? (
    <Chip
      color={statusColor[workerStatus]?.color}
      rightIcon={statusColor[workerStatus]?.icon}
      selected
    >
      Status: {workerStatus}
    </Chip>
  ) : (
    <Chip color="gray" rightIcon={Loading} selected>
      Status: {workerStatus}
    </Chip>
  );
}
