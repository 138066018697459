import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import * as GlobalStyles from '@styles/loading';

export const Container = styled.div`
  height: 301px;
  width: 354px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  .reassign__Notice,
  .human__Notice,
  .human__Required {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .human__Notice {
    margin-top: 8px;

    svg {
      color: ${theme.colors.primary_darker};
      font-size: 16px;
      margin-left: 4px;
      transform: translateY(15%);
    }
  }
`;

export const Icon = styled.div`
  height: 116px;
  width: 116px;
  background: ${theme.colors.error};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${theme.colors.white};
  }
`;

export const { Loading, LoadingContainer } = GlobalStyles;
