import { useState } from 'react';

import * as S from './styles';

export function HoverController({
  text,
  hasOptionInlineRadioSelector,
}: {
  text: string;
  hasOptionInlineRadioSelector?: boolean;
}) {
  const [isHovering, setIsHovering] = useState(false);
  const [justClicked, setJustClicked] = useState(false);

  const onMouseEnter = () => {
    setIsHovering(true);
    setJustClicked(false);
  };

  const onMouseLeave = () => {
    setIsHovering(false);
    setJustClicked(false);
  };

  const shouldAppearHoverState = !justClicked && isHovering;

  return (
    <S.HoverController
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => setJustClicked(true)}
      isAdded={text !== 'ADD'}
      isHovering={shouldAppearHoverState}
      data-testid="hover-controler"
    >
      {shouldAppearHoverState && (
        <S.HoverText
          hasOptionInlineRadioSelector={hasOptionInlineRadioSelector ?? false}
        >
          {text}
        </S.HoverText>
      )}
    </S.HoverController>
  );
}
