import React, { useMemo, useEffect, MouseEventHandler } from 'react';
import { v4 as uuid } from 'uuid';
import { Badge } from '@kea-inc/parrot-ui';
import { DefaultColors } from '@kea-inc/parrot-ui/dist/types';

import { IBadgeProps } from '@kea-inc/parrot-ui/dist/components/Badge/types';
import { useDevice, useKeyPress } from '@hooks';
import { announce } from '@modules/device/device-actions';
import { useAppDispatch } from '@store';

export type AnnounceBadgeProps = {
  message: string;
  children: React.ReactNode;
  variant?: DefaultColors;
  onClick?: () => void;
  shortcut?: string;
  disabled?: boolean;
} & IBadgeProps;

const AnnounceBadge = ({
  message,
  children,
  variant = 'primary',
  onClick,
  shortcut = undefined,
  disabled = false,
  ...props
}: AnnounceBadgeProps) => {
  const dispatch = useAppDispatch();
  const { isPressed } = useKeyPress(shortcut, { withShift: true });
  const { announcementId } = useDevice();
  const id = useMemo(() => uuid(), []);

  const handleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e?.stopPropagation();
    dispatch(announce({ text: message, id }));
    onClick?.();
  };

  const handlePress = () => {
    dispatch(announce({ text: message, id }));
    onClick?.();
  };

  useEffect(() => {
    if (isPressed && !disabled) handlePress();
  }, [isPressed]);

  const isAnnouncing = announcementId === id;

  return (
    <Badge
      onClick={isAnnouncing ? undefined : handleClick}
      variant={isAnnouncing ? 'gray' : variant}
      disabled={disabled}
      {...props}
    >
      {children}
    </Badge>
  );
};

export default React.memo(AnnounceBadge);
