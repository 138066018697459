import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PreflightTest } from '@twilio/voice-sdk';

import { device as twilioDevice } from '@services';

import { initialDeviceState } from './device-state';
import {
  fetchToken,
  mute,
  unmute,
  callBack,
  forwardToStore,
  hangup,
  announce,
  checkMicrophone,
  startDevice,
} from './device-actions';

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialDeviceState,
  reducers: {
    resetDevice: (state) => ({
      ...initialDeviceState,
      registered: twilioDevice.device?.state === 'registered',
      preflight: state.preflight,
      token: state.token,
      edge: state.edge,
      lastEdgeRun: state.lastEdgeRun,
    }),
    setPreflightRunning: (state) => {
      state.pending.isPreflightRunning = true;
      state.errors.hasPreflightError = false;
    },
    onPreflightSuccess: (
      state,
      action: PayloadAction<{ report: PreflightTest.Report }>,
    ) => {
      state.pending.isPreflightRunning = false;
      state.errors.hasPreflightError = false;
      state.preflight = action.payload.report;

      twilioDevice.preflightStarted = false;
    },
    onPreflightError: (state) => {
      state.pending.isPreflightRunning = false;
      state.errors.hasPreflightError = true;

      twilioDevice.preflightStarted = false;
    },
    onDeviceIncoming: (state) => {
      state.pending.isAcceptingCall = true;
    },
    onDeviceConnected: (state) => {
      state.pending.isAcceptingCall = false;
      state.callAcceptedAt = new Date();
      state.hasOngoingCall = true;
      state.isMuted = true;
    },
    onDeviceError: (state) => {
      state.pending.isStartingDevice = false;
      state.errors.hasDeviceStartError = true;
      state.registered = twilioDevice.device?.state === 'registered';
    },
    onDeviceUnregistered: (state) => {
      state.pending.isStartingDevice = false;
      state.errors.hasDeviceStartError = false;
      state.registered = twilioDevice.device?.state === 'registered';
    },
    onCallDisconnect: (state) => {
      state.pending.isDisconnecting = false;
      state.isMuted = false;
      state.hasForwarded = false;
      state.callAcceptedAt = null;
      state.hasOngoingCall = false;
      state.callbackCallSid = null;
    },
    onDeviceRegistered: (state) => {
      state.pending.isStartingDevice = false;
      state.errors.hasDeviceStartError = false;
      state.registered = twilioDevice.device?.state === 'registered';
    },
    setDeviceEdge: (state, action: PayloadAction<{ edge: string }>) => {
      state.edge = action.payload.edge;
    },
    setLastEdgeRun: (state, action: PayloadAction<{ edge: string }>) => {
      state.lastEdgeRun = action.payload.edge;
      const LOCAL_STORAGE_KEY = '@kea-inc/uoa/edge';
      localStorage.setItem(LOCAL_STORAGE_KEY, action.payload.edge);
    },
    setHangingUp: (state, action: PayloadAction<boolean>) => {
      state.pending.isHangingUp = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startDevice.pending, (state) => {
      state.pending.isStartingDevice = true;
      state.errors.hasDeviceStartError = false;
      state.registered = twilioDevice.device?.state === 'registered';
    });
    builder.addCase(startDevice.fulfilled, (state) => {
      state.pending.isStartingDevice = false;
      state.errors.hasDeviceStartError = false;
      state.registered = twilioDevice.device?.state === 'registered';
    });
    builder.addCase(startDevice.rejected, (state) => {
      state.pending.isStartingDevice = false;
      state.errors.hasDeviceStartError = true;
      state.registered = twilioDevice.device?.state === 'registered';
    });

    builder.addCase(checkMicrophone.pending, (state) => {
      state.pending.isCheckingMicrophone = true;
      state.errors.hasMicrophoneError = false;
    });
    builder.addCase(checkMicrophone.fulfilled, (state) => {
      state.pending.isCheckingMicrophone = false;
      state.errors.hasMicrophoneError = false;
      state.preflight = null;
      state.registered = twilioDevice.device?.state === 'registered';
    });
    builder.addCase(checkMicrophone.rejected, (state) => {
      state.pending.isCheckingMicrophone = false;
      state.errors.hasMicrophoneError = true;
    });

    builder.addCase(fetchToken.pending, (state) => {
      state.pending.isFetchingToken = true;
      state.errors.hasFetchTokenError = false;
    });
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.pending.isFetchingToken = false;
      state.errors.hasFetchTokenError = false;

      if (action.payload) {
        state.token = action.payload;
      }
    });
    builder.addCase(fetchToken.rejected, (state) => {
      state.pending.isFetchingToken = false;
      state.errors.hasFetchTokenError = true;
    });

    builder.addCase(mute.pending, (state) => {
      state.pending.isMuting = true;
      state.errors.hasMuteUnmuteError = false;
    });
    builder.addCase(mute.fulfilled, (state) => {
      state.isMuted = true;
      state.pending.isMuting = false;
      state.errors.hasMuteUnmuteError = false;
    });
    builder.addCase(mute.rejected, (state) => {
      state.pending.isMuting = false;
      state.errors.hasMuteUnmuteError = true;
    });

    builder.addCase(unmute.pending, (state) => {
      state.pending.isMuting = true;
      state.errors.hasMuteUnmuteError = false;
    });
    builder.addCase(unmute.fulfilled, (state) => {
      state.isMuted = false;
      state.pending.isMuting = false;
      state.errors.hasMuteUnmuteError = false;
    });
    builder.addCase(unmute.rejected, (state) => {
      state.pending.isMuting = false;
      state.errors.hasMuteUnmuteError = true;
    });

    builder.addCase(callBack.pending, (state) => {
      state.pending.isCallingBack = true;
      state.errors.hasCallBackError = false;
    });
    builder.addCase(callBack.fulfilled, (state, action) => {
      state.pending.isCallingBack = false;
      state.errors.hasCallBackError = false;

      if (action.payload) {
        state.callbackCallSid = action.payload.callSid;
      }
    });
    builder.addCase(callBack.rejected, (state) => {
      state.pending.isCallingBack = false;
      state.errors.hasCallBackError = true;
    });

    builder.addCase(forwardToStore.pending, (state) => {
      state.pending.isForwardingStore = true;
      state.errors.hasForwardStoreError = false;
    });
    builder.addCase(forwardToStore.fulfilled, (state) => {
      state.pending.isForwardingStore = false;
      state.errors.hasForwardStoreError = false;
      state.hasForwarded = true;
    });
    builder.addCase(forwardToStore.rejected, (state) => {
      state.pending.isForwardingStore = false;
      state.errors.hasForwardStoreError = true;
    });

    builder.addCase(hangup.pending, (state) => {
      state.pending.isHangingUp = true;
      state.errors.hasHungUpError = false;
      state.hasTriedHangup = true;
    });
    builder.addCase(hangup.fulfilled, (state) => {
      state.pending.isHangingUp = false;
      state.errors.hasHungUpError = false;
    });
    builder.addCase(hangup.rejected, (state) => {
      state.pending.isHangingUp = false;
      state.errors.hasHungUpError = true;
    });

    builder.addCase(announce.pending, (state, action) => {
      state.pending.isAnnouncingToConference = true;
      state.errors.hasAnnounceToConferenceError = false;

      const { text, id } = action.meta.arg;
      state.lastAnnouncement = text;
      state.announcementId = id || null;
    });
    builder.addCase(announce.fulfilled, (state) => {
      state.pending.isAnnouncingToConference = false;
      state.errors.hasAnnounceToConferenceError = false;
      state.announcementId = null;
    });
    builder.addCase(announce.rejected, (state) => {
      state.pending.isAnnouncingToConference = false;
      state.errors.hasAnnounceToConferenceError = true;
      state.announcementId = null;
    });
  },
});

export const {
  resetDevice,
  setDeviceEdge,
  setLastEdgeRun,
  setPreflightRunning,
  onPreflightSuccess,
  onPreflightError,
  onDeviceRegistered,
  onDeviceUnregistered,
  onDeviceError,
  onDeviceIncoming,
  onCallDisconnect,
} = deviceSlice.actions;

export const { actions } = deviceSlice;

export default deviceSlice.reducer;
