import React, { useMemo } from 'react';

import * as clockAPI from '@modules/clock/api';

import * as S from './styles';
import { useConnector } from './useConnector';

const ClockButton = () => {
  const { selectors } = useConnector();
  const {
    isClockingIn,
    isClockingOut,
    isClockedIn,
    hasOngoingTask,
    deviceIsPending,
    deviceHasError,
    isPendingPreflight,
    isDeviceRegistered,
    reservationSid,
  } = selectors;

  const loading: boolean = isClockingIn || isClockingOut;

  const disabled = useMemo(
    () =>
      isClockedIn
        ? hasOngoingTask
        : deviceIsPending || deviceHasError || isPendingPreflight,
    [
      isClockedIn,
      hasOngoingTask,
      deviceIsPending,
      deviceHasError,
      !isDeviceRegistered,
      isPendingPreflight,
    ],
  );

  return (
    <S.Button
      size="small"
      onClick={isClockedIn ? clockAPI.clockOut : clockAPI.clockIn}
      disabled={disabled || reservationSid !== null}
      loading={loading}
      className="clock_button"
    >
      {isClockedIn ? 'Clock out' : 'Clock in'}
    </S.Button>
  );
};

export default React.memo(ClockButton);
