import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Tabs, Tab, Dropdown } from '@kea-inc/parrot-ui';
import { setVoicePaneContext } from '@modules/voicePane/api';

import {
  IoMenuOutline,
  IoChevronBackOutline,
  IoChevronForwardOutline,
} from 'react-icons/io5';
import { useVoicePane } from '@hooks';
import * as S from './styles';

const toPascalCase = (word) => word.charAt(0).toUpperCase() + word.slice(1);

const ScrollableTabs = () => {
  const { prompts } = useVoicePane();
  const [visibleTab, setVisibleTab] = useState('all');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const scrollRef = useRef(null);

  const tabs = useMemo(() => {
    if (prompts.byContext) {
      const values = [{ value: 'all', content: 'All' }];
      const contexts = Object.keys(prompts?.byContext);
      contexts.forEach((context) => {
        values.push({ value: context, content: toPascalCase(context) });
      });

      return values;
    }
  }, [prompts]);

  const onTabSelection = (value) => {
    setIsDropdownOpen(false);
    setVisibleTab(value);
  };

  const scroll = (direction) => {
    const tabsWrapper = document.querySelector('.wrapper__tabs');

    if (direction === 'left') {
      tabsWrapper.scrollLeft -= 200;
    } else {
      tabsWrapper.scrollLeft += 200;
    }
  };

  useEffect(() => {
    const tabValues = (tabs || []).reduce((result, currentTab) => {
      if (currentTab.value !== 'all') {
        result.push(currentTab.value);
      }
      return result;
    }, []);

    setVoicePaneContext(visibleTab === 'all' ? tabValues : [visibleTab]);
  }, [visibleTab]);

  return (
    <S.Container>
      <IoMenuOutline
        onClick={() => setIsDropdownOpen((prevState) => !prevState)}
      />

      <div className="wrapper" ref={scrollRef}>
        <IoChevronBackOutline onClick={() => scroll('left')} />

        <Tabs
          className="wrapper__tabs"
          orientation="horizontal"
          variant="pill"
          tabs={tabs}
        >
          {tabs &&
            tabs.map((tab) => (
              <Tab
                key={tab.value}
                active={visibleTab === tab.value}
                onClick={() => onTabSelection(tab.value)}
              >
                {tab.content}
              </Tab>
            ))}
        </Tabs>

        <IoChevronForwardOutline onClick={() => scroll('right')} />
      </div>

      <Dropdown.Menu
        anchor={scrollRef}
        position="bottomRight"
        width={120}
        open={isDropdownOpen}
        onClose={() => setIsDropdownOpen(false)}
      >
        {tabs &&
          tabs.map((tab) => (
            <Dropdown.Atoms.Text
              key={tab.value}
              onClick={() => onTabSelection(tab.value)}
            >
              {tab.content}
            </Dropdown.Atoms.Text>
          ))}
      </Dropdown.Menu>
    </S.Container>
  );
};

export default React.memo(ScrollableTabs);
