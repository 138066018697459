import { useCallback, useEffect, useRef, useState } from 'react';
import env from '@beam-australia/react-env';
import { Typography } from '@kea-inc/parrot-ui';
import { MdEmojiPeople } from 'react-icons/md';
import { FaMicrophone } from 'react-icons/fa';

import { CenterOverlay } from '@components';
import { handleReassignTask } from '@utils/handleReassignTask';
import { startForNewOrder } from '@modules/survey/api';
import { useAppDispatch } from '@store';
import { useInterval } from '@hooks';

import * as S from './styles';
import { useConnector } from './useConnector';

const DEFAULT_TTFA_MAX = 8;

const safeGetTtfaMax = () => {
  const ttfaMax = env('TTFA_MAX');
  const parsed = parseInt(ttfaMax, 10);
  if (Number.isInteger(parsed)) {
    return parsed;
  }
  return DEFAULT_TTFA_MAX;
};

export default function HumanRequestOverlay() {
  const [duration, setDuration] = useState(safeGetTtfaMax());
  const [timeToFirstAction, setTimeToFirstAction] = useState(Date.now());
  const { selectors, actions } = useConnector();
  const { agentTookAction, hasOngoingTask, conferenceSid, isReassigningTask } =
    selectors;
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const handleOnFinish = useCallback(async () => {
    handleReassignTask(true);
  }, []);

  useEffect(() => {
    if (!agentTookAction && hasOngoingTask) {
      setTimeToFirstAction(Date.now());
      containerRef?.current?.focus();
    }
  }, [agentTookAction, hasOngoingTask]);

  const handleTakeAction = useCallback(async () => {
    if (!conferenceSid) {
      return;
    }

    dispatch(actions.takeAction(Date.now() - timeToFirstAction));
    startForNewOrder();
    dispatch(actions.unmute());
  }, [timeToFirstAction, conferenceSid]);

  useInterval(() => {
    if (!agentTookAction && hasOngoingTask) {
      if (duration === 0) {
        handleOnFinish();
      }

      if (duration > 0) {
        setDuration((prev) => prev - 1);
      }
    }
  }, 1000);

  return !agentTookAction && hasOngoingTask ? (
    <CenterOverlay onClick={handleTakeAction}>
      <S.Container
        onClick={handleTakeAction}
        onKeyPress={handleTakeAction}
        ref={containerRef}
      >
        {!isReassigningTask && conferenceSid ? (
          <>
            <div className="human__Required">
              <S.Icon>
                <MdEmojiPeople size={70} />
              </S.Icon>

              <div className="human__Notice">
                <Typography weight="heavy" variant="subheader">
                  Human assistance required
                </Typography>
                <Typography>
                  Get ready to speak
                  <FaMicrophone />
                </Typography>
              </div>
            </div>

            <div className="reassign__Notice">
              <Typography variant="title" weight="heavy" color="primary">
                Click anywhere to unmute
              </Typography>
              <Typography>
                Reassigning if no action taken in {duration}s...
              </Typography>
            </div>
          </>
        ) : (
          <S.LoadingContainer>
            <S.Loading />
          </S.LoadingContainer>
        )}
      </S.Container>
    </CenterOverlay>
  ) : (
    <></>
  );
}
