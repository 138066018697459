type ModifierConfig = {
  minOptions: number | null;
  maxOptions: number | null;
  minQuantity: number | null;
  maxQuantity: number | null;
};

export function createMinimumSelectionMessage(modifier: ModifierConfig) {
  if (modifier.minOptions && modifier.maxOptions) {
    if (modifier.minOptions === modifier.maxOptions) {
      return `Select ${modifier.minOptions}`;
    }

    return `Select between ${modifier.minOptions} and ${modifier.maxOptions}`;
  }

  if (modifier.minOptions) {
    return `Select at least ${modifier.minOptions}`;
  }

  if (modifier.maxOptions) {
    return `Select up to ${modifier.maxOptions}`;
  }

  if (modifier.minQuantity) {
    return `Select a total of ${modifier.minQuantity}`;
  }

  return '';
}
