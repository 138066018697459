import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const CenterOverlay = ({ children, onClick }) => (
  <>
    <S.Background />
    <S.Container onClick={onClick}>
      <S.Content className="center__overlay">{children}</S.Content>
    </S.Container>
  </>
);

CenterOverlay.defaultProps = {
  onClick: () => {},
};

CenterOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
};

export default CenterOverlay;
