import { useEffect, useMemo } from 'react';

import { useDelivery } from '@hooks';

import { makeDeliveryPhrases } from '@utils/makeDeliveryPhrases';
import { setChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import AnnounceBadge, { AnnounceBadgeProps } from '../AnnounceBadge';

type DeliveryInstructionsBadgeProps = Omit<
  AnnounceBadgeProps,
  'message' | 'onClick' | 'children' | 'disabled'
>;

export function DeliveryInstructionsBadge(
  props: DeliveryInstructionsBadgeProps,
) {
  const { deliveryInstructions, delivery } = useDelivery();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const { deliveryInstructions: message } = useMemo(
    () => makeDeliveryPhrases(delivery),
    [deliveryInstructions],
  );

  const handleOnClick = () => {
    setChecklist(ORDER_CHECKLIST.HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS, true);
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message },
    });
  };

  useEffect(() => {
    if (deliveryInstructions.length > 0 || delivery.isAddressValidated) {
      setChecklist(ORDER_CHECKLIST.HAS_ASKED_FOR_DELIVERY_INSTRUCTIONS, true);
    }
  }, [deliveryInstructions]);

  return (
    <AnnounceBadge
      onClick={handleOnClick}
      disabled={false}
      message={message}
      {...props}
    >
      Delivery Instructions
    </AnnounceBadge>
  );
}
