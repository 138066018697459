import React from 'react';

import { useOrder } from '@hooks';

import { AnnounceBadge } from '@components';
import { setChecklist } from '@modules/order/api';
import { ORDER_CHECKLIST } from '@modules/order/state';

import { useButtonAnalyticsTracker } from '@hooks/useButtonAnalyticsTracker';
import { EVENT_NAME_BUTTONS } from '@utils/buttonsAnalyticsIds';
import { ChecklistItem } from './ChecklistItem';

export const HasAskedForTip = () => {
  const { tipPercentage, handOffMode } = useOrder();

  const { handleAnalyticsTrackButton } = useButtonAnalyticsTracker();

  const tipPercentageValue = tipPercentage ?? 15;

  let tipMessage = `Would you like to give a ${tipPercentageValue}% tip to our staff?`;

  if (handOffMode?.toLowerCase() === 'delivery') {
    tipMessage = `Would you like to give a ${tipPercentageValue}% tip to the driver?`;
  }

  const handleTipClick = () => {
    setChecklist(ORDER_CHECKLIST.HAS_ASKED_FOR_TIP, true);
    handleAnalyticsTrackButton({
      eventName: EVENT_NAME_BUTTONS.GENERAL,
      metadata: { message: tipMessage },
    });
  };

  return (
    <ChecklistItem field={ORDER_CHECKLIST.HAS_ASKED_FOR_TIP}>
      <AnnounceBadge
        message={tipMessage}
        onClick={handleTipClick}
        style={{ transform: 'translateY(2px)' }}
      >
        Tip?
      </AnnounceBadge>
    </ChecklistItem>
  );
};
