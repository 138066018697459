import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as orderSelectors from '@modules/order/selectors';

const notAbleToPayOnArrivalHandoffModes = ['delivery', 'dispatch'];

export const getCashLimitValues = () => {
  const brandCashLimit = taskrouterSelectors.deprecatedGetBrand()?.cashLimit;
  const storeCashLimit = taskrouterSelectors.getStoreCashLimit();

  const bothAreNull = brandCashLimit === null && storeCashLimit === null;
  const bothHaveValues =
    typeof brandCashLimit === 'string' && typeof storeCashLimit === 'string';
  const oneValueOneNull = !bothAreNull && !bothHaveValues;

  return {
    brandCashLimit,
    storeCashLimit,
    bothAreNull,
    bothHaveValues,
    oneValueOneNull,
  };
};

export const getPaymentOnArrivalAvailability = (total) => {
  const orderDetails = orderSelectors.getDetails();
  const brand = taskrouterSelectors.deprecatedGetBrand();

  if (notAbleToPayOnArrivalHandoffModes.includes(orderDetails?.handOffMode)) {
    return false;
  }

  // TODO: remove this when we handle this in the right place
  if (brand?.key === 'newks' && orderDetails?.handOffMode === 'curbside') {
    return false;
  }

  const {
    brandCashLimit,
    storeCashLimit,
    bothAreNull,
    bothHaveValues,
    oneValueOneNull,
  } = getCashLimitValues();

  if (bothAreNull) return true;
  if (bothHaveValues) return total < storeCashLimit;

  if (oneValueOneNull) {
    if (typeof storeCashLimit === 'string')
      return Number(total) < Number(storeCashLimit);

    return Number(total) < Number(brandCashLimit);
  }
};

export const getEffectiveCashLimit = () => {
  const {
    brandCashLimit,
    storeCashLimit,
    bothAreNull,
    bothHaveValues,
    oneValueOneNull,
  } = getCashLimitValues();

  if (bothAreNull) return null;
  if (bothHaveValues) return storeCashLimit;

  if (oneValueOneNull) {
    if (typeof storeCashLimit === 'string') return storeCashLimit;

    return brandCashLimit;
  }
};
