import { useAppSelector } from '@store';

import { useSelector } from 'react-redux';
import { useAgents, useClock, useDevice } from '@hooks';
import * as taskrouterSelectors from '@modules/taskrouter/selectors';

export const useStore = () => {
  const user = useSelector(({ auth }) => auth.user);
  const clock = useClock();
  const device = useDevice();

  const { currentAgent } = useAgents();

  const { isPending, hasError } = device;
  const brands = currentAgent ? currentAgent.brands : [];
  const trainedOrQueuedBrands = brands.filter(
    (brand) => brand.queued || brand.trained,
  );

  const storesByBrand = useAppSelector(taskrouterSelectors.getStoresByBrand);

  const allBrands = useAppSelector(taskrouterSelectors.getBrands);

  const isFetchingBrands = useAppSelector(taskrouterSelectors.isFetchingBrands);

  return {
    user,
    brands: trainedOrQueuedBrands,
    buttonLoading: clock.pending.isClockingIn,
    buttonDisabled: isPending || hasError,
    startingDevice: isPending,
    hasStartDeviceError: hasError,
    storesByBrand,
    allBrands,
    isFetchingBrands,
  };
};
