import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { types } from './types';
import { INITIAL_STATE, CLOCK_STATUS } from './state';

const clockStatus = {
  [types.CLOCK_FETCHING_CLOCK_STATUS]: (state) =>
    produce(state, (clock) => {
      clock.pending.isFetchingClockStatus = true;
      clock.errors.hasClockStatusError = false;
      return clock;
    }),

  [types.CLOCK_FETCHED_CLOCK_STATUS]: (state, action) =>
    produce(state, (clock) => {
      clock.pending.isFetchingClockStatus = false;
      clock.errors.hasClockStatusError = false;

      const { target, payload } = action;
      clock.byId[target] = payload;
      return clock;
    }),

  [types.CLOCK_NOT_FETCHED_CLOCK_STATUS]: (state) =>
    produce(state, (clock) => {
      clock.pending.isFetchingClockStatus = false;
      clock.errors.hasClockStatusError = true;
      return clock;
    }),
};

const clockIn = {
  [types.CLOCK_CLOCKING_IN]: (state) =>
    produce(state, (clock) => {
      clock.pending.isClockingIn = true;
      clock.errors.hasClockInError = false;
      return clock;
    }),

  [types.CLOCK_CLOCKED_IN]: (state, action) =>
    produce(state, (clock) => {
      clock.pending.isClockingIn = false;
      clock.errors.hasClockInError = false;

      const { id, datetime } = action.payload;

      clock.status = CLOCK_STATUS.CLOCK_IN;
      clock.id = id;
      clock.datetime = datetime;

      return clock;
    }),

  [types.CLOCK_NOT_CLOCKED_IN]: (state) =>
    produce(state, (clock) => {
      clock.pending.isClockingIn = false;
      clock.errors.hasClockInError = true;
      return clock;
    }),
};

const clockOut = {
  [types.CLOCK_CLOCKING_OUT]: (state) =>
    produce(state, (clock) => {
      clock.pending.isClockingOut = true;
      clock.errors.hasClockOutError = false;
      return clock;
    }),

  [types.CLOCK_CLOCKED_OUT]: (state, action) =>
    produce(state, (clock) => {
      clock.pending.isClockingOut = false;
      clock.errors.hasClockOutError = false;

      const { id, datetime } = action.payload;

      clock.status = CLOCK_STATUS.CLOCK_OUT;
      clock.id = id;
      clock.datetime = datetime;

      return clock;
    }),

  [types.CLOCK_NOT_CLOCKED_OUT]: (state) =>
    produce(state, (clock) => {
      clock.pending.isClockingOut = false;
      clock.errors.hasClockOutError = true;
      return clock;
    }),
};

const actions = {
  ...clockStatus,
  ...clockIn,
  ...clockOut,
};

export const clockReducer = createReducer(INITIAL_STATE, actions);
