import React from 'react';

import { RandomMessageAnnounce } from './RandomMessageAnnounce';

export const StallBadge = ({ ...props }) => (
  <RandomMessageAnnounce sampleMessages={messages} {...props}>
    Stall
  </RandomMessageAnnounce>
);

const messages = ['One moment please.', 'Just a moment please.'];
