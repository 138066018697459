import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAccountState } from './state';
import {
  fetchAccountById,
  upsertAccount,
  updateAccount,
  fetchPreviousOrder,
} from './actions';

const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {
    setDetails: (
      state,
      action: PayloadAction<{ key: string; value: string }>,
    ) => {
      const { key, value } = action.payload;

      state.accountDetails[key as keyof typeof state.accountDetails] = value;
    },

    reset: () => initialAccountState,

    closePreviousOrderMenu: (state) => {
      state.isPreviousOrderMenuOpen = false;
    },

    openPreviousOrderMenu: (state) => {
      state.isPreviousOrderMenuOpen = true;
    },
  },
  extraReducers: (builder) => {
    // Fetch Account
    builder.addCase(fetchAccountById.pending, (state, action) => {
      state.id = action.meta.arg;
      state.pending.isFetchingAccount = true;
      state.errors.hasFetchAccountError = false;
    });

    builder.addCase(fetchAccountById.fulfilled, (state, action) => {
      const { firstName, lastName, phone, deliveryAddressId } = action.payload;
      state.pending.isFetchingAccount = false;
      state.errors.hasFetchAccountError = false;
      state.accountDetails.firstName = firstName;
      state.accountDetails.lastName = lastName;
      state.accountDetails.phone = phone;
      state.accountDetails.deliveryAddressId = deliveryAddressId;
    });

    builder.addCase(fetchAccountById.rejected, (state) => {
      state.pending.isFetchingAccount = false;
      state.errors.hasFetchAccountError = true;
    });

    // Upsert Account
    builder.addCase(upsertAccount.pending, (state) => {
      state.pending.isUpsertingAccount = true;
      state.errors.hasUpsertAccountError = false;
    });

    builder.addCase(upsertAccount.fulfilled, (state) => {
      state.pending.isUpsertingAccount = false;
      state.errors.hasUpsertAccountError = false;
    });

    builder.addCase(upsertAccount.rejected, (state) => {
      state.pending.isUpsertingAccount = false;
      state.errors.hasUpsertAccountError = true;
    });

    // Update Account
    builder.addCase(updateAccount.pending, (state) => {
      state.pending.isUpdatingAccount = true;
      state.errors.hasUpdateAccountError = false;
    });

    builder.addCase(updateAccount.fulfilled, (state) => {
      state.pending.isUpdatingAccount = false;
      state.errors.hasUpdateAccountError = false;
    });

    builder.addCase(updateAccount.rejected, (state) => {
      state.pending.isUpdatingAccount = false;
      state.errors.hasUpdateAccountError = true;
    });

    // Fetch PreviousOrder
    builder.addCase(fetchPreviousOrder.pending, (state) => {
      state.previousOrderStatus = 'error';
      state.pending.isFetchingPreviousOrder = true;
      state.errors.hasFetchPreviousOrderError = false;
    });

    builder.addCase(fetchPreviousOrder.fulfilled, (state, action) => {
      state.pending.isFetchingPreviousOrder = false;
      state.errors.hasFetchPreviousOrderError = false;
      state.previousOrder = action.payload;
      state.previousOrderStatus = 'fetched';
    });

    builder.addCase(fetchPreviousOrder.rejected, (state) => {
      state.pending.isFetchingPreviousOrder = false;
      state.errors.hasFetchPreviousOrderError = true;
      state.previousOrderStatus = 'fetching';
    });
  },
});

export const {
  setDetails,
  reset,
  closePreviousOrderMenu,
  openPreviousOrderMenu,
} = accountSlice.actions;

export const { actions } = accountSlice;

export default accountSlice.reducer;
