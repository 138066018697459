import { theme } from '@kea-inc/parrot-ui';
import { ImSpinner2 } from 'react-icons/im';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 48,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin-left: 10px;
`;
