import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@kea-inc/parrot-ui';

import { notificationTypes } from '@modules/notifications/state';
import { useNotification, useHistory } from '@hooks';
import { CenterOverlay } from '@components';
import logger from '@logger';

import * as S from './styles';

const notificationDataByType = {
  [notificationTypes.ERROR]: {
    Icon: S.ErrorIcon,
    titleColor: 'error',
    messageColor: 'error',
  },
  [notificationTypes.INFO]: {
    Icon: S.InfoIcon,
    titleColor: 'primary',
    messageColor: 'black_light',
  },
};

export const NotificationProvider = ({ children }) => {
  const { current, notificationAPI, byId } = useNotification();
  const history = useHistory();

  const notification = current ? byId[current] : null;

  const handleDismiss = () => {
    if (notification.onDismiss) notification.onDismiss();
    if (notification.shouldLogout) history.replace('/logout');
    notificationAPI.dismiss(current);
  };

  useEffect(() => {
    if (notification) {
      const loggerMethod = logger[notification.type];
      if (!loggerMethod) return;

      loggerMethod(
        `${notification.type} notification dispatched with title ${notification.title}`,
        { notification, error: notification.error ?? null },
      );
    }
  }, [notification]);

  const { Icon, titleColor, messageColor } = useMemo(() => {
    if (!notification) return {};
    return notificationDataByType[notification.type];
  }, [notification]);

  return (
    <>
      {notification && (
        <CenterOverlay>
          <S.Notification data-testid="notification__overlay">
            <S.Content>
              <Icon />
              <Typography
                variant="title"
                weight="heavy"
                color={titleColor}
                data-testid="notification-title"
              >
                {notification.title}
              </Typography>
              <Typography
                variant="title"
                weight=""
                color={messageColor}
                data-testid="notification-message"
              >
                {notification.message}
              </Typography>
            </S.Content>

            <S.Footer>
              <Button
                size="small"
                onClick={handleDismiss}
                data-testid="dismiss-notification-button"
              >
                {notification.dismissText ?? 'OK'}
              </Button>
            </S.Footer>
          </S.Notification>
        </CenterOverlay>
      )}
      {children}
    </>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object,
  ]).isRequired,
};
