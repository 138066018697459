import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';
import { getChosenOptions } from './utils';

const actions = {
  [types.STREAM_CONNECTING]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isConnectingStream = true;
      transcripts.errors.hasConnectToStreamError = false;
      return transcripts;
    }),

  [types.STREAM_CONNECTED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isConnectingStream = false;
      transcripts.errors.hasConnectToStreamError = false;
      transcripts.streamConnected = true;
      return transcripts;
    }),

  [types.STREAM_NOT_CONNECTED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isConnectingStream = false;
      transcripts.errors.hasConnectToStreamError = true;
      return transcripts;
    }),

  [types.STREAM_CLOSED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.streamConnected = false;
      return transcripts;
    }),

  [types.TRANSCRIPTS_ADD_MESSAGE]: (state, action) =>
    produce(state, (transcripts) => {
      transcripts.messages.push(action.payload);
      return transcripts;
    }),

  [types.FETCHING_TRANSCRIPTS]: (state) =>
    produce(state, (transcripts) => {
      transcripts.hasFetchedTranscripts = false;
      transcripts.pending.isFetchingTranscripts = true;
      transcripts.errors.hasFetchTranscriptsError = false;
      return transcripts;
    }),

  [types.FETCHED_TRANSCRIPTS]: (state, action) =>
    produce(state, (transcripts) => {
      const { payload } = action;
      transcripts.hasFetchedTranscripts = true;
      transcripts.pending.isFetchingTranscripts = false;
      transcripts.errors.hasFetchTranscriptsError = false;

      if (!Array.isArray(payload)) return transcripts;

      for (const message of payload) {
        if (message.dialog) {
          transcripts.messages.push({
            dialog: message.dialog,
            actor: message.actor,
          });
        }
      }

      return transcripts;
    }),

  [types.NOT_FETCHED_TRANSCRIPTS]: (state) =>
    produce(state, (transcripts) => {
      transcripts.hasFetchedTranscripts = false;
      transcripts.pending.isFetchingTranscripts = false;
      transcripts.errors.hasFetchTranscriptsError = true;
      return transcripts;
    }),

  [types.TRANSCRIPTS_INTENT_CLASSIFYING]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isClassifyingIntent = true;
      transcripts.pending.hasClassifyingIntentError = false;
      return transcripts;
    }),

  [types.TRANSCRIPTS_INTENT_CLASSIFIED]: (state, action) =>
    produce(state, (transcripts) => {
      transcripts.pending.isClassifyingIntent = false;
      transcripts.pending.hasClassifyingIntentError = false;

      const { target, payload: items } = action;

      if (!items.length) return;

      const resolvedItems = [];

      transcripts.lastAutocartTranscriptionId = target;

      items.forEach((item) => {
        if (item.resolved) {
          item.resolved.forEach((resolvedItem) => {
            const chosenOptions = getChosenOptions(resolvedItem);
            const reducedItem = {
              ...resolvedItem,
              chosenOptions,
            };
            resolvedItems.push(reducedItem);
          });

          transcripts.itemsByMessageId[target] = resolvedItems;
        }
      });
    }),

  [types.TRANSCRIPTS_INTENT_NOT_CLASSIFIED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isClassifyingIntent = false;
      transcripts.pending.hasClassifyingIntentError = true;
      return transcripts;
    }),

  [types.AUTOCART_EVENT_CREATING]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isCreatingAutocartEvent = true;
      transcripts.pending.hasCreatingAutocartEventError = false;
      return transcripts;
    }),

  [types.AUTOCART_EVENT_CREATED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isCreatingAutocartEvent = false;
      transcripts.pending.hasCreatingAutocartEventError = false;
      return transcripts;
    }),

  [types.AUTOCART_EVENT_NOT_CREATED]: (state) =>
    produce(state, (transcripts) => {
      transcripts.pending.isCreatingAutocartEvent = false;
      transcripts.pending.hasCreatingAutocartEventError = true;
      return transcripts;
    }),

  [types.RESET]: (state) => produce(state, () => INITIAL_STATE),
};

export const transcriptsReducer = createReducer(INITIAL_STATE, actions);
