export function makeSelectionRequirementsPhrase(
  spokenName: string,
  options?: {
    minOptions: number | null;
    maxOptions: number | null;
    required?: boolean | null;
  },
) {
  if (
    options?.minOptions === null &&
    options.maxOptions === null &&
    options.required === null
  ) {
    return '';
  }

  if (options?.minOptions && options.maxOptions) {
    if (options.minOptions === options.maxOptions) {
      return `${spokenName} selection is required. Please select ${options.minOptions} options.`;
    }

    return `${spokenName} selection is required. Please select between ${options.minOptions} and ${options.maxOptions} options.`;
  }

  if (options?.minOptions) {
    return `${spokenName} selection is required. Please select at least ${options?.minOptions} options.`;
  }

  if (options?.maxOptions) {
    return `${spokenName} selection is required. Please select at most ${options.maxOptions} options.`;
  }

  return `${spokenName} selection is required.`;
}
