import { dispatch } from '@store';

import logger from '@logger';
import { transcriptsStream } from '@services';

import * as errors from './errors';
import * as actions from './actions';
import * as selectors from './selectors';
import * as utils from './utils';

export async function connectStream() {
  if (selectors.isStreamConnected()) return;

  dispatch(actions.transcriptionsStreamConnecting());

  try {
    await utils.connectTokenStream();
    dispatch(actions.transcriptionsStreamConnected());

    utils.addErrorListener(transcriptsStream.source, errorsListener);
    utils.addTranscriptsListener(transcriptsStream.source, transcriptsListener);
  } catch (error) {
    errors.streamConnectionError(error);
    dispatch(actions.transcriptionsStreamNotConnected());
  }
}

export async function closeStream() {
  utils.removeErrorListener(transcriptsStream.source, errorsListener);
  utils.removeTranscriptsListener(
    transcriptsStream.source,
    transcriptsListener,
  );
  transcriptsStream.close();
}

export async function retrieveAllTranscripts() {
  dispatch(actions.transcriptionsFetching());

  try {
    const transcriptions = await utils.retrieveAllTranscripts();
    dispatch(actions.transcriptionsFetched(transcriptions));
  } catch {
    dispatch(actions.transcriptionsNotFetched());
  }
}

export async function addAIMessage(message) {
  dispatch(
    actions.transcriptsAddMessage({
      dialog: message,
      actor: 'AI',
    }),
  );
}

export async function reset() {
  closeStream();
  dispatch(actions.transcriptionsReset());
}

// SIDE EFFECTS
function transcriptsListener(event) {
  if (!event.data) {
    logger.debug('Received transcripts event without data', event);
    return;
  }
  logger.debug('Received transcripts event', event.data);
  const parsedData = JSON.parse(event.data) ?? {};
  const { dialog, actor, id } = parsedData;

  const isValid = dialog && actor && id;
  if (isValid) {
    const message = { ...parsedData };
    dispatch(actions.transcriptsAddMessage(message));

    // Temporarily disabled
    // if (actor === 'customer' || actor === 'caller')
    //   classifyIntent(message.id, dialog);
  }
}

function errorsListener(...args) {
  logger.error('Received error from transcripts stream', args);
  dispatch(actions.transcriptionsStreamError());
}

export async function classifyIntent(messageId, dialog) {
  try {
    dispatch(actions.transcriptsIntentClassifying());
    const { items, intent, validIntent } = await utils.classifyIntent(dialog);

    dispatch(actions.transcriptsIntentClassified(messageId, items));

    if (validIntent) {
      const eventTags = { intent };
      let autocartEventName = 'attempted_suggestion';
      if (items.length > 0) {
        autocartEventName = 'suggestion';
        eventTags.items = items;
      }
      createAutocartEvent(messageId, autocartEventName, eventTags);
    }
  } catch {
    dispatch(actions.transcriptsIntentNotClassified());
  }
}

export async function createAutocartEvent(
  transcriptionId,
  eventName,
  eventTags,
  createdAt = new Date(),
) {
  try {
    dispatch(actions.autocartEventCreating());
    const result = await utils.createAutocartEvent(
      transcriptionId,
      eventName,
      eventTags,
      createdAt,
    );
    dispatch(actions.autocartEventCreated(result));
  } catch {
    dispatch(actions.autocartEventNotCreated());
  }
}
