import { useRef } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';

import { useAppSelector, useAppDispatch } from '@store';
import { openPreviousOrderMenu } from '@modules/account/slice';
import { PreviousOrderMenu } from '@components/PreviousOrderMenu';
import { formatPhoneNumber } from '@utils';
import { getCaller } from '@modules/taskrouter/selectors';

import * as S from './styles';
import { useConnector } from '../useConnector';

const CallerBadge = () => {
  const dispatch = useAppDispatch();
  const badgeRef = useRef(null);
  const caller = useAppSelector(getCaller);

  const formattedPhoneNumber = formatPhoneNumber(caller || '');

  const { selectors } = useConnector();
  const { isTrainingTask } = selectors;

  return (
    <>
      <S.Badge
        onClick={() => dispatch(openPreviousOrderMenu())}
        ref={badgeRef}
        leftIcon={BsFillPersonFill}
        disabled={isTrainingTask}
      >
        {formattedPhoneNumber}
      </S.Badge>

      <PreviousOrderMenu anchor={badgeRef} />
    </>
  );
};

export default CallerBadge;
