import React, { useEffect } from 'react';

import { Greetings, Loading } from '@components';
import { useSurvey } from '@hooks';
import logger from '@logger';
import { device as twilioDevice } from '@services';

import { getUserFeatureFlags } from '@modules/featureFlags/selectors';

import { useAppDispatch, useAppSelector } from '@store';
import { Container, Content, HeaderContainer } from './styles';
import { SurveyOverlay } from './SurveyOverlay';
import { WorkerStatus } from './WorkerStatus';
import { useConnector } from './useConnector';
import ReceivedCall from './ReceivedCall';

const WaitingRoom = () => {
  const { selectors, actions } = useConnector();

  const dispatch = useAppDispatch();

  const {
    isDeviceRegistered,
    isTaskrouterPending,
    isClockingOut,
    user,
    isClockedIn,
    shouldShowRingAnimation,
  } = selectors;
  const {
    setDelayedBrandFeatureFlags,
    setDelayedStoreFeatureFlags,
    setDelayedUserFeatureFlags,
    resetPayment,
    infoNotification,
    clockOut,
  } = actions;
  const { shouldShowSurvey } = useSurvey();

  const useAgentStatuses =
    useAppSelector(getUserFeatureFlags)?.useAgentStatuses;

  useEffect(() => {
    dispatch(setDelayedBrandFeatureFlags());
    dispatch(setDelayedStoreFeatureFlags());
    dispatch(setDelayedUserFeatureFlags());
    resetPayment();
  }, []);

  useEffect(() => {
    if (!isDeviceRegistered && isClockedIn) {
      logger.error('Device is not registered. Trying to register again.');

      twilioDevice.register().catch(() => {
        if (!isClockingOut) {
          infoNotification({
            title: 'Device not registered',
            message: 'Please clock in again to be able to receive calls',
            dismissText: 'Got it!',
          });

          logger.error('Device registration failed. Clocking out.');
          clockOut();
        }
      });
    }
  }, [isDeviceRegistered]);

  if (!user) {
    return <Loading />;
  }

  let greetings = isTaskrouterPending
    ? `Please wait, ${user.given_name}...`
    : `It's all set, ${user.given_name}!`;

  let headline = isTaskrouterPending
    ? "You'll be connected soon"
    : 'Just wait for hungry clients ⏳';

  if (shouldShowRingAnimation) {
    greetings = `It's action time, ${user.given_name}!`;
    headline = 'You received a task 📞';
  }

  return (
    <Container>
      {shouldShowSurvey && <SurveyOverlay />}
      <HeaderContainer>
        <Greetings greetings={greetings} headline={headline} />
        {!useAgentStatuses && <WorkerStatus />}
      </HeaderContainer>
      <Content>
        <ReceivedCall />
      </Content>
    </Container>
  );
};

export default React.memo(WaitingRoom);
