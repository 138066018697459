import styled, { css } from 'styled-components';
import { theme, Typography } from '@kea-inc/parrot-ui';

export const HoverController = styled.div<{
  isAdded: boolean;
  isHovering: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  border-left: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  ${(props) =>
    !props.isAdded &&
    !props.isHovering &&
    css`
      opacity: 0;
    `};

  ${(props) =>
    props.isHovering &&
    css`
      width: calc(100% + 0.25rem);
      transform: translateX(-0.25rem);
      border-radius: 0.25rem;
      border: none;
      background: ${theme.colors.primary_darker}88;
    `};
`;

export const HoverText = styled(Typography).attrs({
  weight: 'heavy',
  color: 'white',
})<{
  hasOptionInlineRadioSelector: boolean;
}>`
  ${(props) =>
    props.hasOptionInlineRadioSelector &&
    css`
      position: absolute;
      top: 0.5rem;
    `};
`;
