import { store } from '@store';

import { CLOCK_STATUS } from './state';

function clock() {
  return store.getState().clock;
}

export function isClockedIn() {
  const { status } = clock();
  return status === CLOCK_STATUS.CLOCK_IN;
}

export function isClockedOut() {
  const { status } = clock();
  return status === CLOCK_STATUS.CLOCK_OUT;
}

export function isClockingOut() {
  const { pending } = clock();
  return pending.isClockingOut;
}
