import { useMemo, useState } from 'react';

export const useSpeech = () => {
  const [transcript, setTranscript] = useState('');
  const [sentences, setSentences] = useState([]);
  const [recording, setIsRecording] = useState(false);
  const [hasError, setHasError] = useState(false);

  const recognition = useMemo(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    const speechRecognition = new SpeechRecognition();

    speechRecognition.lang = 'en-US';
    speechRecognition.continuous = true;

    return speechRecognition;
  }, []);

  recognition.onstart = () => {
    setIsRecording(true);
  };

  recognition.onend = () => {
    setIsRecording(false);
  };

  recognition.onnomatch = () => {
    setHasError(true);
  };

  recognition.onresult = (event) => {
    const { results } = event;

    Object.values(results).forEach((result, idx) => {
      if (idx >= sentences.length) {
        setSentences((prev) => [...prev, result]);
        const text = result[0].transcript;
        setTranscript((prev) => `${prev} ${text}`.replace(/\s+/g, ' ').trim());
      }
    });
  };

  const cleanSpeech = () => {
    setTranscript('');
    setSentences([]);
  };

  return {
    recognition,
    transcript,
    sentences,
    recording,
    hasError,
    cleanSpeech,
  };
};
