import styled from 'styled-components';
import { theme } from '@kea-inc/parrot-ui';
import { AiFillWarning } from 'react-icons/ai';

export const Notification = styled.section`
  min-width: 380px;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 52px 12px;
  min-height: 200px;
`;

export const ErrorIcon = styled(AiFillWarning)`
  font-size: 52px;
  color: ${theme.colors.error};
  margin-bottom: 10px;
`;

export const InfoIcon = styled(AiFillWarning)`
  font-size: 52px;
  color: ${theme.colors.warning};
  margin-bottom: 10px;
`;

export const Footer = styled.footer`
  width: 100%;
  border-top: 1px solid ${theme.colors.gray_light};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`;
