import { Modifier, Option } from '@modules/menu/types';
import { formatList, isEntityAvailable, pluralize } from '@utils';

export function makeModifierOptionsPhrase(
  modifier: Modifier,
  modifierOptions: Option[],
) {
  if (!modifierOptions.length) return '';

  const availableOptionsNames: string[] = [];

  modifierOptions.forEach((option) => {
    if (isEntityAvailable(option)) {
      availableOptionsNames.push(option.spokenName);
    }
  });

  const spokenName = pluralize(
    modifier.spokenName,
    availableOptionsNames.length,
  );
  if (availableOptionsNames.length < 5) {
    if (spokenName === 'Options') {
      return `Our ${spokenName} are: ${formatList(availableOptionsNames)}`;
    }

    return `Our options for ${spokenName} are: ${formatList(
      availableOptionsNames,
    )}`;
  }

  return `We have ${
    availableOptionsNames.length
  } available ${spokenName}. ${formatList(availableOptionsNames)}`;
}

export function makeCurrentOptionsPhrase(
  modifier: Modifier,
  currentOptions: SelectedOption[],
) {
  if (!currentOptions || !currentOptions.length) {
    return `For ${modifier.spokenName}, currently you haven't selected anything.`;
  }
  return `For ${
    modifier.spokenName
  }, currently you've selected: ${formatOptionsList(currentOptions)}.`;
}

function formatOptionsList(options: SelectedOption[]) {
  const names = options.map((option) => {
    if (option.quantity > 1) {
      return `${option.quantity} ${pluralize(option.name)}`;
    }

    return option.name;
  });

  return formatList(names);
}

export interface SelectedOption {
  id: string;
  name: string;
  quantity: number;
}
