import styled, { keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';
import { theme } from '@kea-inc/parrot-ui';

export const Header = styled.section`
  padding: 7px 11px;
`;

export const OrderItems = styled.section`
  padding: 7px 11px;
  max-height: 300px;
  overflow-y: scroll;

  .item {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  .price__quantity {
    margin-top: 8px;
  }

  ul.modifiers__list {
    margin-top: 8px;
    padding: 0 5px;

    li {
      display: flex;
      align-items: center;
    }

    li::before {
      content: '';
      width: 4px;
      height: 4px;
      margin-right: 8px;
      border-radius: 50%;
      background: ${theme.colors.gray_darker};
    }
  }
`;

type FooterProps = {
  isOpen: boolean;
};

export const Footer = styled.section<FooterProps>`
  padding: 7px 15px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

export const Loading = styled(ImSpinner2).attrs({
  size: 36,
})`
  animation: ${spin} 0.8s infinite linear;
  color: ${theme.colors.primary_dark};
  margin: 0 auto;
`;
