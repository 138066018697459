import { useEffect, useRef, useState } from 'react';
import { WorkerStatus as StatusType } from '@modules/taskrouter/types';
import * as notifications from '@modules/notifications/api';
import { useInterval } from '@hooks';
import { useAppDispatch } from '@store';
import env from '@beam-australia/react-env';
import { Typography } from '@kea-inc/parrot-ui';
import { setWorkerStatus } from '@modules/taskrouter/actions';
import {
  openStatusOverlay,
  resetWorkerStatus,
} from '@modules/taskrouter/slice';
import { getAgentStatusesList } from '@utils';
import { Loading } from '../../pages/WaitingRoom/styles';
import { useConnector } from '../../pages/WaitingRoom/useConnector';
import * as S from './styles';
import { prettifyWorkerStatus } from './utils';

export type WorkerStatusProps = {
  showStatusesMenu?: boolean;
};

export function WorkerStatus({ showStatusesMenu = true }: WorkerStatusProps) {
  const { selectors, actions } = useConnector();
  const { getWorkerStatus, resetTaskrouter, clockIn } = actions;
  const { isClockingIn, isClockingOut, isClockedIn, workerStatus } = selectors;
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const workerStatusRef = useRef(null);
  const agentStatuses = getAgentStatusesList();

  const workerStatusInterval = parseInt(
    env('WORKER_STATUS_INTERVAL') || '5000',
    10,
  );

  useInterval(() => {
    if (!isClockingIn && !isClockingOut) {
      dispatch(getWorkerStatus());
    }
  }, workerStatusInterval);

  useEffect(() => {
    if (workerStatus && workerStatus === 'Offline' && isClockedIn) {
      notifications.error({
        title: 'You are offline',
        message: 'Please clock in again.',
        onDismiss: () => {
          dispatch(resetTaskrouter({ shouldCompleteTask: false }));
        },
      });
    }
  }, [workerStatus]);

  const handleChangeStatus = async (status: StatusType) => {
    setIsDropdownOpen(false);
    dispatch(resetWorkerStatus());
    if (!isClockedIn) {
      await clockIn();
    }
    dispatch(setWorkerStatus(status));
    dispatch(openStatusOverlay());
  };

  return workerStatus ? (
    <>
      <S.Chip
        color={agentStatuses[workerStatus]?.color}
        rightIcon={agentStatuses[workerStatus]?.icon}
        selected
        onClick={() => setIsDropdownOpen(true)}
        ref={workerStatusRef}
        size="small"
        id="worker-status-chip"
      >
        Status: {prettifyWorkerStatus(workerStatus)}
      </S.Chip>
      <S.DropdownMenu
        anchor={workerStatusRef}
        position="bottomRight"
        width={200}
        open={isDropdownOpen && showStatusesMenu}
        onClose={() => setIsDropdownOpen(false)}
      >
        {isDropdownOpen && (
          <div>
            <Typography weight="heavy" variant="caption" color="black_light">
              Change Status to:
            </Typography>

            {Object.keys(agentStatuses)
              .filter((key) => agentStatuses[key as StatusType]?.hasButton)
              .map((key) => (
                <S.StatusButton
                  key={key}
                  data-testid={key}
                  size="small"
                  color={agentStatuses[key as StatusType]?.color}
                  onClick={() => handleChangeStatus(key as StatusType)}
                  LeftIcon={agentStatuses[key as StatusType]?.icon}
                >
                  {prettifyWorkerStatus(key)}
                </S.StatusButton>
              ))}
          </div>
        )}
      </S.DropdownMenu>
    </>
  ) : (
    <S.Chip color="gray" rightIcon={Loading} id="worker-status-chip" selected>
      Status: {workerStatus}
    </S.Chip>
  );
}
