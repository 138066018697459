export function getInitialVbx(params: {
  handoffMode: string;
  hasPreexistingCart: boolean;
}) {
  const { handoffMode, hasPreexistingCart } = params;

  if (handoffMode === 'dine-in') {
    return 'Dine-in orders require payment in advance. To start your order, what is your table number?';
  }

  if (hasPreexistingCart) {
    return 'What else can I get for you today.';
  }

  return 'What can I get for you today.';
}
