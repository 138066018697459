import * as taskrouterSelectors from '@modules/taskrouter/selectors';
import * as agentSelectors from '@modules/agent/selectors';
import * as deviceSelectors from '@modules/device/selectors';
import * as accountSelectors from '@modules/account/selectors';
import * as orderSelectors from '@modules/order/selectors';

import { formatList, getCurrentVersion } from '@utils';
import reduxStore from '@store';
import { InitialCartState } from '@modules/cart/types';

export function copyTroubleshootInformationToClipboard() {
  const store = taskrouterSelectors.getStore();
  const taskSid = taskrouterSelectors.getTaskSid();
  const agent = agentSelectors.currentAgent();

  const order_id = taskrouterSelectors.deprecatedGetOrderId();
  const conference_sid = taskrouterSelectors.deprecatedGetConferenceSid();
  const store_id = store ? store.id : null;
  const task_sid = taskSid;
  const agent_username = agent ? agent.username : null;
  const start_time = deviceSelectors.getStartTimeOfCall();
  const copied_at = new Date();
  const caller = taskrouterSelectors.getCaller();
  const account_id = accountSelectors.getAccountId();
  const { cart } = reduxStore.getState();
  const checklist = orderSelectors.getChecklist();
  const last_announcement = deviceSelectors.getLastAnnouncement();

  const prettyGeneralInformation = makePrettyGeneralInformation(
    order_id,
    conference_sid,
    store_id,
    task_sid,
    agent_username,
    start_time,
    copied_at,
    caller,
    account_id,
    last_announcement,
  );

  const prettyCart = makePrettyCart(cart);
  const prettyChecklist = makePrettyChecklist(checklist);

  const prettyMessage = prettyGeneralInformation + prettyCart + prettyChecklist;
  navigator.clipboard.writeText(prettyMessage);
}

function makePrettyGeneralInformation(
  order_id: string | null,
  conference_sid: string | null,
  store_id: string | null,
  task_sid: string | null,
  agent_username: string | null,
  start_time: Date | null,
  copied_at: Date | null,
  caller: string | null,
  account_id: string | null,
  last_announcement: string | null,
) {
  return `
*GENERAL INFORMATION*
- ORDER ID: ${order_id}
- CONFERENCE SID: ${conference_sid}
- STORE ID: ${store_id}
- TASK SID: ${task_sid}
- AGENT USERNAME: ${agent_username}
- START TIME: ${start_time}
- COPIED AT: ${copied_at}
- CALLER: ${caller}
- ACCOUNT ID: ${account_id}
- LAST ANNOUNCEMENT: ${last_announcement}\n
- VERSION: ${getCurrentVersion()}\n
`;
}

function makePrettyCart(cart: InitialCartState) {
  const { entities } = cart.cartItems;

  const message = `
*CART*
${Object.values(entities).reduce((cartItems, cartItem) => {
  const item = reduxStore.getState().menu.items.entities[cartItem?.menuItemId!];
  const productName = item?.name;
  const options: string[] = [];
  cartItem?.options!.forEach((option) => {
    const menuOption = reduxStore.getState().menu.options.entities[option.id];
    if (menuOption) {
      options.push(menuOption?.name);
    }
  });
  return `${cartItems}
${productName}\n
- ${`${formatList(options)}\n`}
`;
}, '')}\n
  `;

  return message;
}

function makePrettyChecklist(checklist: Record<string, string>) {
  return `
*CHECKLIST*
${Object.keys(checklist).reduce(
  (message, key) => `${message}- ${key}: ${checklist[key]}\n`,
  '',
)}
  `;
}

export function prettifyWorkerStatus(workerStatus: string) {
  return workerStatus.replaceAll('_', ' ');
}
