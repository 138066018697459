import React, { useEffect } from 'react';
import * as notifications from '@modules/notifications/api';
import { NotificationProvider } from './NotificationProvider';

const DefaultOnlyOneTabComponent = () => {
  useEffect(() => {
    notifications.error({
      title: 'Oops!',
      message: 'It looks like UOA is already open in another tab.',
    });
  }, []);

  return <></>;
};

export function withOneTabEnforcer({
  OnlyOneTabComponent = DefaultOnlyOneTabComponent,
  localStorageTimeout = 15 * 1000,
  localStorageResetInterval = 10 * 1000,
  appName = 'default-app-name',
} = {}) {
  return (WrappedComponent: React.FC) => (props: any) => {
    if (
      isDuplicatedWindow(
        localStorageTimeout,
        localStorageResetInterval,
        appName,
      )
    ) {
      return (
        <NotificationProvider>
          <OnlyOneTabComponent />
        </NotificationProvider>
      );
    }
    return <WrappedComponent {...props} />;
  };
}

const isDuplicatedWindow = (
  localStorageTimeout: number,
  localStorageResetInterval: number,
  localStorageTabKey: string,
) => {
  const ItemType = {
    Session: 1,
    Local: 2,
  };

  function setCookie(name: string, value: string, days?: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  function getCookie(name: string) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');
    let result = '';

    cookies.forEach((cookie) => {
      const normalizedCookie = cookie.trim();

      if (normalizedCookie.startsWith(nameEQ)) {
        result = normalizedCookie.substring(
          nameEQ.length,
          normalizedCookie.length,
        );
      }
    });

    return result;
  }

  function getItem(itemtype: number) {
    let val = '';
    switch (itemtype) {
      case ItemType.Session:
        val = window.name;
        break;
      case ItemType.Local:
        val = decodeURIComponent(getCookie(localStorageTabKey) ?? '');
        if (val === undefined) val = '';
        break;
      default:
        break;
    }
    return val;
  }

  function setItem(itemtype: number, val: string) {
    switch (itemtype) {
      case ItemType.Session:
        window.name = val;
        break;
      case ItemType.Local:
        setCookie(localStorageTabKey, val);
        break;
      default:
        break;
    }
  }

  function createGUID() {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  function isTabDuplicated() {
    const sessionGuid = getItem(ItemType.Session) || createGUID();
    setItem(ItemType.Session, sessionGuid);

    const val = getItem(ItemType.Local);
    const tabObj = (val === '' ? null : JSON.parse(val)) || null;

    if (
      tabObj === null ||
      tabObj.timestamp < new Date().getTime() - localStorageTimeout ||
      tabObj.guid === sessionGuid
    ) {
      const setTabObj = () => {
        const newTabObj = {
          guid: sessionGuid,
          timestamp: new Date().getTime(),
        };
        setItem(ItemType.Local, JSON.stringify(newTabObj));
      };

      setTabObj();
      setInterval(setTabObj, localStorageResetInterval);
      window.onunload = () => {
        setItem(ItemType.Local, '');
        localStorage.removeItem(localStorageTabKey);
      };
      return false;
    }

    return true;
  }
  return isTabDuplicated();
};
