import React, { useState, useEffect, useMemo } from 'react';

import env from '@beam-australia/react-env';
import { Typography } from '@kea-inc/parrot-ui';

import { CenterOverlay } from '@components';
import { MuteUnmuteBadge } from '@components/Header/Badges';
import { useOrder, useDevice } from '@hooks';
import { handleEndOfTask } from '@utils/handleEndOfTask';

import { deprecatedGetConferenceSid } from '@modules/taskrouter/selectors';
import { TimeOut } from './TimeOut';
import * as S from './styles';
import { Button } from '../PaybotOverlay/styles';

const SubmitOverlay = () => {
  const [hasStartSubmission, setHasStartSubmission] = useState(false);
  const { isSubmitOverlayOpen, pending, errors } = useOrder();
  const { isMuted } = useDevice();

  const { isSubmittingOrder } = pending;
  const { hasSubmitOrderError } = errors;
  const orderSubmitSuccess =
    !isSubmittingOrder && !hasSubmitOrderError && hasStartSubmission;

  useEffect(() => {
    if (isSubmittingOrder) {
      setHasStartSubmission(true);
    }
  }, [isSubmittingOrder]);

  const canIntercept = useMemo(
    () => env('CAN_INTERCEPT_AFTER_SUBMIT') === 'true',
    [],
  );

  const handleEnd = () => {
    const conferenceSid = deprecatedGetConferenceSid();
    handleEndOfTask(conferenceSid);
  };

  return isSubmitOverlayOpen ? (
    <CenterOverlay>
      <S.Container isSubmittingOrder={isSubmittingOrder}>
        <S.Header>
          <Typography variant="subheader" weight="heavy">
            Submitting order
          </Typography>

          {canIntercept && (
            <S.UnmuteBadgeWrapper>
              <MuteUnmuteBadge />
            </S.UnmuteBadgeWrapper>
          )}
        </S.Header>

        <S.Content>
          {isSubmittingOrder && (
            <S.LoadingContainer>
              <S.Loading />
            </S.LoadingContainer>
          )}

          {orderSubmitSuccess && (
            <S.Success>
              <S.SuccessIcon />
              <Typography
                className="title"
                variant="title"
                weight="heavy"
                color="primary"
              >
                ORDER COMPLETED!
              </Typography>
              <TimeOut stop={!isMuted} onFinish={handleEnd} />
            </S.Success>
          )}
        </S.Content>

        {orderSubmitSuccess && (
          <S.Footer>
            <Button size="small" onClick={handleEnd}>
              End call now
            </Button>
          </S.Footer>
        )}
      </S.Container>
    </CenterOverlay>
  ) : (
    <div />
  );
};

export default React.memo(SubmitOverlay);
