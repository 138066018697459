import { theme } from '@kea-inc/parrot-ui';
import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    box-shadow: 0px 0px 2px 2px ${theme.colors.error_light} inset;
  }
  100% {
    box-shadow: 0px 0px 2px 6px ${theme.colors.error_light} inset;
  }
`;

export const Container = styled.div<{
  isMuted: boolean;
}>`
  .split {
    display: flex;
    flex-direction: row;
  }

  .gutter {
    background-color: #eee;
    background-position: 50%;
    background-repeat: no-repeat;
  }

  .gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
  }

  ${(props) =>
    props.isMuted
      ? 'none'
      : css`
          animation: ${pulse} 0.8s infinite ease-out;
          animation-direction: alternate;
        `}
`;

export const Panel = styled.div`
  height: calc(100vh - 60px);
`;

export const Section = styled.div`
  height: 100%;
  padding: 12px 0;

  &.left {
    padding-left: 12px;
  }

  &.right {
    padding-right: 12px;
  }
`;
