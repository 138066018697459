import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAddressById, searchAddress, validateAddress } from './actions';

const initialState = {
  id: '',
  zipcode: '',
  streetName: '',
  streetNumber: '',
  city: '',
  state: '',
  country: '',
  addressLine2: '',
  deliveryInstructions: '',
  addressSearch: '',
  formattedAddress: '',
  lat: 0,
  lng: 0,
  canDeliver: false,
  isFetchingAddress: false,
  isValidatingAddress: false,
  isAddressFetched: false,
  isAddressValidated: false,
  hasFetchingError: false,
  hasValidationError: false,
};

const reducers = {
  setAddressInfo: (
    state: typeof initialState,
    action: PayloadAction<Partial<typeof initialState>>,
  ) => ({
    ...state,
    ...action.payload,
  }),
  resetDelivery: () => initialState,
};

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(searchAddress.pending, (state) => {
      state.isFetchingAddress = true;
      state.isAddressFetched = false;
      state.hasFetchingError = false;
    });

    builder.addCase(searchAddress.fulfilled, (state, action) => {
      state.isFetchingAddress = false;
      state.isAddressFetched = true;
      state.hasFetchingError = false;

      state.zipcode = action.payload.zipCode || '';
      state.streetName = action.payload.streetName || '';
      state.streetNumber = action.payload.streetNumber || '';
      state.city = action.payload.city || '';
      state.state = action.payload.state || '';
      state.country = action.payload.country || '';

      if (action.payload.formattedAddress) {
        state.formattedAddress = action.payload.formattedAddress;
        state.addressSearch = action.payload.formattedAddress;
      }

      if (action.payload.coordinates) {
        state.lat = Number(action.payload.coordinates.lat);
        state.lng = Number(action.payload.coordinates.lng);
      }
    });

    builder.addCase(searchAddress.rejected, (state) => {
      state.isFetchingAddress = false;
      state.isAddressFetched = false;
      state.hasFetchingError = true;
    });

    builder.addCase(validateAddress.pending, (state) => {
      state.isValidatingAddress = true;
      state.isAddressValidated = false;
      state.hasValidationError = false;
    });

    builder.addCase(validateAddress.fulfilled, (state, action) => {
      state.isValidatingAddress = false;
      state.isAddressValidated = true;
      state.hasValidationError = false;

      state.id = action.payload.id!;
      state.canDeliver = action.payload.canDeliver;
    });

    builder.addCase(validateAddress.rejected, (state) => {
      state.isValidatingAddress = false;
      state.isAddressValidated = false;
      state.hasValidationError = true;

      state.canDeliver = false;
      state.id = '';
    });

    builder.addCase(fetchAddressById.pending, (state) => {
      state.isFetchingAddress = true;
      state.isAddressFetched = false;
      state.hasFetchingError = false;
    });

    builder.addCase(fetchAddressById.fulfilled, (state, action) => {
      state.isFetchingAddress = false;
      state.isAddressFetched = true;
      state.hasFetchingError = false;
      state.isAddressValidated = true;
      state.canDeliver = true;

      const {
        id,
        zipCode,
        streetAddress,
        city,
        state: st,
        country,
      } = action.payload;
      const [streetNumber, streetName] = parseAddress(streetAddress);

      state.id = id;
      state.zipcode = zipCode;
      state.streetName = streetName;
      state.streetNumber = streetNumber || '';
      state.city = city;
      state.state = st;
      state.country = country;
      state.addressSearch = `${streetNumber} ${streetName}, ${city}, ${st} ${zipCode}`;
      state.formattedAddress = `${streetNumber} ${streetName}, ${city}, ${st} ${zipCode}`;

      if (action.payload.instructions) {
        state.deliveryInstructions = action.payload.instructions;
      }

      if (action.payload.building) {
        state.addressLine2 = action.payload.building;
      }
    });

    builder.addCase(fetchAddressById.rejected, (state) => {
      state.isFetchingAddress = false;
      state.isAddressFetched = false;
      state.hasFetchingError = true;
    });
  },
});

export const { setAddressInfo, resetDelivery } = deliverySlice.actions;

export default deliverySlice.reducer;

function parseAddress(address: string) {
  const regex = /^(\d+)\s*[,]*\s*(.*?)$/;
  const match = address.match(regex);

  if (match) {
    const [, number, street] = match;
    return [number, street.trim()];
  }

  return ['', address];
}
