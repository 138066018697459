import { useState, useMemo } from 'react';
import { DateTime } from 'luxon';

import { useAppSelector } from '@store';
import { getStoreHours, getStoreTimezone } from '@modules/taskrouter/selectors';

import camelcaseKeys from 'camelcase-keys';
import { useInterval } from './useInterval';

type Hour = {
  dayOfWeek: string;
  action: string;
  actionTime: string;
  id: string;
};

type StoreHours = Record<
  string,
  { id: string; opens?: string; closes?: string }
>;

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const useStoreHours = () => {
  const hours = camelcaseKeys(useAppSelector(getStoreHours));
  const timezone = useAppSelector(getStoreTimezone);
  const [timeAtStore, setTimeAtStore] = useState(
    DateTime.now().setZone(timezone),
  );

  useInterval(() => {
    setTimeAtStore(DateTime.now().setZone(timezone));
  }, 1000);

  const get12HourTime = (time: string) => {
    const timeHours = Number(time.slice(0, 2));

    if (timeHours === 12) return '12pm';
    if (timeHours === 0) return '12am';
    if (timeHours <= 11) return `${timeHours % 12}am`;

    return `${timeHours % 12}pm`;
  };

  const storeHours = useMemo(
    () =>
      daysOfWeek.reduce((acc, day) => {
        hours?.forEach((hour: Hour) => {
          if (hour.dayOfWeek === day.toUpperCase()) {
            if (hour.action === 'TURN_ON') {
              acc[day] = {
                ...acc[day],
                id: hour.id,
                opens: get12HourTime(hour.actionTime),
              };
            } else {
              acc[day] = {
                ...acc[day],
                id: hour.id,
                closes: get12HourTime(hour.actionTime),
              };
            }
          }
        });

        return acc;
      }, {} as StoreHours),
    [daysOfWeek],
  );

  return {
    storeHours,
    timeAtStore,
    timezone,
  };
};
