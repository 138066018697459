import { store } from '@store';

function auth() {
  return store.getState().auth;
}

export function currentUser() {
  const { user } = auth();
  return user;
}

export function getToken() {
  const { token } = auth();
  return token;
}

export function getFullName() {
  const user = currentUser();
  return user.name;
}
