import React, { useEffect } from 'react';
import { Button, Option, Select, Typography } from '@kea-inc/parrot-ui';
import { IoPlay } from 'react-icons/io5';
import { AiFillClockCircle } from 'react-icons/ai';

import { useAppDispatch } from '@store';
import { useDevice, useClock } from '@hooks';

import * as notifications from '@modules/notifications/api';
import * as deviceActions from '@modules/device/device-actions';
import { setDeviceEdge, setLastEdgeRun } from '@modules/device/device-slice';
import * as clockAPI from '@modules/clock/api';

import { edges } from './utils';
import * as S from './styles';

export function EdgeChooser() {
  const dispatch = useAppDispatch();
  const { token, pending, isPending, errors, edge, isPendingPreflight } =
    useDevice();
  const { isClockingIn } = useClock().pending;

  useEffect(() => {
    if (token && isPendingPreflight) {
      dispatch(deviceActions.startDevice(edge));
      dispatch(setLastEdgeRun({ edge }));
    } else {
      dispatch(deviceActions.fetchToken());
    }
  }, [token]);

  const { isFetchingToken, isPreflightRunning, isStartingDevice } = pending;
  const {
    hasFetchTokenError,
    hasPreflightError,
    hasDeviceStartError,
    hasMicrophoneError,
  } = errors;
  const hasError =
    hasFetchTokenError || hasPreflightError || hasDeviceStartError;

  useEffect(() => {
    if (hasError) {
      notifications.error({
        title: 'Preflight failed',
        message: 'Please try again with another edge.',
        onDismiss: () => {
          dispatch(setDeviceEdge({ edge: 'roaming' }));
        },
      });
    }
  }, [hasError]);

  return (
    <S.EdgeChooserContainer>
      {isPreflightRunning || isStartingDevice ? (
        <>
          <Typography color="gray_darker">
            Starting device and checking connection
          </Typography>
          <S.Loading />
        </>
      ) : (
        <Select
          className="edgeChooser__Select"
          value={edge}
          onChange={(value) => dispatch(setDeviceEdge({ edge: value }))}
          status={!isPendingPreflight ? 'none' : 'error'}
          required
        >
          {Object.keys(edges).map((key) => (
            <Option value={key} key={key}>
              {edges[key]}
            </Option>
          ))}
        </Select>
      )}

      {isPendingPreflight ? (
        <Button
          loading={isFetchingToken}
          danger
          onClick={() => dispatch(deviceActions.fetchToken())}
          LeftIcon={IoPlay}
          className="edgeChooser__Button--restart"
        >
          Run preflight
        </Button>
      ) : (
        <Button
          disabled={isPending || hasError || hasMicrophoneError}
          LeftIcon={AiFillClockCircle}
          onClick={clockAPI.clockIn}
          loading={isClockingIn}
          className="edgeChooser__Button--clockIn"
        >
          Clock in now
        </Button>
      )}
    </S.EdgeChooserContainer>
  );
}
