import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';

import { Option } from '../styles';

export const HalfAddedOption = styled(Option)`
  background: #f0f2f4;
  border: 1px dashed ${theme.colors.primary_dark};
  border-left: 0.25rem solid ${theme.colors.primary_dark};

  display: block;
  opacity: 0.8;
`;

export const FullyAddedOption = styled(Option)`
  background: #f0f2f4;
  border: 1px solid ${theme.colors.primary_dark};
  border-left: 0.25rem solid ${theme.colors.primary_dark};

  display: block;
  opacity: 1;
`;

export const InlineRadioOptionsSelector = styled.div``;

export const ButtonGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  margin-top: 0.6rem;
  width: 100%;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.1rem;
  width: 100%;
  z-index: 4;

  button:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  button:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
