import { datadogRum } from '@datadog/browser-rum';
import env from '@beam-australia/react-env';

datadogRum.init({
  clientToken: env('DD_CLIENT_TOKEN'),
  applicationId: env('DD_APPLICATION_ID'),
  env: env('DD_ENV'),
  service: env('DD_SERVICE'),
  version: env('DD_VERSION'),
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'allow',
  trackErrors: false, // Disable automatic error tracking
});
