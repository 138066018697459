import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const retryUrls = ['/twilio/tasks/reassignTask'];
const retryCount: Record<string, number> = {};
const MAX_RETRY_COUNT = 3;

export function addRetryInterceptor(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const { config, response } = error;

      if (config && response) {
        const { url } = config;

        if (!url || !retryUrls.includes(url)) {
          return Promise.reject(error);
        }

        if (!retryCount[url]) {
          retryCount[url] = 0;
        }

        if (retryCount[url] < MAX_RETRY_COUNT) {
          retryCount[url] += 1;
          return axiosInstance(config);
        }

        delete retryCount[url];
      }

      return Promise.reject(error);
    },
  );
}
