export const EVENT_NAME_BUTTONS = {
  GENERAL: 'uoa_general_button_click',
  SPECIFIC: 'uoa_specific_button_click',
} as const;

export const MENU_BUTTON_IDS = {
  LIST_CATEGORIES: 'uoa_menu_list_categories_button',
  LIST_ITEMS: 'uoa_menu_list_items_button',
  WHICH_ITEMS: 'uoa_menu_which_items_button',
  DESCRIPTION_CATEGORY: 'uoa_menu_description_category_button',
  NAME_ITEM: 'uoa_menu_name_item_button',
  DESCRIPTION_ITEM: 'uoa_menu_description_item_button',
  NAME_MODIFIER: 'uoa_menu_name_modifier_button',
  WHICH_MODIFIER: 'uoa_menu_which_modifier_button',
  LIST_MODIFIER: 'uoa_menu_list_modifier_button',
  CURRENT_MODIFIER: 'uoa_menu_current_modifier_button',
  MODIFIER_REQUIRED: 'uoa_menu_modifier_required_button',
} as const;

export const CART_BUTTON_TYPES = {
  UPSELL: 'uoa_cart_upsell_button',
  ITEM: 'uoa_cart_item_button',
  ITEM_PRICE: 'uoa_cart_item_price_button',
} as const;

export const SOUNDBOARD_BUTTON_TYPES = {
  PROMPT: 'uoa_soundboard_prompt_button',
} as const;
