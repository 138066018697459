import { theme } from '@kea-inc/parrot-ui';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';

import { Option } from '../styles';

export const Slider = styled(ReactSlider)`
  flex-grow: 1;
`;

export const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${theme.colors.gray};
  height: 0.125rem;
  margin: 0.625rem 0;
`;

export const StyledThumb = styled.div`
  line-height: 1rem;
  min-width: 1.25rem;
  padding: 0.0625rem;
  font-size: 0.812rem;
  text-align: center;
  background-color: #fff;
  border-radius: 100%;
  border: 1px solid #000000;
  box-shadow: 1px 2px 2px #f0f2f4;
  cursor: grab;
`;

interface SliderProps {
  disabled: boolean;
}

export const AddSliderButton = styled(BiPlusCircle).attrs(
  (props: SliderProps) => ({
    color: props.disabled ? theme.colors.gray_light : theme.colors.gray_dark,
    size: 20,
    type: 'button',
  }),
)<SliderProps>`
  margin: 0.0625rem 0 0 0.25rem;
  z-index: 1;
`;

export const SubtractSliderButton = styled(BiMinusCircle).attrs(
  (props: SliderProps) => ({
    color: props.disabled ? theme.colors.gray_light : theme.colors.gray_dark,
    size: 20,
    type: 'button',
  }),
)<SliderProps>`
  margin: 0.0625rem 0.25rem 0 0;
  z-index: 1;
`;

export const QuantityOption = styled(Option)`
  flex-direction: column;
`;

export const SliderWrapper = styled.section`
  width: 100%;
  display: flex;
  margin-top: 0.5rem;

  z-index: 4;
`;
