import React from 'react';

import { useOrder } from '@hooks';
import { formatPrice } from '@utils';
import { CartItemCard } from '@components';
import { TotalBadge } from '@components/DynamicBadges';
import { useAppDispatch } from '@store';
import * as cartActions from '@modules/cart/actions';

import * as S from './styles';
import { useConnector } from './useConnector';

export const CartTab = React.memo(() => {
  const { tips, shouldTip } = useOrder();
  const dispatch = useAppDispatch();

  const { selectors, actions } = useConnector();
  const {
    subtotal,
    discount,
    taxAndFees,
    total,
    taxes,
    isFetchingPrices,
    cartItems,
    savedCoupon,
    removedDefaultOptions,
  } = selectors;
  const { openCouponsOverlay, openTaxAndFeesOverlay, openTipsOverlay } =
    actions;

  const handleRemove = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    cartItemId: string,
  ) => {
    e.stopPropagation();
    dispatch(cartActions.removeCartItem(cartItemId));
  };

  const handleUpdateOnCart = (cartItemId: string, quantity: number) => {
    dispatch(
      cartActions.updateCartItem({
        cartItemId,
        quantity,
        removedDefaultOptions,
      }),
    );
  };

  const handleSelectCartItem = (cartItemId: string) => {
    dispatch(cartActions.selectCartItemForUpdate(cartItemId));
  };

  return (
    <S.Container>
      <div className="products">
        {cartItems.map((cartItem) => (
          <CartItemCard
            key={cartItem.id}
            cartItem={cartItem}
            onRemove={handleRemove}
            onUpdate={handleUpdateOnCart}
            onSelect={handleSelectCartItem}
          />
        ))}
      </div>

      <S.CartSummary>
        <S.SummaryInfo>
          <S.SummaryLabel>Subtotal</S.SummaryLabel>
          <S.SummaryCost>{formatPrice(subtotal)}</S.SummaryCost>
        </S.SummaryInfo>

        <S.SummaryInfo>
          <S.SummaryLabel>
            <S.HighlightedButton
              data-testid="coupons-overlay-trigger"
              onClick={() => dispatch(openCouponsOverlay())}
            >
              {savedCoupon?.code || 'Coupon'}
            </S.HighlightedButton>
          </S.SummaryLabel>
          <S.SummaryCost>
            {isFetchingPrices ? <S.Loading /> : `- ${formatPrice(discount)}`}
          </S.SummaryCost>
        </S.SummaryInfo>

        <S.SummaryInfo>
          <S.SummaryLabel>
            {taxes && taxes.length ? (
              <S.HighlightedButton onClick={openTaxAndFeesOverlay}>
                Tax & Fees
              </S.HighlightedButton>
            ) : (
              'Tax & Fees'
            )}
          </S.SummaryLabel>
          <S.SummaryCost>
            {isFetchingPrices ? <S.Loading /> : formatPrice(taxAndFees)}
          </S.SummaryCost>
        </S.SummaryInfo>

        {shouldTip && (
          <S.SummaryInfo>
            <S.SummaryLabel>Tip</S.SummaryLabel>
            <S.HighlightedButton
              onClick={openTipsOverlay}
              data-test-id="add-tip-button"
            >
              {tips !== 0 ? formatPrice(tips) : 'Add'}
            </S.HighlightedButton>
          </S.SummaryInfo>
        )}

        <S.SummaryInfo>
          <TotalBadge className="summaryInfo__badge" />
          <S.SummaryCost>
            {isFetchingPrices ? (
              <S.Loading data-testid="pricing__loading" />
            ) : (
              formatPrice(total)
            )}
          </S.SummaryCost>
        </S.SummaryInfo>
      </S.CartSummary>
    </S.Container>
  );
});
