import React from 'react';

import { Typography } from '@kea-inc/parrot-ui';

import { useAppDispatch, useAppSelector } from '@store';
import { selectCategoryById, selectItemById } from '@modules/menu/selectors';
import { navigateToCategory, navigateToItem } from '@modules/menu/actions';
import { changeItemsSearchTerm } from '@modules/menu/slice';
import { actions as cartSlice } from '@modules/cart/slice';
import * as S from './styles';
import { ReloadMenu } from '../ReloadMenu';

export const Breadcrumbs = React.memo(() => {
  const dispatch = useAppDispatch();

  const currentCategoryId = useAppSelector(
    (state) => state.menu.categories.current,
  );

  const currentItemId = useAppSelector((state) => state.menu.items.current);

  const isEditing = useAppSelector((state) => state.menu.items.isEditing);

  const searchTerm = useAppSelector((state) => state.menu.items.searchTerm);

  const handleHomeClick = () => {
    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(cartSlice.setCurrentCartItem(null));

    dispatch(
      navigateToItem({
        itemId: null,
      }),
    );

    dispatch(
      navigateToCategory({
        categoryId: null,
      }),
    );
  };

  const handleCategoryClick = () => {
    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(cartSlice.setCurrentCartItem(null));

    dispatch(
      navigateToItem({
        itemId: null,
      }),
    );

    dispatch(
      navigateToCategory({
        categoryId: currentCategoryId!,
      }),
    );
  };

  const handleItemClick = () => {
    dispatch(changeItemsSearchTerm({ searchTerm: '' }));
    dispatch(cartSlice.setCurrentCartItem(null));

    dispatch(
      navigateToItem({
        itemId: currentItemId!,
      }),
    );
  };

  const category = useAppSelector((state) =>
    selectCategoryById(state.menu.categories, currentCategoryId!),
  );

  const item = useAppSelector((state) =>
    selectItemById(state.menu.items, currentItemId!),
  );

  const searching = searchTerm.length >= 3;

  return (
    <S.BreadcrumbsContainer>
      <Typography
        onClick={handleHomeClick}
        variant="caption"
        color="gray_darker"
      >
        Home
      </Typography>

      {category && (
        <>
          <S.Separator />
          <Typography
            onClick={handleCategoryClick}
            variant="caption"
            color="gray_darker"
          >
            {category.name}
          </Typography>
        </>
      )}

      {item && (
        <>
          <S.Separator />
          <Typography
            onClick={handleItemClick}
            variant="caption"
            color="gray_darker"
          >
            {item.name}
          </Typography>
        </>
      )}

      {searching && (
        <>
          <S.Separator />
          <Typography variant="caption" color="gray_darker">
            Searching
          </Typography>
        </>
      )}

      {isEditing && (
        <>
          <S.Separator />
          <Typography variant="caption" color="gray_darker">
            Editing
          </Typography>
        </>
      )}

      <ReloadMenu />
    </S.BreadcrumbsContainer>
  );
});
