import KeaOrder from '@kea-inc/order';
import { MenuEntity } from '@kea-inc/types';
import { DateTime } from 'luxon';

import { AvailabilitySchedule } from '@modules/menu/types';
import { deprecatedGetStoreTimezone } from '@modules/taskrouter/selectors';

type IsAvailableEntity = {
  hasStockAvailable: boolean;
  availabilityStart: string | null;
  availabilityEnd: string | null;
  availabilitySchedule: AvailabilitySchedule | null;
};

export function isEntityAvailable(
  entity: IsAvailableEntity,
  params?: { timezone: string },
) {
  const timezone = params?.timezone ?? deprecatedGetStoreTimezone();

  const timeAtStore = DateTime.now().setZone(timezone);

  // We gotta not include the offset here because the time is already in the store's timezone
  // If included, the common method will append the offset to the time, which will make it incorrect
  const isoTime = timeAtStore.toISO({ includeOffset: false });

  return KeaOrder.isEntityAvailable(entity as MenuEntity, isoTime);
}
