import produce from 'immer';

import { createReducer } from '@modules/createReducer';

import { INITIAL_STATE } from './state';
import { types } from './types';

const actions = {
  [types.NOTIFICATION_EMIT]: (state, action) =>
    produce(state, (notifications) => {
      const { id } = action.payload;
      notifications.byId[id] = action.payload;
      if (notifications.stack.length === 0) {
        notifications.current = id;
      }
      notifications.stack.push(id);
      return notifications;
    }),

  [types.NOTIFICATION_DISMISS]: (state, action) =>
    produce(state, (notifications) => {
      const id = action.payload;
      if (id === notifications.current) {
        notifications.current = null;
      }
      notifications.stack = notifications.stack.filter(
        (stackId) => stackId !== id,
      );
      delete notifications.byId[id];
      if (notifications.stack.length > 0) {
        const next = notifications.stack[0];
        notifications.current = next;
      }
      return notifications;
    }),

  [types.RESET]: (state) => produce(state, () => INITIAL_STATE),
};

export const notificationsReducer = createReducer(INITIAL_STATE, actions);
