import { useState, useEffect } from 'react';

let timeout;

export function useKeyPress(targetKey, options = {}) {
  const [keyPressed, setKeyPressed] = useState(false);
  const [keyHold, setKeyHold] = useState(false);

  function shouldTriggerPressed(event) {
    const { key, shiftKey, controlKey } = event;
    if (options.withShift && !shiftKey) return false;
    if (options.withControl && !controlKey) return false;
    return String(targetKey) === key;
  }

  function downHandler(event) {
    clearTimeout(timeout);
    if (shouldTriggerPressed(event)) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    clearTimeout(timeout);
    if (String(targetKey) === key) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    timeout = setTimeout(() => {
      setKeyHold(keyPressed);
      clearTimeout(timeout);
    }, 250);
  }, [keyPressed]);

  useEffect(() => {
    if (targetKey) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    }

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return {
    isPressed: keyPressed,
    isHolding: keyHold,
  };
}
